import React, { useRef, useState } from "react";
import "./ContactCustomer.scss";
import {
    Grid,
    TextField,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Card,
    CardContent,
    Checkbox
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import StoreManager from "../../Managers/Store.manager";
import { ICustomerDetail } from '../../Models/IPickOrder'
import { OmsPickOrderDetail, ShipmentLine } from '../../Models/OmsPickOrderDetail';
import { ICustomerContact, Item } from '../../Models/ICustomerContact'
import Constants from '../../Common/Constants';
import allActions from "../../Store/Actions/AllActions"
import { CHANGE_STOREEMAIL } from "../../Store/ActionTypes";
import { ImageZoom } from "./ImageZoom";
import { IImageZoomSettings } from "../../Models/IImageZoomSettings";
import { TailSpin } from "react-loader-spinner";
import ContactCustomerManager from "../../Managers/ContactCustomer.Manager";
import Utils from "../../Common/Utils";
import { ImageAttachments } from "./ImageAttachments";
var moment = require('moment');
export const CustomerResponse = (props: any) => {
    console.log("Props response", props);
    const [openImageZoom, setImageZoom] = useState(false);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [imagesData, setImagesData] = useState([]);
    const [imageURL, setImageURL] = useState('');
    const getDate = (dateTime: any) => {
        if (dateTime && dateTime != "") {
            //return moment.utc(dateTime).tz("America/Los_Angeles").format(Constants.DateFormat.Format);
            return moment(dateTime).format(Constants.DateFormat.Format);
        }
        else {
            return ""
        }

    }


    // const closeImageZoom = () => {
    //     setImageZoom(false);
    //     setImageURL("");
    // }

    // const imageZoomPopUp = (imageURL: any) => {
    //     setImageURL(imageURL);
    //     setImageZoom(true);
    // }

    // let imageZoomSettings: IImageZoomSettings = {
    //     open: openImageZoom,
    //     close: closeImageZoom,
    //     imageSrc: imageURL
    // }
    // const getImagesPath = (images: any) => {
    //     let paths = images.map((image: any) => (image.path));
    //     return paths;
    // }

    // const getImages = async (data: any) => {
    //     let imagesPath = getImagesPath(data);
    //     let payload = {
    //         getImages: imagesPath
    //     }
    //     return await ContactCustomerManager.getImages(payload);
    // }
    // React.useEffect(() => {
    //     async function loadImages() {
    //         let response = await getImages(props.imageAttachments)
    //         if (response.success && response.data?.response?.data?.length > 0) {
    //             setImagesData(response.data.response.data)
    //             setImagesLoaded(true)
    //         }
    //     }
    //     loadImages()

    // }, []);

    const renderResponse = (props: any) => {
        return (<div style={{ marginTop: 20, border: "solid 1px", padding: 2 }}>
            <Grid item xs={12} sm={12} className='inputField ' style={{}}>
                <div className="backdrop">
                    <div className="title">
                        <Typography>Customer Response</Typography>
                    </div>
                    <div className="mainContainer" style={{ padding: "20px", overflowX: "auto" }}>
                        <Typography style={{ color: Constants.Colors.red, textAlign: "center" }} variant="h5" gutterBottom component="div">
                            {props.customerresponse.val}
                        </Typography>
                        <table>


                            {props.responded == "true" ?

                                <React.Fragment>
                                    <tr>


                                        <td style={{ verticalAlign: "top" }}>
                                            <Typography >
                                                <b>Message: </b>
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography>
                                                {props.customermessage}
                                            </Typography>
                                        </td>



                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "top" }}>
                                            <Typography>
                                                <b>Date: </b>
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography>
                                                {getDate(props.customerresponsedate)}
                                            </Typography>
                                        </td>
                                    </tr>
                                </React.Fragment> :
                                <React.Fragment>
                                    <tr>
                                        <td colSpan={2} style={{ verticalAlign: "top" }}>
                                            <Typography>
                                                <b>Customer has not responded to your request.</b>
                                            </Typography>
                                        </td>

                                    </tr>
                                </React.Fragment>

                            }


                        </table>
                        {/* <Typography>
                              <b>Message: </b>{props.customermessage}
                          </Typography>
                          <Typography>
                              <b>Date: </b>{getDate(props.customerresponsedate)}
                          </Typography> */}
                    </div>
                </div>
            </Grid >
            :
            <></>
            <Grid item xs={12} sm={12} className='inputField ' style={{ marginTop: 8 }}>
                <div className="backdrop">
                    <div className="title">
                        <Typography>Associate Request</Typography>
                    </div>
                    <div className="mainContainer" style={{ padding: "20px",wordBreak:"break-word"}}>
                        <table>
                            <tr>
                                <td style={{ verticalAlign: "top" }}>
                                    <Typography>
                                        <b>Template: </b>
                                    </Typography>
                                </td>
                                <td>
                                    <Typography>
                                        {Utils.getLabel(props.filename)}
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ verticalAlign: "top" }}>
                                    <Typography >
                                        <b>Date: </b>
                                    </Typography>
                                </td>
                                <td>
                                    <Typography>
                                        {getDate(props.createdDate)}
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ verticalAlign: "top" }}>
                                    <Typography >
                                        <b>Customer Name:</b>
                                    </Typography>
                                </td>
                                <td>
                                    <Typography>
                                        {props.customername}
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ verticalAlign: "top" }}>
                                    <Typography >
                                        <b>Associate Email Address:</b>
                                    </Typography>
                                </td>
                                <td>
                                    <Typography>
                                        {props.logged_in_user_email}
                                    </Typography>
                                </td>
                            </tr>
                        </table>
                        {/* <Typography>
                              <b>Template: </b> {getLabel(props.filename)}
                          </Typography>
                          <Typography>
                              <b>Customer Name:</b> {props.customername}
                          </Typography>
                          <Typography>
                              <b>Date: </b>{getDate(props.createdDate)}
                          </Typography> */}
                    </div>
                </div>
            </Grid>
            {
                props.imageAttachments?.length > 0 &&
                <Grid item xs={12} sm={12} className='inputField ' style={{ marginTop: 8 }}>
                    <div className="backdrop">
                        <div className="title">
                            <Typography>Attachments</Typography>
                        </div>
                        <ImageAttachments imageAttachments={props.imageAttachments} />
                        {/* <div className="mainContainer" style={{ padding: "20px" }}>
                              {
                                  imagesLoaded ?
                                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                                          {
                                              imagesData.map((image: any) => {
                                                  return (
                                                      <div className="image" style={{ backgroundImage: `url(${image})`, cursor: "pointer" }} onClick={() => { imageZoomPopUp(image) }}>
                                                      </div>
                                                  )
                                              })
                                          }
                                      </div>
                                      :
                                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                                          {
                                              props.imageAttachments.map((image: any) => {
                                                  return (
                                                      <div className="image" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                          <TailSpin color="#D93A2F" height={40} width={40} wrapperStyle={{ justifyContent: "center" }} />
                                                      </div>
                                                  )
                                              })
                                          }
                                      </div>
                              }
  
                          </div> */}
                    </div>
                </Grid>
            }
            {
                props.filename != Constants.ContactCustomerFileName.FollowUp ?
                    <Grid item xs={12} sm={12} className='inputField ' style={{ marginTop: 8 }}>
                        <div className="backdrop">
                            <div className="title">
                                <Typography>Selected Lines</Typography>
                            </div>
                            <div className="mainContainer" style={{ padding: "20px" }}>
                                {
                                    props?.items?.map((item: any) => {
                                        return (
                                            <Card style={{ marginBottom: 8 }}>
                                                <CardContent style={{ padding: 4 }}>
                                                    <Grid container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                        <Grid item xs={12} sm={12} style={{ padding: "8px", textAlign: "left" }}>
                                                            <Typography style={{ fontSize: "13px", fontWeight: 500, color: "#D93A2F", paddingLeft: "0px" }}>{item.description}</Typography>
                                                            <Typography style={{ fontSize: "11px" }}><b>Brand:</b> {item.brand}</Typography>
                                                            <Typography style={{ fontSize: "11px" }}><b>Qty:</b> {item.qty}</Typography>
                                                            <Typography style={{ fontSize: "11px" }}> <b>Item ID:</b> {item.itemId}</Typography>
                                                            <Typography style={{ fontSize: "11px" }}><b>Sku :</b> {item.sku}</Typography>
                                                            <Typography style={{ fontSize: "11px" }}><b>Message :</b> <b style={{ color: "red" }}>{item.message}</b></Typography>
                                                            {/* <Typography style={{ fontSize: "11px" }}><b>Cancelled :</b> {item.cancelled == true || item.cancelled == "true" ? <b style={{ color: "red" }}>Yes</b> : <b style={{ color: Constants.Colors.green }}>No</b>}</Typography> */}
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        );
                                    })
                                }
                            </div>
                        </div>
                        {/* <div ref={errorRef}>
                      <Typography style={{ color: "red" }}>{errorMessage}</Typography>
                  </div> */}
                    </Grid>
                    :
                    <></>
            }
            {
                (props.filename == Constants.ContactCustomerFileName.FollowUp || props.filename == Constants.ContactCustomerFileName.Custom) ?
                    <Grid item xs={12} sm={12} className='inputField ' style={{ marginTop: 8 }}>
                        <div className="backdrop">
                            <div className="title">
                                <Typography>Body</Typography>
                            </div>
                            <div className="mainContainer" style={{ padding: "20px" }}>
                                <Card style={{ marginBottom: 8 }}>
                                    <CardContent style={{ padding: 4 }}>
                                        <Grid container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                            <Grid item xs={12} sm={12} style={{ padding: "8px", textAlign: "left" }}>
                                                <Typography style={{ fontSize: "13px", fontWeight: 500, paddingLeft: "0px", whiteSpace: "pre-line" }}>{props.customBody ? props.customBody : ""}</Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                        {/* <div ref={errorRef}>
                      <Typography style={{ color: "red" }}>{errorMessage}</Typography>
                  </div> */}
                    </Grid>
                    :
                    <></>
            }
            {/* <ImageZoom {...imageZoomSettings} /> */}
        </div >)
    }

    const renderFollowUpResponse = (props: any) => {
        return (
            <React.Fragment>
                <React.Fragment>
                    {renderResponse(props.data)}
                </React.Fragment>
                {props.historyFollowupsSteps.map((item: any) => renderResponse(item.data))}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {
                props.hasOwnProperty("historyFollowupsSteps")
                    ? renderFollowUpResponse(props)
                    :
                    props.data? renderResponse(props.data): renderResponse(props)
                    
            }
        </React.Fragment>
    );
};
