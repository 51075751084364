import { Button, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, Typography } from '@mui/material';
import { Slide } from '@material-ui/core';
import { TransitionProps } from '@mui/material/transitions';
import { Grid } from '@mui/material';
import { InfoIcon, CloseIcon } from "../../Subcomponent/Shared/SharedIcons";
import IconButton from '@mui/material/IconButton';
import Utils from '../../Common/Utils';
import React from 'react';
import { IImageZoomSettings } from '../../Models/IImageZoomSettings';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ImageZoom = (props:IImageZoomSettings) =>{

    const close = (event:any) => {
        event.stopPropagation();
        props.close()
    }
    return (
        <Dialog disableEnforceFocus open={props.open} maxWidth={"lg"} TransitionComponent={Transition}>
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={(event:any)=>{close(event)}}
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        color: "#D93A2F",
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            
            <DialogContent>
                <Grid container style={{display: "flex", justifyContent: "center" }}>
                    <img alt="" src={props.imageSrc} style={{maxHeight:720,maxWidth:720}}/>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}