import {LOAD_ORDER_COUNT,LOAD_DASHBOARD_ORDERS, LOAD_DASHBOARD_RESOURCED_ORDERS, LOAD_DASHBOARD_EXPEDITED_ORDERS, LOAD_DASHBOARD_PEDNINGCUSTOMER_ORDERS, LOAD_DASHBOARD_PEDNINGCUSTOMER_DECISIONS_ORDERS} from '../ActionTypes';
import Constants from "../../Common/Constants";
const initialState = {
    orderCount:{
        allOrders:0,
        readyForPacking:0,
        readyForPicking:0,
        readyForCustomerPickup:0,
        pendingReceipts:0
    },
    expedited:[],
    resourced:[],
    pendingCustomers:[],
    pendingCustomerDecisions:[]

    

}

const DashboardReducer = (state: any = initialState,
    action: { type: any; payLoad: any }) => {
    let clone = JSON.parse(JSON.stringify(state));
    switch (action.type) {
         case LOAD_ORDER_COUNT:
            return {
                ...state,
                orderCount: action.payLoad
            }
         case LOAD_DASHBOARD_ORDERS:
            return {
                ...state,
                orders: action.payLoad
            }
         case LOAD_DASHBOARD_RESOURCED_ORDERS:
            return {
                ...state,
                resourced: action.payLoad
            }
        case LOAD_DASHBOARD_EXPEDITED_ORDERS:
            return {
                ...state,
                expedited: action.payLoad
            }
        case LOAD_DASHBOARD_PEDNINGCUSTOMER_ORDERS:
            return {
                ...state,
                pendingCustomers: action.payLoad
            }
        case LOAD_DASHBOARD_PEDNINGCUSTOMER_DECISIONS_ORDERS:
            return {
                ...state,
                pendingCustomerDecisions: action.payLoad
            }

    }

    return state;
}

export default DashboardReducer;
