import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { INotificationPopupSettings, IButton } from '../../Models/INotificationPopupSettings';
import { NotificationPopup } from '../../Subcomponent/Shared/NotificationPopup'
import { CloseIcon, ArrowBackIcon } from "../../Subcomponent/Shared/SharedIcons"
import allActions from "../../Store/Actions/AllActions"
import Constants from '../../Common/Constants';
export const GenericSuccessNotification: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    let showSuccessMessage = useSelector((state: any) => state.GenericNotificationReducersState.showSuccess);
    let successMessage = useSelector((state: any) => state.GenericNotificationReducersState.successMessage);
    let isExternal = useSelector((state: any) => state.GenericNotificationReducersState.isExternal);
    const closeSuccessPopup = () => {
        if (!isExternal) {
            dispatch(allActions.genericNotificationActions.hideSuccessMessage());
        }
    }
    let successPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeSuccessPopup,
            color: Constants.Colors.red,
        },
    ];
    let successPopupButtonsExternal: IButton[] = [];
    let Success: INotificationPopupSettings = {
        open: showSuccessMessage,
        type: Constants.NotificationPopupType.Success,
        title: "Info",
        msg: successMessage,
        draggable: false,
        handleClose: closeSuccessPopup,
        actions: isExternal ? successPopupButtonsExternal : successPopupButtons
    }
    return (
        <NotificationPopup {...Success}> </NotificationPopup>
    );
};
