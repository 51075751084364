import Constants from "../Common/Constants";
import IdentityManager from "./Identity.manager";
import jwt from 'jwt-decode';
import AuditLogsManager from "./Audit.Logs.Manager";
import ApiService from "../Common/ApiService";
import logger from "../Common/logger";
import { constants } from "buffer";

export default class AuthManager {

  public static userData: any | null = null;
  public static redirectURL: string = window.location.origin;

  public static logout = async () => {

    let accessToken = IdentityManager.AccessToken;
    logger.info({ message: "LogOut Successfull", tag: Constants.LOG_TYPES.LOGOUT });
    window.localStorage.removeItem(Constants.USER_PROFILE);
    window.localStorage.removeItem(Constants.createLogin);
    window.localStorage.removeItem(Constants.UserId);
    window.localStorage.removeItem(Constants.UseDefaultEmployeeId);
    window.localStorage.removeItem(Constants.Config);
    ApiService.getSimple(`/okta/signOut?&idtoken=${accessToken}`).then((_data: any) => {
      let url: string = _data.response;
      AuthManager.reDirectOkta(url);
    });

  }

  public static auth = async () => {
    let response = await fetch(`/auth`);
    let data = await response.json();
    return data;
  }

  public static getToken = () => {
    let accessTokenString = window.location.href.split('#')[1].split("&").find(r => r.includes("access_token"));
    if (accessTokenString !== undefined) {
      let accessToken: string = accessTokenString.split("=")[1]
      let _accessTokenData: any = jwt(accessToken);
      localStorage.setItem(Constants.USER_PROFILE, JSON.stringify(_accessTokenData));
      window.location.replace(AuthManager.redirectURL);
    }
  }

  public static signin = () => {
    //
    let authUrl = new URL(window.location.href.replace(/#/g, "?"));
    let idToken = authUrl.searchParams.get("id_token");
    if (idToken && idToken.length > 0) {
      AuthManager.handleAuthCode(idToken);
    }
    else {
      ApiService.getSimple(`/okta/signin?&rdr=${AuthManager.redirectURL}`).then((data: any) => {
        let url: string = data.response;
        AuthManager.reDirectOkta(url);
      });
    }
  }

  public static reDirectOkta = (_url: any) => {

    window.location.replace(_url);

  }

  public static getDisplayNameFromOkta = (user: any) => {
    ApiService.getSimple(`/okta/getDisplayNameFromOktaByEmail?${user.preferred_username}`).then((data: any) => {
      localStorage.setItem(Constants.OKTA_PROFILE, JSON.stringify(data))

    }).catch((error: any) => {
      console.log("Okta error", error);

    });

  }

  public static handleAuthCode = async (_idtoken: string) => {

    ApiService.getSimple(`/okta/getTokenProfile?&idtoken=${_idtoken}`).then((_data: any) => {

      let userData = _data.response;
      IdentityManager.userData = userData;
      if (userData !== null && userData !== undefined) {
        // AuthManager.getDisplayNameFromOkta(userData)
        localStorage.setItem(Constants.USER_PROFILE, JSON.stringify(userData));
      }

      if (localStorage.getItem(Constants.createLogin) === undefined || localStorage.getItem(Constants.createLogin) === null) {
        logger.info({ message: "Login Successfull", tag: Constants.LOG_TYPES.LOGIN });
        localStorage.setItem(Constants.createLogin, 'true');
      }

      let _url = window.location.origin;
      window.location.replace(_url);
    });
  }

  public static verifyCredentials = async (payload: any) => {
    try {
      return await ApiService.post(`/okta/check`, payload);
    }
    catch (error) {
      console.log(error);
    }
  }
  //run bob
}