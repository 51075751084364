import React, { useState } from "react";
import {
    Grid,
    TextField,
    Typography,
} from "@mui/material";
export const ServiceNowCnfrmText: React.FC<any> = (props) => {
    return (
        <div>
            <Grid item xs={12} sm={12} >
                <p style={{ textAlign  : "justify" }}>
                    The error has already been documented in our logs.However, should you prefer to initiate a <b> ServiceNow ticket</b>  for further action, --you can simply click on the <b> Continue </b>  button.
                </p>
            </Grid>
        </div>
    );
};
