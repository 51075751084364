import { Card, CardContent, Divider, Grid, Tooltip, Typography } from "@mui/material"
import React from "react";
import { DirectionsCar, InfoOutlinedIcon, ShoppingCartCheckoutIcon } from "../Shared/SharedIcons";
import Utils from "../../Common/Utils";
import { Box, margin } from "@mui/system";
import Constants from "../../Common/Constants";

export const ReleasedOrdersTile = (props: any) => {
    const { released, pickedUp, bopiCount, bopiCountValue, bopiUsedCount, bopiUsedCountValue, sfsCount, sfsCountValue, sfsUsedCount, sfsUsedCountValue, stsCount, stsCountValue, stsUsedCount, stsUsedCountValue } = props;

    const setPercentage = (number: number) => {
        return (number * 100).toFixed(2)
    }

    const getPercentage = (value: number) => {
        if (!released) {
            return 0
        }
        return parseFloat((((value) / (released)) * 100).toFixed(2))
    }

    const getUsedBopiPercentage = (value: number) => {
        if (!bopiCount) {
            return 0
        }

        return parseFloat((((value) / (bopiCount)) * 100).toFixed(2))

    }

    const getUsedSfsPercentage = (value: number) => {
        if (!sfsCount) {
            return 0
        }

        return parseFloat((((value) / (sfsCount)) * 100).toFixed(2))

    }
    return (
        <React.Fragment>
            <Card className='dCard' >
                <CardContent className='dCardContent' style={{ padding: 0 }}>

                    <Grid container >

                        <Grid item xs={12} sm={4}>

                            <Grid container style={{ display:"flex",alignItems:"center",justifyContent:"center"}}>
                                <Grid item sm={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                    <div className="tiles-icons" style={{ backgroundColor: "rgb(255, 187, 40)" }} >
                                        <ShoppingCartCheckoutIcon />
                                    </div>
                                </Grid>
                                <Grid item sm={8} style={{ display: "grid", justifyItems: "start" }}>

                                    <p className="tiles-header-font" style={{ display: "flex", alignItems: "center" }}>
                                        Total New Orders <Tooltip title={<h3 style={{ whiteSpace: 'pre-line' }}>{Utils.getToolTipValue("TotalNewOrders") ? Utils.getToolTipValue("TotalNewOrders") : ''}</h3>}>
                                            <InfoOutlinedIcon style={{ color: Constants.Colors.blue, marginLeft: "5px" }} />
                                        </Tooltip></p>
                                    <p className="tiles-value-font">
                                        {bopiCount + sfsCount + stsCount}
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid item xs={12} sm={4} style={{ display: "flex", backgroundColor: "rgb(90, 90, 90)" }}  >
                            <Grid container style={{ alignItems: "center" }}>

                                <Grid container style={{ backgroundColor: "#eeeeee", minHeight: !Utils.isMobile ? "110px":"0px", display: "flex", justifyContent: "center", alignItems: "center", borderBottom: Utils.isMobile ? "1px solid #d3d3d3":"",paddingBottom:Utils.isMobile? "10px":"0px"}}>
                                    <Grid container style={{ marginTop: "10px", display: "flex", justifyContent: "space-around" }}>
                                        <Grid item sm={5}>
                                            {/* <Box> */}
                                            <div style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}>
                                                <Tooltip title={<h3 style={{ whiteSpace: 'pre-line' }}>{Utils.getToolTipValue("SFS") ? Utils.getToolTipValue("SFS") : ''}</h3>}>
                                                    <InfoOutlinedIcon style={{ color: Constants.Colors.blue, marginRight: "5px" }} />
                                                </Tooltip>
                                                <Typography style={{ color: "black", fontSize: "12px" }}>
                                                    <span> SFS - <b>{`${sfsCount}`}</b></span>
                                                </Typography>
                                            </div>

                                            {/* </Box> */}
                                        </Grid>
                                        <Grid item sm={7} style={{ textAlign: "right" }}>
                                            {/* <Box> */}
                                            <Typography style={{ color: "black", fontWeight: "bold", marginRight: "5px", fontSize: "12px" }}>{'$'}{Utils.nFormatter(sfsCountValue, 2)}{`(${getPercentage(sfsCount)}%)`} </Typography>
                                            {/* </Box> */}
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ display: "flex", justifyContent: "space-around", marginTop:Utils.isMobile?"5px":"0px" }}>
                                        <Grid item sm={6}>
                                            {/* <Box> */}
                                            <div style={{ float: "left", marginLeft: "10px" }}>
                                                <Typography style={{ color: "black", fontSize: "12px" }}>Used Items - <b>{`${sfsUsedCount}`} </b>  </Typography>
                                            </div>
                                            {/* </Box> */}
                                        </Grid>
                                        <Grid item sm={6} style={{ textAlign: "right" }}>
                                            {/* <Box> */}
                                            <Typography style={{ color: "black", fontWeight: "bold", marginRight: "5px", fontSize: "12px" }}>{'$'}{Utils.nFormatter(sfsUsedCountValue, 2)} {`(${getUsedSfsPercentage(sfsUsedCount)}%)`}</Typography>
                                            {/* </Box> */}
                                        </Grid>
                                    </Grid>

                                </Grid>

                            </Grid>

                        </Grid>

                        <Grid item xs={12} sm={4} style={{ display: "flex", backgroundColor: "rgb(90, 90, 90)" }}  >
                            <Grid container style={{ alignItems: "center" }}>

                                <Grid container style={{ backgroundColor: "#eeeeee", minHeight: !Utils.isMobile ? "110px":"0px", display: "flex", justifyContent: "center", alignItems: "center", borderLeft: !Utils.isMobile? "1px solid #d3d3d3":"",paddingBottom:Utils.isMobile? "10px":"0px" }}>

                                    {/* {BOPIS TOTAL} */}
                                    <Grid container style={{ marginTop: "10px", display: "flex", justifyContent: "space-around",alignItems:"center"}}>
                                        <Grid item sm={5}>
                                            <div style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}>
                                                <Tooltip title={<h3 style={{ whiteSpace: 'pre-line' }}>{Utils.getToolTipValue("BOPIS") ? Utils.getToolTipValue("BOPIS") : ''}</h3>}>
                                                    <InfoOutlinedIcon style={{ color: Constants.Colors.blue, marginRight: "2px" }} />
                                                </Tooltip>
                                                <Typography style={{ color: "black", fontSize: "12px" }}>
                                                    <span>BOPIS - <b>{`${bopiCount + stsCount}`}</b></span>
                                                </Typography>
                                            </div>

                                            {/* </Box> */}
                                        </Grid>
                                        <Grid item sm={7} style={{ textAlign: "right" }}>
                                            {/* <Box> */}
                                            <Typography style={{ color: "black", fontWeight: "bold", marginRight: "5px", fontSize: "12px" }}>{'$'}{Utils.nFormatter(bopiCountValue + stsCountValue, 2)}{`(${bopiCount ? setPercentage((bopiCount + stsCount) / released) : 0}%)`} </Typography>
                                            {/* </Box> */}
                                        </Grid>
                                    </Grid>
                                    {/* STS BOPIS SUB SECTION */}
                                    <Grid container style={{ marginTop: "5px", display: "flex", justifyContent: "space-around",alignItems:"center",  marginLeft: "10px" }}>
                                        <Grid item sm={5}>
                                            <div style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}>
                                                <Tooltip title={<h3 style={{ whiteSpace: 'pre-line' }}>{Utils.getToolTipValue("STS") ? Utils.getToolTipValue("STS") : ''}</h3>}>
                                                    <InfoOutlinedIcon style={{ color: Constants.Colors.blue, marginRight: "2px" }} />
                                                </Tooltip>
                                                <Typography style={{ color: "black", fontSize: "12px" }}>
                                                    <span>STS - <b>{`${stsCount}`}</b></span>
                                                </Typography>
                                            </div>
                                            {/* </Box> */}
                                        </Grid>
                                        <Grid item sm={7} style={{ textAlign: "right" }}>
                                            {/* <Box> */}
                                            <Typography style={{ color: "black", fontWeight: "bold", marginRight: "5px", fontSize: "12px" }}>{'$'}{Utils.nFormatter(stsCountValue, 2)}{`(${stsCount ? setPercentage(stsCount / (stsCount + bopiCount)) : 0}%)`} </Typography>
                                            {/* </Box> */}
                                        </Grid>
                                    </Grid>
                                    {/* {BOPIS Used Items} */}
                                    <Grid container style={{ display: "flex", justifyContent: "space-around", alignItems:"center", marginLeft: "15px",marginTop:Utils.isMobile?"3px":"0px"}}>
                                        <Grid item sm={6}>

                                            <div style={{ float: "left", marginLeft: "10px" }}>
                                                <Typography style={{ color: "black", fontSize: "12px" }}>Used Items - <b>{`${stsUsedCount + bopiUsedCount}`}  </b>  </Typography>
                                            </div>

                                        </Grid>
                                        <Grid item sm={6} style={{ textAlign: "right" }}>
                                            {/* <Box> */}
                                            <Typography style={{ color: "black", fontWeight: "bold", marginRight: "5px", fontSize: "12px" }}>{'$'}{Utils.nFormatter((stsUsedCountValue + bopiUsedCountValue), 2)} {`(${(bopiCount || stsCount) ? setPercentage((stsUsedCount + bopiUsedCount) / (bopiCount + stsCount)) : 0}%)`}</Typography>
                                            {/* </Box> */}
                                        </Grid>
                                    </Grid>



                                    {/* {Used Items} */}
                                    {/* <Grid container style={{ display: "flex", justifyContent: "space-around", marginLeft: "15px" }}>
                                        <Grid item sm={6}>

                                            <div style={{ float: "left", marginLeft: "10px" }}>
                                                <Typography style={{ color: "white", fontSize: "12px" }}>Used Items - <b>{`${stsUsedCount}`}  </b>  </Typography>
                                            </div>

                                        </Grid>
                                        <Grid item sm={6} style={{ textAlign: "right" }}>
                                            <Typography style={{ color: "white", fontWeight: "bold", marginRight: "5px", fontSize: "12px" }}>{'$'}{Utils.nFormatter(stsUsedCountValue, 2)} {`(${stsUsedCount ? setPercentage(stsUsedCount / (bopiUsedCount + stsUsedCount)) : 0}%)`}</Typography>
                                        </Grid>
                                    </Grid> */}

                                    {/* SSPU BOPIS SUB SECTION */}
                                    {/* <Grid container style={{ display: "flex", justifyContent: "space-around" }}>
                                        <Grid item sm={5}>
                                            <div style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}>
                                                <Tooltip title={<h3 style={{ whiteSpace: 'pre-line' }}>{Utils.getToolTipValue("SSPU") ? Utils.getToolTipValue("SSPU") : ''}</h3>}>
                                                    <InfoOutlinedIcon style={{ color: Constants.Colors.blue, marginRight: "2px" }} />
                                                </Tooltip>
                                                <Typography style={{ color: "white", fontSize: "14px" }}>
                                                    <span>SSPU - <b>{`${stsCount}`}</b></span>
                                                </Typography>
                                            </div>

                                        </Grid>
                                        <Grid item sm={7} style={{ textAlign: "right" }}>

                                            <Typography style={{ color: "white", fontWeight: "bold", marginRight: "5px", fontSize: "14px" }}>{'$'}{Utils.nFormatter(bopiCountValue, 2)}{`( ${bopiCount ? getPercentage(bopiCount) : 0}%)`} </Typography>

                                        </Grid>
                                    </Grid> */}

                                    {/* {Used Items} */}
                                    {/* <Grid container style={{ display: "flex", justifyContent: "space-around", marginLeft: "10px" }}>
                                        <Grid item sm={6}>
                                            <div style={{ float: "left", marginLeft: "10px" }}>
                                                <Typography style={{ color: "white", fontSize: "14px" }}>Used Items - <b>{`${bopiUsedCount}`}  </b>  </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item sm={6} style={{ textAlign: "right" }}>

                                            <Typography style={{ color: "white", fontWeight: "bold", marginRight: "5px", fontSize: "14px" }}>{'$'}{Utils.nFormatter(bopiUsedCountValue, 2)} {`(${bopiUsedCount ? setPercentage(bopiUsedCount / (bopiUsedCount + stsUsedCount)) : 0}%)`}</Typography>
                                        </Grid>
                                    </Grid> */}

                                </Grid>

                            </Grid>

                        </Grid>

                    </Grid>
                </CardContent>
            </Card>

        </React.Fragment>);
}






















// import { Card, CardContent, Grid} from "@mui/material"
// import React from "react";
// import { DirectionsCar, ShoppingCartCheckoutIcon} from "../Shared/SharedIcons";

// export const ReleasedOrdersTile = (props: any) => {
//     const { released, pickedUp } = props;

//     return (
//         <React.Fragment>
//             <Card className='dCard' >
//                 <CardContent className='dCardContent' style={{ padding: 0 }}>
//                     <Grid container>
//                         <Grid item xs={6} sm={6} >
//                             <Grid container>
//                                 <Grid item sm={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
//                                     <div className="tiles-icons" style={{ backgroundColor: "rgb(255, 187, 40)" }}  >
//                                         <ShoppingCartCheckoutIcon />
//                                     </div>
//                                 </Grid>
//                                 <Grid item sm={8} style={{ display: "grid", justifyItems: "start" }}>
//                                     <p className="tiles-header-font">Released</p>
//                                     <p className="tiles-value-font">
//                                         {released}
//                                     </p>
//                                 </Grid>
//                             </Grid>
//                         </Grid>
//                         <Grid item xs={6} sm={6}>
//                             <Grid container>
//                                 <Grid item sm={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
//                                     <div className="tiles-icons" style={{ backgroundColor: "rgb(255, 128, 66)" }} >
//                                         <DirectionsCar />
//                                     </div>
//                                 </Grid>
//                                 <Grid item sm={8} style={{ display: "grid", justifyItems: "start" }}>
//                                     <p className="tiles-header-font">Picked up</p>
//                                     <p className="tiles-value-font">
//                                         {pickedUp}
//                                     </p>
//                                 </Grid>
//                             </Grid>

//                         </Grid>
//                     </Grid>
//                 </CardContent>
//             </Card>

//         </React.Fragment>);
// }