import { CHANGE_ORDER_LINES, CHANGE_CUSTOMER_DETAIL, SET_CUSTOMERPICKUP_ORDER_DETAILS, CHANGE_SELECTED_ITEMS } from "../ActionTypes";
const changeOrderLines = (obj: any) => {
    return {
        type: CHANGE_ORDER_LINES,
        payLoad: obj
    };
}
const changeCustomerDetails = (obj: any) => {
    return {
        type: CHANGE_CUSTOMER_DETAIL,
        payLoad: obj
    }
}

const setOrderDetails = (obj: any) => {

    return {
        type: SET_CUSTOMERPICKUP_ORDER_DETAILS,
        payLoad: obj
    }

}
const changeSelectedItem = (obj: any) => {
    return {
        type: CHANGE_SELECTED_ITEMS,
        payLoad: obj
    }

}


const customerPickupActions = {
    changeOrderLines,
    changeCustomerDetails,
    setOrderDetails,
    changeSelectedItem
}

export default customerPickupActions;