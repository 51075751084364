import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import "./ContactCustomer.scss";
import {
    Button,
    FormControl,
    Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import Utils from "../../Common/Utils";
import allActions from "../../Store/Actions/AllActions";
import { SaveIcon, CloseIcon } from "./SharedIcons";
import ApiService from "../../Common/ApiService";
import Constants from "../../Common/Constants";
import IdentityManager from "../../Managers/Identity.manager";
import OrderManager from "../../Managers/Orders.Manager";
import { IButton, INotificationPopupSettings } from "../../Models/INotificationPopupSettings";
import { NotificationPopup } from "./NotificationPopup";
import { TailSpin } from "react-loader-spinner";
import { NotificationsActive } from "@mui/icons-material";
import Done from "@mui/icons-material/Done";
import PFManager from "../../Managers/Process.Fulfillment.Manager";
import LabelText from "../../Common/LabelText";
var moment = require('moment');
export const ReportErrorPopup: React.FC<any> = (props: any) => {

    // Get Params From URL 
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const orderNoFromUrl = urlParams.get('orderno');
    const shipmentkeyFromUrl = urlParams.get('shipmentkey');

    const { errorMessage, closeErrorReportPopup, urlPathname, handleErrorAlreadyReported, reportErrorAction } = props
    // ********Use State Section ********//
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openGeneralError, setOpenGeneralError] = useState(false);
    const [errorGeneralMsg, setErrorGeneralMsg] = useState("");
    const [serviceNowLoader, setServiceNowLoader] = useState(false)
    const [successMsg, setSuccessMsg] = useState("")
    const [snowTicketNumber, setSnowTicketNumber] = useState("")
    const [showHtml, setShowHtml] = useState(false)
    const [openAlreadyReportedError, setOpenAlreadyReportedError] = useState(false);
    const [systemWideError, setSystemWideError] = useState(false);

    const loaderLeft = Utils.isMobile ? "42%" : "48%";

    // Change Name for URL Path name
    const changeURLPathName = (url: string) => {
        if (url.toLowerCase().includes("pickorder")) {
            return "Pick Screen"
        }
        else if (url.toLowerCase().includes("pack")) {
            return "Pack Screen"
        }
        else if (url.toLowerCase().includes("pickup")) {
            return "PickUp Screen"
        }
        else if (url.toLowerCase().includes("pendingreceipt")) {
            return "Pending Receipt Screen"
        }
        else if (url.toLowerCase().includes("orderdetail")) {
            return "Order Detail Screen"
        }
        else {
            return url
        }

    }

    React.useEffect(() => {
        async function isErrorReported() {
            await checkErrorIsReported()
        }
        isErrorReported()

        let shortDes = ""
        if (orderNoFromUrl) {
            shortDes = "Order #" + orderNoFromUrl + ": Error Occurred on " + changeURLPathName(urlPathname?.substr(1))
        } else {
            shortDes = "Error Occurred on " + changeURLPathName(urlPathname?.substr(1))
        }

        dispatch(allActions.reportErrorServiceNowActions.changeReportDescription(""));
        dispatch(allActions.reportErrorServiceNowActions.changeReportImpact("low"));
        dispatch(allActions.reportErrorServiceNowActions.changeReportPriority("low"));
        dispatch(allActions.reportErrorServiceNowActions.changeReportShortDescription(shortDes));
    }, []);

    const dispatch = useDispatch();

    let description = useSelector((state: any) => state.ReportErrorReducersState.description);
    let shortDescription = useSelector((state: any) => state.ReportErrorReducersState.shortDescription);
    let priorType = useSelector((state: any) => state.ReportErrorReducersState.priority);
    let impactType = useSelector((state: any) => state.ReportErrorReducersState.impact);
    let urgency = useSelector((state: any) => state.ReportErrorReducersState.urgency);
    let store = OrderManager.getStore()
    let upn = IdentityManager.getUserProperty(Constants.Preferred_UserName)
    let empId = IdentityManager.getUserProperty(Constants.EmployeeID)
    let IdsOptions = [{ value: "High", key: "high" }, { value: "Medium", key: "medium" }, { value: "Low", key: "low" }];


    // *********** PopUp Section ************


    // General Error Pop up
    const closeGeneralErrorModel = () => {
        closeErrorReportPopup()
        setOpenGeneralError(false);
        setErrorGeneralMsg("");
    }
    let errorGeneralPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeGeneralErrorModel,
            // color: Constants.Colors.red,
            color: Constants.Colors.white,
            border: Constants.Colors.red,
            textColor: Constants.Colors.red
        }
    ];

    let GeneralError: INotificationPopupSettings = {
        open: openGeneralError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: "Something went wrong",
        draggable: false,
        handleClose: closeGeneralErrorModel,
        actions: errorGeneralPopupButtons
    }

    const closeAlreadyReportedErrPopup = () => {
        handleErrorAlreadyReported()
        setOpenAlreadyReportedError(false);

    }

    // Already Reporeted Pop up
    let alreadyReportedErrPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeAlreadyReportedErrPopup,
            // color: Constants.Colors.red,
            color: Constants.Colors.white,
            border: Constants.Colors.red,
            textColor: Constants.Colors.red
        }
    ];
    let AlreadyReportedError: INotificationPopupSettings = {
        open: openAlreadyReportedError,
        type: Constants.NotificationPopupType.Conformation,
        title: Constants.MESSAGES.ALREADY_REPORTED_SN_TITLE,
        msg: "Ticket #" + snowTicketNumber + ": This error has already been reported to ServiceNow.",
        isCustomJSX: false,
        draggable: false,
        handleClose: closeAlreadyReportedErrPopup,
        actions: alreadyReportedErrPopupButtons
    }

    // Description Event
    const changeDescription = (event: any) => {
        dispatch(allActions.reportErrorServiceNowActions.changeReportDescription(event.target.value));
    }

    // Short Description Event
    const changeShortDescription = (event: any) => {
        dispatch(allActions.reportErrorServiceNowActions.changeReportShortDescription(event.target.value));
    }

    // Urgency Event 
    const changeUrgency = (event: any) => {
        dispatch(allActions.reportErrorServiceNowActions.changeReportUrgency(event.target.value));
    }

    // Impact Event 
    const changeImpact = (event: any) => {
        dispatch(allActions.reportErrorServiceNowActions.changeReportImpact(event.target.value));
    }

    // Prority Event 
    const changePriority = (event: any) => {
        dispatch(allActions.reportErrorServiceNowActions.changeReportPriority(event.target.value));
    }

    // check error message from OMS

    const compareFirstAndLastSubstrings = (str1: string, str2: string) => {

        // if both strings are same return true
        if (str1 === str2) {
            return true
        }

        if (str1.length > 0 && str2.length > 0) {

            // Count occurrences of '|' in both strings

            const countStr1 = (str1.match(/\|/g) || []).length;

            const countStr2 = (str2.match(/\|/g) || []).length;

            // Check if '|' occurs 3 times in both strings

            if (countStr1 === 3 || countStr2 === 3) {

                // Split both strings at '|'

                const substringsStr1 = str1.split('|');

                const substringsStr2 = str2.split('|');

                // Check if first and last substrings are the same

                if (substringsStr1[0].toLowerCase().trim() === substringsStr2[0].toLowerCase().trim() && substringsStr1.slice(-1)[0].toLowerCase().trim() === substringsStr2.slice(-1)[0].toLowerCase().trim()) {

                    return true;

                } else {

                    return false;

                }

            } else {


                return str1.toLowerCase().trim() === str2.toLowerCase().trim();

            }

        } else {

            return false

        }

    }
    // Check if Record already Exist in Elastic  
    const checkErrorIsReported = async () => {

        let reportErrorHistory: any = [];
        let isErrorAlreadyReported: boolean = false;
        await ApiService.get("/pf/getReportErrorHistory?shipmentKey=" + shipmentkeyFromUrl + "&orderNo=" + orderNoFromUrl + "&URL=" + urlPathname + "&upn=" + IdentityManager.getUserProperty(Constants.Preferred_UserName)).then((responseReportErrorHistory: any) => {
            reportErrorHistory = responseReportErrorHistory?.data?.hits?.hits;
            return reportErrorHistory;
        });

        if (reportErrorHistory?.length > 0) {
            for (const item of reportErrorHistory) {

                if (
                    item?._source?.shipmentKey === shipmentkeyFromUrl &&
                    item?._source?.orderno === orderNoFromUrl &&
                    compareFirstAndLastSubstrings(item?._source?.message?.toLowerCase()?.trim(), errorMessage?.toLowerCase()?.trim()) &&
                    item?._source?.reportErrorAction?.toLowerCase().trim() === reportErrorAction?.toLowerCase().trim() &&
                    item?._source?.store?.toLowerCase().trim() === OrderManager.getStore()
                ) {
                    isErrorAlreadyReported = true;
                    if (isErrorAlreadyReported) {
                        setOpenSuccess(false);
                        setServiceNowLoader(false);
                        setSnowTicketNumber(item?._source?.ticketNumber)
                        setOpenAlreadyReportedError(true)

                    }
                }
            }

        }
        let systemWideError =  await systemWideErrorPopUp();
        setServiceNowLoader(false);
        if(systemWideError && !isErrorAlreadyReported)
        {
            setSystemWideError(true);
        }
        else if (!isErrorAlreadyReported) {
            setShowHtml(true)
        }


        return isErrorAlreadyReported
    }

    const systemWideErrorPopUp = async () => {
        try {
            let payload = {
                error: errorMessage
            };
            const result = await PFManager.systemWideError(payload);

            if (result.success && result.data) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.log("systemWideError", error);
            return false;
        }
    };

    //handle save Error Report function
    const handleSaveReportError = async () => {
        setServiceNowLoader(true);
        // Save Report Error Object
        const reportErrorDataObj = {
            description: description + "\n" + "-----------------------------------" + "\n" + (errorMessage ?? ""),
            shortDescription,
            priorType,
            impactType,
            urgency,
            store,
            upn,
            empId
        }
        let reportErrorHistory: any = []
        let isErrorAlreadyReported: boolean = false;
        try {
            await ApiService.get("/pf/getReportErrorHistory?shipmentKey=" + shipmentkeyFromUrl + "&orderNo=" + orderNoFromUrl + "&URL=" + urlPathname + "&upn=" + IdentityManager.getUserProperty(Constants.Preferred_UserName)).then((responseReportErrorHistory: any) => {
                reportErrorHistory = responseReportErrorHistory?.data?.hits?.hits;
                return reportErrorHistory;
            });

            if (reportErrorHistory?.length > 0) {
                for (const item of reportErrorHistory) {

                    if (
                        item?._source?.shipmentKey === shipmentkeyFromUrl &&
                        item?._source?.orderno === orderNoFromUrl &&
                        compareFirstAndLastSubstrings(item?._source?.message?.toLowerCase().trim(), errorMessage?.toLowerCase().trim()) &&
                        item?._source?.reportErrorAction?.toLowerCase().trim() === reportErrorAction?.toLowerCase().trim() &&
                        item?._source?.store?.toLowerCase().trim() === OrderManager.getStore()
                    ) {
                        
                        isErrorAlreadyReported = true;
                    }
                }
                if (isErrorAlreadyReported) {
                    setServiceNowLoader(false);
                    handleErrorAlreadyReported()

                } else {
                    try {
                        // Save Report Error Payload
                        const reportErrorPaylaod = await OrderManager.reportErrorToServiceNow(reportErrorDataObj)
                        setServiceNowLoader(false);
                        if (reportErrorPaylaod?.success) {
                            setSuccessMsg("Ticket #" + ((reportErrorPaylaod?.data?.result?.number) ?? " --- ") + ": Error Reported in ServiceNow.The IT team will look into it further.")
                            // Save History for Error Report
                            const orderHistoryResponse = await OrderManager.saveOrderHistory({
                                shipmentKey: shipmentkeyFromUrl,
                                orderNo: orderNoFromUrl,
                                logType: Constants.reportErrorHistory,
                                message: errorMessage,
                                URL: urlPathname,
                                transactionDate: moment.utc(moment.tz("America/Los_Angeles")).toISOString(),
                                reportErrorAction,
                                shortDescription,
                                description,
                                ticketNumber: reportErrorPaylaod?.data?.result?.number,
                                store
                            });

                            if (orderHistoryResponse?.success) {
                                setOpenSuccess(true)
                            }
                        } else {
                            closeErrorReportPopup()
                            setOpenGeneralError(true);
                            setErrorGeneralMsg("Something went wrong")
                        }


                    } catch (error) {
                        setServiceNowLoader(false);
                        closeErrorReportPopup()
                        setOpenGeneralError(true);
                        setErrorGeneralMsg(error as string)
                    }

                }
            } else {

                try {
                    // Report Error Service Now (Call API)
                    const reportErrorToServiceNowResponse = await OrderManager.reportErrorToServiceNow(reportErrorDataObj)

                    setSuccessMsg("Ticket #" + ((reportErrorToServiceNowResponse?.data?.result?.number) ?? " --- ") + ": Error Reported in ServiceNow.The IT team will look into it further.")
                    setServiceNowLoader(false);
                    if (reportErrorToServiceNowResponse?.success) {

                        // Save Report Error History
                        const orderHistoryResponse = await OrderManager.saveOrderHistory({
                            shipmentKey: shipmentkeyFromUrl,
                            orderNo: orderNoFromUrl,
                            logType: Constants.reportErrorHistory,
                            message: errorMessage,
                            URL: urlPathname,
                            transactionDate: moment.utc(moment.tz("America/Los_Angeles")).toISOString(),
                            reportErrorAction,
                            shortDescription,
                            description,
                            ticketNumber: reportErrorToServiceNowResponse?.data?.result?.number,
                            store
                        });

                        if (orderHistoryResponse?.success) {
                            setOpenSuccess(true)
                        }
                    } else {
                        setOpenGeneralError(true);
                        setErrorGeneralMsg("Something went wrong")
                    }


                } catch (error) {
                    setServiceNowLoader(false);
                    setOpenGeneralError(true);
                    setErrorGeneralMsg(error as string)
                }

            }
        } catch (error) {
            setServiceNowLoader(false);
            setOpenGeneralError(true);
            setErrorGeneralMsg(error as string)
        }
    }

    const renderWebDetails = () => {

        return <React.Fragment>

            {!serviceNowLoader && showHtml ?
                <>
                    <div className="" style={{ padding: "5px" }}>

                        <Grid container xs={12} sm={12} md={12} style={{ display: "flex", justifyContent: "space-between" }}>


                            <Grid item xs={12} sm={12} className='inputField' style={{ display: "flex", justifyContent: "center" }}>
                                <Typography fontWeight={700} display="inline">
                                    Associate Email: <Typography display="inline" >{IdentityManager.getUserProperty(Constants.Preferred_UserName)}</Typography>
                                </Typography>

                            </Grid>

                        </Grid>


                        <Grid container xs={12} sm={12} md={12} style={{ display: "flex", justifyContent: "space-between" }}>
                            {Utils.getConfigurationWithKey(Constants.Configurations.ReportErrorSNPriorityFlag) &&

                                <Grid item xs={5} sm={5} className='inputField'>
                                    <Typography>
                                        Priority
                                    </Typography>
                                    <FormControl>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={priorType}
                                            onChange={(event: SelectChangeEvent) => {
                                                changePriority(event)
                                            }}
                                            variant='outlined'
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            {
                                                IdsOptions.map((val: any) => {

                                                    return <MenuItem value={val.key}>{val.value}</MenuItem>

                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }

                            {Utils.getConfigurationWithKey(Constants.Configurations.ReportErrorSNImpactFlag) &&

                                <Grid item xs={5} sm={5} className='inputField'>
                                    <Typography>
                                        Impact
                                    </Typography>
                                    <FormControl>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={impactType}
                                            onChange={(event: SelectChangeEvent) => {
                                                changeImpact(event)
                                            }}
                                            variant='outlined'
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            {
                                                IdsOptions.map((val: any) => {

                                                    return <MenuItem value={val.key}>{val.value}</MenuItem>

                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }
                        </Grid>
                        {Utils.getConfigurationWithKey(Constants.Configurations.ReportErrorSNUrgencyFlag) &&

                            <Grid item xs={12} sm={12} className='inputField' >
                                <Typography>
                                    Urgency
                                </Typography>
                                <TextField onChange={(event: any) => { changeUrgency(event) }} fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Urgency' variant="outlined" value={urgency} />
                            </Grid>
                        }

                        <Grid item xs={12} sm={12} className='inputField'>
                            <Typography>
                                Short Description
                            </Typography>
                            <TextField onChange={(event: any) => { changeShortDescription(event) }} fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Short Description' variant="outlined" value={shortDescription} />
                        </Grid>
                        <Grid item xs={12} sm={12} className='inputField'>
                            <Typography>
                                Description (Additional details optional)
                            </Typography>
                            <TextField inputProps={{ maxLength: 500 }} className="padding-0" onChange={(event) => { changeDescription(event) }} multiline fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Description' variant="outlined" value={description}>
                                {description}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} className='inputField'>
                            <Typography>
                                Error Message
                            </Typography>
                            <TextField disabled className="padding-0" multiline fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" variant="outlined" value={errorMessage}>

                            </TextField>
                        </Grid>
                    </div >
                    <div style={{ padding: 5, display: "flex", justifyContent: "flex-end" }}>
                        <div style={{ padding: 5, display: "flex", justifyContent: "space-between" }}>
                            <Button style={{ whiteSpace: 'nowrap', backgroundColor: "#D93A2F", color: "white", marginLeft: 5 }} onClick={handleSaveReportError} variant="contained" startIcon={<SaveIcon></SaveIcon>} >
                                {"Create SNOW Ticket"}
                            </Button>

                            <Button style={{ whiteSpace: 'nowrap', backgroundColor: "#D93A2F", color: "white", marginLeft: 5 }} onClick={closeErrorReportPopup} variant="contained" startIcon={<CloseIcon></CloseIcon>} >
                                {"Close"}
                            </Button>
                        </div>
                    </div>
                </>
                :
                (
                    <div style={{ position: "absolute", top: "42%", left: loaderLeft }}>
                        <TailSpin color="#D93A2F" height={80} width={80} />
                    </div>
                )
            }
        </React.Fragment>

    }

    const renderMobileDetails = () => {

        return <React.Fragment>
            {!serviceNowLoader && showHtml ?

                <div className="mainContainer" style={{ padding: "0px" }}>
                    <Grid container xs={12} sm={12} md={12} style={{ display: "flex", justifyContent: "space-between" }}>


                        <Grid item xs={12} sm={12} className='inputField' style={{ display: "flex", justifyContent: "flex-start" }}>
                            <Typography fontWeight={700} display="inline">
                                Associate Email: <Typography display="inline" >{IdentityManager.getUserProperty(Constants.Preferred_UserName)}</Typography>
                            </Typography>

                        </Grid>

                    </Grid>

                    <br></br>


                    <Grid container xs={12} sm={12} md={12} style={{ display: "flex", justifyContent: "space-between" }}>

                        {Utils.getConfigurationWithKey(Constants.Configurations.ReportErrorSNPriorityFlag) &&

                            <Grid item xs={5} sm={5} className='inputField'>
                                <Typography>
                                    Priority
                                </Typography>
                                <FormControl>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={priorType}
                                        onChange={(event: SelectChangeEvent) => {
                                            changePriority(event)
                                        }}
                                        variant='outlined'
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {
                                            IdsOptions.map((val: any) => {

                                                return <MenuItem value={val.key}>{val.value}</MenuItem>

                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        }

                        {Utils.getConfigurationWithKey(Constants.Configurations.ReportErrorSNUrgencyFlag) &&

                            <Grid item xs={5} sm={5} className='inputField'>
                                <Typography>
                                    Impact
                                </Typography>
                                <FormControl>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={impactType}
                                        onChange={(event: SelectChangeEvent) => {
                                            changeImpact(event)
                                        }}
                                        variant='outlined'
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {
                                            IdsOptions.map((val: any) => {

                                                return <MenuItem value={val.key}>{val.value}</MenuItem>

                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                    </Grid>

                    <Grid item xs={12} sm={12} className='inputField' style={{ display: "none" }}>
                        <Typography>
                            Urgency
                        </Typography>
                        <TextField onChange={(event: any) => { changeUrgency(event) }} fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Urgency' variant="outlined" value={urgency} />
                    </Grid>
                    <Grid item xs={12} sm={12} className='inputField'>
                        <Typography>
                            Short Description
                        </Typography>
                        <TextField onChange={(event: any) => { changeShortDescription(event) }} fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Short Description' variant="outlined" value={shortDescription} />
                    </Grid>
                    <Grid item xs={12} sm={12} className='inputField'>
                        <Typography>
                            Description (Additional details optional)
                        </Typography>
                        <TextField className="padding-0" onChange={(event) => { changeDescription(event) }} multiline fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Description' variant="outlined" value={description}>
                            {description}
                        </TextField>
                    </Grid>
                    <div style={{ padding: 5, display: "flex", justifyContent: "flex-end" }}>
                        <div style={{ padding: 5, display: "flex", justifyContent: "space-between" }}>
                            <Button style={{ whiteSpace: 'nowrap', backgroundColor: "#D93A2F", color: "white", marginLeft: 5 }} onClick={handleSaveReportError} variant="contained" startIcon={<SaveIcon></SaveIcon>} >
                                {"Create Ticket"}
                            </Button>

                            <Button style={{ whiteSpace: 'nowrap', backgroundColor: "#D93A2F", color: "white", marginLeft: 5 }} onClick={closeErrorReportPopup} variant="contained" startIcon={<CloseIcon></CloseIcon>} >
                                {"Close"}
                            </Button>
                        </div>
                    </div>
                </div >
                :
                (
                    <div style={{ position: "absolute", top: "42%", left: loaderLeft }}>
                        <TailSpin color="#D93A2F" height={Utils.isMobile? 50 :80} width={Utils.isMobile? 50:80} />
                    </div>
                )
            }
        </React.Fragment>

    }

    const alreadyReportedErrorComponent = () => {

        return (<>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px", marginBottom: "8px" }} >

                <div className="popupHeader" style={{ background: "#1976D2" }}>

                    <NotificationsActive style={{ color: "white" }} ></NotificationsActive>

                    <div>
                        <Typography fontWeight={700} style={{ fontSize: 18, color: "white" }} >Reported Incident</Typography>
                    </div>
                </div>
            </div>
            <Typography style={{ fontSize: 18 }}>{"Ticket #" + (snowTicketNumber ?? " --- ") + ": This error has already been reported to ServiceNow."}</Typography>
            <div style={{ padding: 5, display: "flex", justifyContent: "flex-end" }}>
                <div style={{ padding: 5, display: "flex", justifyContent: "space-between" }}>
                    <Button style={{ whiteSpace: 'nowrap', backgroundColor: "#D93A2F", color: "white", marginLeft: 5 }} onClick={closeErrorReportPopup} variant="contained" startIcon={<CloseIcon></CloseIcon>} >
                        {"Close"}
                    </Button>
                </div>
            </div>
        </>)
    }

    const successComponent = () => {

        return (<>

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px", marginBottom: "8px" }} >

                <div className="popupHeader">
                    <Done style={{ color: "white" }}></Done>

                    <div>
                        <Typography fontWeight={700} style={{ fontSize: 18, color: "white" }} >Success</Typography>
                    </div>
                </div>
            </div>
            <Typography style={{ fontSize: 18 }}>{successMsg}</Typography>
            <div style={{ padding: 5, display: "flex", justifyContent: "flex-end" }}>
                <div style={{ padding: 5, display: "flex", justifyContent: "space-between" }}>
                    <Button style={{ whiteSpace: 'nowrap', backgroundColor: "#D93A2F", color: "white", marginLeft: 5 }} onClick={closeErrorReportPopup} variant="contained" startIcon={<CloseIcon></CloseIcon>} >
                        {"Close"}
                    </Button>
                </div>
            </div>
        </>)
    }

    const systemWideErrorComponent = () => {
        return <>
                <Typography style={{ fontSize: 18 }}>{LabelText.systemWideErrorMessage}</Typography>
                <div style={{ padding: 5, display: "flex", justifyContent: "flex-end" }}>
                    <div style={{ padding: 5, display: "flex", justifyContent: "space-between" }}>
                        <Button style={{ whiteSpace: 'nowrap', backgroundColor: "#D93A2F", color: "white", marginLeft: 5 }} onClick={closeErrorReportPopup} variant="contained" startIcon={<CloseIcon></CloseIcon>} >
                            {"Close"}
                        </Button>
                    </div>
                </div>
            </>
    }

    const renderServiceNowForm = () => {
        return (
            <> {Utils.isMobile ? renderMobileDetails() : renderWebDetails()}</>
        )
    }

    return (
        <div>

            {<NotificationPopup {...GeneralError}></NotificationPopup>}
            {systemWideError ? 
                <>
                  {systemWideErrorComponent()}
                </>:
                <>
                </>
            }
            {openAlreadyReportedError ?
                <>{alreadyReportedErrorComponent()}</>
                :
                <></>
            }
            {openSuccess ?
                <>{successComponent()}</>
                :
                <></>
            }
            {
                openAlreadyReportedError == false && openSuccess === false && !systemWideError ?
                    <>{renderServiceNowForm()}</>
                    :
                    <></>
            }
        </div>
    );
};


