import DOMPurify from 'dompurify';
const ExtendedDescPopup = (props: any) => {
    const sanitizedDesc = DOMPurify?.sanitize(props?.desc) ?? "";
    return (
        <>
           <div dangerouslySetInnerHTML={{ __html: sanitizedDesc }} />
        </>
    )
}

export default ExtendedDescPopup



