import React, { useState } from "react";
import "./ContactCustomer.scss";
import {
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { PersonIcon, AddLocationAltIcon, EmailIcon, LocalPhoneIcon, LocationOnIcon } from "./SharedIcons";
import { CardContent } from "@material-ui/core";
import Utils from "../../Common/Utils";
import Constants from "../../Common/Constants";
export const CustomerDetails: React.FC<any> = (props: any) => {

    const { customerDetail } = props
    console.log(customerDetail, "customerDetail");

    const renderWebDetails = () => {

        return <React.Fragment>
            {

                <div>
                    <Grid container spacing={2} style={{ padding: "10px" }}>
                        <Grid item xs={12} sm={window.location.pathname == "/OrderDetail" ? 12 : 6} className='textLeft' style={{ paddingTop: "8px" }} >
                            <Grid item xs={12} sm={12} className='textLeft' style={{display:"flex", alignItems:"center"}}>
                                <PersonIcon style={{fontSize:"22px"}}/><Typography style={{ fontSize: "18px", fontWeight: 700, marginLeft: "10px", wordBreak: "break-all" }}>{customerDetail.name}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} className='textLeft' style={{ display:"flex",alignItems:"center",paddingTop: "8px", wordBreak: "break-all" }}>
                                <EmailIcon style={{fontSize:"22px"}}/><Typography style={{ fontSize: "13px", fontWeight: 500, color: Constants.Colors.red, marginLeft: "10px" }}>{customerDetail.email}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} className='textLeft' style={{ display:"flex",alignItems:"center", paddingTop: "8px" }}>
                                <LocalPhoneIcon style={{fontSize:"22px"}}/><Typography style={{ fontSize: "13px", fontWeight: 500, color: Constants.Colors.red, marginLeft: "10px" }}>{customerDetail.phone}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={window.location.pathname == "/OrderDetail" ? 12 : 6} className='textLeft' style={{ paddingTop: "12px" }} >
                            <LocationOnIcon style={{ marginTop: "2px", position: "absolute" }} /><span><Typography style={{ fontSize: "12px", fontWeight: 400, color: Constants.Colors.dimGrey, marginLeft: "28px" }}>{customerDetail.city}, {customerDetail.state}</Typography></span>
                            <Typography style={{ fontSize: "12px", fontWeight: 400, color: Constants.Colors.dimGrey, marginLeft: "28px" }}>{customerDetail.address}</Typography>
                            <Typography style={{ fontSize: "12px", fontWeight: 400, color: Constants.Colors.dimGrey, marginLeft: "28px" }}>{customerDetail.zipCode}</Typography>
                        </Grid>
                    </Grid>

                </div >

            }
        </React.Fragment>

    }

    // const renderMobileDetails = () => {

    //     return <React.Fragment>
    //         {

    //             <CardContent style={{ padding: "10px" }}>
    //                 <Typography variant="h5" component="div">
    //                     {customerDetail.name}
    //                     <br />
    //                 </Typography>
    //                 <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
    //                     {customerDetail.address} {customerDetail.city} {customerDetail.state} {customerDetail.zipCode}
    //                 </Typography>
    //                 <hr></hr>
    //                 <Typography variant="body2">
    //                     Email:{customerDetail.email}
    //                 </Typography>
    //                 <Typography variant="body2">
    //                     Phone: {customerDetail.phone}
    //                 </Typography>
    //             </CardContent>
    //         }
    //     </React.Fragment>

    // }

    return (
        <div>
            {/* {Utils.isMobile ? renderMobileDetails() : renderWebDetails()} */}
            {renderWebDetails()}
        </div>
    );
};
