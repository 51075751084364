import ApiService from "./ApiService";
import Constants from "./Constants";
import Utils from "./Utils";

class logger {

   static info = (logObject: ILogger) => {


      let log: any = logObject;
      log.logSub = Constants.LOG_TYPES.INFO;
      logger.gen(log, logObject);

   }

   static error = (logObject: ILogger) => {

      let log: any = logObject;
      log.logSub = Constants.LOG_TYPES.ERROR;
      logger.gen(log, logObject);
   }

   static gen = (log: any, logObject: ILogger) => {

      log = Utils.commonLoggerProps(log);
      ApiService.post(`/logger`, Utils.mapAdditioanlLoggerProps(log, logObject.additionalProps));

   }
}
export interface ILogger {
   message: any;
   tag: any;
   userId?: any;
   upn?: any;
   store?: any;
   additionalProps?: any;
   payLoad?: any;
   shipmentkey?: any;
   orderno?: any;
   logType?: any;
   external?: any;
   responseOMS?: any;
}

export default logger;

