import { Typography } from "@mui/material";
import { useState } from "react";
import Utils from "../../Common/Utils";
import { CloseIcon, OpenInFullOutlinedIcon } from "../Shared/SharedIcons";
import { IButton, INotificationPopupSettings } from "../../Models/INotificationPopupSettings";
import Constants from "../../Common/Constants";
import ExtendedDescPopup from "./ExtendedDescPopup";
import { NotificationPopup } from "../Shared/NotificationPopup";

const ExtendedDescription = (props: any) => {

    const item = props?.itemDetails;
    const [openExtendedDescPopup, setExtendedDescPopup] = useState(false);


    const closeExtendedDescPopup = () => {
        setExtendedDescPopup(false);
    };

    const extendedDescPopupActions: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeExtendedDescPopup,
            color: Constants.Colors.red,
        }
    ]
    const getExtendedDesc = () => {
        const desc = item?.OrderLine?.ItemDetails?.PrimaryInformation?.ExtendedDescription ?? "";
        return desc;
    };

    let extendedDescPopup: INotificationPopupSettings = {
        open: openExtendedDescPopup,
        type: Constants.NotificationPopupType.Info,
        title: "Advanced Description",
        msg: "",
        handleClose: closeExtendedDescPopup,
        isCustomJSX: true,
        customJSX: <ExtendedDescPopup desc={getExtendedDesc()}></ExtendedDescPopup>,
        actions: extendedDescPopupActions,
        fullWidth: Utils.isMobile ? true : false,
        maxWidth: "lg"
    }

    const renderExtendedDesc = () => {
         if (item?.OrderLine?.ItemDetails?.Extn?.ExtnIsUsedOrVintageItem == "Y"
            && item?.OrderLine?.ItemDetails?.PrimaryInformation?.ExtendedDescription) 
        {

            return (
                <div style={{display:"flex",alignItems:"center",justifyContent:"left",cursor:"pointer"}} onClick={()=> setExtendedDescPopup(true)}>
                    <Typography style={{ fontSize: Utils.isMobile ? "13px" : "15px", color: "#E7131A",textDecoration:"underline",fontWeight:"bold" }}>Advanced Description</Typography>
                    <div style={{marginTop: Utils.isMobile ?"2px":"1px",marginLeft:"3px"}}>
                        <OpenInFullOutlinedIcon style={{fontSize:Utils.isMobile ? "17px":"18px",color:"#E7131A"}}></OpenInFullOutlinedIcon>
                    </div>
                </div>
            );
        }
        return<></>;
    }
    return (
        <>
        
            <NotificationPopup {...extendedDescPopup}></NotificationPopup>
            {renderExtendedDesc()}
        </>
    )
}

export default ExtendedDescription



