import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Divider, Grid, Typography, CardContent } from '@mui/material';
const CustomerDetailShimmerMobile = (props: any) => {
    return (
        <React.Fragment>
            <CardContent style={{ padding: "10px" }}>
                <Typography variant="body2">
                    <Skeleton animation="wave" />
                </Typography>
                <Typography variant="body2">
                    <Skeleton animation="wave" />
                </Typography>
                <Typography variant="body2">
                    <Skeleton animation="wave" />
                </Typography>
                <Typography variant="body2">
                    <Skeleton animation="wave" />
                </Typography>
            </CardContent>
        </React.Fragment>
    );

}
export default CustomerDetailShimmerMobile;