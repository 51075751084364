import ApiService from "../Common/ApiService";

export default class ReportManager {
  public static getExpeditesReport = async (payload: any) => {
    try {
      return await ApiService.post(`rp/getExpeditesReport`, payload);
    }
    catch (error) {
      console.log(error);
    }
  }

  public static getShippedAndPickupInStoreReport = async (payload: any) => {
    try {
      return await ApiService.post(`rp/getShippedAndPickupInStoreReport`, payload);
    }
    catch (error) {
      console.log(error);
    }
  }

  public static getCustomerContactedReport = async (payload: any) => {
    try {
      return await ApiService.post(`rp/getCustomerContactedReport`, payload);
    }
    catch (error) {
      console.log(error);
    }
  }

  public static getShippingReport = async (payload: any) => {
    try {
      return await ApiService.post(`rp/getShippingReport`, payload);
    }
    catch (error) {
      console.log(error);
    }
  }

  public static getFulfillData = async (payload: any) => {
    try {
      return await ApiService.post(`rp/getFulfilledOrders`, payload);
    }
    catch (error) {
      console.log(error);
    }
  }
  public static getFulfilledOrdersHistoryData = async (payload: any) => {
    try {
      return await ApiService.post(`rp/getFulfilledOrdersHistoryData`, payload);
    }
    catch (error) {
      console.log(error);
    }
  }
  public static getFulfilledOrdersView = async (payload: any) => {
    try {
      return await ApiService.post(`rp/getFulfilledOrdersView`, payload);
    }
    catch (error) {
      console.log(error);
    }
  }
  public static getResourcedOrdersReport = async (payload: any) => {
    try {
      return await ApiService.post(`rp/getResourcedOrdersReport`, payload);
    }
    catch (error) {
      console.log(error);
    }
  }

  public static getOrderAccuracy = async (payload: any) => {
    try {
      return await ApiService.post(`rp/getOrderAccuracy`, payload);
    }
    catch (error) {
      console.log(error);
    }
  }

  public static getShippingCharges = async (payload: any) => {
    try {
      return await ApiService.post(`rp/getShippingCharges`, payload);
    }
    catch (error) {
      console.log(error);
    }
  }

  public static getOrderProcessingTime = async (payload: any) => {
    try {
      return await ApiService.post(`rp/getOrderProcessingTime`, payload);
    }
    catch (error) {
      console.log(error);
    }
  }

  public static getFeedbackAgg = async () => {
    try {
      return await ApiService.get(`rp/getOrderProcessingTime`);
    }
    catch (error) {
      console.log(error);
    }
  }
}