import React, { useState } from 'react';
import { FC } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Drawer, Button, Card, CardContent } from '@material-ui/core';
// import districtExpandedRows from './districtExpandedRows'; // Assuming districtExpandedRows is a separate component
import './CustomDashboardTable.scss';
import DataTable, { TableStyles } from "react-data-table-component";
import { makeStyles } from '@material-ui/core/styles';
import { CloseIcon, OpenInFullOutlinedIcon } from '../Subcomponent/Shared/SharedIcons';
import DistrictExpandedRows from './DistrictExpandedRows';
import StoreCard from './StoreCard';
import { Divider, Grid, IconButton } from '@mui/material';
import Utils from '../Common/Utils';
import StackGraph from '../Subcomponent/Dashboard/StackGraph';

const useStyles = makeStyles({
    table: {
        backgroundColor: "#EEEEEE"
    },
    tableRow: {
        border: 'none',
    },
    tableCell: {
        padding: '2px 2px 2px 5px',
        border: 'none',
        borderRight: '1px solid #9d9d9d',
        borderLeft: '1px solid #9d9d9d',

    },
    iconTableCell: {
        padding: '8px 8px 2px 5px',
    },
    iconTableRow: {
        borderTop: '1px solid #9d9d9d',
        borderRight: '1px solid #9d9d9d',
        borderLeft: '1px solid #9d9d9d',
    },
    label: {
        fontSize: 14,
        color: '#676767',
    },
    dataValue: {
        fontSize: 14,
        minWidth: "220px",
        width: 'auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    drawerContent: {
        height: '80vh',
        //padding: theme.spacing(2),
    },
});


interface ICustomDashboardTable {
    data: any;
    columns: () => any;
    // regionDistrictList: any;
    identityManager: string;
    districtColumns?: () => any;
    storeColumns?: () => any;
}

const CustomDashboardTable: FC<ICustomDashboardTable> = ({ data, columns, identityManager, storeColumns, districtColumns }) => {
    const classes = useStyles();

    console.log("columns district", storeColumns?.());

    console.log("data", data)

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };

    const [clickedRowId, setClickedRowId] = useState(null); // State to track the clicked row's districtId

    const handleRowClick = (districtId: any) => {
        setClickedRowId(districtId);
    };

    const handleRowClickClose = () => {
        setClickedRowId(null);
    };

    const [clickedRowIdRegion, setClickedRowIdRegion] = useState(null); // State to track the clicked row's districtId

    const handleRowClickRegion = (regionID: any) => {
        setClickedRowIdRegion(regionID);
    };

    const handleRowClickCloseRegion = () => {
        setClickedRowIdRegion(null);
    };

    const renderDistrictHeader = (row:any, clickEvent:any) =>{

        const totalOrders =  Utils.renderTotalOrders(row.districtPick, row.districtPack, row.districtShip);
        const orderAge =  row.districtAvgAge == 0 ? "-" : Utils.hoursTextFormatter(row.districtAvgAge);
        const ordersValue = row.districtDollarValue == 0 ? "-" : Utils.getDollarValue(row.districtDollarValue);
        return (<>
                <Grid container display="flex" justifyContent="space-between" padding="15px" paddingTop="25px" alignItems="center" marginBottom="5px">
                    <Grid item>
                        <Typography style={{fontSize:"20px",fontWeight:"700"}}>District Details</Typography>
                    </Grid>
                    <Grid item >
                        <IconButton style={{padding:0,color:"black"}} onClick={clickEvent}>
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container rowSpacing={2} paddingLeft="15px">
                    <Grid item xs={4} textAlign="left">
                        <Typography className='header-drawer'>Order #</Typography>
                        <Typography className='value-drawer'>{row.district ?? ""}</Typography>
                    </Grid>
                    <Grid item xs={4} textAlign="left" paddingLeft={2}>
                        <Typography className='header-drawer'>Total Orders</Typography>
                        <Typography className='value-drawer'>{totalOrders}</Typography>
                    </Grid>
                    <Grid item  xs={4} textAlign="left">
                        <Typography className='header-drawer'>Order Age (Avg)</Typography>
                        <Typography className='value-drawer'>{orderAge}</Typography>
                    </Grid>
                    <Grid item  xs={4} textAlign="left">
                        <Typography className='header-drawer'>Orders Value</Typography>
                        <Typography className='value-drawer'>{ordersValue}</Typography>
                    </Grid>
                </Grid>
                <Grid container paddingLeft="8px" marginTop="10px">
                    <Grid item xs={12} >
                        <StackGraph pick={row.districtPick}
                            pack={row.districtPack} ship={row.districtShip}  height={70} />
                    </Grid>
                </Grid>
                </>);
    }

    const renderDrawerHeader = (row:any, clickEvent:any) =>{
        const totalOrders =  Utils.renderTotalOrders(row.regionPick, row.regionPack, row.regionShip);
        const orderAge =  row.regionAvgAge == 0 ? "-" : Utils.hoursTextFormatter(row.regionAvgAge);
        const ordersValue = row.regionDollarValue == 0 ? "-" : Utils.getDollarValue(row.regionDollarValue);
        return (<>
                <Grid container display="flex" justifyContent="space-between" padding="15px" paddingTop="25px" alignItems="center" marginBottom="5px">
                    <Grid item>
                        <Typography style={{fontSize:"20px",fontWeight:"700"}}>Region Details</Typography>
                    </Grid>
                    <Grid item >
                        <IconButton style={{padding:0,color:"black"}} onClick={clickEvent}>
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container rowSpacing={2} paddingLeft="15px">
                    <Grid item xs={4} textAlign="left">
                        <Typography className='header-drawer'>Order #</Typography>
                        <Typography className='value-drawer'>{row.region ?? ""}</Typography>
                    </Grid>
                    <Grid item xs={4} textAlign="left" paddingLeft={2}>
                        <Typography className='header-drawer'>Total Orders</Typography>
                        <Typography className='value-drawer'>{totalOrders}</Typography>
                    </Grid>
                    <Grid item  xs={4} textAlign="left">
                        <Typography className='header-drawer'>Order Age (Avg)</Typography>
                        <Typography className='value-drawer'>{orderAge}</Typography>
                    </Grid>
                    <Grid item  xs={4} textAlign="left">
                        <Typography className='header-drawer'>Orders Value</Typography>
                        <Typography className='value-drawer'>{ordersValue}</Typography>
                    </Grid>
                </Grid>
                <Grid container paddingLeft="8px" marginTop="10px">
                    <Grid item xs={12} >
                        <StackGraph pick={row.regionPick}
                            pack={row.regionPack} ship={row.regionShip}  height={70} />
                    </Grid>
                </Grid>
                </>);
    }


    return (
        <div>
            {identityManager == 'isRvp' ?
                <>
                    <TableContainer>
                        <Table className={classes.table}>
                            <TableBody>
                                {data.map((row: any) => (
                                    <React.Fragment key={row.districtId}>
                                        <TableRow className={classes.iconTableRow}>
                                            <TableCell></TableCell>
                                            <TableCell className={classes.iconTableCell} align="right">
                                                <OpenInFullOutlinedIcon onClick={() => handleRowClick(row.districtId)} />
                                            </TableCell>
                                        </TableRow>
                                        {columns().map((column: any) => (
                                            <TableRow key={column.id} className={classes.tableRow}>
                                                <TableCell className={classes.tableCell}>
                                                    <div className="custom-column">
                                                        <span className={classes.label}>{column.name}</span>
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    <div className={classes.dataValue}>{column.cell(row)}</div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <Drawer anchor="bottom" open={row.districtId === clickedRowId} onClose={handleRowClickClose}>
                                            <div key={row.districtId}>
                                                <div className={classes.drawerContent} style={{background:"#F9F9F9" }}>
                                                    {renderDistrictHeader(row,handleRowClickClose)}
                                                    <Divider className='card-divider'/>
                                                    <Card style={{background:"#F9F9F9" }}>
                                                        <CardContent>
                                                            {row.districtStoresList && row.districtStoresList.map((store: any) => (
                                                                <StoreCard key={`${row.districtId}-${store.id}`} store={store} storeColumns={storeColumns?.()} />
                                                            ))}
                                                        </CardContent>
                                                    </Card>
                                                </div>
                                            </div>
                                        </Drawer>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </> :
                identityManager == 'isDM' ?
                    <>
                        {data.map((row: any) => (
                            <React.Fragment key={row.id}>
                                <StoreCard key={row.id} store={row} storeColumns={columns()} />
                            </React.Fragment>
                        ))}
                    </>
                    :
                    <TableContainer>
                        <Table className={classes.table}>
                            <TableHead>

                            </TableHead>
                            <TableBody>
                                {data.map((row: any) => (
                                    <React.Fragment key={row.regionId}>
                                        <TableRow className={classes.iconTableRow}>
                                            <TableCell></TableCell>
                                            <TableCell className={classes.iconTableCell} align="right">
                                                <OpenInFullOutlinedIcon onClick={() => handleRowClickRegion(row.regionId)} />
                                            </TableCell>
                                        </TableRow>
                                        {columns().map((column: any) => (
                                            <TableRow key={column.id} className={classes.tableRow}>
                                                <TableCell className={classes.tableCell}>
                                                    <div className="custom-column">
                                                        <span className={classes.label}>{column.name}</span>
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    <div className={classes.dataValue}>{column.cell(row)}</div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <Drawer anchor="bottom" open={row.regionId === clickedRowIdRegion} onClose={handleRowClickCloseRegion}>
                                            <div className={classes.drawerContent} style={{background:"#F9F9F9" }}>
                                                {renderDrawerHeader(row,handleRowClickCloseRegion)}
                                                <Divider className='card-divider'/>
                                                <DistrictExpandedRows
                                                    data={row}
                                                    districtColumns={districtColumns}
                                                    storeColumns={storeColumns}
                                                />
                                            </div>
                                        </Drawer>
                                    </React.Fragment>

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            }
        </div>
    );
};

export default CustomDashboardTable;
