import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
const OrderDetailShimmerMobile = (props: any) => {
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={3} sm={3}>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <Skeleton animation="wave" height={20} width={150} />
                    <Skeleton animation="wave" height={20} width={150} />
                </Grid>
                <Grid item xs={3} sm={3}>
                </Grid>
                
            </Grid>
        </React.Fragment>
    );

}
export default OrderDetailShimmerMobile;