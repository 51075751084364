import React, { useState } from 'react';
import { Button, IconButton, Paper, Toolbar, Typography, Drawer, Card, CardContent, TextField, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Utils from '../../Common/Utils';
import { ArrowBackIcon, RestartAltIcon, FlashOffIcon, CheckIcon } from "../Shared/SharedIcons";
import Constants from '../../Common/Constants';
import StoreManager from '../../Managers/Store.manager';
import * as SDCCore from "scandit-web-datacapture-core";
import * as SDCBarcode from "scandit-web-datacapture-barcode";
import { barcodeCaptureLoader } from "scandit-web-datacapture-barcode";
import { DataCaptureContext, Camera, Brush, DataCaptureView } from "scandit-web-datacapture-core";
import { BarcodeCapture } from "scandit-web-datacapture-barcode";
import { TailSpin } from "react-loader-spinner";
const BulkPickBarCodeScannerScandit = (props: any) => {
    const licenseKey = StoreManager.config.scanditLicense;
    const width = Utils.getWebbarWidth;
    const height = Utils.getMobilebarheight;
    const [openDrawer, setDrawer] = useState(false);
    const [isOneScanned, setisOneScanned] = useState(false)
    const barCode: any = '';
    const d: any = null;
    const [scanData, setData] = useState(d);
    const [loading, setLoading] = useState(false)
    let context: DataCaptureContext | undefined;
    let view: DataCaptureView | undefined;
    let htmlElement: HTMLElement | undefined;
    let camera: Camera | undefined;
    let overlay: SDCBarcode.BarcodeTrackingBasicOverlay | undefined;
    const onScanned = (symbology: any, data: any) => {
        if (symbology == "upca") {
            if (data.charAt(0) === '0') {
                data = data.substring(1);
            }
        }
        setData(data);
        setisOneScanned(true)
        setDrawer(true);
    }
    const initScandit = async () => {
        await SDCCore.configure({
            licenseKey: licenseKey,
            libraryLocation: new URL("https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.x/build/engine/", document.baseURI).toString(),
            moduleLoaders: [barcodeCaptureLoader()]
        });
        context = await SDCCore.DataCaptureContext.create();
        view = await SDCCore.DataCaptureView.forContext(context);
        htmlElement = document.getElementById("data-capture-view") as HTMLElement;
        view.connectToElement(htmlElement);
        let settings = new SDCBarcode.BarcodeTrackingSettings();
        settings.enableSymbologies([
            SDCBarcode.Symbology.Code39,
            SDCBarcode.Symbology.Code128,
            SDCBarcode.Symbology.EAN8,
            SDCBarcode.Symbology.EAN13UPCA,
            SDCBarcode.Symbology.UPCE,
        ]);
        const barcodeTracking = await SDCBarcode.BarcodeTracking.forContext(context, settings);
        camera = SDCCore.Camera.default;
        const cameraSettings = SDCBarcode.BarcodeTracking.recommendedCameraSettings;
        await camera.applySettings(cameraSettings);
        await context.setFrameSource(camera);
        await camera.switchToDesiredState(SDCCore.FrameSourceState.On);
        overlay = await SDCBarcode.BarcodeTrackingBasicOverlay.withBarcodeTrackingForView(barcodeTracking, view);
        overlay.listener = {
            didTapTrackedBarcode: (overlay: any, trackedBarcode: SDCBarcode.TrackedBarcode) => {
                onScanned(trackedBarcode.barcode.symbology, trackedBarcode.barcode.data)
            },
            brushForTrackedBarcode: (overlay: any, trackedBarcode: any) => {
                return new Brush(SDCCore.Color.fromRGBA(10, 245, 91, 0.2), SDCCore.Color.fromRGBA(10, 245, 91, 0.2), 4)
            },
        };
    }
   
    React.useEffect(() => {
        async function start() {
            setLoading(true);
            await initScandit();
            setLoading(false);
        }
        start();
        return () => {
            cleanUp()
        };
    }, []);
    const changeScanData = (event: any) => {
        setData(event.target.value)
    }
    const cleanUp = async () => {
        await camera?.switchToDesiredState(SDCCore.FrameSourceState.On);
        await context?.dispose();
        await context?.removeAllModes();
        if (overlay) {
            await view?.removeOverlay(overlay);
        }
        view?.detachFromElement();
    }
    React.useEffect(() => {
        async function start() {
            setLoading(true);
            await initScandit();
            setLoading(false);
        }
        start();
        return () => {
            cleanUp()
        };
    }, []);
    return (<div>
        <Drawer
            style={{ zIndex: 99999 }}
            anchor={"top"}
            open={openDrawer}
            onClose={() => { }}
            onClick={() => { }}
        >
            <Card style={{ margin: "6px" }}>
                <CardContent style={{ padding: "10px" }}>
                    <Typography variant="h5" component="div" style={{ fontSize: "18px" }}>
                        Enter Bar Code
                        <br />
                    </Typography>
                    <br></br>
                    <TextField fullWidth variant={"outlined"} placeholder='SKU#' id="fullWidth" value={scanData} onChange={(event: any) => { changeScanData(event) }} />
                    <br></br>
                    <br></br>
                    <Typography variant="h5" component="div" style={{ fontSize: "18px" }}>
                        Quantity
                        <br />
                    </Typography>
                    <br></br>
                    <TextField fullWidth variant={"outlined"} type={"number"} InputProps={{ inputProps: { min: 1 } }} defaultValue={1} InputLabelProps={{ shrink: false }} label="" placeholder='Quantity' id="fullWidth" />

                    <Button onClick={() => { setDrawer(false); setisOneScanned(false) }} style={{ marginTop: "20px", marginRight: "5px", width: "48%", color: Constants.Colors.red, borderColor: Constants.Colors.red }} variant='outlined' className='' startIcon={<RestartAltIcon />}>Retry</Button>
                    <Button onClick={() => { props.onScanned("", false); }} style={{ marginTop: "20px", width: "48%" }} variant='contained' className='btn' startIcon={<CheckIcon />}>Done</Button>

                </CardContent>
            </Card>
        </Drawer>
        <div className='containerTypeBarCodeMbl'>
            <Button onClick={() => { setDrawer(true); setisOneScanned(true); }} className='btn' style={{ fontSize: "10px", borderRadius: "14px" }} variant='contained'>Type your barcode</Button>
        </div>
        <Paper id='bar' style={{ color: "white", top: 0, left: 0, right: 0, zIndex: 999 }} className="appBarPrimary">
            <Toolbar style={{ height: "46px", minHeight: "46px" }}>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}

                    onClick={() => { props.onScanned("", false); }}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ marginRight: 60 }}>
                    Scanner
                </Typography>
            </Toolbar>
        </Paper>
        {
            <div>
                <div style={{ display: loading ? 'flex' : 'none', justifyContent: "center", alignItems: "center", height: "500px", flexDirection: "column" }}>
                    <div>
                        <TailSpin color="#D93A2F" height={50} width={50} wrapperStyle={{ justifyContent: "center" }} />
                    </div>
                    <div style={{ fontSize: "large" }}> <b>Loading...</b></div>
                    <div style={{ fontSize: "large" }}> <b>{Utils.getConfigurationWithKey("ScanLoaderMessage")}</b> </div> </div>
                <div id="data-capture-view" style={{ display: loading ? 'none' : 'block' }}></div>
            </div>
            // :
            // <div></div>
        }

    </div>)
}
export default BulkPickBarCodeScannerScandit;