import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
const OrderDetailShimmer = (props: any) => {
    return (
        <React.Fragment>
            <Grid container spacing={2} display={"flex"} style={{ padding: "10px" }}>
                <Grid item xs={6} sm={6} className='textLeft' style={{ display: "flex" }}>
                    <Skeleton animation="wave" height={35} width={150} />
                </Grid>
                <Grid item xs={6} sm={6} className='textLeft' style={{ display: "flex" }}>
                    <Skeleton animation="wave" height={35} width={150} />
                </Grid>
            </Grid>
        </React.Fragment>
    );

}
export default OrderDetailShimmer;