import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Sector, Cell, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import Utils from '../../Common/Utils';
import { Typography } from "@material-ui/core";
import IdentityManager from '../../Managers/Identity.manager';
import DataNotFound from '../Shared/DataNotFound';
import { log } from 'console';

const ServiceTrend = (props: any) => {
    let graphData: any[] = [];
    let isResponseAllZero = false
    const { isLoaded, data } = props;
    console.log("data.districtShippingData", data.districtShippingData);

    const [firstClass, setFirstClass] = useState(0)
    const [ups2ndDay, setUps2ndDay] = useState(0)
    const [upsNextDay, setUpsNextDay] = useState(0)
    const [nextDayAirSaver, setNextDayAirSaver] = useState(0)
    const [economyGround, setEconomyGround] = useState(0)

    const [totalServiceCount, setTotalServiceCount] = useState(0)


    const [totalFirstClassAmount, setTotalFirstClassAmount] = useState(0)
    const [totalUps2ndDayAmount, setTotalUps2ndDayAmount] = useState(0)
    const [totalUpsNextDayAmount, setTotalUpsNextDayAmount] = useState(0)
    const [totalNextDayAirSaverAmount, setTotalNextDayAirSaverAmount] = useState(0)
    const [totalEconomyGroundAmount, setTotalEconomyGroundAmount] = useState(0)



    useEffect(() => {
        if (data.districtShippingData) {
            let firstClass = 0
            let ups2ndDay = 0
            let upsNextDay = 0
            let nextDayAirSaver = 0
            let economyGround = 0
            let totalCount = 0

            let firstClassAmount = 0
            let ups2ndDayAmount = 0
            let upsNextDayAmount = 0
            let nextDayAirSaverAmount = 0
            let economyGroundAmount = 0
            for (let i = 0; i < data.districtShippingData.length; i++) {
                firstClass = firstClass + data.districtShippingData[i].firstClass;
                ups2ndDay = ups2ndDay + data.districtShippingData[i].ups2ndDay;
                upsNextDay = upsNextDay + data.districtShippingData[i].upsNextDay;
                nextDayAirSaver = nextDayAirSaver + data.districtShippingData[i].nextDayAirSaver;
                economyGround = economyGround + data.districtShippingData[i].economyGround;


                firstClassAmount = firstClassAmount + data.districtShippingData[i].totalFirstClassAmount;
                ups2ndDayAmount = ups2ndDayAmount + data.districtShippingData[i].totalUps2ndDayAmount;
                upsNextDayAmount = upsNextDayAmount + data.districtShippingData[i].totalUpsNextDayAmount;
                nextDayAirSaverAmount = nextDayAirSaverAmount + data.districtShippingData[i].totalNextDayAirSaverAmount;
                economyGroundAmount = economyGroundAmount + data.districtShippingData[i].totalEconomyGroundAmount;

            }

            totalCount = firstClass + ups2ndDay + upsNextDay + nextDayAirSaver + economyGround;

            setTotalServiceCount(totalCount)

            setFirstClass(firstClass)
            setUps2ndDay(ups2ndDay)
            setUpsNextDay(upsNextDay)
            setNextDayAirSaver(nextDayAirSaver)
            setEconomyGround(economyGround)


            setTotalFirstClassAmount(firstClassAmount)
            setTotalUps2ndDayAmount(ups2ndDayAmount)
            setTotalUpsNextDayAmount(upsNextDayAmount)
            setTotalNextDayAirSaverAmount(nextDayAirSaverAmount)
            setTotalEconomyGroundAmount(economyGroundAmount)
        }
    }, [data.districtShippingData])

    if (isLoaded && data) {
        const distObj = data.districtShippingData ? data?.districtShippingData[0] : null;


        if (distObj) {
            graphData = [
                {
                    name: "ECONOMY GROUND",
                    value: economyGround
                },
                {
                    name: "FIRST CLASS",
                    value: firstClass
                },
                {
                    name: "UPS 2nd Day Air",
                    value: ups2ndDay
                },
                {
                    name: "UPS Next Day Air",
                    value: upsNextDay
                },
                {
                    name: "Next Day Air Saver",
                    value: nextDayAirSaver
                },

            ];

        }
    }

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const RADIAN = Math.PI / 180;

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const getLegendName = (name: string, value: number) => {

        if (name.replace(/ /g, '').trim().toLowerCase() === "economyground") {

            return ` ${value} (${parseFloat(((value / totalServiceCount) * 100).toFixed(2))}%)  ${totalEconomyGroundAmount ? `- ($` + Utils.nFormatter(totalEconomyGroundAmount, 2) + ')' : ''}`
        }
        if (name.replace(/ /g, '').trim().toLowerCase() === "firstclass") {
            return ` ${value} (${parseFloat(((value / totalServiceCount) * 100).toFixed(2))}%)  ${totalFirstClassAmount ? `- ($` + Utils.nFormatter(totalFirstClassAmount, 2) + `)` : ''} `
        }
        if (name.replace(/ /g, '').trim().toLowerCase() === "nextdayairsaver") {
            return ` ${value} (${parseFloat(((value / totalServiceCount) * 100).toFixed(2))}%)  ${totalNextDayAirSaverAmount ? `- ($` + Utils.nFormatter(totalNextDayAirSaverAmount, 2) + ')' : ''} `
        }
        if (name.replace(/ /g, '').trim().toLowerCase() === "ups2nddayair") {

            return ` ${value}  (${parseFloat(((value / totalServiceCount) * 100).toFixed(2))}%)  ${totalUps2ndDayAmount ? `- ($` + Utils.nFormatter(totalUps2ndDayAmount, 2) + ')' : ''} `
        }
        if (name.replace(/ /g, '').trim().toLowerCase() === "upsnextdayair") {
            return ` ${value}  (${parseFloat(((value / totalServiceCount) * 100).toFixed(2))}%)  ${totalUpsNextDayAmount ? `- ($` + Utils.nFormatter(totalUpsNextDayAmount, 2) + ')' : ''} `
        }

        return 0

    }

    const renderColorfulLegendText = (value: string, entry: any) => {

        return (
            <span style={{ color: "#596579", fontWeight: 500, fontSize: "12px" }}>
                {value} {"-"} <span style={{ fontWeight: 600 }}>{getLegendName(value, entry.payload.value)}</span>
            </span>
        );
    };
    return (

        <>

            {(graphData.length && !isResponseAllZero) ? <ResponsiveContainer height={300}>
                <PieChart >
                    <Legend
                        align='center'
                        iconType="circle"
                        layout="vertical"
                        verticalAlign="bottom"
                        iconSize={10}
                        formatter={renderColorfulLegendText}

                    />
                    <Tooltip />
                    <Pie
                        data={graphData}
                        cx={"50%"}
                        cy={"80%"}
                        startAngle={180}
                        endAngle={0}
                        outerRadius={150}
                        labelLine={false}
                        fill="#8884d8"
                        innerRadius={90}
                        // label={renderCustomizedLabel}
                        dataKey="value"
                    >
                        {graphData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={Utils.getColor(index)} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer> : <DataNotFound></DataNotFound>}
        </>

    )
}

export default ServiceTrend