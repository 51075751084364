import React, { useState, useEffect } from "react";
import "./FulfillOrders.scss";
import { useTheme, withStyles } from "@material-ui/core/styles";
import { AppBar, Button } from "@material-ui/core";
import Utils from "../Common/Utils";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  Card,
  CardContent,
  Divider,
  InputBase,
  TextField,
  Checkbox,
  Tooltip,
  Grid
} from "@mui/material";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
//import moment from 'moment';
import DataTable from "react-data-table-component";
import { IconButton, Paper, Typography } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SiteBreadCrumbs from "../Subcomponent/Shared/SiteBreadCrumbs";
import Constants from "../Common/Constants";
import allActions from "../Store/Actions/AllActions";
import { NotificationPopup } from "../Subcomponent/Shared/NotificationPopup";
import {
  INotificationPopupSettings,
  IButton,
} from "../Models/INotificationPopupSettings";
import TableViewSwitch from "../Subcomponent/FulfillOrders/TableViewSwitch";
import PickDataTable from "../Subcomponent/FulfillOrders/PickDataTable";
import MobileDataTable from "../Subcomponent/FulfillOrders/MobileDataTable";
import MobilePickDataTable from "../Subcomponent/FulfillOrders/MobilePickDataTable";
import Chip from "@mui/material/Chip";
import MultiPickSlip from "../Subcomponent/FulfillOrders/MultiPickSlip";
import PdfIframe from "../Subcomponent/PickOrder/PdfIframe";
import StoreManager from "../Managers/Store.manager";
import {
  IOrderQuery,
  IFilterInfo,
  IDateFilterValue,
  IOrderValueFilter,
} from "../Models/IOrderQuery";
import OrderManager from "../Managers/Orders.Manager";
import ShipmentStatusConstant from "../Common/ShipmentStatusConstants";
import {
  CloseIcon,
  PlaylistAddCheck,
  ShoppingCart,
  ApartmentIcon,
  DirectionsCar,
  FilterAltIcon,
  LocalShippingIcon,
  PreviewIcon,
  SearchIcon,
  ExpandMoreIcon,
  ExpandLessIcon,
  PrintIcon,
  MarkEmailUnreadIcon,
  MarkEmailReadIcon,
  StopIcon,
  DoneIcon,
  ArrowForwardIcon,
  ReportProblemIcon,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  FilterListIcon,
  SubjectIcon,
  ShoppingCartOutlinedIcon,
  ClassOutlinedIcon,
  NearMeOutlinedIcon,
  LocalShippingOutlinedIcon,
  CheckBoxOutlinedIcon
} from "../Subcomponent/Shared/SharedIcons";
import IdentityManager from "../Managers/Identity.manager";
import ExpandedRow from "../Subcomponent/FulfillOrders/ExpandedRow";
import OMSManager from "../Managers/Oms.manager";
import { ICatalogCategory } from "../Models/ICatalogCategory";
import { ISorting } from "../Models/ISorting";
import PickSlipGroupBy from "../Subcomponent/FulfillOrders/PickSlipGroupBy";
import HideZeroOrders from "../Subcomponent/FulfillOrders/HideZeroOrders";
import { OrderDetailNotificationPopup } from "../Subcomponent/Shared/OrderDetailNotificationPopup";
import CustomerPickupSlip from "../Subcomponent/FulfillOrders/CustomerPickupSlip";
import LabelText from "../Common/LabelText";
import CustomerPickupSlipOptions from "../Subcomponent/FulfillOrders/CustomerPickupSlipOptions";
import PFManager from "../Managers/Process.Fulfillment.Manager";
import { ReportErrorPopup } from "../Subcomponent/Shared/ReportErrorPopup";
import { maxWidth } from "@mui/system";
import { ServiceNowCnfrmText } from "../Subcomponent/Shared/ServiceNowCnfrmText";
var moment = require("moment");
const steps = ["Pack", "Pick", "Pack", "Pickup"];
const FulfillOrders = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var startProductBarPos = -1;
  const theme = useTheme();
  let currentTab = useSelector(
    (state: any) => state.FulfillOrdersReducersState.currentTab
  );
  let selectedOrderTypeFilter = useSelector(
    (state: any) => state.FulfillOrdersReducersState.selectedOrderTypeFilter
  );
  let orderTypeFilters = useSelector(
    (state: any) => state.FulfillOrdersReducersState.orderTypeFilters
  );
  let selectedOrderAgeFilter = useSelector(
    (state: any) => state.FulfillOrdersReducersState.selectedOrderAgeFilter
  );
  let orderAgeFilters = useSelector(
    (state: any) => state.FulfillOrdersReducersState.orderAgeFilters
  );
  let selectedOrderValueFilter = useSelector(
    (state: any) => state.FulfillOrdersReducersState.selectedOrderValue
  );
  let orderValueFilters = useSelector(
    (state: any) => state.FulfillOrdersReducersState.orderValueFilters
  );
  let totalRecords = useSelector(
    (state: any) => state.FulfillOrdersReducersState.total
  );
  let orderRows = useSelector(
    (state: any) => state.FulfillOrdersReducersState.orderRows
  );
  let tabCounts = useSelector(
    (state: any) => state.FulfillOrdersReducersState.tabsCount
  );
  let pickSlipGroupByModal = useSelector(
    (state: any) => state.FulfillOrdersReducersState.pickSlipGroupByModal
  );
  let customerSlipOptions = useSelector(
    (state: any) => state.FulfillOrdersReducersState.customerSlipOptions
  );
  let userSettings = useSelector(
    (state: any) => state.ActionLoaderReducersState.userSettings
  );
  const pathname = window.location.pathname;
  const [openDrawer, setDrawer] = useState(false);
  const [rowDetail, setRowDetail] = useState({} as any);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  // const [hideZero, setHideZero] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [openError, setOpenError] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [openConstruction, setOpenConstruction] = useState(false);
  const [openpdfInfo, setpdfInfo] = useState(false);
  const [multiPickSlipOpenIFrame, setMultiPickSlipOpenIFrame] = useState(false);
  const [catalogCategories, setCataLogCategories] = useState<ICatalogCategory>({
    Category: [],
  });

  //Report Error Action Use State
  const [reportErrorAction, setReportErrorAction] = useState("");

  // Report Error Pop Up Use States
  const [openReportError, setOpenReportError] = useState(false);
  const [openAlreadyReportedError, setOpenAlreadyReportedError] =
    useState(false);
  const [openConfirmReportError, setOpenConfirmReportError] = useState(false);
  const [errorReportMsg, setErrorReportMsg] = useState("");

  const [lineType, setLineType] = useState(0);
  const [isOpenFilters, setOpenFilters] = useState(false);
  const [ordersToPrint, setordersToPrint] = useState<string[]>([]);
  const [blobUrl, setBlobUrl] = useState("");
  const [showPdf, SetShowPdf] = useState(false);  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    userSettings?.pagination ?? Constants.UserSettingsDefault.Pagination
  );
  const [mobilePage, setMobilePage] = React.useState(0);
  const [selectAllCheckBox, setSelectAllCheckBox] = useState(false);
  const [noMobileRecords, setNoMobileRecords] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [intermediate, setIntermediate] = useState(false);
  const [status, setStatus] = useState(0);
  const [sort, setSort] = useState<ISorting>({
    sortField: "Age",
    sortOrder: "desc",
  } as any);
  const [loadingRvpStores, setLoadingRvpStores] = useState(true);
  const [customerSlipData, setCustomerSlipData] = useState([] as any);
  const [customerSlipInfo, setCustomerSlipInfo] = useState(false);
  const location: any = useLocation();

  window.onscroll = function () {
    var bar = document.getElementById("paginationWebId") as any;
    var dataTable = document.getElementById("containerDataTable") as any;
    if (bar && dataTable) {
      var dataTableWidth = dataTable.offsetWidth;
      if (startProductBarPos < 0) startProductBarPos = findPosY(bar);
      if (
        window.pageYOffset > startProductBarPos &&
        window.pageYOffset - startProductBarPos > 50
      ) {
        bar.style.width = dataTableWidth - (0.8 * dataTableWidth) / 100 + "px";
        bar.classList.add("fixedPagination");
        bar.style.marginTop = Utils.getWebbarheight + "px";
      } else {
        bar.classList.remove("fixedPagination");
        bar.style.width = "";
        bar.style.marginTop = "";
      }
    }
    // Mobile View Fix the Tabs and FILTER
    if (Utils.isMobile) {
      console.log("scrolled");
      bar = document.getElementById("tabFilterContaoner") as any;
      var mblBar = document.getElementById("mblBar") as any;
      if (bar) {
        console.log("findPosY(mblBar);" + findPosY(mblBar));
        console.log("startProductBarPos=" + startProductBarPos);
        console.log("window.pageYOffset=" + window.pageYOffset);
        if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);
        if (window.pageYOffset > startProductBarPos) {
          bar.classList.add("scrolledTopFixFulFillOrders");
        } else {
          bar.classList.remove("scrolledTopFixFulFillOrders");
        }
      }

      if (
        !isLoading &&
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 100
      ) {
        let page = mobilePage + 1;
        setMobilePage(page);
      }
    }
  };
  // const onChangeHideZero = () => {
  //     setHideZero(!hideZero)
  // }
  const resetMobileScrolling = () => {
    window.scrollTo(0, 0);
    setNoMobileRecords(false);
    setMobilePage(0);
  };

  const selectAll = (e: any) => {
    setSelectAllCheckBox(!selectAllCheckBox);
    if (e.target.checked) {
      let shipmentIds = [] as any;
      orderRows.forEach((element: any) => {
        shipmentIds.push(element._id);
      });
      setordersToPrint(shipmentIds);
    } else {
      let shipmentIds = [] as any;
      setordersToPrint(shipmentIds);
    }
  };
  const checkIntermediate = () => {
    if (ordersToPrint.length == 0) {
      setSelectAllCheckBox(false);
      setIntermediate(false);
    } else {
      if (orderRows.length == ordersToPrint.length) {
        setIntermediate(false);
        setSelectAllCheckBox(true);
      } else {
        setSelectAllCheckBox(false);
        setIntermediate(true);
      }
    }
  };

  const updateStatusState = () => {
    setStatus(status + 1);
  };

  const closeErrorPopup = () => {
    setOpenError(false);
  };

  const openInfoPopup = () => {
    setOpenInfo(true);
    updateStatusState();
  };

  const closeInfoPopup = () => {
    setOpenInfo(false);
  };

  const openErrorPopup = () => {
    setOpenError(true);
  };
  const closeConstructionPopup = () => {
    setOpenConstruction(false);
  };
  const openConstructionPopup = () => {
    setOpenConstruction(true);
  };

  const openCustomerSlipPopup = () => {
    dispatch(
      allActions.fulfillOrdersActions.changeCustomerSlipOptions({
        ...customerSlipOptions,
        openPopup: true,
      })
    );
  };

  const closeCustomerPickPrint = () => {
    dispatch(
      allActions.fulfillOrdersActions.changeCustomerSlipOptions({
        ...customerSlipOptions,
        customerPrint: false,
        openPopup: false,
        dataLoaded: false,
      })
    );
  };

  const openCustomerSlipInfo = () => {
    setCustomerSlipInfo(true);
  };

  const closeCustomerSlipInfo = () => {
    setCustomerSlipInfo(false);
  };

  const getCustomerSlipData = async () => {
    let dates: any = {};
    switch (customerSlipOptions.dateValue) {
      case "0":
        dates = Utils.getDates(5);
        break;
      case "1":
        dates = Utils.getDates(10);
        break;
      case "2":
        dates = Utils.getDates(15);
        break;
      default:
        break;
    }
    const storeId = IdentityManager.getMultipleStoresId(props.store);
    let payLoad = {
      startDate: dates?.start ?? "",
      endDate: dates?.end ?? "",
      sortOrder: customerSlipOptions.sortOrder,
      store: storeId,
    };

    dispatch(
      allActions.fulfillOrdersActions.changeCustomerSlipOptions({
        ...customerSlipOptions,
        openPopup: false,
      })
    );

    showActionLoader();

    await OrderManager.getCustomerPickSlipData(payLoad)
      .then((result) => {
        hideActionLoader();
        if (result.success) {
          let response = result?.data?.hits;
          if (response?.length > 0) {
            setCustomerSlipData(response);
            dispatch(
              allActions.fulfillOrdersActions.changeCustomerSlipOptions({
                ...customerSlipOptions,
                customerPrint: true,
                openPopup: false,
              })
            );
          } else {
            openCustomerSlipInfo();
          }
        } else {
          console.log("CustomerPickSlip Error", result.msg);
          setErrorMsg(result.msg);
          setErrorReportMsg(result.msg);
          setReportErrorAction(
            Constants.REPORT_ERROR_ACTIONS.FULLFILLED_ORDERS_CUSTOMER_PICKUP
          );
          openErrorPopup();
        }
      })
      .catch((error) => {
        hideActionLoader();
        setErrorMsg(
          "Something went wrong please contact the IT Help Desk for assistance"
        );
        setErrorReportMsg(
          "Something went wrong please contact the IT Help Desk for assistance"
        );
        setReportErrorAction(
          Constants.REPORT_ERROR_ACTIONS.FULLFILLED_ORDERS_CUSTOMER_PICKUP
        );
        openErrorPopup();
        console.log(error);
      });
  };

  const printCustomerSlip = async () => {
    await getCustomerSlipData();
  };




  // Error Popup

  // Buttons When there is no Action
  let errorPopupCloseButtons: IButton[] = [
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeErrorPopup,
      color: Constants.Colors.red,
    },
  ];


  let Error: INotificationPopupSettings = {
    open: openError,
    type: Constants.NotificationPopupType.Error,
    title: "Error",
    msg: errorMsg,
    draggable: false,
    handleClose: closeErrorPopup,
    actions: errorPopupCloseButtons,
  };

  let customerSlipsPopUpButtons: IButton[] = [
    {
      text: "Print Slips",
      icon: <DoneIcon />,
      action: printCustomerSlip,
      color: Constants.Colors.yellow,
    },
    {
      text: "Cancel",
      icon: <CloseIcon />,
      action: closeCustomerPickPrint,
      color: Constants.Colors.grey,
    },
  ];

  let customerSlipsPopUp: INotificationPopupSettings = {
    open: customerSlipOptions.openPopup,
    type: Constants.NotificationPopupType.Info,
    title: LabelText.NotificationPopupHeaders.Info,
    msg: LabelText.CustomerPickup.CustomerSlipsDate,
    handleClose: closeCustomerPickPrint,
    isCustomJSX: true,
    customJSX: <CustomerPickupSlipOptions></CustomerPickupSlipOptions>,
    actions: customerSlipsPopUpButtons,
  };

  let customerSlipInfoButtons: IButton[] = [
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeCustomerSlipInfo,
      color: Constants.Colors.blue,
    },
  ];

  let customerSlipInfoPopUp: INotificationPopupSettings = {
    open: customerSlipInfo,
    type: Constants.NotificationPopupType.Info,
    title: LabelText.NotificationPopupHeaders.Info,
    msg: LabelText.CustomerPickup.NoRecordsInfo,
    draggable: false,
    handleClose: closeCustomerSlipInfo,
    actions: customerSlipInfoButtons,
  };

  let Info = {
    open: openInfo,
    msg: "The Order Status has been changed. Your screen will automatically refresh now.",
    handleClose: closeInfoPopup,
    zIndex: 10000000,
  };

  let constructionButtons: IButton[] = [
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeConstructionPopup,
      color: Constants.Colors.red,
    },
  ];
  let underconstruction: INotificationPopupSettings = {
    open: openConstruction,
    type: Constants.NotificationPopupType.Info,
    title: "",
    msg: "Feature under construction",
    draggable: false,
    handleClose: closeConstructionPopup,
    actions: constructionButtons,
  };
  function findPosY(obj: any) {
    var curtop = 0;
    if (obj && typeof obj.offsetParent != "undefined" && obj.offsetParent) {
      while (obj.offsetParent) {
        curtop += obj.offsetTop;
        obj = obj.offsetParent;
      }
      curtop += obj.offsetTop;
    } else if (obj && obj.y) curtop += obj.y;
    return curtop;
  }
  const showActionLoader = () => {
    dispatch(allActions.actionLoaderActions.showLoader());
  };
  const hideActionLoader = () => {
    dispatch(allActions.actionLoaderActions.hideLoader());
  };
  const handleCheckboxChange = (e: any, orderNumber: string) => {
    if (e.target.checked) {
      let checkedOrders = [...ordersToPrint];
      checkedOrders.push(orderNumber);
      setordersToPrint(checkedOrders);
    } else {
      let checkedOrders = [...ordersToPrint];
      let removed = checkedOrders.filter(function (value, index, arr) {
        return value != orderNumber;
      });
      setordersToPrint(removed);
    }
  };
  const openFilters = () => {
    setOpenFilters(true);
  };
  const closePdfiframe = () => {
    SetShowPdf(false);
    setMultiPickSlipOpenIFrame(false)
  };

  const closePdfInfo = () => {
    setpdfInfo(false);
  };

  let pdfInfoButtons: IButton[] = [
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closePdfInfo,
      color: Constants.Colors.blue,
    },
  ];

  let pdfInfo: INotificationPopupSettings = {
    open: openpdfInfo,
    type: Constants.NotificationPopupType.Info,
    title: "Info",
    msg: "Pick Slip can be generated for upto 50 Orders.",
    draggable: false,
    handleClose: closePdfInfo,
    actions: pdfInfoButtons,
  };

  const openPdfiframe = async (isPickSlip: any) => {
    if (ordersToPrint.length > 0) {
      if (isPickSlip) {
        if(ordersToPrint.length>50)
        {
          setpdfInfo(true);
        }
        else{
          SetShowPdf(true)
        }
        
      } else {
        dispatch(
          allActions.fulfillOrdersActions.changePrintPickSlips({
            ...pickSlipGroupByModal,
            open: true,
          })
        );
      }
    }
  };

  const closeFilters = () => {
    setOpenFilters(false);
  };
  const toggleDrawer = (op: any) => {
    setDrawer(op);
  };

  const handleFilterChange = () => {
    //updateExpandFilterSettings(!isOpenFilters);
    setOpenFilters(!isOpenFilters);
  };

  const updateExpandFilterSettings = (expandFilters: boolean) => {
    const updatedSettings = {
      ...userSettings,
      expandFilters: expandFilters,
    };
    dispatch(allActions.actionLoaderActions.setUserSettings(updatedSettings));
    //Update User Settings in Dynamo
    const payload = {
      attributeName: Constants.UserSettingsFields.ExpandFilters,
      value: expandFilters,
      userName: userSettings.userName,
    };
    Utils.updateUserSettings(payload);
  };

  const changeLineType = (e: any, newValue: any) => {
    setordersToPrint([]);
    setLineType(newValue);
  };
  // Pagination
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(+parseInt(event.target.value));
    setPage(0);
    updatePaginationSettings(parseInt(event.target.value));
  };

  const updatePaginationSettings = (pageSize: number) => {
    const updatedSettings = {
      ...userSettings,
      pagination: pageSize,
    };
    dispatch(allActions.actionLoaderActions.setUserSettings(updatedSettings));
    //Update User Settings in Dynamo
    const payload = {
      attributeName: Constants.UserSettingsFields.Pagination,
      value: pageSize,
      userName: userSettings.userName,
    };
    Utils.updateUserSettings(payload);
  };

  // End Drawer Code
  const handleChangeTab = (event: any, newValue: number) => {
    setPage(0);
    if (Utils.isMobile) {
      resetMobileScrolling();
    }
    console.log(newValue, "handleChange");
    //To reset On Hold filter for packing and customer pickup.
    if (
      selectedOrderTypeFilter == Constants.OrderTypeFilters[5].name &&
      newValue > 1
    ) {
      clearFilter();
    }
    //setValue(newValue);
    dispatch(allActions.fulfillOrdersActions.changeFOTab(newValue));
  };
  const resetFilters = () => {
        if(Utils.isMobile)
        {
          resetMobileScrolling();
        }
        dispatch(allActions.fulfillOrdersActions.changeSelectedOrderAge(""));
        dispatch(allActions.fulfillOrdersActions.changeSelectedOrderValue(""));
        dispatch(allActions.fulfillOrdersActions.changeSelectedOrderType("ALL"));
  }
  const quickFilterClicked = (filterType: string, newSeletedFilter: string) => {
    if (Utils.isMobile) {
      resetMobileScrolling();
    }
    if (filterType == "OrderType") {
      dispatch(
        allActions.fulfillOrdersActions.changeSelectedOrderType(
          newSeletedFilter
        )
      );
      if (newSeletedFilter == "ALL") {
        dispatch(allActions.fulfillOrdersActions.changeSelectedOrderAge(""));
        dispatch(allActions.fulfillOrdersActions.changeSelectedOrderValue(""));
      }
    }
    if (filterType == "OrderAge") {
      dispatch(
        allActions.fulfillOrdersActions.changeSelectedOrderAge(newSeletedFilter)
      );
    }
    if (filterType == "OrderValue") {
      dispatch(
        allActions.fulfillOrdersActions.changeSelectedOrderValue(
          newSeletedFilter
        )
      );
    }
  };

  const customerPickupStatus = (row:any) =>
  {
    return row?._source?.Shipment?.Status==ShipmentStatusConstant.ReadyForCustomerPickup;
  }

  const getDate = (row: any) => {
    let date: string = "";
    if (row._source.Shipment.OrderDate) {
      date = moment
        .utc(row._source.Shipment.OrderDate)
        .tz("America/Los_Angeles")
        .format(Constants.DateFormat.Format);
    }
    return <span style={{ fontFamily: "Roboto Mono", fontSize: "13px" }}>{date}</span>;
  };

  const getStatusDate = (row:any) =>{
      let date: string = "";
      if(customerPickupStatus(row) &&
      row?._source?.Shipment?.StatusDate)
      {
        date = moment
          .utc(row._source.Shipment.StatusDate)
          .tz("America/Los_Angeles")
          .format(Constants.DateFormat.Format);
      }
      return <span style={{ fontFamily: "Roboto Mono", fontSize: "13px" }}>{date}</span>;
  }

  const getDateForDrawer = (row: any) => {
    let date: string = "";
    if(customerPickupStatus(row) &&
      row?._source?.Shipment?.StatusDate)
    {
      date = moment
        .utc(row._source.Shipment.StatusDate)
        .tz("America/Los_Angeles")
        .format(Constants.DateFormat.Format);
    }
    else if (row?._source?.Shipment?.OrderDate) {
      date = moment
        .utc(row._source.Shipment.OrderDate)
        .tz("America/Los_Angeles")
        .format(Constants.DateFormat.Format);
    }
    return date
  };

  const getCustomerName = (row: any) => {
    let name: string = "";
    if (row?._source?.Shipment?.CustomerInfo?.FirstName) {
      name =
        row._source.Shipment.CustomerInfo.FirstName +
        " " +
        row._source.Shipment.CustomerInfo.LastName;
    }
    return name;
  };

  // This method will clear the local storage data for batch picking
  const resetLocalStorage = () => {
    let currentDateTime = moment().toISOString();
    let storageDate = localStorage.getItem("storageCreateDate");
    if (storageDate && storageDate.length > 0) {
      let a = moment(currentDateTime); //now
      let b = moment(storageDate);
      let mins = a.diff(b, "minutes");
      console.log(mins, "minutes");

      if (mins > 30) {
        localStorage.removeItem("1mobilePickSingleMultipleMarkedValues");
        localStorage.removeItem("2mobilePickSingleMultipleMarkedValues");
      }
    }
  };

  const getType = (row: any) => {
    let isWarranty = false;
    let isBundle = false;
    let isResourced = false;
    row._source.Shipment.ShipmentLines.ShipmentLine.forEach((element: any) => {
      if (element.ExtnIsWarrantyItem == "Y") {
        isWarranty = true;
      }
      if (element.KitCode && element.KitCode != "") {
        isBundle = true;
      }
      if (element.ExtnRescheduleReqCount > 0) {
        isResourced = true;
      }
    });
    let title = "";
    let cellValue = "";
    if (isBundle) {
      title = title + "SET";
      cellValue = cellValue + "S";
    }

    if (isWarranty) {
      if (isBundle) {
        title = title + "/PRO COV";
        cellValue = cellValue + "/PC";
      } else {
        title = title + "PRO COV";
        cellValue = cellValue + "PC";
      }
    }
    if (isResourced) {
      title =
        isBundle || isWarranty ? title + "/RESOURCED" : title + "RESOURCED";
      cellValue = isBundle || isWarranty ? cellValue + "/R" : cellValue + "R";
    }
    return (
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "4px", marginLeft: '4px' }}>
        <Tooltip title={title}>
          {isBundle || isWarranty || isResourced ? (
            <Chip
              className="chipTracking"
              label={cellValue}
              size="small"
              style={{
                background: "#A1D899",
                color: "#006100",
                fontSize: "10px",
                height: "16px",
                borderRadius: '4px',
              }}
            />
          ) : (
            <></>
          )}
        </Tooltip>
        {getCustomerContacted(row, isBundle, isWarranty, isResourced)}
      </div>
    );
  };

  const getCustomerContacted = (
    row: any,
    isBundle: any,
    isWarranty: any,
    isResourced: any
  ) => {
    if (row?._source?.Shipment?.CustomerResponded) {
      return (
        <Tooltip title={Constants.FulfillOrdersGrid.CustomerResponded}>
          {isBundle || isWarranty || isResourced ? (
            <MarkEmailReadIcon
              style={{
                color: Constants.Colors.green,
                height: '16px'
              }}
            />
          ) : (
            <MarkEmailReadIcon
              style={{
                color: Constants.Colors.green,
                height: '16px'
              }}
            />
          )}
        </Tooltip>
      );
    } else if (row?._source?.Shipment?.CustomerContacted) {
      return (
        <Tooltip title={Constants.FulfillOrdersGrid.CustomerContacted}>
          {isBundle || isWarranty || isResourced ? (
            <MarkEmailUnreadIcon
              style={{
                color: Constants.Colors.red,
                height: '16px'
              }}
            />
          ) : (
            <MarkEmailUnreadIcon
              style={{
                color: Constants.Colors.red,
                height: '16px'
              }}
            />
          )}
        </Tooltip>
      );
    } else {
      <></>;
    }
  };

  const getDescription = (row: any) => {
    let description = "";
    // let count = row?._source?.Shipment?.ShipmentLines?.ShipmentLine?.length
    // if (count) {
    //     for (let i = 0; i < count; i++) {
    //         let element = row._source.Shipment.ShipmentLines.ShipmentLine[i]
    //         if (element.ExtnIsWarrantyItem == "N" && element.MinLineStatusDesc != "Cancelled") {
    //             description = element.ItemDesc
    //             break
    //         }
    //     }
    // }
    description = row?._source?.Shipment?.ShipmentDescription;
    return (
      <Tooltip title={description}>
        <p
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            padding: 0,
            fontSize: '13px'
          }}
        >
          {description}
        </p>
      </Tooltip>
    );
  };
  const getDescriptionText = (row: any) => {
    let description = "";
    let count = row?._source?.Shipment?.ShipmentLines?.ShipmentLine?.length;
    if (count) {
      for (let i = 0; i < count; i++) {
        let element = row._source.Shipment.ShipmentLines.ShipmentLine[i];
        if (
          element.ExtnIsWarrantyItem == "N" &&
          element.MinLineStatusDesc != "Cancelled"
        ) {
          description = element.ItemDesc;
          break;
        }
      }
    }
    return description;
  };
  const getLevelOfService = (row: any) => {
    let los = "";
    if (
      row._source.Shipment.IsSameStorePickup != undefined &&
      row._source.Shipment.IsSameStorePickup == "Y"
    ) {
      los = "Store Pickup";
    } else {
      los = row._source.Shipment.LevelOfService;
    }
    return los;
  };
  const getAgeTextFormat = (ms: any) => {
    const obj = Utils.convertHoursToDays(ms);

    if (obj.Days && obj.Hours) {
      return `${obj.Days} d(s) - ${obj.Hours} hr(s)`;
    }
    if (obj.Days) {
      return `${obj.Days} d(s) `;
    }
    if (obj.Hours) {
      return `${obj.Hours} hr(s) `;
    }
    if (obj.Minutes) {
      return `${obj.Minutes} min(s) `;
    }
    if (obj.Sec) {
      return `${obj.Sec} sec(s) `;
    } else {
      return ``;
    }
  };
  const getOrderNumberWithType = (row: any) => {
    //return [<span style={{ textWrap: "nowrap", fontFamily: "Roboto Mono", fontWeight: 700, fontSize: "13px" }}>{row._source.Shipment.OrderNo}</span>, getType(row)];
    return (
      <div>
        <span style={{ textWrap: "nowrap", fontFamily: "Roboto Mono", fontWeight: 700, fontSize: "13px" }}>{row._source.Shipment.OrderNo}</span>
        {getType(row)}
      </div>
    );
  };

  const getColumns = () => {
    const columns = [
      {
        name: "",
        cell: (row: any) => WebGridButton(row),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        width: "120px"
      },
      {
        id: "OrderNo",
        name: "Order Number",
        cell: (row: any) => getOrderNumberWithType(row),
        selector: (row: any) => row._source.Shipment.OrderNo,
        sortable: true,
        sortfield: "OrderNo",
        minWidth: "150px",
        width: "150px"
      },
      {
        id: "CustomerName",
        name: "Customer Name",
        selector: (row: any) => getCustomerName(row),
        sortable: true,
        sortfield: "CustomerName",
        minWidth: "170px",
        width: "170px",
        omit: currentTab != 3,
      },
      {
        id: "OrderDate",
        name: "Ordered On",
        cell: (row: any) => getDate(row),
        sortable: true,
        sortfield: "OrderDate",
        minWidth: "185px",
        width: "185px",
        omit: currentTab==3 //Hide for customer pickup queue
      },
      {
        id: "StatusDate",
        name: "Queue Date",
        cell: (row: any) => getStatusDate(row),
        sortable: true,
        sortfield: "StatusDate",
        minWidth: "185px",
        width: "185px",
        omit: currentTab!=3 //Only for customer pickup queue
      },
      {
        id: "LevelOfService",
        name: "Ship Method",
        selector: (row: any) => <span style={{ fontFamily: "Roboto Mono", fontSize: "13px", textWrap: "nowrap" }}>{getLevelOfService(row)}</span>,
        sortable: true,
        sortfield: "LevelOfService",
        minWidth: "180px",
        width: "180px",
        omit: currentTab == 3,
      },
      {
        id: "Qty",
        name: "Qty",
        //selector: (row: any) => Utils.getCount(row),
        cell: (row: any) => <span style={{ fontFamily: "Roboto Mono", fontSize: "13px" }}>{row._source.Shipment.qtyCountCalculated}</span>,
        sortable: true,
        sortfield: "Qty",
        width: "50px",
        minWidth: "50px"
      },
      // {
      //     name: 'Line Type',
      //     cell: (row: any) => getType(row),
      //     sortable: true,
      //     minWidth: "120px",
      //     width: "120px"
      // },
      {
        id: "Age",
        name: "Age",
        cell: (row: any) => getAgeTextFormat(row._source.Shipment.Age),
        selector: (row: any) => row._source.Shipment.Age,
        sortable: true,
        sortfield: "Age",
        minWidth: "80px",
        width: "80px"
      },
      {
        id: "Store",
        name: "Store",
        selector: (row: any) =>
          row._source.Shipment.Status ==
            ShipmentStatusConstant.ReadyForCustomerPickup
            ? row._source.Shipment?.ExtnPickupStoreID
            : row._source.Shipment.ShipNode,
        sortable: true,
        sortfield: "Store",
        minWidth: "85px",
        width: "85px",
        omit: props.store?.length <= 1,
      },
      {
        id: "Description",
        name: "Description",
        cell: (row: any) => getDescription(row),
        sortable: true,
        sortfield: "Description",
        minWidth: '370px',
        maxWidth: '700px'
      },
    ];
    return columns;
  };

  const clearFilter = () => {
    if (Utils.isMobile) {
      resetMobileScrolling();
    }
    dispatch(allActions.fulfillOrdersActions.changeSelectedOrderType("ALL"));
    dispatch(allActions.fulfillOrdersActions.changeSelectedOrderAge(""));
    dispatch(allActions.fulfillOrdersActions.changeSelectedOrderValue(""));
  };
  function getSum(total: number, item: any) {
    return total + item.doc_count;
  }

  const getDataFromElastic = async () => {
    let storeId = IdentityManager.getMultipleStoresId(props.store);
    if (!storeId) {
      return;
    }

    console.log("getDataFromElastic");
    setIsLoading(true);
    showActionLoader();
    let categors = OMSManager?.catalogCategories?.Category;
    if (!categors || categors.length == 0) {
      await getCategoryCatalog();
    }
    let aggPayload = {
      store: storeId,
    };
    let storeID = storeId; // "110"
    let allOrdersCount = 0;
    let customerPickOrdersCount = 0;
    let packOrdersCount = 0;
    let pickOrdersCount = 0;

    OrderManager.getOrdersAgg(aggPayload).then((aggResult: any) => {
      try {
        if (aggResult.success) {
          let customerPickOrdersAggregate =
            aggResult.data.aggregations.CustomerPickOrders.unique_orders?.value;
          let packOrdersAggregate =
            aggResult.data.aggregations.orderCountByType.buckets.PackOrders
              .doc_count;
          let pickOrdersAggregate =
            aggResult.data.aggregations.orderCountByType.buckets.PickOrders
              .doc_count;
          let allOrdersAggregate =
            Utils.parseIntCustom(customerPickOrdersAggregate) +
            Utils.parseIntCustom(packOrdersAggregate) +
            Utils.parseIntCustom(pickOrdersAggregate);

          if (allOrdersAggregate != undefined) {
            allOrdersCount = allOrdersAggregate;
          }
          if (customerPickOrdersAggregate != undefined) {
            customerPickOrdersCount = customerPickOrdersAggregate;
          }
          if (packOrdersAggregate != undefined) {
            packOrdersCount = packOrdersAggregate;
          }
          if (pickOrdersAggregate != undefined) {
            pickOrdersCount = pickOrdersAggregate;
          }

          let tabCounts = [
            {
              name: Constants.TabCounts[0].name,
              valueCount: allOrdersCount,
            },
            {
              name: Constants.TabCounts[1].name,
              valueCount: pickOrdersCount,
            },
            {
              name: Constants.TabCounts[2].name,
              valueCount: packOrdersCount,
            },
            {
              name: Constants.TabCounts[3].name,
              valueCount: customerPickOrdersCount,
            },
          ];
          dispatch(
            allActions.fulfillOrdersActions.changeFOTabCounts(tabCounts)
          );
        } else {
          resetTabsCount();
          console.log("Error Order Aggregate Count=" + aggResult.msg);
        }
      } catch {
        resetTabsCount();
        console.log("Error Order Aggregate Count");
      }
    });

    let orderTypeDecision = "";
    if (currentTab == 1) {
      orderTypeDecision = ShipmentStatusConstant.ReadyForPickString;
    } else if (currentTab == 2) {
      orderTypeDecision = ShipmentStatusConstant.ReadyForPackString;
    } else if (currentTab == 3) {
      orderTypeDecision = ShipmentStatusConstant.ReadyForCustomerPickupString;
    }

    let isExpaditedInfo: IFilterInfo = {
      include: false,
      value: "",
    };
    let isSameStorePickupInfo: IFilterInfo = {
      include: false,
      value: "",
    };
    let isShipToCustomerInfo: IFilterInfo = {
      include: false,
      value: "",
    };
    let isShipToStorePickupInfo: IFilterInfo = {
      include: false,
      value: "",
    };

    let onHoldOrders: IFilterInfo = {
      include: false,
      value: "",
    };

    if (selectedOrderTypeFilter == Constants.OrderTypeFilters[1].name) {
      isExpaditedInfo.include = true;
      isExpaditedInfo.value = "Y";
    }
    if (selectedOrderTypeFilter == Constants.OrderTypeFilters[2].name) {
      isSameStorePickupInfo.include = true;
      isSameStorePickupInfo.value = "Y";
    }
    if (selectedOrderTypeFilter == Constants.OrderTypeFilters[3].name) {
      isShipToCustomerInfo.include = true;
      isShipToCustomerInfo.value = "Y";
    }
    if (selectedOrderTypeFilter == Constants.OrderTypeFilters[4].name) {
      isShipToStorePickupInfo.include = true;
      isShipToStorePickupInfo.value = "Y";
    }
    if (selectedOrderTypeFilter == Constants.OrderTypeFilters[5].name) {
      onHoldOrders.include = true;
      onHoldOrders.value = ShipmentStatusConstant.OnHold;
    }

    let orderAgeFilter: IDateFilterValue = {
      include: false,
      startDate: "",
      endDate: "",
    };

    if (selectedOrderAgeFilter != "") {
      if (selectedOrderAgeFilter == Constants.OrderAgeFilters[3].name) {
        let startDateEndDate = Utils.GetStartEndDate(selectedOrderAgeFilter);
        orderAgeFilter.include = true;
        orderAgeFilter.endDate = startDateEndDate.end;
      } else {
        let startDateEndDate = Utils.GetStartEndDate(selectedOrderAgeFilter);
        orderAgeFilter.include = true;
        orderAgeFilter.startDate = startDateEndDate.start;
        orderAgeFilter.endDate = startDateEndDate.end;
      }
    }
    let orderValueFilter: IOrderValueFilter = {
      include: false,
      min: "",
      max: "",
    };
    if (selectedOrderValueFilter == Constants.OrderValueFilters[0].name) {
      orderValueFilter.include = true;
      orderValueFilter.min = "0";
      orderValueFilter.max = "25";
    }
    if (selectedOrderValueFilter == Constants.OrderValueFilters[1].name) {
      orderValueFilter.include = true;
      orderValueFilter.min = "25";
      orderValueFilter.max = "100";
    }
    if (selectedOrderValueFilter == Constants.OrderValueFilters[2].name) {
      orderValueFilter.include = true;
      orderValueFilter.min = "100";
      orderValueFilter.max = "500";
    }
    if (selectedOrderValueFilter == Constants.OrderValueFilters[3].name) {
      orderValueFilter.include = true;
      orderValueFilter.min = "500";
      orderValueFilter.max = "500000";
    }
    let from = Utils.isMobile
      ? mobilePage * Constants.FulfillOrdersGrid.MobileRowsPerPage
      : page * rowsPerPage;
    let size = Utils.isMobile
      ? Constants.FulfillOrdersGrid.MobileRowsPerPage
      : rowsPerPage;
    let queryParameters: IOrderQuery = {
      store: storeID,
      onHoldOrders: onHoldOrders,
      isExpedite: isExpaditedInfo,
      isShipToCustomer: isShipToCustomerInfo,
      isSameStorePickup: isSameStorePickupInfo,
      isShipToStore: isShipToStorePickupInfo,
      orderType: orderTypeDecision,
      pFrom: from,
      pSize: size,
      orderAge: orderAgeFilter,
      orderValue: orderValueFilter,
      sortField: sort.sortField,
      sortOrder: sort.sortOrder,
      //hideZero: hideZero,
    };

    let resultResponse = {} as any;
    try {
      resultResponse = await OrderManager.getOrders(queryParameters);
      if (resultResponse.success) {
        let result = resultResponse.data;
        let total = result.hits.total.value;
        console.log(total, "total2");
        let dataRows = result.hits.hits;
        // dataRows.forEach((element: any) => {
        //     //element._source.Shipment["Age"] = Utils.getOrderAge(element);
        //element._source.Shipment["defaultExpanded"] = Utils.getRowCollaprsedItemDistinctBase(element);
        // });
        let isExpediteCount = result.nAggregate.IsExpediteCount;
        let isSameStorePickupCount = result.nAggregate.IsSameStorePickupCount;
        let isShipToCustomerCount = result.nAggregate.IsShipToCustomerCount;
        let isShipToStorePickupCount = result.nAggregate.IsShipToStoreCount;
        let onHoldOrdersCount = result.nAggregate.onHoldOrdersCount;

        let orderTypeFiler = [
          {
            name: Constants.OrderTypeFilters[0].name,
            valueCount: total,
          },
          {
            name: Constants.OrderTypeFilters[1].name,
            valueCount: isExpediteCount,
          },
          {
            name: Constants.OrderTypeFilters[2].name,
            valueCount: isSameStorePickupCount,
          },
          {
            name: Constants.OrderTypeFilters[3].name,
            valueCount: isShipToCustomerCount,
          },
          {
            name: Constants.OrderTypeFilters[4].name,
            valueCount: isShipToStorePickupCount,
          },
          {
            name: Constants.OrderTypeFilters[5].name,
            valueCount: onHoldOrdersCount,
          },
        ];
        let oneDayCount = 0;
        let twoDayCount = 0;
        let threeDayCount = 0;
        let otherDayCount = 0;
        let oneDayAggregate =
          result.aggregations.orderAge.buckets.OneDay.doc_count;
        let twoDayAggregate =
          result.aggregations.orderAge.buckets.TwoDay.doc_count;
        let threeDayAggregate =
          result.aggregations.orderAge.buckets.ThreeDay.doc_count;
        let otherDayAggregate =
          result.aggregations.orderAge.buckets.OtherDay.doc_count;
        if (oneDayAggregate != undefined) {
          oneDayCount = oneDayAggregate;
        }
        if (twoDayAggregate != undefined) {
          twoDayCount = twoDayAggregate;
        }
        if (threeDayAggregate != undefined) {
          threeDayCount = threeDayAggregate;
        }
        if (otherDayAggregate != undefined) {
          otherDayCount = otherDayAggregate;
        }
        let orderageFilter = [
          {
            name: Constants.OrderAgeFilters[0].name,
            valueCount: oneDayCount,
          },
          {
            name: Constants.OrderAgeFilters[1].name,
            valueCount: twoDayCount,
          },
          {
            name: Constants.OrderAgeFilters[2].name,
            valueCount: threeDayCount,
          },
          {
            name: Constants.OrderAgeFilters[3].name,
            valueCount: otherDayCount,
          },
        ];
        let zeroToTwentyFive = 0;
        let twentyFiveToHundred = 0;
        let hundredToFiveHundred = 0;
        let fiveHundredToInfinity = 0;
        // zeroToTwentyFive = result.aggregations['0_25'].buckets.reduce(getSum, 0)
        // twentyFiveToHundred = result.aggregations['25_100'].buckets.reduce(getSum, 0)
        // hundredToFiveHundred = result.aggregations['100_500'].buckets.reduce(getSum, 0)
        // fiveHundredToInfinity = result.aggregations['500_5000000'].buckets.reduce(getSum, 0)
        zeroToTwentyFive = result.orderValueAggregate.lessThan25;
        twentyFiveToHundred = result.orderValueAggregate.between25_100;
        hundredToFiveHundred = result.orderValueAggregate.between100_500;
        fiveHundredToInfinity = result.orderValueAggregate.greaterThan500;

        let orderValueFilter = [
          {
            name: Constants.OrderValueFilters[0].name,
            valueCount: zeroToTwentyFive,
          },
          {
            name: Constants.OrderValueFilters[1].name,
            valueCount: twentyFiveToHundred,
          },
          {
            name: Constants.OrderValueFilters[2].name,
            valueCount: hundredToFiveHundred,
          },
          {
            name: Constants.OrderValueFilters[3].name,
            valueCount: fiveHundredToInfinity,
          },
        ];
        dispatch(
          allActions.fulfillOrdersActions.changeOrderValueFilter(
            orderValueFilter
          )
        );
        dispatch(
          allActions.fulfillOrdersActions.changeOrderTypeFilter(orderTypeFiler)
        );
        dispatch(
          allActions.fulfillOrdersActions.changeOrderAgeFilter(orderageFilter)
        );
        dispatch(allActions.fulfillOrdersActions.changeTotal(total));
        if (Utils.isMobile && mobilePage > 0) {
          let prevOrderRows = orderRows;
          if (dataRows.length > 0) {
            prevOrderRows.push(...dataRows);
          } else if (dataRows.length == 0 && prevOrderRows.length > 0) {
            setNoMobileRecords(true);
          }
          dispatch(
            allActions.fulfillOrdersActions.changeOrderRows(prevOrderRows)
          );
        } else {
          dispatch(allActions.fulfillOrdersActions.changeOrderRows(dataRows));
        }
      } else {
        hideActionLoader();
        setIsLoading(false);
        let message =
          "Something went wrong please contact the IT Help Desk for assistance";
        console.log(resultResponse.msg);
        setErrorMsg(message);
        setErrorReportMsg(message);
        setReportErrorAction(
          Constants.REPORT_ERROR_ACTIONS.FULLFILLED_ORDERS_DATA_FRM_ELASTIC
        );
        openErrorPopup();
      }
    } catch (e) {
      hideActionLoader();
      setIsLoading(false);
      let message =
        "Something went wrong please contact the IT Help Desk for assistance";

      console.log(resultResponse.msg);
      setErrorMsg(message);
      setErrorReportMsg(message);
      setReportErrorAction(
        Constants.REPORT_ERROR_ACTIONS.FULLFILLED_ORDERS_DATA_FRM_ELASTIC
      );
      openErrorPopup();
    }

    hideActionLoader();
    setIsLoading(false);
  };

  const getCategoryCatalog = async () => {
    await OMSManager.GCGetEComCatalog().then((data: any) => {
      OMSManager.catalogCategories = data.data;
      console.log(OMSManager.catalogCategories, "OMSManager.catalogCategories");
    });
  };

  const setLocalStorageAwayTime = () => {
    localStorage.setItem("storageCreateDate", moment().toISOString());
  };

  React.useEffect(() => {
    resetLocalStorage();
    return () => {
      // Destructor
      setLocalStorageAwayTime();
    };
  }, []);

  React.useEffect(() => {
    checkIntermediate();
  }, [ordersToPrint]);

  React.useEffect(() => {
    async function sendRequest() {
      await getDataFromElastic();
    }
    if (!noMobileRecords) {
      sendRequest();
    }
    return () => { };
  }, [
    currentTab,
    selectedOrderTypeFilter,
    selectedOrderAgeFilter,
    selectedOrderValueFilter,
    props.store,
    sort,
    page,
    rowsPerPage,
    status,
    mobilePage
  ]);

  React.useEffect(() => {
    if (Utils.isMobile) {
      resetMobileScrolling();
    }
  }, [props.store]);

  const renderTabCount = (tab: number) => {
    return " (" + tabCounts[tab].valueCount + ")";
  };

  const resetTabsCount = () => {
    let tabCounts = [
      {
        name: Constants.TabCounts[0].name,
        valueCount: 0,
      },
      {
        name: Constants.TabCounts[1].name,
        valueCount: 0,
      },
      {
        name: Constants.TabCounts[2].name,
        valueCount: 0,
      },
      {
        name: Constants.TabCounts[3].name,
        valueCount: 0,
      },
    ];
    dispatch(allActions.fulfillOrdersActions.changeFOTabCounts(tabCounts));
  };

  const renderOrderTypeTabs = () => {
    return (
      <Box sx={{ mb: 2, p: 2 }} style={{padding: "0px 5px", marginBottom: "5px"}}>
        <AppBar elevation={0} position="static" style={{ background: "none" }}>
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            className="fTabs"
            style={{ background: "none" }}
          >
            <Tab
              icon={<SubjectIcon className="fTabIcons" />}
              label={"All Orders" + renderTabCount(Constants.TabCounts[0].name)}
            />
            <Tab
              icon={<ShoppingCartOutlinedIcon className="fTabIcons" />}
              label={"Picking" + renderTabCount(Constants.TabCounts[1].name)}
            />
            <Tab
              icon={<svg className="fTabIcons" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.41667 5.66667V17.5833H17.5833V5.66667H14.3333V14.3333L10 12.1667L5.66667 14.3333V5.66667H2.41667ZM2.41667 19.75C1.82083 19.75 1.31076 19.5378 0.886458 19.1135C0.462153 18.6892 0.25 18.1792 0.25 17.5833V4.06875C0.25 3.81597 0.290625 3.57222 0.371875 3.3375C0.453125 3.10278 0.575 2.88611 0.7375 2.6875L2.09167 1.03542C2.29028 0.782639 2.53854 0.588542 2.83646 0.453125C3.13438 0.317708 3.44583 0.25 3.77083 0.25H16.2292C16.5542 0.25 16.8656 0.317708 17.1635 0.453125C17.4615 0.588542 17.7097 0.782639 17.9083 1.03542L19.2625 2.6875C19.425 2.88611 19.5469 3.10278 19.6281 3.3375C19.7094 3.57222 19.75 3.81597 19.75 4.06875V17.5833C19.75 18.1792 19.5378 18.6892 19.1135 19.1135C18.6892 19.5378 18.1792 19.75 17.5833 19.75H2.41667ZM2.85 3.5H17.15L16.2292 2.41667H3.77083L2.85 3.5ZM7.83333 5.66667V10.8125L10 9.72917L12.1667 10.8125V5.66667H7.83333Z" fill="#333333" />
              </svg>
              }
              label={"Packing" + renderTabCount(Constants.TabCounts[2].name)}
            />
            <Tab
              icon={<NearMeOutlinedIcon className="fTabIcons" />}
              label={
                "Customer Pickup" + renderTabCount(Constants.TabCounts[3].name)
              }
            />
          </Tabs>
        </AppBar>
      </Box>
    );
  };
  const detailDrawerOpen = (id: any) => {
    //
    let selectedOrder = orderRows.filter((order: any) => {
      return order._id == id;
    });
    if (selectedOrder && selectedOrder.length > 0) {
      setRowDetail(selectedOrder[0]);
      setOpenDetailDrawer(true);
    }
  };
  const renderDetailDrawer = () => {
    return (

      <React.Fragment>

        <Card className="dCard" style={{ backgroundColor: "#F5F5F5" }}>
          <CardContent className={Utils.isMobile ? "" : "dCardContent"} >
            <div className="topHeading">
              <div className="gridHeading">
                <Typography style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography className="card-heading"> Order Details</Typography>
                  <div
                    onClick={() => {
                      setOpenDetailDrawer(false);
                    }}

                  >
                    <CloseIcon className="card-heading" />
                  </div>
                </Typography>
              </div>
            </div>
            <Grid container rowSpacing={2}>
              <Grid item xs={12} style={{display:"flex"}}>
                <Grid item xs={5}>
                  
                  <Typography className="itemHeading" >Order #</Typography>
                  <Typography className="itemElement" >{rowDetail._source.Shipment.OrderNo}</Typography>
                </Grid>
                
                <Grid item xs={5} style={{paddingLeft:"5px"}}>
                  <Typography className="itemHeading">Ship Method</Typography>
                  <Typography className="itemElement">{rowDetail._source.Shipment.LevelOfService}</Typography>
                </Grid>
                <Grid item xs={2} style={{paddingLeft:"5px"}}>
                  <Typography className="itemHeading" >Qty</Typography>
                  <Typography className="itemElement">{Utils.getCount(rowDetail)}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{display:"flex"}} rowSpacing={2}>
                <Grid item xs={5}>
                  <Typography className="itemHeading" >{customerPickupStatus(rowDetail)? "Queue Date" : "Ordered On"}</Typography>
                  <Typography className="itemElement">{getDateForDrawer(rowDetail)}</Typography>
                </Grid>
                
                <Grid item xs={5} style={{paddingLeft:"5px"}}>
                  <Typography className="itemHeading">Age</Typography>
                  <Typography className="itemElement">{getAgeTextFormat(rowDetail._source.Shipment.Age)}</Typography>
                </Grid>
                {props.store?.length > 1 ?<Grid item xs={2} style={{paddingLeft:"5px"}}>
                  <Typography className="itemHeading" >Store</Typography>
                  <Typography className="itemElement">{rowDetail._source.Shipment.Status ==
                    ShipmentStatusConstant.ReadyForCustomerPickup
                    ? rowDetail._source.Shipment?.ExtnPickupStoreID
                    : rowDetail._source.Shipment.ShipNode}</Typography>
                </Grid>:<></>}
              </Grid>
            </Grid>
            <Divider style={{marginTop:"2px"}} className="card-divider"/>
            <ExpandedRow data={rowDetail}></ExpandedRow>
          </CardContent>
        </Card>
      </React.Fragment>





      // <Card sx={{ mt: 1 }} className="orderDetailMobileContainer">
      //   <CardContent style={{backgroundColor: "#F9F9F9"}}>
      //     {Utils.isMobile && <Stack direction={"row"}>
      //       <Typography fontWeight="700">Order Details</Typography>
      //     </Stack>}
      //     <Stack direction={"row"} >
      //       <TextField
      //         id="outlined-read-only-input"
      //         label="Order #"
      //         defaultValue={rowDetail._source.Shipment.OrderNo}
      //         InputProps={{
      //           readOnly: true,
      //         }}
      //         variant="standard"
      //         style={{ margin: "4px" }}
      //       />
      //       <TextField
      //         id="outlined-read-only-input"
      //         label="Ship Method"
      //         defaultValue={rowDetail._source.Shipment.LevelOfService}
      //         InputProps={{
      //           readOnly: true,
      //         }}
      //         variant="standard"
      //         style={{ margin: "4px" , fontWeight: "bold" }}
      //       />
      //     </Stack>
      //     <Stack direction={"row"}>
      //       <TextField
      //         id="outlined-read-only-input"
      //         label="Qty"
      //         defaultValue={Utils.getCount(rowDetail)}
      //         InputProps={{
      //           readOnly: true,
      //         }}
      //         variant="standard"
      //         style={{ margin: "4px" }}
      //       />
      //       <TextField
      //         id="outlined-read-only-input"
      //         label="Ordered On"
      //         defaultValue={getDateForDrawer(rowDetail)}
      //         InputProps={{
      //           readOnly: true,
      //         }}
      //         variant="standard"
      //         style={{ margin: "4px" }}
      //       />
      //     </Stack>

      //     <ExpandedRow data={rowDetail}></ExpandedRow>
      //   </CardContent>
      // </Card>
    );
  };
  const renderCountOfOrderTypeFilter = (filter: any) => {
    let renderCount = filter.valueCount == 0 ? "" : filter.valueCount;
    return renderCount != "" ? (
      <Box borderRadius="50%">
        <b style={{ fontSize: 12, marginTop: 3 }}>({renderCount})</b>
      </Box>
    ) : (
      ""
    );
  };
  const renderCountOfOrderAgeFilter = (filter: any) => {
    let renderCount = filter.valueCount == 0 ? "" : filter.valueCount;
    return renderCount != "" ? (
      <Box>
        <b style={{ fontSize: 12, marginTop: 3 }}>({renderCount})</b>
      </Box>
    ) : (
      ""
    );
  };
  const renderCountOfOrderValueFilter = (filter: any) => {
    let renderCount = filter.valueCount == 0 ? "" : filter.valueCount;
    return renderCount != "" ? (
      <Box>
        <b style={{ fontSize: 12, marginTop: 3 }}>({renderCount})</b>
      </Box>
    ) : (
      ""
    );
  };

  const FilterItem = ({ checked, onChange, label, count }: any) => {
    return (
      <div className={`filterSection item ${checked ? "filterOutline" : ""}`}>
        <div className={`filterSection filterName`}>
          <Checkbox

            checkedIcon={<CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>}
            onChange={(event: any, checked: boolean) => {
              onChange();
            }}
            checked={checked}
          />
          <span>{label}</span>
        </div>
        <span>{count}</span>
      </div>
    );
  };
  const checkFilters = () => {
    return selectedOrderTypeFilter != "ALL" || Boolean(selectedOrderAgeFilter) || Boolean(selectedOrderValueFilter);
  }

  const renderWebTabs = () => {
    return <Grid container style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
          <Grid item xs={8} md={8}>
            <AppBar elevation={0} position="static" style={{ background: "none" }}>
              <Tabs
                value={currentTab}
                onChange={handleChangeTab}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                className="fTabs"
                style={{ background: "none" }}
              >
                <Tab
                  icon={<SubjectIcon className="fTabIcons" />}
                  label={"All Orders" + renderTabCount(Constants.TabCounts[0].name)}
                />
                <Tab
                  icon={<ShoppingCartOutlinedIcon className="fTabIcons" />}
                  label={"Picking" + renderTabCount(Constants.TabCounts[1].name)}
                />
                <Tab
                  icon={<svg className="fTabIcons" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.41667 5.66667V17.5833H17.5833V5.66667H14.3333V14.3333L10 12.1667L5.66667 14.3333V5.66667H2.41667ZM2.41667 19.75C1.82083 19.75 1.31076 19.5378 0.886458 19.1135C0.462153 18.6892 0.25 18.1792 0.25 17.5833V4.06875C0.25 3.81597 0.290625 3.57222 0.371875 3.3375C0.453125 3.10278 0.575 2.88611 0.7375 2.6875L2.09167 1.03542C2.29028 0.782639 2.53854 0.588542 2.83646 0.453125C3.13438 0.317708 3.44583 0.25 3.77083 0.25H16.2292C16.5542 0.25 16.8656 0.317708 17.1635 0.453125C17.4615 0.588542 17.7097 0.782639 17.9083 1.03542L19.2625 2.6875C19.425 2.88611 19.5469 3.10278 19.6281 3.3375C19.7094 3.57222 19.75 3.81597 19.75 4.06875V17.5833C19.75 18.1792 19.5378 18.6892 19.1135 19.1135C18.6892 19.5378 18.1792 19.75 17.5833 19.75H2.41667ZM2.85 3.5H17.15L16.2292 2.41667H3.77083L2.85 3.5ZM7.83333 5.66667V10.8125L10 9.72917L12.1667 10.8125V5.66667H7.83333Z" fill="#333333" />
                  </svg>
                  }
                  label={"Packing" + renderTabCount(Constants.TabCounts[2].name)}
                />
                <Tab
                  icon={<NearMeOutlinedIcon className="fTabIcons" />}
                  label={
                    "Customer Pickup" + renderTabCount(Constants.TabCounts[3].name)
                  }
                />
              </Tabs>
            </AppBar>
          </Grid>
          <Grid item xs={2} md={2}>
          </Grid>
          <Grid item xs={2} md={2}>
            <Button onClick={handleFilterChange} className={checkFilters() ? "iconBtn selected" : "iconBtn"} style={{height:"42px",textTransform:"none",float:"right"}}>
              <FilterListIcon />
              Filter
            </Button>
          </Grid>
        </Grid>
  }
  const renderFilters = () => {
    let open = isOpenFilters;
    let orderAgeTotalCount = orderAgeFilters.reduce(function (
      sum: any,
      current: any
    ) {
      return sum + Number(current.valueCount);
    },
      0);
    let orderValueTotalCount = orderValueFilters.reduce(function (
      sum: any,
      current: any
    ) {
      return sum + Number(current.valueCount);
    },
      0);
    if (Utils.isMobile) {
      open = true;
    }
    return (
      <Box sx={{ p: 4, display: "flex", justifyContent: "space-between" }} style={{padding:Utils.isMobile? "0px": "10px 32px 10px 32px"}}>
        {Utils.isMobile? 
          <>
            {/* <Typography style={{ fontWeight: 700, fontSize: "32px" }} variant="h5">
              Fulfill Orders
            </Typography>
            <div onClick={handleFilterChange} className={checkFilters() ? "iconBtn selected" : "iconBtn"}>
              <FilterListIcon />
              Filter
            </div> */}
          </>: 
          renderWebTabs()
        }
        <Drawer
          anchor={"right"}
          open={isOpenFilters}
          onClose={handleFilterChange}
          onClick={() => {
            // setOpenDetailDrawer(false);
          }}
          style={{ zIndex: 9999999 }}
        >
          {isOpenFilters ? (
            <>
            <div className="filterDrawer">
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="heading" style={{ marginLeft: "8px" }}>Filters</span>
                <div
                  onClick={() => {
                    handleFilterChange();
                  }}
                  className="close-icon" style={{ marginRight: 0 }}
                >
                  <CloseIcon style={{ fontSize: 18 }} />
                </div>
              </div>
              <div className="filterSection">
                <span className="filterSectionTitle">Order Type</span>
                <div className="filterSection filters">
                  <FilterItem
                    onChange={() => {
                      quickFilterClicked("OrderType", orderTypeFilters[0].name);
                    }}
                    checked={selectedOrderTypeFilter == "ALL"}
                    label="All"
                    count={renderCountOfOrderTypeFilter(orderTypeFilters[0])}
                  />
                  <FilterItem
                    onChange={() => {
                      quickFilterClicked("OrderType", orderTypeFilters[1].name);
                    }}
                    checked={selectedOrderTypeFilter == "EXPEDITED"}
                    label="Expedited"
                    count={renderCountOfOrderTypeFilter(orderTypeFilters[1])}
                  />
                  <FilterItem
                    onChange={() => {
                      quickFilterClicked("OrderType", orderTypeFilters[2].name);
                    }}
                    checked={selectedOrderTypeFilter == "INSTORE"}
                    label="In Store"
                    count={renderCountOfOrderTypeFilter(orderTypeFilters[2])}
                  />
                  <FilterItem
                    onChange={() => {
                      quickFilterClicked("OrderType", orderTypeFilters[3].name);
                    }}
                    checked={selectedOrderTypeFilter == "SHIPTOCUSTOMER"}
                    label="Ship To Customer"
                    count={renderCountOfOrderTypeFilter(orderTypeFilters[3])}
                  />
                  <FilterItem
                    onChange={() => {
                      quickFilterClicked("OrderType", orderTypeFilters[4].name);
                    }}
                    checked={selectedOrderTypeFilter == "SHIPTOSTORE"}
                    label="Ship To Store"
                    count={renderCountOfOrderTypeFilter(orderTypeFilters[4])}
                  />
                  {(currentTab == 0 || currentTab == 1) && (
                    <FilterItem
                      onChange={() => {
                        quickFilterClicked(
                          "OrderType",
                          orderTypeFilters[5].name
                        );
                      }}
                      checked={selectedOrderTypeFilter == "ONHOLD"}
                      label="On Hold"
                      count={renderCountOfOrderTypeFilter(orderTypeFilters[5])}
                    />
                  )}
                </div>
              </div>
              <div className="filterSection">
                <span className="filterSectionTitle">Order Age (days)</span>
                {orderAgeTotalCount == 0 && selectedOrderAgeFilter == "" ? (
                  <div className="filterSection filters">
                    <Typography
                      className=""
                      style={{
                        color: "black",
                        marginTop: 10,
                        marginBottom: 10,
                        fontSize: "16px",
                        fontWeight: "250",
                      }}
                    >
                      No Order Age
                    </Typography>
                  </div>
                ) : (
                  <div className="filterSection filters">
                    <FilterItem
                      onChange={() => {
                        quickFilterClicked("OrderAge", orderAgeFilters[0].name);
                      }}
                      checked={selectedOrderAgeFilter == "0-1"}
                      label="0-1"
                      count={renderCountOfOrderAgeFilter(orderAgeFilters[0])}
                    />
                    <FilterItem
                      onChange={() => {
                        quickFilterClicked("OrderAge", orderAgeFilters[1].name);
                      }}
                      checked={selectedOrderAgeFilter == "1-2"}
                      label="1-2"
                      count={renderCountOfOrderAgeFilter(orderAgeFilters[1])}
                    />
                    <FilterItem
                      onChange={() => {
                        quickFilterClicked("OrderAge", orderAgeFilters[2].name);
                      }}
                      checked={selectedOrderAgeFilter == "2-3"}
                      label="2-3"
                      count={renderCountOfOrderAgeFilter(orderAgeFilters[2])}
                    />
                    <FilterItem
                      onChange={() => {
                        quickFilterClicked("OrderAge", orderAgeFilters[3].name);
                      }}
                      checked={selectedOrderAgeFilter == "g3"}
                      label=">3"
                      count={renderCountOfOrderAgeFilter(orderAgeFilters[3])}
                    />
                  </div>
                )}
              </div>

              <div className="filterSection">
                <span className="filterSectionTitle">Order Value</span>
                {orderValueTotalCount == 0 && selectedOrderValueFilter == "" ? (
                  <div className="filterSection filters">
                    <Typography
                      className=""
                      style={{
                        color: "black",
                        marginTop: 10,
                        marginBottom: 10,
                        fontSize: "16px",
                        fontWeight: "250",
                      }}
                    >
                      No Order Value
                    </Typography>
                  </div>
                ) : (
                  <div className="filterSection filters">
                    <FilterItem
                      onChange={() => {
                        quickFilterClicked(
                          "OrderValue",
                          orderValueFilters[0].name
                        );
                      }}
                      checked={
                        selectedOrderValueFilter == orderValueFilters[0].name
                      }
                      label="< $25"
                      count={renderCountOfOrderValueFilter(
                        orderValueFilters[0]
                      )}
                    />
                    <FilterItem
                      onChange={() => {
                        quickFilterClicked(
                          "OrderValue",
                          orderValueFilters[1].name
                        );
                      }}
                      checked={
                        selectedOrderValueFilter == orderValueFilters[1].name
                      }
                      label="$25 - $100"
                      count={renderCountOfOrderValueFilter(
                        orderValueFilters[1]
                      )}
                    />
                    <FilterItem
                      onChange={() => {
                        quickFilterClicked(
                          "OrderValue",
                          orderValueFilters[2].name
                        );
                      }}
                      checked={
                        selectedOrderValueFilter == orderValueFilters[2].name
                      }
                      label="$100 - $500"
                      count={renderCountOfOrderValueFilter(
                        orderValueFilters[2]
                      )}
                    />
                    <FilterItem
                      onChange={() => {
                        quickFilterClicked(
                          "OrderValue",
                          orderValueFilters[3].name
                        );
                      }}
                      checked={
                        selectedOrderValueFilter == orderValueFilters[3].name
                      }
                      label="> $500"
                      count={renderCountOfOrderValueFilter(
                        orderValueFilters[3]
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="filter-sticky-buttons">
                 <Button onClick={handleFilterChange} style={{ marginRight: '10px'}} variant='contained' className="filter-header-button underline">Close</Button>
                 <Button onClick={resetFilters} variant='contained' className="filter-header-button red">Reset</Button>
            </div>
            </>
          ) : null}
        </Drawer>
      </Box>
    );
  };
  const renderMobileFilters = () => {
    return (
      <Box
        sx={{ p: 3 }}
        style={{
          textAlign: "left",
          padding: "5px",
          paddingLeft: "5px",
          paddingTop: "10px",
          marginBottom: 5,
        }}
      >
        <React.Fragment>
          <Button
            className="btn"
            onClick={() => {
              setDrawer(true);
            }}
            style={{ width: "100%" }}
            startIcon={<FilterAltIcon />}
            variant="outlined"
            color="primary"
          >
            Quick Filters
          </Button>
          <Drawer
            anchor={"top"}
            open={openDrawer}
            onClose={() => { }}
            onClick={() => {
              setDrawer(false);
            }}
            style={{ zIndex: 9999999 }}
          >
            {renderFilters()}
          </Drawer>
        </React.Fragment>
      </Box>
    );
  };
  const ExpandedComponent = (data: any) => {
    return <ExpandedRow data={data.data}></ExpandedRow>;
  };
  const rowClicked = (row: any) => {
    //
    console.log(row, "rowClicked");
    let data = [...orderRows];
    if (data && data.length > 0) {
      data.forEach((item) => {
        if (item._id == row._id) {
          item._source.Shipment.defaultExpanded =
            !item._source.Shipment.defaultExpanded;
        }
      });
    }
    dispatch(allActions.fulfillOrdersActions.changeOrderRows(data));
  };
  const conditionalRowStyles = [
    // You can also pass a callback to style for additional customization
    {
      when: (row: any) => row._id,
      style: (row: any) => ({
        cursor: "pointer",
      }),
    },
  ];

  const handleSort = (column: any, sortOrder: any) => {
    if (column.sortfield) {
      setSort({ sortField: column.sortfield, sortOrder: sortOrder });
    }
  };

  const renderWebDataTable = () => {
    if (currentTab == 1) {
      return (
        <div className="fulfilledDataTable">
          <PickDataTable
            selectedStores={props.store}
            selectAll={selectAll}
            selectAllCheckBox={selectAllCheckBox}
            setSelectAllCheckBox={setSelectAllCheckBox}
            intermediate={intermediate}
            getDate={getDate}
            getOrderNumberWithType={getOrderNumberWithType}
            WebGridButton={WebGridButton}
            lineType={lineType}
            ordersToPrint={ordersToPrint}
            setordersToPrint={setordersToPrint}
            handleCheckboxChange={handleCheckboxChange}
            handleSort={handleSort}
            defaultSortFieldId={sort.sortField}
            defaultSortAsc={sort.sortOrder == "asc" ? true : false}
          ></PickDataTable>
        </div>
      );
    } else {
      return (
        <div className="fulfilledDataTable">
          <DataTable
            className="fulfilledDataTable"
            title=""
            data={orderRows}
            columns={getColumns()}
            onRowDoubleClicked={rowClicked}
            expandableRows
            conditionalRowStyles={conditionalRowStyles}
            highlightOnHover
            // expandableRowExpanded={row => row._source.Shipment.defaultExpanded}
            expandableRowsComponent={ExpandedComponent}
            sortServer
            onSort={handleSort}
            defaultSortFieldId={sort.sortField}
            defaultSortAsc={sort.sortOrder == "asc" ? true : false}
          />
        </div>
      );
    }
  };
  const PackButton = (status: any) => {
    if (status == ShipmentStatusConstant.ShipmentPacked) {
      return "Ship";
    } else {
      return "Pack";
    }
  };
  const PackIcon = (status: any) => {
    if (status == ShipmentStatusConstant.ShipmentPacked) {
      return <LocalShippingOutlinedIcon className="fTabIcons" />;
    } else {
      return <svg width="20" height="20" viewBox="0 0 20 20" fill="red" xmlns="http://www.w3.org/2000/svg" >
        <path d="M2.41667 5.66667V17.5833H17.5833V5.66667H14.3333V14.3333L10 12.1667L5.66667 14.3333V5.66667H2.41667ZM2.41667 19.75C1.82083 19.75 1.31076 19.5378 0.886458 19.1135C0.462153 18.6892 0.25 18.1792 0.25 17.5833V4.06875C0.25 3.81597 0.290625 3.57222 0.371875 3.3375C0.453125 3.10278 0.575 2.88611 0.7375 2.6875L2.09167 1.03542C2.29028 0.782639 2.53854 0.588542 2.83646 0.453125C3.13438 0.317708 3.44583 0.25 3.77083 0.25H16.2292C16.5542 0.25 16.8656 0.317708 17.1635 0.453125C17.4615 0.588542 17.7097 0.782639 17.9083 1.03542L19.2625 2.6875C19.425 2.88611 19.5469 3.10278 19.6281 3.3375C19.7094 3.57222 19.75 3.81597 19.75 4.06875V17.5833C19.75 18.1792 19.5378 18.6892 19.1135 19.1135C18.6892 19.5378 18.1792 19.75 17.5833 19.75H2.41667ZM2.85 3.5H17.15L16.2292 2.41667H3.77083L2.85 3.5ZM7.83333 5.66667V10.8125L10 9.72917L12.1667 10.8125V5.66667H7.83333Z" fill={Constants.Colors.red} />
      </svg >;
    }
  };
  const WebGridButton = (row: any) => {
    if (row._source.Shipment.Status) {
      switch (row._source.Shipment.Status) {
        case ShipmentStatusConstant.ReadyForPick:
          return (
            <React.Fragment>
              <Button
                startIcon={<ShoppingCartOutlinedIcon className="fTabIcons" />}
                variant="contained"
                color="primary"
                className="red-outline-btn"
                onClick={() => {
                  loadOrder(
                    row._source.Shipment.ShipmentKey,
                    row._source.Shipment.Status,
                    row._source.Shipment.OrderNo,
                    row._source.Shipment.ShipNode
                  );
                }}
              >
                Pick
              </Button>
            </React.Fragment>
          );
        case ShipmentStatusConstant.OnHold:
          return (
            <React.Fragment>
              <Button
                startIcon={<StopIcon className="fTabIcons" />}
                variant="contained"
                color="primary"
                title="On Hold"
                className="red-outline-btn on-hold"
                onClick={() => {
                  loadOrder(
                    row._source.Shipment.ShipmentKey,
                    row._source.Shipment.Status,
                    row._source.Shipment.OrderNo,
                    row._source.Shipment.ShipNode
                  );
                }}
              >
                Pick
              </Button>
            </React.Fragment>
          );
        case ShipmentStatusConstant.ReadyForPack:
        case ShipmentStatusConstant.ShipmentBeingPacked:
        case ShipmentStatusConstant.ShipmentPacked:
          return (
            <React.Fragment>
              <Button
                startIcon={PackIcon(row._source.Shipment.Status)}
                variant="contained"
                color="primary"
                className="red-outline-btn"
                onClick={() => {
                  loadOrder(
                    row._source.Shipment.ShipmentKey,
                    row._source.Shipment.Status,
                    row._source.Shipment.OrderNo,
                    row._source.Shipment.ShipNode
                  );
                }}
              >
                {PackButton(row._source.Shipment.Status)}
              </Button>
            </React.Fragment>
          );
        case ShipmentStatusConstant.ReadyForCustomerPickup:
          return (
            <React.Fragment>
              <Button
                startIcon={<NearMeOutlinedIcon className="fTabIcons" />}
                variant="contained"
                className="red-outline-btn"
                color="primary"
                onClick={() => {
                  loadOrder(
                    row._source.Shipment.ShipmentKey,
                    row._source.Shipment.Status,
                    row._source.Shipment.OrderNo,
                    row._source.Shipment.ExtnPickupStoreID
                      ? row._source.Shipment.ExtnPickupStoreID
                      : 0
                  );
                }}
              >
                Pickup
              </Button>
            </React.Fragment>
          );
      }
    }
  };
  const loadOrder = async (
    shipmentKey: any,
    type: any,
    orderNo?: any,
    storeId: any = 0
  ) => {
    showActionLoader();
    let detailStatus = true;
    await Utils.checkOrderDetailStatus({
      shipmentKey: shipmentKey,
      status: type,
    })
      .then((res: any) => {
        if (res?.success) {
          detailStatus = res.data;
        }
      })
      .catch((err: any) => {
        console.log("Order Detail Status Error", err);
      });

    switch (type) {
      case ShipmentStatusConstant.ReadyForPick:
      case ShipmentStatusConstant.OnHold:
        if (detailStatus) {
          hideActionLoader();
          if (storeId != 0) {
            lineType == 0
              ? navigate(
                "/PickOrder?orderno=" +
                orderNo +
                "&shipmentkey=" +
                shipmentKey +
                "&store=" +
                storeId +
                ""
              )
              : navigate("/BulkPickOrder?shipmentkey=" + shipmentKey + "");
          } else {
            lineType == 0
              ? navigate(
                "/PickOrder?orderno=" +
                orderNo +
                "&shipmentkey=" +
                shipmentKey +
                ""
              )
              : navigate("/BulkPickOrder?shipmentkey=" + shipmentKey + "");
          }
        } else {
          hideActionLoader();
          openInfoPopup();
        }
        break;

      case ShipmentStatusConstant.ReadyForPack:
      case ShipmentStatusConstant.ShipmentBeingPacked:
      case ShipmentStatusConstant.ShipmentPacked:
        if (detailStatus) {
          hideActionLoader();
          if (storeId != 0) {
            navigate(
              "/PackOrder?orderno=" +
              orderNo +
              "&shipmentkey=" +
              shipmentKey +
              "&store=" +
              storeId
            );
          } else {
            navigate(
              "/PackOrder?orderno=" +
              orderNo +
              "&shipmentkey=" +
              shipmentKey +
              ""
            );
          }
        } else {
          hideActionLoader();
          openInfoPopup();
        }
        break;
      case ShipmentStatusConstant.ReadyForCustomerPickup:
        if (detailStatus) {
          hideActionLoader();
          if (storeId != 0) {
            navigate(
              "/CustomerPickup?orderno=" +
              orderNo +
              "&shipmentkey=" +
              shipmentKey +
              "" +
              "&store=" +
              storeId +
              ""
            );
          } else {
            navigate(
              "/CustomerPickup?orderno=" +
              orderNo +
              "&shipmentkey=" +
              shipmentKey +
              ""
            );
          }
        } else {
          hideActionLoader();
          openInfoPopup();
        }
        break;
    }
  };

  const stepperButtonIcon = (type: any) => {
    switch (type) {
      case ShipmentStatusConstant.ReadyForPick:
        return <ShoppingCartOutlinedIcon style={{ fontSize: 18 }} />;
      case ShipmentStatusConstant.OnHold:
        return <StopIcon style={{ fontSize: 18 }} />;
      case ShipmentStatusConstant.ReadyForPack:
      case ShipmentStatusConstant.ShipmentBeingPacked:
        return <svg width="17" height="17" viewBox="0 0 20 20" fill="red" xmlns="http://www.w3.org/2000/svg" >
          <path d="M2.41667 5.66667V17.5833H17.5833V5.66667H14.3333V14.3333L10 12.1667L5.66667 14.3333V5.66667H2.41667ZM2.41667 19.75C1.82083 19.75 1.31076 19.5378 0.886458 19.1135C0.462153 18.6892 0.25 18.1792 0.25 17.5833V4.06875C0.25 3.81597 0.290625 3.57222 0.371875 3.3375C0.453125 3.10278 0.575 2.88611 0.7375 2.6875L2.09167 1.03542C2.29028 0.782639 2.53854 0.588542 2.83646 0.453125C3.13438 0.317708 3.44583 0.25 3.77083 0.25H16.2292C16.5542 0.25 16.8656 0.317708 17.1635 0.453125C17.4615 0.588542 17.7097 0.782639 17.9083 1.03542L19.2625 2.6875C19.425 2.88611 19.5469 3.10278 19.6281 3.3375C19.7094 3.57222 19.75 3.81597 19.75 4.06875V17.5833C19.75 18.1792 19.5378 18.6892 19.1135 19.1135C18.6892 19.5378 18.1792 19.75 17.5833 19.75H2.41667ZM2.85 3.5H17.15L16.2292 2.41667H3.77083L2.85 3.5ZM7.83333 5.66667V10.8125L10 9.72917L12.1667 10.8125V5.66667H7.83333Z" fill="#FFF" />
        </svg >;
      case ShipmentStatusConstant.ReadyForCustomerPickup:
        return <NearMeOutlinedIcon style={{ fontSize: 18 }} />;
      case ShipmentStatusConstant.ShipmentPacked:
        return <LocalShippingOutlinedIcon style={{ fontSize: 18 }} />;
    }
  };
  const stepstoShow = (type: any) => {
    switch (type) {
      case ShipmentStatusConstant.ReadyForPick:
      case ShipmentStatusConstant.OnHold:
        return "Pick";
      case ShipmentStatusConstant.ReadyForPack:
      case ShipmentStatusConstant.ShipmentBeingPacked:
        return "Pack";
      case ShipmentStatusConstant.ShipmentPacked:
        return "Ship";
      case ShipmentStatusConstant.ReadyForCustomerPickup:
        return "Pickup";
    }
  };
  const monileGridButton = (
    status: any,
    shipmentKey: any,
    orderNo: any,
    storeId: any
  ) => {
    // return (
    //   <div
    //     className={status != ShipmentStatusConstant.OnHold? "red-outline-btn":"red-outline-btn on-hold"}
    //     onClick={() => {
    //       loadOrder(shipmentKey, status, orderNo, storeId);
    //     }}
    //   >
    //     {stepperButtonIcon(status)}
    //     <Typography style={{ fontSize: "12px" }}>
    //       {stepstoShow(status)}
    //     </Typography>
    //   </div>
    // );
    return <React.Fragment>
            <Button onClick={() => { loadOrder(shipmentKey, status, orderNo, storeId) }} className={status == ShipmentStatusConstant.OnHold ? "mobile-list-btn on-hold" : "mobile-list-btn"}>
                {stepperButtonIcon(status)}
                <Typography style={{ fontSize: "12px",marginLeft:"3px" }}>
                    {stepstoShow(status)}
                </Typography>
            </Button>
        </React.Fragment>
  };
  const renderMobileData = () => {
    //MobilePickDataTable
    console.log(lineType, "lineType");
    if (lineType == 1) {
      return (
        <MobilePickDataTable
          data={orderRows}
          steps={steps}
          detailDrawerOpen={detailDrawerOpen}
          monileGridButton={monileGridButton}
          value={currentTab}
          noMobileRecords={noMobileRecords}
        ></MobilePickDataTable>
      );
    } else {
      return (
        <MobileDataTable
          data={orderRows}
          steps={steps}
          detailDrawerOpen={detailDrawerOpen}
          monileGridButton={monileGridButton}
          value={currentTab}
          store={props.store}
          noMobileRecords={noMobileRecords}
        ></MobileDataTable>
      );
    }
  };
  const renderPaginationAndSearch = () => {
    if (!Utils.isMobile) {
      return <React.Fragment>{pagination()}</React.Fragment>;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };
  const pagination = () => {
    return (
      <div
        id="paginationWebId"
        className="fulfilledPaginationWeb"
        style={{ background: "#D93A2F", display: "flex" }}
      >
        <div>
          <Stack spacing={1} style={{}}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={totalRecords}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Stack>
        </div>
      </div>
    );
  };

  const renderQuickFilters = () => {
    return  <div style={{margin:"0px 5px 10px 5px"}}>
        <Button className={checkFilters() ? "mobile-filters-btn selected" : "mobile-filters-btn"} onClick={handleFilterChange} startIcon={<FilterListIcon />} variant="outlined">Filters</Button>
    </div>
  }

  return (
    <div>
      <NotificationPopup {...underconstruction}></NotificationPopup>
      <NotificationPopup {...pdfInfo}></NotificationPopup>
      <NotificationPopup {...Error}></NotificationPopup>
      <NotificationPopup {...customerSlipsPopUp}></NotificationPopup>
      <NotificationPopup {...customerSlipInfoPopUp}></NotificationPopup>
      <OrderDetailNotificationPopup {...Info}></OrderDetailNotificationPopup>
      <PickSlipGroupBy></PickSlipGroupBy>

      {renderFilters()}
      <div
        style={{ marginTop: 0 }}
        className={Utils.isMobile ? "innerContianerMobile" : "innerContianer"}
      >
        {Utils.isMobile && <div id="tabFilterContaoner">{
          renderOrderTypeTabs()}
          {renderQuickFilters()}
          </div>
        }
        {/* {Utils.isMobile && <div>{renderQuickFilters()}</div>} */}
        <TabPanel value={currentTab} index={currentTab} dir={theme.direction}>
          {currentTab == 1 ? (
            <Paper
              style={{
                paddingLeft: 3,
                paddingRight: 3,
                marginBottom: 1,
                marginTop: Utils.isMobile ? 8 : 0,
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  style={{ display: "flex", justifyContent: "left" }}
                ></Grid>
                <Grid item xs={6} sm={6} style={{}}>
                  {Utils.disableUserAction(props.store) && (
                    <React.Fragment>
                      {!Utils.isMobile ? (
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          style={{ float: "right" }}
                        >
                          <Button
                            startIcon={<PrintIcon />}
                            style={{ marginTop: 5, marginBottom: 4 }}
                            disabled={ordersToPrint.length > 0 ? false : true}
                            className={
                              ordersToPrint.length > 0
                                ? "btn"
                                : "btn disabledButton"
                            }
                            variant="outlined"
                            color="primary"
                            onClick={() => openPdfiframe(true)}
                          >
                            Pick Slip
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            startIcon={<PrintIcon />}
                            style={{ marginTop: 5, marginBottom: 4 }}
                            disabled={ordersToPrint.length > 0 ? false : true}
                            className={
                              ordersToPrint.length > 0
                                ? "btn"
                                : "btn disabledButton"
                            }
                            variant="outlined"
                            color="primary"
                            onClick={() => openPdfiframe(false)}
                          >
                            Multi Pick List
                          </Button>
                        </Box>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Paper>
          ) : (
            <></>
          )}
          {currentTab == 3 ? (
            <Paper
              style={{
                paddingLeft: 3,
                paddingRight: 3,
                marginBottom: 1,
                marginTop: Utils.isMobile ? 8 : 0,
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ display: "flex", justifyContent: "right" }}
                >
                  {props.store?.length > 0 &&
                    Utils.disableUserAction(props.store) && (
                      <React.Fragment>
                        {!Utils.isMobile ? (
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            style={{ float: "right" }}
                          >
                            <Button
                              startIcon={<PrintIcon />}
                              style={{ marginTop: 5, marginBottom: 4 }}
                              className={"btn"}
                              variant="outlined"
                              color="primary"
                              onClick={() => openCustomerSlipPopup()}
                            >
                              Customer Pickup List
                            </Button>
                          </Box>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    )}
                </Grid>
              </Grid>
            </Paper>
          ) : (
            <></>
          )}
          {renderPaginationAndSearch()}
          {!Utils.isMobile ? renderWebDataTable() : renderMobileData()}
        </TabPanel>
        <Drawer
          anchor={"bottom"}
          open={openDetailDrawer}
          onClose={() => { }}
          onClick={() => {
            setOpenDetailDrawer(false);
          }}
          style={{ zIndex: 9999999 }}
          PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
        >
          {openDetailDrawer == true ? renderDetailDrawer() : <></>}
        </Drawer>
      </div>
      {pickSlipGroupByModal.showPdf || showPdf ? (
        <div>
          <MultiPickSlip
            store={props.store}
            isPickSlip={showPdf}
            ordersToPrint={ordersToPrint}
            setordersToPrint={setordersToPrint}   
            multiPickSlipOpenIFrame={multiPickSlipOpenIFrame}         
            setMultiPickSlipOpenIFrame={setMultiPickSlipOpenIFrame}
          ></MultiPickSlip>
          {multiPickSlipOpenIFrame ? 
            <PdfIframe
            open={pickSlipGroupByModal.showPdf || showPdf}
            close={closePdfiframe}
          ></PdfIframe>
          :<></>
          }
          
        </div>
      ) : (
        <></>
      )}
      {customerSlipOptions.customerPrint && (
        <div>
          <CustomerPickupSlip
            store={props.store}
            data={customerSlipData}
          ></CustomerPickupSlip>
          <PdfIframe
            open={customerSlipOptions.customerPrint}
            close={closeCustomerPickPrint}
          ></PdfIframe>
        </div>
      )}
    </div>
  );
  interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    const [scrolled, setScrolled] = useState(false);
  
    useEffect(() => {
      const handleScroll = () => {
        setScrolled(window.scrollY > 0);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
        style={{
          marginTop: Utils.isMobile && !scrolled ? "-14px" : "",
        }}
      >
        <Box sx={{ p: 3 }} style={{ padding: "5px" }} id="containerDataTable">
          <Typography
            component="div"
            style={{
              marginTop: scrolled ? "20px" : "0px",
            }}
          >
            {children}
          </Typography>
        </Box>
      </div>
    );
  }
};
export default FulfillOrders;
