import React, { useState } from "react";
import "./Reasons.scss";
import StoreManager from "../../Managers/Store.manager"
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  TextField,
} from "@mui/material";
import Utils from "../../Common/Utils";
import { useSelector } from "react-redux";
import { CheckBoxIcon, CheckBoxOutlineBlankIcon, CheckBoxOutlinedIcon } from "./SharedIcons";
export const StoreListDropDown = (props: any) => { 
  const stores = useSelector((state: any) => state.ActionLoaderReducersState.stores);
  const isEnterprise = useSelector((state: any) => state.ActionLoaderReducersState.isEnterprise);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxOutlinedIcon fontSize="small" />;

  return (
    <Autocomplete
      multiple
      id="country-select-demo"
      className="selectStore"
      value={props.store}
      limitTags={1}

      // value = {StoreManager.currentStore.StoreId == undefined ? "": StoreManager.currentStore.StoreId}
      //onChange={(event: any, value: any) => props.changeStoreList(event, value)}
      onChange={(e, value) => { props.changeStoreList(e, value) }}
      disabled={isEnterprise}
      //defaultValue={{ id: "", name: "Choose Store" }}
      style={{ marginTop: 10, borderRadius: 4,  width: "100%"}}
      getOptionLabel={(option:any) => option.id + "-" + option.name}
      disableCloseOnSelect
      options={stores}
      autoHighlight

      renderOption={(props, option: any, { selected }) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <li style={{fontSize:"small"}}>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    //style={{ marginRight: 8 }}
                    checked={selected}
                />
                {option.id}-{option.name}
            </li>
        </Box>
      )}
      renderTags={(value:any, getTagProps) => {
        const numTags = value.length;
        const limitTags = 1;

        return (
          <>
            {value.slice(0, limitTags).map((option:any, index:any) => (
              <Chip
                {...getTagProps({ index })}
                size="small"
                key={index}
                label={option.name}
              />
            ))}

            {numTags > limitTags && ` +${numTags - limitTags}`}
          </>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={stores && stores.length == 0 ? "Loading Stores..." : props.store.length==0?"Choose Store":""}
          style={{ background: "white", borderRadius: 4 }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off'
          }}
        />
      )}
    />
  );
};
