import { CHANGE_DESCRIPTION , CHANGE_SHORT_DESCRIPTION, CHANGE_PRIORITY, CHANGE_IMPACT, URGENCY } from "../ActionTypes";


const changeReportUrgency = (obj: any) => {
    
    return {
        type: URGENCY,
        payLoad: obj
    }
}

const changeReportImpact = (obj: any) => {
    
    return {
        type: CHANGE_IMPACT,
        payLoad: obj
    }
}

const changeReportPriority = (obj: any) => {
    
    return {
        type: CHANGE_PRIORITY,
        payLoad: obj
    }
}

const changeReportShortDescription = (obj: any) => {
    
    return {
        type: CHANGE_SHORT_DESCRIPTION,
        payLoad: obj
    }
}

const changeReportDescription = (obj: any) => {
    
    return {
        type: CHANGE_DESCRIPTION,
        payLoad: obj
    }
}


const reportErrorServiceNowActions = {

    changeReportDescription,
    changeReportShortDescription,
    changeReportPriority,
    changeReportImpact,
    changeReportUrgency

}

export default reportErrorServiceNowActions;