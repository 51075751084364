import { CHANGE_EMAILTEMPLATES, CHANGE_CUSTOMERDATA, CHANGE_SELECTEDORDERLINES, CHANGE_SELECTEDTEMPLATE, CHANGE_NOTES, SHOW_COMPILEDRESULT, CHANGE_STOREEMAIL, CHANGE_ERRORMESSAGE, CHANGE_CONTACTCUSTOMERHISTORY, CHANGE_PARENTCONTACTCUSTOMER, CHANGE_CUSTOMBODY, CHANGE_CUSTOMSUB } from "../ActionTypes";
const changeEmailTemplates = (obj: any) => {
    return {
        type: CHANGE_EMAILTEMPLATES,
        payLoad: obj
    };
}
const changeCustomerDate = (obj: any) => {
    return {
        type: CHANGE_CUSTOMERDATA,
        payLoad: obj
    }
}

const changeSelectedOrderLines = (obj: any) => {

    return {
        type: CHANGE_SELECTEDORDERLINES,
        payLoad: obj
    }

}
const changeSelectedTemplate = (obj: any) => {
    return {
        type: CHANGE_SELECTEDTEMPLATE,
        payLoad: obj
    }

}
const changeNotes = (obj: any) => {
    return {
        type: CHANGE_NOTES,
        payLoad: obj
    }

}
const showCompiledResult = (obj: any) => {
    return {
        type: SHOW_COMPILEDRESULT,
        payLoad: obj
    }

}

const changeStoreEmail = (obj: any) => {
    return {
        type: CHANGE_STOREEMAIL,
        payLoad: obj
    }

}
const changeErrorMessage = (obj: any) => {
    return {
        type: CHANGE_ERRORMESSAGE,
        payLoad: obj
    }
}
const changeContactCustomerHistory = (obj: any) => {
    return {
        type: CHANGE_CONTACTCUSTOMERHISTORY,
        payLoad: obj
    }
}
const changeCustomBody = (obj: any) => {
    return {
        type: CHANGE_CUSTOMBODY,
        payLoad: obj
    }
}
const changeCustomSub = (obj: any) => {
    return {
        type: CHANGE_CUSTOMSUB,
        payLoad: obj
    }
}
const changeParentContactCustomer = (obj: any) => {
    return {
        type: CHANGE_PARENTCONTACTCUSTOMER,
        payLoad: obj
    }
}

const contactCustomerActions = {
    changeEmailTemplates,
    changeCustomerDate,
    changeSelectedOrderLines,
    changeSelectedTemplate,
    changeNotes,
    showCompiledResult,
    changeStoreEmail,
    changeErrorMessage,
    changeContactCustomerHistory,
    changeCustomBody,
    changeCustomSub,
    changeParentContactCustomer
}

export default contactCustomerActions;