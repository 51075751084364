import { Grid, Typography } from "@mui/material";
import React from "react";
import Utils from "../../Common/Utils";
import OrderDetailShimmer from "./OrderDetailShimmer";
import OrderDetailShimmerMobile from "./OrderDetailShimmerMobile";
import { Avatar, Badge, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import "./OrderDetailGrid.scss"
import Constants from "../../Common/Constants";
import { ExpandMoreIcon } from "./SharedIcons";
export const OrderDetailGrid = (props: any) => {
    const { fulfillmentType, levelOfService, pickUpStore, storeNumber } = props.data;
    const webView = () => {
        return (
            <Card className="dCard" style={{ margin: 16 }}>
                <CardContent className="dCardContent">
                    {/* <div className="topHeading"> */}
                    {/* <div className='heading'> */}
                    <Typography className="card-heading">Order Details</Typography>
                    {/* </div> */}
                    <Divider />
                    {!props.loading ?
                        <div>
                            {props.orderDetailRoute ?
                                <Grid container spacing={2} display={"flex"} style={{ padding: "10px" }}>
                                    <Grid item md={3} sm={12} xs={12} className="gridContainer">
                                        <Typography style={{ fontSize: "12px", fontWeight: 400, color: Constants.Colors.dimGrey }}>Fulfillment Location:</Typography>
                                        <Typography style={{ fontSize: "13px", fontWeight: 500, marginTop: "4px" }}>{storeNumber}</Typography>
                                    </Grid>
                                    {pickUpStore ?
                                        <Grid item md={3} sm={12} xs={12} className="gridContainer">
                                            <Typography style={{ fontSize: "12px", fontWeight: 400, color: Constants.Colors.dimGrey }}>Pickup Store:</Typography>
                                            <Typography style={{ fontSize: "13px", fontWeight: 500, marginTop: "4px" }}>{pickUpStore}</Typography>
                                        </Grid>
                                        :
                                        <></>}
                                    <Grid item md={3} sm={12} xs={12} className="gridContainer">
                                        <Typography style={{ fontSize: "12px", fontWeight: 400, color: Constants.Colors.dimGrey }}>Level of Service:</Typography>
                                        <Typography style={{ fontSize: "13px", fontWeight: 500, marginTop: "4px" }}>{levelOfService}</Typography>
                                    </Grid>
                                    <Grid item md={3} sm={12} xs={12} className="gridContainer">
                                        <Typography style={{ fontSize: "12px", fontWeight: 400, color: Constants.Colors.dimGrey }}>Fulfilment Type:</Typography>
                                        <Typography style={{ fontSize: "13px", fontWeight: 500, marginTop: "4px" }}> {Utils.changeFulFillmentType(fulfillmentType)}</Typography>
                                    </Grid>
                                </Grid>
                                :
                                <React.Fragment>
                                    {pickUpStore &&
                                        <Grid container spacing={2} display={"flex"} style={{ padding: "10px" }}>
                                            <Grid item md={6} sm={12} xs={12} className="gridContainer">
                                                <Typography style={{ fontSize: "12px", fontWeight: 400, color: Constants.Colors.dimGrey }}>Fulfillment Location:</Typography>
                                                <Typography style={{ fontSize: "13px", fontWeight: 500, marginTop: "4px" }}>{storeNumber}</Typography>
                                            </Grid>
                                            <Grid item md={6} sm={12} xs={12} className="gridContainer">
                                                <Typography style={{ fontSize: "12px", fontWeight: 400, color: Constants.Colors.dimGrey }}>Pickup Store:</Typography>
                                                <Typography style={{ fontSize: "13px", fontWeight: 500, marginTop: "4px" }}>{pickUpStore}</Typography>
                                            </Grid>
                                        </Grid>}
                                    <Grid container spacing={2} display={"flex"} style={{ padding: "10px" }}>
                                        <Grid item md={6} sm={12} xs={12} className="gridContainer">
                                            <Typography style={{ fontSize: "12px", fontWeight: 400, color: Constants.Colors.dimGrey }}>Level of Service:</Typography>
                                            <Typography style={{ fontSize: "13px", fontWeight: 500, marginTop: "4px" }}>{levelOfService}</Typography>
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12} className="gridContainer">
                                            <Typography style={{ fontSize: "12px", fontWeight: 400, color: Constants.Colors.dimGrey }}>Fulfilment Type:</Typography>
                                            <Typography style={{ fontSize: "13px", fontWeight: 500, marginTop: "4px" }}> {Utils.changeFulFillmentType(fulfillmentType)}</Typography>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>}


                        </div>
                        :
                        <div>
                            <OrderDetailShimmer></OrderDetailShimmer>
                        </div>}


                    {/* </div> */}
                </CardContent>
            </Card>
        )
    }
    const mobileView = () => {
        return (

            <Accordion defaultExpanded style={{ margin: 5, padding: 10, borderRadius: 16, boxShadow: "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 2px rgb(0 0 0 / 12%)" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ color: Constants.Colors.darkGrey, height: 80 }}
                >
                    <div className="topHeading">
                        <div className="gridHeading" style={{ display: "flex" }}>
                            <Typography>Order Details</Typography>
                        </div>
                    </div>
                </AccordionSummary>
                <Divider />
                <AccordionDetails style={{ display: "block", padding: 0, overflowY: "scroll" }}>
                    {
                        !props.loading ?
                            <div style={{ padding: "20px" }}>
                                <Grid container style={{ margin: "0px" }}>
                                    {
                                        props.orderDetailRoute ?
                                            <React.Fragment>
                                                <Grid container spacing={2} display={"flex"} style={{ padding: "10px" }}>
                                                    <Grid item md={6} sm={6} xs={6} className="gridContainer">
                                                        <Typography style={{ fontSize: "12px", fontWeight: 400, color: Constants.Colors.dimGrey, display: 'flex', alignItems: 'flex-start' }}>Fulfillment Location:</Typography>
                                                        <Typography style={{ fontSize: "13px", fontWeight: 500, marginTop: "4px", display: 'flex', alignItems: 'flex-start' }}>{storeNumber}</Typography>
                                                    </Grid>
                                                    <Grid item md={6} sm={6} xs={6} className="gridContainer">

                                                        <React.Fragment>
                                                            <Typography style={{ fontSize: "12px", fontWeight: 400, color: Constants.Colors.dimGrey, display: 'flex', alignItems: 'flex-start' }}>Pickup Store:</Typography>
                                                            <Typography style={{ fontSize: "13px", fontWeight: 500, marginTop: "4px", display: 'flex', alignItems: 'flex-start' }}>{pickUpStore}</Typography>
                                                        </React.Fragment>


                                                    </Grid>
                                                </Grid>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                {pickUpStore &&
                                                    <React.Fragment>
                                                        {/* <Grid item xs={12} sm={12}>
                                                            <Typography style={{ fontSize: "11px" }}>Fulfillment Location: <b>{storeNumber}</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12}>
                                                            <Typography style={{ fontSize: "11px" }}>Pickup Store: <b>{pickUpStore}</b></Typography>
                                                        </Grid> */}
                                                        <Grid container spacing={2} display={"flex"} style={{ padding: "10px" }}>
                                                            <Grid item md={6} sm={6} xs={6} className="gridContainer">
                                                                <Typography style={{ fontSize: "12px", fontWeight: 400, color: Constants.Colors.dimGrey, display: 'flex', alignItems: 'flex-start' }}>Fulfillment Location:</Typography>
                                                                <Typography style={{ fontSize: "13px", fontWeight: 500, marginTop: "4px", display: 'flex', alignItems: 'flex-start' }}>{storeNumber}</Typography>
                                                            </Grid>
                                                            <Grid item md={6} sm={6} xs={6} className="gridContainer">

                                                                <React.Fragment>
                                                                    <Typography style={{ fontSize: "12px", fontWeight: 400, color: Constants.Colors.dimGrey, display: 'flex', alignItems: 'flex-start' }}>Pickup Store:</Typography>
                                                                    <Typography style={{ fontSize: "13px", fontWeight: 500, marginTop: "4px", display: 'flex', alignItems: 'flex-start' }}>{pickUpStore}</Typography>
                                                                </React.Fragment>


                                                            </Grid>
                                                        </Grid>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>

                                    }
                                    <Grid container spacing={2} display={"flex"} style={{ padding: "10px" }}>
                                        <Grid item sm={6} xs={6} className="gridContainer">
                                            <Typography style={{ fontSize: "12px", fontWeight: 400, color: Constants.Colors.dimGrey, display: 'flex', alignItems: 'flex-start' }}>Level of Service:</Typography>
                                            <Typography style={{ fontSize: "13px", fontWeight: 500, marginTop: "4px", display: 'flex', alignItems: 'flex-start' }}>{levelOfService}</Typography>
                                        </Grid>
                                        <Grid item sm={6} xs={6} className="gridContainer">

                                            <React.Fragment>
                                                <Typography style={{ fontSize: "12px", fontWeight: 400, color: Constants.Colors.dimGrey, display: 'flex', alignItems: 'flex-start' }}>Fulfilment Type:</Typography>
                                                <Typography style={{ fontSize: "13px", fontWeight: 500, marginTop: "4px", display: 'flex', alignItems: 'flex-start' }}>{Utils.changeFulFillmentType(fulfillmentType)}</Typography>
                                            </React.Fragment>


                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                            :
                            <div className="mainContainer" style={{ padding: "20px" }}>
                                <OrderDetailShimmerMobile></OrderDetailShimmerMobile>
                            </div >
                    }
                </AccordionDetails>
            </Accordion>
        )

    }
    return (
        <React.Fragment>
            {Utils.isMobile ? mobileView() : webView()}
        </React.Fragment>
    )
}