export const TEST_ACTION = "TEST_ACTION"
export const REMOVE_ARTICLE = "REMOVE_ARTICLE"
export const SHOW_ACTIONLOADER = "SHOW_ACTIONLOADER"
export const HIDE_ACTIONLOADER = "HIDE_ACTIONLOADER"
export const SET_CANCEL_REASON = "SET_CANCEL_REASON"
export const SET_STORES = "SET_STORES"
export const SET_DISTRICTS = "SET_DISTRICTS"
export const SET_KCDC_TOTALQUANTITY = "SET_KCDC_TOTALQUANTITY"
export const SET_ATTACHMENT_IMAGES = "SET_ATTACHMENT_IMAGES"
export const SET_DRAWER_DROPDOWNS = "SET_DRAWER_DROPDOWNS"
export const SET_REGIONS = "SET_REGIONS"
export const SET_ENTERPRISE = "SET_ENTERPRISE"
export const SET_USER_SETTINGS = "SET_USER_SETTINGS"
//Pack Order
export const CHANGE_SHIPDATE = "CHANGE_SHIPDATE"
export const CHANGE_RESIDENTIAL = "CHANGE_RESIDENTIAL"
export const CHANGE_BOXCHARACTERISTIC = "CHANGE_BOXCHARACTERISTIC"
export const ADD_BOXCHARACTERISTIC_TOLIST = "ADD_BOXCHARACTERISTIC_TOLIST"
export const REMOVE_BOXCHARACTERISTIC_FROMLIST = "REMOVE_BOXCHARACTERISTIC_FROMLIST"
export const INITILIZE_BOXCHARACTERISTIC = "INITILIZE_BOXCHARACTERISTIC"
export const REMOVE_ALL_BOXCHARACTERISTIC_FROMLIST = "REMOVE_ALL_BOXCHARACTERISTIC_FROMLIST"
export const ADD_UNPACKAGED_ITEMS = "ADD_UNPACKAGED_ITEMS"
export const ADD_PACKAGED_ITEMS = "ADD_PACKAGED_ITEMS"
export const CHANGE_UNPACKAGED_ITEMS = "CHANGE_UNPACKAGED_ITEMS"
export const CHANGE_SELECTED_ITEMS = "CHANGE_SELECTED_ITEMS"
export const CHANGE_ITEM_QUANTITY = "CHANGE_ITEM_QUANTITY"
export const CHANGE_PACKAGED_ITEM = "CHANGE_PACKAGED_ITEM"
export const CHANGE_CUSTOMER_DETAIL_PACK = "CHANGE_CUSTOMER_DETAIL_PACK"
export const CHANGE_ShipmentDataFromShippingCenter = "CHANGE_ShipmentDataFromShippingCenter"
//FulfillOrders
export const CHANGE_FO_TAB = "CHANGE_FO_TAB"
export const CHNAGE_FO_PAGINATION = "CHNAGE_FO_PAGINATION"
export const CHANGE_FO_TOTAL = "CHANGE_FO_TOTAL"
export const CHANGE_FO_ORDERROWS = "CHANGE_FO_ORDERROWS"
export const CHANGE_SELECTED_ORDER_TYPE = "CHANGE_SELECTED_ORDER_TYPE"
export const CHANGE_ORDER_TYPE_FILTER = "CHANGE_ORDER_TYPE_FILTER"
export const CHANGE_SELECTED_ORDER_AGE = "CHANGE_SELECTED_ORDER_AGE"
export const CHANGE_ORDER_AGE_FILTER = "CHANGE_ORDER_AGE_FILTER"
export const CHANGE_SELECTED_ORDER_VALUE = "CHANGE_SELECTED_ORDER_VALUE"
export const CHANGE_ORDER_VALUE_FILTER = "CHANGE_ORDER_VALUE_FILTER"
export const CHANGE_TAB_Counts = "CHANGE_TAB_Counts"
export const CHANGE_PICK_SINGLE_MULTIPLE_VAL = "CHANGE_PICK_SINGLE_MULTIPLE_VAL"
export const CHANGE_PICK_SINGLE_MULTIPLE_MARKED_VALUES = "CHANGE_PICK_SINGLE_MULTIPLE_MARKED_VALUES"
export const CHANGE_PRINT_PICKSLIP = "CHANGE_PRINT_PICKSLIP"
export const CHANGE_PRINT_CUSTOMERSLIP = "CHANGE_PRINT_CUSTOMERSLIP"
//PickOrders
export const CHANGE_ORDER_LINES = "CHANGE_ORDER_LINES"
export const CHANGE_CUSTOMER_DETAIL = "CHANGE_CUSTOMERDETAIL"
export const CHANGE_COMMON_CODE_LIST = "CHANGE_COMMON_CODE_LIST"
export const CHANGE_SELECTED_SHIPMENTLINEKEY = "CHANGE_SELECTED_SHIPMENTLINEKEY"
export const CHANGE_ORDER_DETAIL = "CHANGE_ORDER_DETAIL"
export const SET_CUSTOMERPICKUP_ORDER_DETAILS = "SET_CUSTOMERPICKUP_ORDER_DETAILS"
export const CHANGE_SCANNED_SHIPMENTLINES = "CHANGE_SCANNED_SHIPMENTLINES"
export const GET_CUSTOMER_INFO_FROM_ELASTIC = "GET_CUSTOMER_INFO_FROM_ELASTIC"
//Dashbaord
export const LOAD_ORDER_COUNT = "LOAD_ORDER_COUNT"
export const LOAD_DASHBOARD_ORDERS = "LOAD_DASHBOARD_ORDERS"
export const LOAD_DASHBOARD_RESOURCED_ORDERS = "LOAD_DASHBOARD_RESOURCED_ORDERS"
export const LOAD_DASHBOARD_EXPEDITED_ORDERS = "LOAD_DASHBOARD_EXPEDITED_ORDERS"
export const LOAD_DASHBOARD_PEDNINGCUSTOMER_ORDERS = "LOAD_DASHBOARD_PEDNINGCUSTOMER_ORDERS"
export const LOAD_DASHBOARD_PEDNINGCUSTOMER_DECISIONS_ORDERS = "LOAD_DASHBOARD_PEDNINGCUSTOMER_DECISIONS_ORDERS"

// Order History
export const SET_HISTORY = "SET_HISTORY"
export const SAVE_HISTORY = "SAVE_HISTORY"
export const IS_LOADING_HISTORY = "IS_LOADING_HISTORY"
export const FORCE_HISTORY_LOAD = "FORCE_HISTORY_LOAD"

//ReceiveOrders
export const LOAD_RECEIVEORDERS = "LOAD_RECEIVEORDERS"
export const LOAD_ALLRECEIVEORDERS = "LOAD_ALLRECEIVEORDERS"
export const CHANGE_RECEIVEORDERDETAIL = "CHANGE_RECEIVEORDERDETAIL"

//Tec Audts
export const CHANGE_TECAUDITLINES = "CHANGE_TECAUDITLINES";
export const CHANGE_SELECTEDTAG = "CHANGE_SELECTEDTAG";
export const CHANGE_TAGSLIST = "CHANGE_TAGSLIST"


//Contact Customer 
export const CHANGE_EMAILTEMPLATES = "CHANGE_EMAILTEMPLATES"
export const CHANGE_CUSTOMERDATA = "CHANGE_CUSTOMERDATA"
export const CHANGE_SELECTEDORDERLINES = "CHANGE_SELECTEDORDERLINES"
export const CHANGE_SELECTEDTEMPLATE = "CHANGE_SELECTEDTEMPLATE"
export const CHANGE_NOTES = "CHANGE_NOTES"
export const SHOW_COMPILEDRESULT = "SHOW_COMPILEDRESULT"
export const CHANGE_STOREEMAIL = "CHANGE_STOREEMAIL"
export const CHANGE_ERRORMESSAGE = "CHANGE_ERRORMESSAGE"
export const CHANGE_CONTACTCUSTOMERHISTORY = "CHANGE_CONTACTCUSTOMERHISTORY"
export const CHANGE_CUSTOMBODY = "CHANGE_CUSTOMBODY"
export const CHANGE_CUSTOMSUB = "CHANGE_CUSTOMSUB"
export const CHANGE_PARENTCONTACTCUSTOMER = "CHANGE_PARENTCONTACTCUSTOMER"
//Generic Notifications
export const SHOW_SUCCESSMESSAGE = "SHOW_SUCCESSMESSAGE"
export const HIDE_SUCCESSMESSAGE = "HIDE_SUCCESSMESSAGE"
export const ISEXTERNAL = "ISEXTERNAL"
// Search 
export const CHANGE_SEARCHRESULT = "CHANGE_SEARCHRESULT"
// KCDC
export const LOAD_KCDCORDERS = "LOAD_KCGCORDERS"
export const LOAD_KCDC_ORDER_DETAILS = "LOAD_KCDC_ORDER_DETAILS"

// Fulfilled Orders History
export const SET_DATE = "LOAD_KCGCORDERS"
export const SET_ORDER_TYPE = "SET_ORDER_TYPE"
export const SET_RANGE_TYPE = "SET_RANGE_TYPE"

//Report Error 
export const CHANGE_DESCRIPTION = "CHANGE_DESCRIPTION"
export const CHANGE_SHORT_DESCRIPTION = "CHANGE_SHORT_DESCRIPTION"
export const CHANGE_PRIORITY = "CHANGE_PRIORITY"
export const CHANGE_IMPACT = "CHANGE_IMPACT"
export const URGENCY = "URGENCY"
// ScandIt
export const VIEW = "VIEW"
export const CONTEXT = "CONTEXT"
export const BAR_CODE_CAPTURE = "BAR_CODE_CAPTURE"
export const BAR_CODE_TRACKING = "BAR_CODE_TRACKING"
export const CAMERA = "CAMERA"
export const BAR_CODE_TRACKING_OVERLAY = "BAR_CODE_TRACKING_OVERLAY"
export const BAR_CODE_CAPTURE_OVERLAY = "BAR_CODE_CAPTURE_OVERLAY"

