import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import moment from 'moment';
import { Checkbox, IconButton } from '@material-ui/core';
import { CheckBoxOutlinedIcon } from '../Shared/SharedIcons';
const CustomerWaiting = () => {
    interface Column {
        id: 'ordern' | 'customerName' | 'waitigSince' | 'status';
        label: string;
        minWidth?: number;
        align?: 'right';
        format?: (value: number) => string;
    };
    const columns: readonly Column[] = [
        { id: 'ordern', label: 'Order #', minWidth: 170 },
        { id: 'customerName', label: 'Customer Name', minWidth: 100 },
        {
            id: 'waitigSince',
            label: 'Waiting Since',
            minWidth: 170,
            align: 'right'
        }
    ];
    interface Data {
        ordern: any;
        customerName: any;
        waitigSince: any;
        status: any;
    }

    const createData = (
        ordern: any,
        customerName: any,
        waitigSince: any,
        status?: any,
    ) => {

        return {
            ordern, customerName, waitigSince: moment(new Date()).format("YYYY/MM/DD hh:mm A"), status
        }
    };

    const rows = [
        createData('GCW28394628', 'Sohaib Javed', ""),
        createData('GCW288694626', 'Asad Saeed', ""),
        createData('GCW283946298', 'Adam Lambert', ""),
        createData('GCW20976345', 'John Doe', ""),
    ];

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper style={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                key={"checkbox"}
                                align={"right"}
                                style={{}}
                            >
                                {""}
                            </TableCell>
                            {columns.map((column) => (

                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, whiteSpace:"nowrap" }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell
                                key={"moreactions"}
                                align={"right"}
                                style={{}}
                            >
                                {""}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.ordern}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checkedIcon={<CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>}
                                                color="primary"
                                                inputProps={{
                                                    'aria-label': 'select all desserts',
                                                }}
                                            />
                                        </TableCell>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                        <TableCell>
                                            <IconButton aria-label="comments">
                                                <DragIndicatorIcon />
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );

}

export default CustomerWaiting;