import {
  Card,
  CardContent,
  Checkbox,
  Drawer,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import IdentityManager from "../../Managers/Identity.manager";
import allActions from "../../Store/Actions/AllActions";
import { DistrictListDropDown } from "./DistrictListDropDown";
import { RegionListDropDown } from "./RegionListDropdown";
import { StoreListDropDown } from "./StoreListDropDown";
import { CheckBoxOutlinedIcon, CloseIcon } from "../../Subcomponent/Shared/SharedIcons";
import Utils from "../../Common/Utils";

export const DrawerDropdowns = (props: any) => {
  const openDrawer = useSelector(
    (state: any) => state.ActionLoaderReducersState.openDrawerDropdowns
  );
  const isEnterprise = useSelector(
    (state: any) => state.ActionLoaderReducersState.isEnterprise
  );
  const regions = useSelector(
    (state: any) => state.ActionLoaderReducersState.regions
  );
  const dispatch = useDispatch();
  const closeDrawer = () => {
    dispatch(allActions.actionLoaderActions.setDrawerDropdowns(false));
  };
  const changeEnterprise = () => {
    let changeIsEnterprise = !isEnterprise;
    if (changeIsEnterprise) {
      props.changeRegion(null, regions);
    }
    dispatch(allActions.actionLoaderActions.setEnterprise(changeIsEnterprise));
  };
  const mobileProps: any = Utils.isMobile ? { width: "100%", maxWidth: "100vw" } : ''
  return (
    <Drawer
      anchor={"right"}
      open={openDrawer}
      onClose={() => {
        closeDrawer();
      }}
      PaperProps={{ style: mobileProps }}

    >
      <Card style={{ height: "100%", background: "#F9F9F9", margin: "2px", padding: 10, width: Utils.isMobile ? "100%" : "280px" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            onClick={() => {
              closeDrawer();
            }}
            className="close-icon"
          >
            <CloseIcon style={{ fontSize: 18 }} />
          </div>
          <CardContent style={{ padding: 10 }}>
            {IdentityManager.isEnterprise && (
              <Grid container style={{ marginBottom: "10px" }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Checkbox
                    
                    checkedIcon={<CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>}
                    disabled={regions.length == 0}
                    checked={isEnterprise}
                    onChange={() => changeEnterprise()}
                  />
                  <Typography style={{ marginTop: "2px" }}>
                    Enterprise Data View
                  </Typography>
                </Grid>
              </Grid>
            )}
            <div style={{width: "90%"}}>
              {IdentityManager.showRegionDropdown && (
                <React.Fragment>
                  <Typography component="div" style={{ fontSize: Utils.isMobile ? "13" : "16", fontWeight: 500 }}>Regions</Typography>
                  <RegionListDropDown
                    region={props.region}
                    changeRegion={props.changeRegion}
                  ></RegionListDropDown>
                </React.Fragment>
              )}
              {IdentityManager.showDistrictDropdown && (
                <React.Fragment>
                  <Typography component="div" style={{ fontSize: Utils.isMobile ? "13" : "16", fontWeight: 500, marginTop: "20px" }}>
                    Districts
                  </Typography>
                  <DistrictListDropDown
                    district={props.district}
                    changeDistrict={props.changeDistrict}
                  ></DistrictListDropDown>
                </React.Fragment>
              )}
              {IdentityManager.showStoreDropdown && (
                <React.Fragment>
                  <Typography component="div" style={{ fontSize: Utils.isMobile ? "13" : "16", fontWeight: 500, marginTop: "20px" }}>
                    Stores
                  </Typography>
                  <StoreListDropDown
                    store={props.store}
                    changeStoreList={props.changeStoreList}
                  ></StoreListDropDown>
                </React.Fragment>
              )}
            </div>
          </CardContent>
        </div>
      </Card>
    </Drawer>
  );
};
