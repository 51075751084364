import React, { useRef, useState } from "react";
import "./ContactCustomer.scss";
import {
    Grid,
    TextField,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Card,
    CardContent,
    Checkbox,
    Divider
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import StoreManager from "../../Managers/Store.manager";
import { ICustomerDetail } from '../../Models/IPickOrder'
import { OmsPickOrderDetail } from '../../Models/OmsPickOrderDetail';
import { ICustomerContact, Item } from '../../Models/ICustomerContact'
import Constants from '../../Common/Constants';
import { IContactCustomer } from "../../Models/IContactCustomer";
import allActions from "../../Store/Actions/AllActions"

import Utils from "../../Common/Utils";
import IdentityManager from "../../Managers/Identity.manager";
import PFManager from "../../Managers/Process.Fulfillment.Manager";
import { ImageUploader } from "./ImageUploader";
import { CustomerResponse } from '../Shared/CustomerResponse'
import ContactCustomerManager from "../../Managers/ContactCustomer.Manager";
import { ImageAttachments } from "./ImageAttachments";
import { CheckBoxOutlinedIcon } from "./SharedIcons";
const { v4: uuidv4 } = require('uuid');
export const ContactCustomer: React.FC<any> = (props: any) => {


    const [displayName, setDisplayName] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const dispatch = useDispatch();

    let orderDetail: OmsPickOrderDetail = useSelector((state: any) => state.PickOrdersReducersState.orderDetail);
    let customerDetail: ICustomerDetail = useSelector((state: any) => state.PickOrdersReducersState.customerDetail);
    let emailTemplates = useSelector((state: any) => state.ContactCustomerReducersState.emailTemplates);
    console.log(emailTemplates, "emailTemplates");
    let storeEmail = useSelector((state: any) => state.ContactCustomerReducersState.storeEmail);
    let selectedTemplate = useSelector((state: any) => state.ContactCustomerReducersState.selectedTemplate);
    let selectedOrderLines: ICustomerContact = useSelector((state: any) => state.ContactCustomerReducersState.selectedOrderLines);
    let customBody = useSelector((state: any) => state.ContactCustomerReducersState.customBody);
    let customSub = useSelector((state: any) => state.ContactCustomerReducersState.customSub);
    let contactCustomerHistory = useSelector((state: any) => state.ContactCustomerReducersState.contactCustomerHistory);
    const [dynamicFollowUpHtml, setDynamicFollowUpHtml] = useState("");
    const [imageAttachments, setImageAttachments] = useState([]);
    let parentContactCustomerGuid = useSelector((state: any) => state.ContactCustomerReducersState.parentContactCustomer);

    const radioChange = (event: any) => {
        selectedTemplate = emailTemplates.filter((template: any) => {
            return template.filename == event.target.value
        })[0];
        dispatch(allActions.contactCustomerActions.changeSelectedTemplate(selectedTemplate));
        resetCustomValues();
        getFollowUpTemplates();
    }

    const getImageAttachments = () => {
        let images: any = [];
        contactCustomerHistory.forEach((history: any) => {
            if (history?._source.hasOwnProperty("imageAttachments")) {
                images.push(...history._source.imageAttachments);
            }
        });
        setImageAttachments(images);
    }

    const getFollowUpTemplates = () => {

        if (selectedTemplate.filename == Constants.ContactCustomerFileName.FollowUp) {
            getImageAttachments();
            contactCustomerHistory = Utils.sortContactCustomerHistory(contactCustomerHistory)
            ContactCustomerManager.getFollowUpContactCustomerTemplate(contactCustomerHistory).then((res: any) => {
                if (res?.data?.response?.success && res?.data?.response?.data) {
                    let response = res.data.response.data
                    setDynamicFollowUpHtml(response);
                }
                else {
                    setDynamicFollowUpHtml("");
                }
            }).catch((err: any) => {
                console.log(err);
            });
        }
    }

    const changeCustomSub = (event: any) => {
        dispatch(allActions.contactCustomerActions.changeCustomSub(event.target.value));
    }

    const resetCustomValues = () => {
        if (selectedTemplate.filename == Constants.ContactCustomerFileName.FollowUp) {
            dispatch(allActions.contactCustomerActions.changeCustomSub("Follow up"));
        }
        else {
            dispatch(allActions.contactCustomerActions.changeCustomSub(""));
        }
        dispatch(allActions.contactCustomerActions.changeCustomBody(""));
    }

    const changeCustomBody = (event: any) => {
        //console.log(event.target.value)        
        dispatch(allActions.contactCustomerActions.changeCustomBody(event.target.value));
    }
    const msgChange = (event: any, shipmentLineKey: any) => {
        console.log(event.target.value)
        console.log(shipmentLineKey)
        setLoading(true)
        for (const element of selectedOrderLines.items) {
            if (element.shipmentlinekey == shipmentLineKey) {
                element.message = event.target.value
            }
        }
        dispatch(allActions.contactCustomerActions.changeSelectedOrderLines({}));
        dispatch(allActions.contactCustomerActions.changeSelectedOrderLines(selectedOrderLines));
        setLoading(false)
    }
    const changeStoreEmail = (event: any) => {
        dispatch(allActions.contactCustomerActions.changeStoreEmail(event.target.value));
    }
    const getLabel = (name: string) => {
        let result = ""
        switch (name) {
            case "GCNotOriginalPackaging.txt":
                result = "Not Original Packaging"
                break;
            case "GCOutOfBox.txt":
                result = "Out Of Box"
                break;
            case "MFNotOriginalPackaging.txt":
                result = "Not Original Packaging"
                break;
            case "MFOutOfBox.txt":
                result = "Out Of Box"
                break;
            case "Custom.txt":
                result = "Custom Email"
                break;
            default:
                result = name

        }
        return result
    }
    const changeSelected = (shipmentLineKey: any) => {
        setLoading(true)
        for (const element of selectedOrderLines.items) {
            if (element.shipmentlinekey == shipmentLineKey) {
                element.selected = !element.selected;
            }
        }
        dispatch(allActions.contactCustomerActions.changeSelectedOrderLines({}));
        dispatch(allActions.contactCustomerActions.changeSelectedOrderLines(selectedOrderLines));
        setLoading(false)
    }
    const generateUUID = () => {
        return uuidv4();
    }
    const getCustomerContactedHistory = async () => {
        try {
            const contactCustomer = await PFManager.getCustomerContactedHistory(orderDetail.ShipmentKey)
            if (contactCustomer?.data?.hits?.hits?.length > 0) {
                var index = emailTemplates.map((e: any) => { return e.filename; }).indexOf(Constants.ContactCustomerFileName.FollowUp);
                if (index == -1) {
                    let followup = {
                        "filename": Constants.ContactCustomerFileName.FollowUp,
                        "contents": "",
                        "subject": "Follow up",
                        "templateName": "Follow Up"
                    }
                    let templates = [...emailTemplates]
                    templates.push(followup)
                    dispatch(allActions.contactCustomerActions.changeEmailTemplates(templates));
                }
            }
            else {
                let templates = emailTemplates.filter((e: any) => e.filename != Constants.ContactCustomerFileName.FollowUp)
                dispatch(allActions.contactCustomerActions.changeEmailTemplates(templates));
            }
            let parentContactCustomer: any = []
            if (contactCustomer?.data?.hits?.hits?.length > 0) {
                parentContactCustomer = contactCustomer?.data?.hits?.hits.filter(((x: any) => x._source.guid == parentContactCustomerGuid || x._source.parentContactCustomerGuid == parentContactCustomerGuid))

            }

            dispatch(allActions.contactCustomerActions.changeContactCustomerHistory(parentContactCustomer));
        }
        catch (err) {
            console.log("Customer Contacted History Error", err);
        }
    }

    const getOktaProfile = async (empId: any) => {
        const oktaProfileResponse = await ContactCustomerManager.getDisplayNameFromOktaByEmpId(empId)
        if (oktaProfileResponse) {
            setDisplayName(oktaProfileResponse.data[0]?.profile?.displayName)

        }

    }
    React.useEffect(() => {
        getCustomerContactedHistory()
        let itemList = [] as any

        orderDetail?.ShipmentLines?.ShipmentLine?.map((orderLine) => {
            let item: Item = {
                shipmentlinekey: orderLine.ShipmentLineKey,
                itemId: orderLine.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID,
                brand: orderLine?.OrderLine?.ItemDetails?.Extn?.ExtnBrandName,
                description: orderLine.ItemDesc,
                message: "",
                qty: orderLine.Quantity,
                sku: orderLine.ItemID,
                price: orderLine.UnitPrice,
                selected: false
            }
            itemList.push(item)
        })

        let selectedStore: any = StoreManager.getSelectedStoreDetails(props.store);
        getOktaProfile(selectedStore.SMId)
        if (selectedStore) {
            let contactCustomer: ICustomerContact = {
                orderno: orderDetail?.ShipmentLines?.ShipmentLine[0]?.OrderNo,
                shipmentkey: orderDetail.ShipmentKey,
                customername: customerDetail.name,
                customeremail: customerDetail.email,
                location: selectedStore.StoreId + "-" + selectedStore.StoreName,
                store: selectedStore.StoreId.toString(),
                code: Utils.generateRandom(4, Constants.CryptoRandomTypes.numeric),
                storemanager: displayName ? displayName : selectedStore.SMName,
                phone: selectedStore.Phone,
                filename: "",
                guid: generateUUID(),
                storeemail: selectedStore?.StoreEmail,
                items: itemList,
                url: window.location.origin + "/",
                logged_in_user_email: IdentityManager.getUserProperty(Constants.Preferred_UserName),
                store_retail_manager_ops_email: Utils.retailOpsManagerEmail(selectedStore.StoreId, selectedStore.StoreName)
            }
            dispatch(allActions.contactCustomerActions.showCompiledResult(false));
            dispatch(allActions.contactCustomerActions.changeSelectedTemplate({}));
            dispatch(allActions.contactCustomerActions.changeErrorMessage(""));
            dispatch(allActions.contactCustomerActions.changeSelectedOrderLines(contactCustomer));
            resetCustomValues();
        }

        setLoading(false);
    }, [displayName]);
    return (
        <div style={{ marginTop: 20, textAlign: "left" }}>
            <Grid item xs={12} sm={12} className='inputField'>
                <Typography style={{ fontSize: 14, fontWeight: 500 }}>Template</Typography>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={selectedTemplate.filename ? selectedTemplate.filename : ""}
                        onChange={(event) => radioChange(event)}
                    >
                        {
                            emailTemplates?.map((template: any) => {
                                console.log(orderDetail?.EnterpriseCode?.toLowerCase(), "orderDetail?.EnterpriseCode.toLowerCase()");
                                if ((template?.filename?.substring(0, 2)?.toLowerCase() == orderDetail?.EnterpriseCode?.toLowerCase())
                                    || template?.filename == Constants.ContactCustomerFileName.FollowUp
                                    || template?.filename == Constants.ContactCustomerFileName.Custom) {
                                    let label = getLabel(template?.templateName)
                                    return (
                                        <FormControlLabel value={template?.filename} control={<Radio />} label={label} className="label" />
                                    );
                                }
                            })
                        }

                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} className='inputField'>
                <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                    Customer Email*
                </Typography>
                <TextField disabled fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Customer Email' variant="outlined" value={customerDetail.email} />
            </Grid>
            <Grid item xs={12} sm={12} className='inputField'>
                <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                    Store Email*
                </Typography>
                {
                    StoreManager.currentStore.StoreEmail == "" ?
                        <TextField fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Store Email' variant="outlined" value={storeEmail} onChange={(event) => changeStoreEmail(event)} />
                        :
                        <TextField disabled fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Store Email' variant="outlined" value={StoreManager.currentStore.StoreEmail} />
                }
            </Grid>
            <Grid item xs={12} sm={12} className='inputField'>
                <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                    Subject
                </Typography>
                {
                    (selectedTemplate.filename == Constants.ContactCustomerFileName.FollowUp || selectedTemplate.filename == Constants.ContactCustomerFileName.Custom) ?
                        <TextField fullWidth style={{backgroundColor:"white"}} InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Subject' variant="outlined" onChange={(event) => changeCustomSub(event)} value={customSub} />
                        :
                        selectedTemplate.subject ?
                            <TextField disabled fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Subject' variant="outlined" value={selectedTemplate.subject} />
                            :
                            <TextField disabled fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Subject' variant="outlined" value={""} />

                }

            </Grid>
            {
                selectedTemplate.filename == Constants.ContactCustomerFileName.FollowUp ?
                    <Grid item xs={12} sm={12} className='inputField'>
                        <Grid item xs={12} sm={12} className='inputField' style={{ marginBottom: 10 }}>
                            <ImageUploader />
                        </Grid>
                        <Typography>
                            Body
                        </Typography>
                        <Typography>
                            <TextField multiline rows={4} onChange={(event) => changeCustomBody(event)} style={{ textAlign: "left",backgroundColor:"white" }} fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Body' variant="outlined" value={customBody} />
                        </Typography>
                    </Grid>
                    :
                    <Grid item xs={12} sm={12} className='inputField ' style={{ marginTop: 8 }}>
                        <Grid item xs={12} sm={12} className='inputField' style={{ marginBottom: 10 }}>
                            <ImageUploader />
                        </Grid>
                        {selectedTemplate.filename == Constants.ContactCustomerFileName.Custom ?
                            <>
                                <Typography>
                                    Body
                                </Typography>
                                <Typography>
                                    <TextField multiline rows={4} onChange={(event) => changeCustomBody(event)} style={{ textAlign: "left",backgroundColor:"white" }} fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Body' variant="outlined" value={customBody} />
                                </Typography>
                            </>
                            :
                            <></>
                        }
                        <Card className="dCard">                            
                            <CardContent className={Utils.isMobile ? "" : "dCardContent"}>
                               <div className="topHeading">
                                    <div className="gridHeading">
                                        <Typography className="card-heading"> Order Lines</Typography>
                                    </div>
                                </div>
                                <Divider className="card-divider"/>
                                <div style={{ padding: "6px" }}>
                                    {
                                        selectedOrderLines?.items?.map((item: Item) => {
                                            return (
                                                        <Grid container style={{ marginTop: "15px" }}>
                                                            <Grid item xs={2} sm={1}>
                                                                <Checkbox
                                                                    checkedIcon={<CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>}
                                                                    style={{}}
                                                                    checked={item.selected}
                                                                    onChange={() => changeSelected(item.shipmentlinekey)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={10} sm={11} style={{ textAlign: "left"}}>
                                                                <Typography style={{ fontSize: Utils.isMobile? "14px": "16px", fontWeight: 400 }}>{item.description}</Typography>
                                                                {/* <Grid container display="flex"> */}
                                                                    {!Utils.isMobile ? <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                                                            <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                    Item ID
                                                                                </Typography>
                                                                                <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                    <b>{item.itemId}</b>
                                                                                </Typography>
                                                                            </div>
                                                                            <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                    Qty
                                                                                </Typography>
                                                                                <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                    <b>{item.qty}</b>
                                                                                </Typography>
                                                                            </div>
                                                                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                    Price
                                                                                </Typography>
                                                                                <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                    <b>${item.price}</b>
                                                                                </Typography>
                                                                            </div>
                                                                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                    Brand
                                                                                </Typography>
                                                                                <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                    <b>{item.brand}</b>
                                                                                </Typography>
                                                                            </div>
                                                                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                    SKU
                                                                                </Typography>
                                                                                <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                    <b>{item.sku}</b>
                                                                                </Typography>
                                                                            </div>
                                                                        </div>
                                                                        </div>:
                                                                        <>
                                                                        
                                                                            <Grid container>
                                                                                <Grid item xs={12} sm={12} style={{textAlign:"left",display:"flex", alignItems:"center"}}>
                                                                                    <Typography sx={{ fontSize: 12}} color="text.secondary">
                                                                                        Item ID
                                                                                    </Typography>
                                                                                    <Typography sx={{ fontSize: 13}} style={{marginLeft:"3px"}}>
                                                                                        <b>{item.itemId}</b>
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6} style={{textAlign:"left",display:"flex", alignItems:"center"}}>
                                                                                     <Typography sx={{ fontSize: 12}} color="text.secondary">
                                                                                        Qty
                                                                                    </Typography>
                                                                                    <Typography sx={{ fontSize: 13}} style={{marginLeft:"3px"}}>
                                                                                        <b>{item.qty}</b>
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={12} style={{textAlign:"left",display:"flex", alignItems:"center"}}>
                                                                                     <Typography sx={{ fontSize: 12}} color="text.secondary">
                                                                                        Price
                                                                                    </Typography>
                                                                                    <Typography sx={{ fontSize: 13}} style={{marginLeft:"3px"}}>
                                                                                        <b>${item.price}</b>
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={12} style={{textAlign:"left",display:"flex", alignItems:"center"}}>
                                                                                     <Typography sx={{ fontSize: 12}} color="text.secondary">
                                                                                        Brand
                                                                                    </Typography>
                                                                                    <Typography sx={{ fontSize: 13}} style={{marginLeft:"3px"}}>
                                                                                        <b>{item.brand}</b>
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={12} style={{textAlign:"left",display:"flex", alignItems:"center"}}>
                                                                                     <Typography sx={{ fontSize: 12}} color="text.secondary">
                                                                                        Sku
                                                                                    </Typography>
                                                                                    <Typography sx={{ fontSize: 13}} style={{marginLeft:"3px"}}>
                                                                                        <b>{item.sku}</b>
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>
                                                                        }
                                                                {/* </Grid> */}
                                                                {!Utils.isMobile && <TextField onChange={(event) => msgChange(event, item.shipmentlinekey)} style={{ textAlign: "left" }} fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Message' variant="outlined" value={item.message} />}
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {Utils.isMobile && <TextField onChange={(event) => msgChange(event, item.shipmentlinekey)} style={{ textAlign: "left" }} fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Message' variant="outlined" value={item.message} />}
                                                            </Grid>
                                                        </Grid>
                                            );
                                        })
                                    }
                                </div>
                            </CardContent>
                        </Card>

                    </Grid>
            }
            {
                selectedTemplate.filename == Constants.ContactCustomerFileName.FollowUp ?
                    // <div>
                    //     {
                    //         contactCustomerHistory && contactCustomerHistory.length > 0 ?
                    //             <div>
                    //                 <div>
                    //                     {
                    //                         contactCustomerHistory.map((history: any, index: any) => {
                    //                             return (
                    //                                 <div style={{ padding: "10px", border: "1px #DCDCDC solid", backgroundColor: "#F8F8F8", margin: "5px" }}>
                    //                                     <Grid item xs={12} sm={12} className='inputField'>
                    //                                         <Typography style={{ display: "flex", fontSize: "20px", fontWeight: 500 }}>
                    //                                             {Utils.getLabel(history._source.filename)}
                    //                                         </Typography>
                    //                                     </Grid>
                    //                                     <CustomerResponse {...history._source}></CustomerResponse>
                    //                                 </div>)
                    //                         })
                    //                     }
                    //                 </div>
                    //             </div>
                    //             :
                    //             <></>


                    //     }
                    // </div>
                    <React.Fragment>
                        {imageAttachments.length > 0 &&
                            <div className="backdrop">
                                <div className="title">
                                    <Typography>Attachments</Typography>
                                </div>
                                <ImageAttachments imageAttachments={imageAttachments} />
                            </div>
                        }
                        <div dangerouslySetInnerHTML={{ __html: dynamicFollowUpHtml }} />

                    </React.Fragment>
                    :
                    <></>
            }
        </div>
    );
};
