import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Sector, Cell, Legend, ResponsiveContainer, Tooltip, Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts';
import Utils from '../../Common/Utils';
import { Typography } from "@material-ui/core";
import IdentityManager from '../../Managers/Identity.manager';
import DataNotFound from '../Shared/DataNotFound';
import { log } from 'console';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid } from '@mui/material';
import Constants from '../../Common/Constants';
import PlaylistAddCheck from '@mui/icons-material/PlaylistAddCheck';
import { AttachMoneyIcon, LocalShippingIcon, ShoppingCart, CardGiftcardIcon } from '../Shared/SharedIcons';

const OrderTimeChart = (props: any) => {

    const { data, isLoaded } = props;
    const distListData = data;


    const [avgPicking, setAvgPicking] = useState(0);
    const [avgPacking, setAvgPacking] = useState(0);
    const [avgShipping, setAvgShipping] = useState(0);
    useEffect(() => {
        if (distListData?.length > 1) {

            let distPickCount = 0;
            let distPackCount = 0;
            let distShipCount = 0;
            let pickStoresCount = 0;
            let packStoresCount = 0;
            let shipStoresCount = 0;
            for (let i = 0; i < distListData.length; i++) {
                distPickCount = distPickCount + distListData[i].pick;
                distPackCount = distPackCount + distListData[i].pack;
                distShipCount = distShipCount + distListData[i].ship;
                const pickStoresArr = distListData[i].orderTimeStoresData.filter(((x: any) => x.pick > 0))
                pickStoresCount = pickStoresCount + pickStoresArr.length
                const packStoresArr = distListData[i].orderTimeStoresData.filter(((x: any) => x.pack > 0))
                packStoresCount = packStoresCount + packStoresArr.length
                const shipStoresArr = distListData[i].orderTimeStoresData.filter(((x: any) => x.ship > 0))
                shipStoresCount = shipStoresCount + shipStoresArr.length
            }

            const avgPick = distPickCount / pickStoresCount;
            const avgPack = distPackCount / packStoresCount;
            const avgShip = distShipCount / shipStoresCount;

            setAvgPicking(avgPick)
            setAvgPacking(avgPack)
            setAvgShipping(avgShip)


        }
        else if (distListData?.length === 1) {
            const pickStoresArr = distListData[0]?.orderTimeStoresData.filter(((x: any) => x.pick > 0))
            const packStoresArr = distListData[0]?.orderTimeStoresData.filter(((x: any) => x.pack > 0))
            const shipStoresArr = distListData[0]?.orderTimeStoresData.filter(((x: any) => x.ship > 0))
            setAvgPicking(distListData[0].pick / pickStoresArr.length);
            setAvgPacking(distListData[0].pack / packStoresArr.length);
            setAvgShipping(distListData[0].ship / shipStoresArr.length);

        }
    }, [distListData, isLoaded])


    const getDaysAndHours = (ms: number) => {
        const obj = Utils.convertHoursToDays(ms)
        if (obj.Days && obj.Hours) {
            return `${obj.Days} day(s) - ${obj.Hours} hr(s)`
        }
        if (obj.Days) {
            return `${obj.Days} day(s) `
        }
        if (obj.Hours) {
            return `${obj.Hours} hr(s) `
        } if (obj.Minutes) {
            return `${obj.Minutes} min(s) `
        } if (obj.Sec) {
            return `${obj.Sec}sec(s) `
        }

        else { return `-` }
    }
    return (
        <>
            {avgPicking || avgPacking || avgShipping ?
                <div>
                    <div>
                        <Grid container>
                            <Grid item sm={12} xs={12}>
                                <Card className='dCard' style={{ backgroundColor: "#eee", height: "90%" }}>
                                    <CardContent className='dCardContent' style={{ padding: 3 }}>
                                        <Grid container>
                                            <Grid item sm={12} xs={12}>
                                                <Grid container display="grid" alignItems="center" minHeight={105}>
                                                    <Grid item sm={12} xs={12}>
                                                        <Grid container>
                                                            <Grid item sm={3} xs={3} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                                                <div className="grid-icons" style={{ backgroundColor: Constants.Colors.blue }}  >
                                                                    <ShoppingCart />
                                                                </div>
                                                            </Grid>
                                                            <Grid item sm={8} xs={8} style={{ display: "grid", justifyItems: "center", alignItems: "center" }}>
                                                                <div style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
                                                                    <p style={{ width: "100%" }} className="grid-header-font">Picking</p>

                                                                </div>
                                                                <p style={{ width: "100%" }} className="grid-value-font-reports">
                                                                    {getDaysAndHours(avgPicking)}
                                                                </p>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>


                            <Grid item sm={12} xs={12}>
                                <Card className='dCard' style={{ backgroundColor: "#eee", height: "90%" }}>
                                    <CardContent className='dCardContent' style={{ padding: 3 }}>
                                        <Grid container alignItems="center" minHeight={105}>
                                            <Grid item sm={12} xs={12}>
                                                <Grid container >
                                                    <Grid item sm={3} xs={3} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                                        <div className="grid-icons" style={{ backgroundColor: Constants.Colors.green }}  >
                                                            <CardGiftcardIcon />
                                                        </div>
                                                    </Grid>



                                                    <Grid item sm={8} xs={8} style={{ display: "grid", justifyItems: "center" }}>
                                                        <div style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
                                                            <p style={{ width: "100%" }} className="grid-header-font">Packing</p>

                                                        </div>
                                                        <p style={{ width: "100%" }} className="grid-value-font-reports">
                                                            {getDaysAndHours(avgPacking)}
                                                        </p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Card className='dCard' style={{ backgroundColor: "#eee", height: "90%" }}>
                                    <CardContent className='dCardContent' style={{ padding: 3 }}>
                                        <Grid container alignItems="center" minHeight={105}>
                                            <Grid item sm={12} xs={12}>
                                                <Grid container >
                                                    <Grid item sm={3} xs={3} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                                        <div className="grid-icons" style={{ backgroundColor: Constants.Colors.green }}  >
                                                            <LocalShippingIcon />
                                                        </div>
                                                    </Grid>
                                                    <Grid item sm={8} xs={8} style={{ display: "grid", justifyItems: "center" }}>
                                                        <div style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
                                                            <p style={{ width: "100%" }} className="grid-header-font">Shipping</p>

                                                        </div>
                                                        <p style={{ width: "100%" }} className="grid-value-font-reports">
                                                            {getDaysAndHours(avgShipping)}
                                                        </p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                    </div>

                </div>

                : <DataNotFound></DataNotFound>}
        </>

    )
}

export default OrderTimeChart