import React, { useState } from "react";
import Constants from "../../Common/Constants";
import { ICustomerDetail } from '../../Models/IPickOrder'
import { OmsPackOrderDetail, Container, ContainerDetail } from "../../Models/OmsPackOrderDetails";
import { Typography } from '@material-ui/core';
import StoreManager from "../../Managers/Store.manager"
import { useDispatch, useSelector } from 'react-redux';
import allActions from "../../Store/Actions/AllActions";
import PdfIframe from "../../Subcomponent/PickOrder/PdfIframe";
import PickSlipWithPack from "../FulfillOrders/PickSlipWithPack";
var Barcode = require('react-barcode');
const html2pdf = require('html2pdf.js');
const shipFontSize = "12px"
var momenttz = require('moment-timezone');
const today = momenttz.tz("America/Los_Angeles").format(Constants.DateFormat.DateOnly);
export const PackPrintSlip: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const [openpdf, setOpenpdf] = useState(false);
  const [openPickSlip, setPickSlip] = useState(0);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const shipmentkey = urlParams.get('shipmentkey');
  const currentStore = StoreManager.currentStore
  let customerDetail: ICustomerDetail = useSelector((state: any) => state.PackOrderReducersState.customerDetail);
  let packagedItems: Container[] = useSelector((state: any) => state.PackOrderReducersState.packagedItems);
  let unpackagedItems: OmsPackOrderDetail = useSelector((state: any) => state.PackOrderReducersState.unpackagedItems);
  let OrderDate = momenttz(unpackagedItems.ShipmentLines?.ShipmentLine[0]?.Order?.OrderDate).tz("America/Los_Angeles").format(Constants.DateFormat.DateOnly)
  const renderTableHeading = (text: any) => {
    return <Typography variant='body1' style={{ letterSpacing: "1.5px", paddingTop: "6px" }}><span style={{ letterSpacing: "1.5px", fontSize: "10px" }}>{text}</span></Typography>;
  }
  const showActionLoader = () => {
    dispatch(allActions.actionLoaderActions.showLoader());
  }
  const hideActionLoader = () => {
    dispatch(allActions.actionLoaderActions.hideLoader());
  }
  const print = async () => {
    if (props.loadingOrderLines == false && props.isPrintPackOrder >= 1) {
      showActionLoader();
      //setPickSlip(openPickSlip + 1)
      let el = document.getElementById("PackSlipContainer") as any;
      let fileName = `Pack Slip`;
      let options = {
        pdfCallback: pdfDone,
        margin: [0.1, 0.1, 0.1, 0.1],  // tlbr
        filename: fileName + '.pdf',
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 2, logging: true },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'p' },
        pagebreak: { before: '.breakPage' }
      };

      let worker = await html2pdf().set(options).from(el).toPdf().output('blob');
      var fileURL = URL.createObjectURL(worker);
      hideActionLoader();
      window.open(fileURL, '_blank');

    }
  }
  const closePackSlipContainer = () => {

    setOpenpdf(false);

  }
  const pdfDone = () => {
    console.log("Pdf generated successfully")
  }
  React.useEffect(() => {
    async function printPackSlip() {
      await print()
    }
    printPackSlip()
    return () => {

    }

  }, [props.isPrintPackOrder]);
  return (
    <React.Fragment>
      <div style={{ display: "none" }}>
        <div id="PackSlipContainer" style={{ background: "white" }}>
          {
            packagedItems.length > 0 && props.loadingOrderLines == false ?
              packagedItems.map((container: Container, index: any) => {
                return (
                  <div className={index == 0 ? "" : "breakPage"} style={{ width: "100%" }}>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ width: "45%", textAlign: "left" }}>
                        <div className="logo-ContainerPackScreen"></div>
                      </div>
                      <div style={{ width: "55%", textAlign: "left" }}>
                        <Typography
                          variant="h4"
                          style={{
                            textAlign: "center",
                            color: "#D93A2F",
                            paddingBottom: "10px",
                            paddingTop: "10px",
                            width: "100px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Pack Slip
                        </Typography>
                      </div>
                    </div>
                    <div style={{ display: "flex", width: "100%", marginTop: "5px" }}>
                      <div style={{ width: "50%", textAlign: "center" }}>
                        <Typography variant="h5">
                          {" "}
                          <span
                            style={{
                              color: "#D93A2F",
                              letterSpacing: "3.5px",
                              paddingBottom: "6px",
                            }}
                          >
                            Originating Store
                          </span>{" "}
                        </Typography>
                        <Typography variant="body1">
                          {currentStore.StoreName}
                        </Typography>
                        <Typography variant="body1">
                          {currentStore.Company} {currentStore.id}
                        </Typography>
                        <Typography variant="body1">
                          {currentStore.Address},{currentStore.Address2}
                        </Typography>
                        <Typography variant="body1">
                          {currentStore.City},{currentStore.State},{currentStore.Zip}
                        </Typography>
                        <Typography variant="body1">
                          {currentStore.Phone}
                        </Typography>
                      </div>
                      <div style={{ width: "50%", textAlign: "center" }}>
                        <Typography variant="h5">
                          {" "}
                          <span
                            style={{
                              color: "#D93A2F",
                              letterSpacing: "3.5px",
                              paddingBottom: "6px",
                            }}
                          >
                            Destination
                          </span>{" "}
                        </Typography>
                        <Typography variant="body1">
                          {" "}
                          <span
                            style={{ letterSpacing: "1.5px", fontSize: shipFontSize }}
                          ></span>
                          {customerDetail.name}
                        </Typography>
                        <Typography variant="body1">
                          {" "}
                          <span
                            style={{ letterSpacing: "1.5px", fontSize: shipFontSize }}
                          ></span>
                          {customerDetail.address}
                        </Typography>
                        <Typography variant="body1">
                          {" "}
                          <span
                            style={{ letterSpacing: "1.5px", fontSize: shipFontSize }}
                          ></span>
                          {customerDetail.city},{customerDetail.zipCode},{customerDetail.state}
                        </Typography>
                        <Typography variant="body1">
                          {" "}
                          <span
                            style={{ letterSpacing: "1.5px", fontSize: shipFontSize }}
                          ></span>
                          US
                        </Typography>
                      </div>
                    </div>
                    <div style={{ display: "flex", width: "100%", marginTop: "5px", justifyContent: "space-between" }}>
                      <Typography
                        variant="body1"
                        style={{ letterSpacing: "1.5px", paddingTop: "6px" }}
                      >
                        Ship Method:<br></br><b>{unpackagedItems.LevelOfService}</b>
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ letterSpacing: "1.5px", paddingTop: "6px" }}
                      >
                        Date of Shipment:<br></br><b>{today}</b>
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ letterSpacing: "1.5px", paddingTop: "6px" }}
                      >
                        Date of Order:<br></br><b>{OrderDate}</b>
                      </Typography>
                    </div>
                    <div style={{ display: "flex", width: "100%", marginTop: "5px" }}>
                      <div style={{ width: "50%", textAlign: "center" }}>
                        <Typography
                          variant="body1"
                          style={{ letterSpacing: "1.5px", paddingTop: "6px" }}
                        >
                          <b>Order #</b>
                        </Typography>
                        <Barcode width={"2px"} value={unpackagedItems?.ShipmentLines?.ShipmentLine[0]?.OrderNo} />
                      </div>
                      <div style={{ width: "50%", textAlign: "center" }}>
                        <Typography
                          variant="body1"
                          style={{ letterSpacing: "1.5px", paddingTop: "6px" }}
                        >
                          <b>Tracking #:</b>
                        </Typography>
                        <Barcode width={"2px"} value={container.TrackingNo} />
                      </div>
                    </div>
                    <div style={{ display: "flex", width: "100%", marginTop: "40px" }}>
                      <table style={{ width: "100%" }} cellPadding={0} cellSpacing={0}>
                        <thead style={{ background: "#D93A2F", color: "white" }}>
                          <th style={{ width: "10%", textAlign: "center" }}>
                            {renderTableHeading("Quantity")}
                          </th>
                          <th style={{ width: "18%", textAlign: "left" }}>
                            {renderTableHeading("Item ID")}
                          </th>
                          <th style={{ width: "44%", textAlign: "left" }}>
                            {renderTableHeading("Description")}
                          </th>
                        </thead>
                        {
                          container.ContainerDetails?.ContainerDetail?.map((containerDetail: ContainerDetail) => {
                            return (
                              <tr style={{ borderBottom: "1px solid #eee", height: "70px" }}>
                                <td style={{ textAlign: "center" }}>
                                  <Typography
                                    style={{
                                      letterSpacing: "1.5px",
                                      fontSize: "15px",
                                      fontWeight: 500,
                                    }}
                                  > {Number(containerDetail.Quantity)}</Typography>
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  <Typography style={{ letterSpacing: "1.5px", fontSize: "15px", fontWeight: 500 }}>
                                    {containerDetail.ItemID}
                                  </Typography>
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  <Typography style={{ letterSpacing: "1.5px", fontSize: "15px", fontWeight: 500 }}>
                                    {containerDetail.ItemDesc}
                                  </Typography>
                                </td>
                              </tr>
                            );
                          })
                        }
                      </table>
                    </div>
                  </div>
                );
              })
              :
              <div></div>
          }
        </div>

      </div>
      {
        openpdf ?
          <PdfIframe open={openpdf} close={closePackSlipContainer} blobUrl={""} containerName={"PackSlipContainer"}></PdfIframe>
          :
          ""
      }
      {/* <PickSlipWithPack shipmentKey={shipmentkey} close={""} newTab={true} isPrintPackOrder={openPickSlip}></PickSlipWithPack> */}
    </React.Fragment>

  );
};
