import React from 'react';
import './NotificationPopup.scss';
import { Button, Dialog, DialogContent, DialogContentText, DialogActions, Slide, DialogTitle, Typography } from '@material-ui/core';
import { TransitionProps } from '@mui/material/transitions';
import { Grid, Paper } from '@mui/material';
import { DangerousIcon, InfoIcon, WarningIcon, CheckCircleIcon, CloseIcon } from "../../Subcomponent/Shared/SharedIcons";
import Draggable from "react-draggable";
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { INotificationPopupSettings, IButton } from '../../Models/INotificationPopupSettings';
import Utils from '../../Common/Utils';
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function colorDecision(type: string) {
    switch (type) {
        case "error":
            return "#D93A2F";
        case "info":
            return "#2196F3";
        case "warning":
            return "#ff9800";
        case "success":
            return "#4CAF50";
        case "conformation":
            return "#2196F3";
        case "cancelwithreason":
            return "#E7131A";
    }
}
export const NotificationPopup: React.FC<INotificationPopupSettings> = (settings) => {
    const fontSize = Utils.isMobile ? 30 : 35

    const iconColor = "black"
    const color = colorDecision(settings.type.toLowerCase())
    const isError = settings.type.toLowerCase() === 'error';
    const isInfo = settings.type.toLowerCase() === 'info';
    const isWarning = settings.type.toLowerCase() === 'warning';
    const isSuccess = settings.type.toLowerCase() === 'success';
    const isConformation = settings.type.toLowerCase() === 'conformation';
    const iscancelWithReason = settings.type.toLowerCase() === 'cancelwithreason';
    let isExternal = useSelector((state: any) => state.GenericNotificationReducersState.isExternal);
    const PaperComponent = (props: any) => {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }
    const close = () => {
        settings.handleClose()
    }
    const renderButton = (button: IButton, index: number) => {
        return <Button size={Utils.isMobile ? "small" : "medium"} key={index} style={{ whiteSpace: 'nowrap', backgroundColor: button.color ? button.color : "#D93A2F", color: button.textColor ? button.textColor : "white", border: button.border ? `1px solid ${button.border}` : "" }} onClick={() => { if (button.action) button.action() }} variant="contained" startIcon={button.icon}>
            {button.text ? button.text : "Proceed"}
        </Button>
    }
    return (
        <Dialog disableEnforceFocus fullWidth={settings.fullWidth ? settings.fullWidth : false} maxWidth={settings.maxWidth ? "md" : "sm"} open={settings.open} onClose={settings.enableBackDropClick ? () => { settings.handleClose() } : () => { }} TransitionComponent={settings.draggable ? undefined : Transition} PaperComponent={settings.draggable ? PaperComponent : undefined} PaperProps={{ style: { borderRadius: '16px', background:"#F9F9F9" } }}>
            <DialogTitle id="draggable-dialog-title" style={{ cursor: settings.draggable ? "move" : "", padding: '5px 15px' }}>
                {/* Title */}
                <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {/* Icons */}
                        {isError && <DangerousIcon style={{ color: color, fontSize: fontSize, marginRight: 8 }} />}
                        {isInfo && <InfoIcon style={{ color: color, fontSize: fontSize, marginRight: 8 }} />}
                        {isWarning && <WarningIcon style={{ color: color, fontSize: fontSize, marginRight: 8 }} />}
                        {isSuccess && <CheckCircleIcon style={{ color: color, fontSize: fontSize, marginRight: 8 }} />}
                        {isConformation && <InfoIcon style={{ color: color, fontSize: fontSize, marginRight: 8 }} />}
                        {iscancelWithReason && <WarningIcon style={{ color: color, fontSize: fontSize, marginRight: 8 }} />}
                        {/* Title Text */}
                        <div>
                            <p style={{ color: color, fontSize: Utils.isMobile ? 20 : 26, margin: 0 }}>{settings.title}</p>
                        </div>
                    </div>
                    {/* Close Icon */}
                    {!isExternal && (
                        <div
                            onClick={close}
                            className="close-icon"
                            style={{ cursor: "pointer", textAlign: "right" }}
                        >
                            <CloseIcon style={{ fontSize: 18 }} />
                        </div>
                    )}
                </div>
            </DialogTitle>
            {
                settings.isCustomJSX ?
                    <DialogContent className={`itemDialogBorder dialogMinHeight ${settings.fullWidth ? "" : "dialogWidth"}`} style={{ textAlign: "center", height: settings.height ? settings.height : "", width: settings.width ? settings.width : "" }}>
                        <Typography style={{ fontSize: 18 }}>{settings.msg}</Typography>
                        {settings.customJSX}
                    </DialogContent>
                    :
                    <DialogContent className={`itemDialogBorder dialogMinHeight ${settings.fullWidth ? "" : "dialogMinWidth"}`} style={{ display: "flex", justifyContent: "center" }}>
                        <Typography style={{ fontSize: 16, alignSelf: "center" }}>{settings.msg}</Typography>
                    </DialogContent>
            }
            {
                settings.actions.length > 0 ?
                    <DialogActions>
                        {settings.actions?.map((button, index) => {
                            return renderButton(button, index)
                        })
                        }
                    </DialogActions>
                    :
                    ""
            }

        </Dialog >
    );
}