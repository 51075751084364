import { SHOW_SUCCESSMESSAGE, HIDE_SUCCESSMESSAGE, ISEXTERNAL } from "../ActionTypes";
const initialState = {
    showSuccess: false,
    successMessage: "",
    isExternal: false
}
const GenericNotificationReducers = (state: any = initialState,
    action: { type: any; payLoad: any }) => {
    switch (action.type) {
        case SHOW_SUCCESSMESSAGE:
            return {
                ...state,
                showSuccess: true,
                successMessage: action.payLoad
            }
        case HIDE_SUCCESSMESSAGE:
            return {
                ...state,
                showSuccess: false,
                successMessage: ""
            }
        case ISEXTERNAL:
            return {
                ...state,
                isExternal: true
            }
    }

    return state;
}

export default GenericNotificationReducers;