import { Button, Card, CardContent, Divider, Drawer, Grid, Paper, TextField, Toolbar, Typography, InputAdornment, SwipeableDrawer, Accordion, AccordionSummary, AccordionDetails, Chip, Tooltip } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import Utils from '../Common/Utils';
import Constants from '../Common/Constants';
import allActions from '../Store/Actions/AllActions';
import SiteBreadCrumbs from '../Subcomponent/Shared/SiteBreadCrumbs';
import StoreManager from "../Managers/Store.manager";
import OrderHistory from '../Subcomponent/Shared/OrderHistory';
import OrderManager from '../Managers/Orders.Manager';
import ShipmentStatusConstant from "../Common/ShipmentStatusConstants";
import { NotificationPopup } from "../Subcomponent/Shared/NotificationPopup";
import { INotificationPopupSettings, IButton } from "../Models/INotificationPopupSettings";
import { CloseIcon, ArrowBackIcon, ManageSearchIcon, PersonIcon, AddLocationAltIcon, EmailIcon, LocalPhoneIcon, CheckIcon, KeyboardReturnIcon, ArrowForwardIcon, ReportProblemIcon, ArrowBackIosNewOutlinedIcon, ExpandMoreIcon } from "../Subcomponent/Shared/SharedIcons";
import { OrderDetailGrid } from '../Subcomponent/Shared/OrderDetailGrid';
import { CustomerDetails } from '../Subcomponent/Shared/CustomerDetails';
import { IImageViewerDetail, IImageViewerPopupSettings } from '../Models/IImageViewerPopupSettings';
import { ImageViewerPopup } from '../Subcomponent/Shared/ImageViewerPopup';
import DataTable from 'react-data-table-component';
import { color } from '@mui/system';
import { ReportErrorPopup } from '../Subcomponent/Shared/ReportErrorPopup';
import { ServiceNowCnfrmText } from '../Subcomponent/Shared/ServiceNowCnfrmText';

const OrderDetail = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const pathname = window.location.pathname;
    const shipmentkey = urlParams.get('shipmentkey');
    const orderno = urlParams.get('orderno');
    const [openDrawer, setDrawer] = useState(false);
    const [openHistoryDrawer, setHistoryDrawer] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState("");
    const [orderDetail, setOrderDetail] = useState({} as any)
    const [orderDetailGridData, setOrderDetailGridData] = useState({ fulfillmentType: "", levelOfService: "", pickUpStore: "", storeNumber: "" });
    const [customerDetail, setCustomerDetail] = useState({} as any)
    const [imagePopUp, setImagePopUp] = useState(false);
    const [imageData, setImageData] = useState({} as IImageViewerDetail);

    //Report Error Action Use State
    const [reportErrorAction, setReportErrorAction] = useState("");

    // Report Error Pop Up Use States
    const [openReportError, setOpenReportError] = useState(false);
    const [openAlreadyReportedError, setOpenAlreadyReportedError] = useState(false);
    const [openConfirmReportError, setOpenConfirmReportError] = useState(false);
    const [errorReportMsg, setErrorReportMsg] = useState("");

    const location = useLocation();
    const navigateToSearchResult = () => {
        navigate("/Search")
    }
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // Scroll
    var startProductBarPos = -1;
    window.onscroll = function () {
        console.log("scrolled");
        var bar = document.getElementById('bar') as any;
        var mblBar = document.getElementById('mblBar') as any;
        if (bar) {
            if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);
            if (window.pageYOffset > startProductBarPos) {
                bar.classList.add("scrolledTopFix");
            } else {
                bar.classList.remove("scrolledTopFix");
            }
        }
    };
    function findPosY(obj: any) {
        var curtop = 0;
        if (obj && typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
            while (obj.offsetParent) {
                curtop += obj.offsetTop;
                obj = obj.offsetParent;
            }
            curtop += obj.offsetTop;
        }
        else if (obj && obj.y)
            curtop += obj.y;
        return curtop;
    }

    const showNavigation = () => {
        if (orderDetail?._source?.Shipment) {
            let { Status } = orderDetail._source.Shipment;
            let store = StoreManager.currentStore?.StoreId;
            return ((Status == ShipmentStatusConstant.ReadyForPick ||
                Status == ShipmentStatusConstant.OnHold ||
                Status == ShipmentStatusConstant.ReadyForPack ||
                Status == ShipmentStatusConstant.ShipmentBeingPacked ||
                Status == ShipmentStatusConstant.ShipmentPacked) &&
                store == orderDetail._source.Shipment.ShipNode) ||
                (Status == ShipmentStatusConstant.ReadyForCustomerPickup &&
                    store == orderDetail._source.Shipment.ExtnPickupStoreID);
        }
        return false;
    }

    const showPendingRcepit = () => {
        if (orderDetail?._source?.Shipment) {
            let { Status } = orderDetail._source.Shipment;
            return (Status == ShipmentStatusConstant.PartialPendingReceive ||
                Status == ShipmentStatusConstant.PendingReceipts)

        }
        return false;
    }

    const navigateToOrder = () => {
        if (orderDetail?._source?.Shipment) {
            let { OrderNo, ShipmentKey, ShipNode, ExtnPickupStoreID } = orderDetail._source.Shipment;
            switch (orderDetail._source.Shipment.Status) {
                case ShipmentStatusConstant.ReadyForPick: case ShipmentStatusConstant.OnHold:
                    navigate("/PickOrder?orderno=" + OrderNo + "&shipmentkey=" + ShipmentKey + "&store=" + ShipNode + "")
                    break;

                case ShipmentStatusConstant.ReadyForPack: case ShipmentStatusConstant.ShipmentBeingPacked: case ShipmentStatusConstant.ShipmentPacked:
                    navigate("/PackOrder?orderno=" + OrderNo + "&shipmentkey=" + ShipmentKey + "&store=" + ShipNode + "");
                    break;

                case ShipmentStatusConstant.ReadyForCustomerPickup:
                    navigate("/CustomerPickup?orderno=" + OrderNo + "&shipmentkey=" + ShipmentKey + "&store=" + (ExtnPickupStoreID ? ExtnPickupStoreID : 0) + "");
                    break;
            }
        }
    }

    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }
    const closeErrorPopup = () => {
        setOpenError(false)
    }
    const openErrorPopup = () => {
        setOpenError(true)
    }
    const getCustomerDetails = (shipment: any) => {
        let data = shipment?._source?.Shipment
        let initialCustomerDetail = {
            name: "",
            city: "",
            email: "",
            phone: "",
            zipCode: "",
            address: "",
            Country: "",
            state: ""
        }
        if (data.CustomerInfo != undefined) {
            try {
                if (data.CustomerInfo.hasOwnProperty("FirstName")) {
                    initialCustomerDetail.name = data.CustomerInfo.FirstName
                }
                if (data.CustomerInfo.hasOwnProperty("DayPhone")) {
                    initialCustomerDetail.phone = data.CustomerInfo.DayPhone
                }
                if (data.CustomerInfo.hasOwnProperty("LastName")) {
                    initialCustomerDetail.name = initialCustomerDetail.name + " " + data.CustomerInfo.LastName
                }
                if (data.CustomerInfo.hasOwnProperty("City")) {
                    initialCustomerDetail.city = data.CustomerInfo.City
                }
                if (data.CustomerInfo.hasOwnProperty("State")) {
                    initialCustomerDetail.state = data.CustomerInfo.State
                }
                if (data.CustomerInfo.hasOwnProperty("Country")) {
                    initialCustomerDetail.Country = data.CustomerInfo.Country
                }
                if (data.CustomerInfo.hasOwnProperty("CustomerEMailID")) {
                    initialCustomerDetail.email = data.CustomerInfo.CustomerEMailID
                }
                if (data.CustomerInfo.hasOwnProperty("ZipCode")) {
                    initialCustomerDetail.zipCode = data.CustomerInfo.ZipCode
                }
                if (data.CustomerInfo.hasOwnProperty("AddressLine1")) {
                    initialCustomerDetail.address = data.CustomerInfo.AddressLine1
                }
                if (data.CustomerInfo.hasOwnProperty("AddressLine2") && data.CustomerInfo.AddressLine2 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.CustomerInfo.AddressLine2
                }
                if (data.CustomerInfo.hasOwnProperty("AddressLine3") && data.CustomerInfo.AddressLine3 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.CustomerInfo.AddressLine3
                }
            }
            catch {
                console.log("Error Getting Customer Detail")
            }

        }
        return initialCustomerDetail
    }
    const getStatusLabel = (status: any) => {
        let statusLabel = "";
        switch (status) {
            case ShipmentStatusConstant.ReadyForPick:
                statusLabel = "Pick"
                break
            case ShipmentStatusConstant.OnHold:
                statusLabel = Constants.FulfillOrdersDisplayNames.OnHold;
                break;
            case ShipmentStatusConstant.ReadyForPack: case ShipmentStatusConstant.ShipmentBeingPacked: case ShipmentStatusConstant.ShipmentPacked:
                statusLabel = "Pack"
                break;
            case ShipmentStatusConstant.ReadyForCustomerPickup:
                statusLabel = "Customer Pickup"
                break;
            case ShipmentStatusConstant.CustomerPickedup:
                statusLabel = "Customer Pickedup"
                break;
            case ShipmentStatusConstant.PendingReceipts:
                statusLabel = "Pending Receipts"
                break;
            case ShipmentStatusConstant.PartialPendingReceive:
                statusLabel = "Pending Receive"
                break;
            case ShipmentStatusConstant.Shipped:
                statusLabel = "Shipped"
                break;
            case ShipmentStatusConstant.Cancelled:
                statusLabel = "Cancelled"
                break;
            default:
                statusLabel = status;
                break;
        }
        return statusLabel
    }
    const getDetail = () => {
        if (shipmentkey != undefined && shipmentkey != "") {
            let payload = {
                "id": shipmentkey
            }
            showActionLoader()
            setLoading(true)
            OrderManager.orderById(payload).then((result) => {
                if (result.success) {
                    if (result?.data?.hits?.hits != undefined && result?.data?.hits?.hits.length > 0) {
                        //let customerDetail = result?.data?.hits?.hits[0]
                        setCustomerDetail(getCustomerDetails(result?.data?.hits?.hits[0]))
                        setOrderDetail(result?.data?.hits?.hits[0])

                        let orderDetailGrid = {
                            fulfillmentType: result.data.hits.hits[0]?._source?.Shipment?.ShipmentLines?.ShipmentLine[0]?.FulfillmentType,
                            levelOfService: result.data.hits.hits[0]?._source?.Shipment?.LevelOfService,
                            pickUpStore: result.data.hits.hits[0]?._source?.Shipment?.ExtnPickupStoreID,
                            storeNumber: result.data.hits.hits[0]?._source?.Shipment?.ShipNode
                        };
                        setOrderDetailGridData(orderDetailGrid);
                    }
                    else {
                        let message = "Something went wrong please contact the IT Help Desk for assistance"
                        setErrorMsg(message);
                        setErrorReportMsg(message)
                        setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.ORDER_DETAILS_GET_DETAILS);
                        openErrorPopup()
                    }
                }
                hideActionLoader()
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                hideActionLoader()
                let message = "Something went wrong please contact the IT Help Desk for assistance"
                setErrorMsg(message);
                setErrorReportMsg(message);
                setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.ORDER_DETAILS_GET_DETAILS);
                openErrorPopup()
                setLoading(false)
            })
        }
    }
    const imageViewer = (item: any) => {
        let imageData: IImageViewerDetail = {
            imageURL: item.ImageUrl,
            itemDesc: item.ItemDesc,
            itemID: item.ItemDetails?.Extn?.ExtnPOSItemID,
            skuID: item.ItemID,
            price: item.UnitPrice,
            qty: item.OrderedQty
        }

        setImagePopUp(true);
        setImageData(imageData);
    }
    const handleNavigation = (row: any) => {

        navigate("/PendingReceipt?orderno=" + orderDetail._source?.Shipment?.OrderNo + "&shipmentkey=" + orderDetail._source?.Shipment?.ShipmentKey + "&shipmentcontainerkey=" + row.ShipmentContainerKey + "&store=" + orderDetail._source?.Shipment?.ExtnPickupStoreID)
    }

    const getColumns = () => {
        const columns = [
            {
                name: 'Tracking # Details',
                cell: (row: any) => row.TrackingNo,
                minWidth: Utils.isMobile ? "200px" : "250px",
                width: Utils.isMobile ? "200px" : "250px",

            },

            {
                name: '',
                cell: (row: any) => WebGridButton(row),
                button: true,
                minWidth: Utils.isMobile ? "200px" : "250px",
                width: Utils.isMobile ? "200px" : "250px",
            },

        ];
        return columns;
    }



    const WebGridButton = (row: any) => {
        return <React.Fragment>
            {
                row?.ExtnContainerStatus == "Pending Receipt" ?
                    <Button variant="contained" color="primary" className='header-button underline' onClick={() => { handleNavigation(row) }}>Go to Order</Button>
                    :
                    <Button variant="contained" className='header-button disabled' disabled>Received</Button>
            }
        </React.Fragment >
    }

    React.useEffect(() => {
        async function getOrderDetail() {
            await getDetail()
        }
        getOrderDetail()
    }, [location]);

    // Popups

    // Show Pop ups If Error is already reported
    const handleErrorAlreadyReported = () => {
        // setOpenAlreadyReportedError(true);
        setOpenReportError(false);
    }

    // OPEN Confirm Report Error
    const reportConfirmErrorPopup = () => {
        setOpenConfirmReportError(true);
        setOpenError(false);

    }

    // CLOSE Report Error POP up
    const closeErrorReportPopup = () => {
        setOpenReportError(false)
    }

    // Report Error Pop up
    let ReportError: INotificationPopupSettings = {
        open: openReportError,
        type: Constants.NotificationPopupType.Conformation,
        title: "Report Error",
        msg: "",
        isCustomJSX: true,
        customJSX: <ReportErrorPopup handleErrorAlreadyReported={handleErrorAlreadyReported} errorMessage={errorReportMsg} closeErrorReportPopup={closeErrorReportPopup} urlPathname={pathname} reportErrorAction={reportErrorAction}></ReportErrorPopup>,
        draggable: false,
        handleClose: closeErrorReportPopup,
        actions: [],
        fullWidth: true,
        maxWidth: "lg"
    }

    // OPEN Confirm Report Error POPup
    const reportErrorPopup = () => {
        setOpenReportError(true);
        setOpenConfirmReportError(false)
    }

    // CLOSE Confirm Report Error
    const closeConfirmReportError = () => {
        setOpenConfirmReportError(false);
    }
    let confirmReportErrorPopupButtons: IButton[] = [
        {
            text: "Continue",
            icon: <ArrowForwardIcon />,
            action: reportErrorPopup,
            color: Constants.Colors.red,
        }
    ];
    let confirmReportError: INotificationPopupSettings = {
        open: openConfirmReportError,
        type: Constants.NotificationPopupType.Conformation,
        title: Constants.MESSAGES.SN_CNFRM_TITLE,
        msg: "",
        customJSX: <ServiceNowCnfrmText></ServiceNowCnfrmText>,
        isCustomJSX: true,
        draggable: false,
        handleClose: closeConfirmReportError,
        actions: confirmReportErrorPopupButtons
    }

    // Error Popup

    // Buttons When there is no Action
    let errorPopupCloseButtons: IButton[] = [{
        text: "Close",
        icon: <CloseIcon />,
        action: closeErrorPopup,
        color: Constants.Colors.red,
    }];

    // Buttons When there is Action
    let errorPopupButtons: IButton[] = [
        {
            text: "Report Error",
            icon: <ReportProblemIcon />,
            action: reportConfirmErrorPopup,
            color: Constants.Colors.orange,
        },
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeErrorPopup,
            color: Constants.Colors.red,
        }
    ];

    if (Utils.getConfigurationWithKey(Constants.Configurations.ReportErrorSNFlag) === false) {
        errorPopupButtons.shift()
    };
    let Error: INotificationPopupSettings = {
        open: openError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorMsg,
        draggable: false,
        handleClose: closeErrorPopup,
        actions: reportErrorAction ? errorPopupButtons : errorPopupCloseButtons
    }


    const closeImageViewer = () => {
        setImagePopUp(false);
        setImageData({} as IImageViewerDetail);
    }

    let imageViewerSettings: IImageViewerPopupSettings = {
        open: imagePopUp,
        imageDetail: imageData,
        close: closeImageViewer
    }



    const navigateToHome = () => {
        //navigate("/");
        navigate(-1)
    }
    const getType = (row: any) => {
        if (row && row.length > 0) {
            let isWarranty = false;
            let isBundle = false;
            let isResourced = false;
            row.forEach((element: any) => {
                if (element.ExtnIsWarrantyItem == "Y") {
                    isWarranty = true;
                }
                if (element.KitCode && element.KitCode != "") {
                    isBundle = true;
                }
                if (element.ExtnRescheduleReqCount && element.ExtnRescheduleReqCount > 0) {
                    isResourced = true;
                }
            });
            let title = "";
            let cellValue = "";
            if (isBundle) {
                title = title + "SET";
                cellValue = cellValue + "S";
            }

            if (isWarranty) {
                if (isBundle) {
                    title = title + "/PRO COV";
                    cellValue = cellValue + "/PC";
                } else {
                    title = title + "PRO COV";
                    cellValue = cellValue + "PC";
                }
            }
            if (isResourced) {
                title =
                    isBundle || isWarranty ? title + "/RESOURCED" : title + "RESOURCED";
                cellValue = isBundle || isWarranty ? cellValue + "/R" : cellValue + "R";
            }
            return (
                <div style={{ marginLeft: '4px' }}>
                    <Tooltip title={title}>
                        {isBundle || isWarranty || isResourced ? (
                            <Chip
                                className="chipTracking"
                                label={cellValue}
                                size="small"
                                style={{
                                    background: "#A1D899",
                                    color: "#006100",
                                    fontSize: "10px",
                                    height: "16px",
                                    borderRadius: '4px',
                                }}
                            />
                        ) : (
                            <></>
                        )}
                    </Tooltip>
                </div>
            );
        }
        else {
            return <></>
        }
    };
    const mobileView = () => {
        const height = Utils.displayHeight + 'px';
        return <React.Fragment >
            {<div style={{ paddingBottom: "150px" }}>
                {/* <Paper id='bar' style={{ color: "white", top: 0, left: 0, right: 0, zIndex: 999, borderRadius: 0 }} className="appBarPrimary">
                    <Toolbar style={{ height: "46px", minHeight: "46px" }}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}

                            onClick={() => { navigate(-1) }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ marginLeft: "-48px" }}>
                            Order Detail
                        </Typography>
                        <IconButton style={{ background: "#D93A2F", color: "white" }} sx={{ p: '10px' }} aria-label="actions">
                        </IconButton>
                    </Toolbar>
                </Paper> */}


                <Paper style={{ marginTop: 2, paddingTop: 1, paddingBottom: 1, background: "#F9F9F9" }}>
                    <Grid container style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                        <Grid item xs={12} sm={12} className='textLeft'>
                            <Grid container style={{ paddingLeft: "12px" }}>
                                <Grid item xs={3}>
                                    <Button onClick={() => navigateToHome()} className="back-button" variant='contained' startIcon={<ArrowBackIosNewOutlinedIcon />}>Back</Button>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography className="medium-heading">Order #</Typography>
                                    <Typography className="large-heading" style={{ wordBreak: "break-all" }}>{
                                        orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                                            orderDetail?._source?.Shipment?.OrderNo
                                            :
                                            ""
                                    }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {showPendingRcepit() &&
                        <Grid container spacing={2} style={{ padding: 10 }}>
                            <Grid item xs={12} sm={12}   >
                                <div className='dataTableOrder'>
                                    <DataTable
                                        className='dataTableOrder'
                                        title=""
                                        data={orderDetail?._source?.Shipment.Containers.Container}
                                        columns={getColumns()}
                                    />
                                </div>
                            </Grid>

                        </Grid>}
                    {showNavigation() && <div style={{ padding: "0px 5px 0px 5px" }}>
                        <Button onClick={() => { navigateToOrder() }} style={{ marginTop: "4px", marginBottom: "4px", width: "98%" }} variant='contained' className='header-button underline'>Go To Order</Button>
                    </div>}
                    {/* <Card style={{ margin: "6px" }} id='cardOrder'>
                        <CardContent style={{ padding: "10px" }}>
                            <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                <Grid item xs={10} sm={9} style={{ padding: "8px" }}>
                                    <Typography variant="h6" style={{ padding: "0px" }} className=''>
                                        <b>Order # </b> {
                                            orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                                                orderDetail?._source?.Shipment?.OrderNo
                                                :
                                                ""
                                        }
                                    </Typography>
                                    <Typography variant="h6" style={{ padding: "0px" }} className=''>
                                        <b>Status: </b>{
                                            orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                                                getStatusLabel(orderDetail?._source?.Shipment?.Status)
                                                :
                                                ""
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid spacing={2} container alignItems="center" justifyContent="center" marginTop="2px" sx={{ flexGrow: 1 }}>
                                {showNavigation() && <Button variant='contained' className='btn' size='small'
                                    onClick={() => navigateToOrder()}>Go To Order</Button>}
                            </Grid>
                        </CardContent>
                    </Card> */}
                    <OrderDetailGrid loading={loading} orderDetailRoute={true} data={orderDetailGridData}></OrderDetailGrid>
                    <Accordion defaultExpanded style={{ margin: 5, padding: 10, borderRadius: 16, boxShadow: "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 2px rgb(0 0 0 / 12%)" }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ color: Constants.Colors.darkGrey, height: 80 }}
                        >
                            <div className="topHeading">
                                <div className="gridHeading" style={{ display: "flex" }}>
                                    <Typography style={{ display: "flex" }}> Order Lines {getType(orderDetail?._source?.Shipment?.ShipmentLines?.ShipmentLine)}</Typography>

                                </div>
                            </div>
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails style={{ display: "block", padding: 0, minHeight: "250px", overflowY: "scroll" }}>
                            {
                                orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                                    <React.Fragment>
                                        {
                                            Utils.getValidShipLines(orderDetail?._source?.Shipment?.ShipmentLines?.ShipmentLine, true).map((line: any, index: any) => {

                                                return (
                                                    <React.Fragment>
                                                        <Grid container display={"flex"} style={{ marginTop: "20px" }} className="justifyBetween productHeader">
                                                            <div>
                                                                <Grid item xs={12} sm={12} spacing={2} container alignItems="center" justifyContent="space-between">
                                                                    <Grid item display={"flex"} style={{ marginTop: "8px" }}>
                                                                        <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                                                            Qty
                                                                        </Typography>
                                                                        <Typography variant="body2" sx={{ fontSize: 13, fontWeight: 500, marginLeft: 1 }}>
                                                                            {line.OrderedQty}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item display={"flex"} style={{ marginTop: "8px" }}>
                                                                        <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                                                            Status
                                                                        </Typography>
                                                                        <Typography variant="body2" sx={{ fontSize: 13, fontWeight: 500, marginLeft: 1 }}>
                                                                            {
                                                                                orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                                                                                    getStatusLabel(orderDetail?._source?.Shipment?.Status)
                                                                                    :
                                                                                    ""
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </Grid>
                                                        <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                            <Grid item xs={4} sm={4} style={{ padding: "8px" }}>
                                                                <img style={{ height: "100px", cursor: "zoom-in" }} alt="" src={Utils.convertToHttps(line.ImageUrl ? line.ImageUrl : "")} onClick={() => { imageViewer(line) }} />
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} style={{ padding: "8px", textAlign: "left" }}>
                                                                {Utils.isUsedItemByDescription(line.ItemDesc) ?
                                                                    <Chip
                                                                        className="chipTracking"
                                                                        label={"Used"}
                                                                        size="small"
                                                                        style={{
                                                                            background: "#A1D899",
                                                                            color: "#006100",
                                                                            fontSize: "10px",
                                                                            height: "16px",
                                                                            borderRadius: '4px',
                                                                        }}
                                                                    /> :
                                                                    <></>
                                                                }
                                                                <Typography style={{ fontSize: "16px", fontWeight: 400 }}>{line.ItemDesc}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <div style={{ marginLeft: '1rem', marginBottom: '1rem', marginTop: '1rem' }}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                                                <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                        Item #
                                                                    </Typography>
                                                                    <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                        <b>{line.ItemID}</b>
                                                                    </Typography>
                                                                </div>
                                                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                        Price
                                                                    </Typography>
                                                                    <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                        <b>${Utils.nFormatter(line.UnitPrice, 2)}</b>
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            line.KitCode == "BUNDLE" && line.ExtnIsWarrantyItem != "Y" && line.MinLineStatus != "9000" && line.MaxLineStatus != "9000" ?
                                                                <React.Fragment>
                                                                    {
                                                                        orderDetail?._source?.Shipment?.ShipmentLines.ShipmentLine.map((bLine: any) => {
                                                                            if (bLine.BundleParentOrderLineKey != undefined && line.OrderLineKey != undefined && bLine.BundleParentOrderLineKey == line.OrderLineKey) {
                                                                                return (
                                                                                    <React.Fragment>
                                                                                        <Grid container display={"flex"} style={{ marginTop: "20px" }} className="justifyBetween productHeader">
                                                                                            <div>
                                                                                                <Grid item xs={12} sm={12} spacing={2} container alignItems="center" justifyContent="space-between">
                                                                                                    <Grid item display={"flex"} style={{ marginTop: "8px" }}>
                                                                                                        <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                                                                                            Qty
                                                                                                        </Typography>
                                                                                                        <Typography variant="body2" sx={{ fontSize: 13, fontWeight: 500, marginLeft: 1 }}>
                                                                                                            {line.OrderedQty}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item display={"flex"} style={{ marginTop: "8px" }}>
                                                                                                        <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                                                                                            Status
                                                                                                        </Typography>
                                                                                                        <Typography variant="body2" sx={{ fontSize: 13, fontWeight: 500, marginLeft: 1 }}>
                                                                                                            {
                                                                                                                orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                                                                                                                    getStatusLabel(orderDetail?._source?.Shipment?.Status)
                                                                                                                    :
                                                                                                                    ""
                                                                                                            }
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                                            <Grid item xs={4} sm={4} style={{ padding: "8px" }}>
                                                                                                <img style={{ height: "100px", cursor: "zoom-in" }} alt="" src={Utils.convertToHttps(bLine.ImageUrl ? line.ImageUrl : "")} />
                                                                                            </Grid>
                                                                                            <Grid item xs={6} sm={6} style={{ padding: "8px", textAlign: "left" }}>
                                                                                                <Typography style={{ fontSize: "16px", fontWeight: 400 }}>{bLine.ItemDesc}</Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <div style={{ marginLeft: '1rem', marginBottom: '1rem', marginTop: '1rem' }}>
                                                                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                                                                                <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                                                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                        Item #
                                                                                                    </Typography>
                                                                                                    <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                        <b>{bLine.ItemID}</b>
                                                                                                    </Typography>
                                                                                                </div>
                                                                                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                        Price
                                                                                                    </Typography>
                                                                                                    <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                        <b>${Utils.nFormatter(bLine.UnitPrice, 2)}</b>
                                                                                                    </Typography>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                );
                                                                            }
                                                                        })

                                                                    }
                                                                </React.Fragment>
                                                                : <></>
                                                        }
                                                    </React.Fragment>)
                                            })
                                        }
                                    </React.Fragment>
                                    :
                                    <></>
                            }
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded style={{ margin: 5, padding: 10, borderRadius: 16, boxShadow: "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 2px rgb(0 0 0 / 12%)" }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ color: Constants.Colors.darkGrey, height: 80 }}
                        >
                            <div className="topHeading">
                                <div className="gridHeading" style={{ display: "flex" }}>
                                    <Typography>Customer Details</Typography>
                                </div>
                            </div>
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails style={{ display: "block", padding: 0, overflowY: "scroll" }}>
                            {
                                orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                                    <CustomerDetails customerDetail={customerDetail}></CustomerDetails>
                                    :
                                    <></>
                            }
                        </AccordionDetails>
                    </Accordion>
                    <div>
                        {
                            loading == false ?
                                <OrderHistory orderDetail={true}></OrderHistory>
                                :
                                <></>
                        }
                    </div>
                    {/* <Card className='customerDetailsBoxPickOrder'>
                        <CardContent style={{ padding: "1px", paddingBottom: "10px", background: "#F3F3F4" }}>
                            <Button onClick={() => { setDrawer(true) }} className="MobileDrawerButtons" variant="outlined">Customer Details</Button>
                            <Button onClick={() => { setHistoryDrawer(true) }} className="MobileDrawerButtons" variant="outlined">Fulfillment History</Button>
                        </CardContent>
                    </Card> */}
                </Paper>



                {/* <SwipeableDrawer
                    anchor={"bottom"}
                    open={openHistoryDrawer}
                    onClose={() => { }}
                    onOpen={() => { }}
                    ModalProps={{ onBackdropClick: () => { setHistoryDrawer(false) } }}
                    onClick={() => { setHistoryDrawer(false) }}
                >
                    <Card style={{ margin: "6px" }}>
                        <CardContent style={{ padding: "10px" }}>
                            {
                                loading == false ?
                                    <OrderHistory orderDetail={true}></OrderHistory>
                                    :
                                    <></>
                            }
                        </CardContent>
                    </Card>
                </SwipeableDrawer>
                <Drawer
                    anchor={"bottom"}
                    open={openDrawer}
                    onClose={() => { }}
                    onClick={() => { setDrawer(false) }}
                >
                    <Card style={{ margin: "6px" }}>
                        <CardContent style={{ padding: "10px" }}>
                            {
                                orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                                    <CustomerDetails customerDetail={customerDetail}></CustomerDetails>
                                    :
                                    <></>


                            }
                        </CardContent>
                    </Card>
                </Drawer> */}
            </div>}
        </React.Fragment>
    }

    const webView = () => {
        return (
            <div>
                {/* <SiteBreadCrumbs siteBreadCrumbs={Constants.SiteBreadCrumbs.OrderDetail}></SiteBreadCrumbs> */}
                <div style={{ margin: "20px" }}>
                    {/* <Paper className='paperToBottomPadding' style={{ padding: "10px" }}> */}
                    <Grid container spacing={2} display={"flex"}>
                        <Grid item xs={6} sm={6} className='textLeft'>
                            <Grid container display={"flex"}>
                                <Grid item>
                                    <Button onClick={() => navigateToHome()} className="back-button" variant='contained' startIcon={<ArrowBackIosNewOutlinedIcon />}>Back</Button>
                                </Grid>
                                <Grid item style={{ marginLeft: "30px" }}>
                                    <Typography className="medium-heading">Order #</Typography>
                                    <Typography className="large-heading">
                                        {
                                            orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                                                orderDetail?._source?.Shipment?.OrderNo
                                                : ""
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6} className='textRight'>
                            {/* <Button variant='contained' className='btn' onClick={() => navigateToSearchResult()} startIcon={<ManageSearchIcon />}>Advance Search</Button> */}
                            {showNavigation() && <Button variant='contained' className='header-button underline' onClick={() => navigateToOrder()}>Go To Order</Button>}
                        </Grid>
                    </Grid>
                    {/* </Paper> */}
                    {showPendingRcepit() &&
                        <Grid container spacing={2} style={{ marginTop: "5px" }}>
                            <Grid item xs={12} sm={12}>
                                <div className='dataTableOrder'>
                                    <DataTable
                                        className='dataTableOrder'
                                        title=""
                                        data={orderDetail?._source?.Shipment.Containers.Container}
                                        columns={getColumns()}
                                    />
                                </div>
                            </Grid>

                        </Grid>}
                    <div style={{ marginTop: "26px" }}>
                        <Grid container spacing={2} display={"flex"} style={{ padding: "0px" }}>
                            <Grid item xs={9} sm={9} style={{ paddingTop: "0px", paddingRight: "4px", paddingLeft: "0px" }} className='textLeft'>
                                <OrderDetailGrid loading={loading} orderDetailRoute={true} data={orderDetailGridData}></OrderDetailGrid>
                                <Card className="dCard" style={{ margin: 16 }}>
                                    <CardContent className="dCardContent">
                                        <Typography className="card-heading" style={{ display: "flex" }}> Order Lines {getType(orderDetail?._source?.Shipment?.ShipmentLines?.ShipmentLine)}</Typography>
                                        <Divider style={{ border: "1px" }} />
                                        <div style={{ marginTop: 2, padding: "20px" }}>
                                            {
                                                orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                                                    <React.Fragment>
                                                        {
                                                            Utils.getValidShipLines(orderDetail?._source?.Shipment?.ShipmentLines?.ShipmentLine, true).map((line: any, index: any) => {

                                                                return (
                                                                    <React.Fragment>
                                                                        <Grid container spacing={1} display={"flex"} className="justifyBetween productHeader">
                                                                            <div>
                                                                                <Grid item xs={12} sm={12} spacing={2} container alignItems="center" justifyContent="space-between">
                                                                                    <Grid item display={"flex"} style={{ marginTop: "8px" }}>
                                                                                        <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                                                                            Qty
                                                                                        </Typography>
                                                                                        <Typography variant="body2" sx={{ fontSize: 13, fontWeight: 500, marginLeft: 1 }}>
                                                                                            {line.OrderedQty}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item display={"flex"} style={{ marginTop: "8px" }}>
                                                                                        <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                                                                            Status
                                                                                        </Typography>
                                                                                        <Typography variant="body2" sx={{ fontSize: 13, fontWeight: 500, marginLeft: 1 }}>
                                                                                            {
                                                                                                orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                                                                                                    getStatusLabel(orderDetail?._source?.Shipment?.Status)
                                                                                                    :
                                                                                                    ""
                                                                                            }
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid container spacing={1} display={"flex"} key={index} style={{ margin: 0 }}>
                                                                            <Grid item xs={12} sm={12} className='textLeft'>
                                                                                <Grid container spacing={1} style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                                    <Grid item xs={3} sm={3} style={{ padding: "8px", display: "flex", justifyContent: "center" }}>
                                                                                        <div className="image-zoom" onClick={() => imageViewer(line)}>
                                                                                            <img style={{ height: "auto", width: "100%" }} alt="" src={Utils.convertToHttps(line.ImageUrl ? line.ImageUrl : "")} />
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={9} sm={9} style={{ padding: "8px", textAlign: "left" }}>
                                                                                        {Utils.isUsedItemByDescription(line.ItemDesc) ?
                                                                                            <Chip
                                                                                                className="chipTracking"
                                                                                                label={"Used"}
                                                                                                size="small"
                                                                                                style={{
                                                                                                    background: "#A1D899",
                                                                                                    color: "#006100",
                                                                                                    fontSize: "10px",
                                                                                                    height: "16px",
                                                                                                    borderRadius: '4px',
                                                                                                }}
                                                                                            /> :
                                                                                            <></>
                                                                                        }
                                                                                        <Typography style={{ fontSize: "16px", fontWeight: 400 }}>{line.ItemDesc}</Typography>
                                                                                        <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                                                                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                                                                                <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                                                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                        Item #
                                                                                                    </Typography>
                                                                                                    <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                        <b>{line.ItemID}</b>
                                                                                                    </Typography>
                                                                                                </div>
                                                                                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                        Price
                                                                                                    </Typography>
                                                                                                    <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                        <b>${Utils.nFormatter(line.UnitPrice, 2)}</b>
                                                                                                    </Typography>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        {
                                                                            line.KitCode == "BUNDLE" && line.ExtnIsWarrantyItem != "Y" && line.MinLineStatus != "9000" && line.MaxLineStatus != "9000" ?
                                                                                <React.Fragment>
                                                                                    {
                                                                                        orderDetail?._source?.Shipment?.ShipmentLines.ShipmentLine.map((bLine: any) => {
                                                                                            if (bLine.BundleParentOrderLineKey != undefined && line.OrderLineKey != undefined && bLine.BundleParentOrderLineKey == line.OrderLineKey) {
                                                                                                return (
                                                                                                    <React.Fragment>
                                                                                                        <Grid container spacing={1} display={"flex"} className="justifyBetween productHeader">
                                                                                                            <div>
                                                                                                                <Grid item xs={12} sm={12} spacing={2} container alignItems="center" justifyContent="space-between">
                                                                                                                    <Grid item display={"flex"} style={{ marginTop: "8px" }}>
                                                                                                                        <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                                                                                                            Qty
                                                                                                                        </Typography>
                                                                                                                        <Typography variant="body2" sx={{ fontSize: 13, fontWeight: 500, marginLeft: 1 }}>
                                                                                                                            {line.OrderedQty}
                                                                                                                        </Typography>
                                                                                                                    </Grid>
                                                                                                                    <Grid item display={"flex"} style={{ marginTop: "8px" }}>
                                                                                                                        <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                                                                                                            Status
                                                                                                                        </Typography>
                                                                                                                        <Typography variant="body2" sx={{ fontSize: 13, fontWeight: 500, marginLeft: 1 }}>
                                                                                                                            {
                                                                                                                                orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                                                                                                                                    getStatusLabel(orderDetail?._source?.Shipment?.Status)
                                                                                                                                    :
                                                                                                                                    ""
                                                                                                                            }
                                                                                                                        </Typography>
                                                                                                                    </Grid>

                                                                                                                </Grid>
                                                                                                            </div>
                                                                                                        </Grid>
                                                                                                        <Grid container spacing={1} display={"flex"} key={index} style={{ margin: 0 }}>
                                                                                                            <Grid item xs={12} sm={12} className='textLeft'>
                                                                                                                <Grid container spacing={1} style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                                                                    <Grid item xs={3} sm={3} style={{ padding: "8px", display: "flex", justifyContent: "center" }}>
                                                                                                                        <div className="image-zoom" onClick={() => imageViewer(bLine)}>
                                                                                                                            <img style={{ height: "auto", width: "100%" }} alt="" src={Utils.convertToHttps(bLine.ImageUrl ? line.ImageUrl : "")} />
                                                                                                                        </div>
                                                                                                                    </Grid>
                                                                                                                    <Grid item xs={9} sm={9} style={{ padding: "8px", textAlign: "left" }}>
                                                                                                                        <Typography style={{ fontSize: "16px", fontWeight: 400 }}>{bLine.ItemDesc}</Typography>
                                                                                                                        <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                                                                                                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                                                                                                                <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                                                                                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                                                        Item #
                                                                                                                                    </Typography>
                                                                                                                                    <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                                                        <b>{bLine.ItemID}</b>
                                                                                                                                    </Typography>
                                                                                                                                </div>
                                                                                                                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                                                                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                                                        Price
                                                                                                                                    </Typography>
                                                                                                                                    <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                                                        <b>${Utils.nFormatter(bLine.UnitPrice, 2)}</b>
                                                                                                                                    </Typography>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </Grid>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </React.Fragment>
                                                                                                );
                                                                                            }
                                                                                        })

                                                                                    }
                                                                                </React.Fragment>
                                                                                : <></>
                                                                        }
                                                                    </React.Fragment>)
                                                            })
                                                        }
                                                    </React.Fragment> :
                                                    <></>
                                            }
                                        </div>
                                        <Divider style={{ border: "1px" }} />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3} sm={3} style={{ paddingTop: "0px", paddingRight: "0px", paddingLeft: "6px", marginTop: "16px" }}>

                                <Card className="dCard">
                                    <CardContent className="dCardContent">
                                        <Typography className="card-heading" style={{ textAlign: "left" }}> Customer Details</Typography>
                                        <Divider style={{ border: "1px" }} />
                                        {
                                            orderDetail?._source?.Shipment?.OrderNo != undefined && orderDetail?._source?.Shipment?.OrderNo != "" ?
                                                <CustomerDetails customerDetail={customerDetail}></CustomerDetails>
                                                :
                                                <></>
                                        }
                                    </CardContent>
                                </Card>
                                <div>
                                    {
                                        loading == false ?
                                            <OrderHistory orderDetail={true}></OrderHistory>
                                            :
                                            <></>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div >
            </div >
        );
    }
    return (
        <div>
            <NotificationPopup {...Error}></NotificationPopup>
            <NotificationPopup {...ReportError}></NotificationPopup>
            <NotificationPopup {...confirmReportError}> </NotificationPopup>
            <ImageViewerPopup {...imageViewerSettings}>
            </ImageViewerPopup>
            {Utils.isMobile ? mobileView() : webView()}
        </div>
    );

}

export default OrderDetail;