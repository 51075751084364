import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import RestoreIcon from "@material-ui/icons/Restore";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeIcon from "@material-ui/icons/Home";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import CloudDownload from "@material-ui/icons/CloudDownload";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import AssignmentIcon from "@material-ui/icons/Assignment";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import Constants from "../../Common/Constants";
import IdentityManager from "../../Managers/Identity.manager";
import "./SharedUplift.scss";
import { Paper } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  AssessmentIcon,
  BarChartIcon,
  FeedbackIcon,
  SyncIcon,DownloadingOutlinedIcon, InventoryOutlinedIcon, RedoOutlinedIcon
} from "./SharedIcons";
const MobileNavbar = (props: any) => {
  console.log("SSSSS", props);
  const displayDrawerData = props.displayDrawerData;
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setMobileNavSelectedValue] = React.useState(0);
  const kcdcQuantity = useSelector(
    (state: any) => state.ActionLoaderReducersState.kcdcTotalQuantity
  );
  const renderMobileNavbar = (displayDrawerData: any) => {
    let visibleData =
      displayDrawerData.length > 4
        ? displayDrawerData.slice(0, 4)
        : displayDrawerData;
    return (
      <Paper
        style={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 999 }}
        className="mobileNavigationBarss"
        elevation={13}
      >
        <BottomNavigation
          style={{ position: "fixed", bottom: "-4px", width: "100%", height: "8%"  , paddingBottom: "76px"}}
          showLabels={true}
          value={props.selected}
          onChange={(e: any, newValue) => {
            props.setMobileNavSelectedValue(newValue);
            let currentTarget = e.currentTarget.innerText?.toString();
            console.log(newValue, "newValue");
            console.log(e, "value of e");
            console.log(currentTarget, "- Current Target");
            console.log(e.currentTarget.innerText, "- Inner Text");

            if (currentTarget.trim() === "More") {
              console.log("In Navbar If Condition");
              setAnchorEl(e.currentTarget);
            } else {
              console.log("In Navbar Else Condition");
              let selectedRoute = IdentityManager.getUserNavigation().filter(
                (e: any) => {
                  return e.Position == newValue;
                }
              );
              if (selectedRoute && selectedRoute.length > 0) {
                navigate(selectedRoute[0].Route);
              }
            }
          }}
        >
          {visibleData.map((item: any, i: number) => (
            <BottomNavigationAction
              className="mobileNavbarButton"
              style={{ alignItems: "flex-start" }}
              key={i}
              label={getItemHeader(item)}
              icon={
                <>
                  {i === props.selected ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "#EEEEEE",
                        color:"#2D2D2D",
                        borderRadius: "16px",
                        width: "64px",
                        height: "32px",
                        marginTop: "6px",
                        marginBottom: "6px",
                      }}
                    >
                      {renderMobileIcon(item.Position)}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "64px",
                        height: "32px",
                        marginTop: "6px",
                        marginBottom: "6px",
                      }}
                    >
                      {renderMobileIcon(item.Position)}
                    </div>
                  )}
                </>
              }
            />
          ))}

          {renderMobilMoreOptions(displayDrawerData)}
          {renderMoreDialogOptions(displayDrawerData)}
        </BottomNavigation>
      </Paper>
    );
  };

  const getItemHeader = (item: any) => {
    if (item.Route == Constants.RouteLabels.KCDCReturns && kcdcQuantity > 0) {
      return `${item.Header} (${kcdcQuantity})`;
    }
    return item.Header;
  };

  const getMenuItemHeader = (item: any) => {
    if (item.Route == Constants.RouteLabels.KCDCReturns && kcdcQuantity > 0) {
      return (
        <span className="dropdownMenuItem">
          <span>
            {item.Header} ({kcdcQuantity})
          </span>
          {renderMobileIcon(item.Position)}
        </span>
      );
    }
    return (
      <span className="dropdownMenuItem">
        <span>{item.Header}</span>
        {renderMobileIcon(item.Position)}
      </span>
    );
  };

  const renderMobilMoreOptions = (displayDrawerData: any) => {
    if (displayDrawerData && displayDrawerData.length > 3) {
      return (
        <BottomNavigationAction
          className="mobileNavbarButton moreButton"
          style={{ background: "black !important", alignItems: "flex-start" }}
          label={"More"}
          icon={
            <>
            {props.selected > 3 ?
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#EEEEEE",
                  color:"#2D2D2D",
                  borderRadius: "16px",
                  width: "64px",
                  height: "32px",
                  marginTop: "6px",
                  marginBottom: "6px",
                  marginRight: "10px",
                }}
              >
                <MenuIcon />
              </div>:
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "64px",
                  height: "32px",
                  marginTop: "6px",
                  marginBottom: "6px",
                }}
              >     
                <MenuIcon />
              </div>
            }
            </>
          }
        />
      );
    }
  };

  const renderMoreDialogOptions = (displayDrawerData: any) => {
    if (displayDrawerData && displayDrawerData.length > 4) {
      let mobileToShowMoreOptions = displayDrawerData.slice(
        4,
        displayDrawerData.length
      );
      return (
        <Menu
          PaperProps={{
            style: {
              width: "230px",
              borderRadius: "16px",
            },
          }}
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMoreOptionClose}
        >
          {mobileToShowMoreOptions.map((item: any, i: number) => {
            return (
              <MenuItem
                key={i}
                onClick={() => {
                  mobileMoreOptionsClicked(item);
                }}
              >
                {getMenuItemHeader(item)}
              </MenuItem>
            );
          })}
        </Menu>
      );
    }
  };

  const renderMobileIcon = (type: any) => {
    switch (type) {
      case 0:
        return <HomeIcon></HomeIcon>;
      case 1:
        return <InventoryOutlinedIcon></InventoryOutlinedIcon>;
      case 2:
        return <DownloadingOutlinedIcon></DownloadingOutlinedIcon>;
      case 3:
        return <RedoOutlinedIcon></RedoOutlinedIcon>;
      case 4:
        return <AssignmentIcon></AssignmentIcon>;
      case 5:
        return <BarChartIcon />;
      case 7:
        return <SyncIcon />;
      case 8:
        return <FeedbackIcon />;
    }
  };

  const handleMoreOptionClose = () => {
    setAnchorEl(null);
  };

  const mobileMoreOptionsClicked = (item: any) => {
    handleMoreOptionClose();
    navigate(item.Route);
  };

  return renderMobileNavbar(displayDrawerData);
};

export default MobileNavbar;
