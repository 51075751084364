import { Divider, IconButton, ListItemIcon, MenuItem, Menu } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MoreVertIcon, EscalatorIcon, EmailIcon, CancelIcon, EscalatorOutlinedIcon, EmailOutlinedIcon, CancelOutlinedIcon } from "../../Subcomponent/Shared/SharedIcons";
import Utils from '../../Common/Utils';
import allActions from '../../Store/Actions/AllActions';
import { useDispatch, useSelector } from 'react-redux';
import { IReasonState } from '../../Models/IReasons';
import Constants from '../../Common/Constants';
const ItemAction = (props: any) => {
    //run bob
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    let cancelReason: IReasonState = useSelector((state: any) => state.ActionLoaderReducersState.cancelReason);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const openResourcePopUp = () => {
        props.openResources(props.ShipmentLineKey);
        handleClose();
    };

    const openContactCustomer = () => {
        props.openCustomerContact();
        handleClose();
    };

    const openCancelLine = () => {
        dispatch(allActions.actionLoaderActions.setCancelReason(
            {
                ...cancelReason,
                showModal: true,
                cancelItem: props.item
            }
        ));
        handleClose();
    };

    return (
        <div>
            <IconButton
                style={{ paddingTop: 5 }}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined} onClick={handleClick}><MoreVertIcon /></IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                keepMounted
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                PaperProps={{
                    style: {
                        width: "230px",
                        borderRadius: "16px",
                    },
                }}
            >
                <MenuItem
                    className="dropdownMenuItem" onClick={() => openResourcePopUp()}>
                    Resource
                    {/* <ListItemIcon style={{ paddingLeft: 20, color: Constants.Colors.darkGrey }}> */}
                    <EscalatorOutlinedIcon style={{ fontSize: "20px" }} />
                    {/* </ListItemIcon> */}
                </MenuItem>
                <Divider />
                <MenuItem className="dropdownMenuItem" onClick={() => openContactCustomer()}>
                    Contact Customer
                    {/* <ListItemIcon style={{ paddingLeft: 30, color: Constants.Colors.darkGrey }}> */}
                    <EmailOutlinedIcon style={{ fontSize: "20px" }} />
                    {/* </ListItemIcon> */}
                </MenuItem>

                <Divider />
                <MenuItem className="dropdownMenuItem cancel" onClick={() => { openCancelLine() }}>
                    Cancel Line
                    {/* <ListItemIcon style={{ paddingLeft: 20, color: Constants.Colors.red }}> */}
                    <CancelOutlinedIcon style={{ fontSize: "20px" }} />
                    {/* </ListItemIcon> */}
                </MenuItem>
            </Menu>
        </div >
    );

}
export default ItemAction;