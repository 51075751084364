import React, { useState } from "react";
import Utils from "../../Common/Utils";
import "./NavBarUplift.scss";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import { useNavigate } from "react-router-dom";
import AuthManager from "../../Managers/Auth.manager";
import logo from "../../Content/gc_logo05_alt.png";
import {
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Stack,
  Paper,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
} from "@mui/material";
import {
  SearchIcon,
  CancelIcon,
  SearchOutlinedIcon,
  AddLocationAltIcon,
  LogoutIcon,
} from "../../Subcomponent/Shared/SharedIcons";
import { NotificationPopup } from "../../Subcomponent/Shared/NotificationPopup";
import {
  INotificationPopupSettings,
  IButton,
} from "../../Models/INotificationPopupSettings";
import IdentityManager from "../../Managers/Identity.manager";
import Constants from "../../Common/Constants";
import { StoreListDropDown } from "./StoreListDropDown";
import { CloseIcon } from "../../Subcomponent/Shared/SharedIcons";
import { DistrictListDropDown } from "./DistrictListDropDown";
import { useDispatch } from "react-redux";
import allActions from "../../Store/Actions/AllActions";
import { DrawerDropdowns } from "./DrawerDropdowns";
const MobileAppbar = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorUserProfileEl] = React.useState<null | HTMLElement>(
    null
  );
  const [showSearch, setShowSearch] = useState(false);
  const logout = () => AuthManager.logout();
  const handleMenuClose = () => setAnchorUserProfileEl(null);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [openStoreChagnePopup, setOpenStoreChagnePopup] = useState(false);
  const [districtPopup, setDistrictPopup] = React.useState(false);
  const handleMenuOpen = (event: any) => {
    setAnchorUserProfileEl(event.currentTarget);
  };
  const openStorePopup = () => {
    setOpenStoreChagnePopup(true);
    setAnchorUserProfileEl(null);
  };
  const closeStorePopup = () => {
    setOpenStoreChagnePopup(false);
  };
  const sugessionResult = [
    {
      primary: "Order # GC2983472",
      CustomerName: "Sohaib Javed",
      QTY: "1",
    },
    {
      primary: "Order # GC2983473",
      CustomerName: "Sohaib Javed",
      QTY: "1",
    },
    {
      primary: "Order # GC29834986",
      CustomerName: "Sohaib Javed",
      QTY: "1",
    },
    {
      primary: "Order # GC2983477",
      CustomerName: "Sohaib Javed",
      QTY: "1",
    },
    {
      primary: "Order # GC2983479",
      CustomerName: "Sohaib Javed",
      QTY: "1",
    },
  ];
  const navigateToSearchResult = () => {
    navigate("/Search");
  };
  const navigateToOrderDetail = () => {
    navigate("/OrderDetail");
  };
  const openDistrictPopup = () => {
    setDistrictPopup(true);
  };
  const closeDistrictPopup = () => {
    setDistrictPopup(false);
  };
  const searchSuggestionResult = () => {
    return (
      <Paper
        className="searchResult"
        style={{ width: "98%", margin: 2, zIndex: 99999 }}
      >
        <List style={{ padding: 0 }}>
          {sugessionResult.map((key: any, index: any) => {
            return (
              <div>
                <ListItem
                  onClick={() => navigateToOrderDetail()}
                  className="SearchHover"
                  alignItems="flex-start"
                  style={{ paddingTop: 2, paddingBottom: 2 }}
                >
                  <ListItemText
                    primary={key.primary}
                    secondary={
                      <React.Fragment>
                        {"Customer Name:"}
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          color="text.primary"
                          style={{ marginRight: 10 }}
                        >
                          {key.CustomerName}
                        </Typography>
                        {"QTY:"}
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          color="text.primary"
                        >
                          1
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider />
              </div>
            );
          })}
        </List>
      </Paper>
    );
  };

  const searchTextChange = (event: any) => {
    setSearchText(event.target.value);
    if (event.target.value.length > 2) {
      setShowSuggestion(true);
    } else {
      setShowSuggestion(false);
    }
  };
  let changeStorePopupButtons: IButton[] = [
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeStorePopup,
      color: Constants.Colors.red,
    },
  ];
  let changeStore: INotificationPopupSettings = {
    open: openStoreChagnePopup,
    type: Constants.NotificationPopupType.Info,
    title: "Change Store",
    actions: changeStorePopupButtons,
    handleClose: closeStorePopup,
    isCustomJSX: true,
    msg: "",
    customJSX: (
      <StoreListDropDown
        store={props.store}
        changeStoreList={props.changeStoreList}
      ></StoreListDropDown>
    ),
    //reason: reason,
    //description: description,
  };

  let changeDistrictPopupButtons: IButton[] = [
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeDistrictPopup,
      color: Constants.Colors.red,
    },
  ];
  let changeDistrict: INotificationPopupSettings = {
    open: districtPopup,
    type: Constants.NotificationPopupType.Info,
    title: "Change District",
    actions: changeDistrictPopupButtons,
    handleClose: closeDistrictPopup,
    isCustomJSX: true,
    msg: "",
    customJSX: (
      <DistrictListDropDown
        district={props.district}
        changeDistrict={props.changeDistrict}
      ></DistrictListDropDown>
    ),
  };

  const openDrawerDropdowns = () => {
    dispatch(allActions.actionLoaderActions.setDrawerDropdowns(true));
  };

  const renderMainOptions = () => {
    if (Utils.isExternalRoute()) return "";

    return (
      <div className="containerBox profileIcon">
        <IconButton
          className="containerBox profileIcon profileIconBtn"
          color="inherit"
          onClick={() => props.openFullScreenSearch()}
          style={{backgroundColor:"#BBBBBB"}}
        >
          <SearchOutlinedIcon style={{ fontSize: "22px", color: "black" }} />
        </IconButton>
        <IconButton
          className="containerBox profileIcon profileIconBtn"
          style={{backgroundColor:"#BBBBBB"}}
          color="inherit"
          onClick={openDrawerDropdowns}
          id="iconButton"
        >
          <AddLocationAltIcon style={{ fontSize: "22px", color: "black" }} />
        </IconButton>
        {IdentityManager.isAdmin && (
          <IconButton
            className="containerBox profileIcon profileIconBtn"
            style={{backgroundColor:"#BBBBBB"}}
            onClick={handleMenuOpen}
            color="inherit"
          >
            <AccountCircleOutlinedIcon
              style={{ fontSize: "22px", color: "black" }}
            />
          </IconButton>
        )}
        <Menu
          PaperProps={{
            style: {
              width: "230px",
              borderRadius: "16px",
            },
          }}
          key={Utils.generateRandom(10, Constants.CryptoRandomTypes.numeric)}
          open={open}
          keepMounted
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem
            className="dropdownMenuItem"
            onClick={() => props.setuserProfileDialog(true)}
            key={Utils.generateRandom(10, Constants.CryptoRandomTypes.numeric)}
          >
            Profile
            <AccountCircleOutlinedIcon style={{ fontSize: "20px" }} />
          </MenuItem>
          <Divider />
          {/* {IdentityManager.isAdmin ? (
            <MenuItem
              className="dropdownMenuItem"
              key={Utils.generateRandom(
                10,
                Constants.CryptoRandomTypes.numeric
              )}
              onClick={openStorePopup}
            >
              <span>Choose Store</span>
              <AddLocationAltIcon style={{ fontSize: "20px" }} />
            </MenuItem>
          ) : (
            ""
          )} */}
          {/* <Divider /> */}
          <MenuItem
            className="dropdownMenuItem"
            key={Utils.generateRandom(10, Constants.CryptoRandomTypes.numeric)}
            onClick={logout}
          >
            Sign Out
            <LogoutIcon style={{ fontSize: "20px" }} />
          </MenuItem>
        </Menu>
      </div>
    );
  };

  const renderMobileAppbar = () => {
    return (
      <div>
        {" "}
        <NotificationPopup {...changeStore}></NotificationPopup>
        <NotificationPopup {...changeDistrict}></NotificationPopup>
        <DrawerDropdowns
          region={props.region}
          changeRegion={props.changeRegion}
          district={props.district}
          changeDistrict={props.changeDistrict}
          store={props.store}
          changeStoreList={props.changeStoreList}
        ></DrawerDropdowns>
        <Paper
          id="mblBar"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 999,
            boxShadow: "none",
          }}
        >
          <div className="mobileNabvarContainer">
            <div className="mobile-compact-logo-container" />
            {!showSearch ? (
              <React.Fragment>
                <Stack
                  direction={"column"}
                  style={{ width: "100%", paddingTop: "0px" }}
                >
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    {/* <div className="containerBox imageBox">
                                    <img alt='Logo' src={logo} style={{ height: "40px" }} />
                                </div> */}
                    <div
                      className="containerBox titleBox"
                      style={{ color: "white", fontSize: "18px" }}
                    >
                      {/* Store Fulfillment */}
                    </div>
                    {renderMainOptions()}
                  </div>
                </Stack>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Paper
                  className={!Utils.isMobile ? "searchWeb" : ""}
                  style={{
                    padding: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "87%",
                    borderRadius: "0",
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Orders"
                    inputProps={{ "aria-label": "search google maps" }}
                    onChange={(e) => searchTextChange(e)}
                    value={searchText}
                  />
                  <IconButton
                    style={{ background: "#d6d3d3" }}
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={() => navigateToSearchResult()}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
                <IconButton
                  style={{ color: "white" }}
                  sx={{ p: "10px" }}
                  aria-label="close"
                  onClick={() => {
                    setShowSearch(false);
                    setShowSuggestion(false);
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </React.Fragment>
            )}
          </div>
          {showSuggestion ? searchSuggestionResult() : <></>}
        </Paper>
      </div>
    );
  };
  const open = Boolean(anchorEl);
  return renderMobileAppbar();
};
export default MobileAppbar;
