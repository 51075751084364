import ApiService from "../Common/ApiService";
import { ICustomerContact, Item } from '../Models/ICustomerContact'
export default class ContactCustomerManager {
    public static getEmailTemplates = async () => {
        try {
            return await ApiService.nomGet(`nom/getTemplates`);
        }
        catch (error) {
            console.log(error);
        }
    }

    // Get Manager OKta Ptofile
    public static getDisplayNameFromOktaByEmpId = async (empId: any) => {
        try {
            return await ApiService.getSimple(`/okta/getDisplayNameFromOktaByEmpId?empId=${empId}`);
        }
        catch (error) {
            console.log(error);
        }
    }

    public static getTemplateHtml = async (payload: any) => {
        payload.view = true
        //
        try {
            return await ApiService.nomPost(`nom/getTemplateHtml`, payload);
        }
        catch (error) {
            console.log(error);
        }
    }
    public static getFollowupTemplateHtml = async (payload: any) => {
        payload.view = true
        //
        try {
            return await ApiService.nomPost(`nom/getFollowupTemplateHtml`, payload);
        }
        catch (error) {
            console.log(error);
        }
    }
    public static setFollowupTemplateHtml = async (payload: any) => {
        payload.view = true
        //
        try {
            return await ApiService.nomPost(`nom/sendFollowupCustomerEmail`, payload);
        }
        catch (error) {
            console.log(error);
        }
    }
    public static sendCustomerEmail = async (payload: any) => {
        //
        try {
            return await ApiService.nomPost(`nom/sendCustomerEmail`, payload);
        }
        catch (error) {
            console.log(error);
        }
    }
    public static sendEmailWithAttatchment = async (payload: any) => {
        try {
            return await ApiService.nomPost(`nom/sendCustomerEmailWithAttatchment`, payload);
        }
        catch (error) {
            console.log(error);
        }
    }
    public static getImages = async (payload: any) => {
        try {
            return await ApiService.nomPost(`nom/getImages`, payload);
        }
        catch (error) {
            console.log(error);
        }
    }
    public static getFollowUpContactCustomerTemplate = async (payload: any) => {
        try {
            return await ApiService.nomPost(`nom/getFollowUpContactCustomerTemplate`, payload);
        }
        catch (error) {
            console.log(error);
        }
    }
}