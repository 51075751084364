import React, { useCallback, useState, useEffect } from 'react';
import { Button, IconButton, Paper, Toolbar, Typography, Drawer, Card, CardContent, TextField } from '@mui/material';
import Utils from '../../Common/Utils';
import { ArrowBackIcon, RestartAltIcon, FlashOffIcon, CheckIcon, AssignmentLateIcon, CloseIcon } from "../Shared/SharedIcons";
import Constants from '../../Common/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationPopup } from '../Shared/NotificationPopup'
import { INotificationPopupSettings, IButton } from '../../Models/INotificationPopupSettings';
import StoreManager from '../../Managers/Store.manager';
import {
    DataCaptureView,
    Camera,
    DataCaptureContext,
    configure,
    FrameSourceState,
    RadiusLocationSelection,
    NumberWithUnit,
    MeasureUnit,
    AimerViewfinder,
    Brush,
    Color,
} from "scandit-web-datacapture-core";
import type { SymbologySettings, Barcode, BarcodeCaptureSession, TrackedBarcode } from "scandit-web-datacapture-barcode";
import {
    barcodeCaptureLoader,
    BarcodeCapture,
    BarcodeCaptureSettings,
    Symbology,
    BarcodeCaptureOverlay,
    SymbologyDescription,
    BarcodeTrackingSettings,
    BarcodeTrackingScenario,
    BarcodeTracking,
    BarcodeTrackingBasicOverlay
} from "scandit-web-datacapture-barcode";
import { TailSpin } from "react-loader-spinner";
const MobileBarCodeScannerTrackingNumberScandit = (props: any) => {
    const licenseKey = StoreManager.config.scanditLicense;
    const [isOneScanned, setisOneScanned] = useState(false)
    const width = Utils.getWebbarWidth
    const [openDrawer, setDrawer] = useState(false);
    const barCode: any = '';
    const d: any = null;
    const [scanData, setData] = useState(d);
    //Scandit Redux 
    let view: DataCaptureView = useSelector((state: any) => state.ScandItReducersState.view);
    let context: DataCaptureContext = useSelector((state: any) => state.ScandItReducersState.context);
    let barCodeCapture: BarcodeCapture = useSelector((state: any) => state.ScandItReducersState.barCodeCapture);
    let barCodeTracking: BarcodeTracking = useSelector((state: any) => state.ScandItReducersState.barCodeTracking);
    let camera: Camera = useSelector((state: any) => state.ScandItReducersState.camera);
    let barCodeTrackingOverlay: BarcodeTrackingBasicOverlay = useSelector((state: any) => state.ScandItReducersState.barCodeTrackingOverlay);
    let barCodeCaptureOverlay: BarcodeCaptureOverlay = useSelector((state: any) => state.ScandItReducersState.barCodeCaptureOverlay);

    const [viewFullLoad, setViewFullLoad] = useState({} as DataCaptureView | undefined);
    const [contextFullLoad, setContextFullLoad] = useState({} as DataCaptureContext | undefined);
    const [barcodeCaptureFullLoad, setBarcodeCaptureFullLoad] = useState({} as BarcodeCapture | undefined);
    const [barcodeTrackingFullLoad, setBarcodeTrackingFullLoad] = useState({} as BarcodeTracking | undefined);
    const [cameraFullLoad, setCameraFullLoad] = useState({} as Camera | undefined);
    const [barcodeTrackingOverlayFullLoad, setTrackingOverlayFullLoad] = useState({} as BarcodeTrackingBasicOverlay | undefined);
    const [barcodeCaptureOverlayFullLoad, setOverlayFullLoad] = useState({} as BarcodeCaptureOverlay | undefined);
    const [openGeneralError, setOpenGeneralError] = useState(false);
    const [errorGeneralMsg, setErrorGeneralMsg] = useState("");

    const closeGeneralErrorModel = () => {
        setOpenGeneralError(false);
        setErrorGeneralMsg("");
    }
    const showGeneralErrorModel = async (msg: any) => {

        setOpenGeneralError(true);
        setErrorGeneralMsg(msg);

    }
    let errorGeneralPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeGeneralErrorModel,
            color: Constants.Colors.red,
        }
    ];
    let GeneralError: INotificationPopupSettings = {
        open: openGeneralError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorGeneralMsg,
        draggable: false,
        handleClose: closeGeneralErrorModel,
        actions: errorGeneralPopupButtons
    }
    const onScanned = (data: any) => {
        setData(data);
        setisOneScanned(true)
        //setDrawer(true);  
        props.onScanned(data, false);
    }

    const changeScanData = (event: any) => {
        setData(event.target.value)
    }
    const initScanditFocused = async () => {
        try {
            view.connectToElement(document.getElementById("data-capture-view")!);
            view.showProgressBar();
            view.setProgressBarPercentage(null);
            view.setProgressBarMessage("Accessing Camera...");
            barCodeCapture.addListener({
                didScan: async (barcodeCaptureMode: BarcodeCapture, session: BarcodeCaptureSession) => {
                    await barCodeCapture.setEnabled(false);
                    const barcode: Barcode = session.newlyRecognizedBarcodes[0];
                    const symbology: SymbologyDescription = new SymbologyDescription(barcode.symbology);
                    onScanned(barcode.data ?? "");
                },
            });
            await camera.switchToDesiredState(FrameSourceState.On);
            await barCodeCapture.setEnabled(true);
            view.hideProgressBar();
        }
        catch (err: any) {
            console.error(err);
            let errorString = JSON.stringify(err)
            if (errorString == "{}") {
                errorString = err.toString();
            }
            showGeneralErrorModel(errorString);
        }

    }
    const initScanditMatrixScand = async () => {
        try {
            view.connectToElement(document.getElementById("data-capture-view")!);
            view.showProgressBar();
            view.setProgressBarPercentage(null);
            view.setProgressBarMessage(Utils.getConfigurationWithKey("ScanLoaderMessage"));
            barCodeTrackingOverlay.listener = {
                brushForTrackedBarcode: (overlay: BarcodeTrackingBasicOverlay, trackedBarcode: TrackedBarcode) => {
                    const fillColor = Color.fromRGBA(60, 179, 113, 0.4);
                    const strokeColor = Color.fromRGBA(60, 179, 113, 0.4);
                    const strokeWidth = 2;
                    return new Brush(fillColor, strokeColor, strokeWidth);
                },
                didTapTrackedBarcode: (overlay: BarcodeTrackingBasicOverlay, trackedBarcode: TrackedBarcode) => {
                    onScanned(trackedBarcode.barcode.data ?? "");
                }
            };
            await camera.switchToDesiredState(FrameSourceState.On);
            await barCodeTracking.setEnabled(true);
            view.hideProgressBar();
        }
        catch (err: any) {
            console.error(err);
            let errorString = JSON.stringify(err)
            if (errorString == "{}") {
                errorString = err.toString();
            }
            showGeneralErrorModel(errorString);
        }
    }
    const initScanditMatrixScandFullLoad = async () => {
        try {
            await configure({
                licenseKey: licenseKey,
                libraryLocation: new URL("./scandit/build/", document.baseURI).toString(),
                moduleLoaders: [barcodeCaptureLoader()],
            });
            let _view = new DataCaptureView();
            _view.connectToElement(document.getElementById("data-capture-view")!);
            _view.showProgressBar();
            _view.setProgressBarPercentage(null);
            _view.setProgressBarMessage(Utils.getConfigurationWithKey("ScanLoaderMessage"));
            let _context: DataCaptureContext = await DataCaptureContext.create();
            await _view.setContext(_context);
            const _camera: Camera = Camera.default;
            const cameraSettings = BarcodeTracking.recommendedCameraSettings;
            await _camera.applySettings(cameraSettings);
            await _context.setFrameSource(_camera);
            const settings: BarcodeTrackingSettings = BarcodeTrackingSettings.forScenario(BarcodeTrackingScenario.A)
            settings.enableSymbologies([
                Symbology.EAN13UPCA,
                Symbology.EAN8,
                Symbology.UPCE,
                Symbology.Code39,
                Symbology.Code128
            ]);
            const symbologyUPCASettings: SymbologySettings = settings.settingsForSymbology(Symbology.EAN13UPCA);
            symbologyUPCASettings.setExtensionEnabled("remove_leading_upca_zero", true);

            const _barcodeTracking = await BarcodeTracking.forContext(_context, settings);
            await _barcodeTracking.setEnabled(false);
            const _barcodeTrackingOverlay: BarcodeTrackingBasicOverlay = await BarcodeTrackingBasicOverlay.withBarcodeTrackingForView(_barcodeTracking, _view)

            _barcodeTrackingOverlay.listener = {
                brushForTrackedBarcode: (overlay: BarcodeTrackingBasicOverlay, trackedBarcode: TrackedBarcode) => {
                    const fillColor = Color.fromRGBA(60, 179, 113, 0.4);
                    const strokeColor = Color.fromRGBA(60, 179, 113, 0.4);
                    const strokeWidth = 2;

                    return new Brush(fillColor, strokeColor, strokeWidth);
                },
                didTapTrackedBarcode: (overlay: BarcodeTrackingBasicOverlay, trackedBarcode: TrackedBarcode) => {
                    onScanned(trackedBarcode.barcode.data ?? "");
                }
            };
            await _camera.switchToDesiredState(FrameSourceState.On);
            await _barcodeTracking.setEnabled(true);
            _view.hideProgressBar();
            setContextFullLoad(_context);
            setViewFullLoad(_view);
            setCameraFullLoad(_camera);
            setBarcodeTrackingFullLoad(_barcodeTracking);
            setTrackingOverlayFullLoad(_barcodeTrackingOverlay);
        }
        catch (err: any) {
            console.error(err);
            let errorString = JSON.stringify(err)
            if (errorString == "{}") {
                errorString = err.toString();
            }
            showGeneralErrorModel(errorString);
        }
    }
    const initScanditFocusedFullLoad = async () => {
        try {
            await configure({
                licenseKey: licenseKey,
                libraryLocation: new URL("./scandit/build/", document.baseURI).toString(),
                moduleLoaders: [barcodeCaptureLoader()],
            });
            let _view = new DataCaptureView();
            _view.connectToElement(document.getElementById("data-capture-view")!);
            _view.showProgressBar();
            _view.setProgressBarPercentage(null);
            _view.setProgressBarMessage("Accessing Camera...");
            let _context: DataCaptureContext = await DataCaptureContext.create();
            await _view.setContext(_context);
            const _camera: Camera = Camera.default;
            const cameraSettings = BarcodeCapture.recommendedCameraSettings;
            await _camera.applySettings(cameraSettings);
            await _context.setFrameSource(_camera);
            const settings: BarcodeCaptureSettings = new BarcodeCaptureSettings();
            settings.locationSelection = new RadiusLocationSelection(new NumberWithUnit(0.2, MeasureUnit.Fraction))
            settings.enableSymbologies([
                Symbology.EAN13UPCA,
                Symbology.EAN8,
                Symbology.UPCE,
                Symbology.Code39,
                Symbology.Code128
            ]);
            const symbologyUPCASettings: SymbologySettings = settings.settingsForSymbology(Symbology.EAN13UPCA);
            symbologyUPCASettings.setExtensionEnabled("remove_leading_upca_zero", true);
            const _barcodeCapture = await BarcodeCapture.forContext(_context, settings);
            await _barcodeCapture.setEnabled(false);
            const _barcodeCaptureOverlay: BarcodeCaptureOverlay = await BarcodeCaptureOverlay.withBarcodeCaptureForView(_barcodeCapture, _view)
            const brush = new Brush(
                Color.fromHex("#50C878"),
                Color.fromHex("#50C878"),
                1
            )
            _barcodeCaptureOverlay.setBrush(brush)
            const viewfinder: AimerViewfinder = new AimerViewfinder()
            await _barcodeCaptureOverlay.setViewfinder(viewfinder)

            _barcodeCapture.addListener({
                didScan: async (barcodeCaptureMode: BarcodeCapture, session: BarcodeCaptureSession) => {
                    await _barcodeCapture.setEnabled(false);
                    const barcode: Barcode = session.newlyRecognizedBarcodes[0];
                    const symbology: SymbologyDescription = new SymbologyDescription(barcode.symbology);
                    onScanned(barcode.data ?? "");
                },
            });
            await _camera.switchToDesiredState(FrameSourceState.On);
            await _barcodeCapture.setEnabled(true);
            _view.hideProgressBar();
            setContextFullLoad(_context);
            setViewFullLoad(_view);
            setCameraFullLoad(_camera);
            setBarcodeCaptureFullLoad(_barcodeCapture);
            setOverlayFullLoad(_barcodeCaptureOverlay);
        }
        catch (err: any) {
            console.error(err);
            let errorString = JSON.stringify(err)
            if (errorString == "{}") {
                errorString = err.toString();
            }
            showGeneralErrorModel(errorString);
        }
    }
    const cleanUp = async () => {
        let loadScandit = Utils.getConfigurationWithKey("LoadScanditOnStart")
        if (loadScandit == "1") {
            await camera?.switchToDesiredState(FrameSourceState.Off);
            view?.detachFromElement(); //
        }
        else {
            await cameraFullLoad?.switchToDesiredState(FrameSourceState.Off);
            await contextFullLoad?.dispose();
            viewFullLoad?.detachFromElement(); //
        }
    }
    const goBack = async () => {
        await cleanUp();
        props.onScanned("", true);
    }
    React.useEffect(() => {
        async function start() {
            let activeType = Utils.getConfigurationWithKey("ScanTypeMatrix")
            let loadScandit = Utils.getConfigurationWithKey("LoadScanditOnStart")
            if (loadScandit == "1") {
                if (activeType == "1") {
                    await initScanditMatrixScand();
                }
                else {
                    await initScanditFocused();
                }
            }
            else {
                if (activeType == "1") {
                    await initScanditMatrixScandFullLoad();
                }
                else {
                    await initScanditFocusedFullLoad();
                }
            }
            window.scrollTo(0, 0)
        }
        start();
    }, []);
    return (<div>
        <NotificationPopup {...GeneralError}></NotificationPopup>
        <Drawer
            style={{ zIndex: 99999 }}
            anchor={"top"}
            open={openDrawer}
            onClose={() => { }}
            onClick={() => { }}
        >
            <Card style={{ margin: "6px" }}>
                <CardContent style={{ padding: "10px" }}>
                    <Typography variant="h5" component="div" style={{ fontSize: "18px" }}>
                        Enter Bar Code
                        <br />
                    </Typography>
                    <br></br>
                    <TextField fullWidth variant={"outlined"} type={"number"} placeholder='SKU#' id="fullWidth" value={scanData} />
                    <br></br>
                    <br></br>
                    <Typography variant="h5" component="div" style={{ fontSize: "18px" }}>
                        Quantity
                        <br />
                    </Typography>
                    <br></br>
                    <TextField fullWidth variant={"outlined"} type={"number"} InputProps={{ inputProps: { min: 1 } }} defaultValue={1} InputLabelProps={{ shrink: false }} label="" placeholder='Quantity' id="fullWidth" />
                    <Button onClick={() => { setDrawer(false); setisOneScanned(false) }} style={{ marginTop: "20px", marginRight: "5px", width: "48%", color: Constants.Colors.red, borderColor: Constants.Colors.red }} variant='outlined' className='' startIcon={<RestartAltIcon />}>Retry</Button>
                    <Button onClick={() => { props.onScanned("", false); }} style={{ marginTop: "20px", width: "48%" }} variant='contained' className='btn' startIcon={<CheckIcon />}>Done</Button>
                </CardContent>
            </Card>
        </Drawer>
        <Paper style={{ color: Constants.COLORS.MOBILE_RED, top: 0, left: 0, right: 0, zIndex: 999 }} className="">
            <Toolbar style={{ height: "46px", minHeight: "46px" }}>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={() => goBack()}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="caption" component="div" sx={{ flexGrow: 1 }} style={{ marginRight: 60 }}>
                    {Utils.getConfigurationWithKey("ScanLoaderMessage")}
                </Typography>
            </Toolbar>
        </Paper>
        {
            isOneScanned == false ?
                <div>
                    <div id="data-capture-view"></div>
                </div>
                :
                <div></div>
        }
    </div>)
}
export default MobileBarCodeScannerTrackingNumberScandit;