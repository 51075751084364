import { SET_HISTORY, SAVE_HISTORY, IS_LOADING_HISTORY, FORCE_HISTORY_LOAD } from '../ActionTypes';
import Constants from "../../Common/Constants";
import { IVerticalStepper } from '../../Models/IVerticalStepper';
import Utils from '../../Common/Utils';

interface IOrderHistoryStat {
    isLoading: boolean,
    history: IVerticalStepper,
    key?: any
}

const initialState: IOrderHistoryStat = {
    isLoading: false,
    key: Utils.getCurrentDate(),
    history: {
        activeStep: "",
        stepsOptions: [],

    }
}

const OrderHistoryReducer = (state: any = initialState,
    action: { type: any; payLoad: any }) => {
    let clone = JSON.parse(JSON.stringify(state));
    console.log(action.payLoad, "payload");
    switch (action.type) {
        case SET_HISTORY:
            return {
                ...state,
                history: action.payLoad
            }
        case IS_LOADING_HISTORY:
            return {
                ...state,
                isLoading: action.payLoad
            }
        case FORCE_HISTORY_LOAD:
            return {
                ...state,
                key: action.payLoad,
                isLoading: true
            }
    }

    return state;
}

export default OrderHistoryReducer;

