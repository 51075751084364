import { Box, Card, CardContent, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import React, { useEffect } from "react";
import { useState } from "react";
import Constants from "../../Common/Constants";
import Utils from "../../Common/Utils";
import SAD from '../../Assets/SAD.png';
import HAPPY from '../../Assets/HAPPY.png';
import NEUTRAL from '../../Assets/NEUTRAL.png';
import { ChatBubbleIcon, ChatIcon, MarkEmailReadIcon, MarkunreadIcon, ShoppingCartIcon, ZoomOutMapIcon } from "../Shared/SharedIcons"
import { CommunicationDataGrid } from "./CommunicationDataGrid";
import CustomChip from "./CustomChip";

import IdentityManager from "../../Managers/Identity.manager";


export const FeedbackTile = (props: any) => {

    return (
        <React.Fragment>
            <Card className='dCard' >
                <CardContent className='dCardContent' style={{ padding: 0 }}  >
                    <div className='dCardLeftHeader'>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <ChatIcon style={{ color: Constants.Colors.red }} />
                        </div>
                        <Typography style={{ fontWeight: "bold", color: "#5f6661", fontSize: 17, marginTop: "5px", marginLeft: "5px" }}>
                            Feedback
                        </Typography>

                    </div>
                    <Divider />
                    <Grid container>
                        <Grid item xs={4} sm={4} >
                            <Grid container>
                                <Grid item sm={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                    <div className="tiles-icons" >
                                        <img width={40} src={SAD} alt=""></img>
                                    </div>
                                </Grid>
                                <Grid item sm={8} style={{ display: "grid", justifyItems: "start" }}>
                                    <p className="tiles-header-font">Unhappy</p>
                                    <p className="tiles-value-font">
                                        {props.feedbackStats?.unSatisfiedReactionsPer}{"%"}
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <Grid container>
                                <Grid item sm={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                    <div className="tiles-icons" >
                                        <img width={40} src={NEUTRAL} alt=""></img>
                                    </div>
                                </Grid>
                                <Grid item sm={8} style={{ display: "grid", justifyItems: "start" }}>
                                    <p className="tiles-header-font">Neutral</p>
                                    <p className="tiles-value-font">
                                        {props.feedbackStats?.neutralReactionsPer}{"%"}
                                    </p>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <Grid container>
                                <Grid item sm={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                    <div className="tiles-icons">
                                        <img width={40} src={HAPPY} alt=""></img>
                                    </div>
                                </Grid>
                                <Grid item sm={8} style={{ display: "grid", justifyItems: "start" }}>
                                    <p className="tiles-header-font">Satisfied</p>
                                    <p className="tiles-value-font">
                                        {props.feedbackStats?.satisfiedReactionsPer}{"%"}
                                    </p>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid container style={{ backgroundColor: "#5A5A5A", minHeight: "80px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid container style={{ marginTop: "10px", display: "flex", justifyContent: "space-around" }}>
                            <Grid item sm={8}>
                                {/* <Box> */}
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    <Typography style={{ color: "white", fontSize: "18px" }}>Total Responses Received</Typography>
                                </div>
                                {/* </Box> */}
                            </Grid>
                            <Grid item sm={4}>
                                <Box>
                                    <Typography style={{ color: "white", fontWeight: "bold", wordBreak: "break-all", fontSize: "18px" }}>{props.feedbackStats?.totalReactions}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </React.Fragment >);
}