import React, { useCallback, useState } from 'react';
import { Bars } from "react-loader-spinner";
import Utils from '../../Common/Utils';
import allActions from '../../Store/Actions/AllActions';
import { useDispatch, useSelector } from 'react-redux';
const loaderLeft = Utils.isMobile ? "35%" : "46.5%"
export default function ActionLoader() {
  const showLoader = useSelector((state: any) => state.ActionLoaderReducersState);
  return (
    <div className="ActionLoader" style={{ display: showLoader.show > 0 ? "block" : "none" }}>
      <div style={{ position: "fixed", top: "40%", left: loaderLeft }}>
        <Bars color="#D93A2F" height={120} width={120} />
      </div>
    </div>
  );
}
