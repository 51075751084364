import {
    LOAD_KCDCORDERS,
    LOAD_KCDC_ORDER_DETAILS
} from "../ActionTypes";
const loadKcdcOrders = (obj: any) => {
    return {
        type: LOAD_KCDCORDERS,
        payLoad: obj
    };
}

const loadKcdcOrderDetail = (obj: any) =>{

    return {
        type: LOAD_KCDC_ORDER_DETAILS,
        payLoad: obj
    };
}

const kcdcOrdersActions = {
    loadKcdcOrders,
    loadKcdcOrderDetail
}


export default kcdcOrdersActions;
