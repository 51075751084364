import { AnyARecord } from "dns";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CHANGE_ShipmentDataFromShippingCenter, REMOVE_ALL_BOXCHARACTERISTIC_FROMLIST, CHANGE_PACKAGED_ITEM, CHANGE_ITEM_QUANTITY, CHANGE_SELECTED_ITEMS, ADD_UNPACKAGED_ITEMS, ADD_BOXCHARACTERISTIC_TOLIST, INITILIZE_BOXCHARACTERISTIC, REMOVE_BOXCHARACTERISTIC_FROMLIST, CHANGE_BOXCHARACTERISTIC, CHANGE_SHIPDATE, CHANGE_RESIDENTIAL, CHANGE_CUSTOMER_DETAIL_PACK } from "../ActionTypes";
const addBoxToList = (obj: any) => {
    return {
        type: ADD_BOXCHARACTERISTIC_TOLIST,
        payLoad: obj
    };
}
const initilizeBoxCharacteristicList = (obj: any) => {
    return {
        type: INITILIZE_BOXCHARACTERISTIC,
        payLoad: obj
    }
}
const removeBoxFromList = (obj: any) => {
    return {
        type: REMOVE_BOXCHARACTERISTIC_FROMLIST,
        payLoad: obj
    };
}
const removeAllBoxFromList = () => {
    return {
        type: REMOVE_ALL_BOXCHARACTERISTIC_FROMLIST
    }
}
const changeBoxCharacteristicByIndex = (obj: any) => {
    return {
        type: CHANGE_BOXCHARACTERISTIC,
        payLoad: obj
    };
}
const changeShipDate = (obj: any) => {
    return {
        type: CHANGE_SHIPDATE,
        payLoad: obj
    };
}
const changeResidential = (obj: any) => {
    return {
        type: CHANGE_RESIDENTIAL,
        payLoad: obj
    };
}
const addUnpackagedItems = (obj: any) => {
    return {
        type: ADD_UNPACKAGED_ITEMS,
        payLoad: obj
    };
}
const changeSelectedItems = (obj: any) => {
    return {
        type: CHANGE_SELECTED_ITEMS,
        payLoad: obj
    };
}
const changeItemQuantity = (obj: any) => {
    return {
        type: CHANGE_ITEM_QUANTITY,
        payLoad: obj
    };
}
const changePackagedItem = (obj: any) => {
    return {
        type: CHANGE_PACKAGED_ITEM,
        payLoad: obj
    };
}
const changeCustomerDetail = (obj: any) => {
    return {
        type: CHANGE_CUSTOMER_DETAIL_PACK,
        payLoad: obj
    }
}
const changeShipmentDataFromShippingCenter = (obj: any) => {
    return {
        type: CHANGE_ShipmentDataFromShippingCenter,
        payLoad: obj
    }
}


const packOrderActions = {
    changeSelectedItems,
    changeShipDate,
    changeResidential,
    addBoxToList,
    initilizeBoxCharacteristicList,
    removeBoxFromList,
    changeBoxCharacteristicByIndex,
    addUnpackagedItems,
    changeItemQuantity,
    changePackagedItem,
    removeAllBoxFromList,
    changeCustomerDetail,
    changeShipmentDataFromShippingCenter
}

export default packOrderActions;