import { Tooltip, Typography } from "@mui/material";
import {
  AssignmentIcon,
  BarChartOutlinedIcon,
  CalendarMonthOutlinedIcon,
  CallMadeIcon,
  CallReceived,
  DownloadingOutlinedIcon,
  EqualizerIcon,
  FeedbackOutlinedIcon,
  ForumIcon,
  HistoryIcon,
  HomeIcon,
  InventoryOutlinedIcon,
  PlaylistAddCheckIcon,
  RedoOutlinedIcon,
  SyncOutlinedIcon,
} from "./SharedIcons";
import { useNavigate } from "react-router-dom";

export default function CompactNavbar(props: any): any {
  const navigate = useNavigate();

  const chooseIcon = (value: any) => {
    switch (value) {
      case "Dashboard":
        return <HomeIcon style={{ fontSize: "24px" }} />;
      case "FulfillOrders":
        return <InventoryOutlinedIcon style={{ fontSize: "24px" }} />;
      case "ReceiveOrders":
        return <DownloadingOutlinedIcon style={{ fontSize: "24px" }} />;
      case "KCDCReturns":
        return <RedoOutlinedIcon style={{ fontSize: "24px" }} />;
      case "Reports":
        return <BarChartOutlinedIcon style={{ fontSize: "24px" }} />;
      case "TechAudits":
        return <AssignmentIcon style={{ fontSize: "20px" }} />;
      case "OrdersSyncFlow":
        return <SyncOutlinedIcon style={{ fontSize: "20px" }} />;
      case "Feedback":
        return <FeedbackOutlinedIcon style={{ fontSize: "20px" }} />;
      case "OrderHistory":
        return <CalendarMonthOutlinedIcon style={{ fontSize: "20px" }} />;
      default:
        break;
    }
  };

  const chooseSelected = (value: any) => {
    const selected = value === props.selected;
    return selected ? "black" : "#FFF";
  };

  const handleClick = (value: any) => {
    props.setSelectedOption(value.Header);
    navigate(value.Route);
  };

  return (
    <div
      style={{
        minWidth: "71px",
        paddingTop:"25px",
        backgroundColor: "#333",
        height: "100vh",
      }}
      className="sticky"
    >
      {props.displayDrawerData.map((value: any, index: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height:"50px",
            alignItems: "center",
            cursor:"pointer",
            backgroundColor: value.Header === props.selected ? "#F9F9F9" : "",
          }}
        >
          <Tooltip
            title={
              <>
                <Typography variant="body2" sx={{ fontSize: 15 }}>
                  {value.Header}
                </Typography>
              </>
            }
            placement="right"
          >
            <div
              key={index}
              onClick={() => {
                handleClick(value);
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: chooseSelected(value.Header)
              }}
            >
              {chooseIcon(value.Route)}
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
}
