import React, { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';

const BulletShimmer = () => {
    return (
        <div style={{ padding: "20px" }}>
            <Grid container spacing={2}>
                <Grid item xs={4} sm={4}>
                    <Skeleton variant="circular" width={50} height={50} />
                </Grid>
                <Grid item xs={8} sm={8}>
                    <Skeleton variant="text" height={50} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4} sm={4}>
                    <Skeleton variant="circular" width={50} height={50} />
                </Grid>
                <Grid item xs={8} sm={8}>
                    <Skeleton variant="text" height={50} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4} sm={4}>
                    <Skeleton variant="circular" width={50} height={50} />
                </Grid>
                <Grid item xs={8} sm={8}>
                    <Skeleton variant="text" height={50} />
                </Grid>
            </Grid>
        </div>

    )
}

export default BulletShimmer;