import ApiService from "../Common/ApiService";

export default class UserManager {
    

    public static getUserSettings = async (payload: any) => {
        try {
            let result: any = await ApiService.post("user/getUserSettings", payload);
            if (result.success && result?.data?.response?.data?.length>0) {
                return result.data.response.data;
            }
            else {
                console.log("Error: getUserSettings" + result.msg);
                return [];
            }
        }
        catch (error) {
            console.log("Error: getUserSettings", error);
            return [];
        }
    }

    public static updateUserSettings = async (payload: any) => {
        try {
            let result: any = await ApiService.post("user/updateUserSettings", payload);
            if (result.success) {
                return result.data.response?.data;
            }
            else {
                console.log("Error: updateUserSettings" + result.msg);
            }
        }
        catch (error) {
            console.log("Error: updateUserSettings", error);
        }
    }
}