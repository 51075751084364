
import React from "react";
import { Typography } from "@material-ui/core";
import { Card, CardContent, Grid } from "@mui/material";
import Utils from "../Common/Utils";


const KCDCExpandedRows = (props: any) => {
    
    const renderItemID = (item: any) => {
        let itemID: any = item.ItemDetails?.Extn?.ExtnPOSItemID;
        return itemID ? itemID : "-";
    };

    const renderExpandedData = () => {
        let shipmentsLinesToRender: any[] = [];

        props?.data?._source?.Shipment?.ShipmentLines?.ShipmentLine?.forEach(
            (element: any) => {
                if (
                    element
                ) {
                    shipmentsLinesToRender.push(element);
                }
            }
        );

        console.log(props, "KCDCExpandedRowsProps");
        return (
            <div style={{ marginLeft: Utils.isMobile ? "" : "50px", maxHeight: "450px", overflowY: "auto" }} className="expandedParent"
            >
                {shipmentsLinesToRender.map((item: any) => {
                    return (
                        <div className={Utils.isMobile ? "expandedSectionMobile" : "expandedSection"}  >
                            <Typography
                                style={{
                                    fontSize: "13px",
                                    fontWeight: 500,
                                    width: !Utils.isMobile ? "600px" : "auto",
                                    textAlign: 'start',
                                    fontFamily: "Roboto"
                                }}
                            >
                                {item.ItemDesc}
                            </Typography>
                            <div className="item" style={{ width: !Utils.isMobile ? "70px" : "auto" }}>
                                <Typography style={{ fontSize: "12px" }}>Item ID</Typography>
                                <Typography style={{ fontSize: "12px" }}>
                                    <b style={{ fontFamily: "Roboto Mono" }}>{renderItemID(item)}</b>
                                </Typography>
                            </div>
                            
                        </div>
                    );
                })}
            </div>
        );
    };
    return <React.Fragment>{renderExpandedData()}</React.Fragment>;
};

export default KCDCExpandedRows;
