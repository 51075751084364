import testActions from "./TestAction";
import actionLoaderActions from "./ActionLoaderActions"
import packOrderActions from "./PackOrderActions";
import fulfillOrdersActions from "./FulfillOrdersActions"
import pickOrderActions from "./PickOrderActions";
import dashboardActions from "./DashboardActions";
import customerPickupActions from "./CustomerPickupActions"
import orderHistoryActions from "./OrderHistoryActions";
import receiveOrdersActions from "./ReceiveOrdersActions";
import tecAuditsActions from "./TecAuditsActions";
import contactCustomerActions from "./ContactCustomerActions";
import genericNotificationActions from "./GenericNotificationActions";
import searchActions from "./SearchActions"
import kcdcOrdersActions from "./KcdcOrdersActions";
import fulfilledOrdersHistoryActions from "./FulfilledOrdersHistoryActions";
import reportErrorServiceNowActions from "./ReportErrorActions";
import scandItActions from "./ScanditActions";

const allActions = {
    testActions,
    actionLoaderActions,
    packOrderActions,
    fulfillOrdersActions,
    pickOrderActions,
    dashboardActions,
    customerPickupActions,
    orderHistoryActions,
    receiveOrdersActions,
    tecAuditsActions,
    contactCustomerActions,
    genericNotificationActions,
    searchActions,
    kcdcOrdersActions,
    fulfilledOrdersHistoryActions,
    reportErrorServiceNowActions,
    scandItActions
}

export default allActions;