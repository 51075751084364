import { Button, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, Typography } from '@mui/material';
import { Slide } from '@material-ui/core';
import { TransitionProps } from '@mui/material/transitions';
import { Grid } from '@mui/material';
import { InfoIcon, CloseIcon } from "../../Subcomponent/Shared/SharedIcons";
import IconButton from '@mui/material/IconButton';
import Utils from '../../Common/Utils';
import React from 'react';
import { IImageViewerPopupSettings } from '../../Models/IImageViewerPopupSettings';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ImageViewerPopup = (props:IImageViewerPopupSettings) => {

    const close = () => {
        props.close()
    }

    const setImageSize = (imageUrl: string) =>{
        let httpsUrl = Utils.convertToHttps(imageUrl);
        let url = Utils.setImageResolution(httpsUrl);
        return url;
    }

    const renderPrice = (price?:number) =>{
        if(price)
        {
            return `$${price}`;
        }
        return "-";
    }

    return (
        <Dialog disableEnforceFocus open={props.open} maxWidth={"lg"} TransitionComponent={Transition}>
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={close}
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        color: "#D93A2F",
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            
            <DialogContent>
                <Grid container style={{display: "flex", justifyContent: "center" }}>
                    <img alt="" src={setImageSize(props.imageDetail.imageURL)}/>
                </Grid>
                <Grid container style={{padding:"10px"}}>
                    <Typography style={{ fontSize: "15px", fontWeight: 500, color: "#D93A2F" }}>{props.imageDetail.itemDesc}</Typography>
                </Grid>
                <Grid container style={{padding:"10px"}}>
                    <Grid item sm={6} md={6} xs={12}>
                        <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                            Item ID: <b>{props.imageDetail.itemID}</b>
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                            SKU #: <b>{props.imageDetail.skuID}</b>
                        </Typography>
                    </Grid>
                    <Grid item sm={6} md={6} xs={12}>
                        <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                            Price: <b>{renderPrice(props.imageDetail.price)}</b>
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                            Qty: <b>{props.imageDetail.qty}</b>
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
);

}