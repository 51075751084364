import { deflate } from "zlib";
import { IKcdcDetail } from "../../Models/IKcdcDetail";
import {LOAD_KCDCORDERS, LOAD_KCDC_ORDER_DETAILS } from "../ActionTypes";
const initialState = {
    allkcdcOrders:[],
    orderDetail: {} as IKcdcDetail
}
const KcdcReducer = (state: any = initialState,
    action: { type: any; payLoad: any }) => {
    switch (action.type) {
        case LOAD_KCDCORDERS:
            return {
                ...state,
                allkcdcOrders: action.payLoad
            }
        case LOAD_KCDC_ORDER_DETAILS:
            return {
                ...state,
                orderDetail: action.payLoad
            }
        
    }

    return state;
}

export default KcdcReducer;