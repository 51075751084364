import { Card, CardContent, Grid, Tooltip } from "@mui/material"
import React from "react";
import { DirectionsCar, InfoOutlinedIcon, ShoppingCartCheckoutIcon } from "../Shared/SharedIcons";
import Constants from "../../Common/Constants";
import Utils from "../../Common/Utils";

export const PickedUpOrdersTile = (props: any) => {
    const { released, pickedUp } = props;


    return (
        <React.Fragment>
            <Card className='dCard' >
                <CardContent className='dCardContent' style={{ padding: 0, cursor: "default" }}>
                    <Grid container style={{ padding: "4px", display:"flex",alignItems:"center",justifyContent:"center" }}>

                        <Grid item sm={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                            <div className="tiles-icons" style={{ backgroundColor: "rgb(255, 128, 66)" }}   >
                                <DirectionsCar />
                            </div>
                        </Grid>
                        <Grid item sm={8} style={{ display: "grid", justifyItems: "start" }}>
                            <p className="tiles-header-font" style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                Customer Picked up <Tooltip title={<h3 style={{ whiteSpace: 'pre-line' }}>{Utils.getToolTipValue("CustomerPickedup") ? Utils.getToolTipValue("CustomerPickedup") : ''}</h3>}>
                                    <InfoOutlinedIcon style={{ color: Constants.Colors.blue, marginLeft: "5px" }} />
                                </Tooltip></p>
                            {/* <p className="tiles-header-font">Customer Picked up</p> */}
                            <p className="tiles-value-font">
                                {pickedUp}
                            </p>
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>

        </React.Fragment >);
}