import { AnyARecord } from "dns";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import PrintConfigurationManager from "../../Managers/PrintConfiguration.Manager";
import {
   LOAD_ORDER_COUNT,
   LOAD_DASHBOARD_ORDERS,
   LOAD_DASHBOARD_RESOURCED_ORDERS,
   LOAD_DASHBOARD_EXPEDITED_ORDERS,
   LOAD_DASHBOARD_PEDNINGCUSTOMER_ORDERS,
   LOAD_DASHBOARD_PEDNINGCUSTOMER_DECISIONS_ORDERS
} from "../ActionTypes";
const loadDashboardOrdersCount = (obj: any) => {
    return {
        type: LOAD_ORDER_COUNT,
        payLoad: obj
    };
}

const loadDashboardResourceOrders = (obj:any)=>{

    return {
        type: LOAD_DASHBOARD_RESOURCED_ORDERS,
        payLoad: obj
    };

}

const loadDashboardExpeditedOrders = (obj:any)=>{

    return {
        type: LOAD_DASHBOARD_EXPEDITED_ORDERS,
        payLoad: obj
    };

}

const loadDashboardPendingCustomerOrders = (obj:any)=>{

    return {
        type: LOAD_DASHBOARD_PEDNINGCUSTOMER_ORDERS,
        payLoad: obj
    };

}

const loadDashboardPendingCustomerDecisionsOrders = (obj:any)=>{

    return {
        type: LOAD_DASHBOARD_PEDNINGCUSTOMER_DECISIONS_ORDERS,
        payLoad: obj
    };

}

const dashboardActions = {
    loadDashboardOrdersCount,
    loadDashboardResourceOrders,
    loadDashboardExpeditedOrders,
    loadDashboardPendingCustomerOrders,
    loadDashboardPendingCustomerDecisionsOrders
   
}

export default dashboardActions;