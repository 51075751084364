import ApiService from "../Common/ApiService";
import ShipmentStatusConstants from "../Common/ShipmentStatusConstants";
import { IOrderQuery } from "../Models/IOrderQuery";
import StoreManager from "./Store.manager";
import IdentityManager from "./Identity.manager";
import Constants from "../Common/Constants";
import Utils from "../Common/Utils";

export default class OrderManager {

  public static getOrders = async (orderQuery: IOrderQuery) => {
    try {
      return await ApiService.post(`/pf/getOrders`, orderQuery);
    }
    catch (error) {
      console.log(error);
    }
  }

  // Send Long URL and Get Short URL
  public static getShortURL = async (body: any) => {

    try {
      return await ApiService.post(`/pf/getShortURL`, body);
    }
    catch (error) {
      console.log(error);
    }

  }

  // Get URL
  public static getDyamicOrderURL = async (body: any) => {

    try {
      return await ApiService.post(`/pf/getDyamicOrderURL`, body);
    }
    catch (error) {
      console.log(error);
    }

  }

  // Report Error to Service Now
  public static reportErrorToServiceNow = async (body: any) => {

    try {
      return await ApiService.post(`/pf/saveReportErrorToServiceNow`, body);
    }
    catch (error) {
      console.log(error);
    }

  }
  public static searchOrder = async (orderQuery: any) => {
    try {
      return await ApiService.post(`/pf/searchOrders`, orderQuery);
    }
    catch (error) {
      console.log(error);
    }
  }
  public static orderById = async (orderQuery: any) => {
    try {
      return await ApiService.post(`/pf/orderById`, orderQuery);
    }
    catch (error) {
      console.log(error);
    }
  }
  public static getReceiveOrders = async (storeId: any) => {
    try {
      return await ApiService.post(`/pf/getPendingReceiptsOrders`, storeId);
    }
    catch (error) {
      console.log(error);
    }
  }

  public static getkcdcOrders = async (storeId: any) => {
    try {
      return await ApiService.post(`/pf/getkcdcOrders`, storeId);
    }
    catch (error) {
      console.log(error);
    }
  }

  public static getOrdersAgg = async (orderQuery: any) => {
    try {
      return await ApiService.post(`/pf/getOrdersAggregate`, orderQuery);
    }
    catch (error) {
      console.log(error);
    }
  }

  public static getOrdersAggregatesWrapper = async (orderQuery: any) => {

    let orderCount = {
      allOrders: 0,
      readyForPacking: 0,
      readyForPicking: 0,
      readyForCustomerPickup: 0,
      pendingReceipts: 0,
      onHold:0
    };
    try {


      let aggResult = await ApiService.post(`/pf/getOrdersAggregate`, orderQuery);
      if (aggResult.success) {
        let allOrdersAggregate = aggResult.data.aggregations.orderCountByType.buckets.AllOrders.doc_count;  // Do not use All Orders Aggregate Dircetly Anywhere. its count might
        let allOrdersCount = Utils.parseIntCustom(aggResult.data.aggregations.CustomerPickOrders.unique_orders?.value) + Utils.parseIntCustom(aggResult.data.aggregations.orderCountByType.buckets.PackOrders.doc_count) + Utils.parseIntCustom(aggResult.data.aggregations.orderCountByType.buckets.PickOrders.doc_count);
        let customerPickOrdersAggregate = aggResult.data.aggregations.CustomerPickOrders.unique_orders?.value;
        let packOrdersAggregate = aggResult.data.aggregations.orderCountByType.buckets.PackOrders.doc_count;
        let pickOrdersAggregate = aggResult.data.aggregations.orderCountByType.buckets.PickOrders.doc_count;
        let onHoldOrdersAggregate = aggResult.data.aggregations.orderCountByType.buckets?.OnHoldOrders?.doc_count;
        let pendingOrdersAggregate = aggResult.data.aggregations.orderCountByType.buckets.PendingReceipts.doc_count;
        let pendingOrdersAggregateUpdated = aggResult.data.aggregations.PendingReceipts.PendingReceiptsOrders.value;

        if (allOrdersAggregate != undefined) {
          orderCount.allOrders = allOrdersCount
        }
        if (customerPickOrdersAggregate != undefined) {
          orderCount.readyForCustomerPickup = customerPickOrdersAggregate
        }
        if (packOrdersAggregate != undefined) {
          orderCount.readyForPacking = packOrdersAggregate
        }
        if (pickOrdersAggregate != undefined) {
          orderCount.readyForPicking = pickOrdersAggregate
        }
        if (onHoldOrdersAggregate) {
          orderCount.onHold = onHoldOrdersAggregate;
        }
        if (pendingOrdersAggregateUpdated) {
          orderCount.pendingReceipts = pendingOrdersAggregateUpdated;
        }
      }
      else {
        console.log("Error Order Aggregate Count=" + aggResult.msg)
      }
    }
    catch (error) {
      console.log(error);
    }

    return orderCount;

  }

  public static getDashboardOrders = async (orderQuuery: any) => {

    let orders = {
      resourced: [],
      expedited: [],
      pendingCustomers: [],
    }

    await Promise.all([

      ApiService.post(`/pf/getresourcedOrders`, orderQuuery).then((responseResourced: any) => {

        if (responseResourced.success) {
          orders.resourced = responseResourced.data.hits.hits;
        }

      }),
      ApiService.post(`/pf/getExpeditedOrders`, orderQuuery).then((responseExpedited: any) => {

        if (responseExpedited.success) {
          orders.expedited = responseExpedited.data.hits.hits;
        }

      }),
      ApiService.post(`/pf/getPendingCustomerPickupOrders`, orderQuuery).then((responsePending: any) => {

        if (responsePending.success) {
          orders.pendingCustomers = responsePending.data.hits.hits;
        }

      }),


    ]);


    return orders;

  }

  public static getReSourcedOrders = async (orderQuuery: any) => {


    let resourced: any[] = [];

    await ApiService.post(`/pf/getresourcedOrders`, orderQuuery).then((responseResourced: any) => {

      if (responseResourced.success) {
        resourced = responseResourced.data.hits.hits;
      }

    });
    return resourced;

  }

  public static getExpeditedIrders = async (orderQuuery: any) => {


    let expedited: any[] = [];

    await ApiService.post(`/pf/getExpeditedOrders`, orderQuuery).then((responseResourced: any) => {

      if (responseResourced.success) {
        expedited = responseResourced.data.hits.hits;
      }

    });
    return expedited;

  }

  public static getPendingCustomerPickupOrders = async (orderQuuery: any) => {


    let pendingCustomers: any[] = [];

    await ApiService.post(`/pf/getPendingCustomerPickupOrders`, orderQuuery).then((responseResourced: any) => {

      if (responseResourced.success) {
        pendingCustomers = responseResourced.data.hits.hits;
      }

    });
    return pendingCustomers;

  }

  public static getPendingCustomerDecisionsOrders = async (orderQuuery: any) => {


    let pendingCustomerDecisions: any[] = [];
    await ApiService.post(`/pf/getCustomerContactedOrders`, orderQuuery).then((responseResourced: any) => {

      if (responseResourced.success) {
        pendingCustomerDecisions = responseResourced.data.hits.hits;
      }

    });

    return pendingCustomerDecisions;

  }
  public static getUrlDisabledByGuid = async (payload: any) => {
    return await ApiService.post(`/pf/getUrlDisabledByGuid`, payload);
  }

  public static getStore = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let store = urlParams.get('store');
    if (store == null || store == undefined || store == '') {
      store = StoreManager.currentStore?.StoreId && StoreManager.currentStore?.StoreId?.toString().length > 0 ? StoreManager.currentStore?.StoreId?.toString() : IdentityManager.storeNumber;
    }
    return store
  }
  public static saveOrderHistory = async (input: any) => {
    const queryString = window.location.search;
    input.store = OrderManager.getStore()
    input.userId = IdentityManager.getUserProperty(Constants.EmployeeID);
    input.upn = IdentityManager.getUserProperty(Constants.Preferred_UserName);
    const urlParams = new URLSearchParams(queryString);
    input.orderno = urlParams.get('orderno');
    input.isMobile = Utils.isMobile ? '1' : '0';
    return await ApiService.post(`/pf/saveOrderHistory`, input);
  }

  public static sendInfoToSNS = async (input: any) => {

    return await ApiService.post(`/pf/sendInfoToSNS`, input);
  }

  public static deleteLogByDocId = async (docId: any) => {
    try {
      return await ApiService.post(`/pf/deleteLogByDocId`, docId);
    }
    catch (error) {
      console.log(error);
    }
  }

  public static getCustomerPickSlipData = async (payload:any) => {
    try {
      return await ApiService.post(`/pf/getCustomerPickSlipData`, payload);
    }
    catch (error) {
      console.log(error);
    }
  }

  public static getFulfilledOrdersHistory = async (payload:any) => {
    try {
      return await ApiService.post(`/pf/getFulfilledOrdersHistory`, payload);
    }
    catch (error) {
      console.log(error);
    }
  }



}