import { Grid, Typography } from "@mui/material"
import { TailSpin } from "react-loader-spinner"
import { useEffect, useState } from "react";
import ContactCustomerManager from "../../Managers/ContactCustomer.Manager";
import { IImageZoomSettings } from "../../Models/IImageZoomSettings";
import { ImageZoom } from "./ImageZoom";

export const ImageAttachments = (props:any) =>{

    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [imagesData, setImagesData] = useState([]);
    
    const [openImageZoom, setImageZoom] = useState(false);
    const [imageURL, setImageURL] = useState('');

    const getImagesPath = (images: any) => {
        let paths = images.map((image: any) => (image.path));
        return paths;
    }

    const getImages = async (data: any) => {
        let imagesPath = getImagesPath(data);
        let payload = {
            getImages: imagesPath
        }
        return await ContactCustomerManager.getImages(payload);
    }


    const closeImageZoom = () => {
        setImageZoom(false);
        setImageURL("");
    }

    const imageZoomPopUp = (event:any,imageURL: any) => {
        event.stopPropagation();
        setImageURL(imageURL);
        setImageZoom(true);
    }
    

    let imageZoomSettings: IImageZoomSettings = {
        open: openImageZoom,
        close: closeImageZoom,
        imageSrc: imageURL
    }

    useEffect(() => {
        async function loadImages() {
            let response = await getImages(props.imageAttachments)
            if (response.success && response.data?.response?.data?.length > 0) {
                setImagesData(response.data.response.data)
                setImagesLoaded(true)
            }
        }
        if(props.imageAttachments?.length>0)
        {
            loadImages()
        }

    }, []);

    return (
        <Grid item xs={12} sm={12}>
            {
                props.imageAttachments?.length>0 &&
                    <div className="mainContainer" style={{ padding: "20px" }}>
                        {
                            imagesLoaded ?
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {
                                        imagesData.map((image: any) => {
                                            return (
                                                <div className="image" style={{ backgroundImage: `url(${image})`, cursor: "pointer" }} onClick={(event:any) => { imageZoomPopUp(event,image) }}>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                :
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {
                                        props.imageAttachments?.map(() => {
                                            return (
                                                <div className="image" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <TailSpin color="#D93A2F" height={40} width={40} wrapperStyle={{ justifyContent: "center" }} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                        }

                    </div>
            }
            <ImageZoom {...imageZoomSettings} />
        </Grid>
    )
}