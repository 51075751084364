import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import { auto } from "@popperjs/core";
import React, { useEffect, useState } from "react";
import {
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    Bar,
    BarChart,
    Line,
    ComposedChart
} from "recharts";
import Utils from "../../Common/Utils";
import ChartShimmer from "../Shared/ChartShimmer";
import DataNotFound from "../Shared/DataNotFound";



const ShippedPickupInStoreReport = (props: any) => {

    const { shippedHits, pickUpInStoreHits, shipped, pickUpInStore, dataType, total, pickupTotalAmountValue, pickupHitsTotal } = props.data;
    const dataExpedite = props.dataExpedite
    const [graphData, setGraphData] = useState([{
        key_as_string: "",
        Shipped: 0,
        Pickup: 0,
        key: 0,
        Expedited: 0
    }])

    const [filteredGraphData, setFilteredGraphData] = useState([{
        key_as_string: "",
        Shipped: 0,
        Pickup: 0,
        key: 0,
        Expedited: 0
    }])

    useEffect(() => {
        loadGraphData()
    }, [props.isLoaded, shipped, props.isLoadedExpedite])

    const loadGraphData = () => {
        if (props.isLoaded && props.isLoadedExpedite) {
            let barChartData: any[] = transformShippedPickupData();
            setGraphData(barChartData)
            setFilteredGraphData(barChartData)
        }

    }

    const addToolTipLabelData = () => {
        let formattedData: any[] = dataExpedite.expeditedData;
        if (formattedData && formattedData.length > 0) {
            formattedData.forEach((element: any) => {

                element["Expedited Orders"] = element["doc_count"];

            })
        }

        return formattedData;

    }
    // const calculateStatistcsData = () => {
    //     //Shipping Statistics
    //     let totalShippedPickedup = (shippedHitsTotal ?? 0) + (pickupHitsTotal ?? 0);
    //     let shippedTotal = shippedHitsTotal ?? 0;
    //     let shippedPerCal = Math.round((shippedTotal / totalShippedPickedup) * 100) ? Math.round((shippedTotal / totalShippedPickedup) * 100) : 0;
    //     let shippedTotalAmount = shippedTotalAmountValue ?? 0; //calculateTotalAmount(shippedHits);

    //     //Pickup Statistics
    //     let pickUpTotal = pickupHitsTotal ?? 0;

    //     let pickUpPerCal = shippedPerCal ? 100 - shippedPerCal : 0;
    //     let pickUpTotalAmount = pickupTotalAmountValue ?? 0; //calculateTotalAmount(pickUpInStoreHits);
    //     return {
    //         shipped: shippedTotal,
    //         shippedPerc: shippedPerCal + '%',
    //         shippedAmount: Utils.formatCurrency(shippedTotalAmount),
    //         pickUp: pickUpTotal,
    //         pickUpPerc: pickUpPerCal + '%',
    //         pickUpAmount: Utils.formatCurrency(pickUpTotalAmount),
    //     }
    // }

    // const calculateTotalAmount = (array: any) => {
    //     //
    //     let totalShipmentLines: any[] = [];

    //     array?.hits?.forEach((_hit: any) => {
    //         _hit?._source?.Shipment?.ShipmentLines?.ShipmentLine.forEach((_shipmentLine: any) => {
    //             _shipmentLine.OrderStatus = _hit?._source?.Shipment?.Status
    //             totalShipmentLines.push(_shipmentLine);
    //         });
    //     });

    //     totalShipmentLines = Utils.getValidShipLines(totalShipmentLines);

    //     let totalShipmentAmount = 0;
    //     totalShipmentLines.forEach((element: any) => {
    //         totalShipmentAmount += Number(element.LineTotal);
    //     })
    //     return totalShipmentAmount;
    // }
    const handleClick = (entry: any) => {

        if (filteredGraphData[0].hasOwnProperty(entry.dataKey)) {

            setFilteredGraphData(getRemovedFilteredData(entry))
        }

        if (!filteredGraphData[0].hasOwnProperty(entry.dataKey)) {

            setFilteredGraphData(getAddedFilteredData(entry))
        }


    }

    const getRemovedFilteredData = (entry: any) => {
        return filteredGraphData?.map((obj: any) => {
            const newObj: any = {};
            for (const prop in obj) {
                if (prop !== entry.dataKey) {
                    newObj[prop] = obj[prop];
                }

            }
            return newObj;
        });

    }

    const getAddedFilteredData = (entry: any) => {

        let keys = Object.keys(filteredGraphData[0])
        if (!keys.includes(entry.dataKey)) {
            keys.push(entry.dataKey)
        }
        return graphData?.map((obj: any) => {
            const newObj: any = {};
            for (const prop in obj) {
                if (keys.includes(prop)) {
                    newObj[prop] = obj[prop];
                }

            }
            return newObj;
        });

    }

    const renderCustomLegend = (props: any) => {
        const { payload } = props;

        if (payload.length && filteredGraphData.length) {
            return (
                <div>
                    {
                        payload.map((entry: any, index: any) => (
                            <span onClick={() => handleClick(entry)} key={`item-${index}`} style={{ marginLeft: '5px', cursor: "pointer" }}>
                                <div style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: Object.keys(filteredGraphData[0]).includes(entry.dataKey) ? entry.color : "grey" }}></div>
                                {entry.value}
                            </span>
                        ))
                    }
                </div>
            );
        }

        return null
    }

    const transformShippedPickupData = () => {
        //        
        let mergedData: any[] = [];
        //Hourly Basis
        if (dataType === '0') {
            shipped.forEach((s: any) => {
                mergedData.push({ key_as_string: s.convertedTime, Shipped: s.doc_count, Pickup: 0, time: s.key_as_string, Total: 0, Expedited: 0 });
            });
            pickUpInStore.forEach((ps: any) => {
                //
                let index = mergedData.findIndex((x: any) => x.key_as_string === ps.convertedTime);
                if (index < 0) {
                    mergedData.push({ key_as_string: ps.convertedTime, Shipped: 0, Pickup: ps.doc_count, time: ps.key_as_string, Total: 0, Expedited: 0 })
                }
                else {
                    mergedData[index].Pickup += ps.doc_count;
                }
            })
            total.forEach((t: any) => {
                let index = mergedData.findIndex((x: any) => x.key_as_string === t.convertedTime);
                if (index < 0) {
                    mergedData.push({ key_as_string: t.convertedTime, Shipped: 0, Pickup: 0, time: t.key_as_string, Total: t.doc_count, Expedited: 0 });
                }
                else {
                    mergedData[index].Total += t.doc_count;
                }
            });
            dataExpedite.expeditedData.forEach((t: any) => {
                let index = mergedData.findIndex((x: any) => x.key_as_string === t.convertedTime);
                if (index < 0) {
                    mergedData.push({ key_as_string: t.convertedTime, Shipped: 0, Pickup: 0, time: t.key_as_string, Total: 0, Expedited: t.doc_count });
                }
                else {
                    mergedData[index].Expedited += t.doc_count;
                }
            });
            const sorted = mergedData.sort((a, b) => a.time > b.time ? 1 : -1);
            return sorted
        }
        //Other Datatypes
        shipped.forEach((s: any) => {
            //
            mergedData.push({ key_as_string: s.key_as_string, Shipped: s.doc_count, Pickup: 0, key: s.key, Total: 0, Expedited: 0 });
        });
        total.forEach((t: any) => {
            let index = mergedData.findIndex((x: any) => x.key_as_string === t.key_as_string);
            if (index < 0) {
                mergedData.push({ key_as_string: t.key_as_string, Shipped: 0, Pickup: 0, key: t.key, Total: t.doc_count, Expedited: 0 });
            }
            else {
                mergedData[index].Total += t.doc_count;
            }
        });

        pickUpInStore.forEach((ps: any) => {
            //
            let index = mergedData.findIndex((x: any) => x.key_as_string === ps.key_as_string);
            if (index < 0) {
                mergedData.push({ key_as_string: ps.key_as_string, Shipped: 0, Pickup: ps.doc_count, key: ps.key, Total: 0, Expedited: 0 })
            }
            else {
                mergedData[index].Pickup += ps.doc_count;
            }
        })

        dataExpedite.expeditedData.forEach((t: any) => {
            let index = mergedData.findIndex((x: any) => x.key_as_string === t.key_as_string);
            if (index < 0) {
                mergedData.push({ key_as_string: t.key_as_string, Shipped: 0, Pickup: 0, key: t.key, Total: 0, Expedited: t.doc_count });
            }
            else {
                mergedData[index].Expedited += t.doc_count;
            }
        });
        const sorted = mergedData.sort((a, b) => a.key - b.key);
        return sorted
    }


    return (
        <React.Fragment>
            {(props.isLoaded && props.isLoadedExpedite) ?
                <>
                    {
                        (filteredGraphData.length == 0) ?
                            <DataNotFound></DataNotFound>
                            : <>{!Utils.isMobile && <div><Typography className="report-card-heading" style={{ display: "flex", marginTop: "0px" }}> Orders and fulfillment Trend</Typography></div>}
                                {Utils.isMobile && <div><Typography className="report-card-heading" style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "15px" }}> Orders and fulfillment Trend</Typography></div>}
                                <div style={{ marginTop: !Utils.isMobile ? "15px" : "0px" }}>
                                    <ResponsiveContainer width={'99%'} height={300} >
                                        <ComposedChart
                                            width={500}
                                            height={250}
                                            data={filteredGraphData}
                                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="key_as_string" interval="preserveStartEnd" />
                                            <YAxis interval="preserveStartEnd" type="number" />
                                            <Tooltip />
                                            <Legend content={renderCustomLegend} />
                                            <Bar dataKey="Total" name="New Orders" fill="#6DA4D1"
                                                stroke="#000000"
                                                strokeWidth={1} />
                                            <Bar dataKey="Shipped" name="Orders Fulfilled" fill="#509A48" stackId="a"
                                                stroke="#000000"
                                                strokeWidth={1} />
                                            <Bar dataKey="Pickup" name="Customer Picked up" fill="#9AD56B" stackId="a"
                                                stroke="#000000"
                                                strokeWidth={1} />
                                            {/* <Line type="monotone" dataKey="Expedited" stroke="#D93A2F" strokeWidth={2} /> */}


                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </div></>
                    }

                </> : <ChartShimmer></ChartShimmer>
            }
        </React.Fragment>
    );
};
export default ShippedPickupInStoreReport;
