import { AnyARecord } from "dns";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Utils from "../../Common/Utils";
import PrintConfigurationManager from "../../Managers/PrintConfiguration.Manager";
import {
   SET_HISTORY,
   IS_LOADING_HISTORY,
   FORCE_HISTORY_LOAD
} from "../ActionTypes";

const setHistory = (obj: any) => {
    return {
        type: SET_HISTORY,
        payLoad: obj
    };
}

const setHistoryLoading = (obj:any)=>{

    return {
        type: IS_LOADING_HISTORY,
        payLoad: obj
    };

}

const forceHistoryLoad = ()=>{
    return {
        type: FORCE_HISTORY_LOAD,
        payLoad: Utils.getCurrentDate()
    };
}

const orderHistoryActions = {
    setHistory,
    setHistoryLoading,
    forceHistoryLoad
   
}

export default orderHistoryActions;