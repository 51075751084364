import ApiService from "../Common/ApiService";

export default class CacheBuster {

    public static checkVersion = async () => {

        try {

            let _result = await ApiService.getSimple("/meta.json");
            CacheBuster.cacheBust(_result);

        } catch (error) {
            console.log(error);
        }
    }

    public static cacheBust = (_result: any) => {
        
        let updateTag = localStorage.getItem('updateTag');
        if (updateTag && updateTag !== _result.version) {
            // update tag in local storage
            localStorage.setItem('updateTag', _result.version);
            // delete browser cache and hard reload
            console.log("cacheBust = true");
            CacheBuster.hardReload();
        }
        else{
            localStorage.setItem('updateTag', _result.version);
        }
    }

    public static hardReload = () =>{
        const url = new URL(window.location.href);
        url.searchParams.set('reloadTime', Date.now().toString());
        window.location.href = url.toString();
    }
}