
import { useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Utils from "../../Common/Utils";
const columns: GridColDef[] = [
    { field: 'skuID', headerName: 'Sku #', width: 150},
    { field: 'area', headerName: 'Location', width:  150}
];

const AllItemsLocations = (props: any) => {
    const [pageSize, setPageSize] = useState<number>(10);
    return (
        <>
            <div style={{ height: 500 }}>
                <DataGrid
                    rows={props.rows}
                    columns={columns}
                    pagination                    
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 20, 30]}
                />
            </div>
        </>
    )
}

export default AllItemsLocations



