import React, { useState } from "react";
import "./ResourceItem.scss";
import {
    Grid,
    Typography,
    Radio,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    DialogActions,
    Button,
    Card,
    CardContent,
    Divider,
    Stack,
    TablePagination
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import Constants from "../../Common/Constants";
import Utils from "../../Common/Utils";
import { IResourceView, Node, ShipNodePersonInfo, Availability } from '../../Models/IResourceView';
import { OmsPickOrderDetail, ShipmentLine, CommonCode } from '../../Models/OmsPickOrderDetail';
import { TailSpin } from "react-loader-spinner";
import { NotificationPopup } from '../Shared/NotificationPopup'
import allActions from "../../Store/Actions/AllActions"
import { VerifyCredentials } from '../../Subcomponent/Shared/VerifyCredentials';
import { INotificationPopupSettings, IButton } from '../../Models/INotificationPopupSettings';
import { SocialDistanceIcon, LocationOnIcon, MailIcon, LocalPhoneIcon, StoreIcon, DoneIcon, CloseIcon, ArrowForwardIcon, ReportProblemIcon, AddIcon, RemoveIcon, CheckBoxOutlinedIcon } from "../../Subcomponent/Shared/SharedIcons";
import StoreManager from "../../Managers/Store.manager"
import OMSManager from "../../Managers/Oms.manager";
import { useNavigate } from "react-router-dom";
import './ResourceItem.scss';
import OrderManager from "../../Managers/Orders.Manager";
import { IResourceShipmentLine } from "../../Models/IResourceShipmentLine";
import IdentityManager from "../../Managers/Identity.manager";
import { ReportErrorPopup } from "../Shared/ReportErrorPopup";
import { IResourceItemInput } from "../../Models/IResourceItemInput";
import { ServiceNowCnfrmText } from "../Shared/ServiceNowCnfrmText";
const Input = styled(MuiInput)`
  width: 60px;
`;
var moment = require('moment');
export const ResourceItem: React.FC<any> = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pathname = window.location.pathname;
    let resourceDefault = Number(StoreManager.config.resourceDefault)
    let resourceMax = Number(StoreManager.config.resourceMax)
    let orderDetail: OmsPickOrderDetail = useSelector((state: any) => state.PickOrdersReducersState.orderDetail);
    let selectedShipmentLineKey = useSelector((state: any) => state.PickOrdersReducersState.selectedShipmentLineKey);
    //let radioChecked = [rows[0].id];
    const [value, setValue] = React.useState(resourceDefault);
    const [valueConformed, setValueConformed] = React.useState(resourceDefault);
    const [reasonCode, setReasonCode] = React.useState(0);
    const [reasonText, setReasonText] = React.useState("");
    const [selectionModel, setSelectionModel] = React.useState([-1]);
    const [checkboxChecked, setCheckboxChecked] = React.useState(-1);
    const [selectedStoreDetail, setSelectedStoreDetail] = React.useState<Node>();
    const [resourceViewData, setResourceViewData] = React.useState<IResourceView>({} as IResourceView);
    const [loading, setLoading] = React.useState(true);
    const [enableYesButton, setYesButton] = React.useState(false);
    const [openGeneralError, setOpenGeneralError] = useState(false);
    const [errorGeneralMsg, setErrorGeneralMsg] = useState("");
    const [verifyCredentials, setVerifyCredentials] = useState(false);
    const [pageSize, setPageSize] = useState<number>(15);
    const availableQty = Number(orderDetail.ShipmentLines.ShipmentLine.find(x => x.ShipmentLineKey == selectedShipmentLineKey)?.Quantity ?? "1");
    const [resourceQty, setResourceQty] = useState<number>(availableQty);
    const [mobileSelectedValue, setMobileSelectedValue] = useState(-1);
    const [mobileAvailableStores, setMobileAvailableStores] = useState([]);

    //Report Error Action Use State
    const [reportErrorAction, setReportErrorAction] = useState("");

    // Report Error Pop Up Use States
    const [openReportError, setOpenReportError] = useState(false);
    const [openAlreadyReportedError, setOpenAlreadyReportedError] = useState(false);
    const [openConfirmReportError, setOpenConfirmReportError] = useState(false);
    const [errorReportMsg, setErrorReportMsg] = useState("");
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [zipCode, setZipCode] = React.useState("");

    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }
     const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(+(parseInt(event.target.value)));
        setPage(0);
    };
    const columnsWeb = [
        {
            field: "radiobutton",
            headerName: "",
            width: 100,
            sortable: false,
            renderCell: (params: any) => {
                return (
                    <Radio checked={selectionModel != undefined && selectionModel[0] === params.id} value={params.ShipNode} />
                )
            }
        },
        { field: "ShipNode", headerName: "Store", width: 200 },
        { field: "Quantity", headerName: "Available Quantity", width: 250, resizable: true, sortComparator: (v1: any, v2: any) => { const num1 = v1 === '' ? 0 : parseInt(v1); const num2 = v2 === '' ? 0 : parseInt(v2); return num1 - num2; } },
        {
            field: "checkbox",
            headerName: "Force Sourcing",
            width: 300, resizable: true,
            renderCell: (params: any) => {
                if (params.row.EnableForcedSourcing == "Y") {
                    return (
                        <Checkbox     
                            checkedIcon={<CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>}
                            checked={checkboxChecked == params.row.id}
                            onChange={(e) => checkboxChange(e, params.row.id)}
                            style={{
                                color: "#D93A2F",
                            }} />
                    )
                }
                else {
                    return ("");
                }

            }
        }
    ];

    const columnsMobile = [
        {
            field: "radiobutton",
            headerName: "",
            width: 50,
            sortable: false,
            renderCell: (params: any) => {
                return (
                    <Radio checked={selectionModel != undefined && selectionModel[0] === params.id} value={params.ShipNode} />
                )
            }
        },
        { field: "ShipNode", headerName: "Store" },
        { field: "Quantity", headerName: "Available Quantity", description: "Available Quantity", width: 150, resizable: true },
        {
            field: "checkbox",
            headerName: "Force Sourcing",
            width: 200, resizable: true,
            renderCell: (params: any) => {
                if (params.row.EnableForcedSourcing == "Y") {
                    return (
                        <Checkbox
                            checkedIcon={<CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>}
                            checked={checkboxChecked == params.row.id}
                            onChange={(e) => checkboxChange(e, params.row.id)}
                            style={{
                                color: "#D93A2F",
                            }} />
                    )
                }
                else {
                    return ("");
                }

            }
        }
    ];
    const checkboxChange = (e: any, id: any) => {
        if (e.target.checked) {
            setCheckboxChecked(id)
        }
        else {
            setCheckboxChecked(-1)
        }
    }
    const columns = Utils.isMobile ? columnsMobile : columnsWeb
    let resourceReasonCodes = [] as any;
    orderDetail?.CommonCodeList?.CommonCode.forEach((code: CommonCode) => {
        resourceReasonCodes.push({ name: code.CodeShortDescription, value: code.CodeValue })
    });
    const handleInputChange = (event: any) => {
        setValue(Number(event.target.value));
        setValueConformed(Number(event.target.value));
    };

    const closeVerificationPopUp = () => {
        setVerifyCredentials(false);
    }

    const openVerifyCredentials = () => {
        if (IdentityManager.verifyCredentialsException) {
            confirmCredentials();
        }
        else {
            setVerifyCredentials(true);
        }
    }

    const confirmCredentials = () => {
        resourceItem()
    }
    let verifyCredentialsButtons: IButton[] = [];

    let verifyCredentialsPopUp: INotificationPopupSettings = {
        open: verifyCredentials,
        type: Constants.NotificationPopupType.Conformation,
        title: "Status Approved Credentials",
        msg: "",
        handleClose: closeVerificationPopUp,
        isCustomJSX: true,
        fullWidth: true,
        customJSX: <VerifyCredentials confirmCredentials={confirmCredentials} closePopUp={closeVerificationPopUp}></VerifyCredentials>,
        actions: verifyCredentialsButtons
    }
    const handleBlur = () => {
        if (value < 0) {
            setValue(0);
            setValueConformed(0);
        } else if (value > resourceMax) {
            setValue(resourceMax);
            setValueConformed(resourceMax);
        }
    };
    function calculateValue(value: number) {
        return value * 10;
    }
    const handleChange = (event: any, newValue: any) => {
        if (typeof newValue === "number") {
            setValue(newValue);
        }
    };
    const pagination = () => {
        return (
        <div
            id="paginationWebId"
            className="resourcePaginationMobile"
        >
            <div>
            <Stack>
                <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalRecords}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage=""
                />
            </Stack>
            </div>
        </div>
        );
    };
    const handleChangeConformed = (event: any, newValue: any) => {
        if (typeof newValue === "number") {
            setValueConformed(newValue);
        }
    };
    const changeRadioSelection = (newSelectionModel: any) => {
        if (resourceViewData?.NodeList?.Node) {
            setSelectionModel(newSelectionModel);
            let selectedStore: Node = resourceViewData?.NodeList?.Node[newSelectionModel[0]]
            setSelectedStoreDetail(selectedStore)
            setCheckboxChecked(-1)
        }
    }
    const changeMobileRadioSelection = (value:number) => {
        if (resourceViewData?.NodeList?.Node && value>=0) {
            setMobileSelectedValue(value);
            let selectedStore: Node = resourceViewData?.NodeList?.Node[value];
            setSelectedStoreDetail(selectedStore)
            setCheckboxChecked(-1)
        }
    }
    const renderWebDataTable = () => {
        // const rows = [
        //     { id: 1, store: "914", available: "31" },
        //     { id: 2, store: "KCDC", available: "513" },
        // ];
        let rows = [] as any;

        resourceViewData?.NodeList?.Node?.forEach((store: Node, index: any) => {
            let storeData = {
                id: index,
                ShipNode: store.Availability.ShipNode,
                EnableForcedSourcing: store.EnableForcedSourcing,
                Quantity: store.Availability.AvailableQty
            }
            rows.push(storeData);
        });
        console.log("Resource Data",rows);

        return (
            !Utils.isMobile ? <div className="" style={{ height: 300 }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[15, 30, 50]}
                    pagination
                    selectionModel={selectionModel}
                    onSelectionModelChange={(newSelectionModel: any) => changeRadioSelection(newSelectionModel)}
                />
            </div> :
            <div>
                {pagination()}
                <div className='storesContainer'>
                    <div className='storeDataTable'>
                        {
                            mobileAvailableStores.map((row:any)=>{
                                return (
                                    <div style={{ background: row.id == mobileSelectedValue ? '#F9F9F9' : 'white' }} className='storeRow' key={row.id}>
                                        <div className='cellLeft'>
                                            
                                            <div className="cellRadio">
                                                <Radio onChange={(e:any) => changeMobileRadioSelection(Number(e.target.value))} checked={mobileSelectedValue === row.id} value={row.id} />
                                            </div>
                                            <Typography className='cellHeader'>
                                                Store
                                            </Typography>
                                            <Typography className='cellHeader'>
                                                Available Quantity
                                            </Typography>
                                            <Typography className='cellHeader'>
                                                Force Sourcing
                                            </Typography>
                                        </div>
                                        <div className='cellRight'>
                                            <Typography className='cellData'>
                                            </Typography>
                                            <Typography className='cellData' style={{paddingTop:"2px"}}>
                                                {row.ShipNode}
                                            </Typography>
                                            <Typography className='cellData' style={{paddingTop:"2px"}}>
                                                {row.Quantity}
                                            </Typography>
                                            <div className="cellCheckBox">
                                                <Checkbox
                                                    checked={checkboxChecked == row.id}
                                                    disabled={row.EnableForcedSourcing == "Y"? false: true}
                                                    onChange={(e) => checkboxChange(e, row.id)}
                                                    style={{
                                                        color: row.EnableForcedSourcing == "Y" ? "#D93A2F":"rgba(0, 0, 0, 0.26)",
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    };

    const changeReasonText = (e: any) => {
        setReasonText(e.target.value)
    }
    const changeReasonCode = (e: any) => {
        setReasonCode(e.target.value)
    }
    const renderReasons = () => {
        return (
            <div style={{ marginTop: 10 }}>
                <Grid item xs={12} sm={12} className='inputField'>
                    <Typography>
                        Reason Code*
                    </Typography>
                    <FormControl className="white-bg-color">
                        <Select
                            onChange={(e) => changeReasonCode(e)}
                            displayEmpty
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={reasonCode}
                            variant='outlined'
                            inputProps={{ 'aria-label': 'Without label' }}>
                            <MenuItem disabled value={0}>
                                <em>Please Select ID Type</em>
                            </MenuItem>
                            {resourceReasonCodes.map((item: any, i: number) => <MenuItem value={item.value}>{item.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} className='inputField'>
                    <Typography>
                        Reason Text*

                    </Typography>
                    <TextField
                        value={reasonText}
                        onChange={(e) => changeReasonText(e)}
                        fullWidth
                        InputLabelProps={{ shrink: false }}
                        id="filled-basic"
                        placeholder="Reason text"
                        variant="outlined"
                        style={{ backgroundColor: 'white' }}
                        multiline
                    />
                </Grid>
            </div>


        );
    }
    const renderQuantity = () => {
        return (
            //  !Utils.isMobile ? 
            <div style={{ marginTop: 20 }}>
                <Grid item xs={12} sm={12} className='inputField'>
                    <Typography>
                        Resource Quantity*
                    </Typography>
                    {quantityCounter()}
                </Grid>
            </div>
            // :
            // <div style={{marginTop:10, textAlign:"left"}}> 
            //         <Typography>
            //             Resource Quantity*
            //         </Typography>   
            //         <div style={{marginLeft:10,marginTop:10}}>
            //             {quantityCounter()}
            //         </div>
            // </div>
        );
    }
    const handleQuantityChange = (event: any) => {
        let value = event.target.value;
        if (value == "") {
            setResourceQty(0);
        }
        else if ((Number(value) >= 1 && Number(value) <= Number(availableQty))) {
            setResourceQty(Number(value));
        }
    };

    const getAvailableStoreData = () => {
        if(resourceViewData?.NodeList?.Node?.length>0)
        {
            const from = page * rowsPerPage;
            let resourceData = resourceViewData.NodeList.Node;
            resourceData = resourceData.slice(from, from + rowsPerPage);
            let availableStores = [] as any;
            resourceData.forEach((store:Node,index:any)=>{
                let row = {
                    id: index,
                    ShipNode: store.Availability.ShipNode,
                    EnableForcedSourcing: store.EnableForcedSourcing,
                    Quantity: store.Availability.AvailableQty
                }
                availableStores.push(row);
            });
            setMobileAvailableStores(availableStores);
        }
    }

    const callResourceViewData = () => {
        setLoading(true)
        setYesButton(false)
        OMSManager.GCPickResourceView(orderDetail, selectedShipmentLineKey, valueConformed, zipCode).then((result) => {
            if (result.success == true) {
                setResourceViewData(result.data);
                if(Utils.isMobile)
                {
                    setTotalRecords(result.data?.NodeList?.Node?.length ?? 0);
                }
                // setReasonText("")
                // setReasonCode(0)
                setCheckboxChecked(-1);
                setSelectionModel([]);
                setMobileSelectedValue(-1);
                setLoading(false)
            }
            else {
                //show Error 
                setLoading(false)
                setOpenGeneralError(true)
                setErrorGeneralMsg("Something went wrong");
                setErrorReportMsg("Something went wrong");
                setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.RESOURCE_ITEM_RESOURCE_VIEW)
            }
        }).catch(() => {
            setLoading(false)
            setOpenGeneralError(true)
            setErrorGeneralMsg("Something went wrong");
            setErrorReportMsg("Something went wrong");
            setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.RESOURCE_ITEM_RESOURCE_VIEW)
        });
    }

    const showGeneralErrorModel = async (msg: any, action: any) => {

        setOpenGeneralError(true);
        setErrorGeneralMsg(msg);
        setReportErrorAction(action);


    }

    const closeGeneralErrorModel = () => {
        setOpenGeneralError(false);
        setErrorGeneralMsg("");
    }

    const popUpBack = () => {
        navigate(-1)
    }

    const closeErrorReportPopup = () => {
        setOpenReportError(false)
    }

    // Show Pop ups If Error is already reported
    const handleErrorAlreadyReported = () => {
        // setOpenAlreadyReportedError(true);
        setOpenReportError(false);
    }
    // Report Error
    const reportConfirmErrorPopup = () => {
        setOpenConfirmReportError(true);
        setOpenGeneralError(false);

    }

    let errorGeneralPopupCloseButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeGeneralErrorModel,
            color: Constants.Colors.white,
            border: Constants.Colors.red,
            textColor: Constants.Colors.red
        }
    ];

    let errorGeneralPopupButtons: IButton[] = [
        {
            text: "Report Error",
            icon: <ReportProblemIcon />,
            action: reportConfirmErrorPopup,
            color: Constants.Colors.red,
        },
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeGeneralErrorModel,
            color: Constants.Colors.white,
            border: Constants.Colors.red,
            textColor: Constants.Colors.red
        }
    ];

    if (Utils.getConfigurationWithKey(Constants.Configurations.ReportErrorSNFlag) === false) {
        errorGeneralPopupButtons.shift()
    };

    let GeneralError: INotificationPopupSettings = {
        open: openGeneralError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorGeneralMsg,
        draggable: false,
        handleClose: closeGeneralErrorModel,
        actions: reportErrorAction ? errorGeneralPopupButtons : errorGeneralPopupCloseButtons
    }

    // Report Error Pop up
    let ReportError: INotificationPopupSettings = {
        open: openReportError,
        type: Constants.NotificationPopupType.Conformation,
        title: "Report Error",
        msg: "",
        isCustomJSX: true,
        customJSX: <ReportErrorPopup handleErrorAlreadyReported={handleErrorAlreadyReported} errorMessage={errorReportMsg} closeErrorReportPopup={closeErrorReportPopup}  urlPathname={pathname} reportErrorAction={reportErrorAction}></ReportErrorPopup>,
        draggable: false,
        handleClose: closeErrorReportPopup,
        actions: [],
        fullWidth: true,
        maxWidth: "lg"
    }

    // Confirm Report Error
    const closeConfirmReportError = () => {
        setOpenConfirmReportError(false);
    }

    const reportErrorPopup = () => {
        setOpenReportError(true);
        setOpenConfirmReportError(false)
    }

    let confirmReportErrorPopupButtons: IButton[] = [
        {
            text: "Continue",
            icon: <ArrowForwardIcon />,
            action: reportErrorPopup,
            color: Constants.Colors.red,
        }
    ];
    let confirmReportError: INotificationPopupSettings = {
        open: openConfirmReportError,
        type: Constants.NotificationPopupType.Conformation,
        title: Constants.MESSAGES.SN_CNFRM_TITLE,
        msg: "",
        customJSX: <ServiceNowCnfrmText></ServiceNowCnfrmText>,
        isCustomJSX: true,
        draggable: false,
        handleClose: closeConfirmReportError,
        actions: confirmReportErrorPopupButtons
    }



    const logOrderHistory = async (input: any) => {
        const reasonCodeName = resourceReasonCodes?.find((x: any) => x.value == input.reasonCode)?.name ?? "";
        const storePhone = StoreManager.allStoredata.find(x => x.StoreId == StoreManager.currentStore.StoreId)?.Phone ?? "";
        await OrderManager.saveOrderHistory({
            shipmentKey: props.shipmentKey,
            logType: "history",
            transactionType: Constants.OrderHistoryTypes.Resource_Item,
            transactionDate: moment.utc(moment.tz("America/Los_Angeles")).toISOString(),
            selectedShipmentLineKey: input.selectedShipmentLineKey,
            resourceShipmentNode: input.resourceShipmentNode,
            resourceShipmentLineData: input.resourceShipmentLineData,
            reasonCodeName: reasonCodeName,
            reasonCode: input.reasonCode,
            reasonText: input.reasonText,
            storePhone: storePhone,
            userName: IdentityManager.getUserProperty(Constants.EmployeeName),
            totalQty: availableQty,
            resourceQty: resourceQty,
            partialResource: resourceQty < availableQty ? "1" : "0"
        });
        dispatch(allActions.orderHistoryActions.forceHistoryLoad());
    }

    const resetResourceData = () => {
        setSelectionModel([]);
        setResourceViewData({} as IResourceView);
        setMobileSelectedValue(-1);
        setMobileAvailableStores([]);
        setSelectedStoreDetail({} as Node);
        setTotalRecords(0);
    };

    const resourceItem = () => {
        if (StoreManager.currentStore.StoreId == undefined) {
            return
        }
        let selectedNode: Node | undefined = checkNormalResourcing() ? (!Utils.isMobile? resourceViewData?.NodeList?.Node[selectionModel[0]]: resourceViewData?.NodeList?.Node[mobileSelectedValue]) : undefined;
        let resourceShipmentNode = selectedNode?.Availability?.ShipNode;
        let availability = selectedNode?.Availability?.AvailableQty;
        let forceSourcing = checkboxChecked == -1 ? "N" : "Y";
        let firstAvailableDate = selectedNode?.Availability?.FirstAvailableDate ? selectedNode?.Availability?.FirstAvailableDate : "";
        showActionLoader();
        const payload: IResourceItemInput = {
            orderDetail, selectedShipmentLineKey, reasonCode, reasonText, resourceShipmentNode, availability,
            forceSourcing, shipNode: StoreManager.currentStore.StoreId,
            firstAvailableDate,
            availableQty, resourceQty
        }
        OMSManager.GCPickResourceLine(payload).then((response: any) => {
            if (response.success == true) {
                if (response.data.IsSuccess == "Y") {
                    let selectedShipmentLine: ShipmentLine = orderDetail?.ShipmentLines?.ShipmentLine?.filter((shipmentLine: ShipmentLine) => {
                        return shipmentLine.ShipmentLineKey == selectedShipmentLineKey
                    })[0];

                    let resourceShipmentLineData: IResourceShipmentLine = {
                        imageUrl: selectedShipmentLine.ImageUrl,
                        itemDesc: selectedShipmentLine.ItemDesc,
                        itemID: selectedShipmentLine?.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID,
                        skuID: selectedShipmentLine.ItemID,
                        unitPrice: selectedShipmentLine.UnitPrice,
                        quantity: selectedShipmentLine.Quantity
                    };

                    logOrderHistory({ selectedShipmentLineKey, reasonCode, reasonText, resourceShipmentNode, resourceShipmentLineData });
                    hideActionLoader();
                    props.closeResource();
                    if (orderDetail.ShipmentLines && orderDetail.ShipmentLines.ShipmentLine && orderDetail.ShipmentLines.ShipmentLine.length == 1 && availableQty == resourceQty) {
                        navigate("/FulfillOrders");
                    }
                    else {
                        props.setSuccess(true)
                    }
                }
                else {
                    setOpenGeneralError(true)
                    setErrorGeneralMsg("Something went wrong");
                    setErrorReportMsg("Something went wrong");
                    setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.RESOURCE_ITEM_PICK_LINE)
                    hideActionLoader();
                }
            }
            else {
                let errorMsg = "";
                setOpenGeneralError(true)
                if (!response.success && response.msg) {
                    errorMsg = response.msg;
                }
                else {
                    errorMsg = "Something went wrong";
                }
                setErrorGeneralMsg(errorMsg);
                setErrorReportMsg(errorMsg);
                setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.RESOURCE_ITEM_PICK_LINE)
                hideActionLoader();
            }
        }).catch(() => {
            setOpenGeneralError(true)
            setErrorGeneralMsg("Something went wrong");
            setErrorReportMsg("Something went wrong");
            setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.RESOURCE_ITEM_PICK_LINE)
            hideActionLoader();
        })
    }

    React.useEffect(()=>{
        //Set Zip Code
        let zipCode = orderDetail.ToAddress.ShortZipCode;
        if(props?.fulfillmentType === Constants.OrderFulfillmentType.SHIP_2_CUSTOMER)
        {
            zipCode = orderDetail.ToAddress.ShortZipCode;
        }
        else if(props?.fulfillmentType === Constants.OrderFulfillmentType.PICKUP_IN_STORE)
        {
            const currentStoreZip = Utils.getShortZipCode(StoreManager?.currentStore?.Zip);
            if(currentStoreZip)
            {
                zipCode = currentStoreZip;
            }
        }

        zipCode = zipCode.replace(/\D/g,'0');

        setZipCode(zipCode);

    },[]);

    React.useEffect(() => {
        if (reasonCode != 0 && reasonText != "" && Number(resourceQty) > 0 && (zipCode!="" || resourceQty < availableQty) && (resourceQty < availableQty || (selectionModel.length != 0 && selectionModel[0] != -1) || mobileSelectedValue>=0)) {
            setYesButton(true)
        }
        else {
            setYesButton(false)
        }
    }, [reasonCode, reasonText, selectionModel, resourceQty, mobileSelectedValue, zipCode]);

    React.useEffect(() => {
        async function getResourceData() {
            await callResourceViewData()
        }
        if(zipCode!="" && zipCode.length>=5)
        {
            getResourceData();
        }
        else{
            resetResourceData();
        }
    }, [valueConformed,zipCode]);

    React.useEffect(()=>{
        if(Utils.isMobile)
        {         
            getAvailableStoreData();
        }
    }, [resourceViewData,rowsPerPage,page]);

    const handleQtyIncrement = () => {
        if (resourceQty < availableQty) {
            setResourceQty(resourceQty + 1);
        }
    }


    const handleQtyDecrement = () => {
        if (resourceQty > 1) {
            setResourceQty(resourceQty - 1);
        }
    }

    const quantityCounter = () => {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                    <div className="circle-buttons">
                        <AddIcon onClick={() => { handleQtyIncrement() }} style={{ fontSize: "20px" }} />
                    </div>
                </div>
                <div>
                    <TextField onInput={(e) => handleQuantityChange(e)} value={resourceQty} inputProps={{ style: { textAlign: 'center' } }} InputProps={{ style: { marginLeft: "8px", marginRight: "8px", width: Utils.isMobile ? "auto" : "100px", textAlign: "center", background: "white" } }} id="filled-basic" variant="outlined" autoComplete="off" />
                </div>
                <div>
                    <div className="circle-buttons">
                        <RemoveIcon onClick={() => { handleQtyDecrement() }} style={{ fontSize: "20px" }} />
                    </div>
                </div>
            </div>
        );
    }

    const checkNormalResourcing = () => {
        if (availableQty == resourceQty) return true;
    }

    const onChangeZipCode = (value:any) => {
        if(value==""|| (value.length<=5 && Number(value)>0))
        {
            setZipCode(value);
        }
        else if(Number(value)==0){
            setZipCode("");
        }
    }

    return (
        <div>
            <NotificationPopup {...verifyCredentialsPopUp}>
            </NotificationPopup>
            <NotificationPopup {...GeneralError}></NotificationPopup>
            <NotificationPopup {...ReportError}></NotificationPopup>
            <NotificationPopup {...confirmReportError} ></NotificationPopup>
            {Utils.partialResource() && availableQty > 1 && renderQuantity()}
            {renderReasons()}
            {checkNormalResourcing() && <>
            <Typography style={{ textAlign: "left", marginTop: 5 }}>ZIP Code*</Typography>
            <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
                <Grid item sm={2} xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={zipCode}
                    placeholder={"Zip Code"}
                    style={{ backgroundColor: 'white' }}
                    onChange={(e) => {onChangeZipCode(e.target.value)}}

                />
                </Grid>
            </Grid>
            <Typography style={{ textAlign: "left", marginTop: 5 }}>Radius (Miles)</Typography>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <SocialDistanceIcon />
                    </Grid>
                    <Grid item xs>
                        <Slider
                            value={value}
                            min={5}
                            step={1}
                            max={resourceMax}
                            scale={calculateValue}
                            onChange={(e, newValue) => handleChange(e, newValue)}
                            style={{ color: Constants.Colors.red }}
                            onChangeCommitted={(e, newValue) => handleChangeConformed(e, newValue)}
                        />
                    </Grid>
                    <Grid item>
                        <Input
                            value={value}
                            size="small"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            inputProps={{
                                step: 5,
                                min: 1,
                                max: resourceMax,
                                type: "number",
                                "aria-labelledby": "input-slider",
                            }}
                        />
                    </Grid>
                </Grid>
                {loading == false ?
                    <React.Fragment>
                        <Card className='dCard' >
                            <CardContent className='dCardContent' style={{ padding: 0 }}>
                                <Grid container>
                                    <div className="topHeading" style={{ padding: 8 }}>
                                        <div className="gridHeading textLeft">
                                            <Typography>Available Stores</Typography>
                                        </div>
                                        <div style={{ padding: Utils.isMobile ? "8px": "10px" }}>
                                            <Grid sm={12} xs={12} item>
                                                {renderWebDataTable()}
                                            </Grid>
                                        </div>
                                    </div>
                                </Grid>
                            </CardContent>
                        </Card>
                        {selectedStoreDetail?.NodeOrgCode != undefined ?
                            <Card className="dCard" style={{ marginTop: 6 }}>
                                <CardContent className={Utils.isMobile? "" : "dCardContent"}>
                                    <Grid container>

                                        <div className="topHeading">
                                            {!Utils.isMobile && <><div className="gridHeading" style={{ display: "flex", justifyContent: "space-between" }}>
                                                <Typography>Resource To</Typography>
                                                <Typography>{selectedStoreDetail.DistanceFromShipToAddress} Miles Away</Typography>
                                            </div>
                                            <Divider className="card-divider"/></>}
                                            <div style={{ padding: Utils.isMobile? "0px":"10px", display: "flex" }}>
                                                <Grid item sm={7} xs={6}>
                                                    <Typography
                                                        className={Utils.isMobile? "resource-heading mobile":"resource-heading"} style={{marginBottom:"10px"}}>
                                                        {selectedStoreDetail.Description}

                                                    </Typography>
                                                    <Typography className="resource-value">
                                                        {selectedStoreDetail.DistanceFromShipToAddress} Miles Away
                                                    </Typography>
                                                    {
                                                        selectedStoreDetail.ShipNodePersonInfo.EMailID != "" ?
                                                            <Typography className="resource-value">{selectedStoreDetail.ShipNodePersonInfo.EMailID}</Typography>
                                                            :
                                                            ""
                                                    }
                                                    {
                                                        selectedStoreDetail.ShipNodePersonInfo.DayPhone != "" ?
                                                            <Typography className="resource-value">
                                                                {selectedStoreDetail.ShipNodePersonInfo.DayPhone}
                                                            </Typography>
                                                            :
                                                            ""
                                                    }
                                                </Grid>
                                                <Grid item sm={5} xs={6} style={{paddingLeft:Utils.isMobile?"5px":"0px"}}>
                                                    <Typography
                                                        className={Utils.isMobile? "resource-heading mobile":"resource-heading"} style={{marginBottom:"10px"}}
                                                    >
                                                    Store Address
                                                    </Typography>
                                                    <Typography className="resource-value">
                                                        {selectedStoreDetail.ShipNodePersonInfo.AddressLine1}
                                                    </Typography>
                                                    <Typography className="resource-value">
                                                        {selectedStoreDetail.ShipNodePersonInfo.AddressLine2}
                                                    </Typography>
                                                    <Typography className="resource-value">
                                                        {selectedStoreDetail.ShipNodePersonInfo.City},{selectedStoreDetail.ShipNodePersonInfo.State},{selectedStoreDetail.ShipNodePersonInfo.ZipCode}
                                                    </Typography>
                                                    <Typography className="resource-value">
                                                        {selectedStoreDetail.ShipNodePersonInfo.Country}
                                                    </Typography>
                                                </Grid>
                                            </div>
                                        </div>

                                    </Grid>
                                </CardContent>
                            </Card>
                            :
                            <div></div>
                        }

                    </React.Fragment>
                    :
                    <div>
                        <TailSpin color="#D93A2F" height={80} width={80} />
                    </div>

                }</>}
            <DialogActions>
                <Button className={enableYesButton ? "" : "disabledButton"} disabled={enableYesButton ? false : true} key={0} style={{ whiteSpace: 'nowrap', backgroundColor: Constants.Colors.blue, color: "white" }} onClick={() => openVerifyCredentials()} variant="contained" startIcon={<DoneIcon />}>
                    Yes
                </Button>
                <Button key={1} style={{ whiteSpace: 'nowrap', border: `1px solid ${Constants.Colors.blue}`, backgroundColor: "white", color: Constants.Colors.blue }} onClick={() => props.closeResource()} variant="contained" startIcon={<CloseIcon />}>
                    No
                </Button>
            </DialogActions>
        </div >
    );
};
