import React, { useState } from 'react';
import { Button, Card, CardContent, Divider, Drawer, Grid, Paper, TextField, Toolbar, Typography, InputAdornment } from '@mui/material';
import Loader from "react-loader-spinner";
import ShipmentStatusConstant from "../../Common/ShipmentStatusConstants";
import { CloseIcon, PlaylistAddCheck, ShoppingCartOutlinedIcon, ApartmentIcon, DirectionsCar, FilterAltIcon, LocalShippingIcon, PreviewIcon, SearchIcon, ExpandMoreIcon, ExpandLessIcon } from "../../Subcomponent/Shared/SharedIcons";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IButton, INotificationPopupSettings } from '../../Models/INotificationPopupSettings';
import Constants from '../../Common/Constants';
import { NotificationPopup } from './NotificationPopup';
import Utils from '../../Common/Utils';
import allActions from '../../Store/Actions/AllActions';


const StatusBtn = (props: any) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openConstruction, setOpenConstruction] = useState(false);
    const closeConstructionPopup = () => {
        setOpenConstruction(false)
    }
    const openConstructionPopup = () => {
        setOpenConstruction(true)
    }

    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }

    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }

    // Construction Popup
    let constructionButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeConstructionPopup,
            color: Constants.Colors.red,
        },
    ];

    let underconstruction: INotificationPopupSettings = {
        open: openConstruction,
        type: Constants.NotificationPopupType.Info,
        title: "",
        msg: "Feature under construction",
        draggable: false,
        handleClose: closeConstructionPopup,
        actions: constructionButtons
    }

    const loadOrder = async (shipmentKey: any, type: any, orderNo?: any, storeId?: any,) => {

        showActionLoader();
        let detailStatus = true;
        await Utils.checkOrderDetailStatus({
            shipmentKey: shipmentKey,
            status: type
        }).then((res: any) => {
            if (res?.success) {
                detailStatus = res.data;
            }
        }).catch((err: any) => {
            console.log("Order Detail Status Error", err);
        })

        switch (type) {
            case ShipmentStatusConstant.ReadyForPick:
                if (detailStatus) {
                    hideActionLoader();
                    navigate("/PickOrder?orderno=" + orderNo + "&shipmentkey=" + shipmentKey + "&store=" + storeId + "")
                }
                else {
                    hideActionLoader();
                    props.openInfo();
                }
                break;
            case ShipmentStatusConstant.ReadyForPack:
                if (detailStatus) {
                    hideActionLoader();
                    navigate("/PackOrder?orderno=" + orderNo + "&shipmentkey=" + shipmentKey + "&store=" + storeId + "");
                }
                else {
                    hideActionLoader();
                    props.openInfo();
                }
                break;
            case ShipmentStatusConstant.ReadyForCustomerPickup:
                if (detailStatus) {
                    hideActionLoader();
                    navigate("/CustomerPickup?orderno=" + orderNo + "&shipmentkey=" + shipmentKey + "&store=" + storeId + "");
                }
                else {
                    hideActionLoader();
                    props.openInfo();
                }
                break;
            case ShipmentStatusConstant.OnHold:
                if (detailStatus) {
                    hideActionLoader();
                    navigate("/PickOrder?orderno=" + orderNo + "&shipmentkey=" + shipmentKey + "&store=" + storeId + "");
                }
                else {
                    hideActionLoader();
                    props.openInfo();
                }
                break;
        }
    }
    const PackButton = (status: any) => {
        if (status == ShipmentStatusConstant.ShipmentPacked) {
            return "Ship";
        }
        else {
            return "Pack";
        }
    }
    const PackIcon = (status: any) => {
        if (status == ShipmentStatusConstant.ShipmentPacked) {
            return <LocalShippingIcon className='fTabIcons' />;
        }
        else {
            return <ApartmentIcon className='fTabIcons' />;
        }
    }
    const WebGridButton = (row: any) => {
        if (row._source.Shipment.Status) {
            switch (row._source.Shipment.Status) {
                case ShipmentStatusConstant.OnHold:
                    return <React.Fragment>
                        <Button startIcon={<ShoppingCartOutlinedIcon className='fTabIcons' />} variant="contained" color="primary" style={{ backgroundColor: Constants.Colors.white, borderColor: Constants.Colors.red, color: Constants.Colors.red, boxShadow: 'none', border: "2px solid", paddingLeft: 8, paddingRight: 8 }} onClick={() => { loadOrder(row._source.Shipment.ShipmentKey, row._source.Shipment.Status, row._source.Shipment.OrderNo, row._source.Shipment.ShipNode) }}>Pick</Button>
                    </React.Fragment >
                case ShipmentStatusConstant.ReadyForPick:
                    return <React.Fragment>
                        <Button startIcon={<ShoppingCartOutlinedIcon className='fTabIcons' />} variant="contained" color="primary" style={{ backgroundColor: Constants.Colors.white, borderColor: Constants.Colors.red, color: Constants.Colors.red, boxShadow: 'none', border: "2px solid", paddingLeft: 8, paddingRight: 8 }} onClick={() => { loadOrder(row._source.Shipment.ShipmentKey, row._source.Shipment.Status, row._source.Shipment.OrderNo, row._source.Shipment.ShipNode) }}>Pick</Button>
                    </React.Fragment >
                case ShipmentStatusConstant.ReadyForPack: case ShipmentStatusConstant.ShipmentBeingPacked: case ShipmentStatusConstant.ShipmentPacked:
                    return <React.Fragment>
                        <Button startIcon={PackIcon(row._source.Shipment.Status)} variant="contained" color="primary" style={{ backgroundColor: Constants.Colors.white, borderColor: Constants.Colors.red, color: Constants.Colors.red, boxShadow: 'none', border: "2px solid", paddingLeft: 8, paddingRight: 8 }} onClick={() => { loadOrder(row._source.Shipment.ShipmentKey, row._source.Shipment.Status, row._source.Shipment.OrderNo, row._source.Shipment.ShipNode) }}>{PackButton(row._source.Shipment.Status)}</Button>
                    </React.Fragment>
                case ShipmentStatusConstant.ReadyForCustomerPickup:
                    return <React.Fragment>
                        <Button startIcon={<DirectionsCar className='fTabIcons' />} variant="contained" color="primary" style={{ backgroundColor: Constants.Colors.white, borderColor: Constants.Colors.red, color: Constants.Colors.red, boxShadow: 'none', border: "2px solid", paddingLeft: 8, paddingRight: 8 }} onClick={() => { loadOrder(row._source.Shipment.ShipmentKey, row._source.Shipment.Status, row._source.Shipment.OrderNo, row._source.Shipment.ExtnPickupStoreID ? row._source.Shipment.ExtnPickupStoreID : 0) }}>Pickup</Button>
                    </React.Fragment>
            }
        }
    }

    return (

        <React.Fragment>
            <NotificationPopup {...underconstruction}></NotificationPopup>
            {WebGridButton(props.item)}
        </React.Fragment>


    );
}

export default StatusBtn;

