import { FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Constants from "../../Common/Constants";
import allActions from "../../Store/Actions/AllActions";
import LabelText from "../../Common/LabelText";

const CustomerPickupSlipOptions = () => {
    
    const dispatch = useDispatch();
    let customerSlipOptions: any = useSelector((state: any) => state.FulfillOrdersReducersState.customerSlipOptions);

    return (
        <>
        <Paper style={{ padding: "5px" }}>
            <Grid container>
                <Grid item xs={12} sm={12} className='inputField'>
                    <FormControl className='externalcustomRadio'>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={customerSlipOptions.dateValue}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {

                                dispatch(allActions.fulfillOrdersActions.changeCustomerSlipOptions(
                                    {
                                        ...customerSlipOptions,
                                        dateValue: (event.target as HTMLInputElement).value
                                    }
                                ))
                            }}
                        >
                            {Constants.customerSlipDurationOptions.map((element: any) => {
                                return <FormControlLabel value={element.id} control={<Radio style={{ color: "#f2554a" }} />} label={element.name} />
                            })}


                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Paper>
            <Typography style={{ fontSize: 18,marginTop:"5px" }}>{LabelText.CustomerPickup.CustomerSlipsSortOrder}</Typography>
        <Paper>
            <Grid container>
                <Grid item xs={12} sm={12} className='inputField'>
                     <FormControl className='externalcustomRadio'>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={customerSlipOptions.sortOrder}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {

                                dispatch(allActions.fulfillOrdersActions.changeCustomerSlipOptions(
                                    {
                                        ...customerSlipOptions,
                                        sortOrder: (event.target as HTMLInputElement).value
                                    }
                                ))
                            }}
                        >
                            {Constants.customerSlipSortOptions.map((element: any) => {
                                return <FormControlLabel value={element.id} control={<Radio style={{ color: "#f2554a" }} />} label={element.name} />
                            })}


                        </RadioGroup>
                    </FormControl>
                </Grid>

            </Grid>
        </Paper>
        </>
    )
}

export default CustomerPickupSlipOptions;