import { Navigate, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { textAlign } from '@mui/system';
import { Typography } from '@material-ui/core';
import StoreManager from '../../Managers/Store.manager';
import allActions from '../../Store/Actions/AllActions';
import ApiService from '../../Common/ApiService'
import PdfIframe from "../../Subcomponent/PickOrder/PdfIframe";
import Constants from '../../Common/Constants';
import { Card, CardContent, Grid } from '@mui/material';
import Utils from '../../Common/Utils';
import { IButton, INotificationPopupSettings } from '../../Models/INotificationPopupSettings';
import { CloseIcon, DoneIcon } from '../Shared/SharedIcons';
import LabelText from '../../Common/LabelText';
import PrintPickSlipsGroupByOptions from './PrintPickSlipsGroupByOptions';
import { NotificationPopup } from '../Shared/NotificationPopup';

const PickSlipGroupBy = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let pickSlipGroupByModal: any = useSelector((state: any) => state.FulfillOrdersReducersState.pickSlipGroupByModal);
    console.log(pickSlipGroupByModal, "PickSlipGroupBy");

    const closeCancel = () => {

        dispatch(allActions.fulfillOrdersActions.changePrintPickSlips(
            {
                ...pickSlipGroupByModal,
                open: false
            }
        ))

    }


    const printPickSlips = () => {

        // This will trigger the state in main fulfillment components
        dispatch(allActions.fulfillOrdersActions.changePrintPickSlips(
            {
                ...pickSlipGroupByModal,
                showPdf: true
            }
        ))

    }


    let Buttons: IButton[] = [{
        text: "Print Slips",
        icon: <DoneIcon />,
        action: printPickSlips,
        color: Constants.Colors.yellow,
    },
    {
        text: "Cancel",
        icon: <CloseIcon />,
        action: closeCancel,
        color: Constants.Colors.grey,
    }
    ]
    let Popup: INotificationPopupSettings = {
        open: pickSlipGroupByModal.open,
        type: Constants.NotificationPopupType.Info,
        title: LabelText.NotificationPopupHeaders.Info,
        msg: LabelText.PickOrder.PickSlipsPrint,
        handleClose: closeCancel,
        isCustomJSX: true,
        customJSX: <PrintPickSlipsGroupByOptions></PrintPickSlipsGroupByOptions>,
        actions: Buttons
    }


    return (
        <React.Fragment>
            {pickSlipGroupByModal.open ? <NotificationPopup {...Popup} /> : ""}
        </React.Fragment>
    )

}

export default PickSlipGroupBy;