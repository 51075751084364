import Constants from "./Constants";

import moment from "moment";
import StoreManager from "../Managers/Store.manager";
import PFManager from "../Managers/Process.Fulfillment.Manager";
import { ShipmentLine2, BillToAddress, ToAddress } from "../Models/OmsPackOrderDetails"
import { ICreateShipmentPayload, PAYLOAD, PACKAGE } from "../Models/ICreateShipmentPayload"
import IdentityManager from "../Managers/Identity.manager"
import IStoreInfo from "../Models/IStoreInfo"
import IBoxCharacteristic from "../Models/IBoxCharacteristic"
export default class PFUtils {

    public static getBody_RePrint = (empId: string, SUID: string, shipper: string) => {
        return {
            ACTION: Constants.PROCESS_FULFILLMENT.ACTIONS.REPRINT,
            PAYLOAD: {
                APP_ID: Constants.PROCESS_FULFILLMENT.APP_ID,
                EMPLOYEE_ID: empId,//"986732",
                SHIPPER: shipper,//"G220",
                SUID: SUID//"3DE9386ED84249F98B7EB2FACEBA0F40"
            }
        };
    }

    public static getBody_Void_Shipment = (empId: string, SUID: string, shipper: string) => {
        return {
            ACTION: Constants.PROCESS_FULFILLMENT.ACTIONS.VOID_SHIPMENT,
            PAYLOAD: {
                APP_ID: Constants.PROCESS_FULFILLMENT.APP_ID,
                EMPLOYEE_ID: empId,//"3453535",
                SHIPPER: shipper,//"G220",
                SUID: SUID,//"EDF39EF1626842999FF47E6A165BD21"
            }
        }
    }

    public static getBody_Create_Shipment = (empId: string, shipper: string, data: any) => {
        return {
            ACTION: Constants.PROCESS_FULFILLMENT.ACTIONS.CREATE_SHIPMENT,
            PAYLOAD: {
                APP_ID: Constants.PROCESS_FULFILLMENT.APP_ID,
                EMPLOYEE_ID: empId,//"986732",
                SHIPPER: shipper,//"G220",
                SHIPPER_SHIPMENT_REFERENCE: data.SHIPPER_SHIPMENT_REFERENCE,

                CONSIGNEE_CONTACT: data.CONSIGNEE_CONTACT,
                CONSIGNEE_COMPANY: data.CONSIGNEE_COMPANY,
                CONSIGNEE_ADDRESS1: data.CONSIGNEE_ADDRESS1,
                CONSIGNEE_ADDRESS2: data.CONSIGNEE_ADDRESS2,
                CONSIGNEE_ADDRESS3: data.CONSIGNEE_ADDRESS3,
                CONSIGNEE_CITY: data.CONSIGNEE_CITY,
                CONSIGNEE_POSTALCODE: data.CONSIGNEE_POSTALCODE,
                CONSIGNEE_STATE: data.CONSIGNEE_STATE,
                CONSIGNEE_COUNTRYID: data.CONSIGNEE_COUNTRYID,
                CONSIGNEE_PHONE: data.CONSIGNEE_PHONE,

                RETURN_COMPANY: data.RETURN_COMPANY,
                RETURN_CONTACT: data.RETURN_CONTACT,
                RETURN_ADDRESS1: data.RETURN_ADDRESS1,
                RETURN_ADDRESS2: data.RETURN_ADDRESS2,
                RETURN_CITY: data.RETURN_CITY,
                RETURN_STATE: data.RETURN_STATE,
                RETURN_POSTALCODE: data.RETURN_POSTALCODE,
                RETURN_PHONE: data.RETURN_PHONE,

                SHIPDATE: data.SHIPDATE,
                SHIPMENTHOLD_FLAG: data.SHIPMENTHOLD_FLAG,
                CONSIGNEE_RESIDENTIAL_FLAG: data.CONSIGNEE_RESIDENTIAL_FLAG,
                PACKAGES: data.PACKAGES,
                CARRIER: data.CARRIER,
                SERVICE: data.SERVICE,
                REASON: data.REASON,
                WEIGHT_UNIT: data.WEIGHT_UNIT,

                CUSTOMER_EMAIL: data.CUSTOMER_EMAIL,
                CUSTOMER_SEND_EMAIL: data.CUSTOMER_SEND_EMAIL,
                STORE_EMAIL: data.STORE_EMAIL,
                STORE_SEND_EMAIL: data.STORE_SEND_EMAIL,
            }
        }
    }

    public static parseCarrierServices = (data: any[]) => {
        let def: any = {},
            results: any[] = [],
            carriers: string[] = [],
            defloaded: boolean = false;

        data.forEach((r: any) => {
            if (!carriers.includes(r.Carrie)) { carriers.push(r.Carrie); }
            if (r.Default === 'true' && !defloaded) { def = r; defloaded = true; }
        });

        carriers.forEach((r: string) => {
            let result: any = {};
            result['Title'] = r;
            result['Services'] = data
                .filter((e: any) => e.Carrie === r)
                .map((e: any) => ({
                    price: '..',
                    name: e.Name !== undefined && e.Name !== null ? e.Name : "",
                    service: e.Service !== undefined && e.Service !== null ? e.Service : "",
                    SaturdayDelivery: e.SaturdayDelivery !== undefined ? e.SaturdayDelivery : "true"
                }));
            results.push(result);
        });

        return [results, def];
    }

    public static parseData_CreateShipment = (data: any) => {
        let _date = moment(data.shipDate).format('YYYY-MM-DD');

        let storeName = ''
        if (data.isDC) {
            let dc = StoreManager.DCList.find(r => r.id === data.shipToDetails.id);
            storeName = `${dc.StoreID} - ${dc.Description}`
        } else {
            storeName = `Guitar Center Store ${data.shipToDetails.id}`;
        }

        let customer = data.formType === 'Customer/Vendor' ? data.customer : storeName;
        let returnContact = data.formType === 'ReturnLabel' ? data.customer : `Store ${data.shipFromDetails.id}`;
        let carrier = PFManager.carrierServiceData[parseInt(data.carrier)].Title;
        let service = PFManager.carrierServiceData[parseInt(data.carrier)].Services[parseInt(data.service)].service;
        let consigneeCompany = data.formType === 'Customer/Vendor' ? data.consigneeCompany : data.formType === 'ReturnLabel' ? `Guitar Center ${data.shipToDetails.City}` : ""

        let requestData: any = {
            SHIPPER_SHIPMENT_REFERENCE: data.shipperRef,//"GC10078003",
            CONSIGNEE_CONTACT: customer,
            CONSIGNEE_COMPANY: consigneeCompany,
            CONSIGNEE_ADDRESS1: data.shipToDetails.Address,
            CONSIGNEE_ADDRESS2: data.shipToDetails.Address2,
            CONSIGNEE_ADDRESS3: "",
            CONSIGNEE_CITY: data.shipToDetails.City,
            CONSIGNEE_POSTALCODE: data.shipToDetails.Zip,
            CONSIGNEE_STATE: data.shipToDetails.State,
            CONSIGNEE_COUNTRYID: "US",
            CONSIGNEE_PHONE: data.shipToDetails.Phone,
            SHIPDATE: _date,
            RETURN_COMPANY: data.formType === 'ReturnLabel' ? "GC Return" : `Guitar Center ${data.shipFromDetails.City}`,
            RETURN_CONTACT: returnContact,
            RETURN_ADDRESS1: data.shipFromDetails.Address,
            RETURN_ADDRESS2: data.shipFromDetails.Address2,
            RETURN_CITY: data.shipFromDetails.City,
            RETURN_STATE: data.shipFromDetails.State,
            RETURN_POSTALCODE: data.shipFromDetails.Zip,
            RETURN_PHONE: data.shipFromDetails.Phone,
            SHIPMENTHOLD_FLAG: data.flags.SHIPMENTHOLD_FLAG ? "1" : "0",
            CONSIGNEE_RESIDENTIAL_FLAG: data.flags.IS_RESIDENTIAL ? "1" : "0",
            PACKAGES: [],
            CARRIER: carrier,
            SERVICE: service,
            REASON: data.reason
        }

        if (StoreManager.config.multiPackage === "true") {
            data.packages.forEach((r: any) =>
                requestData.PACKAGES.push({
                    WEIGHT: r.weight,
                    DIMENSION: r.dimension,
                    PROOF_FLAG: data.flags.PROOF_FLAG ? "1" : "0",
                    PROOF_SIGNATURE_FLAG: data.flags.PROOF_SIGNATURE_FLAG ? "1" : "0",
                    SATURDAYDELIVERY_FLAG: data.flags.SATURDAYDELIVERY_FLAG ? "1" : "0"
                })
            );
        } else {
            let weight = data.weightUnit === 'lb' ? data.weight : (parseInt(data.weight) / 16) + "";
            requestData.PACKAGES.push({
                WEIGHT: weight,
                DIMENSION: data.dimension,
                PROOF_FLAG: data.flags.PROOF_FLAG ? "1" : "0",
                PROOF_SIGNATURE_FLAG: data.flags.PROOF_SIGNATURE_FLAG ? "1" : "0",
                SATURDAYDELIVERY_FLAG: data.flags.SATURDAYDELIVERY_FLAG ? "1" : "0"
            })
        }

        if (data.formType === 'ReturnLabel') {
            // {customer_email: '', store_email: '', sendMail_customer: true, sendMail_store: true}
            requestData.CUSTOMER_EMAIL = data.returnLabelData.customer_email;
            requestData.CUSTOMER_SEND_EMAIL = data.returnLabelData.sendMail_customer;
            requestData.STORE_EMAIL = data.returnLabelData.store_email;
            requestData.STORE_SEND_EMAIL = data.returnLabelData.sendMail_store;
        }

        requestData.WEIGHT_UNIT = data.weightUnit
        return requestData;
    }
    public static parseData_CreateShipment_exstore = (ShipmentKey: string, OrderNo: string, ShipmentLineKey: string[], OrderLineKey: string[], boxDetail: IBoxCharacteristic, selectedStore: IStoreInfo, levelofService: string, toAddress: ToAddress, billToAddress: BillToAddress, shipDate: any, isResidential: any, iskcdc: boolean) => {
        let _date = moment(shipDate).format('YYYY-MM-DD');
        //
        try {
            if (StoreManager.currentStore == undefined || StoreManager.currentStore.StoreId == undefined) {
                return { success: false, msg: "Select Store" }
            }
            let LOS = PFUtils.getLevelofServiceString(levelofService);
            let empId = IdentityManager.getUserProperty(Constants.EmployeeID);
            if (empId == "") {

            }
            if (empId == "") {
                empId = IdentityManager.getUserProperty(Constants.Preferred_UserName);
            }
            let shiper = StoreManager.getStorePreFixByStoreId(selectedStore.StoreId) + selectedStore.StoreId;
            let consignee = PFUtils.getConsigneeData(toAddress, billToAddress, iskcdc);
            let pack: PACKAGE = {
                WEIGHT: boxDetail.weight,
                DIMENSION: boxDetail.length + "x" + boxDetail.width + "x" + boxDetail.height,
                PROOF_FLAG: "0",
                PROOF_SIGNATURE_FLAG: "0",
                SATURDAYDELIVERY_FLAG: "0",
            }
            let payload: PAYLOAD = {
                APP_ID: "Store Fulfillment",
                EMPLOYEE_ID: empId,
                SHIPPER: shiper,
                SHIPPER_SHIPMENT_REFERENCE: OrderNo,
                CONSIGNEE_CONTACT: consignee.CONSIGNEE_CONTACT,
                CONSIGNEE_COMPANY: consignee.CONSIGNEE_COMPANY,
                CONSIGNEE_ADDRESS1: consignee.CONSIGNEE_ADDRESS1,
                CONSIGNEE_ADDRESS2: consignee.CONSIGNEE_ADDRESS2,
                CONSIGNEE_ADDRESS3: consignee.CONSIGNEE_ADDRESS3,
                CONSIGNEE_CITY: consignee.CONSIGNEE_CITY,
                CONSIGNEE_POSTALCODE: consignee.CONSIGNEE_POSTALCODE,
                CONSIGNEE_STATE: consignee.CONSIGNEE_STATE,
                CONSIGNEE_COUNTRYID: consignee.CONSIGNEE_COUNTRYID,
                CONSIGNEE_PHONE: consignee.CONSIGNEE_PHONE,
                RETURN_COMPANY: "Guitar Center " + selectedStore.City,
                RETURN_CONTACT: "Store " + selectedStore.StoreId,
                RETURN_ADDRESS1: selectedStore.Address,
                RETURN_ADDRESS2: selectedStore.Address1,
                RETURN_CITY: selectedStore.City,
                RETURN_STATE: selectedStore.State,
                RETURN_POSTALCODE: selectedStore.Zip,
                RETURN_PHONE: selectedStore.Phone,
                SHIPDATE: _date,
                SHIPMENTHOLD_FLAG: "0",
                CONSIGNEE_RESIDENTIAL_FLAG: isResidential ? "1" : "0",
                PACKAGES: [pack],
                CARRIER: "",
                SERVICE: LOS,
                REASON: "WebOrder",
                WEIGHT_UNIT: "lb",
                ShipmentKey: ShipmentKey,
                ShipmentLineKey: ShipmentLineKey,
                OrderLineKey: OrderLineKey,
            }
            let createShipment: ICreateShipmentPayload = {
                ACTION: "CREATE_SHIPMENT",
                PAYLOAD: payload
            }

            return { success: true, msg: "", data: createShipment }
        }
        catch {
            return { success: false, msg: "Error While Parsing Data" }
        }

    }
    public static getConsigneeData(toAddress: ToAddress, billToAddress: BillToAddress, isKcdc: boolean) {
        let consignee = {
            CONSIGNEE_CONTACT: "",
            CONSIGNEE_COMPANY: "",
            CONSIGNEE_ADDRESS1: "",
            CONSIGNEE_ADDRESS2: "",
            CONSIGNEE_ADDRESS3: "",
            CONSIGNEE_CITY: "",
            CONSIGNEE_POSTALCODE: "",
            CONSIGNEE_STATE: "",
            CONSIGNEE_COUNTRYID: "",
            CONSIGNEE_PHONE: "",
        }
        let consigneeCompanyDecision = isKcdc ? "Guitar Center" : "------"
        consignee.CONSIGNEE_CONTACT = toAddress.FirstName + " " + toAddress.LastName;
        consignee.CONSIGNEE_COMPANY = !toAddress.Company || toAddress.Company.length == 0 ? consigneeCompanyDecision : toAddress.Company;
        consignee.CONSIGNEE_ADDRESS1 = toAddress.AddressLine1;
        consignee.CONSIGNEE_ADDRESS2 = toAddress.AddressLine2;
        consignee.CONSIGNEE_CITY = toAddress.City;
        consignee.CONSIGNEE_POSTALCODE = toAddress.ZipCode;
        consignee.CONSIGNEE_STATE = toAddress.State;
        consignee.CONSIGNEE_COUNTRYID = !toAddress.Country || toAddress.Country.length == 0 ? "US" : toAddress.Country;
        consignee.CONSIGNEE_PHONE = toAddress.MobilePhone
        if (consignee.CONSIGNEE_PHONE == "") {
            consignee.CONSIGNEE_PHONE = billToAddress.DayPhone
        }
        if (consignee.CONSIGNEE_PHONE == "") {
            consignee.CONSIGNEE_PHONE = billToAddress.MobilePhone
        }
        //Padding Zeroes at start.
        if(consignee.CONSIGNEE_PHONE!="" && consignee.CONSIGNEE_PHONE.length<10)
        {
            consignee.CONSIGNEE_PHONE = consignee.CONSIGNEE_PHONE.padStart(10,"0");
        }
        return consignee;
    }
    public static getLevelofServiceString(levelofService: string) {
        let los = levelofService.trim()
        let result = ""
        switch (los) {
            case 'ECONOMY GROUND':
                result = 'ECONOMY_GROUND';
                break;
            case 'NEXT DAY AIR':
                result = 'NEXT_DAY_AIR';
                break;
            case 'SECOND DAY EXPRESS':
                result = 'SECOND_DAY_EXPRESS';
                break;
            default:
                return 'ECONOMY_GROUND';
        }
        return result
    }
    public static getBody_Email_Data = (data: any, email: string, pdfData: any, trackingNo: string, storeCopy: boolean) => {
        let ad1 = data.shipToDetails.Address !== undefined && data.shipToDetails.Address !== null ? data.shipToDetails.Address : '';
        let ad2 = data.shipToDetails.Address2 !== undefined && data.shipToDetails.Address2 !== null ? data.shipToDetails.Address2 : '';
        let city = data.shipToDetails.City !== undefined && data.shipToDetails.City !== null ? data.shipToDetails.City : '';
        let id = data.shipToDetails.id !== undefined && data.shipToDetails.id !== null ? data.shipToDetails.id : '';

        let body: any = {};
        body.ReferenceNo = data.shipperRef;
        body.PDFData = pdfData;
        body.CustomerEmail = email;
        body.TrackingNo = trackingNo;
        body.Store = {
            Name: `${id} - ${city}`,
            Address: `${ad1}, ${ad2}`,
            City: city,
            State: data.shipToDetails.State !== undefined && data.shipToDetails.State !== null ? data.shipToDetails.State : '',
            ZIP: data.shipToDetails.Zip !== undefined && data.shipToDetails.Zip !== null ? data.shipToDetails.Zip : '',
            Phone: data.shipToDetails.Phone !== undefined && data.shipToDetails.Phone !== null ? data.shipToDetails.Phone : '',
            Email: storeCopy ? data.returnLabelData.store_email !== undefined && data.shipToDetails.store_email !== null ? data.shipToDetails.store_email : '' : ""
        }
        return body;
    }
}