import IUserSettings from "../../Models/IUserSettings";
import { SET_CANCEL_REASON, SET_STORES, SHOW_ACTIONLOADER,HIDE_ACTIONLOADER, SET_KCDC_TOTALQUANTITY, SET_ATTACHMENT_IMAGES, SET_DISTRICTS, SET_DRAWER_DROPDOWNS, SET_REGIONS, SET_ENTERPRISE, SET_USER_SETTINGS} from "../ActionTypes";
const showLoader = () => {
    return {
        type: SHOW_ACTIONLOADER
    };
}
const hideLoader = () => {
    return {
        type: HIDE_ACTIONLOADER
    };
}

const setCancelReason = (obj:any) => {
    return {
        type: SET_CANCEL_REASON,
        payLoad: obj
    };
}

const setStores = (obj:any) => {
    return {
        type: SET_STORES,
        payLoad: obj
    };
}

const setDistricts = (obj:any) => {
    return {
        type: SET_DISTRICTS,
        payLoad: obj
    };
}

const setKcdcTotalQuantity = (obj:any) =>{
    return {
        type: SET_KCDC_TOTALQUANTITY,
        payLoad: obj
    };
}

const setAttachmentImages = (obj:any) =>{
    return {
        type: SET_ATTACHMENT_IMAGES,
        payLoad: obj
    };
}

const setDrawerDropdowns = (obj:any)=>{
    return {
        type: SET_DRAWER_DROPDOWNS,
        payLoad: obj
    };
}

const setEnterprise = (obj:any)=>{
    return {
        type: SET_ENTERPRISE,
        payLoad: obj
    };
}

const setUserSettings = (settings:IUserSettings)=>{
    return {
        type: SET_USER_SETTINGS,
        payLoad: settings
    };
}

const setRegions = (obj:any) => {
    return {
        type: SET_REGIONS,
        payLoad: obj
    };
}

const actionLoaderActions = {
    showLoader,
    hideLoader,
    setCancelReason,
    setStores,
    setKcdcTotalQuantity,
    setAttachmentImages,
    setDistricts,
    setDrawerDropdowns,
    setRegions,
    setEnterprise,
    setUserSettings
}

export default actionLoaderActions;
