import { VIEW, CONTEXT, BAR_CODE_CAPTURE, BAR_CODE_TRACKING, CAMERA, BAR_CODE_TRACKING_OVERLAY, BAR_CODE_CAPTURE_OVERLAY } from "../ActionTypes";
import {
    DataCaptureView,
    Camera,
    DataCaptureContext,
} from "scandit-web-datacapture-core";
import type { BarcodeTrackingBasicOverlay, BarcodeTracking, BarcodeCapture, BarcodeCaptureOverlay } from "scandit-web-datacapture-barcode";
const initialState = {
    view: {} as DataCaptureView | undefined,
    context: {} as DataCaptureContext | undefined,
    barCodeCapture: {} as BarcodeCapture | undefined,
    barCodeTracking: {} as BarcodeTracking | undefined,
    camera: {} as Camera | undefined,
    barCodeTrackingOverlay: {} as BarcodeTrackingBasicOverlay | undefined,
    barCodeCaptureOverlay: {} as BarcodeCaptureOverlay | undefined
}
const ScandItReducers = (state: any = initialState,
    action: { type: any; payLoad: any }) => {

    switch (action.type) {
        case VIEW:
            return {
                ...state,
                view: action.payLoad
            }

        case CONTEXT:
            return {
                ...state,
                context: action.payLoad
            }
        case BAR_CODE_CAPTURE:
            return {
                ...state,
                barCodeCapture: action.payLoad
            }
        case BAR_CODE_TRACKING:
            return {
                ...state,
                barCodeTracking: action.payLoad
            }
        case CAMERA:
            return {
                ...state,
                camera: action.payLoad
            }

        case BAR_CODE_TRACKING_OVERLAY:
            return {
                ...state,
                barCodeTrackingOverlay: action.payLoad
            }
        case BAR_CODE_CAPTURE_OVERLAY:
            return {
                ...state,
                barCodeCaptureOverlay: action.payLoad
            }
    }
    return state;
}

export default ScandItReducers;