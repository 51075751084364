import React, { useState } from "react";
import "./ContactCustomer.scss";
import {
    Grid,
    TextField,
    Typography,
} from "@mui/material";
export const Note: React.FC<any> = (props) => {
    return (
        <div style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={12} className='inputField'>
                <Typography>
                    Note*
                    </Typography>
                <TextField onChange={(event) => props.changeNote(event)} style={{ height: 180, textAlign: "left" }} fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Note' variant="outlined" value={props.note} multiline />
            </Grid>
        </div>
    );
};
