import { IconButton, Menu, MenuItem, Divider } from "@mui/material";
import React from "react";
import Utils from "../../Common/Utils";
import "./NavBarUplift.scss";
import {
  AccountCircleOutlinedIcon,
  AddLocationAltIcon,
  LogoutIcon,
} from "../../Subcomponent/Shared/SharedIcons";
import { useNavigate,useLocation } from "react-router-dom";
import IdentityManager from "../../Managers/Identity.manager";
import AuthManager from "../../Managers/Auth.manager";
import SearchBar from "./SeachBar";
import { Typography } from "@mui/material";
import { NotificationPopup } from "../../Subcomponent/Shared/NotificationPopup";
import { StoreListDropDown } from "./StoreListDropDown";
import { CloseIcon } from "../../Subcomponent/Shared/SharedIcons";
import {
  INotificationPopupSettings,
  IButton,
} from "../../Models/INotificationPopupSettings";
import Constants from "../../Common/Constants";
import { DistrictListDropDown } from "./DistrictListDropDown";
import { useDispatch } from "react-redux";
import allActions from "../../Store/Actions/AllActions";
import { DrawerDropdowns } from "./DrawerDropdowns";
// import tooltip
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";

const WebAppbar = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [anchorEl, setAnchorUserProfileEl] = React.useState<null | HTMLElement>(
    null
  );
  const user = IdentityManager.getUserProperty("name");
  const displayName = IdentityManager.getUserProperty("displayName");
  const [openStoreChagnePopup, setOpenStoreChagnePopup] = React.useState(false);
  const [districtPopup, setDistrictPopup] = React.useState(false);
  const [openDrawer, setDrawer] = React.useState(false);
  const handleMenuOpen = (event: any) => {
    setAnchorUserProfileEl(event.currentTarget);
  };
  const openStorePopup = () => {
    setOpenStoreChagnePopup(true);
  };

  const closeStorePopup = () => {
    setOpenStoreChagnePopup(false);
  };
  const openDistrictPopup = () => {
    setDistrictPopup(true);
  };
  const closeDistrictPopup = () => {
    setDistrictPopup(false);
  };

  const handleMenuClose = () => setAnchorUserProfileEl(null);
  const [age, setAge] = React.useState("");

  const handleChange = (event: any) => {
    setAge(event.target.value);
  };
  const toStart = (e: any) => {
    props.setSelectedOption("");
    navigate("/");
  };
  const renderSearch = () => {
    return <SearchBar />;
  };

  const useStyles = makeStyles((theme) => ({
    tooltip: {
      maxWidth: 400,
      overflowY: "auto",
      maxHeight: 200,
    },
  }));

  const classes = useStyles();

  // Custom HTML tooltip
  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      PopperProps={{ style: { marginTop: 0 } }}
      {...props}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#ffffff",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 200,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
      marginTop: "0px",
    },
  }));

  // Selected Stores data that needs to send in tooltip
  const getSelectedStore = () => {
    if (!props.store.length) {
      return <p>Please Select any store </p>;
    }

    const listItems = props.store.map((val: any) => (
      <li>
        {val.districtId} - {val.name}
      </li>
    ));

    return (
      <React.Fragment>
        <ol style={{ listStyleType: "none" }} className={classes.tooltip}>
          {listItems}
        </ol>
      </React.Fragment>
    );
  };

  let changeStorePopupButtons: IButton[] = [
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeStorePopup,
      color: Constants.Colors.red,
    },
  ];
  let changeStore: INotificationPopupSettings = {
    open: openStoreChagnePopup,
    type: Constants.NotificationPopupType.Info,
    title: "Change Store",
    actions: changeStorePopupButtons,
    handleClose: closeStorePopup,
    isCustomJSX: true,
    msg: "",
    customJSX: (
      <StoreListDropDown
        store={props.store}
        changeStoreList={props.changeStoreList}
      ></StoreListDropDown>
    ),
    //reason: reason,
    //description: description,
  };
  let changeDistrictPopupButtons: IButton[] = [
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeDistrictPopup,
      color: Constants.Colors.red,
    },
  ];
  let changeDistrict: INotificationPopupSettings = {
    open: districtPopup,
    type: Constants.NotificationPopupType.Info,
    title: "Change District",
    actions: changeDistrictPopupButtons,
    handleClose: closeDistrictPopup,
    isCustomJSX: true,
    msg: "",
    customJSX: (
      <DistrictListDropDown
        district={props.district}
        changeDistrict={props.changeDistrict}
      ></DistrictListDropDown>
    ),
  };

  const renderStoreList = () => {
    return (
      <React.Fragment>
        {!Utils.isMobile ? (
          <StoreListDropDown
            store={props.store}
            changeStoreList={props.changeStoreList}
          ></StoreListDropDown>
        ) : (
          <></>
        )}
      </React.Fragment>
    );
  };

  const renderChangeDistrict = () => {
    return (
      // <Tooltip title={Constants.MESSAGES.SELECT_DISTRICT}>
      <div className={"profileContainer"}>
        <div className={"profileName"}>
          <Typography
            title={Constants.MESSAGES.SELECT_DISTRICT}
            style={{ marginTop: 1, marginLeft: 5, cursor: "pointer" }}
            onClick={openDistrictPopup}
          >
            <u>Choose District</u>
          </Typography>
        </div>
      </div>
    );
    // </Tooltip>)
    // <Tooltip title="hello">
    // <Typography style={{marginTop: 22,marginLeft:5,cursor:"pointer"}} onClick={openDistrictPopup}><u>Choose District</u></Typography>
    // </Tooltip>
  };
  const openDrawerDropdowns = () => {
    dispatch(allActions.actionLoaderActions.setDrawerDropdowns(true));
  };

  const displayDrawer = () => {
    return (
      <>
        <div
          style={{
            textAlign: "center",
            marginTop: "5px",
            cursor: "pointer",
          }}
          onClick={openDrawerDropdowns}
        >
          <div>
            <AddLocationAltIcon style={{ fontSize: "28px" }} />
          </div>
          <div>
            <Typography style={{ fontSize: "12px" }}>
              {props.store.length == 1
                ? `${props.store[0].id}-${props.store[0].name}`
                : "Choose Stores"}
            </Typography>
          </div>
        </div>
      </>
    );
  };

  const getTitle = () => {
    let pathname:any = location?.pathname ?? "";
    let route:any = Constants.RouteNames.find(routeObj => Object.keys(routeObj)[0] === pathname);
    return route ? route[pathname]: "";
  }

  const renderWebBarTitle = () => {
    return (
      <div style={{ display: "flex"}}>
        <div
          className={
            !props.compactNavbarToggle
              ? "logo-Container"
              : props.compactNavbarToggle || props.compactNavbarWidth
              ? "compact-logo-container"
              : "logo-Container"
          }
          onClick={(e) => {
            toStart(e);
          }}
        >
          {!props.compactNavbarToggle? <Typography style={{color:"white",fontSize:"20px",marginLeft:"35px"}}>Store Fulfillment</Typography>
           : props.compactNavbarToggle || props.compactNavbarWidth ? <></> : <Typography style={{color:"white",fontSize:"20px",marginLeft:"35px"}}>Store Fulfillment</Typography>}
        </div>
        {!Utils.isMobile && !props.loading && (
          <div
            onClick={props.openStoreInformation}
            className="storeDiv"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <div className="storeContainer">
              <div className="storeTitle">{getTitle()}</div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderWebbarProfile = () => {
    {
    }
    return (
      user &&
      !props.loading && (
        <div className={"profileContainer"}>
          <div className={"profileMenu"}>
            <IconButton
              color="inherit"
              id="iconButton"
              onClick={handleMenuOpen}
            >
              <AccountCircleOutlinedIcon style={{ fontSize: "20px" }} />
            </IconButton>
            <Menu
              PaperProps={{
                style: {
                  width: "230px",
                  borderRadius: "16px",
                },
              }}
              key={Utils.generateRandom(
                10,
                Constants.CryptoRandomTypes.numeric
              )}
              open={open}
              keepMounted
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleMenuClose}
            >
              <MenuItem
                className="dropdownMenuItem"
                onClick={() => props.setuserProfileDialog(true)}
                key={Utils.generateRandom(
                  10,
                  Constants.CryptoRandomTypes.numeric
                )}
              >
                Profile
                <AccountCircleOutlinedIcon style={{ fontSize: "20px" }} />
              </MenuItem>
              <Divider />
              {/* {IdentityManager.isAdmin ? (
                <MenuItem
                  className="dropdownMenuItem"
                  key={Utils.generateRandom(
                    10,
                    Constants.CryptoRandomTypes.numeric
                  )}
                  onClick={openStorePopup}
                >
                  <span>Choose Store</span>
                  <AddLocationAltIcon style={{ fontSize: "20px" }} />
                </MenuItem>
              ) : (
                ""
              )}
              <Divider /> */}
              <MenuItem
                className="dropdownMenuItem"
                key={Utils.generateRandom(
                  10,
                  Constants.CryptoRandomTypes.numeric
                )}
                onClick={logout}
              >
                Sign Out
                <LogoutIcon style={{ fontSize: "20px" }} />
              </MenuItem>
            </Menu>
          </div>
        </div>
      )
    );
  };
  const webNavBar = () => {
    return (
      <React.Fragment>
        <NotificationPopup {...changeStore}></NotificationPopup>
        <NotificationPopup {...changeDistrict}></NotificationPopup>
        {/* <div style={{display:"flex",marginLeft:"20px"}}>
                    {Utils.isExternalRoute() || !IdentityManager.isAdmin ? "" : renderStoreList()}
                    {IdentityManager.isAdmin && renderChangeDistrict()}
                </div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: "#F9F9F9",
            width: "100%",
          }}
        >
          {renderWebBarTitle()}
          <div
            style={{
              display: "flex",
              gap: "16px",
              paddingRight: "15px",
            }}
          >
          {IdentityManager.showDrawerDropdown && displayDrawer()}
            {Utils.isExternalRoute() ? "" : renderSearch()}
            {Utils.isExternalRoute() ? "" : renderWebbarProfile()}
          </div>
        </div>
        <DrawerDropdowns
          region={props.region}
          changeRegion={props.changeRegion}
          district={props.district}
          changeDistrict={props.changeDistrict}
          store={props.store}
          changeStoreList={props.changeStoreList}
        ></DrawerDropdowns>
      </React.Fragment>
    );
  };
  const logout = () => AuthManager.logout();
  const open = Boolean(anchorEl);
  return <React.Fragment>{webNavBar()}</React.Fragment>;
};
export default WebAppbar;
