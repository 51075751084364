
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Chip, Divider, Drawer, Grid, Paper, SwipeableDrawer, Toolbar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import Utils from '../Common/Utils';
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { DoneIcon, CloseIcon, ArrowBackIcon, LocationOnIcon, ReportIcon, PersonIcon, AddLocationAltIcon, EmailIcon, LocalPhoneIcon, ReceiptLongIcon, PlaylistAddCheck, ArrowForwardIcon, ReportProblemIcon, ArrowBackIosNewOutlinedIcon, ReportOutlinedIcon, DownloadingOutlinedIcon, ExpandMoreIcon } from "../Subcomponent/Shared/SharedIcons"
import { NotificationPopup } from '../Subcomponent/Shared/NotificationPopup'
import Constants from '../Common/Constants';
import { INotificationPopupSettings, IButton } from '../Models/INotificationPopupSettings';
import LabelText from "../Common/LabelText";
import SiteBreadCrumbs from '../Subcomponent/Shared/SiteBreadCrumbs';
import PickSlip from '../Subcomponent/FulfillOrders/PickSlip';
import { StatusApproval } from "../Subcomponent/PendingReceipt/StatusApproval";
import OrderLineShimmer from "../Subcomponent/PickOrder/OrderLineShimmer"
import OrderLineShimmerMobile from "../Subcomponent/PickOrder/OrderLineShimmerMobile"
import CustomerDetailShimmer from "../Subcomponent/PickOrder/CustomerDetailShimmer"
import CustomerDetailShimmerMobile from "../Subcomponent/PickOrder/CustomerDetailShimmerMobile"
import StoreManager from "../Managers/Store.manager";
import { IReceiveOrderDetail, ContainerDetail } from '../Models/IReceiveOrderDetail';
import OMSManager from "../Managers/Oms.manager";
import allActions from '../Store/Actions/AllActions';
import { ReceivePrintSlip } from "../Subcomponent/ReceiveOrders/ReceivePrintSlip";
import ApiService from '../Common/ApiService';
import IdentityManager from '../Managers/Identity.manager';
import OrderHistory from '../Subcomponent/Shared/OrderHistory';
import AddressDetailsShimmerMobile from '../Subcomponent/PickOrder/AddressDetailsShimmerMobile';
import BulletShimmer from '../Subcomponent/Shared/BulletShimmer';
import OrderManager from '../Managers/Orders.Manager';
import { IImageViewerDetail, IImageViewerPopupSettings } from '../Models/IImageViewerPopupSettings';
import { ImageViewerPopup } from '../Subcomponent/Shared/ImageViewerPopup';
import { ReportErrorPopup } from '../Subcomponent/Shared/ReportErrorPopup';
import "./PendingReceipt.scss"
import { ServiceNowCnfrmText } from '../Subcomponent/Shared/ServiceNowCnfrmText';

var moment = require('moment');
const PendingReceipt = (props: any) => {
    const [openDrawer, setDrawer] = useState(false);
    const [openAddressDrawer, setAddressDrawer] = useState(false);
    const [openHistoryDrawer, setHistoryDrawer] = useState(false);
    const [isConfirmPopup, setIsConfirmPopup] = useState(false);
    const [loadingOrderLines, setLoadingOrderLines] = useState(true);
    const [isConfirmProblemPopup, setIsConfirmProblemPopup] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [showPickupSlip, setShowPickupSlip] = useState(0);
    const [errorMsg, setErrorMsg] = useState("");
    const queryString = window.location.search;
    const pathname = window.location.pathname;
    const urlParams = new URLSearchParams(queryString);
    const shipmentKey = urlParams.get('shipmentkey');
    const orderno = urlParams.get('orderno');
    const queryParamStore = urlParams.get('store');
    const [openSuccess, setOpenSuccess] = useState(false);
    const shipmentContainerKey = urlParams.get('shipmentcontainerkey');
    const [navigationReceiveOrders, setNavigationReceiveOrders] = useState(false);
    let orderDetail: IReceiveOrderDetail = useSelector((state: any) => state.ReceiveOrdersReducersState.receiveOrderDetail);
    const isHistoryLoading = useSelector((state: any) => state.OrderHistoryReducerState.isLoading);
    const [imagePopUp, setImagePopUp] = useState(false);
    const [imageData, setImageData] = useState({} as IImageViewerDetail);

    //Report Error Action Use State
    const [reportErrorAction, setReportErrorAction] = useState("");

    // Report Error Pop Up Use States
    const [openReportError, setOpenReportError] = useState(false);
    const [openAlreadyReportedError, setOpenAlreadyReportedError] = useState(false);
    const [openConfirmReportError, setOpenConfirmReportError] = useState(false);
    const [errorReportMsg, setErrorReportMsg] = useState("");

    const openLoadingOrderLines = () => {
        setLoadingOrderLines(true)
    }
    const closeLoadingOrderLines = () => {
        setLoadingOrderLines(false)
    }
    const closeErrorPopup = () => {
        setOpenError(false)
    }
    const openErrorPopup = () => {
        setOpenError(true)
    }
    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.primary.main
        }
    }));
    const classes = useStyles();
    const theme = createTheme({
        palette: {
            primary: {
                // main: window.innerWidth > 1023 ? Constants.COLORS.DESKTOP_RED: Constants.COLORS.MOBILE_RED,
                // main: '#da0c0c',
                main: '#D93A2F',
            },
            secondary: {
                main: '#FFFFFF',
            },
        },
    })
    console.log(classes.root, "asas");
    const TestState = useSelector((state: any) => state.TestReducerState);
    console.log(TestState, "TestState");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    window.scrollTo(0, 0);

    // Scroll
    var startProductBarPos = -1;
    window.onscroll = function () {
        console.log("scrolled");
        var bar = document.getElementById('bar') as any;
        var mblBar = document.getElementById('mblBar') as any;
        if (bar) {

            if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);

            if (window.pageYOffset > startProductBarPos) {

                bar.classList.add("scrolledTopFix");

            } else {
                bar.classList.remove("scrolledTopFix");
            }
        }
    };
    function findPosY(obj: any) {
        var curtop = 0;
        if (obj && typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
            while (obj.offsetParent) {
                curtop += obj.offsetTop;
                obj = obj.offsetParent;
            }
            curtop += obj.offsetTop;
        }
        else if (obj && obj.y)
            curtop += obj.y;
        return curtop;
    }
    const closeConform = () => {
        setIsConfirmPopup(false)
    }
    const openConform = () => {
        setIsConfirmPopup(true)
    }
    const navigateToReceiveOrders = () => {
        navigate("/ReceiveOrders");
    }
    const navigateToFulfillOrders = () => {
        navigate("/FulfillOrders");
    }
    const openSuccessPopup = () => {
        setOpenSuccess(true)
    }
    const closeSuccessPopup = () => {
        setOpenSuccess(false)
    }

    const closeConfirmProblem = () => {
        setIsConfirmProblemPopup(false)
    }
    const openConfirmProblem = () => {
        setIsConfirmProblemPopup(true)
    }
    const popUpBack = () => {
        navigate(-1)
    }

    // Popups

    // Show Pop ups If Error is already reported
    const handleErrorAlreadyReported = () => {
        // setOpenAlreadyReportedError(true);
        setOpenReportError(false);
    }

    // OPEN Confirm Report Error
    const reportConfirmErrorPopup = () => {
        setOpenConfirmReportError(true);
        setOpenError(false);

    }

    // CLOSE Report Error POP up
    const closeErrorReportPopup = () => {
        setOpenReportError(false)
    }

    // Report Error Pop up
    let ReportError: INotificationPopupSettings = {
        open: openReportError,
        type: Constants.NotificationPopupType.Conformation,
        title: "Report Error",
        msg: "",
        isCustomJSX: true,
        customJSX: <ReportErrorPopup handleErrorAlreadyReported={handleErrorAlreadyReported} errorMessage={errorReportMsg} closeErrorReportPopup={closeErrorReportPopup} urlPathname={pathname} reportErrorAction={reportErrorAction}></ReportErrorPopup>,
        draggable: false,
        handleClose: closeErrorReportPopup,
        actions: [],
        fullWidth: true,
        maxWidth: "lg"
    }

    // OPEN Confirm Report Error POPup
    const reportErrorPopup = () => {
        setOpenReportError(true);
        setOpenConfirmReportError(false)
    }

    // CLOSE Confirm Report Error
    const closeConfirmReportError = () => {
        setOpenConfirmReportError(false);
    }
    let confirmReportErrorPopupButtons: IButton[] = [
        {
            text: "Continue",
            icon: <ArrowForwardIcon />,
            action: reportErrorPopup,
            color: Constants.Colors.red,
        }
    ];
    let confirmReportError: INotificationPopupSettings = {
        open: openConfirmReportError,
        type: Constants.NotificationPopupType.Conformation,
        title: Constants.MESSAGES.SN_CNFRM_TITLE,
        msg: "",
        customJSX: <ServiceNowCnfrmText></ServiceNowCnfrmText>,
        isCustomJSX: true,
        draggable: false,
        handleClose: closeConfirmReportError,
        actions: confirmReportErrorPopupButtons
    }


    // Error Popup

    // Buttons When there is no Action
    let errorPopupCloseButtons: IButton[] = [{
        text: "Close",
        icon: <CloseIcon />,
        action: closeErrorPopup,
        color: Constants.Colors.red,
    }];

    // Buttons When there is Action
    let errorPopupButtons: IButton[] = [
        {
            text: "Report Error",
            icon: <ReportProblemIcon />,
            action: reportConfirmErrorPopup,
            color: Constants.Colors.orange,
        },
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeErrorPopup,
            color: Constants.Colors.red,
        }
    ];

    if (Utils.getConfigurationWithKey(Constants.Configurations.ReportErrorSNFlag) === false) {
        errorPopupButtons.shift()
    };
    let Error: INotificationPopupSettings = {
        open: openError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorMsg,
        draggable: false,
        handleClose: closeErrorPopup,
        actions: reportErrorAction ? errorPopupButtons : errorPopupCloseButtons
    }


    const openNavigationReceiveOrders = () => {
        setNavigationReceiveOrders(true);
    }

    let navigateToReceiveOrdersButtons: IButton[] = [
        {
            text: "To Pending Receipts List",
            icon: <DoneIcon />,
            action: navigateToReceiveOrders,
            color: Constants.Colors.red,
        }
    ];

    let navigationToReceiveOrders: INotificationPopupSettings = {
        open: navigationReceiveOrders,
        type: Constants.NotificationPopupType.Info,
        title: "",
        msg: "This Order doesn't belong to selected Store.",
        draggable: false,
        handleClose: navigateToReceiveOrders,
        actions: navigateToReceiveOrdersButtons
    }

    const getReceiveDetailFromOms = async () => {
        let result = [] as any
        openLoadingOrderLines();
        //
        try {
            result = await OMSManager.getReceiveOrdersDetail(shipmentKey, shipmentContainerKey)
        }
        catch {
            //
            setErrorMsg("Something went wrong please contact the IT Help Desk for assistance");
            setErrorReportMsg("Something went wrong please contact the IT Help Desk for assistance")
            setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PENDING_RECEIPT_RECEIVE_DETAILS_OMS);
            closeLoadingOrderLines();
            openErrorPopup();
        }
        //ContainerDetail       
        //
        if (result.success == true) {
            let data: IReceiveOrderDetail = result.data
            if (data.ContainerDetail.length > 0) {
                dispatch(allActions.receiveOrdersActions.changeReceiveOrderDetail(data));
                closeLoadingOrderLines();
            }
            else {
                setErrorMsg("Something went wrong please contact the IT Help Desk for assistance");
                setErrorReportMsg("Something went wrong please contact the IT Help Desk for assistance");
                setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PENDING_RECEIPT_RECEIVE_DETAILS_OMS);
                openErrorPopup();
            }
        }
        else {
            setErrorMsg("Something went wrong please contact the IT Help Desk for assistance");
            setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PENDING_RECEIPT_RECEIVE_DETAILS_OMS);
            setErrorReportMsg("Something went wrong please contact the IT Help Desk for assistance");
            openErrorPopup();
        }
    }
    const getServiceCode = (service: any) => {
        switch (service) {
            case 'NEXT DAY AIR':
                return 'red';

            case 'SECOND DAY EXPRESS':
                return 'green';

            case 'ECONOMY GROUND':
                return "blue"
            case 'STANDARD GROUND':
                return 'blue'
            case 'EMPLOYEE PICKUP':
                return 'blue'
            case 'DIGITAL DELIVERY':
                return 'blue'
            case 'STANDARD INTL':
                return 'blue'
            default:
                return 'blue';
        }
    }
    const getPayload = () => {
        let storeId = IdentityManager.getStoreId(props.store);
        let result: IReceiveOrderDetail = JSON.parse(JSON.stringify(orderDetail));
        let containerDetailPayload = [] as any
        result.LoginID = OMSManager.GetUserIdFromLocalStorage();
        result.EmployeeID = OMSManager.GetEmployeeId();
        result.ShipmentKey = shipmentKey + "";
        result.StoreID = storeId;
        result.ShipmentContainerKey = shipmentContainerKey + "";
        result.IsManagerApproval = "N";
        delete result.BillToAddress;
        delete result.PaymentMethods;
        delete result.ToAddress;
        delete result.PhoneNo;
        delete result.DeliveryMethod;
        delete result.ShipDate;
        delete result.LastName
        result.ContainerDetail.forEach((containerLines: ContainerDetail) => {
            if (containerLines.hasOwnProperty("WarrantyComponent")) {
                let WarrantyComponent = {
                    "WarrantyComponent": {
                        "ShipmentLineKey": containerLines?.WarrantyComponent?.ShipmentLineKey,
                        "Quantity": Number(containerLines?.WarrantyComponent?.Quantity),
                        "OrderLineKey": containerLines?.WarrantyComponent?.OrderLineKey,
                        "OrderReleaseKey": containerLines?.WarrantyComponent?.OrderReleaseKey
                    } as any
                }
                if (containerLines?.WarrantyComponent?.hasOwnProperty("ParentShipmentLineKey")) {
                    WarrantyComponent.WarrantyComponent.ParentShipmentLineKey = containerLines?.WarrantyComponent?.ParentShipmentLineKey
                }
                containerDetailPayload.push(WarrantyComponent)
            }
            else {
                let itemDetail = {
                    "ItemID": containerLines.ItemID,
                    "OrderLineKey": containerLines.OrderLineKey,
                    "OrderReleaseKey": containerLines.OrderReleaseKey,
                    "Quantity": Number(containerLines.Quantity),
                    "SerialNumber": containerLines.SerialNumber,
                    "ShipmentLineKey": containerLines.ShipmentLineKey
                } as any
                if (containerLines.hasOwnProperty("SetComponent")) {
                    itemDetail.SetComponent = containerLines.SetComponent
                }
                if (containerLines.hasOwnProperty("ParentShipmentLineKey")) {
                    itemDetail.ParentShipmentLineKey = containerLines.ParentShipmentLineKey
                }
                containerDetailPayload.push(itemDetail)
            }
        })
        result.ContainerDetail = containerDetailPayload
        let payload = {
            "ContainerDetails": result
        }
        return payload
    }
    const problemWithCarton = () => {
        //alert("problemWithCarton")
        let payload = getPayload();
        payload.ContainerDetails.ExtnIsProblemWithCarton = "Y";
        //
        console.log(payload);
        showActionLoader();
        ApiService.post("/oms/GCReceiptCartonProblem", payload).then(async (response: any) => {
            //
            if (response.success == true) {
                await addBoxesDetails(payload);
                setShowPickupSlip(showPickupSlip + 1)
                closeConfirmProblem()
                hideActionLoader()
                navigateToReceiveOrders();
                //openSuccessPopup()
            }
            else {
                closeConfirmProblem()
                hideActionLoader()
                setErrorMsg("Something went wrong please contact the IT Help Desk for assistance");
                setErrorReportMsg("Something went wrong please contact the IT Help Desk for assistance");
                setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PENDING_RECEIPT_CARTON_PROBLEM);
                openErrorPopup();
            }
        }).catch(() => {
            closeConfirmProblem()
            hideActionLoader()
            setErrorMsg("Something went wrong please contact the IT Help Desk for assistance");
            setErrorReportMsg("Something went wrong please contact the IT Help Desk for assistance");
            setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PENDING_RECEIPT_CARTON_PROBLEM);
            openErrorPopup();
        })

    }
    const addBoxesDetails = async (payload: any) => {
        let orderDetail: any = {};
        await ApiService.get(`/pf/getOrderFromExStore?shipmentKey=` + shipmentKey).then((response: any) => {
            orderDetail = response.data.hits.hits.length > 0 ? response.data.hits.hits[0]._source.Shipment : {};
        })

        if (payload?.ContainerDetails?.ShipNode == "MFI") {
            let shipmentArr: any = []
            payload?.ContainerDetails?.ContainerDetail.forEach((detail: any) => {
                if (detail.WarrantyComponent == undefined) {
                    let conDetailObj = {
                        identifier: payload?.ContainerDetails?.TrackingNo,
                        boxNumber: 1,
                        FulfillmentType: "Pending_Receipt", // hardcoded not showing in ui
                        ItemID: Utils.getEIDUsingShipmentLineKeyFromPendingReceipt(detail.OrderLineKey, orderDetail.ShipmentLines.ShipmentLine),
                        ExtnPOSItemID: detail.ItemID ? detail.ItemID : "",
                        ItemDesc: "" // sending empty
                    }
                    shipmentArr.push(conDetailObj)
                }
            })
            await OrderManager.saveOrderHistory({
                orderNo: orderno,
                shipmentKey: shipmentKey,
                logType: Constants.boxNumberHistory,
                boxNumberList: JSON.stringify(shipmentArr)
            });
        }
    }
    const conformReceive = () => {
        //alert("conformReceive")
        let payload = getPayload();
        //
        console.log(payload);
        showActionLoader();
        ApiService.post("/oms/GCReceiptConfirmCarton", payload).then(async (response: any) => {
            if (response.success == true) {
                OrderManager.saveOrderHistory({
                    shipmentKey: shipmentKey,
                    logType: "history",
                    transactionType: Constants.OrderHistoryTypes.Pending_Receive,
                    message: "",
                    transactionDate: moment.utc(moment.tz("America/Los_Angeles")).toISOString()
                });
                await addBoxesDetails(payload);
                setShowPickupSlip(showPickupSlip + 1)
                closeConform()
                hideActionLoader()
                navigateToReceiveOrders();
                //openSuccessPopup()
            }
            else {
                let errorMsg = "";
                closeConform()
                hideActionLoader()
                if (!response.success && response.msg) {
                    errorMsg = response.msg;
                }
                else {
                    errorMsg = "Something went wrong please contact the IT Help Desk for assistance";
                }
                setErrorMsg(errorMsg);
                setErrorReportMsg(errorMsg);
                setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PENDING_RECEIPT_CNFRM_RECEIVE);
                openErrorPopup();
            }
        }).catch(() => {
            closeConform()
            hideActionLoader()
            setErrorMsg("Something went wrong please contact the IT Help Desk for assistance");
            setErrorReportMsg("Something went wrong please contact the IT Help Desk for assistance");
            setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PENDING_RECEIPT_CNFRM_RECEIVE);
            openErrorPopup();
        })
    }
    let successPopupButtons: IButton[] = [
        {
            text: Utils.isMobile ? "Fulfillment" : "Back to Fulfillment",
            icon: <PlaylistAddCheck />,
            action: navigateToFulfillOrders,
            color: Constants.Colors.red,
        },
        {
            text: Utils.isMobile ? "Pending Receipt" : "Back to Pending Receipt",
            icon: <ReceiptLongIcon />,
            action: navigateToReceiveOrders,
            color: Constants.Colors.green,
        }
    ]
    let successPopup: INotificationPopupSettings = {
        open: openSuccess,
        type: Constants.NotificationPopupType.Success,
        title: "Success",
        msg: "Order Received Successfully",
        handleClose: closeSuccessPopup,
        isCustomJSX: false,
        actions: successPopupButtons,
    }
    let confirmPopupButtons: IButton[] = [{
        text: "Receive",
        icon: <DoneIcon />,
        action: conformReceive,
        color: Constants.Colors.green,
    },
    {
        text: "Cancel",
        icon: <CloseIcon />,
        action: closeConform,
        color: Constants.Colors.grey,
    }
    ]
    let confirmPopup: INotificationPopupSettings = {
        open: isConfirmPopup,
        type: Constants.NotificationPopupType.Success,
        title: "Receive",
        msg: "You are about to mark this order as Received",
        handleClose: closeConform,
        isCustomJSX: false,
        //customJSX: <StatusApproval></StatusApproval>,
        actions: confirmPopupButtons,
    }
    let confirmProblemPopupButtons: IButton[] = [{
        text: "Problem With Carton",
        icon: <DoneIcon />,
        action: problemWithCarton,
        color: Constants.Colors.red,
    },
    {
        text: "Cancel",
        icon: <CloseIcon />,
        action: closeConfirmProblem,
        color: Constants.Colors.grey,
    }
    ]
    let confirmProblemPopup: INotificationPopupSettings = {
        open: isConfirmProblemPopup,
        type: Constants.NotificationPopupType.Warning,
        title: "Problem With Carton",
        msg: "You are about to mark order with Problem With Carton",
        handleClose: closeConfirmProblem,
        isCustomJSX: false,
        //customJSX: <StatusApproval></StatusApproval>,
        actions: confirmProblemPopupButtons,
    }
    React.useEffect(() => {
        async function getReceiveDetail() {
            await getReceiveDetailFromOms()
        }
        getReceiveDetail()
        return () => {

        }

    }, []);

    React.useEffect(() => {
        if (queryParamStore && props.store?.length == 1 && props.store[0].id != queryParamStore) {
            openNavigationReceiveOrders();
        }
    }, [props.store]);

    const mobileView = () => {
        return (
            <div style={{ paddingBottom: "150px" }}>
                {/* <Paper id='bar' style={{ color: "white", top: 0, left: 0, right: 0, zIndex: 999, borderRadius: 0 }} className="appBarPrimary">
                        <Toolbar style={{ height: "46px", minHeight: "46px" }}>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                onClick={() => { navigate(-1) }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{ marginLeft: "-48px" }}>
                                Pending Receipt
                            </Typography>
                        </Toolbar>
                    </Paper> */}
                <Paper style={{ marginTop: 2, paddingTop: 1, paddingBottom: 1, background: "#F9F9F9" }}>
                    <Grid container style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                        <Grid item xs={12} sm={12} className='textLeft'>
                            <Grid container style={{ paddingLeft: "12px" }}>
                                <Grid item xs={3}>
                                    <Button onClick={() => { navigateToReceiveOrders() }} className="back-button" variant='contained' startIcon={<ArrowBackIosNewOutlinedIcon />}>Back</Button>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography className="medium-heading">Tracking #</Typography>
                                    <Typography className="large-heading" style={{ wordBreak: "break-all" }}>{orderDetail.TrackingNo}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {Utils.disableUserAction(props.store) && <Grid container spacing={2} style={{ marginBottom: "10px", padding: "10px" }}>
                        <Grid item xs={6} sm={6}>
                            <Button onClick={() => openConfirmProblem()} style={{ width: "100%" }} variant='contained' className='header-button' startIcon={<ReportOutlinedIcon />}>Carton Error</Button>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Button onClick={() => openConform()} style={{ width: "100%" }} variant='contained' className='header-button red' startIcon={<DownloadingOutlinedIcon />}>Receive</Button>
                        </Grid>
                    </Grid>}
                    <Accordion defaultExpanded style={{ margin: 5, padding: 10, borderRadius: 16, boxShadow: "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 2px rgb(0 0 0 / 12%)" }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ color: Constants.Colors.darkGrey, height: 80 }}
                        >
                            <Grid item display={"flex"} style={{ alignItems: "center" }}>
                                <Typography className="medium-heading" style={{ marginBottom: "4px" }}>Order #</Typography>
                                <Typography className="card-heading" style={{ marginLeft: "5px" }}>{orderDetail.OrderNo}</Typography>
                            </Grid>
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails  style={{ display: "block", padding: 0, minHeight: "250px", overflowY: "scroll" }}>
                            {
                                !loadingOrderLines ?
                                    <React.Fragment>
                                        {
                                            orderDetail.ContainerDetail.map((container: any, index: any) => {
                                                if (!container.hasOwnProperty("WarrantyComponent")) {
                                                    return (
                                                        <React.Fragment>
                                                            <Grid container display={"flex"} style={{ marginTop: "10px" }} className="justifyBetween productHeader">
                                                                <div>
                                                                    <Grid item xs={12} sm={12} spacing={2} container alignItems="center" justifyContent="space-between">
                                                                        <Grid item display={"flex"} style={{ marginTop: "8px" }}>
                                                                            <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                                                                Ordering Store #
                                                                            </Typography>
                                                                            <Typography variant="body2" sx={{ fontSize: 13, fontWeight: 500, marginLeft: 1 }}>
                                                                                {orderDetail.OrderingStore}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item display={"flex"} style={{ marginTop: "8px" }}>
                                                                            <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                                                                Shipped Qty
                                                                            </Typography>
                                                                            <Typography variant="body2" sx={{ fontSize: 13, fontWeight: 500, marginLeft: 1 }}>
                                                                                {container.Quantity}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </Grid>
                                                            <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                <Grid item xs={4} sm={4} style={{ padding: "8px" }}>
                                                                    <img style={{ height: "100px", cursor: "zoom-in" }} alt="" src={Utils.convertToHttps(container.ImageUrl ? container.ImageUrl : "")} onClick={() => { imageViewer(container) }} />
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} style={{ padding: "8px", textAlign: "left" }}>
                                                                    {renderProCov(orderDetail.ContainerDetail)}
                                                                    <Typography style={{ fontSize: "16px", fontWeight: 400 }}>{container.ItemDesc}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                            {/* <Grid con
                                                            tainer spacing={1} display={"flex"} key={index} style={{ margin: 0 }}>
                                                            <Grid item xs={12} sm={12} className='textLeft'>
                                                                <Grid container spacing={1} style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                    <Grid item xs={3} sm={3} style={{ padding: "8px", display: "flex", justifyContent: "center" }}>
                                                                        <div className="image-zoom" onClick={() => imageViewer(container)}>
                                                                            <img style={{ height: "auto", width: "100%" }} alt="" src={Utils.convertToHttps(container.ImageUrl)} />
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={9} sm={9} style={{ padding: "8px", textAlign: "left" }}>
                                                                            {renderProCov(orderDetail.ContainerDetail)}
                                                                            <Typography style={{ fontSize: "16px", fontWeight: 400 }}>{container.ItemDesc}</Typography>
                                                                            <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                                                                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                                                                    <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                                                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                            Item ID
                                                                                        </Typography>
                                                                                        <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                            <b>{container.ItemID}</b>
                                                                                        </Typography>
                                                                                    </div>
                                                                                    <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                                                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                            Brand
                                                                                        </Typography>
                                                                                        <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                            <b>{container.BrandName}</b>
                                                                                        </Typography>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            </Grid> */}
                                                            <div style={{ marginLeft: '1rem', marginBottom: '1rem', marginTop: '1rem' }}>
                                                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                                                    <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                            Item ID
                                                                        </Typography>
                                                                        <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                            <b>{container.ItemID}</b>
                                                                        </Typography>
                                                                    </div>
                                                                    <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                            Brand
                                                                        </Typography>
                                                                        <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                            <b>{container.BrandName}</b>
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </React.Fragment>
                                                    )
                                                }
                                            })
                                        }
                                    </React.Fragment>
                                    :
                                    <OrderLineShimmerMobile></OrderLineShimmerMobile>
                            }
                        </AccordionDetails>
                    </Accordion>

                    <Accordion defaultExpanded style={{ margin: 5, padding: 10, borderRadius: 16, boxShadow: "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 2px rgb(0 0 0 / 12%)" }}
                        sx={{
                            '&:before': {
                                display: 'none',
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            style={{ color: Constants.Colors.darkGrey, height: 80 }}

                        >
                            <Typography className="card-heading">Address Details</Typography>
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails style={{ display: "block", padding: 0, minHeight: "250px", overflowY: "scroll" }}>
                            {!loadingOrderLines ? <Grid container style={{ padding: 14, marginTop: 3 }}>
                                <Grid item xs={6} sm={6}>
                                    <Typography className="address-heading" style={{ marginBottom: 15 }}>
                                        Billing Address
                                    </Typography>
                                    <Typography className="address-value">
                                        {orderDetail?.BillToAddress?.FirstName} {orderDetail?.BillToAddress?.MiddleName} {orderDetail?.BillToAddress?.LastName}
                                    </Typography>
                                    <Typography className="address-value">
                                        {orderDetail?.BillToAddress?.AddressLine1}
                                        {
                                            orderDetail?.BillToAddress?.AddressLine2 == "" ?
                                                ""
                                                :
                                                "," + orderDetail?.BillToAddress?.AddressLine2
                                        }
                                    </Typography>
                                    <Typography className="address-value">
                                        {orderDetail?.BillToAddress?.City},{orderDetail?.BillToAddress?.State},{orderDetail?.BillToAddress?.ShortZipCode}
                                    </Typography>
                                    <Typography className="address-value">
                                        {orderDetail?.BillToAddress?.Country}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>

                                    <Typography className="address-heading" style={{ marginBottom: 15 }}>
                                        Shipping Address
                                    </Typography>
                                    <Typography className="address-value">
                                        {orderDetail?.ToAddress?.FirstName} {orderDetail?.ToAddress?.MiddleName} {orderDetail?.ToAddress?.LastName}
                                    </Typography>
                                    <Typography className="address-value">
                                        {orderDetail?.ToAddress?.Company}
                                    </Typography>
                                    <Typography className="address-value">
                                        {orderDetail?.ToAddress?.AddressLine1}
                                        {
                                            orderDetail?.ToAddress?.AddressLine2 == "" ?
                                                ""
                                                :
                                                "," + orderDetail?.ToAddress?.AddressLine2
                                        }
                                    </Typography>
                                    <Typography className="address-value">
                                        {orderDetail?.ToAddress?.City},{orderDetail?.ToAddress?.State},{orderDetail?.ToAddress?.ZipCode}
                                    </Typography>
                                    <Typography className="address-value">
                                        {orderDetail?.ToAddress?.Country}
                                    </Typography>
                                </Grid>
                            </Grid> :
                                <CustomerDetailShimmer></CustomerDetailShimmer>}

                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ margin: 5, padding: 10, borderRadius: 16, boxShadow: "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 2px rgb(0 0 0 / 12%)" }}
                        sx={{
                            '&:before': {
                                display: 'none',
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            style={{ color: Constants.Colors.darkGrey, height: 80 }}

                        >
                            <Typography className="card-heading">Customer Details</Typography>
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails style={{ display: "block", padding: 0, minHeight: "250px", overflowY: "scroll" }}>
                            {
                                !loadingOrderLines ?
                                    <Grid container spacing={2} style={{ display: "flex", flexDirection: "column", padding: "10px" }}>
                                        <Grid item xs={12} sm={12} className='textLeft' style={{display:"flex", alignItems:"center"}}>
                                            <PersonIcon style={{fontSize:"22px"}}/><Typography style={{ fontSize: "18px", fontWeight: 700, marginLeft: "10px", wordBreak: "break-all" }}>{orderDetail.FirstName} {orderDetail.LastName}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} className='textLeft' style={{ display:"flex", alignItems:"center", paddingTop: "8px", wordBreak: "break-all" }}>
                                            <EmailIcon style={{fontSize:"22px"}}/><Typography style={{ fontSize: "13px", fontWeight: 500, color: Constants.Colors.red, marginLeft: "10px" }}>{orderDetail.EMailID}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} className='textLeft' style={{ display:"flex", alignItems:"center", paddingTop: "8px" }}>
                                            <LocalPhoneIcon style={{fontSize:"22px"}}/><Typography style={{ fontSize: "13px", fontWeight: 500, color: Constants.Colors.red, marginLeft: "10px" }}>{orderDetail?.PhoneNo}</Typography>
                                        </Grid>
                                    </Grid>
                                    :
                                    <CustomerDetailShimmerMobile></CustomerDetailShimmerMobile>
                            }

                        </AccordionDetails>
                    </Accordion>
                    <OrderHistory store={props.store}></OrderHistory>
                </Paper>
                {/* <Card style={{ marginTop: "6px" }} id='cardOrder'>
                        <CardContent style={{ padding: "1px" }}>
                            <div className="backdrop">
                                <div className="title">
                                    <Typography>Order # {orderDetail.OrderNo}</Typography>
                                </div>

                                <div className="mainContainer" style={{ padding: "5px" }}>
                                    <Card style={{ margin: "6px" }} >
                                        <CardContent style={{ padding: "10px" }}>
                                            {
                                                orderDetail.ContainerDetail.map((container) => {
                                                    if (!container.hasOwnProperty("WarrantyComponent")) {
                                                        return (
                                                            <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                <Grid item xs={3} sm={3} style={{ padding: "8px", borderRight: "1px solid #2f2d2d" }}>
                                                                    <img style={{ height: "70px", cursor: "zoom-in" }} alt="" src={Utils.convertToHttps(container.ImageUrl)} onClick={() => imageViewer(container)} />
                                                                </Grid>
                                                                <Grid item xs={9} sm={9} style={{ padding: "8px", textAlign: "left" }}>
                                                                    {renderProCov(orderDetail.ContainerDetail)}
                                                                    <Typography style={{ fontSize: "13px", fontWeight: 500, color: "#D93A2F" }}>{container.ItemDesc}</Typography>
                                                                    <Typography style={{ fontSize: "11px" }}>Shipped Qty: <b>{container.Quantity}</b></Typography>
                                                                    <Typography style={{ fontSize: "11px" }}>Item ID <b>{container.ItemID}</b></Typography>
                                                                    <Typography style={{ fontSize: "11px" }}>Brand: <b>{container.BrandName}</b></Typography>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    }
                                                })
                                            }
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    <Card style={{ marginTop: "6px" }} id='cardOrder'>
                        <CardContent style={{ padding: "1px" }}>
                            <div className="backdrop">
                                <div className="title">
                                    <Typography>Address Details</Typography>
                                </div>
                                <div className="mainContainer" style={{ padding: "5px" }}>
                                    <Grid item xs={12} sm={12} className='inputField'>
                                        <Typography sx={{ fontSize: "13px", fontWeight: 500, color: Constants.Colors.red, display: "flex" }}>
                                            <LocationOnIcon></LocationOnIcon> Billing Address
                                        </Typography>
                                        <Typography style={{ fontSize: "11px", paddingLeft: "25px" }}>
                                            {orderDetail?.BillToAddress?.FirstName} {orderDetail?.BillToAddress?.MiddleName} {orderDetail?.BillToAddress?.LastName}
                                        </Typography>
                                        <Typography style={{ fontSize: "11px", paddingLeft: "25px" }}>
                                            {orderDetail?.BillToAddress?.AddressLine1}
                                            {
                                                orderDetail?.BillToAddress?.AddressLine2 == "" ?
                                                    ""
                                                    :
                                                    "," + orderDetail?.BillToAddress?.AddressLine2
                                            }
                                        </Typography>
                                        <Typography style={{ fontSize: "11px", paddingLeft: "25px" }}>
                                            {orderDetail?.BillToAddress?.City},{orderDetail?.BillToAddress?.State},{orderDetail?.BillToAddress?.ShortZipCode}
                                        </Typography>
                                        <Typography style={{ fontSize: "11px", paddingLeft: "25px" }}>
                                            {orderDetail?.BillToAddress?.Country}
                                        </Typography>
                                    </Grid>
                                    <hr></hr>
                                    <Grid item xs={12} sm={12} className='inputField'>
                                        <Typography sx={{ fontSize: "13px", fontWeight: 500, color: Constants.Colors.red, display: "flex" }}>
                                            <LocationOnIcon></LocationOnIcon> Shipping Address
                                        </Typography>
                                        <Typography style={{ fontSize: "11px", paddingLeft: "25px" }}>
                                            {orderDetail?.ToAddress?.FirstName} {orderDetail?.ToAddress?.MiddleName} {orderDetail?.ToAddress?.LastName}
                                        </Typography>
                                        <Typography style={{ fontSize: "11px", paddingLeft: "25px" }}>
                                            {orderDetail?.ToAddress?.Company}
                                        </Typography>
                                        <Typography style={{ fontSize: "11px", paddingLeft: "25px" }}>
                                            {orderDetail?.ToAddress?.AddressLine1}
                                            {
                                                orderDetail?.ToAddress?.AddressLine2 == "" ?
                                                    ""
                                                    :
                                                    "," + orderDetail?.ToAddress?.AddressLine2
                                            }
                                        </Typography>
                                        <Typography style={{ fontSize: "11px", paddingLeft: "25px" }}>
                                            {orderDetail?.ToAddress?.City},{orderDetail?.ToAddress?.State},{orderDetail?.ToAddress?.ZipCode}
                                        </Typography>
                                        <Typography style={{ fontSize: "11px", paddingLeft: "25px" }}>
                                            {orderDetail?.ToAddress?.Country}
                                        </Typography>

                                    </Grid>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    <Card className='customerDetailsBoxPickOrder'>
                        <CardContent style={{ padding: "1px", paddingBottom: "10px", background: "#F3F3F4" }}>
                            <Button onClick={() => { setDrawer(true) }} className="MobileDrawerButtons" variant="outlined">Customer Details</Button>
                            <Button onClick={() => { setHistoryDrawer(true) }} className="MobileDrawerButtons" variant="outlined">Fulfillment History</Button>
                           
                        </CardContent>
                    </Card>
                    <SwipeableDrawer
                        anchor={"bottom"}
                        open={openHistoryDrawer}
                        onClose={() => { }}
                        onOpen={() => { }}
                        ModalProps={{ onBackdropClick: () => { setHistoryDrawer(false) } }}
                    >
                        <OrderHistory store={props.store}></OrderHistory>
                    </SwipeableDrawer> */}
                {/* <Drawer
                        anchor={"bottom"}
                        open={openAddressDrawer}
                        onClose={() => { }}
                        onClick={() => { setAddressDrawer(false) }}
                    >
                        <Card style={{ margin: "6px" }}>
                            <CardContent style={{ padding: "10px" }}>
                                <Grid item xs={12} sm={12} className='inputField'>
                                    <Typography sx={{ fontWeight: 'bold', color: Constants.Colors.red, display: "flex" }}>
                                        <LocationOnIcon></LocationOnIcon> Billing Address
                                    </Typography>
                                    <Typography style={{ paddingLeft: "25px" }}>
                                        {orderDetail?.BillToAddress?.FirstName} {orderDetail?.BillToAddress?.MiddleName} {orderDetail?.BillToAddress?.LastName}
                                    </Typography>
                                    <Typography style={{ paddingLeft: "25px" }}>
                                        {orderDetail?.BillToAddress?.AddressLine1}
                                        {
                                            orderDetail?.BillToAddress?.AddressLine2 == "" ?
                                                ""
                                                :
                                                "," + orderDetail?.BillToAddress?.AddressLine2
                                        }
                                    </Typography>
                                    <Typography style={{ paddingLeft: "25px" }}>
                                        {orderDetail?.BillToAddress?.City},{orderDetail?.BillToAddress?.State},{orderDetail?.BillToAddress?.ShortZipCode}
                                    </Typography>
                                    <Typography style={{ paddingLeft: "25px" }}>
                                        {orderDetail?.BillToAddress?.Country}
                                    </Typography>
                                </Grid>
                                <hr></hr>
                                <Grid item xs={12} sm={12} className='inputField'>
                                    <Typography sx={{ fontWeight: 'bold', color: Constants.Colors.red, display: "flex" }}>
                                        <LocationOnIcon></LocationOnIcon> Shipping Address
                                    </Typography>
                                    <Typography style={{ paddingLeft: "25px" }}>
                                        {orderDetail?.ToAddress?.FirstName} {orderDetail?.ToAddress?.MiddleName} {orderDetail?.ToAddress?.LastName}
                                    </Typography>
                                    <Typography style={{ paddingLeft: "25px" }}>
                                        {orderDetail?.ToAddress?.Company}
                                    </Typography>
                                    <Typography style={{ paddingLeft: "25px" }}>
                                        {orderDetail?.ToAddress?.AddressLine1}
                                        {
                                            orderDetail?.ToAddress?.AddressLine2 == "" ?
                                                ""
                                                :
                                                "," + orderDetail?.ToAddress?.AddressLine2
                                        }
                                    </Typography>
                                    <Typography style={{ paddingLeft: "25px" }}>
                                        {orderDetail?.ToAddress?.City},{orderDetail?.ToAddress?.State},{orderDetail?.ToAddress?.ZipCode}
                                    </Typography>
                                    <Typography style={{ paddingLeft: "25px" }}>
                                        {orderDetail?.ToAddress?.Country}
                                    </Typography>

                                </Grid>
                            </CardContent>
                        </Card>
                    </Drawer> */}
                <Drawer
                    anchor={"bottom"}
                    open={openDrawer}
                    onClose={() => { }}
                    onClick={() => { setDrawer(false) }}
                >
                    <Card style={{ margin: "6px" }}>
                        <CardContent style={{ padding: "10px" }}>
                            <Typography variant="h5" component="div">
                                {orderDetail.FirstName} {orderDetail.LastName}
                                <br />
                            </Typography>

                            <Typography variant="body2">
                                Email: {orderDetail.EMailID}
                            </Typography>
                            <Typography variant="body2">
                                Phone: {orderDetail?.PhoneNo}
                            </Typography>

                        </CardContent>
                    </Card>
                </Drawer>
            </div>
        );
    }

    const closeImageViewer = () => {
        setImagePopUp(false);
        setImageData({} as IImageViewerDetail);
    }

    let imageViewerSettings: IImageViewerPopupSettings = {
        open: imagePopUp,
        imageDetail: imageData,
        close: closeImageViewer
    }


    const imageViewer = (item: any) => {
        let imageData: IImageViewerDetail = {
            imageURL: item.ImageUrl,
            itemDesc: item.ItemDesc,
            itemID: item.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID ?? "-",
            skuID: item.ItemID,
            price: item.UnitPrice,
            qty: item.Quantity
        }

        setImagePopUp(true);
        setImageData(imageData);
    }

    const renderProCov = (container: any) => {
        let proCov = container?.some((detail: any) => detail?.hasOwnProperty("WarrantyComponent"));

        return (proCov ?
            <Chip
                className="chipTracking"
                label={"PRO COV"}
                style={{
                    background: "#A1D899",
                    color: "#006100",
                    fontSize: "12px",
                    fontWeight: 700,
                    height: "20px",
                    borderRadius: "4px"
                }}
            /> : <></>);
    }

    const webView = () => {
        return (
            <div>
                {/* <SiteBreadCrumbs siteBreadCrumbs={Constants.SiteBreadCrumbs.PendingReceipt}></SiteBreadCrumbs> */}
                <div style={{ margin: "20px" }}>
                    <Grid container spacing={2} display={"flex"}>
                        <Grid item xs={6} sm={6} className='textLeft'>
                            <Grid container display={"flex"}>
                                <Grid item>
                                    <Button onClick={() => { navigateToReceiveOrders() }} className="back-button" variant='contained' startIcon={<ArrowBackIosNewOutlinedIcon />}>Back</Button>
                                </Grid>
                                <Grid item style={{ marginLeft: "30px" }}>
                                    <Typography className="medium-heading">Tracking #</Typography>
                                    <Typography className="large-heading">{orderDetail.TrackingNo}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {Utils.disableUserAction(props.store) && <Grid item xs={6} sm={6} className='textRight'>
                            <Button variant='contained' onClick={() => openConfirmProblem()} className="header-button" startIcon={<ReportOutlinedIcon />}>Carton Error</Button>
                            <Button variant='contained' onClick={() => openConform()} className='header-button red' startIcon={<DownloadingOutlinedIcon />} style={{ marginLeft: 10 }}>Receive</Button>
                            {/* <Button variant='contained' onClick={() => setShowPickupSlip(showPickupSlip + 1)} className='btn' startIcon={<DoneIcon />}>Print</Button> */}
                        </Grid>}
                    </Grid>
                    <div style={{ marginTop: "26px" }}>
                        <Grid container spacing={2} display={"flex"} style={{ padding: "0px" }}>
                            <Grid item xs={9} sm={9} style={{ paddingTop: "0px", paddingRight: "4px", paddingLeft: "0px" }} className='textLeft'>
                                <Card className="dCard" style={{ margin: 16 }}>
                                    <CardContent className="dCardContent">
                                        <Grid item display={"flex"} style={{ alignItems: "center" }}>
                                            <Typography className="medium-heading" style={{ marginBottom: "4px" }}>Order #</Typography>
                                            <Typography className="card-heading" style={{ marginLeft: "5px" }}>{orderDetail.OrderNo}</Typography>
                                        </Grid>
                                        <Divider />
                                        <div style={{ marginTop: 3, padding: "20px" }}>
                                            <div>
                                                {
                                                    !loadingOrderLines ?
                                                        <React.Fragment>
                                                            {
                                                                orderDetail.ContainerDetail.map((container: any, index: any) => {
                                                                    if (!container.hasOwnProperty("WarrantyComponent")) {
                                                                        return (
                                                                            <React.Fragment>
                                                                                <Grid container spacing={1} display={"flex"} className="justifyBetween productHeader">
                                                                                    <div>
                                                                                        <Grid item xs={12} sm={12} spacing={2} container alignItems="center" justifyContent="space-between">
                                                                                            <Grid item display={"flex"} style={{ marginTop: "8px" }}>
                                                                                                <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                                                                                    Ordering Store #
                                                                                                </Typography>
                                                                                                <Typography variant="body2" sx={{ fontSize: 13, fontWeight: 500, marginLeft: 1 }}>
                                                                                                    {orderDetail.OrderingStore}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item display={"flex"} style={{ marginTop: "8px" }}>
                                                                                                <Typography sx={{ fontSize: 12 }} color="text.secondary">
                                                                                                    Shipped Qty
                                                                                                </Typography>
                                                                                                <Typography variant="body2" sx={{ fontSize: 13, fontWeight: 500, marginLeft: 1 }}>
                                                                                                    {container.Quantity}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid container spacing={1} display={"flex"} key={index} style={{ margin: 0 }}>
                                                                                    <Grid item xs={12} sm={12} className='textLeft'>
                                                                                        <Grid container spacing={1} style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                                            <Grid item xs={3} sm={3} style={{ padding: "8px", display: "flex", justifyContent: "center" }}>
                                                                                                <div className="image-zoom" onClick={() => imageViewer(container)}>
                                                                                                    <img style={{ height: "auto", width: "100%" }} alt="" src={Utils.convertToHttps(container.ImageUrl)} />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={9} sm={9} style={{ padding: "8px", textAlign: "left" }}>
                                                                                                {renderProCov(orderDetail.ContainerDetail)}
                                                                                                <Typography style={{ fontSize: "16px", fontWeight: 400 }}>{container.ItemDesc}</Typography>
                                                                                                <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                                                                                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                                                                                        <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                                                                            <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                                Item ID
                                                                                                            </Typography>
                                                                                                            <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                                <b>{container.ItemID}</b>
                                                                                                            </Typography>
                                                                                                        </div>
                                                                                                        <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                                                                            <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                                Brand
                                                                                                            </Typography>
                                                                                                            <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                                <b>{container.BrandName}</b>
                                                                                                            </Typography>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </React.Fragment>
                                                        :
                                                        <OrderLineShimmer></OrderLineShimmer>
                                                }
                                            </div>
                                        </div>
                                        <Divider />
                                    </CardContent>
                                </Card>
                                <Accordion className="dCard" style={{ margin: 16 }}
                                    sx={{
                                        '&:before': {
                                            display: 'none',
                                        }
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        style={{ color: Constants.Colors.darkGrey, height: 70, padding: 30, marginTop: 10 }}
                                    >
                                        <Typography className="card-heading">Address Details</Typography>
                                    </AccordionSummary>
                                    <Divider style={{ margin: "0px 30px 0px 30px", border: "1px" }} />
                                    <AccordionDetails>
                                        {!loadingOrderLines ? <Grid container style={{ padding: 14, marginTop: 3 }}>
                                            <Grid item xs={6} sm={6}>
                                                <Typography className="address-heading" style={{ marginBottom: 15 }}>
                                                    Billing Address
                                                </Typography>
                                                <Typography className="address-value">
                                                    {orderDetail?.BillToAddress?.FirstName} {orderDetail?.BillToAddress?.MiddleName} {orderDetail?.BillToAddress?.LastName}
                                                </Typography>
                                                <Typography className="address-value">
                                                    {orderDetail?.BillToAddress?.AddressLine1}
                                                    {
                                                        orderDetail?.BillToAddress?.AddressLine2 == "" ?
                                                            ""
                                                            :
                                                            "," + orderDetail?.BillToAddress?.AddressLine2
                                                    }
                                                </Typography>
                                                <Typography className="address-value">
                                                    {orderDetail?.BillToAddress?.City},{orderDetail?.BillToAddress?.State},{orderDetail?.BillToAddress?.ShortZipCode}
                                                </Typography>
                                                <Typography className="address-value">
                                                    {orderDetail?.BillToAddress?.Country}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={6}>

                                                <Typography className="address-heading" style={{ marginBottom: 15 }}>
                                                    Shipping Address
                                                </Typography>
                                                <Typography className="address-value">
                                                    {orderDetail?.ToAddress?.FirstName} {orderDetail?.ToAddress?.MiddleName} {orderDetail?.ToAddress?.LastName}
                                                </Typography>
                                                <Typography className="address-value">
                                                    {orderDetail?.ToAddress?.Company}
                                                </Typography>
                                                <Typography className="address-value">
                                                    {orderDetail?.ToAddress?.AddressLine1}
                                                    {
                                                        orderDetail?.ToAddress?.AddressLine2 == "" ?
                                                            ""
                                                            :
                                                            "," + orderDetail?.ToAddress?.AddressLine2
                                                    }
                                                </Typography>
                                                <Typography className="address-value">
                                                    {orderDetail?.ToAddress?.City},{orderDetail?.ToAddress?.State},{orderDetail?.ToAddress?.ZipCode}
                                                </Typography>
                                                <Typography className="address-value">
                                                    {orderDetail?.ToAddress?.Country}
                                                </Typography>
                                            </Grid>
                                        </Grid> :
                                            <CustomerDetailShimmer></CustomerDetailShimmer>}

                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={3} sm={3} className='textRight' style={{ paddingTop: "0px", paddingRight: "0px", paddingLeft: "6px", marginTop: "16px" }}>
                                <Card className="dCard">
                                    <CardContent className="dCardContent">
                                        <Typography className="card-heading" style={{ textAlign: "left" }}> Customer Details</Typography>
                                        <Divider style={{ border: "1px" }} />
                                        {
                                            !loadingOrderLines ?
                                                <Grid container spacing={2} style={{ display: "flex", flexDirection: "column", padding: "10px" }}>
                                                    <Grid item xs={12} sm={12} className='textLeft' style={{display:"flex", alignItems:"center"}}>
                                                        <PersonIcon style={{fontSize:"22px"}}/><Typography style={{ fontSize: "18px", fontWeight: 700, marginLeft: "10px", wordBreak: "break-all" }}>{orderDetail.FirstName} {orderDetail.LastName}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} className='textLeft' style={{ display:"flex", alignItems:"center", paddingTop: "8px", wordBreak: "break-all" }}>
                                                        <EmailIcon style={{fontSize:"22px"}}/><Typography style={{ fontSize: "13px", fontWeight: 500, color: Constants.Colors.red, marginLeft: "10px" }}>{orderDetail.EMailID}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} className='textLeft' style={{ display:"flex", alignItems:"center", paddingTop: "8px" }}>
                                                        <LocalPhoneIcon style={{fontSize:"22px"}}/><Typography style={{ fontSize: "13px", fontWeight: 500, color: Constants.Colors.red, marginLeft: "10px" }}>{orderDetail?.PhoneNo}</Typography>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <CustomerDetailShimmer></CustomerDetailShimmer>
                                        }
                                    </CardContent>
                                </Card>
                                <div>
                                    <OrderHistory store={props.store}></OrderHistory>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div >
            </div>
        );
    }
    return (
        <div>
            <NotificationPopup {...successPopup}>
            </NotificationPopup>
            <NotificationPopup {...confirmProblemPopup}>
            </NotificationPopup>
            <NotificationPopup {...confirmPopup}>
            </NotificationPopup>
            <NotificationPopup {...Error}>
            </NotificationPopup>
            <NotificationPopup {...ReportError}></NotificationPopup>
            <NotificationPopup {...confirmReportError}> </NotificationPopup>
            <NotificationPopup {...navigationToReceiveOrders}>
            </NotificationPopup>
            <ImageViewerPopup {...imageViewerSettings}>
            </ImageViewerPopup>
            {Utils.isMobile ? mobileView() : webView()}
            {loadingOrderLines == false ?
                <ReceivePrintSlip loadingOrderLines={loadingOrderLines} showPickupSlip={showPickupSlip}></ReceivePrintSlip>
                :
                <div></div>
            }

            {/* {!Utils.isMobile ? <PickSlip></PickSlip> : ""} */}
        </div>
    );
}
export default PendingReceipt;