import React, { useState } from 'react';
import IdentityManager from '../../Managers/Identity.manager';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TableContainer, Table, Paper, TableRow, TableCell, TableBody, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Constants from '../../Common/Constants';
import { InfoIcon } from '../Shared/SharedIcons';
import Utils from '../../Common/Utils';
// import { ContentCopyIcon } from '../Shared/SharedIcons';
// import { CopyToClipboard } from 'react-copy-to-clipboard';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function UserInfoDialog(props: any): any {
  const closeDialogBox = () => props.setuserProfileDialog(false);
  const [clipBoardCopy, setClipBoardCopy] = useState("")
  const renderTable = () => {
    return (
      <TableContainer style={{overflowX: "visible"}} component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell><b>Name</b></TableCell>
              <TableCell style={{wordBreak: "break-all"}} align="right">{IdentityManager.getUserProperty('displayName') ? IdentityManager.getUserProperty('displayName') : IdentityManager.getUserProperty('name')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Email</b></TableCell>
              <TableCell style={{wordBreak: "break-all"}}  align="right">{IdentityManager.getUserProperty(Constants.Preferred_UserName)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Employee Id</b></TableCell>
              <TableCell style={{wordBreak: "break-all"}}  align="right">{IdentityManager.getUserProperty(Constants.EmployeeID)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Store</b></TableCell>
              <TableCell style={{wordBreak: "break-all"}}  align="right">{IdentityManager.getUserProperty(Constants.Location)}</TableCell>
            </TableRow>
            
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return (
    <Dialog open={props.open} onClose={() => { closeDialogBox() }} TransitionComponent={Transition}  PaperProps={{ style: { borderRadius: '16px', background:"#F9F9F9" } }}>
      <DialogTitle className="itemDialogBorder">
        <div style={{ display: "flex", alignItems: "center" }}>
          <InfoIcon style={{ color: "#2196F3", fontSize: Utils.isMobile ? 30 : 35, marginRight: 8 }} />
          <div>
              <p style={{ color: "#2196F3", fontSize: Utils.isMobile ? 20 : 26, margin: 0 }}>User Profile</p>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className="itemDialogBorder">
        {renderTable()}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { closeDialogBox() }} variant="contained" className='header-button underline'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}