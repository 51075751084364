import React from "react";
import { Typography } from '@material-ui/core';
import Constants from "../../Common/Constants";
import { ICustomerPickupDetail } from "../../Models/ICustomerPickupDetail";
import StoreManager from "../../Managers/Store.manager"
import { ICustomerDetail } from '../../Models/IPickOrder';
import { useDispatch, useSelector } from 'react-redux';
import { IReceiveOrderDetail } from '../../Models/IReceiveOrderDetail';
var momenttz = require('moment-timezone');
var Barcode = require('react-barcode');
const html2pdf = require('html2pdf.js');
const shipFontSize = "12px"
const today =  momenttz.tz("America/Los_Angeles").format(Constants.DateFormat.DateOnly);
export const ReceivePrintSlip: React.FC<any> = (props) => {
    const currentStore = StoreManager.currentStore
    let orderDetail:IReceiveOrderDetail = useSelector((state: any) => state.ReceiveOrdersReducersState.receiveOrderDetail);
        const renderTableHeading = (text: any) => {
        return <Typography variant='body1' style={{ letterSpacing: "1.5px", paddingTop: "6px" }}><span style={{ letterSpacing: "1.5px", fontSize: "10px" }}>{text}</span></Typography>;
    }
    const print = async ()=>{
        if(props.loadingOrderLines == false && props.showPickupSlip > 0 ){
            let el = document.getElementById("OrderPickupContainer") as any;
            let fileName = `Order Pickup Notice`;
            let options = {
                pdfCallback: pdfDone,
                margin: [0.1, 0.1, 0.1, 0.1],  // tlbr
                filename: fileName + '.pdf',
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { scale: 2, logging: true },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'p' },
                pagebreak: { before: '.breakPage' }
            };

            let worker = await html2pdf().set(options).from(el).toPdf().output('blob');
            var fileURL = URL.createObjectURL(worker);
            window.open(fileURL, '_blank');
        }
    }
    const pdfDone = () => {
        console.log("Pdf generated successfully");
    }
    React.useEffect(() => {
        async function printSlip() {
            await print()
        }
        printSlip()
    },[props.showPickupSlip]);
    return (
        <div style={{ display: "none" }}>
            <div id="OrderPickupContainer" style={{ background: "white" }}>
                <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        {/* <div style={{ width: "35%", textAlign: "left" }}>
                            <div className="logo-ContainerPackScreen"></div>
                        </div> */}
                        <div style={{ width: "100%" }}>
                            <Typography
                                variant="h4"
                                style={{
                                    textAlign: "center",
                                    paddingBottom: "10px",
                                    paddingTop: "10px",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                Order Pickup Notice - {currentStore.Company}({currentStore.id})
                            </Typography>
                        </div>
                    </div>
                    <div style={{ display: "flex", width: "100%", marginTop: "5px", textAlign: "center" }}>
                        <div style={{ width: "50%",  }}>
                            <Typography variant="h5">
                                {" "}
                                <span
                                    style={{
                                        color: "#D93A2F",
                                        letterSpacing: "3.5px",
                                        paddingBottom: "6px",
                                    }}
                                >
                                    Order Info
                                </span>{" "}
                            </Typography>
                            <div style={{marginTop:"10px"}}>
                                <Typography variant="body1" >
                                    Order # :<b>{orderDetail.OrderNo}</b>
                                </Typography>
                                <Typography variant="body1">
                                    OrderDate: {momenttz(orderDetail?.OrderDate).tz("America/Los_Angeles").format(Constants.DateFormat.DateOnly)}
                                </Typography>
                                <Typography variant="body1">
                                    Customer : {orderDetail?.FirstName ? orderDetail?.FirstName : "---"} {orderDetail?.LastName ? orderDetail?.LastName : "---"}
                                </Typography>
                            </div>
                        </div>
                        <div style={{ width: "50%", textAlign: "center" }}>
                            <Barcode width={"2px"} value={orderDetail.OrderNo} />
                        </div>
                    </div>
                    <div style={{ display: "flex", width: "100%", marginTop: "5px" }}>
                        <div style={{ width: "50%", textAlign: "center" }}>
                            <Typography variant="h5">
                                {" "}
                                <span
                                    style={{
                                        color: "#D93A2F",
                                        letterSpacing: "3.5px",
                                        paddingBottom: "6px",
                                    }}
                                >
                                    Shipping Address
                                </span>{" "}
                            </Typography>
                            <Typography variant="body1">
                                {orderDetail?.ToAddress?.FirstName} {" "} {orderDetail?.ToAddress?.MiddleName} {" "} {orderDetail?.ToAddress?.LastName}
                            </Typography>
                            <Typography variant="body1">
                                {orderDetail?.ToAddress?.Company}
                            </Typography>
                            <Typography variant="body1">
                                {orderDetail?.ToAddress?.AddressLine1} {","} {orderDetail?.ToAddress?.AddressLine2? orderDetail?.ToAddress?.AddressLine2 : ""} {" "}
                            </Typography>
                            <Typography variant="body1">
                                {orderDetail?.ToAddress?.City}{","}{orderDetail?.ToAddress?.State}{","}{orderDetail?.ToAddress?.ShortZipCode}
                            </Typography>
                            <Typography variant="body1">
                                {orderDetail?.ToAddress?.Country}
                            </Typography>
                        </div>
                    </div>
                    <div style={{ display: "flex", width: "100%", marginTop: "5px" }}>
                        <div style={{ width: "50%", textAlign: "center" }}>
                            <Typography variant="h5">
                                {" "}
                                <span
                                    style={{
                                        color: "#D93A2F",
                                        letterSpacing: "3.5px",
                                        paddingBottom: "6px",
                                    }}
                                >
                                    Payment Method
                                </span>{" "}
                            </Typography>
                            <Typography variant="body1">
                                {(orderDetail?.PaymentMethods?.PaymentMethod &&
                                    orderDetail?.PaymentMethods?.PaymentMethod?.length>0) ?
                                    orderDetail?.PaymentMethods?.PaymentMethod[0]?.CreditCardType
                                    :
                                    ""
                                }
                            </Typography>
                        </div>
                        <div style={{ width: "50%", textAlign: "center" }}>
                            <Typography variant="h5">
                                {" "}
                                <span
                                    style={{
                                        color: "#D93A2F",
                                        letterSpacing: "3.5px",
                                        paddingBottom: "6px",
                                    }}
                                >
                                    Date
                                </span>
                            </Typography>
                            <Typography variant="body1">
                                {today}
                            </Typography>
                        </div>
                    </div>
                    <div style={{ display: "flex", width: "100%", marginTop: "40px" }}>
                        <table style={{ width: "100%" }} cellPadding={0} cellSpacing={0}>
                            <thead style={{ background: "#D93A2F", color: "white" }}>
                                <th style={{ width: "10%", textAlign: "center" }}>
                                    {renderTableHeading("Quantity")}
                                </th>
                                <th style={{ width: "10%", textAlign: "left" }}>
                                    {renderTableHeading("Item ID")}
                                </th>
                                <th style={{ width: "32%", textAlign: "left" }}>
                                    {renderTableHeading("Description")}
                                </th>
                                <th style={{ width: "12%", textAlign: "left" }}>
                                    {renderTableHeading("Serial No.")}
                                </th>
                                <th style={{ width: "12%", textAlign: "left" }}>
                                    {renderTableHeading("Price")}
                                </th>
                                <th style={{ width: "12%", textAlign: "left" }}>
                                    {renderTableHeading("Status")}
                                </th>
                            </thead>
                            {
                                orderDetail.ContainerDetail.map((item: any, index: any) => {
                                    if(!item.hasOwnProperty("WarrantyComponent")){
                                        return ( <tr style={{ borderBottom: "1px solid #eee", height: "70px" }}>
                                            <td style={{ textAlign: "center" }}>
                                                <Typography
                                                    style={{
                                                        fontSize: "12px",
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {item.Quantity}
                                                </Typography>
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
                                                    {item.ItemID}
                                                </Typography>
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
                                                    {item.ItemDesc}
                                                </Typography>
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
                                                    { item.SerialNumber}
                                                </Typography>
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
                                                    {item.UnitPrice}{"$"}
                                                </Typography>
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
                                                    {"Ready for Pickup"}
                                                </Typography>
                                            </td>
                                        </tr>);
                                    }
                                })
                            }

                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
