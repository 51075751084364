import React, { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
import { Bars } from "react-loader-spinner";
import Utils from '../../Common/Utils';
const ChartShimmer = () => {
    return (
        <div style={{ padding: "20px", textAlign: "center", display: "flex", justifyContent: "center" }}>
            <Bars color="#D93A2F" height={Utils.isMobile ? 50 : 100} width={Utils.isMobile ? 50 : 100} />
        </div>

    )
}

export default ChartShimmer;