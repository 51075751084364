import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import compress from 'browser-image-compression';
import IconButton from '@mui/material/IconButton';
import "./ImageUploader.scss";
import CancelIcon from '@mui/icons-material/Cancel';
import allActions from '../../Store/Actions/AllActions';
import { useDispatch, useSelector } from 'react-redux';

export const ImageUploader = () => {

  const dispatch = useDispatch();
  const images = useSelector((state: any) => state.ActionLoaderReducersState.attachmentImages);

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  }

  const showActionLoader = () => {
    dispatch(allActions.actionLoaderActions.showLoader());
  }

  const hideActionLoader = () => {
    dispatch(allActions.actionLoaderActions.hideLoader());
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop: async (acceptedFiles: any) => {
      let uploadedImages = JSON.parse(JSON.stringify(images));
      showActionLoader();
      for (let file of acceptedFiles) {
        if (uploadedImages.length < 5) {
          if (file.size > 1048576) {
            const compressedFile = await compress(file, options);
            file.binary = await compress.getDataUrlFromFile(compressedFile);
          }
          else {
            file.binary = await compress.getDataUrlFromFile(file);
          }
          let obj = {
            binary: file.binary,
            type: file.type
          }
          uploadedImages.push(obj);
        }
        else {
          break;
        }
      }
      hideActionLoader();
      dispatch(allActions.actionLoaderActions.setAttachmentImages(uploadedImages));
    }
  });

  const removeImage = (index: number) => {
    images.splice(index, 1);
    dispatch(allActions.actionLoaderActions.setAttachmentImages([...images]));
  }

  return (
    <div className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Typography style={{ fontWeight: "bold" }}>Drag 'n' drop some images here, or click to select images</Typography>
        <Typography>(Users can select a maximum of 5 images at a time.)</Typography>
      </div>
      {
        images?.length > 0 &&
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {

            images.map((file: any, index: any) => {
              return (
                <div key={index}>
                  <span className="remove-icon">
                    <CancelIcon style={{ color: "red", height: "20px", width: "20px", cursor: "pointer" }} onClick={() => { removeImage(index) }} />
                  </span>
                  <img src={file.binary} className="image" />
                </div>
              )
            })
          }
        </div>
      }
    </div>
  );
}