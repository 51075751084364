import { CHANGE_EMAILTEMPLATES, CHANGE_SELECTEDTEMPLATE, CHANGE_CUSTOMERDATA, CHANGE_SELECTEDORDERLINES, CHANGE_NOTES, SHOW_COMPILEDRESULT, CHANGE_STOREEMAIL, CHANGE_ERRORMESSAGE, CHANGE_CONTACTCUSTOMERHISTORY, CHANGE_CUSTOMBODY, CHANGE_CUSTOMSUB, CHANGE_PARENTCONTACTCUSTOMER } from "../ActionTypes";
const initialState = {
    emailTemplates: [],
    selectedTemplate: {},
    customerData: {},
    selectedOrderLines: {},
    notes: "",
    showCompiledResult: false,
    storeEmail: "",
    errorMessage: "",
    contactCustomerHistory: [],
    customBody: "",
    customSub: "",
    parentContactCustomer:""
}
const ContactCustomerReducers = (state: any = initialState,
    action: { type: any; payLoad: any }) => {
    switch (action.type) {
        case CHANGE_EMAILTEMPLATES:
            return {
                ...state,
                emailTemplates: action.payLoad
            }
        case CHANGE_SELECTEDTEMPLATE:
            return {
                ...state,
                selectedTemplate: action.payLoad
            }
        case CHANGE_CUSTOMERDATA:
            return {
                ...state,
                customerData: action.payLoad
            }
        case CHANGE_SELECTEDORDERLINES:
            return {
                ...state,
                selectedOrderLines: action.payLoad
            }
        case CHANGE_NOTES:
            return {
                ...state,
                notes: action.payLoad
            }
        case SHOW_COMPILEDRESULT:
            return {
                ...state,
                showCompiledResult: action.payLoad
            }
        case CHANGE_STOREEMAIL:
            return {
                ...state,
                storeEmail: action.payLoad
            }
        case CHANGE_ERRORMESSAGE:
            return {
                ...state,
                errorMessage: action.payLoad
            }
        case CHANGE_CONTACTCUSTOMERHISTORY:
            return {
                ...state,
                contactCustomerHistory: action.payLoad
            }
        case CHANGE_CUSTOMBODY:
            return {
                ...state,
                customBody: action.payLoad
            }
        case CHANGE_CUSTOMSUB:
            return {
                ...state,
                customSub: action.payLoad
            }
        case CHANGE_PARENTCONTACTCUSTOMER:
            return {
                ...state,
                parentContactCustomer: action.payLoad
            }
    }
    return state;
}

export default ContactCustomerReducers;