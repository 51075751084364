import React, { useCallback, useState } from "react";
import "./App.scss";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { Routes, Route, useLocation } from "react-router-dom";
import Utils from "./Common/Utils";
import Constants from "./Common/Constants"
import CacheBuster from "./Managers/Cache.Buster";
import AuthManager from "./Managers/Auth.manager";
import IdentityManager from "./Managers/Identity.manager";
import Dashboard from "./Routes/Dashboard";
import FulfillOrders from "./Routes/FulfillOrders";
import ReceiveOrders from "./Routes/ReceiveOrders";
import Reports from "./Routes/Reports";
import OrdersSyncFlow from "./Routes/OrdersSyncFlow";
import KCDCReturns from "./Routes/KCDCReturns";
import Navbar from "./Subcomponent/Shared/Navbar";
import DrawerRow from "./Subcomponent/Shared/DrawerRow";
import UserInfoDialog from "./Subcomponent/Dialog/UserInfoDialog";
import ErrorIcon from "@material-ui/icons/Error";
import MobileNavbar from "./Subcomponent/Shared/MobileNavbar";
import FullScreenSearch from "./Subcomponent/MobileSearchBar/FullScreenSearch";
import KCDCReturnDetail from "./Routes/KCDCReturnDetail";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { render } from "@testing-library/react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import PickOrder from "./Subcomponent/PickOrder/PickOrder";
import BulkPickOrder from "./Subcomponent/PickOrder/BulkPickOrder";
import PackOrder from "./Routes/PackOrder";
import NotAuthorized from "./Routes/NotAuthorized";
import OrderDetail from "./Routes/OrderDetail";
import CustomerPickup from "./Routes/CustomerPickup";
import PendingReceipt from "./Routes/PendingReceipt";
import ExternalCustomerView from "./Routes/ExternalCustomerView";
import SearchResult from "./Routes/Search";
import ActionLoader from "./Subcomponent/Shared/ActionLoader";
import allActions from "./Store/Actions/AllActions";
import PFManager from "./Managers/Process.Fulfillment.Manager";
import TechAudits from "./Routes/TechAudits";
import StoreManager from "./Managers/Store.manager";
import { GenericSuccessNotification } from "./Subcomponent/Shared/GenericSuccessNotification";
import OMSManager from "./Managers/Oms.manager";
import { usePickerState } from "@material-ui/pickers";
import LabelText from "./Common/LabelText";
import ResizeObserver from "resize-observer-polyfill";
import OrderManager from "./Managers/Orders.Manager";
import IStoreInfo from "./Models/IStoreInfo";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import megaphone from "./megaphone.png";
import FeedbackDialog from "./Subcomponent/Feedback/FeedbackDialog";
import Feedback from "./Routes/Feedback";
import CompactNavbar from "./Subcomponent/Shared/CompactNavbar";
import { Tooltip, Typography, useMediaQuery } from "@mui/material";
import {
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon,
} from "./Subcomponent/Shared/SharedIcons";
import FulfilledOrdersHistory from "./Routes/FulfilledOrdersHistory";
import IUserSettings from "./Models/IUserSettings";
import UserManager from "./Managers/User.Manager";
import { datadogRum } from "@datadog/browser-rum";
import {
  DataCaptureView,
  Camera,
  DataCaptureContext,
  configure,
  FrameSourceState,
  RadiusLocationSelection,
  NumberWithUnit,
  MeasureUnit,
  AimerViewfinder,
  Brush,
  Color,
} from "scandit-web-datacapture-core";
import type {
  SymbologySettings,
  Barcode,
  BarcodeCaptureSession,
} from "scandit-web-datacapture-barcode";
import {
  barcodeCaptureLoader,
  BarcodeCapture,
  BarcodeCaptureSettings,
  Symbology,
  BarcodeCaptureOverlay,
  SymbologyDescription,
  BarcodeTrackingSettings,
  BarcodeTrackingScenario,
  BarcodeTracking,
  BarcodeTrackingBasicOverlay,
} from "scandit-web-datacapture-barcode";

// Firebase Import
// import { generateToken, messaging } from './firebase';
// import { onMessage } from 'firebase/messaging';

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

const AttentionMessage = ({
  closeToast,
  toastProps,
  message,
  heading,
}: any) => (
  <div>
    {/* <img style={{ textAlign: "center" }} width="50" height="50" src={megaphone} alt="Party Popper" /> */}
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        justifyContent: "center",
      }}
    >
      <img width="50" height="50" src={megaphone} alt="megaphone" />
      <div style={{ textAlign: "center", width: "100%" }}>
        <h3
          style={{
            color: "#FFFFFF",
            backgroundColor: "#D93A2F",
            padding: "5px",
            margin: "0 auto",
          }}
        >
          {heading}
        </h3>
      </div>
    </div>
    <div style={{ textAlign: "left" }}>
      {<div dangerouslySetInnerHTML={{ __html: message }} />}
    </div>
  </div>
);
const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [store, setStore] = React.useState([]);
  const [district, setDistrict] = React.useState([]);
  const [region, setRegion] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [selected, setSelectedHeader] = useState("");
  const [mobileSelected, setMobileSelected] = useState(0);
  const [displayDrawer, setdisplayDrawer] = useState(false);
  const [userProfileDialog, setuserProfileDialog] = useState(false);
  const [feedBackDialog, setFeedBackDialog] = useState(false);
  const [isNavigationLoaded, setNavigationLoaded] = useState(false);
  const [omsProfileNotSetUpError, setOmsProfileNotSetUpError] = useState("");
  const [data, setData] = useState(null);
  const [isNotificationShowed, setIsNotificationShowed] = useState(false);
  const boxRef = React.useRef<HTMLInputElement>(null);
  const [showFullScreenSearch, setFullScreenShowSearch] = useState(false);
  //const stores = useSelector((state: any) => state.ActionLoaderReducersState.stores);
  const kcdcQuantity = useSelector(
    (state: any) => state.ActionLoaderReducersState.kcdcTotalQuantity
  );
  const districtsList = useSelector(
    (state: any) => state.ActionLoaderReducersState.districts
  );
  const regions = useSelector(
    (state: any) => state.ActionLoaderReducersState.regions
  );
  const [compactNavbarToggle, setCompactNavbarToggle] = useState(false);
  const compactNavbarWidth = useMediaQuery("(max-width:1200px)");

  // Fire Base
  // React.useEffect(() => {
  //     generateToken();
  //     onMessage(messaging , (payload) => {
  //         console.log("On Messaging Payload" , payload);

  //     })
  // }, [])

  const showNotificationToaster = async () => {
    await getConfigurations();
    const currentDate = new Date();
    Utils.getNotification()?.map((msg: any) => {
      if (msg.active) {
        if (
          new Date(msg.startDate) <= currentDate &&
          new Date(msg.endDate) >= currentDate
        ) {
          return toast(
            <AttentionMessage message={msg.message} heading={msg.heading} />
          );
        }
      }
    });
  };

  const changeStoreList = (event: any, value: any) => {
    if (
      value &&
      value.length == 1 &&
      (value[0].id !== undefined || value[0].id !== "")
    ) {
      let res = StoreManager.allStoredata.find(
        (r) => r.StoreId.toString() === value[0].id
      );
      if (res !== undefined && res !== null) {
        StoreManager.currentStore = res;
      }
    } else if (value && value.length == 0) {
      StoreManager.currentStore = {} as IStoreInfo;
      setDistrict([]);
      setRegion([]);
      navigate("/Dashboard");
    } else if (value && value.length > 1) {
      StoreManager.currentStore = {} as IStoreInfo;
    }
    setStore(value);
  };

  const changeDistrict = (event: any, value: any) => {
    let _districtStores: any = [];
    value.forEach((district: any) => {
      const stores = StoreManager.storeList.filter(
        (s) => s.districtId.toString() == district.id
      );
      _districtStores.push(...stores);
    });
    if (value && value.length == 0) {
      StoreManager.currentStore = {} as IStoreInfo;
      setRegion([]);
    }
    setDistrict(value);
    setStore(_districtStores);
  };

  const getToggleValue = () => {
    const storedValue = localStorage.getItem(Constants.CompactNavbarToggle);
    const toggleValue = storedValue === "true";
    return toggleValue;
  };

  const changeRegion = (event: any, value: any) => {
    let _districts: any = [];
    value.forEach((region: any) => {
      const districts: any = districtsList.filter(
        (d: any) => d.regionId == region.id
      );
      _districts.push(...districts);
    });
    setRegion(value);
    changeDistrict(null, _districts);
  };

  const fullScreenSearchClose = () => {
    setFullScreenShowSearch(false);
  };
  const fullScreenSearchOpen = () => {
    setFullScreenShowSearch(true);
  };
  const errorFromOtherScreen = () => {
    setOmsProfileNotSetUpError(LabelText.omsProfileNotConfigured);
    setError(true);
  };
  const resumeInit = useCallback(async () => {
    if (!Utils.isExternalRoute()) {
      console.log("get initial data");
      setHeader();
      await getConfigurations();
      await getUserSettings();
      getRights().then(async () => {
        if (IdentityManager.isAdmin || IdentityManager.storeNumber !== "") {
          setNavigationLoaded(true);
          routeGuard(location);
          setLoading(false);
          let _stores: any = await Utils.getInitialData(errorFromOtherScreen);
          dispatch(allActions.actionLoaderActions.setStores(_stores));
          let _districts: any = StoreManager.getDistrictList();
          dispatch(allActions.actionLoaderActions.setDistricts(_districts));
          let _regions: any = StoreManager.getRegionsList();
          dispatch(allActions.actionLoaderActions.setRegions(_regions));
          setLoading(false);
        } else {
          setError(true);
        }
      });
    } else {
      Utils.getInitialDataExternalLink();
      setLoading(false);
    }
  }, []);
  const selectProfileStores = () => {
    let employeeID = IdentityManager.getUserProperty(Constants.EmployeeID);
    if (employeeID && employeeID != "") {
      employeeID = IdentityManager.getUserProperty(Constants.EmployeeID)
        ?.toString()
        .padStart(6, "0");
      const isRvp = IdentityManager.isRvp;
      const isDM = IdentityManager.isDM;
      if (isRvp) {
        const empRegions: any = StoreManager.allStoredata.filter(
          (r) => r.RMId === employeeID
        ); // multiple region
        if (empRegions && empRegions.length > 0) {
          const mapEmpRegions = Utils.mapRegionsForDropdown(empRegions);
          changeRegion(null, mapEmpRegions);
        }
      }
      if (isDM) {
        const empDistricts = StoreManager.allStoredata.filter(
          (r) => r.DMId.toString() === employeeID
        ); // multiple districts
        if (empDistricts && empDistricts.length > 0) {
          const district = districtsList.filter(
            (d: any) => d.id == empDistricts[0].DistrictId
          );
          changeDistrict(null, district);
        }
      }
    }
  };

  const selectAllRegions = () => {
    dispatch(allActions.actionLoaderActions.setEnterprise(true));
    changeRegion(null, regions);
  };

  const authorizeUser = useCallback(async () => {
    console.log("authorizeUser");
    if (Utils.checkUserProfile_LocalStorage()) {
      let userInfoString = localStorage.getItem(Constants.USER_PROFILE);
      if (typeof userInfoString == "string") {
        await resumeInit();
      }
    } else {
      localStorage.removeItem(Constants.createLogin);
      AuthManager.signin();
    }
  }, [resumeInit]);

  const routeGuard = useCallback(
    (_location: any) => {
      console.log("routeGuard");
      let path = _location.pathname.replace("/", "");
      if (path && path.length > 0 && path != "NotAuthorized") {
        if (!Utils.isExternalRoute()) {
          const allFoundGroups = IdentityManager.getUserAllGroups();
          let targetGroup =
            allFoundGroups.length > 0 ? allFoundGroups[0] : null;
          if (targetGroup) {
            try {
              if (targetGroup[path] == "0" || targetGroup[path] == undefined) {
                setError(true);
              }
            } catch {
              setError(true);
            }
          } else {
            setError(true);
          }
        }
      }
    },
    [navigate]
  );
  const checkAuth = useCallback(async () => {
    //
    console.log("checkAuth");
    if (IdentityManager.isTokenValid) {
      localStorage.removeItem(Constants.USER_PROFILE);
      await authorizeUser();
    } else {
      await resumeInit();
    }
  }, [authorizeUser, resumeInit]);

  async function getRights() {
    await PFManager.setGroupsRights();
  }

  const getConfigurations = async () => {
    await PFManager.getConfigurations();
  };
  const initDataDog = async () => {
    var dataDogConfigs: any = await PFManager.getDataDogConfigurations();
    if (dataDogConfigs != undefined && dataDogConfigs.ApplicationId) {
      console.log("dataDogInit");
      const userEmail = IdentityManager.getUserProperty(Constants.Preferred_UserName);
      const userID = IdentityManager.getUserProperty(Constants.EmployeeID);
      datadogRum.init({
        applicationId: dataDogConfigs.ApplicationId,
        clientToken: dataDogConfigs.ClientToken,
        site: dataDogConfigs.Site,
        service: dataDogConfigs.Service,
        env: dataDogConfigs.Env,
        version: dataDogConfigs.Version,
        sessionSampleRate: dataDogConfigs.sessionSampleRate,
        sessionReplaySampleRate: dataDogConfigs.sessionReplaySampleRate,
        trackUserInteractions: dataDogConfigs.trackUserInteractions,
        trackResources: dataDogConfigs.trackResources,
        trackLongTasks: dataDogConfigs.trackLongTasks,
        defaultPrivacyLevel: dataDogConfigs.defaultPrivacyLevel,

        beforeSend: (event, context) => {
          
          if(userID){
            datadogRum.setGlobalContextProperty('userID', userID);
          }

          const hasLocation = (context as any).location;
          if (hasLocation?.search) {
            const searchParams = new URLSearchParams(hasLocation.search);
            const orderNo = searchParams.get('orderno');
            const shipmentKey = searchParams.get('shipmentkey');
        
            if (orderNo) {
              appendToGlobalContextProperty('orderNo', orderNo);
            }
        
            if (shipmentKey) {
              appendToGlobalContextProperty('shipmentKey', shipmentKey);
            }
          }
          return false;
        }
      });

      datadogRum.setUser({
        email: userEmail
    });
    }
    const appendToGlobalContextProperty = (key: string, value: string) => {
      // Retrieve the current value of the global context property
      const currentValues = datadogRum.getGlobalContext()?.[key] || [];
    
      // Ensure the current value is an array
      const updatedValues = Array.isArray(currentValues) ? currentValues : [currentValues];
    
      // Append the new value if it's not already present
      if (!updatedValues.includes(value)) {
        updatedValues.push(value);
      }
      // Set the updated array back as the global context property
      datadogRum.setGlobalContextProperty(key, updatedValues);
    };
  };

  const getUserSettings = async () => {
    const payload = {
      userName:
        IdentityManager.getUserProperty(Constants.Preferred_UserName)?.split(
          "@"
        )[0] ?? "",
      empID: IdentityManager.getUserProperty(Constants.EmployeeID) ?? "",
    };
    const result = await UserManager.getUserSettings(payload);
    if (result.length > 0) {
      const settings: IUserSettings = result[0];
      dispatch(allActions.actionLoaderActions.setUserSettings(settings));
    }
  };

  const resetKcdcQuantity = () => {
    dispatch(allActions.actionLoaderActions.setKcdcTotalQuantity(0));
  };

  const getKcdcTotalQuantity = () => {
    if (StoreManager.currentStore?.StoreId) {
      let storeId = StoreManager.currentStore.StoreId.toString().split(" ");
      OrderManager.getkcdcOrders(storeId)
        .then((res: any) => {
          if (res.success && res.data?.hits?.totalShipmentLines > 0) {
            let totalShipmentLines = res.data?.hits.totalShipmentLines;
            dispatch(
              allActions.actionLoaderActions.setKcdcTotalQuantity(
                totalShipmentLines
              )
            );
          } else {
            resetKcdcQuantity();
          }
        })
        .catch((err) => {
          console.log("KCDC Total Quanty Error", err);
        });
    } else {
      resetKcdcQuantity();
    }
  };

  React.useEffect(() => {
    if (Utils.isExternalRoute()) {
      resumeInit();
      routeGuard(location);
      setNavigationLoaded(true);
    } else {
      if (!Utils.checkUserProfile_LocalStorage()) {
        // check if the user has profile in localstorage otherwise redirect to checkAuth
        checkAuth();
      } else {
        console.log('RESTARTING SESSION');
        
        CacheBuster.checkVersion();
        resumeInit();
      }
    }
    return () => {
      // TODO document why this arrow function is empty
    };
  }, [location, checkAuth, routeGuard]);

  React.useEffect(() => {
    getKcdcTotalQuantity();
    const intervalId = setInterval(() => {
      getKcdcTotalQuantity();
    }, 1000 * 30);

    return () => {
      clearInterval(intervalId);
    };
  }, [StoreManager.currentStore.StoreId]);

  const initScanditMatrixScand = async (licenseKey: string) => {
    await configure({
      licenseKey: licenseKey,
      libraryLocation: new URL("./scandit/build/", document.baseURI).toString(),
      moduleLoaders: [barcodeCaptureLoader()],
    });
    let _view = new DataCaptureView();
    let _context: DataCaptureContext = await DataCaptureContext.create();
    await _view.setContext(_context);
    const _camera: Camera = Camera.default;
    const cameraSettings = BarcodeTracking.recommendedCameraSettings;
    await _camera.applySettings(cameraSettings);
    await _context.setFrameSource(_camera);
    const settings: BarcodeTrackingSettings =
      BarcodeTrackingSettings.forScenario(BarcodeTrackingScenario.A);
    settings.enableSymbologies([
      Symbology.EAN13UPCA,
      Symbology.EAN8,
      Symbology.UPCE,
      Symbology.Code39,
      Symbology.Code128,
    ]);
    const symbologyCode39Settings: SymbologySettings =
      settings.settingsForSymbology(Symbology.Code39);
    symbologyCode39Settings.activeSymbolCounts = [
      7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ];
    const symbologyCode128Settings: SymbologySettings =
      settings.settingsForSymbology(Symbology.Code128);
    symbologyCode128Settings.activeSymbolCounts = [
      7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ];
    const symbologyUPCASettings: SymbologySettings =
      settings.settingsForSymbology(Symbology.EAN13UPCA);
    symbologyUPCASettings.setExtensionEnabled("remove_leading_upca_zero", true);

    const _barcodeTracking = await BarcodeTracking.forContext(
      _context,
      settings
    );
    await _barcodeTracking.setEnabled(false);
    const _barcodeTrackingOverlay: BarcodeTrackingBasicOverlay =
      await BarcodeTrackingBasicOverlay.withBarcodeTrackingForView(
        _barcodeTracking,
        _view
      );

    dispatch(allActions.scandItActions.scandItContext(_context));
    dispatch(allActions.scandItActions.scandItView(_view));
    dispatch(allActions.scandItActions.scandItCamera(_camera));
    dispatch(
      allActions.scandItActions.scandItBarCodeTracking(_barcodeTracking)
    );
    dispatch(
      allActions.scandItActions.scandItBarCodeTrackingOverlay(
        _barcodeTrackingOverlay
      )
    );
  };

  const initScanditFocused = async (licenseKey: string) => {
    await configure({
      licenseKey: licenseKey,
      libraryLocation: new URL("./scandit/build/", document.baseURI).toString(),
      moduleLoaders: [barcodeCaptureLoader()],
    });
    let _view = new DataCaptureView();
    let _context: DataCaptureContext = await DataCaptureContext.create();
    await _view.setContext(_context);
    const _camera: Camera = Camera.default;
    const cameraSettings = BarcodeCapture.recommendedCameraSettings;
    await _camera.applySettings(cameraSettings);
    await _context.setFrameSource(_camera);
    const settings: BarcodeCaptureSettings = new BarcodeCaptureSettings();
    settings.locationSelection = new RadiusLocationSelection(
      new NumberWithUnit(0.2, MeasureUnit.Fraction)
    );
    settings.enableSymbologies([
      Symbology.EAN13UPCA,
      Symbology.EAN8,
      Symbology.UPCE,
      Symbology.Code39,
      Symbology.Code128,
    ]);
    const symbologyCode39Settings: SymbologySettings =
      settings.settingsForSymbology(Symbology.Code39);
    symbologyCode39Settings.activeSymbolCounts = [
      7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ];
    const symbologyCode128Settings: SymbologySettings =
      settings.settingsForSymbology(Symbology.Code128);
    symbologyCode128Settings.activeSymbolCounts = [
      7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ];
    const symbologyUPCASettings: SymbologySettings =
      settings.settingsForSymbology(Symbology.EAN13UPCA);
    symbologyUPCASettings.setExtensionEnabled("remove_leading_upca_zero", true);

    const _barcodeCapture = await BarcodeCapture.forContext(_context, settings);
    await _barcodeCapture.setEnabled(false);
    const _barcodeCaptureOverlay: BarcodeCaptureOverlay =
      await BarcodeCaptureOverlay.withBarcodeCaptureForView(
        _barcodeCapture,
        _view
      );
    const brush = new Brush(
      Color.fromHex("#50C878"),
      Color.fromHex("#50C878"),
      1
    );
    _barcodeCaptureOverlay.setBrush(brush);
    const viewfinder: AimerViewfinder = new AimerViewfinder();
    await _barcodeCaptureOverlay.setViewfinder(viewfinder);
    dispatch(allActions.scandItActions.scandItContext(_context));
    dispatch(allActions.scandItActions.scandItView(_view));
    dispatch(allActions.scandItActions.scandItCamera(_camera));
    dispatch(allActions.scandItActions.scandItBarCodeCapture(_barcodeCapture));
    dispatch(
      allActions.scandItActions.scandItBarCodeCaptureOverlay(
        _barcodeCaptureOverlay
      )
    );
  };
  const initScandit = async () => {
    let configs = await StoreManager.getConfigsForScandit();
    let activeType = Utils.getConfigurationWithKey("ScanTypeMatrix");
    let loadScandit = Utils.getConfigurationWithKey("LoadScanditOnStart");
    if (loadScandit == "1") {
      if (activeType == "1") {
        await initScanditMatrixScand(configs.scanditLicense);
      } else {
        await initScanditFocused(configs.scanditLicense);
      }
    }
  };

  React.useEffect(() => {
    if (!Utils.isExternalRoute()) {
      showNotificationToaster();
    }
    const startDataDog = async () => {
      await initDataDog();
    };
    const startScandit = async () => {
      await initScandit();
    };
    startDataDog();
    if (!Utils.isExternalRoute() && Utils.isMobile) {
      startScandit();
    }
  }, []);

  React.useEffect(() => {
    let value = compactNavbarWidth ? true : false;
    if (!value) {
      setCompactNavbarToggle(getToggleValue());
    } else {
      setCompactNavbarToggle(value);
    }
  }, [compactNavbarWidth]);

  const setHeader = () => {
    let list = PFManager.navigationData;
    let name =
      window.location.pathname.includes("/") &&
      window.location.pathname.length > 1
        ? window.location.pathname.split("/")[1]
        : window.location.pathname;
    if (
      name == "PickOrder" ||
      name == "PackOrder" ||
      name == "CustomerPickup" ||
      name == "BulkPickOrder"
    ) {
      name = "FulfillOrders";
    }
    if (name == "PendingReceipt") {
      name = "ReceiveOrders";
    }
    let selected = list.find((r) => name === r.Route);
    if (selected !== undefined && selected !== null) {
      setSelectedHeader(selected.Header);
      setMobileSelected(selected.Position);
    }
  };
  const toggleDrawer = () => {
    setdisplayDrawer(!displayDrawer);
  };

  const setSelectedOption = (newSelected: string) => {
    setSelectedHeader(newSelected);
    setdisplayDrawer(false);
  };
  const changeState = (data: any) => setData(data);
  const renderDrawer = () => {
    const height = Utils.displayHeight + "px";
    let path = location.pathname.replace("/", "");
    let n = 1.15;
    if (path && path.length > 0) {
      if (path.toLowerCase() !== "dashboard") {
        n = 1.09;
      }
    }

    const webbarHeight = Utils.isMobile
      ? Utils.getMobilebarheight * n + "px"
      : Utils.getWebbarheight + "px";

    const loaderLeft = Utils.isMobile ? "42%" : "48%";
    const displayDrawerData = IdentityManager.getUserNavigation();

    return (
      <React.Fragment>
        <React.Fragment>
          {Utils.isMobile && displayDrawer && (
            <div
              style={{
                height: height,
                width: "100vw",
                position: "absolute",
                zIndex: 2,
                top: "40px",
              }}
              onClick={toggleDrawer}
            >
              <div
                style={{
                  height: height,
                  width: "200px",
                  background: "#F3F3F4",
                  zIndex: 3,
                  marginTop: "24px",
                }}
              >
                {displayDrawerData.map((item: any, _i: number) => (
                  <DrawerRow
                    data={item}
                    key={Utils.generateRandom(
                      10,
                      Constants.CryptoRandomTypes.numeric
                    )}
                    selected={selected}
                    setSelectedOption={setSelectedOption}
                  ></DrawerRow>
                ))}
              </div>
            </div>
          )}
        </React.Fragment>
        {Utils.isExternalRoute() ? (
          ""
        ) : (
          <React.Fragment>
            <Navbar
              key={Utils.generateRandom(
                10,
                Constants.CryptoRandomTypes.numeric
              )}
              loading={loading}
              changeState={changeState}
              toggleDrawer={toggleDrawer}
              setSelectedOption={setSelectedOption}
              openFullScreenSearch={fullScreenSearchOpen}
              changeStoreList={changeStoreList}
              store={store}
              district={district}
              changeDistrict={changeDistrict}
              region={region}
              changeRegion={changeRegion}
              setuserProfileDialog={setuserProfileDialog}
              setFeedBackDialog={setFeedBackDialog}
              compactNavbarToggle={compactNavbarToggle}
              setCompactNavbarAction={setCompactNavbarAction}
              compactNavbarWidth={compactNavbarWidth}
            />
          </React.Fragment>
        )}
        {loading ? (
          <div style={{ position: "absolute", top: "42%", left: loaderLeft }}>
            <TailSpin color="#D93A2F" height={80} width={80} />
          </div>
        ) : (
          renderBody(displayDrawerData, height, webbarHeight)
        )}
        {<ToastContainer style={{ width: "500px" }} autoClose={120000} />}
      </React.Fragment>
    );
  };

  const renderBody = (
    displayDrawerData: any,
    height: any,
    webbarHeight: any
  ) => {
    if (!Utils.isMobile) {
      return (
        <div className="drawerDiv" style={{ marginTop: webbarHeight }}>
          <div>
            {Utils.isExternalRoute()
              ? ""
              : renderNavigation(displayDrawerData, height)}
          </div>
          <div
            className={
              Utils.isExternalRoute()
                ? "renderExternalComponent"
                : "renderComponent"
            }
            style={{
              minHeight: height,
              marginLeft: Utils.isExternalRoute()
                ? "0px"
                : !compactNavbarToggle
                ? "240px"
                : compactNavbarWidth || compactNavbarToggle
                ? "71px"
                : "240px",
            }}
          >
            <div style={{ overflowY: "hidden",background:"#F9F9F9"}}>{renderRoutes()}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="drawerDiv" style={{ marginTop: webbarHeight }}>
          {Utils.isExternalRoute()
            ? ""
            : renderNavigation(displayDrawerData, height)}
          <Box sx={{ pb: 7 }}>
            <CssBaseline />
            <div className="renderComponent" style={{ minHeight: height }}>
              <div>{renderRoutes()}</div>
            </div>
          </Box>
        </div>
      );
    }
  };
  const changeNavigationFromDashboard = (route: string, type: number) => {
    let selectedRoute = IdentityManager.getUserNavigation().filter(
      (e) => e.Route == route
    )[0];
    setSelectedHeader(selectedRoute.Header);
    setMobileSelected(selectedRoute.position);
    setdisplayDrawer(false);
    if (type != 0) {
      dispatch(allActions.fulfillOrdersActions.changeFOTab(type));
    }
    navigate(route);
  };

  const setCompactNavbarAction = () => {
    localStorage.setItem(
      Constants.CompactNavbarToggle,
      (!compactNavbarToggle).toString()
    );
    setCompactNavbarToggle(!compactNavbarToggle);
  };

  const setMobileNavSelectedValue = (mobile: number) => {
    setMobileSelected(mobile);
  };
  const renderRoutes = () => {
    return (
      <Routes>
        <Route
          path="/"
          element={
            <Dashboard
              navigateToDrawer={changeNavigationFromDashboard}
              store={store}
              district={district}
              changeDistrict={changeDistrict}
              changeStore={changeStoreList}
              changeRegion={changeRegion}
              selectProfileStores={selectProfileStores}
              selectAllRegions={selectAllRegions}
            />
          }
        />
        <Route
          path="/Dashboard"
          element={
            <Dashboard
              navigateToDrawer={changeNavigationFromDashboard}
              store={store}
              district={district}
              changeDistrict={changeDistrict}
              changeStore={changeStoreList}
              changeRegion={changeRegion}
              selectProfileStores={selectProfileStores}
              selectAllRegions={selectAllRegions}
            />
          }
        />
        <Route
          path="/FulfillOrders"
          element={<FulfillOrders store={store} />}
        />
        <Route path="/PickOrder" element={<PickOrder store={store} />} />
        <Route path="/BulkPickOrder" element={<BulkPickOrder />} />
        <Route path="/PackOrder" element={<PackOrder store={store} />} />
        <Route
          path="/ReceiveOrders"
          element={<ReceiveOrders store={store} />}
        />
        <Route path="/KCDCReturns" element={<KCDCReturns store={store} />} />
        <Route path="/Reports" element={<Reports store={store} />} />
        <Route
          path="/OrdersSyncFlow"
          element={<OrdersSyncFlow store={store} />}
        />
        <Route
          path="/CustomerPickup"
          element={<CustomerPickup store={store} />}
        />
        <Route path="/NotAuthorized" element={<NotAuthorized />} />
        <Route
          path="/PendingReceipt"
          element={<PendingReceipt store={store} />}
        />
        <Route path="/Search" element={<SearchResult />} />
        <Route path="/OrderDetail" element={<OrderDetail />} />
        <Route
          path="/KCDCReturnDetail"
          element={<KCDCReturnDetail store={store} />}
        />
        <Route
          path="/ExternalCustomerView"
          element={<ExternalCustomerView />}
        />
        <Route path="/TechAudits" element={<TechAudits store={store} />} />
        <Route path="/Feedback" element={<Feedback store={store} />} />
        <Route
          path="/OrderHistory"
          element={<FulfilledOrdersHistory store={store} />}
        />
      </Routes>
    );
  };

  const renderNavigation = (displayDrawerData: any, height: any) => {
    if (!Utils.isMobile && !compactNavbarToggle) {
      return (
        <>
          <div className="drawer" style={{ minHeight: height}}>
            {displayDrawerData.map((item: any, i: number) => (
              <DrawerRow
                data={item}
                key={i}
                value={i}
                selected={selected}
                setSelectedOption={setSelectedOption}
              ></DrawerRow>
            ))}
            <div style={{ position: "fixed", left: "226px", top: 141 }}>
              <Tooltip
                title={
                  <>
                    <Typography variant="body2" sx={{ fontSize: 15 }}>
                      {"Collapse Menu"}
                    </Typography>
                  </>
                }
                placement="bottom-start"
              >
                <div className="navigation-icon">
                  <KeyboardArrowLeftIcon
                    style={{ fontSize: "20px" }}
                    onClick={setCompactNavbarAction}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        </>
      );
    }

    if (!Utils.isMobile && (compactNavbarToggle || compactNavbarWidth)) {
      return (
        <>
          <CompactNavbar
            displayDrawerData={displayDrawerData}
            selected={selected}
            setSelectedOption={setSelectedOption}
          />
          <div style={{ position: "fixed", left: "57px", top: 146 }}>
            <Tooltip
              title={
                <>
                  <Typography variant="body2" sx={{ fontSize: 15 }}>
                    {"Expand Menu"}
                  </Typography>
                </>
              }
              placement="bottom-start"
            >
              <div className="navigation-icon">
                <KeyboardArrowRightIcon
                  style={{ fontSize: "20px" }}
                  onClick={setCompactNavbarAction}
                />
              </div>
            </Tooltip>
          </div>
        </>
      );
    } else {
      return (
        <MobileNavbar
          displayDrawerData={displayDrawerData}
          selected={mobileSelected}
          setMobileNavSelectedValue={setMobileNavSelectedValue}
        />
      );
    }
  };
  const renderError = () => {
    return (
      <div className="UserError">
        <div className="title">
          <ErrorIcon style={{ fontSize: "60px" }} />
          ERROR
        </div>
        <div className="subtitle">
          {omsProfileNotSetUpError && omsProfileNotSetUpError.length > 0
            ? `${omsProfileNotSetUpError}`
            : ""}
          Your profile is not configured to access this resource
        </div>
        <div className="subtitle">
          Please{" "}
          <a
            onClick={() => {
              AuthManager.logout();
            }}
            href="#"
          >
            click here
          </a>{" "}
          to Logout and Login again. In case if issue still persist contact the
          IT Help Desk (818) 735-8800 ext. 2660 or email{" "}
          <a href={"mailto:gwitickets@guitarcenter.com"}>
            gwitickets@guitarcenter.com
          </a>
        </div>
      </div>
    );
  };
  return (
    <div className="App">
      <GenericSuccessNotification></GenericSuccessNotification>
      {isNavigationLoaded ? (
        <React.Fragment>
          <ActionLoader></ActionLoader>
          {showFullScreenSearch ? (
            <FullScreenSearch
              fullScreenClose={fullScreenSearchClose}
            ></FullScreenSearch>
          ) : (
            <></>
          )}
          <UserInfoDialog
            changeState={changeState}
            open={userProfileDialog}
            setuserProfileDialog={setuserProfileDialog}
          ></UserInfoDialog>
          {Utils.isExternalRoute() ? (
            <></>
          ) : (
            <FeedbackDialog
              open={feedBackDialog}
              setFeedBackDialog={setFeedBackDialog}
            ></FeedbackDialog>
          )}
          {error ? renderError() : renderDrawer()}
        </React.Fragment>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "42%",
            left: Utils.isMobile ? "42%" : "48%",
          }}
        >
          <TailSpin color="#D93A2F" height={80} width={80} />
        </div>
      )}
    </div>
  );
};

export default App;
