import React from 'react';
import { Card, CardContent, Accordion, AccordionSummary, AccordionDetails, IconButton, TableContainer, Table, TableRow, TableCell, TableBody, Typography, Grid } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import StoreCard from './StoreCard';
import { CloseIcon } from '../Subcomponent/Shared/SharedIcons';

const DistrictExpandedRows = (props: any) => {

    const { data, districtColumns, storeColumns } = props;
    console.log("region", data);

    return (
        <div id='cardOrder'>
                    <TableContainer>
                        <Table className=''>
                            <TableBody>
                                {data.regionDistrictList.map((district: any) => (
                                    <TableRow key={district.id}>
                                        <TableCell colSpan={districtColumns()?.length}>
                                            <Accordion className='dCard'>
                                                <AccordionSummary expandIcon={<KeyboardArrowDown />} aria-controls="panel1a-content" id="panel1a-header">
                                                    <div style={{ display: 'flex', flexDirection: 'column',width:"100%" }}>
                                                        {districtColumns()?.map((column: any, index: number) => (
                                                            index !== districtColumns()?.length - 1 ?
                                                                <div key={column.id} style={{ display: 'flex', flexDirection: 'row', alignItems:"center" }}>
                                                                    {index !== 0 && ( // Check if it's not the first column
                                                                        <Typography style={{ color: "#676767", marginRight: '8px', fontSize:"12px",marginLeft:"8px", fontStyle:"normal",fontWeight:400,lineHeight:"16px" }}>
                                                                            {column.name}
                                                                        </Typography>
                                                                    )}
                                                                    <Typography style={{color: "#333",fontSize:"13px",fontStyle:"normal",fontWeight:500,lineHeight:"20px"}}>
                                                                        <strong>{column.cell(district)}</strong>
                                                                    </Typography>
                                                                </div>
                                                                :
                                                                <div key={column.id}>
                                                                    <Typography style={{ width: 'auto', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                        <strong>{column.cell(district)}</strong>
                                                                    </Typography>
                                                                </div>
                                                        ))}
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div style={{ overflow: 'auto', width:"100%" }}>
                                                        {district.districtStoresList && district.districtStoresList.map((store: any, index: number) => (
                                                            <StoreCard key={store.id} store={store} storeColumns={storeColumns()} />
                                                        ))}
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
        </div>
    );



};

export default DistrictExpandedRows;
