export default class LabelText {
    public static NotificationPopupHeaders = {
        Success: "Success",
        Error: "Error",
        Warning: "Warning",
        Info: "Info",
        Resource: "Resource"
    }
    public static PickOrder = {
        PickCancelOrderWarning: "You are about to cancel some or all of a customer's order. Are you sure you want to cancel?",
        PickResourceOrderWarning: "You are about to re-source some or all of a customer's order. Are you sure you want to re-source?",
        PickCompleteSuccess: "Pick for Order is Complete",//"Pick for Order # GCW92863826 is Complete",
        ContactCustomerTitle: "Contact Customer",
        BoxTitle: "Box Details",
        Note: "Add Note",
        PickSlipsPrint: "Please select an option below to group pick slips data"
    }

    public static CustomerPickup = {
        CustomerSlipsDate: "Please select the duration from the options below",
        NoRecordsInfo:"There are no records to print.",
        CustomerSlipsSortOrder: "Please select the sorting option for Order Age",
    }

    public static MAILTEMPLATES = {
        notOriginalPackaging: `Sohaib Javed
  
Thank you for your recent order. We're reaching out to let you know that one of the items you ordered is not available at our warehouse. However, we located the same product at our LOCATION Guitar Center store. That's why the BRAND MODEL GEARI you purchased may not arrive in its original packaging. But rest assured that the item is new, and you still get the full manufacturer's warranty.
  
If you'd prefer to choose another piece of gear instead, please call or email us within 48 hours to discuss options; otherwise, we'll go ahead and ship your order.
  
Thank you,
Store Manager
Guitar Center - LOCATION
XXX-XXX-XXXX`,
        outOfBox: `[FIRST_NAME]
Thank you for your recent order. We're reaching out to let you know that one of the items you ordered is not available at our warehouse, but we located the same product at our LOCATION] Guitar Center store. However, the [BRAND_MODEL_GEARI may not arrive in its original packaging and showsvisible signs of wear, including space for associate to describe blemishes]. But rest assured that you still get the full manufacturer's warranty.
We will wait to hear from you before shipping the item. If you'd prefer to choose another piece of gear instead, please call or email us to discuss options.
Thank you,
[STORE_MANAGER] Store Manager Guitar Center - [LOCATION] XXX-XXX-XXXX`
    }
    public static MAILSUBJECTS = {
        notOriginalPackaging: `Your order is like-new condition, but not in original packaging`,
        outOfBox: `Your order is like-new condition, and is out of box`
    }
    public static PendingReceipts = {
        statusApprval: "Status Approval Credentials"
    }
    public static MarkINVC = {
        detail: "Are you sure you want to mark this item INVC?"
    }

    public static ExternalOrderView = {
        CancelOrderWarning: "You are about to cancel some or all of order Line Item(s). Are you sure you want to cancel?",
        ExternalPickCancelOrderWarning: "You are about to cancel some or part of your order. Are you sure you want to cancel?",
    }


    public static generalErrorMessage = "Something went wrong please contact the IT Help Desk for assistance";
    public static noCommonCodeListError = "There is a problem with the order, Please contact the IT Help Desk for assistance"
    public static invalidQuantity = "Please enter valid quantity";
    public static invalidItemId = "Invalid Item ID: Item ID does not match with Order Lines";
    public static noShipmentLines = "No Shipment Lines Found.";

    public static omsProfileNotConfigured = "OMS Profile is not configured."

    public static externalCancelOrder = "Your order has been changed and your request cannot be processed. Please contact our Customer Service at 866-498-7882.";

    public static errorMessage = "Something went wrong while processing your request.";

    public static systemWideErrorMessage = "It's a system-wide issue and our team is working on it. Please try again later.";

    public static lengthLimit = "Length must be ≤ 108 inches";
    public static girthLimit = "(2 x (Width + Height)) must be ≤ 165 inches";
    public static weightLimit = "Weight must be ≤ 150 lbs";
    public static dimensionsNote = "Package dimensions or weight are over-sized. Please ship LTL";
    public static noScanItem = "Scanned Item not found.";
}