import { Navigate, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { textAlign } from '@mui/system';
import { Typography } from '@material-ui/core';
import StoreManager from '../../Managers/Store.manager';
import allActions from '../../Store/Actions/AllActions';
import Constants from '../../Common/Constants';
import Utils from '../../Common/Utils';
import { Divider, imageListItemBarClasses } from '@mui/material';
import OMSManager from '../../Managers/Oms.manager';
import IdentityManager from '../../Managers/Identity.manager';
const primaryColor = "#D93A2F"
const shipFontSize = "12px"


var moment = require('moment');
var momenttz = require('moment-timezone');
const today = momenttz.tz("America/Los_Angeles").format(Constants.DateFormat.DateOnly);
const MultiPickSlip = (props: any) => {
    const dispatch = useDispatch();
    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());

    }

    // Use States Format Data
    const [formatedDataState, setFormatedDataState] = useState([] as any);
    const [shelvePickDataState, setShelvePickDataState] = useState({} as any);

    const currentStore = StoreManager.currentStore
    let orderRows = useSelector((state: any) => state.FulfillOrdersReducersState.orderRows);
    let pickSlipGroupByModal = useSelector((state: any) => state.FulfillOrdersReducersState.pickSlipGroupByModal);
    const getAgeTextFormat = (ms: any) => {
        const obj = Utils.convertHoursToDays(ms)

        if (obj.Days && obj.Hours) {
            return `${obj.Days} d(s) - ${obj.Hours} hr(s)`
        }
        if (obj.Days) {
            return `${obj.Days} d(s) `
        }
        if (obj.Hours) {
            return `${obj.Hours} hr(s) `
        }
        if (obj.Minutes) {
            return `${obj.Minutes} min(s) `
        }
        if (obj.Sec) {
            return `${obj.Sec} sec(s) `
        } else { return `` }
    }
    const formatData = () => {
        let returnData: any = {};
        //
        let finalData: any = {};
        if (props.ordersToPrint && props.ordersToPrint.length > 0) {
            let shipLines: any[] = [];
            let orders: any[] = JSON.parse(JSON.stringify(orderRows));
            let fOrders = orders.filter((filteritem: any) => props.ordersToPrint.includes(filteritem._id));
            if (fOrders && fOrders.length > 0) {

                fOrders.forEach((element: any) => {
                    element._source.Shipment.ShipmentLinesPickSlip.forEach((item: any) => {

                        item.OrderNo = element._source.Shipment.OrderNo;
                        item.LevelOfService = element._source.Shipment.LevelOfService;
                        item.Age = getAgeTextFormat(element._source.Shipment.Age);
                        item.ParentCategoryID = "";
                        item.ParentCategoryIdDescription = "";
                        item.OrderStatus = element._source.Shipment.Status
                        if (item?.ItemDetails?.CategoryList?.Category) {
                            item.ParentCategoryID = item?.ItemDetails?.CategoryList?.Category.ParentCategoryID;
                            if (item.ParentCategoryID && item.ParentCategoryID.length > 0) {
                                if (OMSManager.catalogCategories && OMSManager.catalogCategories.Category && OMSManager.catalogCategories.Category.length > 0) {
                                    let fCategory: any = OMSManager.catalogCategories.Category.filter(a => a.CategoryID == item.ParentCategoryID);
                                    if (fCategory && fCategory.length > 0) {
                                        item.ParentCategoryIdDescription = fCategory[0].ShortDescription;
                                    }
                                }
                                else {
                                    item.ParentCategoryIdDescription = item.ParentCategoryID;
                                }

                            }
                        }
                        shipLines.push(item);

                    });
                });


                // get Valid Ship Lines
                //shipLines = Utils.getValidShipLines(shipLines);

                switch (pickSlipGroupByModal.value) {
                    case "0":
                        returnData = Utils.groupBy(shipLines, "OrderNo");
                        break;
                    case "1":
                        returnData = Utils.groupBy(shipLines, "LevelOfService");
                        break;
                    case "2":
                        returnData = Utils.groupBy(shipLines, "Age");
                        break;
                    case "3":
                        returnData = Utils.groupBy(shipLines, "ItemID");
                        break;
                    case "4":
                        returnData = Utils.groupBy(shipLines, "ParentCategoryIdDescription");
                        break;
                    default:
                        break;
                }

                console.log(returnData, "formatData");

            }



            let keys = Object.keys(returnData);
            if (keys && keys.length > 0) {
                for (const key of keys) {
                    let newShipLines = [];
                    let currentKeyData = returnData[key];
                    if (currentKeyData && currentKeyData.length > 0) {

                        // get distinct Item Id
                        const unique = currentKeyData.map((item: any) => item.ItemID)
                            .filter((value: any, index: any, self: any) => self.indexOf(value) === index)

                        if (unique && unique.length > 0) {
                            // get each unique count and 

                            for (const u of unique) {
                                let sumItems = currentKeyData.filter((a: any) => a.ItemID == u);
                                let sum = 0;
                                for (const item of sumItems) {
                                    sum = sum + (+item.OrderedQty)
                                }
                                let fItem = currentKeyData.filter((a: any) => a.ItemID == u);
                                if (fItem && fItem.length > 0) {
                                    let firsOne = fItem[0];
                                    firsOne.OrderedQty = sum;
                                    newShipLines.push(firsOne);
                                }
                            }
                        }
                        finalData[key] = newShipLines;
                    }
                }
            }

        }
        console.log(orderRows, "orderRows");
        return finalData;
    }

    React.useEffect(() => {
        async function init() {
            if (props.isPickSlip) {
                try {
                    showActionLoader()
                    let allData: any = []
                    props.ordersToPrint.forEach((elemet: any) => {
                        const orderRowsDeepCopy = JSON.parse(JSON.stringify(orderRows));
                        let itemDetailFromElastic = orderRowsDeepCopy?.filter((filteritem: any) => filteritem._id == elemet)[0];
                        let shipmentLine = itemDetailFromElastic._source.Shipment.ShipmentLinesPickSlip
                        let customerAddress = getCustomerDetails(itemDetailFromElastic._source.Shipment)
                        allData.push({ itemDetailFromElastic: itemDetailFromElastic, shipmentLine: shipmentLine, customerAddress: customerAddress })
                    });
                    let itemIds: any = []

                    allData.forEach((uElement: any) => {
                        let storeId = uElement.itemDetailFromElastic?._source?.Shipment?.ShipNode;
                        const orderNo = uElement?.itemDetailFromElastic?._source?.Shipment?.OrderNo ?? "";
                        uElement?.itemDetailFromElastic?._source?.Shipment?.ShipmentLines?.ShipmentLine?.forEach((element: any) => {
                            if (element?.ItemDetails?.Extn?.ExtnPOSItemID) {
                                if (!itemIds.some((item: any) => item?.posItemId === element?.ItemDetails?.Extn?.ExtnPOSItemID)) {
                                    itemIds.push({ "location": storeId, "posItemId": element?.ItemDetails?.Extn?.ExtnPOSItemID, orderNo})

                                }
                            }
                        });
                    });

                    let itemsLocationsData = await OMSManager.getItemLocationDetails(itemIds);;

                    if (itemsLocationsData?.data?.length > 0) {

                        allData.forEach((element: any) => {
                            element?.shipmentLine?.forEach((shipment: any) => {
                                const itemLocation = itemsLocationsData?.data?.filter((location: any) => location?.sku === shipment?.ItemDetails?.Extn?.ExtnPOSItemID);

                                if (itemLocation.length > 0) {
                                    shipment.ItemLocationInfo = itemLocation;
                                } else {
                                    // Handle the case where no matching location is found
                                    shipment.ItemLocationInfo = [];
                                }

                            });
                        });
                    }


                    setFormatedDataState(allData)
                    setTimeout(() => {
                        props.setMultiPickSlipOpenIFrame(true)
                        hideActionLoader()
                    }, 100);
                }
                catch {
                    hideActionLoader()
                }
            }
            else {
                // Shelve Pick List

                try {
                    showActionLoader()

                    let itemIds: any = []
                    let storeId = StoreManager.currentStore.StoreId;
                    const orderData = formatData();
                    const objectKeys = Object.keys(orderData)
                    objectKeys.forEach((el: any) => {
                        orderData[el].forEach((shipment: any) => {
                            if (shipment?.ItemDetails?.Extn?.ExtnPOSItemID) {
                                if (!itemIds.some((item: any) => item?.posItemId === shipment?.ItemDetails?.Extn?.ExtnPOSItemID)) {
                                    itemIds.push({ "location": storeId, "posItemId": shipment?.ItemDetails?.Extn?.ExtnPOSItemID, "orderNo": shipment?.OrderNo ?? "" })

                                }
                            }
                        })
                    })

                    let itemsLocationsData = await OMSManager.getItemLocationDetails(itemIds);
                    if (itemsLocationsData?.data?.length > 0) {
                        objectKeys.forEach((el: any) => {
                            orderData[el].forEach((shipment: any) => {
                                const itemLocation = itemsLocationsData?.data?.filter((location: any) => location?.sku === shipment?.ItemDetails?.Extn?.ExtnPOSItemID);
                                if (itemLocation) {
                                    shipment.ItemLocationInfo = itemLocation;
                                } else {
                                    // Handle the case where no matching location is found
                                    shipment.ItemLocationInfo = [];
                                }
                            })
                        })
                    }

                    setShelvePickDataState(orderData)
                    setTimeout(() => {
                        props.setMultiPickSlipOpenIFrame(true)
                        hideActionLoader()
                    }, 100);
                } catch {
                    hideActionLoader()
                }




            }
        }
        init();
    }, [props.ordersToPrint, pickSlipGroupByModal.value]);
    const getpickSlipGroupByModalValue = (item: any) => {

        let value: any = "";
        let fValue = Constants.choicesValues.filter(a => a.id == pickSlipGroupByModal.value);
        if (fValue && fValue.length > 0) {
            value = fValue[0].val;
        }

        return { value, item };

    }

    const renderOneGroupData = (lines: any[]) => {
        let srCount = 0
        if (pickSlipGroupByModal.value == "3" || pickSlipGroupByModal.value == "4") {
            return lines.map((item: any, index: any) => {
                if (item.ExtnIsWarrantyItem == "N") {
                    return (
                        <>
                            <tr>
                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                    {renderTableHeading(index + 1)}
                                </td>
                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                    {renderTableHeading(item?.ItemDetails?.Extn?.ExtnPOSItemID)}
                                </td>
                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">

                                    {renderTableHeading(item.ItemDesc)}
                                </td>
                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                    {renderTableHeading(item.UnitPrice)}
                                </td>
                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                    {renderTableHeading(item.OrderedQty)}
                                </td>
                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                    {item?.ItemLocationInfo?.length > 0 ? renderTableHeading(item?.ItemLocationInfo[0]?.piDate) : "-"}

                                </td>
                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                    {item?.ItemLocationInfo?.length > 0 ? renderTableHeading(item?.ItemLocationInfo[0]?.rxDate) : "-"}

                                </td>
                            </tr>
                            <tr style={{ textAlign: "left" }} className="picklistTableCollaped">
                                <td colSpan={9}>
                                    {item?.ItemLocationInfo?.length > 0 ? renderTableLocations(item?.ItemLocationInfo[0]?.items) : ""}
                                </td>

                            </tr>
                        </>


                    );
                }
            })
        }
        else {
            return lines.map((item: any, index: any) => {
                if (item.BundleParentOrderLineKey == undefined) {
                    srCount = srCount + 1
                    return (
                        <>
                            <tr>
                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                    {renderTableHeading(srCount)}
                                </td>
                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                    {renderTableHeading(item?.ItemDetails?.Extn?.ExtnPOSItemID)}
                                </td>
                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">

                                    {renderTableHeading(item.ItemDesc)}
                                </td>
                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                    {renderTableHeading(item.UnitPrice)}
                                </td>
                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                    {renderTableHeading(item.OrderedQty)}
                                </td>
                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                    {item?.ItemLocationInfo?.length > 0 ? renderTableHeading(item?.ItemLocationInfo[0]?.piDate) : "-"}

                                </td>
                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                    {item?.ItemLocationInfo?.length > 0 ? renderTableHeading(item?.ItemLocationInfo[0]?.rxDate) : "-"}

                                </td>
                            </tr>
                            <tr style={{ textAlign: "left" }} className="picklistTableCollaped">
                                <td colSpan={9}>
                                    {item?.ItemLocationInfo?.length > 0 ? renderTableLocations(item?.ItemLocationInfo[0]?.items) : ""}
                                </td>

                            </tr>
                            {renderBundleChildRows(item.OrderLineKey, lines)}
                        </>
                    );
                }
            })
        }

    }

    const renderBundleChildRows = (bundleParentOrderLineKey: any, shipmentLine: any) => {
        return shipmentLine.map((item: any, index: any) => {
            if (item.BundleParentOrderLineKey && item.BundleParentOrderLineKey == bundleParentOrderLineKey) {
                return (
                    <>

                        <tr>
                            <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                {""}
                            </td>
                            <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                {renderTableHeading(item?.ItemDetails?.Extn?.ExtnPOSItemID)}
                            </td>
                            <td style={{ textAlign: "left" }} className="picklistTableCollaped">

                                {renderTableHeading(item.ItemDesc)}
                            </td>
                            <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                {renderTableHeading(item.UnitPrice)}
                            </td>
                            <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                {renderTableHeading(item.OrderedQty)}
                            </td>
                            <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                {item?.ItemLocationInfo?.length > 0 ? renderTableHeading(item?.ItemLocationInfo[0]?.piDate) : "-"}

                            </td>
                            <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                {item?.ItemLocationInfo?.length > 0 ? renderTableHeading(item?.ItemLocationInfo[0]?.rxDate) : "-"}

                            </td>
                        </tr>
                        <tr style={{ textAlign: "left" }} className="picklistTableCollaped">
                            <td colSpan={9}>
                                {item?.ItemLocationInfo?.length > 0 ? renderTableLocations(item?.ItemLocationInfo[0]?.items) : ""}
                            </td>

                        </tr>
                    </>

                );

            }

        })

    }

    const renderTableHeading = (text: any) => {

        return <Typography variant='body1' style={{}}><span style={{ fontSize: "12px" }}>{text}</span></Typography>;
    }

    const renderTableLocations = (items: any) => {

        return <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>



            {items?.map((item: any, index: any) => {
                return <div style={{ display: "flex", flexDirection: "column" }}>
                    <div key={index} style={{ marginRight: '20px' }}>
                        <div >
                            {((index === 0) || (index % 7 === 0)) && <Typography variant='body1' display="inline" style={{ fontSize: "12px", padding: "5px", fontWeight: 700, paddingRight: "28px" }}>SKU: </Typography>} <Typography variant='body1' display="inline" style={{ fontSize: "12px", padding: "5px", minWidth: "30px", textAlign: "left" }}>{item['SKU.Number'] || '-'}</Typography>
                        </div>
                        <div >
                            {((index === 0) || (index % 7 === 0)) && <Typography variant='body1' display="inline" style={{ fontSize: "12px", padding: "5px", fontWeight: 700 }}>Location: </Typography>}  <Typography display="inline" style={{ fontSize: "12px", padding: "5px", minWidth: "30px", textAlign: "left" }}> {item['LastPIArea'] || '-'}</Typography>
                        </div>

                    </div>
                    {<Divider style={{ width: "100%" }}></Divider>}
                </div>
            })}
        </div>

    }
    const getCustomerDetails = (data: any) => {
        let initialCustomerDetail = {
            name: "",
            city: "",
            email: "",
            phone: "",
            zipCode: "",
            address: "",
            Country: "",
            state: ""
        }
        if (data.CustomerInfo != undefined) {
            try {
                if (data.CustomerInfo.hasOwnProperty("FirstName")) {
                    initialCustomerDetail.name = data.CustomerInfo.FirstName
                }
                if (data.CustomerInfo.hasOwnProperty("LastName")) {
                    initialCustomerDetail.name = initialCustomerDetail.name + " " + data.CustomerInfo.LastName
                }
                if (data.CustomerInfo.hasOwnProperty("City")) {
                    initialCustomerDetail.city = data.CustomerInfo.City
                }
                if (data.CustomerInfo.hasOwnProperty("State")) {
                    initialCustomerDetail.state = data.CustomerInfo.state
                }
                if (data.CustomerInfo.hasOwnProperty("Country")) {
                    initialCustomerDetail.Country = data.CustomerInfo.Country
                }
                if (data.CustomerInfo.hasOwnProperty("CustomerEMailID")) {
                    initialCustomerDetail.email = data.CustomerInfo.CustomerEMailID
                }
                if (data.CustomerInfo.hasOwnProperty("ZipCode")) {
                    initialCustomerDetail.zipCode = data.CustomerInfo.ZipCode
                }
                if (data.CustomerInfo.hasOwnProperty("AddressLine1")) {
                    initialCustomerDetail.address = data.CustomerInfo.AddressLine1
                }
                if (data.CustomerInfo.hasOwnProperty("AddressLine2") && data.CustomerInfo.AddressLine2 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.CustomerInfo.AddressLine2
                }
                if (data.CustomerInfo.hasOwnProperty("AddressLine3") && data.CustomerInfo.AddressLine3 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.CustomerInfo.AddressLine3
                }
            }
            catch {
                console.log("Error Getting Customer Detail")
            }

        }
        return initialCustomerDetail
    }

    const renderPrintPickSlips = () => {
        // let data: any = formatData();
        let data: any = shelvePickDataState;

        let objKeys = Object.keys(data);
        if (objKeys && objKeys.length > 0) {

            return <div style={{ width: '100%' }}>

                <div style={{ display: "flex", width: "100%", marginTop: "5px", borderBottom: "1px solid black" }}>
                    <div style={{ width: "33%", textAlign: "left" }}>
                        <Typography variant='body1' style={{ textAlign: "left", color: "black", fontWeight: 500 }}>Order Picking List</Typography>
                        <Typography variant='body2' style={{ textAlign: "left", color: "black" }}>Store #: {IdentityManager.getStoreId(props.store)}</Typography>

                    </div>
                    <div style={{ width: "66%", textAlign: "right" }}>
                        <Typography variant='body2' style={{ textAlign: "right", color: "black" }}>Current Date: {today}</Typography>
                    </div>

                </div>

                {
                    objKeys.map((item: any) => {
                        return <div>
                            <div style={{ display: "flex", width: "100%", marginTop: "20px", fontWeight: 500, color: "black", padding: "10px", fontSize: "12px" }}>
                                <table style={{ width: "100%" }} cellPadding={0} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "10%" }}>
                                                <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>{getpickSlipGroupByModalValue(item).value}:</Typography>
                                            </td>
                                            <td style={{ width: "33%" }}>

                                                <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>{item}</Typography>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ display: "flex", width: "100%" }}>
                                <table style={{ width: "100%", borderRight: "1px solid black" }} cellPadding={0} cellSpacing={0} className="packingListTable">
                                    <thead style={{ color: "black", borderTop: "1px solid black" }}>
                                        <th style={{ width: "5%", textAlign: "left" }} className="picklistTableCollaped">
                                            <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Sr no.</Typography>
                                        </th>
                                        <th style={{ width: "10%", textAlign: "left" }} className="picklistTableCollaped">
                                            <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>POS Item ID</Typography>
                                        </th>
                                        <th style={{ width: "35%", textAlign: "left" }} className="picklistTableCollaped">

                                            <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Item Description</Typography>
                                        </th>
                                        <th style={{ width: "5%", textAlign: "left" }} className="picklistTableCollaped">
                                            <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Price</Typography>

                                        </th>
                                        <th style={{ width: "5%", textAlign: "left" }} className="picklistTableCollaped">
                                            <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Qty To Pick</Typography>

                                        </th>
                                        <th style={{ width: "10%", textAlign: "left" }} className="picklistTableCollaped">
                                            <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Last PI</Typography>

                                        </th>
                                        <th style={{ width: "10%", textAlign: "left" }} className="picklistTableCollaped">
                                            <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Receive Date</Typography>

                                        </th>
                                    </thead>
                                    {
                                        renderOneGroupData(data[item])
                                    }
                                </table>
                            </div>
                        </div>


                    })
                }
            </div>

        }
        else {
            return "";
        }

    }



    const renderPrintDiv = () => {
        return formatedDataState.map((item: any, i: number) => {

            //let itemDetailFromElastic = orderRows.filter((filteritem: any) => filteritem._id == item)[0];
            //let shipmentLine = itemDetailFromElastic._source.Shipment.ShipmentLinesPickSlip
            //let customerAddress = getCustomerDetails(itemDetailFromElastic._source.Shipment)
            let { itemDetailFromElastic, shipmentLine, customerAddress, ItemLocationInfo } = item

            let srCount = 0
            // aria-label = {`pdf-page-` + ++i }
            return (
                <div className={i == 0 ? "" : "breakPage"} style={{ width: '100%' }}>
                    {i == 0 ? <></> : <Typography variant='h5' style={{ textAlign: "center", color: "white", background: "white", paddingBottom: "10px", paddingTop: "10px" }}></Typography>}
                    <Typography variant='h5' style={{ textAlign: "center", color: "black", background: "#eee", paddingBottom: "10px", paddingTop: "10px" }}>PICK TICKET</Typography>
                    <div style={{ display: "flex", width: "100%", marginTop: "5px" }}>
                        <div style={{ width: "33%", textAlign: "left" }}>
                            <Typography variant='body1'> <span style={{ color: "black", borderBottom: "1px solid black", paddingBottom: "6px", fontWeight: 500 }}>Pick Request</span> </Typography>
                            <Typography variant='body2' style={{ paddingTop: "6px" }}>Ticket #:{moment().valueOf()}</Typography>
                            <br>
                            </br>
                            <Typography variant='body1' style={{ paddingTop: "6px", fontWeight: 500 }}>Current Date:</Typography>
                            <Typography variant='body2' style={{}}><span style={{}}>{today}</span></Typography>
                        </div>
                        <div style={{ width: "33%", textAlign: "left" }}>
                            <Typography variant='body1'> <span style={{ color: "black", fontWeight: 500 }}>Ship From:</span> </Typography>
                            <Typography variant='body2'>{currentStore.StoreName} </Typography>
                            <Typography variant='body2'> <span style={{}}>{currentStore.Company} {currentStore.id}</span> </Typography>
                            <Typography variant='body2'> <span style={{}}>{currentStore.Address}</span> </Typography>
                            <Typography variant='body2'> <span style={{}}>{currentStore.City} {currentStore.State} {currentStore.Zip}</span> </Typography>
                            <Typography variant='body2'> <span style={{}}>{currentStore.Phone}</span> </Typography>
                        </div>
                        <div style={{ width: "33%", textAlign: "left" }}>
                            <Typography variant='body1'> <span style={{ color: "black", fontWeight: 500 }}>Ship To:</span> </Typography>
                            <Typography variant='body2'> <span style={{}}>{customerAddress.name}</span> </Typography>
                            <Typography variant='body2'> <span style={{}}>{customerAddress.address}</span> </Typography>
                            <Typography variant='body2'> <span style={{}}>{customerAddress.state} {customerAddress.zipCode}</span> </Typography>
                            <Typography variant='body2'> <span style={{}}>{customerAddress.email}</span> </Typography>
                            <Typography variant='body2'> <span style={{}}>{customerAddress.Country}</span> </Typography>
                        </div>
                    </div>
                    <div style={{ display: "flex", width: "100%", marginTop: "5px" }}>
                        <div style={{ width: "33%", textAlign: "left" }}>
                            <Typography variant='body1' style={{ paddingTop: "6px", fontWeight: 500 }}>Order #:</Typography>
                            <Typography variant='body2' style={{}}><span style={{}}>{itemDetailFromElastic._source.Shipment.OrderNo}</span></Typography>
                        </div>
                        <div style={{ width: "33%", textAlign: "left" }}>
                            <Typography variant='body1' style={{ paddingTop: "6px", fontWeight: 500 }}>Ship Method:</Typography>
                            <Typography variant='body2' style={{}}><span style={{}}>{itemDetailFromElastic._source.Shipment.LevelOfService}</span></Typography>
                        </div>
                    </div>
                    <div style={{ display: "flex", width: "100%", marginTop: "40px" }}>
                        <table style={{ width: "100%", borderRight: "1px solid black" }} cellPadding={0} cellSpacing={0} className="packingListTable">
                            <thead style={{ color: "black", borderTop: "1px solid black" }}>
                                <th style={{ width: "5%", textAlign: "left" }} className="picklistTableCollaped">
                                    <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Sr no.</Typography>
                                </th>
                                <th style={{ width: "10%", textAlign: "left" }} className="picklistTableCollaped">
                                    <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>POS Item ID</Typography>
                                </th>
                                <th style={{ width: "35%", textAlign: "left" }} className="picklistTableCollaped">

                                    <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Item Description</Typography>
                                </th>
                                <th style={{ width: "5%", textAlign: "left" }} className="picklistTableCollaped">
                                    <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Price</Typography>

                                </th>
                                <th style={{ width: "5%", textAlign: "left" }} className="picklistTableCollaped">
                                    <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Qty To Pick</Typography>

                                </th>
                                <th style={{ width: "10%", textAlign: "left" }} className="picklistTableCollaped">
                                    <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Last PI</Typography>

                                </th>
                                <th style={{ width: "10%", textAlign: "left" }} className="picklistTableCollaped">
                                    <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Receive Date</Typography>

                                </th>

                            </thead>
                            {
                                shipmentLine.map((item: any, index: any) => {

                                    if (item.BundleParentOrderLineKey == undefined) {
                                        srCount = srCount + 1
                                        return (
                                            <>
                                                <tr>
                                                    <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                                        {renderTableHeading(srCount)}
                                                    </td>
                                                    <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                                        {renderTableHeading(item?.ItemDetails?.Extn?.ExtnPOSItemID)}
                                                    </td>
                                                    <td style={{ textAlign: "left" }} className="picklistTableCollaped">

                                                        {renderTableHeading(item.ItemDesc)}
                                                    </td>
                                                    <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                                        {renderTableHeading(item.UnitPrice)}
                                                    </td>
                                                    <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                                        {renderTableHeading(item.OrderedQty)}

                                                    </td>
                                                    <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                                        {item?.ItemLocationInfo?.length > 0 ? renderTableHeading(item?.ItemLocationInfo[0]?.piDate) : "-"}

                                                    </td>
                                                    <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                                        {item?.ItemLocationInfo?.length > 0 ? renderTableHeading(item?.ItemLocationInfo[0]?.rxDate) : "-"}

                                                    </td>
                                                </tr>
                                                <tr style={{ textAlign: "left" }} className="picklistTableCollaped">
                                                    <td colSpan={9}>
                                                        {item?.ItemLocationInfo?.length > 0 ? renderTableLocations(item?.ItemLocationInfo[0]?.items) : ""}
                                                    </td>

                                                </tr>

                                                {renderBundleChildRows(item.OrderLineKey, shipmentLine)}
                                            </>
                                        );

                                    }
                                })
                            }
                        </table>
                    </div>

                </div>

            );
        });
    }
    return (
        <div style={{display:"none"}}>
            {
                (formatedDataState.length > 0 || Object.keys(shelvePickDataState)?.length > 0) ?
                    <div id='PickSlipContainer' style={{ background: 'white' }}>
                        {
                            props.isPickSlip ? renderPrintDiv() : renderPrintPickSlips()
                        }
                    </div>
                    :
                    <div id='PickSlipContainerNOT'></div>
            }
        </div>
    );
}
export default MultiPickSlip;