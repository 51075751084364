import { Paper, Typography, Divider, Grid, Card, CardContent } from "@mui/material";
import React from "react";
import Utils from "../../Common/Utils";
import { IResourceShipmentLine } from "../../Models/IResourceShipmentLine";

export const ResourceData = (props: any) => {
    let shipmentData: IResourceShipmentLine = props?.resourceShipmentLineData;
    return (
        <>
            {(props.reasonCodeName || props.reasonText) && <Card className="dCard">
                <CardContent className="dCardContent">
                    <Grid item xs={12} sm={12}>

                        <div className="topHeading">
                            <div className="gridHeading textLeft">
                                <Typography>Notes</Typography>
                            </div>
                            <Divider></Divider>
                            <div style={{ padding: "20px" }}>
                                <Grid item md={12} sm={12} xs={12} style={{ textAlign: "left" }}>
                                    {props.reasonCodeName && <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                        Reason Code: <b>{props.reasonCodeName}</b>
                                    </Typography>}
                                    {props.reasonText && <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                        Reason Text: <b>{props.reasonText}</b>
                                    </Typography>}
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </CardContent>
            </Card>}
            <Card className="dCard" style={{ marginTop: 6, marginBottom: 6 }}>
                <CardContent className="dCardContent">
                    <Grid item xs={12} sm={12}>
                        <div className="topHeading">
                            <div className="gridHeading textLeft">
                                <Typography>Details</Typography>
                            </div>
                            <Divider></Divider>
                            <div style={{ padding: "20px" }}>
                                <Grid container>
                                    <Grid item md={6} sm={6} xs={12} style={{ textAlign: "left" }}>
                                        <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                            Store: <b>{props.store}</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12} style={{ textAlign: "left" }}>
                                        <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                            Store Ph#: <b>{props.storePhone}</b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item md={6} sm={6} xs={12} style={{ textAlign: "left" }}>
                                        <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                            Employee Id: <b>{props.userId}</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12} style={{ textAlign: "left" }}>
                                        <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                            Employee Name: <b>{props.userName}</b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item md={12} sm={12} xs={12} style={{ textAlign: "left" }}>
                                        <Typography variant="body2" sx={{ fontSize: 15, wordWrap: "break-word" }} gutterBottom>
                                            Employee Email: <b>{props.upn}</b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </CardContent>
            </Card>
            <Card className='dCard'>
                <CardContent className="dCardContent">
                    <Grid item xs={12} sm={12}>
                        <div className="topHeading">
                            <div className="gridHeading textLeft">
                                <Typography>Item Details</Typography>
                            </div>
                            <Divider></Divider>

                            {Utils.isMobile ? mobileViewItemDetails(shipmentData) : webViewItemDetails(shipmentData)}


                            {/* <div style={{ padding: "20px" }}>
                                    <Grid container spacing={2} display={"flex"}>
                                        <Grid item xs={11} sm={11} className='textLeft'>
                                        </Grid>
                                        <Grid item xs={1} sm={1}>
                                            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                                Qty
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid container spacing={2} display={"flex"}>
                                        <Grid item xs={8} sm={8} className='textLeft'>
                                            <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                <Grid item xs={4} sm={4} style={{ padding: "8px", borderRight: "1px solid #2f2d2d", display: "flex", justifyContent: "center" }}>
                                                    <img alt="" style={{ height: "100px" }} src={Utils.convertToHttps(shipmentData.imageUrl ? shipmentData.imageUrl : "")} />
                                                </Grid>
                                                <Grid item xs={8} sm={8} style={{ padding: "8px", textAlign: "left" }}>
                                                    <Typography style={{ fontSize: "15px", fontWeight: 500, color: "#D93A2F" }}>{shipmentData.itemDesc}</Typography>
                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                        ItemID # <b>{shipmentData.itemID}</b>
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                        SKU # <b>{shipmentData.skuID}</b>
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                                        Price: <b>${shipmentData.unitPrice}</b>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={3} sm={3}>
                                        </Grid>
                                        <Grid item xs={1} sm={1}>
                                            <Typography>
                                                {shipmentData.quantity}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div> */}
                        </div>
                    </Grid>
                </CardContent>
            </Card>
        </>)
}

function mobileViewItemDetails(shipmentData: any) {
    return (
        <Grid container spacing={1} display={"flex"}>
            {/* Image and Description Row */}
            <Grid item xs={12} sm={12} className='textLeft'>
                <Grid container spacing={1} style={{ margin: "2px" }} sx={{ flexGrow: 1 }}>
                    <Grid item xs={5} sm={5} style={{ padding: "8px", display: "flex", justifyContent: "center" }}>
                        <img alt="" style={{ height: "100px", width: "100%" }} src={Utils.convertToHttps(shipmentData.imageUrl ? shipmentData.imageUrl : "")} />
                    </Grid>
                    <Grid item xs={7} sm={7} style={{ padding: "8px", textAlign: "left", wordWrap: "break-word" }}>
                        <Typography style={{ fontSize: "15px", fontWeight: 400 }}>{shipmentData.itemDesc}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            {/* Items Row */}
            <Grid item xs={12} sm={12} className='textLeft'>
                <Grid container spacing={1} style={{ margin: "2px" }} sx={{ flexGrow: 1 }}>
                    <Grid item xs={6} sm={6} style={{ padding: "8px", textAlign: "left" }}>
                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                            Item #
                        </Typography>
                        <Typography sx={{ fontSize: 13 }} gutterBottom>
                            <b>{shipmentData.itemID}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} style={{ padding: "8px", textAlign: "left" }}>
                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                            Price
                        </Typography>
                        <Typography sx={{ fontSize: 13 }} gutterBottom>
                            <b>${Utils.nFormatter(shipmentData.unitPrice,2)}</b>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            {/* Remaining Item Row */}
            <Grid item xs={12} sm={12} className='textLeft'>
                <Grid container spacing={1} style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                    <Grid item xs={12} sm={12} style={{ padding: "8px", textAlign: "left" }}>
                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                            SKU
                        </Typography>
                        <Typography sx={{ fontSize: 13 }} gutterBottom>
                            <b>{shipmentData.skuID}</b>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>


    )
}
function webViewItemDetails(shipmentData: any) {
    return (
        <Grid container spacing={2} display={"flex"}>
            <Grid item xs={12} sm={12} className='textLeft'>
                <Grid container spacing={1} style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                    <Grid item xs={3} sm={3} style={{ padding: "8px", display: "flex", justifyContent: "center" }}>
                        <img alt="" style={{ height: "100px" }} src={Utils.convertToHttps(shipmentData.imageUrl ? shipmentData.imageUrl : "")} />
                    </Grid>

                    <Grid item xs={9} sm={9} style={{ padding: "8px", textAlign: "left" }}>

                        <Typography style={{ fontSize: "16px", fontWeight: 400 }}>{shipmentData.itemDesc}</Typography>

                        <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                        Item #
                                    </Typography>
                                    <Typography sx={{ fontSize: 13 }} gutterBottom>
                                        <b>{shipmentData.itemID}</b>
                                    </Typography>
                                </div>
                                <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                        SKU
                                    </Typography>
                                    <Typography sx={{ fontSize: 13 }} gutterBottom>
                                        <b>{shipmentData.skuID}</b>
                                    </Typography>
                                </div>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                        Price
                                    </Typography>
                                    <Typography sx={{ fontSize: 13 }} gutterBottom>
                                        <b>${Utils.nFormatter(shipmentData.unitPrice,2)}</b>
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}