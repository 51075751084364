import { CHANGE_TECAUDITLINES, CHANGE_SELECTEDTAG, CHANGE_TAGSLIST } from "../ActionTypes";
const changeTecLines = (obj: any) => {
    return {
        type: CHANGE_TECAUDITLINES,
        payLoad: obj
    };
}
const changeSelectedTag = (obj: any) => {
    return {
        type: CHANGE_SELECTEDTAG,
        payLoad: obj
    }
}

const changeTagsList = (obj: any) => {

    return {
        type: CHANGE_TAGSLIST,
        payLoad: obj
    }

}

const tecAuditsActions = {
    changeTecLines,
    changeSelectedTag,
    changeTagsList
}

export default tecAuditsActions;