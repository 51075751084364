import React, { useEffect, useState } from 'react';
import './Routes.scss';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Chip, Grid } from '@material-ui/core';
import Utils from '../Common/Utils';
import { TextField, Theme, FormControl, Autocomplete, Checkbox, Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import DataTable from 'react-data-table-component';
import { Typography, Tooltip } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import SiteBreadCrumbs from '../Subcomponent/Shared/SiteBreadCrumbs'
import { ReceiptLongIcon, CloseIcon, DoneIcon, CheckBoxOutlineBlankIcon, CheckBoxIcon, CheckBoxOutlinedIcon } from "../Subcomponent/Shared/SharedIcons";
import { NotificationPopup } from "../Subcomponent/Shared/NotificationPopup";
import { INotificationPopupSettings, IButton } from "../Models/INotificationPopupSettings";
import allActions from '../Store/Actions/AllActions';
import Constants from '../Common/Constants';
import { FeedbackTile } from '../Subcomponent/Reports/FeedbackTile';
import PFManager from '../Managers/Process.Fulfillment.Manager';
import { IFeedback, IFeedbackStats } from '../Models/IFeedback';
import { useDispatch, useSelector } from 'react-redux';

import SAD from '../Assets/SAD.png';
import HAPPY from '../Assets/HAPPY.png';
import NEUTRAL from '../Assets/NEUTRAL.png';

var moment = require('moment');

const Feedback = (props: any) => {
    const dispatch = useDispatch();
    let userSettings = useSelector((state: any) => state.ActionLoaderReducersState.userSettings);
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxOutlinedIcon fontSize="small" />;
    const [errorMsg, setErrorMsg] = useState("");
    const [openError, setOpenError] = useState(false);
    const [feedbackData, setFeedbackData] = useState([] as any);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(userSettings?.pagination ?? Constants.UserSettingsDefault.Pagination);
    const [feedbackQuestionsOptions, setFeedbackQuestionsOptions] = React.useState([] as any);
    const [selectedQuestion, setSelectedQuestion] = React.useState([] as any);
    const [feedbackStats, setFeedbackStats] = React.useState({} as IFeedbackStats);
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(+(parseInt(event.target.value)));
        setPage(0);
        updatePaginationSettings(parseInt(event.target.value));
  };
  
  const updatePaginationSettings = (pageSize:number) =>
  {
      const updatedSettings = {
            ...userSettings,
          pagination: pageSize
      }
      dispatch(allActions.actionLoaderActions.setUserSettings(updatedSettings));
      //Update User Settings in Dynamo
      const payload = {
        attributeName: Constants.UserSettingsFields.Pagination,
        value: pageSize,
        userName : userSettings.userName
      }
      Utils.updateUserSettings(payload);
  }


    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }

    const changeQuestionsList = (event: any, value: any) => {
        setSelectedQuestion(value);
    }

    var startProductBarPos = -1;
    window.onscroll = function () {
        var bar = document.getElementById('paginationWebId') as any;
        var dataTable = document.getElementById('containerDataTable') as any;
        if (bar && dataTable) {
            var dataTableWidth = dataTable.offsetWidth;
            if (startProductBarPos < 0) startProductBarPos = findPosY(bar);
            if (window.pageYOffset > startProductBarPos) {
                bar.style.width = (dataTableWidth - (0.8 * dataTableWidth / 100)) + "px";
                bar.classList.add("fixedPagination");
                bar.style.marginTop = Utils.getWebbarheight + "px";
            } else {
                bar.classList.remove("fixedPagination");
                bar.style.width = "";
                bar.style.marginTop = "";
            }
        }
        // Mobile View Fix the Tabs and FILTER
        if (Utils.isMobile) {
            console.log("scrolled");
            bar = document.getElementById('tabFilterContaoner') as any;
            var mblBar = document.getElementById('mblBar') as any;
            if (bar) {
                if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);
                if (window.pageYOffset > startProductBarPos) {
                    bar.classList.add("scrolledTopFix");
                } else {
                    bar.classList.remove("scrolledTopFix");
                }
            }
        }
    };
    function findPosY(obj: any) {
        var curtop = 0;
        if (obj && typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
            while (obj.offsetParent) {
                curtop += obj.offsetTop;
                obj = obj.offsetParent;
            }
            curtop += obj.offsetTop;
        }
        else if (obj && obj.y)
            curtop += obj.y;
        return curtop;
    }

    const getEmployeeID = (row: any) => {
        let result = ""
        if (row._source.userId == "") {
            result = row._source.upn
        }
        else {
            result = row._source.userId
        }
        return result
    }

    const getDate = (row: any) => {
        let date: string = ""
        if (row._source.createdDate) {
            date = moment.utc(row._source.createdDate).tz("America/Los_Angeles").format(Constants.DateFormat.Format);
        }
        return date
    }

    const closeErrorPopup = () => {
        setOpenError(false)
    }

    const openErrorPopup = () => {
        setOpenError(true)
    }

    let errorPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeErrorPopup,
            color: Constants.Colors.red,
        },
    ]

    let Error: INotificationPopupSettings = {
        open: openError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorMsg,
        draggable: false,
        handleClose: closeErrorPopup,
        actions: errorPopupButtons
    }

    const renderPaginationAndSearch = () => {
        return <React.Fragment >
            {pagination()}
        </React.Fragment>
    }

    const pagination = () => {
        return < div id='paginationWebId' className="paginationReceiveOrdersWeb" style={{ background: "#D93A2F" }}>
            <div style={{}}>
                <Stack spacing={1} style={{ paddingTop: 7 }}>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={totalRecords}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}

                    />
                </Stack>
            </div>
        </div >
    }

    const loadFeedbackOptions = () => {
        const feedbackOptions = Utils.getFeedbackQuestionsDropdown();
        setFeedbackQuestionsOptions(feedbackOptions);
        changeQuestionsList(null, feedbackOptions);
    }


    const renderWebDataTable = () => {
        return <div className='dataTable' id='containerDataTable'>
            <DataTable
                className='dataTable'
                title=""
                data={feedbackData}
                columns={getColumns()}
            />
        </div>
    }

    const renderResponse = (row: any) => {
        switch (row._source.selectedResponse) {
            case Constants.FeedbackReactions.Neutral:
                return (<img width={30} src={NEUTRAL} alt=""></img>);
            case Constants.FeedbackReactions.Satisfied:
                return (<img width={30} src={HAPPY} alt=""></img>);
            case Constants.FeedbackReactions.UnSatisfied:
                return (<img width={30} src={SAD} alt=""></img>);
            default:
                return <></>;
        }
    }

    const getColumns = () => {
        const columns = [
            {
                name: 'User Id',
                cell: (row: any) => getEmployeeID(row),
                sortable: true,
                maxWidth: "250px"
            },
            {
                name: 'Question',
                cell: (row: any) => <Tooltip title={row._source.questionText}><p style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{row._source.questionText}</p></Tooltip>,
                sortable: true,
                maxWidth: "350px"
            },
            {
                name: 'Suggestion',
                cell: (row: any) => <Tooltip title={row._source.textFeedback}><p style={{}}>{row._source.textFeedback}</p></Tooltip>,
                sortable: true,
                maxWidth: "350px"
            },
            {
                name: 'Response',
                cell: (row: any) => renderResponse(row),
                sortable: true,
                width: "120px"
            },
            {
                name: 'Store',
                selector: (row: any) => row._source.store,
                sortable: true,
                width: "150px"
            },
            {
                name: 'Created Date',
                selector: (row: any) => getDate(row),
                sortable: true,
                maxWidth: "200px"
            },
        ];
        return columns;
    }

    const loadData = async () => {
        showActionLoader();
        const _questionIds = selectedQuestion.map((q: any) => q.id);
        await PFManager.getFeedBackAgg(_questionIds).then((response: any) => {
            if (response.success) {
                let stats: IFeedback = {
                    totalReactions: response.data?.aggregations?.total_count?.value,
                    unSatisfiedReactions: response.data?.aggregations?.selected_response_counts?.buckets?.find((x: any) => x.key == Constants.FeedbackReactions.UnSatisfied)?.doc_count ?? 0,
                    satisfiedReactions: response.data?.aggregations?.selected_response_counts?.buckets?.find((x: any) => x.key == Constants.FeedbackReactions.Satisfied)?.doc_count ?? 0,
                    neutralReactions: response.data?.aggregations?.selected_response_counts?.buckets?.find((x: any) => x.key == Constants.FeedbackReactions.Neutral)?.doc_count ?? 0,
                };
                let feedbackPercentages: IFeedbackStats;
                if (stats.totalReactions > 0) {
                    feedbackPercentages = {
                        totalReactions: stats.totalReactions,
                        unSatisfiedReactionsPer: ((stats.unSatisfiedReactions / stats.totalReactions) * 100).toFixed(2),
                        satisfiedReactionsPer: ((stats.satisfiedReactions / stats.totalReactions) * 100).toFixed(2),
                        neutralReactionsPer: ((stats.neutralReactions / stats.totalReactions) * 100).toFixed(2),
                    }
                }
                else {
                    feedbackPercentages = {
                        totalReactions: 0,
                        unSatisfiedReactionsPer: 0,
                        satisfiedReactionsPer: 0,
                        neutralReactionsPer: 0
                    }
                }
                setFeedbackStats(feedbackPercentages);
            }
            else {
                console.log(response);
            }
        }).catch((error: any) => {
            console.log(error);
        });

        let from = page * rowsPerPage;
        let size = rowsPerPage;
        let payload = {
            questionIds: _questionIds,
            pFrom: from,
            pSize: size
        }
        await PFManager.getFeedBackData(payload).then((response: any) => {
            hideActionLoader();
            if (response.success) {
                let result = response.data;
                let total = result?.hits?.total?.value;
                let dataRows = result?.hits?.hits;
                if (dataRows?.length > 0) {
                    setTotalRecords(total);
                    setFeedbackData(dataRows);
                }
                else {
                    setTotalRecords(0);
                    setFeedbackData([]);
                }

            }
            else {
                hideActionLoader();
                console.log("getFeedbackData Error", response);
            }
        }).catch((error: any) => {
            hideActionLoader();
            console.log("getFeedbackData Exception", error);
        });
    }

    React.useEffect(() => {
        async function init() {
            loadData();
        }
        init();
    }, [page, rowsPerPage, selectedQuestion]);

    React.useEffect(() => {
        loadFeedbackOptions();
    }, [])

    return (
        <div className={Utils.isMobile ? "innerContianerMobile" : "innerContianer"}>
            <NotificationPopup {...Error}></NotificationPopup>
            {!Utils.isMobile ? <SiteBreadCrumbs siteBreadCrumbs={Constants.SiteBreadCrumbs.Feedback} ></SiteBreadCrumbs> : <></>}
            <Grid container style={{ marginTop: 5, marginBottom: 5 }}>
                <FormControl fullWidth>
                    <Typography style={{ marginTop: '10px', textAlign: 'left' }}>
                        Select Question
                    </Typography>
                    <Autocomplete
                        multiple
                        id="country-select-demo"
                        className="selectStore"
                        value={selectedQuestion}
                        limitTags={1}
                        onChange={(e, value) => { changeQuestionsList(e, value) }}
                        style={{ marginTop: 10, borderRadius: 4, width: "100%" }}
                        getOptionLabel={(option: any) => option.name}
                        options={feedbackQuestionsOptions}
                        autoHighlight

                        renderOption={(props, option: any, { selected }) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <li style={{ fontSize: "small" }}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        checked={selected}
                                    />
                                    {option.name}
                                </li>
                            </Box>
                        )}
                        renderTags={(value: any, getTagProps) => {
                            const numTags = value.length;
                            const limitTags = 1;

                            return (
                                <>
                                    {value.slice(0, limitTags).map((option: any, index: any) => (
                                        <Chip
                                            {...getTagProps({ index })}
                                            size="small"
                                            key={index}
                                            label={option.name}
                                        />
                                    ))}

                                    {numTags > limitTags && ` +${numTags - limitTags}`}
                                </>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={feedbackQuestionsOptions && feedbackQuestionsOptions.length == 0 ? "Loading Questions..." : selectedQuestion.length == 0 ? "Choose Question" : ""}
                                style={{ background: "white", borderRadius: 4 }}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off'
                                }}
                            />
                        )}
                    />
                </FormControl>
            </Grid>
            <FeedbackTile feedbackStats={feedbackStats}></FeedbackTile>
            {renderPaginationAndSearch()}
            {renderWebDataTable()}

        </div >
    );
}

export default Feedback;