import { CHANGE_CUSTOMER_DETAIL, CHANGE_ORDER_DETAIL, CHANGE_SELECTED_SHIPMENTLINEKEY, CHANGE_COMMON_CODE_LIST, CHANGE_SCANNED_SHIPMENTLINES, GET_CUSTOMER_INFO_FROM_ELASTIC } from "../ActionTypes";
// const changeOrderLines = (obj: any) => {
//     return {
//         type: CHANGE_ORDER_LINES,
//         payLoad: obj
//     };
// }

const getCustomerInfoFromElastic = (obj: any) => {
    return {
        type: GET_CUSTOMER_INFO_FROM_ELASTIC,
        payLoad: obj,
    }
}

const changeCustomerDetails = (obj: any) => {
    return {
        type: CHANGE_CUSTOMER_DETAIL,
        payLoad: obj
    }
}
const changeOMSOrderDetails = (obj: any) => {
    return {
        type: CHANGE_ORDER_DETAIL,
        payLoad: obj
    }
}

const changeCommonCodeReasons = (obj: any) => {
    return {
        type: CHANGE_COMMON_CODE_LIST,
        payLoad: obj
    }
}
const chagneSelectedShipmentKey = (obj: any) => {
    return {
        type: CHANGE_SELECTED_SHIPMENTLINEKEY,
        payLoad: obj
    }
}

const chagneScannedShipmentLines = (obj: any) => {
    return {
        type: CHANGE_SCANNED_SHIPMENTLINES,
        payLoad: obj
    }
}

const packOrderActions = {
    //changeOrderLines,
    getCustomerInfoFromElastic,
    changeCustomerDetails,
    changeCommonCodeReasons,
    chagneSelectedShipmentKey,
    changeOMSOrderDetails,
    chagneScannedShipmentLines
}

export default packOrderActions;