import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../Store/Actions/AllActions';
import Constants from "../../Common/Constants";
import { Button, Grid, TextField, Typography} from "@mui/material";
import { AddCircleIcon, DoDisturbOnIcon} from "../Shared/SharedIcons"
export const AddAdHoqTrackingNumbers: React.FC<any> = (props) => { 
    let selectedItems = useSelector((state: any) => state.PackOrderReducersState.selectedItems);               
  const handleInputChange = (e:any, index:any) => {
    const { name, value } = e.target;
    const list = [...props.inputListAdhoq];
    list[index][name] = value;
    props.setInputListAdhoq(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index: any) => {
    const list = [...props.inputListAdhoq];
    list.splice(index, 1);
    props.setInputListAdhoq(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    props.setInputListAdhoq([...props.inputListAdhoq, { trackingNo: "", error:false}]);
  };  
    React.useEffect(() => {
    
    }, []);
    return (
        <div style={{ marginTop: 20 }}>            
            {props.inputListAdhoq.map((x:any, i:any) => {
        return (
            <Grid item xs={12} sm={12} className="inputField" >
            <Typography>Tracking No</Typography>
            <TextField                                
                error={x.error}
                helperText={x.error? "Invalid" : ""}
                type={"text"}                                               
                InputLabelProps={{ shrink: false }}                
                name={"trackingNo"}
                placeholder="Enter Tracking No"
                variant="outlined"
                value = {x.trackingNo}
                onChange={e => handleInputChange(e, i)}                       
            />
            {
                (selectedItems.length <= 1 && props.isBundle == false && props.disableMultiBox == false) ?
                <div style={{display:"flex", justifyContent:"right"}}>
                {props.inputListAdhoq.length !== 1 &&  <Button                                     
                                    onClick={() => handleRemoveClick(i)}                                
                                    variant="contained"
                                    className=""
                                    style={{marginRight:5, backgroundColor:"grey"}}       
                                    startIcon={<DoDisturbOnIcon />}
                                >
                                    Remove
                </Button>
                }
                  {props.inputListAdhoq.length - 1 === i &&  <Button            
                                    onClick={() => handleAddClick()}                                
                                    variant="contained"
                                    className=""                                
                                    startIcon={<AddCircleIcon />}
                                >
                                    Add Another
                </Button>}           
                </div>
                :
                ""
            }
         
            {/*   */}
        </Grid>          
        );
      })}
      {/* <div style={{ marginTop: 20 }}>{JSON.stringify(props.inputListAdhoq)}</div> */}
        </div>
    );
};
