import React from "react";
import { Typography } from "@material-ui/core";
import { Card, CardContent, Grid } from "@mui/material";
import OMSManager from "../../Managers/Oms.manager";
import "../../Routes/FulfillOrders.scss";
import Utils from "../../Common/Utils";

const ExpandedRow = (props: any) => {
  const renderCategory = (item: any) => {
    let cat: any = "";
    cat = item?.ItemDetails?.CategoryList?.Category?.ParentCategoryID;
    if (cat && cat.length > 0) {
      if (
        OMSManager.catalogCategories &&
        OMSManager.catalogCategories.Category &&
        OMSManager.catalogCategories.Category.length > 0
      ) {
        let fCategory: any = OMSManager.catalogCategories.Category.filter(
          (a) => a.CategoryID == cat
        );
        if (fCategory && fCategory.length > 0) {
          cat = fCategory[0].ShortDescription;
        }
      }
    }

    return cat == null || cat == undefined ? "-" : cat;
  };

  const renderItemID = (item: any) => {
    let itemID: any = item.ItemDetails?.Extn?.ExtnPOSItemID;
    return itemID ? itemID : "-";
  };

  const renderExpandedData = () => {
    let shipmentsLinesToRender: any[] = [];

    props.data._source.Shipment.ShipmentLines.ShipmentLine.forEach(
      (element: any) => {
        if (
          element.MaxLineStatusDesc != "Backordered" &&
          element.ExtnIsWarrantyItem == "N" &&
          element.MinLineStatusDesc != "Cancelled" &&
          element.BundleParentOrderLineKey == undefined
        ) {
          shipmentsLinesToRender.push(element);
        }
      }
    );

    console.log(props, "ExpandedRowProps");
    return (
      <div style={{ marginLeft: Utils.isMobile  ? "" : "50px" ,maxHeight: "450px" , overflowY: "auto" }} className="expandedParent"
      >
        {shipmentsLinesToRender.map((item: any) => {
          return (
            <div className={Utils.isMobile ? "expandedSectionMobile": "expandedSection"}  >
              <Typography
                style={{
                  fontSize: "13px",
                  fontWeight: 500,
                  width: !Utils.isMobile ? "600px" : "auto",
                  textAlign: 'start',
                  fontFamily: "Roboto"
                }}
              >
                {item.ItemDesc}
              </Typography>
              <div className="item" style={{ width: !Utils.isMobile ? "70px" : "auto" }}>
                <Typography style={{ fontSize: "12px" }}>Item ID</Typography>
                <Typography style={{ fontSize: "12px" }}>
                  <b style={{ fontFamily: "Roboto Mono" }}>{renderItemID(item)}</b>
                </Typography>
              </div>
              <div className="item" style={{ width: !Utils.isMobile ? "50px" : "auto" }}>
                <Typography style={{ fontSize: "12px" }}>Category</Typography>
                <Typography style={{ fontSize: "12px" }}>
                  <b style={{ fontFamily: "Roboto Mono" }}>{renderCategory(item)}</b>
                </Typography>
              </div>
              <div className="item" style={{ width: !Utils.isMobile ? "70px" : "auto" }}>
                <Typography style={{ fontSize: "12px" }}>Price</Typography>
                <Typography style={{ fontSize: "12px" }}>
                  <b style={{ fontFamily: "Roboto Mono" }}>{item.UnitPrice}</b>
                </Typography>
              </div>
              <div className="item">
                <Typography style={{ fontSize: "12px" }}>Qty</Typography>
                <Typography style={{ fontSize: "12px" }}>
                  <b style={{ fontFamily: "Roboto Mono" }}>{item.OrderedQty}</b>
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  return <React.Fragment>{renderExpandedData()}</React.Fragment>;
};

export default ExpandedRow;
