import { Card, CardContent, Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography } from "@mui/material";
import { Slide } from '@material-ui/core';
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import DataTable from "react-data-table-component";
import { CloseIcon } from "../Shared/SharedIcons";
import IdentityManager from "../../Managers/Identity.manager";
import DataNotFound from "../Shared/DataNotFound";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CommunicationDataGrid = (props: any) => {
  const close = () => {
    props.close()
  }

  const getColumns = () => {
    const columns = [
      {
        id: "district",
        name: 'District',
        sortable: true,
        cell: (row: any) => row.district
      },
      {
        id: "send",
        name: 'Emails Sent',
        sortable: true,
        selector: (row: any) => row.emailsSent
      },
      {
        id: "responses",
        name: 'Responses Received',
        sortable: true,
        selector: (row: any) => row.customerResponded
      }
    ];
    return columns;
  }

  const getExpandedColumns = () => {
    const columns = [
      {
        id: "store",
        name: 'Store',
        sortable: true,
        cell: (row: any) => row.storeName
      },
      {
        id: "send",
        name: 'Emails Sent',
        sortable: true,
        selector: (row: any) => row.storeEmailsSent
      },
      {
        id: "responses",
        name: 'Responses Received',
        sortable: true,
        selector: (row: any) => row.storeCustomerResponded
      }
    ];
    return columns;
  }

  const expandedRows = (props: any) => {
    const { ccStoresData } = props.data;
    return (
      <>
        {<Card id='cardOrder' style={{ margin: "5px", width: "98%", height: "98%" }}>
          <CardContent style={{ padding: 3 }}>
            <div className="dataTableDashboardExpanded">
              <DataTable
                title=""
                className=''
                data={ccStoresData}
                columns={getExpandedColumns()}
                noDataComponent = {<DataNotFound></DataNotFound>}
              />
            </div >
          </CardContent >
        </Card >}
      </>

    );
  }
  return (
    <Dialog disableEnforceFocus open={props.open} fullWidth maxWidth={"md"} TransitionComponent={Transition}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => { close() }}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography className="report-card-heading" style={{ display: "flex", justifyContent: "center"}}>Customer Communications Summary</Typography>
        {
          props.data && props.data.length > 1 ? <div className='dataTableDashboardExpanded'>
            <DataTable
              className='dataTableDashboardExpanded'
              title=""
              data={props.data}
              columns={getColumns()}
              expandableRows
              expandableRowsComponent={expandedRows}
              noDataComponent = {<DataNotFound></DataNotFound>}

            />
          </div>
            :
            <Card id='cardOrder' style={{ margin: "5px", width: "98%", height: "98%" }}>
              <CardContent style={{ padding: 3 }}>
                <div className="dataTableDashboardExpanded">
                  <DataTable
                    title=""
                    className=''
                    data={props.data[0] && props.data[0].ccStoresData ? props.data[0].ccStoresData : []}
                    columns={getExpandedColumns()}
                    noDataComponent = {<DataNotFound></DataNotFound>}
                  />
                </div >
              </CardContent >
            </Card >
        }
      </DialogContent>
    </Dialog>
  );
}