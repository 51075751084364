import { LOAD_RECEIVEORDERS, LOAD_ALLRECEIVEORDERS, CHANGE_RECEIVEORDERDETAIL } from "../ActionTypes";
const initialState = {
    receiveOrders: [],
    allReceiveOrders: [],
    receiveOrderDetail: {}
}
const ReceiveOrdersReducers = (state: any = initialState,
    action: { type: any; payLoad: any }) => {
    switch (action.type) {
        case LOAD_RECEIVEORDERS:
            return {
                ...state,
                receiveOrders: action.payLoad
            }
        case LOAD_ALLRECEIVEORDERS:
            return {
                ...state,
                allReceiveOrders: action.payLoad
            }
        case CHANGE_RECEIVEORDERDETAIL:
            return {
                ...state,
                receiveOrderDetail: action.payLoad
            }
    }

    return state;
}

export default ReceiveOrdersReducers;