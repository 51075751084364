import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Button, ButtonGroup, Grid, Typography, Step, StepLabel, Stepper, AppBar, Tabs, Tab } from "@material-ui/core";
import Constants from '../Common/Constants';
import DirectionsCar from '@mui/icons-material/DirectionsCar';
import PlaylistAddCheck from '@mui/icons-material/PlaylistAddCheck';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import { MenuIcon, PlaylistAddIcon, StopIcon, ApartmentIcon, LocalShippingIcon, MarkEmailUnreadIcon, MessageIcon, ReceiptLongIcon, EscalatorIcon, CancelIcon, TrendingFlatTwoToneIcon, SummarizeIcon, CloseIcon, ListAltIcon, KeyboardArrowDownIcon, NearMeIcon, SyncSharpIcon, CheckBoxOutlinedIcon } from '../Subcomponent/Shared/SharedIcons';
import Utils from '../Common/Utils';
import SiteBreadCrumbs from '../Subcomponent/Shared/SiteBreadCrumbs';
import "./FulfilledOrdersHistory.scss";
import { useEffect, useState } from 'react';
import { Card, CardContent, Divider, Stack, TablePagination, Checkbox } from '@mui/material';
import OrderManager from '../Managers/Orders.Manager';
import allActions from '../Store/Actions/AllActions';
import { useDispatch, useSelector } from 'react-redux';
import IdentityManager from '../Managers/Identity.manager';
import { useNavigate } from 'react-router-dom';
import { IButton, INotificationPopupSettings } from '../Models/INotificationPopupSettings';
import { NotificationPopup } from '../Subcomponent/Shared/NotificationPopup';
import Drawer from "@mui/material/Drawer";
import { useTheme, withStyles } from "@material-ui/core/styles";
var moment = require('moment');

const FulfilledOrdersHistory = (props: any) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [openError, setOpenError] = useState(false);
  const rangeType = useSelector((state: any) => state.FulfilledOrdersHistoryReducerState.rangeType);
  const orderType = useSelector((state: any) => state.FulfilledOrdersHistoryReducerState.orderType);
  const dateFilter = useSelector((state: any) => state.FulfilledOrdersHistoryReducerState.dateFilter);
  let userSettings = useSelector((state: any) => state.ActionLoaderReducersState.userSettings);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(userSettings?.pagination ?? Constants.UserSettingsDefault.Pagination);
  const [orderHistoryData, setOrderHistoryData] = useState([] as any);
  var startProductBarPos = -1;
  //new variables 
  const theme = useTheme();
  const [isOpenFilters, setOpenFilters] = useState(false);
  const [selectedRangeFilter, setselectedRangeFilter] = useState("Today");
  const [orderTypeFilters, setOrderTypeFilters] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [mobilePage, setMobilePage] = React.useState(0);
  const [currentTab, setCurrentTab] = React.useState(0);
  //new variables 

  window.onscroll = function () {
    var bar = document.getElementById("paginationWebId") as any;
    var dataTable = document.getElementById("containerDataTable") as any;
    if (bar && dataTable) {
      var dataTableWidth = dataTable.offsetWidth;
      if (startProductBarPos < 0) startProductBarPos = findPosY(bar);
      if (
        window.pageYOffset > startProductBarPos &&
        window.pageYOffset - startProductBarPos > 50
      ) {
        bar.style.width = dataTableWidth - (0.8 * dataTableWidth) / 100 + "px";
        bar.classList.add("fixedPagination");
        bar.style.marginTop = Utils.getWebbarheight + "px";
      } else {
        bar.classList.remove("fixedPagination");
        bar.style.width = "";
        bar.style.marginTop = "";
      }
    }
    // Mobile View Fix the Tabs and FILTER
    if (Utils.isMobile) {
      console.log("scrolled");
      bar = document.getElementById("tabFilterContaoner") as any;
      var mblBar = document.getElementById("mblBar") as any;
      if (bar) {
        console.log("findPosY(mblBar);" + findPosY(mblBar));
        console.log("startProductBarPos=" + startProductBarPos);
        console.log("window.pageYOffset=" + window.pageYOffset);
        if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);
        if (window.pageYOffset > startProductBarPos) {
          bar.classList.add("scrolledTopFixFulFillOrders");
        } else {
          bar.classList.remove("scrolledTopFixFulFillOrders");
        }
      }

      if (
        !isLoading &&
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 100
      ) {
        let page = mobilePage + 1;
        setMobilePage(page);
      }
    }
  };
  function findPosY(obj: any) {
    var curtop = 0;
    if (obj && typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
      while (obj.offsetParent) {
        curtop += obj.offsetTop;
        obj = obj.offsetParent;
      }
      curtop += obj.offsetTop;
    }
    else if (obj && obj.y)
      curtop += obj.y;
    return curtop;
  }
  const showActionLoader = () => {
    dispatch(allActions.actionLoaderActions.showLoader());
  }

  const hideActionLoader = () => {
    dispatch(allActions.actionLoaderActions.hideLoader());
  }

  const checkRangeType = (type: any) => {
    return type == rangeType;
  }

  const checkOrderType = (type: any) => {
    return type == orderType;
  }

  const onChangeOrderType = (type: any) => {
    dispatch(allActions.fulfilledOrdersHistoryActions.setOrderType(type));
  }

  const getSelectedFilterText = (type: string) => {
    let selectedText = "";
    switch (type) {
      case "Y":
        selectedText = "Yesterday"
        break;

      case "M":
        selectedText = "MTD"
        break;

      case "T":
        selectedText = "Today"
        break;

      case "7":
        selectedText = "Last 7 days"
        break;

      case "15":
        selectedText = "Last 15 days"
        break;

      case "30":
        selectedText = "Last 30 days"
        break;

      case "90":
        selectedText = "Last 90 days"
        break;
      default:
        selectedText = "Filter"
        break;
    }
    return selectedText;
  }

  const onChangeRangeType = (type: any) => {
    dispatch(allActions.fulfilledOrdersHistoryActions.setRangeType(type));
    let dates = {};
    switch (type) {
      case "Y":
        dates = Utils.getDates(-1);
        break;

      case "M":
        dates = Utils.getDates(0);
        break;

      case "T":
        dates = Utils.getDates(1);
        break;

      case "7":
        dates = Utils.getDates(7);
        break;

      case "15":
        dates = Utils.getDates(15);
        break;

      case "30":
        dates = Utils.getDates(30);
        break;

      case "90":
        dates = Utils.getDates(90);
        break;
      default:
        break;
    }
    //getSelectedFilterText(type);
    dispatch(allActions.fulfilledOrdersHistoryActions.setDate(dates));
    setOpenFilters(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(+(parseInt(event.target.value)));
    setPage(0);
    updatePaginationSettings(parseInt(event.target.value));
  };

  const updatePaginationSettings = (pageSize: number) => {
    const updatedSettings = {
      ...userSettings,
      pagination: pageSize
    }
    dispatch(allActions.actionLoaderActions.setUserSettings(updatedSettings));
    //Update User Settings in Dynamo
    const payload = {
      attributeName: Constants.UserSettingsFields.Pagination,
      value: pageSize,
      userName: userSettings.userName
    }
    Utils.updateUserSettings(payload);
  }

  // const pagination = () => {
  //   return < div id='paginationWebId' className="paginationReceiveOrdersWeb" style={{ background: "#D93A2F" }}>
  //     <div style={{}}>
  //       <Stack spacing={1} style={{ paddingTop: 7 }}>
  //         <TablePagination
  //           rowsPerPageOptions={[5, 10, 25, 50, 100]}
  //           component="div"
  //           count={totalRecords}
  //           page={page}
  //           onPageChange={handleChangePage}
  //           rowsPerPage={rowsPerPage}
  //           onRowsPerPageChange={handleChangeRowsPerPage}

  //         />
  //       </Stack>
  //     </div>
  //   </div >
  // }

  // const renderPagination = () => {
  //   return <React.Fragment >
  //     {pagination()}
  //   </React.Fragment>
  // }

  const renderPrice = (price: any) => {
    const value = Number(price)?.toFixed(2) ?? "0.00";
    return value;
  }

  const navigateToOrderDetail = (orderno: any, shipmentkey: any) => {
    navigate("/OrderDetail?orderno=" + orderno + "&shipmentkey=" + shipmentkey + "");
    resetState()
  }

  const loadData = async () => {
    showActionLoader();
    const from = page * rowsPerPage;
    const size = rowsPerPage;
    let storeId = IdentityManager.getMultipleStoresId(props.store);
    const payLoad = {
      startDate: dateFilter.start,
      endDate: dateFilter.end,
      pFrom: from,
      pSize: size,
      store: storeId,
      orderType: orderType
    }
    setCurrentTabOnLoad(orderType)
    await OrderManager.getFulfilledOrdersHistory(payLoad).then((response: any) => {
      hideActionLoader();
      if (response.success) {
        let result = response.data?.historyData;
        let totalRecords = response.data?.totalRecords;
        setTotalRecords(totalRecords);
        setOrderHistoryData(result);
      }
      else {
        setTotalRecords(0);
        setOrderHistoryData([]);
        setErrorMsg(response.msg);
        setOpenError(true);
        console.log("getFulfilledOrdersHistory Error", response);
      }
    }).catch((error: any) => {
      hideActionLoader();
      setErrorMsg("Something went wrong please contact the IT Help Desk for assistance");
      setOpenError(true);
      console.log("getFeedbackData Exception", error);
    });
  }

  const renderOrderHistory = () => {
    if (orderHistoryData.length > 0) {
      return (
        <>
          {
            !Utils.isMobile ?
              <Card>
                <CardContent style={{ padding: 5 }}>
                  {
                    orderHistoryData.map((order: any, index: any) => {
                      return (
                        <Grid container key={index} style={{ marginTop: "1px" }}>
                          <Grid item md={12} sm={12} xs={12}>
                            <Grid container style={{ background: "#F9F9F9", padding: "5px" }}>
                              <Grid item md={12} sm={12} xs={12}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                  <Typography style={{ marginLeft: "5px", fontSize: !Utils.isMobile ? "16px" : "14px", fontFamily: "Roboto", fontWeight: "600" }}>
                                    {order.desc}
                                  </Typography>
                                  <Button variant="contained" color="primary" className="red-outline-btn" title="Go to Order" size="small" onClick={() => { navigateToOrderDetail(order.orderNo, order.shipmentKey) }}><ListAltIcon style={{ fontSize: "18px" }} />Detail</Button>
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item md={2} sm={2} xs={6}>
                                <Grid container>
                                  <Grid item sm={12} xs={12} style={{ display: "grid", justifyItems: "left" }}>
                                    <p className="header-font-oh">Order #</p>
                                    <p className="value-font-oh">
                                      {order.orderNo}
                                    </p>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item md={3} sm={3} xs={6}>
                                <Grid container>
                                  <Grid item sm={12} xs={12} style={{ display: "grid", justifyItems: "left" }}>
                                    <p className="header-font-oh">Order Date</p>
                                    <p className="value-font-oh">
                                      {getDate(order.orderDate)}
                                    </p>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item md={2} sm={2} xs={4}>
                                <Grid container>
                                  <Grid item sm={12} xs={12} style={{ display: "grid", justifyItems: "left" }}>
                                    <p className="header-font-oh">Qty</p>
                                    <p className="value-font-oh">
                                      {order.qty}
                                    </p>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item md={3} sm={3} xs={4}>
                                <Grid container>
                                  <Grid item sm={12} xs={12} style={{ display: "grid", justifyItems: "left" }}>
                                    <p className="header-font-oh">Price</p>
                                    <p className="value-font-oh">
                                      ${Utils.nFormatter(order.price,2)}
                                    </p>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item md={2} sm={2} xs={4}>
                                <Grid container>
                                  <Grid item sm={12} xs={12} style={{ display: "grid", justifyItems: "left" }}>
                                    <p className="header-font-oh">Ship Method</p>
                                    <p className="value-font-oh">
                                      {order.shipMethod}
                                    </p>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Divider />
                            <Grid container>
                              {horizontalStepper(order.orderHistory)}
                            </Grid>

                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </CardContent>
              </Card>
              :
              <div className='mobileContainer'>
                <div className='mobileFulfilmentDataTable'>
                  {
                    orderHistoryData.map((order: any, index: any) => {
                      return (<>
                        <Grid container style={{ background: "#F9F9F9", padding: "5px" }}>
                          <Grid item md={12} sm={12} xs={12}>
                            <div style={{ display: "flex", alignItems: "left", justifyContent: "space-between" }}>
                              <Typography style={{ marginLeft: "5px", fontSize: !Utils.isMobile ? "16px" : "14px", fontFamily: "Roboto", fontWeight: "600", minWidth: "245px", textAlign: "left" }}>
                                {order.desc}
                              </Typography>
                              <div>
                                <Button variant="contained" color="primary" className="red-outline-btn" title="Go to Order" size="small" onClick={() => { navigateToOrderDetail(order.orderNo, order.shipmentKey) }}><ListAltIcon style={{ fontSize: "18px" }} />Detail</Button>
                              </div>
                            </div>
                          </Grid>
                        </Grid >
                        <Divider />
                        <div style={{ background: index % 2 === 0 ? 'white' : '#F9F9F9' }} className='mobileRow'>
                          <div className='mobileCellLeft'>
                            <Typography className='cellMeta'>
                              Order #
                            </Typography>
                            <Typography className='cellMeta'>
                              Order Date
                            </Typography>
                            <Typography className='cellMeta'>
                              Qty
                            </Typography>
                            <Typography className='cellMeta'>
                              Price
                            </Typography>
                            <Typography className='cellMeta'>
                              Ship Method
                            </Typography>
                          </div>
                          <div className='mobileCellRight'>
                            <Typography style={{ fontFamily: "Roboto Mono" }} className='orderIdLabel' >
                              <Typography className='cellInfoMain'>
                                {order.orderNo}
                              </Typography>

                            </Typography>
                            <Typography className='cellInfoMain'>
                              {getDate(order.orderDate)}

                            </Typography>
                            <Typography className='cellInfo'>
                              {order.qty}
                            </Typography>
                            <Typography className='cellInfo'>
                              ${renderPrice(order.price)}
                            </Typography>
                            <Typography className='cellInfo'>
                              {order.shipMethod}
                            </Typography>
                          </div>
                        </div>
                        <Grid container>
                          {horizontalStepper(order.orderHistory)}
                        </Grid>
                        <Divider />
                      </>);
                    })
                  }
                </div>
              </div >
          }

        </>

      )

    }
    else {
      return (
        <Grid container style={{ marginTop: "1px" }}>
          <Grid item md={12} sm={12} xs={12}>
            <Card>
              <CardContent>
                <Typography>
                  There are no records to display.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )
    }
  }

  const closeErrorPopup = () => {
    setOpenError(false)
  }

  const openErrorPopup = () => {
    setOpenError(true)
  }

  let errorPopupButtons: IButton[] = [
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeErrorPopup,
      color: Constants.Colors.red,
    },
  ]

  let error: INotificationPopupSettings = {
    open: openError,
    type: Constants.NotificationPopupType.Error,
    title: "Error",
    msg: errorMsg,
    draggable: false,
    handleClose: closeErrorPopup,
    actions: errorPopupButtons
  }


  const getDate = (dateTime: any) => {
    if (dateTime) {
      return moment(dateTime).format(Constants.DateFormat.Format);
    }
    return "";
  }

  const iconDecision = (type: string) => {
    switch (type) {
      case Constants.OrderHistoryTypes.Order_Release:
        return (
          <div className="icons-oh">
            <PlaylistAddCheck style={{ fontSize: "18px" }} />
          </div>
        );
      case Constants.OrderHistoryTypes.Order_Queue:
        return (
          <div className="icons-oh">
            <PlaylistAddIcon style={{ fontSize: "18px" }} />
          </div>
        );
      case Constants.OrderHistoryTypes.Picking:
        return (
          <div className="icons-oh">
            <ShoppingCart style={{ fontSize: "18px" }} />
          </div>
        );
      case Constants.OrderHistoryTypes.OnHold:
        return (
          <div className="icons-oh">
            <StopIcon style={{ fontSize: "18px" }} />
          </div>
        );
      case Constants.OrderHistoryTypes.Packing:
        return (
          <div className="icons-oh">
            <ApartmentIcon style={{ fontSize: "20px" }} />
          </div>
        );
      case Constants.OrderHistoryTypes.Shipped:
        return (
          <div className="icons-oh">
            <LocalShippingIcon style={{ fontSize: "20px" }} />
          </div>
        );
      case Constants.OrderHistoryTypes.Customer_Pickup:
        return (
          <div className="icons-oh">
            <DirectionsCar style={{ fontSize: "20px" }} />
          </div>
        );
      case Constants.OrderHistoryTypes.Customer_Contacted:
        return (
          <div className="icons-oh">
            <MarkEmailUnreadIcon style={{ fontSize: "20px" }} />
          </div>
        );
      case Constants.OrderHistoryTypes.Note:
        return (
          <div className="icons-oh">
            <MessageIcon style={{ fontSize: "20px" }} />
          </div>
        );
      case Constants.OrderHistoryTypes.Pending_Receive:
        return (
          <div className="icons-oh">
            <ReceiptLongIcon style={{ fontSize: "20px" }} />
          </div>
        );
      case Constants.OrderHistoryTypes.Resource_Item:
        return (
          <div className="icons-oh">
            <EscalatorIcon style={{ fontSize: "20px" }} />
          </div>
        );
      case Constants.OrderHistoryTypes.Cancel_Order:
        return (
          <div className="icons-oh">
            <CancelIcon style={{ fontSize: "20px" }} />
          </div>
        );
    }
  }

  const renderTransactionType = (text: any, store: any, resourceStore?: any, resourceShipmentNode?: any) => {

    if (text == Constants.OrderHistoryTypes.Order_Release) {
      text = "Order Created"
    }
    if (text == Constants.OrderHistoryTypes.Order_Queue) {
      if (store != "") {
        text = "Order Released to Store " + store
      }
      else {
        text = "Order Release"
      }
    }
    if (text == Constants.OrderHistoryTypes.Resource_Item && resourceStore && resourceShipmentNode) {
      text = `Resourced from Store ${resourceStore} to ${resourceShipmentNode}`
    }
    if (text == Constants.OrderHistoryTypes.OnHold) {
      text = Constants.FulfillOrdersDisplayNames.OnHold;
    }
    return text;

  }
  const resetState = () => {
    setOrderHistoryData([]);
    //setCurrentTab(0)
    //onChangeOrderType("All")
  }
  const horizontalStepper = (history: any) => {
    return (
      <Box style={{ width: "100%", overflow: "scroll" }}>
        <Stepper activeStep={1000} orientation={!Utils.isMobile ? "horizontal" : "vertical"} style={{ padding: "12px" }} connector={!Utils.isMobile ? <TrendingFlatTwoToneIcon /> : undefined}>
          {history.map((order: any, index: any) => {
            const labelProps: any = {};
            labelProps.optional = (
              <Typography variant="caption" >
                {getDate(order.transactionDate)}
              </Typography>
            );
            labelProps.icon = (iconDecision(order.transactionType))
            return (
              <Step key={index} active={true}>
                <StepLabel {...labelProps}>
                  {renderTransactionType(order.transactionType, order.shipNode ? order.shipNode : "", order.store)}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    );
  }
  useEffect(() => {
    async function init() {
      loadData();
    }
    init();
  }, [dateFilter, page, rowsPerPage, orderType, props.store]);
  //new ui code 

  const FilterItem = ({ checked, onChange, label, count }: any) => {
    return (
      <div className={`filterSection item ${checked ? "filterOutline" : ""}`}>
        <div className={`filterSection filterName`}>
          <Checkbox
            
            checkedIcon={<CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>}
            onChange={(event: any, checked: boolean) => {
              onChange();
            }}
            checked={checked}
          />
          <span>{label}</span>
        </div>
        <span>{count}</span>
      </div>
    );
  };

  const handleFilterChange = () => {
    setOpenFilters(!isOpenFilters)
  }

  const handleChangeTab = (event: any, newValue: number) => {
    switch (newValue) {
      case 0:
        onChangeOrderType(Constants.FulfilledOrdersHistory.OrderTypes.All)
        break;
      case 1:
        onChangeOrderType(Constants.FulfilledOrdersHistory.OrderTypes.Shipped)
        break;
      case 2:
        onChangeOrderType(Constants.FulfilledOrdersHistory.OrderTypes.Pickedup)
        break;
      case 3:
        onChangeOrderType(Constants.FulfilledOrdersHistory.OrderTypes.Used)
        break;
      default:
        break;
    }
    setCurrentTab(Number(newValue))
  }

  const setCurrentTabOnLoad = (type: string)=>{
    switch (type) {
      case Constants.FulfilledOrdersHistory.OrderTypes.All:
        setCurrentTab(0)
        break;
      case Constants.FulfilledOrdersHistory.OrderTypes.Shipped:
        setCurrentTab(1)
        break;
      case Constants.FulfilledOrdersHistory.OrderTypes.Pickedup:
        setCurrentTab(2)
        break;
      case Constants.FulfilledOrdersHistory.OrderTypes.Used:
        setCurrentTab(3)
        break;
      default:
        break;
    }    
  }
  const renderWebTabs = () => {
    return <Grid container style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
              <Grid item xs={8} md={8}>
                  <AppBar elevation={0} position="static" style={{ background: "none" }}>
                    <Tabs
                      value={currentTab}
                      onChange={handleChangeTab}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      className="fTabs"
                      style={{ background: "none" }}
                    >
                      <Tab
                        icon={<MenuIcon className="fTabIcons" />}
                        label={"All Orders"}
                      />
                      <Tab
                        icon={<LocalShippingIcon className="fTabIcons" />}
                        label={"Shipped"}
                      />
                      <Tab
                        icon={<NearMeIcon className="fTabIcons" />}
                        label={"Picked Up"}
                      />
                      <Tab
                        icon={<SyncSharpIcon className="fTabIcons" />}
                        label={
                          "Used Items"
                        }
                      />
                    </Tabs>
                  </AppBar>
              </Grid>
              <Grid item xs={2} md={2}>
              </Grid>
              <Grid item xs={2} md={2}>
                <Button onClick={handleFilterChange} className="iconBtn" style={{height:"42px",textTransform:"none",float:"right"}}>
                  {getSelectedFilterText(rangeType)}
                  <KeyboardArrowDownIcon />
                </Button>
              </Grid>
            </Grid>
  }

  const renderFilters = () => {
    return (
      <Box sx={{ p: 4, display: "flex", justifyContent: "space-between" }} style={{padding:Utils.isMobile? "0px": "0px 20px"}}>
        {/* {
          Utils.isMobile ? 
          <>
            <Typography style={{ fontWeight: 700, fontSize: "32px" }} variant="h5">
              Order History
            </Typography>
            <div onClick={handleFilterChange} className="iconBtn">
              {getSelectedFilterText(rangeType)}
              <KeyboardArrowDownIcon />
            </div>
          </> :
          renderWebTabs()
        } */}
        {Utils.isMobile ? <></>:renderWebTabs()}
        <Drawer
          anchor={"right"}
          open={isOpenFilters}
          onClose={handleFilterChange}
          onClick={() => {
            // setOpenDetailDrawer(false);
          }}
          style={{ zIndex: 9999999 }}
        >
          {isOpenFilters ? (
            <div className="filterDrawer">
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="heading" style={{ marginLeft: "8px" }}>Filters</span>
                <div
                  onClick={() => {
                    handleFilterChange();
                  }}
                  className="close-icon" style={{ marginRight: 0 }}
                >
                  <CloseIcon style={{ fontSize: 18 }} />
                </div>
              </div>
              <div className="filterSection">
                <div className="filterSection filters">
                  <FilterItem
                    onChange={() => {
                      onChangeRangeType("Y")
                    }}
                    checked={rangeType == "Y"}
                    label="Yesterday"
                  />
                  <FilterItem
                    onChange={() => {
                      onChangeRangeType("T")
                    }}
                    checked={rangeType == "T"}
                    label="Today"
                  />
                  <FilterItem
                    onChange={() => {
                      onChangeRangeType("M")
                    }}
                    checked={rangeType == "M"}
                    label="MTD"
                  />
                  <FilterItem
                    onChange={() => {
                      onChangeRangeType("7")
                    }}
                    checked={rangeType == "7"}
                    label="Last 7 days"
                  />
                  <FilterItem
                    onChange={() => {
                      onChangeRangeType("15")
                    }}
                    checked={rangeType == "15"}
                    label="Last 15 days"
                  />
                  <FilterItem
                    onChange={() => {
                      onChangeRangeType("30")
                    }}
                    checked={rangeType == "30"}
                    label="Last 30 days"
                  />
                  <FilterItem
                    onChange={() => {
                      onChangeRangeType("90")
                    }}
                    checked={rangeType == "90"}
                    label="Last 90 days"
                  />
                </div>
              </div>
            </div>
          ) : null}
        </Drawer>
      </Box>
    );
  };
  const renderPaginationAndSearch = () => {
    if (!Utils.isMobile) {
      return <React.Fragment>{pagination()}</React.Fragment>;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };
  const pagination = () => {
    return (
      <div
        id="paginationWebId"
        className="fulfilledPaginationWeb"
        style={{ background: "#D93A2F", display: "flex" }}
      >
        <div>
          <Stack spacing={1} style={{}}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={totalRecords}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Stack>
        </div>
      </div>
    );
  };
  const renderOrderTypeTabs = () => {
    return (
      <Box sx={{ mb: 2, p: 2 }} style={{padding: "0px 5px", marginBottom: "5px"}}>
        <AppBar elevation={0} position="static" style={{ background: "none" }}>
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            className="fTabs"
            style={{ background: "none" }}
          >
            <Tab
              icon={<MenuIcon className="fTabIcons" />}
              label={"All Orders"}
            />
            <Tab
              icon={<LocalShippingIcon className="fTabIcons" />}
              label={"Shipped"}
            />
            <Tab
              icon={<NearMeIcon className="fTabIcons" />}
              label={"Picked Up"}
            />
            <Tab
              icon={<SyncSharpIcon className="fTabIcons" />}
              label={
                "Used Items"
              }
            />
          </Tabs>
        </AppBar>
      </Box>
    );
  };

  const renderMobileFilters = () => {
    return  <div style={{margin:"0px 5px 10px 5px"}}>
        <Button className="mobile-filters-btn" onClick={handleFilterChange} endIcon={<KeyboardArrowDownIcon />} variant="outlined">{
            getSelectedFilterText(rangeType)}
        </Button>
    </div>
  }
  //new ui  code 
  return (
    <div className={Utils.isMobile ? "innerContianerMobile" : "innerContianer"}>

      <NotificationPopup {...error}></NotificationPopup>
      {/* {!Utils.isMobile ? <SiteBreadCrumbs siteBreadCrumbs={Constants.SiteBreadCrumbs.FulfilledOrdersHistory} ></SiteBreadCrumbs> : <></>} */}
      {renderFilters()}
      {Utils.isMobile && <div id="tabFilterContaoner">{renderOrderTypeTabs()}
      {renderMobileFilters()}
      </div>}
      {/* {renderPagination()} */}
      <TabPanel value={currentTab} index={currentTab} dir={theme.direction}>
        {renderPaginationAndSearch()}
        {!Utils.isMobile ? renderOrderHistory() : renderOrderHistory()}
      </TabPanel>
      {/* <div id='containerDataTable'>
        {renderOrderHistory()}
      </div> */}
    </div>
  );
  interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
        style={{
          marginTop: Utils.isMobile ? "-14px" : "",
        }}
      >
        <Box sx={{ p: 3 }} style={{ padding: "5px" }} id="containerDataTable">
          <Typography component="div">{children}</Typography>
        </Box>
      </div>
    );
  }
};
export default FulfilledOrdersHistory;