import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import Constants from "../../Common/Constants";
import StoreManager from "../../Managers/Store.manager"
import { useDispatch, useSelector } from 'react-redux';
import ContactCustomerManager from "../../Managers/ContactCustomer.Manager"
import CustomIPickupSlip from "../../Models/CustomIPickupSlip";
import { IPricingSummary, CustomItem, CustomIToAddress, CustomICustomerDetail } from "../../Models/CustomIPickupSlip";
import { ArrowForwardIcon, CloseIcon, ReportProblemIcon } from "../Shared/SharedIcons"
import { INotificationPopupSettings, IButton } from '../../Models/INotificationPopupSettings';
import LabelText from "../../Common/LabelText";
import { NotificationPopup } from '../Shared/NotificationPopup'
import allActions from "../../Store/Actions/AllActions"
import { useNavigate } from 'react-router-dom';
import { PDFDocument } from 'pdf-lib'
import IdentityManager from "../../Managers/Identity.manager";
import Utils from "../../Common/Utils";
import { ReportErrorPopup } from '../Shared/ReportErrorPopup';
import { ServiceNowCnfrmText } from '../Shared/ServiceNowCnfrmText';
var momenttz = require('moment-timezone');
var Barcode = require('react-barcode');
const html2pdf = require('html2pdf.js');

const shipFontSize = "12px"
const today = momenttz.tz("America/Los_Angeles").format(Constants.DateFormat.DateOnly);
export const PickupPrintSlip: React.FC<any> = (props) => {
    const currentStore = StoreManager.currentStore;
    const pathname = window.location.pathname;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let customIPickupSlip: CustomIPickupSlip = props.CustomIPickupSlip
    //let orderLines = useSelector((state: any) => state.CustomerPickupState.orderLines);
    //let customerDetail: ICustomerDetail = useSelector((state: any) => state.CustomerPickupState.customerDetail);
    //let orderDetails: ICustomerPickupDetail = useSelector((state: any) => state.CustomerPickupState.orderDetails);
    //let selectedItems = useSelector((state: any) => state.CustomerPickupState.selectedItems);
    const [openGeneralError, setOpenGeneralError] = React.useState(false);
    const [errorGeneralMsg, setErrorGeneralMsg] = React.useState("");

    // Report Error Pop Up Use States
    const [openReportError, setOpenReportError] = useState(false);
    const [openAlreadyReportedError, setOpenAlreadyReportedError] = useState(false);
    const [openConfirmReportError, setOpenConfirmReportError] = useState(false);
    const [errorReportMsg, setErrorReportMsg] = useState("");

    //Report Error Action Use State
    const [reportErrorAction, setReportErrorAction] = useState("");

    const closeGeneralErrorModel = () => {
        setOpenGeneralError(false);
        setErrorGeneralMsg("");
    }


    const popUpBack = () => {
        navigate(-1)
    }

    const closeErrorReportPopup = () => {
        setOpenReportError(false)
    }

    // Show Pop ups If Error is already reported
    const handleErrorAlreadyReported = () => {
        // setOpenAlreadyReportedError(true);
        setOpenReportError(false);
    }
    // Report Error
    const reportConfirmErrorPopup = () => {
        setOpenConfirmReportError(true);
        setOpenGeneralError(false);

    }

    let errorGeneralPopupCloseButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeGeneralErrorModel,
            // color: Constants.Colors.red,
            color: Constants.Colors.white,
            border: Constants.Colors.red,
            textColor: Constants.Colors.red
        }
    ];

    let errorGeneralPopupButtons: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeGeneralErrorModel,
            // color: Constants.Colors.red,
            color: Constants.Colors.white,
            border: Constants.Colors.red,
            textColor: Constants.Colors.red
        },
        {
            text: "Report Error",
            icon: <ReportProblemIcon />,
            action: reportConfirmErrorPopup,
            color: Constants.Colors.red,
        }
    ];

    let GeneralError: INotificationPopupSettings = {
        open: openGeneralError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorGeneralMsg,
        draggable: false,
        handleClose: closeGeneralErrorModel,
        actions: reportErrorAction ? errorGeneralPopupButtons : errorGeneralPopupCloseButtons
    }

    // Report Error Pop up
    let ReportError: INotificationPopupSettings = {
        open: openReportError,
        type: Constants.NotificationPopupType.Conformation,
        title: "Report Error",
        msg: "",
        isCustomJSX: true,
        customJSX: <ReportErrorPopup handleErrorAlreadyReported={handleErrorAlreadyReported} errorMessage={errorReportMsg} closeErrorReportPopup={closeErrorReportPopup} urlPathname={pathname} reportErrorAction={reportErrorAction}></ReportErrorPopup>,
        draggable: false,
        handleClose: closeErrorReportPopup,
        actions: [],
        fullWidth: true,
        maxWidth: "lg"
    } 

    // Confirm Report Error
    const closeConfirmReportError = () => {
        setOpenConfirmReportError(false);
    }

    const reportErrorPopup = () => {
        setOpenReportError(true);
        setOpenConfirmReportError(false)
    }

    let confirmReportErrorPopupButtons: IButton[] = [
        {
            text: "Continue",
            icon: <ArrowForwardIcon />,
            action: reportErrorPopup,
            color: Constants.Colors.red,
        }
    ];
    let confirmReportError: INotificationPopupSettings = {
        open: openConfirmReportError,
        type: Constants.NotificationPopupType.Conformation,
        title: Constants.MESSAGES.SN_CNFRM_TITLE,
        msg: "",
        customJSX: <ServiceNowCnfrmText></ServiceNowCnfrmText>,
        isCustomJSX: true,
        draggable: false,
        handleClose: closeConfirmReportError,
        actions: confirmReportErrorPopupButtons
    }


    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }
    const renderTableHeading = (text: any) => {
        return <Typography style={{ paddingTop: "6px", marginLeft:"5px" }}><span style={{ fontSize: "10px" }}>{text}</span></Typography>;
    }
    const showGeneralErrorModel = async (msg: any) => {
        setOpenGeneralError(true);
        setErrorGeneralMsg(msg);
        setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.PICKUP_PRINT_SLIP);
        setErrorReportMsg(msg);
    }
    const print = async () => {
        if (props.loadingOrderLines == false && props.showPickupSlip > 0) {
            let el = document.getElementById("PickupSlipContainer") as any;
            if (props.saveType == "email") {
                showActionLoader()
                let fileName = `Pickup Slip`;
                let options = {
                    pdfCallback: pdfDone,
                    margin: [0.1, 0.1, 0.1, 0.1],  // tlbr
                    filename: fileName + '.pdf',
                    image: { type: 'jpeg', quality: 1 },
                    html2canvas: { scale: 2, logging: true },
                    jsPDF: { unit: 'in', format: 'a4', orientation: 'p' },
                    pagebreak: { before: '.breakPage' }
                };
                let worker = await html2pdf().set(options).from(el).toPdf().output("datauristring")
                const data_pdf = worker.substring(worker.indexOf(',') + 1);
                let selectedStore: any = StoreManager.getSelectedStoreDetails(props.store);

                let payload = {
                    PDFData: data_pdf,
                    OrderNo: customIPickupSlip.OrderNo,
                    subject: `[Order # : ${customIPickupSlip.OrderNo}] Pickup Slip`,
                    name: customIPickupSlip.CustomICustomerDetail.name,
                    customerEmail: customIPickupSlip.CustomICustomerDetail.email,
                    storeemail: selectedStore?.StoreEmail,
                    store_retail_manager_ops_email: Utils.retailOpsManagerEmail(selectedStore.StoreId, selectedStore.StoreName),
                    logged_in_user_email: IdentityManager.getUserProperty(Constants.Preferred_UserName)
                }

                let response = await ContactCustomerManager.sendEmailWithAttatchment(payload)
                if (response.success && response?.data?.response?.success == true) {
                    navigate("/FulfillOrders");
                }
                else {

                    showGeneralErrorModel(LabelText.generalErrorMessage);
                }
                hideActionLoader()
            }
            else {
                showActionLoader()
                let fileName = `Pickup Slip`;
                let options = {
                    pdfCallback: pdfDone,
                    margin: [0.1, 0.1, 0.1, 0.1],  // tlbr
                    filename: fileName + '.pdf',
                    image: { type: 'jpeg', quality: 1 },
                    html2canvas: { scale: 2, logging: true },
                    jsPDF: { unit: 'in', format: 'a4', orientation: 'p' },
                    pagebreak: { before: '.breakPage' }
                };
                let worker = await html2pdf().set(options).from(el).toPdf().output('blob');
                var fileURL = URL.createObjectURL(worker);
                hideActionLoader();
                const newTabPromise = new Promise((res, rej) => {
                    res(window.open(fileURL, '_blank'))
                })

                newTabPromise.then(() => { 
                    if(props.navigateAfterSuccess && props.navigateAfterSuccess == true){
                        navigate("/FulfillOrders");
                    }
                 })

            }

        }
    }
    const pdfDone = () => {
        console.log("Pdf generated successfully");
    }

    const formatCost = (number: any) => {

        return parseFloat(number).toFixed(2)

    }
    React.useEffect(() => {
        async function printPackSlip() {
            await print()
        }
        printPackSlip()
    }, [props.showPickupSlip]);
    return (
        <React.Fragment>
            <NotificationPopup {...GeneralError}>
            </NotificationPopup>
            <NotificationPopup {...ReportError}></NotificationPopup>
            <NotificationPopup {...confirmReportError} ></NotificationPopup>
            <div style={{ display: "none" }}>
                <div id="PickupSlipContainer" style={{ background: "white" }}>
                    <div style={{ width: "100%" }}>
                        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                            <div style={{ width: "35%", textAlign: "left" }}>
                                <div className="logo-ContainerPackScreen"></div>
                            </div>
                            <div style={{ width: "65%", textAlign: "left" }}>
                                <Typography
                                    variant="h5"
                                    style={{
                                        textAlign: "center",
                                        color: "#D93A2F",
                                        paddingBottom: "10px",
                                        paddingTop: "10px",
                                        width: "100px",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    Customer Receipt - {currentStore.Company}({currentStore.id})
                                </Typography>
                            </div>
                        </div>
                        <div style={{ display: "flex", width: "100%", marginTop: "5px", textAlign: "center" }}>
                            <div style={{ width: "50%", }}>
                                <Typography variant="h5">
                                    {" "}
                                    <span
                                        style={{
                                            color: "#D93A2F",
                                            letterSpacing: "3.5px",
                                            paddingBottom: "6px",
                                        }}
                                    >
                                        Order Info
                                    </span>{" "}
                                </Typography>
                                <div style={{ marginTop: "10px" }}>
                                    <Typography variant="body2" >
                                        Order # :<b>{customIPickupSlip.OrderNo}</b>
                                    </Typography>
                                    <Typography variant="body2">
                                        {customIPickupSlip.OrderDate}
                                    </Typography>
                                    <Typography variant="body2">
                                        Customer #:<b>{customIPickupSlip.BillToId && customIPickupSlip.BillToId.length > 0 ? customIPickupSlip.BillToId : ""}</b>
                                    </Typography>
                                </div>
                            </div>
                            <div style={{ width: "50%", textAlign: "center" }}>
                                <Barcode width={"2px"} value={customIPickupSlip.OrderNo} />
                            </div>
                        </div>
                        <div style={{ display: "flex", width: "100%", marginTop: "5px" }}>
                            <div style={{ width: "50%", textAlign: "center" }}>
                                <Typography variant="h5">
                                    {" "}
                                    <span
                                        style={{
                                            color: "#D93A2F",

                                            paddingBottom: "6px",
                                        }}
                                    >
                                        Billing Address
                                    </span>{" "}
                                </Typography>
                                <Typography variant="body2">
                                    {customIPickupSlip.CustomICustomerDetail.name}
                                </Typography>
                                <Typography variant="body2">
                                    {customIPickupSlip.CustomICustomerDetail.address}
                                </Typography>
                                <Typography variant="body2">
                                    {customIPickupSlip.CustomICustomerDetail.city},{customIPickupSlip.CustomICustomerDetail.state},{customIPickupSlip.CustomICustomerDetail.zipCode}
                                </Typography>
                            </div>
                            <div style={{ width: "50%", textAlign: "center" }}>
                                <Typography variant="h5">
                                    {" "}
                                    <span
                                        style={{
                                            color: "#D93A2F",

                                            paddingBottom: "6px"
                                        }}
                                    >
                                        Shipping Address
                                    </span>{" "}
                                </Typography>
                                <Typography variant="body2">
                                    {customIPickupSlip.CustomIToAddress.FirstName} {" "} {customIPickupSlip.CustomIToAddress.MiddleName} {" "} {customIPickupSlip.CustomIToAddress.LastName}
                                </Typography>
                                <Typography variant="body2">
                                    {customIPickupSlip.CustomIToAddress.Company}
                                </Typography>
                                <Typography variant="body2">
                                    {customIPickupSlip.CustomIToAddress.AddressLine1} {","} {customIPickupSlip.CustomIToAddress.AddressLine2 ? customIPickupSlip.CustomIToAddress.AddressLine2 : ""} {" "}
                                </Typography>
                                <Typography variant="body2">
                                    {customIPickupSlip.CustomIToAddress.City}{","}{customIPickupSlip.CustomIToAddress.State}{","}{customIPickupSlip.CustomIToAddress.ShortZipCode}
                                </Typography>
                                <Typography variant="body2">
                                    {customIPickupSlip.CustomIToAddress.Country}
                                </Typography>
                            </div>
                        </div>
                        <div style={{ display: "flex", width: "100%", marginTop: "5px" }}>
                            <div style={{ width: "50%", textAlign: "center" }}>
                                <Typography variant="h5">
                                    {" "}
                                    <span
                                        style={{
                                            color: "#D93A2F",
                                            letterSpacing: "3.5px",
                                            paddingBottom: "6px",
                                        }}
                                    >
                                        Payment Method
                                    </span>{" "}
                                </Typography>
                                <Typography variant="body2">
                                    {
                                        customIPickupSlip.PaymentMethod
                                    }
                                </Typography>
                            </div>
                            <div style={{ width: "50%", textAlign: "center" }}>
                                <Typography variant="h5">
                                    {" "}
                                    <span
                                        style={{
                                            color: "#D93A2F",

                                            paddingBottom: "6px",
                                        }}
                                    >
                                        Date
                                    </span>
                                </Typography>
                                <Typography variant="body2">
                                    {today}
                                </Typography>
                            </div>
                        </div>
                        <div style={{ display: "flex", width: "100%", marginTop: "5px" }}>
                            <div style={{ width: "50%", textAlign: "center" }}>
                                <Typography variant="h5">
                                    {" "}
                                    <span
                                        style={{
                                            color: "#D93A2F",

                                            paddingBottom: "6px",
                                        }}
                                    >
                                        Associate
                                    </span>{" "}
                                </Typography>
                                <Typography variant="body2">
                                    {customIPickupSlip.UserName}
                                </Typography>
                            </div>
                        </div>
                        <div style={{ display: "flex", width: "100%", marginTop: "40px" }}>
                            <table style={{ width: "100%" }} cellPadding={0} cellSpacing={0}>
                                <thead style={{ background: "#D93A2F", color: "white" }}>
                                    <th style={{ width: "10%", textAlign: "center" }}>
                                        {renderTableHeading("Quantity")}
                                    </th>
                                    <th style={{ width: "10%", textAlign: "left" }}>
                                        {renderTableHeading("Item ID")}
                                    </th>
                                    <th style={{ width: "32%", textAlign: "left" }}>
                                        {renderTableHeading("Description")}
                                    </th>
                                    <th style={{ width: "12%", textAlign: "left" }}>
                                        {renderTableHeading("Serial No.")}
                                    </th>
                                    <th style={{ width: "12%", textAlign: "left" }}>
                                        {renderTableHeading("Sales Ticket")}
                                    </th>
                                    <th style={{ width: "12%", textAlign: "left" }}>
                                        {renderTableHeading("Price")}
                                    </th>
                                    <th style={{ width: "12%", textAlign: "left" }}>
                                        {renderTableHeading("Status")}
                                    </th>
                                </thead>
                                {
                                    customIPickupSlip.CustomItem.map((item: CustomItem, index: any) => {
                                        return (<tr style={{ borderBottom: "1px solid #eee", height: "70px" }}>
                                            <td style={{ textAlign: "center" }}>
                                                <Typography
                                                    style={{
                                                        fontSize: "12px",
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {item.Quantity}
                                                </Typography>
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
                                                    {item.ItemID}
                                                </Typography>
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                <Typography style={{ fontSize: "12px", fontWeight: 500,marginLeft:"5px" }}>
                                                    {item.ItemDesc}
                                                </Typography>
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
                                                    {item.SerialNumber}
                                                </Typography>
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
                                                    {item.ExtnPOSSalesTicketNo}
                                                </Typography>
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
                                                    {"$"}{item.UnitPrice}
                                                </Typography>
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
                                                    {customIPickupSlip.Status}
                                                </Typography>
                                            </td>
                                        </tr>);
                                    })
                                }

                            </table>
                        </div>
                        {customIPickupSlip.IPricingSummary ? <div style={{ display: "flex", width: "100%", marginTop: "15px" }}>
                            <div style={{ width: "70%", textAlign: "center" }}>

                            </div>
                            <div style={{ width: "30%", textAlign: "right", display: "flex", justifyContent: "flex-end", paddingRight: "15px" }}>
                                <table cellPadding={0} cellSpacing={0}>
                                    <tr>
                                        <td style={{ textAlign: "left", width: "50%" }}>
                                            <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
                                                Adjustments
                                            </Typography>
                                        </td>
                                        <td style={{ textAlign: "right", width: "50%" }}>
                                            <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
                                                {formatCost(customIPickupSlip.IPricingSummary.Adjustments)}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "left", width: "50%" }}>
                                            <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
                                                Shipping
                                            </Typography>
                                        </td>
                                        <td style={{ textAlign: "right", width: "50%" }}>
                                            <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
                                                {formatCost(customIPickupSlip.IPricingSummary.ShippingCharges)}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "left", width: "50%" }}>
                                            <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
                                                SubTotal
                                            </Typography>
                                        </td>
                                        <td style={{ textAlign: "right", width: "50%" }}>
                                            <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
                                                {formatCost(customIPickupSlip.IPricingSummary.SubTotal)}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "left", width: "50%" }}>
                                            <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
                                                Taxes
                                            </Typography>
                                        </td>
                                        <td style={{ textAlign: "right", width: "50%" }}>
                                            <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
                                                {formatCost(customIPickupSlip.IPricingSummary.Taxes)}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr style={{ borderTop: "1px solid black" }}>
                                        <td style={{ textAlign: "left", width: "50%", }}>
                                            <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
                                                Total
                                            </Typography>
                                        </td>
                                        <td style={{ textAlign: "right", width: "50%" }}>
                                            <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
                                                $ {formatCost(customIPickupSlip.IPricingSummary.Total)}
                                            </Typography>
                                        </td>
                                    </tr>
                                </table>

                            </div>
                        </div> : ""}

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
