import { CHANGE_TECAUDITLINES, CHANGE_SELECTEDTAG, CHANGE_TAGSLIST } from "../ActionTypes";
const initialState = {
    auditLines: [],
    selectedTag: {
        key: "All",
        label:"All"
    },
    tags: []
}
const TecAuditsReducers = (state: any = initialState,
    action: { type: any; payLoad: any }) => {
    switch (action.type) {
        case CHANGE_TECAUDITLINES:
            return {
                ...state,
                auditLines: action.payLoad
            }
        case CHANGE_SELECTEDTAG:
            return {
                ...state,
                selectedTag: action.payLoad
            }
        case CHANGE_TAGSLIST:
            return {
                ...state,
                tags: action.payLoad
            }
    }
    return state;
}

export default TecAuditsReducers;