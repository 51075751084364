import { Navigate, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { textAlign } from '@mui/system';
import { Divider, Typography } from '@material-ui/core';
import StoreManager from '../../Managers/Store.manager';
import allActions from '../../Store/Actions/AllActions';
import ApiService from '../../Common/ApiService'
import PdfIframe from "../../Subcomponent/PickOrder/PdfIframe";
import Constants from '../../Common/Constants';
import Utils from '../../Common/Utils';
import { OmsPickOrderDetail } from '../../Models/OmsPickOrderDetail';
import OMSManager from '../../Managers/Oms.manager';
const primaryColor = "#D93A2F"
const shipFontSize = "12px"
var moment = require('moment');
var momenttz = require('moment-timezone');
const today = momenttz.tz("America/Los_Angeles").format(Constants.DateFormat.DateOnly);
const PickSlip = (props: any) => {
    const dispatch = useDispatch();
    const currentStore = StoreManager.currentStore
    const [loading, setLoading] = useState(true);
    const [openpdf, setOpenpdf] = useState(false);
    const [itemDetailFromElastic, SetitemDetailFromElastic] = useState({} as any);
    const [pickSlipItemLocations, setPickSlipItemLocations] = useState([] as any);
    const [customerAddress, SetcustomerAddress] = useState({} as any);
    const [ShipmentNo, SetShipmentNo] = useState("");
    const [OrderNo, SetOrderNo] = useState("");
    const [LevelOfService, SetLevelOfService] = useState("");
    let orderRows = useSelector((state: any) => state.FulfillOrdersReducersState.orderRows);
    let orderDetail: OmsPickOrderDetail = useSelector((state: any) => state.PickOrdersReducersState.orderDetail);
    let srCount = 0

    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }

    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }
    const getCustomerDetails = (data: any) => {
        let initialCustomerDetail = {
            name: "",
            city: "",
            email: "",
            phone: "",
            zipCode: "",
            address: "",
            Country: "",
            state: ""
        }
        if (data.CustomerInfo != undefined) {
            try {
                if (data.CustomerInfo.hasOwnProperty("FirstName")) {
                    initialCustomerDetail.name = data.CustomerInfo.FirstName
                }
                if (data.CustomerInfo.hasOwnProperty("LastName")) {
                    initialCustomerDetail.name = initialCustomerDetail.name + " " + data.CustomerInfo.LastName
                }
                if (data.CustomerInfo.hasOwnProperty("City")) {
                    initialCustomerDetail.city = data.CustomerInfo.City
                }
                if (data.CustomerInfo.hasOwnProperty("State")) {
                    initialCustomerDetail.state = data.CustomerInfo.state
                }
                if (data.CustomerInfo.hasOwnProperty("Country")) {
                    initialCustomerDetail.Country = data.CustomerInfo.Country
                }
                if (data.CustomerInfo.hasOwnProperty("CustomerEMailID")) {
                    initialCustomerDetail.email = data.CustomerInfo.CustomerEMailID
                }
                if (data.CustomerInfo.hasOwnProperty("ZipCode")) {
                    initialCustomerDetail.zipCode = data.CustomerInfo.ZipCode
                }
                if (data.CustomerInfo.hasOwnProperty("AddressLine1")) {
                    initialCustomerDetail.address = data.CustomerInfo.AddressLine1
                }
                if (data.CustomerInfo.hasOwnProperty("AddressLine2") && data.CustomerInfo.AddressLine2 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.CustomerInfo.AddressLine2
                }
                if (data.CustomerInfo.hasOwnProperty("AddressLine3") && data.CustomerInfo.AddressLine3 != "") {
                    initialCustomerDetail.address = initialCustomerDetail.address + ", " + data.CustomerInfo.AddressLine3
                }
            }
            catch {
                console.log("Error Getting Customer Detail")
            }

        }
        return initialCustomerDetail
    }
    const renderTableHeading = (text: any) => {
        // return <Typography variant='body1' style={{ letterSpacing: "1.5px", paddingTop: "6px" }}><span style={{ letterSpacing: "1.5px", fontSize: "10px" }}>{text}</span></Typography>;
        return <Typography variant='body1' style={{}}><span style={{ fontSize: "12px" }}>{text}</span></Typography>;
    }
    const renderItemLocationReceive = (sku: any) => {
        if (pickSlipItemLocations?.length > 0) {
            const selectedItem: any = pickSlipItemLocations?.filter((item: any) => { return item?.sku === sku })
            return selectedItem.length > 0 ? <Typography variant='body1' style={{}}><span style={{ fontSize: "12px" }}>{selectedItem[0]?.rxDate}</span></Typography> : "-"
        }
        else {

            return "-"
        }

    }

    const renderItemLocationPI = (sku: any) => {

        if (pickSlipItemLocations?.length > 0) {
            const selectedItem: any = pickSlipItemLocations?.filter((item: any) => { return item?.sku === sku })
            return selectedItem.length > 0 ? <Typography variant='body1' style={{}}><span style={{ fontSize: "12px" }}>{selectedItem[0]?.piDate}</span></Typography> : "-"
        }
        else {

            return "-"
        }

    }

    const renderTableLocations = (sku: any) => {

        if(pickSlipItemLocations?.length > 0) {
            const selectedItem: any = pickSlipItemLocations?.filter((item: any) => { return item?.sku === sku })
            if (selectedItem.length === 0) {
                return
            } else {

                return <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
                    {selectedItem[0]?.items.map((item: any, index: any) => {
                        return <div style={{ display: "flex", flexDirection: "column" }}>
                            <div key={index} style={{ marginRight: '20px' }}>
                                <div >
                                    {((index === 0) || (index % 7 === 0)) && <Typography variant='body1' display="inline" style={{ fontSize: "12px", padding: "5px", fontWeight: 700, paddingRight: "28px" }}>SKU: </Typography>} <Typography variant='body1' display="inline" style={{ fontSize: "12px", padding: "5px", minWidth: "30px", textAlign: "left" }}>{item['SKU.Number'] || '-'}</Typography>
                                </div>
                                <div >
                                    {((index === 0) || (index % 7 === 0)) && <Typography variant='body1' display="inline" style={{ fontSize: "12px", padding: "5px", fontWeight: 700 }}>Location: </Typography>}  <Typography display="inline" style={{ fontSize: "12px", padding: "5px", minWidth: "30px", textAlign: "left" }}> {item['LastPIArea'] || '-'}</Typography>
                                </div>

                            </div>
                            {<Divider style={{ width: "100%" }}></Divider>}
                        </div>
                    })}
                </div>
            }
        }
    }
    const renderBundleChildRows = (bundleParentOrderLineKey: any) => {
        return itemDetailFromElastic.map((item: any, index: any) => {
            if (item.BundleParentOrderLineKey && item.BundleParentOrderLineKey == bundleParentOrderLineKey) {
                return (
                    <>

                        <tr>
                            <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                {""}
                            </td>
                            <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                {renderTableHeading(item?.ItemDetails?.Extn?.ExtnPOSItemID)}
                            </td>
                            <td style={{ textAlign: "left" }} className="picklistTableCollaped">

                                {renderTableHeading(item.ItemDesc)}
                            </td>
                            <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                {renderTableHeading(item.UnitPrice)}
                            </td>
                            <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                {renderTableHeading(item.OrderedQty)}
                            </td>
                            <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                {renderItemLocationPI(item?.ItemDetails?.Extn?.ExtnPOSItemID)}
                            </td>
                            <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                {renderItemLocationReceive(item?.ItemDetails?.Extn?.ExtnPOSItemID)}
                            </td>
                            
                        </tr>

                        <tr style={{ textAlign: "left" }} className="picklistTableCollaped">
                            <td colSpan={9}>
                                {renderTableLocations(item?.ItemDetails?.Extn?.ExtnPOSItemID)}
                            </td>

                        </tr>
                    </>


                );

            }

        })

    }
    const loadData = async () => {
        showActionLoader();
        let itemLocationsPayload = Utils.getItemsLocationPayload(orderDetail);
        if (itemLocationsPayload && itemLocationsPayload.length > 0) {
            try{

                let itemsLocationResponse = await OMSManager.getItemLocationDetails(itemLocationsPayload)
                if (itemsLocationResponse && itemsLocationResponse.success) {
                    setPickSlipItemLocations(itemsLocationResponse.data)
                }
                else {
                    setPickSlipItemLocations([]);
                }
            }
            catch(error){
                console.log("OMSGetPosCycleCount Error",error);
                setPickSlipItemLocations([]);
            }
        }
        else {
            setPickSlipItemLocations([]);
        }

        if (orderRows.length > 0) {
            let result = orderRows.filter((filteritem: any) => filteritem._id == props.shipmentKey)[0]
            SetitemDetailFromElastic(result._source.Shipment.ShipmentLinesPickSlip)
            SetShipmentNo(result._source.Shipment.ShipmentNo)
            SetOrderNo(result._source.Shipment.OrderNo);
            SetLevelOfService(result._source.Shipment.LevelOfService)
            SetcustomerAddress(getCustomerDetails(result._source.Shipment))
            hideActionLoader();
            setLoading(false)
            setOpenpdf(true)

        }
        else {
            let orderDetail: any = {};
            ApiService.get(`/pf/getOrderFromExStorePickSlipValid?shipmentKey=` + props.shipmentKey).then((response: any) => {
                orderDetail = response.data.hits.hits.length > 0 ? response.data.hits.hits[0]._source.Shipment : {};
                SetitemDetailFromElastic(orderDetail.ShipmentLinesPickSlip);
                SetShipmentNo(orderDetail.ShipmentNo);
                SetOrderNo(orderDetail.OrderNo);
                SetLevelOfService(orderDetail.LevelOfService);
                SetcustomerAddress(getCustomerDetails(orderDetail));
                hideActionLoader();
                setLoading(false)
                setOpenpdf(true)
            })

        }
    }

    React.useEffect(() => {
        console.log("useEffect");
        async function Init() {
            await loadData()
        }
        Init()
        return () => {
            // TODO document why this arrow function is empty

        }
    }, []);
    return (
        <React.Fragment>
            {
                !loading ?
                    <div style={{ display: "none" }}>
                        <div id='PickSlipContainer' style={{ background: 'white' }}>
                            <div style={{ width: '100%' }}>
                                <Typography variant='h5' style={{ textAlign: "center", color: "black", background: "#eee", paddingBottom: "10px", paddingTop: "10px" }}>PICK TICKET</Typography>
                                <div style={{ display: "flex", width: "100%", marginTop: "5px" }}>
                                    <div style={{ width: "33%", textAlign: "left" }}>
                                        <Typography variant='body1'> <span style={{ color: "black", borderBottom: "1px solid black", paddingBottom: "6px", fontWeight: 500 }}>Pick Request</span> </Typography>
                                        <Typography variant='body2' style={{ paddingTop: "6px" }}>Ticket #:{moment().valueOf()}</Typography>
                                        <br>
                                        </br>
                                        <Typography variant='body1' style={{ paddingTop: "6px", fontWeight: 500 }}>Current Date:</Typography>
                                        <Typography variant='body2' style={{}}><span style={{}}>{today}</span></Typography>
                                    </div>
                                    <div style={{ width: "33%", textAlign: "left" }}>
                                        <Typography variant='body1'> <span style={{ color: "black", fontWeight: 500 }}>Ship From:</span> </Typography>
                                        <Typography variant='body2'>{currentStore.StoreName} </Typography>
                                        <Typography variant='body2'> <span style={{}}>{currentStore.Company} {currentStore.id}</span> </Typography>
                                        <Typography variant='body2'> <span style={{}}>{currentStore.Address}</span> </Typography>
                                        <Typography variant='body2'> <span style={{}}>{currentStore.City} {currentStore.State} {currentStore.Zip}</span> </Typography>
                                        <Typography variant='body2'> <span style={{}}>{currentStore.Phone}</span> </Typography>
                                    </div>
                                    <div style={{ width: "33%", textAlign: "left" }}>
                                        <Typography variant='body1'> <span style={{ color: "black", fontWeight: 500 }}>Ship To:</span> </Typography>
                                        <Typography variant='body2'> <span style={{}}>{customerAddress.name}</span> </Typography>
                                        <Typography variant='body2'> <span style={{}}>{customerAddress.address}</span> </Typography>
                                        <Typography variant='body2'> <span style={{}}>{customerAddress.state} {customerAddress.zipCode}</span> </Typography>
                                        <Typography variant='body2'> <span style={{}}>{customerAddress.email}</span> </Typography>
                                        <Typography variant='body2'> <span style={{}}>{customerAddress.Country}</span> </Typography>
                                    </div>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginTop: "5px" }}>
                                    <div style={{ width: "33%", textAlign: "left" }}>
                                        <Typography variant='body1' style={{ paddingTop: "6px", fontWeight: 500 }}>Order #:</Typography>
                                        <Typography variant='body2' style={{}}><span style={{}}>{OrderNo}</span></Typography>
                                    </div>
                                    <div style={{ width: "33%", textAlign: "left" }}>
                                        <Typography variant='body1' style={{ paddingTop: "6px", fontWeight: 500 }}>Ship Method:</Typography>
                                        <Typography variant='body2' style={{}}><span style={{}}>{LevelOfService}</span></Typography>
                                    </div>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginTop: "40px" }}>
                                    <table style={{ width: "100%", borderRight: "1px solid black" }} cellPadding={0} cellSpacing={0} className="packingListTable">
                                        <thead style={{ color: "black", borderTop: "1px solid black" }}>
                                            <th style={{ width: "5%", textAlign: "left" }} className="picklistTableCollaped">
                                                <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Sr no.</Typography>
                                            </th>
                                            <th style={{ width: "10%", textAlign: "left" }} className="picklistTableCollaped">
                                                <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>POS Item ID</Typography>
                                            </th>
                                            <th style={{ width: "35%", textAlign: "left" }} className="picklistTableCollaped">

                                                <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Item Description</Typography>
                                            </th>
                                            <th style={{ width: "5%", textAlign: "left" }} className="picklistTableCollaped">
                                                <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Price</Typography>

                                            </th>
                                            <th style={{ width: "5%", textAlign: "left" }} className="picklistTableCollaped">
                                                <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Qty To Pick</Typography>

                                            </th>
                                            <th style={{ width: "10%", textAlign: "left" }} className="picklistTableCollaped">
                                                <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Last PI</Typography>

                                            </th>
                                            <th style={{ width: "10%", textAlign: "left" }} className="picklistTableCollaped">
                                                <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Receive Date</Typography>

                                            </th>
                                        </thead>
                                        {
                                            itemDetailFromElastic.map((item: any, index: any) => {
                                                if (item.BundleParentOrderLineKey == undefined) {
                                                    srCount = srCount + 1
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                                                    {renderTableHeading(srCount)}
                                                                </td>
                                                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                                                    {renderTableHeading(item?.ItemDetails?.Extn?.ExtnPOSItemID)}
                                                                </td>
                                                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">

                                                                    {renderTableHeading(item.ItemDesc)}
                                                                </td>
                                                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                                                    {renderTableHeading(item.UnitPrice)}
                                                                </td>
                                                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                                                    {renderTableHeading(item.OrderedQty)}
                                                                </td>
                                                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                                                    {renderItemLocationPI(item?.ItemDetails?.Extn?.ExtnPOSItemID)}
                                                                </td>
                                                                <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                                                                    {renderItemLocationReceive(item?.ItemDetails?.Extn?.ExtnPOSItemID)}
                                                                </td>

                                                            </tr>
                                                            <tr style={{ textAlign: "left" }} className="picklistTableCollaped">
                                                                <td colSpan={9}>
                                                                    {renderTableLocations(item?.ItemDetails?.Extn?.ExtnPOSItemID)}
                                                                </td>

                                                            </tr>
                                                            {
                                                                renderBundleChildRows(item.OrderLineKey)
                                                            }
                                                        </>
                                                    );

                                                }

                                            })
                                        }

                                    </table>
                                </div>

                                {/* <Typography variant='h5' style={{ textAlign: "center", color: "white", background: "#D93A2F", paddingBottom: "10px", paddingTop: "10px" }}>PICK TICKET</Typography>
                                <div style={{ display: "flex", width: "100%", marginTop: "5px" }}>
                                    <div style={{ width: "33%", textAlign: "left" }}>
                                        <Typography variant='h5'> <span style={{ color: "#D93A2F", borderBottom: "1px solid #D93A2F", letterSpacing: "3.5px", paddingBottom: "6px" }}>Pick Request</span> </Typography>
                                        <Typography variant='body2' style={{ letterSpacing: "1.5px", paddingTop: "6px" }}>Shipment No: # {ShipmentNo}</Typography>
                                        <br>
                                        </br>
                                        <Typography variant='body1' style={{ letterSpacing: "1.5px", paddingTop: "6px" }}>Current Date:</Typography>
                                        <Typography variant='body1' style={{ letterSpacing: "1.5px", paddingTop: "6px" }}><span style={{ letterSpacing: "1.5px", fontSize: shipFontSize }}>{today}</span></Typography>
                                    </div>
                                    <div style={{ width: "33%", textAlign: "center" }}>
                                        <Typography variant='h5'> <span style={{ color: "#D93A2F", letterSpacing: "3.5px", paddingBottom: "6px" }}>Ship From</span> </Typography>
                                        <Typography variant='body1'> <b style={{ letterSpacing: "1.5px", fontSize: shipFontSize }}>{currentStore.StoreName}</b> </Typography>
                                        <Typography variant='body1'> <span style={{ letterSpacing: "1.5px", fontSize: shipFontSize }}>{currentStore.Company} {currentStore.id}</span> </Typography>
                                        <Typography variant='body1'> <span style={{ letterSpacing: "1.5px", fontSize: shipFontSize }}>{currentStore.Address}</span> </Typography>
                                        <Typography variant='body1'> <span style={{ letterSpacing: "1.5px", fontSize: shipFontSize }}>{currentStore.City} {currentStore.State} {currentStore.Zip}</span> </Typography>
                                        <Typography variant='body1'> <span style={{ letterSpacing: "1.5px", fontSize: shipFontSize }}>{currentStore.Phone}</span> </Typography>
                                    </div>
                                    <div style={{ width: "33%", textAlign: "center" }}>
                                        <Typography variant='h5'> <span style={{ color: "#D93A2F", letterSpacing: "3.5px", paddingBottom: "6px" }}>Ship To</span> </Typography>
                                        <Typography variant='body1'> <span style={{ letterSpacing: "1.5px", fontSize: shipFontSize }}>{customerAddress.name}</span> </Typography>
                                        <Typography variant='body1'> <span style={{ letterSpacing: "1.5px", fontSize: shipFontSize }}>{customerAddress.address}</span> </Typography>
                                        <Typography variant='body1'> <span style={{ letterSpacing: "1.5px", fontSize: shipFontSize }}>{customerAddress.state} {customerAddress.zipCode}</span> </Typography>
                                        <Typography variant='body1'> <span style={{ letterSpacing: "1.5px", fontSize: shipFontSize }}>{customerAddress.email}</span> </Typography>
                                        <Typography variant='body1'> <span style={{ letterSpacing: "1.5px", fontSize: shipFontSize }}>{customerAddress.Country}</span> </Typography>
                                    </div>
                                </div>

                                <div style={{ display: "flex", width: "100%", marginTop: "40px" }}>
                                    <table style={{ width: "100%" }} cellPadding={0} cellSpacing={0}>
                                        <thead style={{ background: "#D93A2F", color: "white" }}>
                                            <th style={{ width: "10%", textAlign: "center" }}>
                                                {renderTableHeading("Sr.No")}
                                            </th>
                                            <th style={{ width: "18%", textAlign: "left" }}>
                                                {renderTableHeading("Order #")}
                                            </th>
                                            <th style={{ width: "44%", textAlign: "left" }}>
                                                {renderTableHeading("Item Description")}
                                            </th>
                                            <th style={{ width: "18%", textAlign: "left" }}>
                                                {renderTableHeading("Ship Method")}
                                            </th>
                                            <th style={{ width: "10%", textAlign: "center" }}>
                                                {renderTableHeading("Qty To Pick")}
                                            </th>
                                        </thead>
                                        {
                                            itemDetailFromElastic.map((item: any) => {
                                                if (item.ExtnIsWarrantyItem == "N") {
                                                    return (
                                                        <tr style={{ borderBottom: "1px solid #eee", height: "106px" }}>
                                                            <td style={{ textAlign: "center" }}>
                                                                {renderTableHeading("1")}
                                                            </td>
                                                            <td style={{ textAlign: "left" }}>
                                                                {renderTableHeading(OrderNo)}
                                                            </td>
                                                            <td style={{ textAlign: "left" }}>
                                                                <Typography style={{ letterSpacing: "1.5px", fontSize: "15px", fontWeight: 500 }}>{item.ItemDesc}</Typography>
                                                                <Typography style={{ letterSpacing: "1.5px", fontSize: "11px" }}>Item ID: <b>{item.ItemID}</b></Typography>
                                                                <Typography style={{ letterSpacing: "1.5px", fontSize: "11px" }}>Price: <b>{item.UnitPrice}</b></Typography>
                                                            </td>
                                                            <td style={{ textAlign: "left" }}>
                                                                {renderTableHeading(LevelOfService)}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {renderTableHeading(item.OrderedQty)}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            })
                                        }
                                    </table>
                                </div> */}
                            </div>
                        </div>

                    </div>

                    :
                    <div></div>
            }
            {
                openpdf ?
                    <PdfIframe open={openpdf} close={props.close} blobUrl={""} ></PdfIframe>
                    :
                    ""
            }
        </React.Fragment>
    );


}

export default PickSlip;