import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const StoreCard = (props: any) => {
    const store = props.store;
    const storeColumns = props.storeColumns;
    return (
        <Card key={store.id} style={{ marginBottom: '8px', width: "100%" }} className='dCard'>
            <CardContent className='dCardContent'>
                {storeColumns.map((column: any, colIndex: number) => (
                    colIndex !== storeColumns.length - 1 ?
                        <div key={`${store.id}-${column.id}`} style={{ display: 'flex', flexDirection: 'row',alignItems:"center" }}>
                            {colIndex !== 0 && ( // Check if it's not the first column
                                <Typography style={{ color: "#676767", marginRight: '8px',fontSize:"12px",marginLeft:"8px", fontStyle:"normal",fontWeight:400  }}>
                                    {column.name}
                                </Typography>
                            )}
                            <Typography style={{color: "#333",fontSize:"13px",fontStyle:"normal",fontWeight:500,lineHeight:"20px"}}>
                                <strong>{column.cell(store)}</strong>
                            </Typography>
                        </div> :
                        <div key={`${store.id}-${column.id}`}>
                            <Typography>
                                <strong>{column.cell(store)}</strong>
                            </Typography>
                        </div>
                ))}
            </CardContent>
        </Card>
    );
}

export default StoreCard;
