import { CHANGE_ORDER_LINES, CHANGE_CUSTOMER_DETAIL, SET_CUSTOMERPICKUP_ORDER_DETAILS, CHANGE_SELECTED_ITEMS } from "../ActionTypes";
const initialState = {
    orderLines: [],
    customerDetail: {},
    orderDetails: {},
    selectedItems: [],
}
const CustomerPickupReducers = (state: any = initialState,
    action: { type: any; payLoad: any }) => {
    let clone = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case CHANGE_ORDER_LINES:
            return {
                ...state,
                orderLines: action.payLoad
            }
        case CHANGE_CUSTOMER_DETAIL:
            return {
                ...state,
                customerDetail: action.payLoad
            }

        case SET_CUSTOMERPICKUP_ORDER_DETAILS:
            return {
                ...state,
                orderDetails: action.payLoad
            }
        case CHANGE_SELECTED_ITEMS:
            return {
                ...state,
                selectedItems: action.payLoad
            }

    }
    return state;
}

export default CustomerPickupReducers;