import IdentityManager from "./Identity.manager";
import PFUtils from "../Common/Process.Fulfillment.Utils";
import StoreManager from "./Store.manager";
import ApiService from "../Common/ApiService";
import ApiURLS from "../Common/ApiURLS"
import { StringDecoder } from "string_decoder";
import Constants from "../Common/Constants";
export default class PFManager {

    public static default: any = null;
    public static labelData: any = [];
    public static reasonCodes: any[] = [];
    public static carrierServiceData: any = [];
    public static groupsRights: any[] = [];
    public static navigationData: any[] = [
        {
            "IsExternal": "false",
            "Position": 1,
            "Route": "FulfillOrders",
            "Data": "",
            "Header": "Fulfill Orders"
        },
        {
            "IsExternal": "false",
            "Position": 2,
            "Route": "ReceiveOrders",
            "Data": "",
            "Header": "Receive"
        },
        {
            "IsExternal": "false",
            "Position": 5,
            "Route": "Reports",
            "Data": "",
            "Header": "Reports"
        },
        {
            "IsExternal": "false",
            "Position": 6,
            "Route": "TechAudits",
            "Data": "",
            "Header": "TechAudits"
        },
        {
            "IsExternal": "false",
            "Position": 7,
            "Route": "OrdersSyncFlow",
            "Data": "",
            "Header": "Orders Sync Flow"
        },
        {
            "IsExternal": "false",
            "Position": 3,
            "Route": "KCDCReturns",
            "Data": "",
            "Header": "Returns"
        },
        {
            "IsExternal": "false",
            "Position": 0,
            "Route": "Dashboard",
            "Data": "",
            "Header": "Dashboard"
        },
        {
            "IsExternal": "false",
            "Position": 8,
            "Route": "Feedback",
            "Data": "",
            "Header": "Feedback"
        },
        {
            "IsExternal": "false",
            "Position": 4,
            "Route": "OrderHistory",
            "Data": "",
            "Header": "Order History"
        },
        {
            "IsExternal": true,
            "Position": -1,
            "Route": "ExternalCustomerView",
            "Data": "",
            "Header": "ExternalCustomerView"
        }
    ];

    public static getCarrierServices = async () => {
        let token = IdentityManager.AccessToken;
        let body = { "ACTION": "GET_SERVICES" };
        let _res = await fetch(
            `/pf/sendRequest`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }
        );
        if (_res.status !== 500 && _res.status !== 400) {
            let res = await _res.json();
            let [results, def] = PFUtils.parseCarrierServices(res.response.data);
            PFManager.carrierServiceData = results;
            PFManager.default = def;
        }
    }

    public static getCodes = async () => {
        let token = IdentityManager.AccessToken;
        let body = { "ACTION": "GET_CODES" };
        let _res = fetch(
            `/pf/sendRequest`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }
        )
            .then(async (response) => {
                let res: any = await response.json();
                PFManager.reasonCodes = res.response.data;
            })
            .catch(error => console.log('error', error));
    }

    public static getRecommendedService = async (state: string, weight: string, company: string) => {
        let token = IdentityManager.AccessToken;
        let body = {
            "ACTION": "GET_SERVICE_RECOMMENDATION",
            "PAYLOAD": {
                "STATE": state,
                "WEIGHT": weight,
                "COMPANY": company
            }
        };
        let result = null;
        try {
            let _res = await fetch(
                `/pf/sendRequest`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            )
            let res: any = await _res.json();
            result = res;
        } catch (error) {
            console.log(error);
        }
        return result;
    }

    public static getLabels = async (params: any, word: string) => {
        let token = IdentityManager.AccessToken;
        let total: number = 0, _result: any = {};
        let body = {
            word: word,
            app: params.app,
            status: params.status,
            carrier: params.carrier,
            service: params.service,
            store: params.storeNumber,
            to: params.date.To,
            from: params.date.From,
            psize: params.pagination.psize,
            pfrom: params.pagination.pfrom
        }
        try {
            let _res = await fetch(
                `/pf/getLabels`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            );
            let result = await _res.json();
            if (_res.status !== 500 && _res.status !== 400) {
                total = result.hits.total.value
                _result['data'] = result.hits.hits.map((r: any) => ({ ...r._source, isSelected: false, isVoided: false }));
                _result['aggregations'] = result.aggregations;
                PFManager.labelData = _result;
            } else {
                Object.assign(_result, result);
            }
            _result['status'] = _res.status;
        } catch (error) {
            console.log(error);
        }
        return [total, _result];
    }

    public static getReturnLabels = async (store: any, date_To: any, date_From: any) => {
        let token = IdentityManager.AccessToken;
        let _result: any = {};
        try {
            let _res = await fetch(
                `/pf/getReturnLabels?store=${store}&to=${date_To}&from=${date_From}`,
                { headers: { 'Authorization': `Bearer ${token}` } }
            );
            let result = await _res.json();
            if (_res.status !== 500 && _res.status !== 400) {
                _result['data'] = result.hits.hits.map((r: any) => ({ ...r._source, isSelected: false, isVoided: false, isProcessing: false }));
                PFManager.labelData = _result;
            } else {
                Object.assign(_result, result);
            }
            _result['status'] = _res.status;
        } catch (error) {
            console.log(error);
        }
        return _result;
    }

    public static createShipment = async (shipper: string, data: any) => {
        let token = IdentityManager.AccessToken,
            empId = IdentityManager.getUserProperty(Constants.EmployeeID);

        let body: any = PFUtils.getBody_Create_Shipment(empId, shipper, data);
        if (window.location.search.includes('r=1')) {
            body.PAYLOAD.LOGTYPE = 'ReturnLabel';
        }

        let _result: any = null;
        try {
            let _res = await ApiService.post(ApiURLS.PF.createShipment.URL, body)
            _result = _res;
        } catch (error) {
            console.log(error);
        }
        return _result;
    }

    public static rePrint = async (SUID: string, shipper: string) => {
        let token = IdentityManager.AccessToken;
        let empId = IdentityManager.getUserProperty(Constants.EmployeeID);
        let body = PFUtils.getBody_RePrint(empId, SUID, shipper);
        let _result: any = {};
        try {
            let _res = await fetch(
                `/pf/sendRequest`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            );
            if (_res.status !== 500 && _res.status !== 400) {
                _result = await _res.json();
            }
        } catch (error) {
            console.log(error);
        }
        return _result;
    }

    public static voidShipment = async (SUID: string, shipper: string) => {
        let token = IdentityManager.AccessToken;
        let empId = IdentityManager.getUserProperty(Constants.EmployeeID);
        let body = PFUtils.getBody_Void_Shipment(empId, SUID, shipper);
        let _result: any = { response: { msg: '' } };
        try {
            let _res = await fetch(
                `/pf/sendRequest`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            );
            if (_res.status !== 500 && _res.status !== 400) {
                _result = await _res.json();
            }
        } catch (error) {
            console.log(error);
        }
        return _result;
    }

    public static getEODData = async (store: any, date_To: any, carrier: any) => {
        let token = IdentityManager.AccessToken;
        let _result: any = {};
        try {
            let _res = await fetch(
                `/pf/getEODData?store=${store}&to=${date_To}&carrier=${carrier}`,
                { headers: { 'Authorization': `Bearer ${token}` } }
            );
            let result = await _res.json();
            if (_res.status !== 500 && _res.status !== 400) {
                _result['data'] = result.hits.hits.map((r: any) => ({ ...r._source, isSelected: false, isVoided: false }));
                PFManager.labelData = _result;
            } else {
                Object.assign(_result, result);
            }
            _result['status'] = _res.status;
        } catch (error) {
            console.log(error);
        }
        return _result;
    }

    public static generateEmail = async (data: any, email: string, pdfData: any, trackingNo: string, storeCopy: boolean) => {
        let token = IdentityManager.AccessToken;
        let body = PFUtils.getBody_Email_Data(data, email, pdfData, trackingNo, storeCopy);
        let _result: any = { response: { msg: '' } };
        try {
            let _res = await fetch(
                `/pf/generateEmail`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            );
            if (_res.status !== 500 && _res.status !== 400) {
                _result = await _res.json();
            }
        } catch (error) {
            console.log(error);
        }
        return _result;
    }
    public static setGroupsRights = async () => {
        if (PFManager.groupsRights.length == 0) {
            try {
                let result: any = await ApiService.nomGet(ApiURLS.NOM.getGroupsRights.URL);
                if (result.success) {
                    if (result.data.response.statusCode == "200") {
                        PFManager.groupsRights = result.data.response.data;
                    }
                    else {
                        console.log("setGroupsRights" + result.data.response.statusCode);
                    }
                }
                else {
                    console.log("Error: setGroupsRights" + result.msg);
                }

            }
            catch (error) {
                console.log(error);
            }
        }

    }
    public static setNavigationData = async () => {
        if (PFManager.navigationData.length == 0) {
            try {
                let result: any = await ApiService.nomGet(ApiURLS.NOM.getNavigation.URL);
                if (result.success) {
                    if (result.data.response.statusCode == "200") {
                        PFManager.navigationData = result.data.response.data;
                    }
                    else {
                        console.log("setNavigationData" + result.data.response.statusCode);
                    }
                }
                else {
                    console.log("Error: setNavigationData" + result.msg);
                }

            }
            catch (error) {
                console.log(error);
            }
        }
    }
    public static getConfigurations = async () => {
        try {
            let result: any = await ApiService.nomGet(ApiURLS.NOM.getConfiguration.URL);
            if (result.success) {
                if (result.data.response.statusCode == "200") {
                    localStorage.setItem(Constants.Config, JSON.stringify(result.data.response.data))
                }
                else {
                    console.log("getConfiguration" + result.data.response.statusCode);
                }
            }
            else {
                console.log("Error: getConfiguration" + result.msg);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    public static getDataDogConfigurations = async () => {
        let config = {}
        try {
            let result: any = await ApiService.nomGet(ApiURLS.NOM.getDataDogConfig.URL);
            if (result.success) {
                if (result.data) {
                    config = result.data
                }
                else {
                    console.log("getDataDogConfigurations" + result.data.response.statusCode);
                }
            }
            else {
                console.log("Error: getDataDogConfigurations" + result.msg);
            }
        }
        catch (error) {
            console.log(error);
        }
        return config;
    }

    public static getShipmentDataFromShippingCenter = async (shipmentKey: any) => {
        try {
            let result: any = await ApiService.get("pf/getShipmentDataFromShippingCenter?shipmentKey=" + shipmentKey);
            return result
        }
        catch (error) {
            console.log(error);
        }
    }

    public static getCarrierFromShippingCenter = async (trackingNo: any) => {
        try {
            let result: any = await ApiService.get("pf/getCarrierFromShippingCenter?trackingNo=" + trackingNo);
            return result
        }
        catch (error) {
            console.log(error);
        }
    }

    public static checkSkuOlderOrder = async (store: string, sku: any) => {
        try {
            let result: any = await ApiService.post("pf/checkSkuOlderOrder", { store, sku });
            return result
        }
        catch (error) {
            console.log(error);
        }
    }

    public static omsDeltaSync = async (payload: any) => {
        try {
            let result: any = await ApiService.post("pf/omsDeltaSync", payload);
            return result
        }
        catch (error) {
            throw error;
        }
    }

    public static checkOrderDetailStatus = async (payload: any) => {
        try {
            let result: any = await ApiService.post("pf/checkOrderDetailStatus", payload);
            return result
        }
        catch (error) {
            throw error;
        }
    }
    public static getCustomerContactedHistory = async (shipmentKey: any) => {
        try {
            let result: any = await ApiService.get(`pf/getCustomerContactedHistory?shipmentKey=${shipmentKey}`);
            return result
        }
        catch (error) {
            console.log(error);
        }
    }
    public static getDashboardGridData = async (payload: any) => {
        try {
            let result: any = await ApiService.post("pf/getDashboardGridData", payload);
            return result
        }
        catch (error) {
            console.log(error);
        }
    }

    public static addFeedbackBulk = async (payload: any) => {
        return await ApiService.post(`/pf/addFeedbackBulk`, payload);
    }

    public static getFeedBackAgg = async (payload: any) => {
        try {
            let result: any = await ApiService.post("pf/getFeedBackAgg", payload);
            return result
        }
        catch (error) {
            console.log(error);
        }
    }

    public static getFeedBackData = async (payload: any) => {
        try {
            let result: any = await ApiService.post("pf/getFeedBackData", payload);
            return result
        }
        catch (error) {
            console.log(error);
        }
    }

    public static systemWideError = async (payload:any) => {
        try {
            let result: any = await ApiService.post("pf/getSystemWideError", payload);
            return result
        }
        catch (error) {
            console.log(error);
        }
    }
}