import { SHOW_SUCCESSMESSAGE, HIDE_SUCCESSMESSAGE, ISEXTERNAL } from "../ActionTypes";
const showSuccessMessage = (obj: any) => {
    return {
        type: SHOW_SUCCESSMESSAGE,
        payLoad: obj
    };
}
const hideSuccessMessage = () => {
    return {
        type: HIDE_SUCCESSMESSAGE
    };
}
const isExternal = () => {
    return {
        type: ISEXTERNAL
    };
}
const genericNotificationActions = {
    showSuccessMessage,
    hideSuccessMessage,
    isExternal
}

export default genericNotificationActions;
