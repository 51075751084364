import { TEST_ACTION } from "../ActionTypes";

const initialState = {
    currentTime: new Date().toISOString(),
    counter: 0
}
const TestReducer = (state: any = initialState,
    action: { type: any; payLoad: any }) => {
    let clone = JSON.parse(JSON.stringify(state));
    let counter = clone.counter;
    switch (action.type) {
        case TEST_ACTION:
            return {
                ...state,
                counter: counter + 1
            }
    }

    return state;
}

export default TestReducer;