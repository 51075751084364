import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Sector, Cell, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import Utils from '../../Common/Utils';
import { Typography } from "@material-ui/core";
import IdentityManager from '../../Managers/Identity.manager';
import DataNotFound from '../Shared/DataNotFound';



const CustomerResponseTrend = (props: any) => {
    let graphData: any[] = [];
    let isResponseAllZero = false
    const { isLoaded, data } = props;
    const [storeCancelOrderResponse, setStoreCancelOrderResponse] = useState(0)
    const [storeContactMeResponse, setStoreContactMeResponse] = useState(0)
    const [storeCustomerResponded, setStoreCustomerResponded] = useState(0)
    const [storeSendMeResponse, setStoreSendMeResponse] = useState(0)
    const [storeWantNewResponse, setStoreWantNewResponse] = useState(0)

    useEffect(() => {
        if (data.districtCCData) {
            let cancelOrderResponse = 0
            let contactMeResponse = 0
            let customerResponded = 0
            let sendMeResponse = 0
            let wantNewResponse = 0
            for (let i = 0; i < data.districtCCData.length; i++) {
                cancelOrderResponse = cancelOrderResponse + data.districtCCData[i].cancelOrderResponse;
                contactMeResponse = contactMeResponse + data.districtCCData[i].contactMeResponse;
                customerResponded = customerResponded + data.districtCCData[i].customerResponded;
                sendMeResponse = sendMeResponse + data.districtCCData[i].sendMeResponse;
                wantNewResponse = wantNewResponse + data.districtCCData[i].wantNewResponse;
            }
            setStoreCancelOrderResponse(cancelOrderResponse)
            setStoreContactMeResponse(contactMeResponse)
            setStoreCustomerResponded(customerResponded)
            setStoreSendMeResponse(sendMeResponse)
            setStoreWantNewResponse(wantNewResponse)
        }
    }, [data.districtCCData])

    if (isLoaded && data) {
        const distObj = data.districtCCData ? data?.districtCCData[0] : null;

        if (distObj) {

            graphData = [
                {
                    name: "I want it new, in box (and I'm willing to wait)",
                    value: storeWantNewResponse
                },
                {
                    name: "Yes, please send me what you've got",
                    value: storeSendMeResponse
                },
                {
                    name: "Cancel Order",
                    value: storeCancelOrderResponse
                },
                {
                    name: "Contact me to discuss",
                    value: storeContactMeResponse
                },
            ];

            isResponseAllZero = graphData.every((val) => val === 0)

            console.log(isResponseAllZero, "isResponseAllZero")

        }
    }



    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const RADIAN = Math.PI / 180;

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        if (percent !== 0) {
            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            );
        }
    };

    const renderColorfulLegendText = (value: string, entry: any) => {
        return (

            <span style={{ color: "#596579", fontWeight: 500, fontSize: "12px" }}>
                {value} {"-"} <span style={{ fontWeight: 600 }}>{entry.payload.value}</span>
            </span>

        );
    };
    return (

        <>
            {(graphData.length && !isResponseAllZero) ? <ResponsiveContainer height={300}>
                <PieChart >
                    <Legend
                        align='center'
                        iconType="circle"
                        layout="vertical"
                        verticalAlign="bottom"
                        iconSize={10}
                        formatter={renderColorfulLegendText}

                    />
                    <Tooltip />
                    <Pie
                        data={graphData}
                        cx={"50%"}
                        cy={"80%"}
                        startAngle={180}
                        endAngle={0}
                        outerRadius={150}
                        labelLine={false}
                        fill="#8884d8"

                        label={renderCustomizedLabel}
                        dataKey="value"
                    >
                        {graphData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={Utils.getColor(index)} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer> : <DataNotFound></DataNotFound>}
        </>

    )
}

export default CustomerResponseTrend