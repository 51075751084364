import { Box, Card, CardContent, Divider, Grid, Tooltip, Typography } from "@mui/material"
import React, { useEffect } from "react";
import { useState } from "react";
import Constants from "../../Common/Constants";
import Utils from "../../Common/Utils";
import { AssignmentReturnIcon, AssignmentTurnedInIcon, InfoOutlinedIcon, LocalShippingIcon, ShoppingCartIcon, ZoomOutMapIcon } from "../Shared/SharedIcons"
import CustomChip from "./CustomChip";
import { ShippingDataGrid } from "./ShippingDataGrid";
import IdentityManager from "../../Managers/Identity.manager";


export const ShippingTile = (props: any) => {
    const { shippingData, isShippingDataLoaded, shippingCharges, isShippingChargesLoaded } = props;
    const [gridPopUp, setGridPopUp] = useState(false);
    const distList = shippingData.districtShippingData; 

    const [sumOfShipped, setsumOfShipped] = useState(0)
    const [sumOfReturn, setsumOfReturn] = useState(0)
    const [shipToStore, setShipToStore] = useState(0)
    const [shipToCustomer, setShipToCustomer] = useState(0)
    const [shipToStorePerc, setShipToStorePerc] = useState(0)
    const [shipToCustomerPerc, setShipToCustomerPerc] = useState(0)
    const [shipToStoreCount, setShipToStoreCount] = useState(0)
    const [shipToCustomerCount, setShipToCustomerCount] = useState(0)

    useEffect(() => {
        if (distList) {
            let shippedCount = 0
            let returnCount = 0
            let shipToStore = 0
            let shipToCustomer = 0
            let shipToStoreCount = 0
            let shipToCustomerCount = 0
            for (let i = 0; i < distList.length; i++) {
                shippedCount = shippedCount + (distList[i].shipToStore + distList[i].shipToCustomer) //(distList[i].shippingCost);
                returnCount = returnCount + (distList[i].returnCost);
                shipToStore = shipToStore + (distList[i].shipToStore);
                shipToCustomer = shipToCustomer + (distList[i].shipToCustomer);
                shipToStoreCount = shipToStoreCount + (distList[i].shipToStoreCount);
                shipToCustomerCount = shipToCustomerCount + (distList[i].shipToCustomerCount);

            }
            setShipToStoreCount(shipToStoreCount)
            setShipToCustomerCount(shipToCustomerCount)
            setShipToStore(shipToStore)
            setShipToCustomer(shipToCustomer)
            setsumOfShipped(shippedCount)
            setsumOfReturn(returnCount)

            // Set Ship to store and Customer Percentage
            let sumOfStoreAndCustomerValues = shipToStore + shipToCustomer;
            const shippedToStorePerc = parseFloat(((shipToStore / sumOfStoreAndCustomerValues) * 100).toFixed(2));
            const shippedToCustomerPerc = parseFloat(((shipToCustomer / sumOfStoreAndCustomerValues) * 100).toFixed(2));
            setShipToStorePerc(shippedToStorePerc)
            setShipToCustomerPerc(shippedToCustomerPerc)
        }
    }, [distList])

    const closeGridPopup = () => {
        setGridPopUp(false);
    }

    return (
        <React.Fragment>
            {/* <Card className='dCard' >
                <CardContent onClick={() => { setGridPopUp(true) }} className='dCardContent' style={{ padding: 0 }} >
                    <div className='dCardHeader' style={{ background: Constants.Colors.red }} >
                        <div>
                            <Typography style={{ fontWeight: "500", color: "white" }}>
                                Shippings
                            </Typography>
                        </div>
                        <div onClick={() => { setGridPopUp(true) }} style={{ cursor: "pointer" }}>
                            <ZoomOutMapIcon style={{ float: "right", padding: "5px", width: "20px", color: Constants.Colors.white }} />
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-around", padding: "10px" }}>
                        <div>
                            <Typography style={{ fontWeight: "500", color: "" }}>
                                Shipping Cost
                            </Typography>
                            <CustomChip size={1.2} icon={<AssignmentTurnedInIcon style={{ color: Constants.Colors.blue }} />} label={`${Utils.getKFormat(Math.round(sumOfShipped))}`} variant="outlined" color="success"></CustomChip>
                        </div>
                        <div>
                            <Typography style={{ fontWeight: "500", color: "" }}>
                                Returns Costs
                            </Typography>
                            <CustomChip size={1.2} icon={<AssignmentReturnIcon style={{ color: Constants.Colors.grey }} />} label={`${Utils.getKFormat(Math.round(sumOfReturn))}`} variant="outlined" color="success"></CustomChip>
                        </div>
                    </div>
                </CardContent>
            </Card> */}
            <Card className='dCard' >
                <CardContent className='dCardContent' style={{ padding: 0, minHeight: !Utils.isMobile ? 200: 0 }}  >
                    <div className='dCardLeftHeader'>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <LocalShippingIcon style={{ color: Constants.Colors.black }} />
                        </div>
                        <Typography style={{ fontWeight: "bold", color: "#5f6661", fontSize: 17, marginTop: "5px", marginLeft: "5px" }}>
                            Shipping Cost
                        </Typography>
                        <div onClick={() => { setGridPopUp(true) }} style={{ cursor: "pointer", marginLeft: "auto" }}>
                            <ZoomOutMapIcon style={{ float: "right", margin: 3, width: "20px", color: Constants.Colors.grey }} />
                        </div>
                    </div>

                    <Divider />

                    <Grid container>
                        <Grid item xs={6} sm={6} >
                            <Grid container style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                <Grid item sm={4} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                    <div className="tiles-icons" style={{ backgroundColor: Constants.Colors.blue }}  >
                                        <AssignmentTurnedInIcon />
                                    </div>
                                </Grid>
                                <Grid item sm={8} style={{ display: "grid", justifyItems: "start" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>

                                        <p className="tiles-header-font" >
                                            Shipping
                                        </p>
                                        <Tooltip title={<h3 style={{ whiteSpace: 'pre-line' }}>{Utils.getToolTipValue("Shipping") ? Utils.getToolTipValue("Shipping") : ''}</h3>}>
                                            <InfoOutlinedIcon style={{ color: Constants.Colors.blue, marginLeft: "5px" }} />
                                        </Tooltip>
                                    </div>



                                    {/* <p className="tiles-header-font">Shipping</p> */}
                                    <p className="tiles-value-font">
                                        {"$"}{`${Utils.nFormatter(sumOfShipped, 2)}`}
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Grid container style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                <Grid item sm={3} style={{ display: "grid", alignItems: "center", justifyItems: "end" }}>
                                    <div className="tiles-icons" style={{ backgroundColor: Constants.Colors.grey }} >
                                        <AssignmentReturnIcon />
                                    </div>
                                </Grid>
                                <Grid item sm={9} style={{ display: "grid", justifyItems: "start" }}>

                                    <div style={{ display: "flex", alignItems: "center" }}>

                                        <p className="tiles-header-font" >
                                            Return Shipment
                                        </p>
                                        <Tooltip title={<h3 style={{ whiteSpace: 'pre-line' }}>{Utils.getToolTipValue("ReturnShipment") ? Utils.getToolTipValue("ReturnShipment") : ''}</h3>}>
                                            <InfoOutlinedIcon style={{ color: Constants.Colors.blue, marginLeft: "5px" }} />
                                        </Tooltip>
                                    </div>

                                    {/* <p className="tiles-header-font">Return Shipment</p> */}
                                    <p className="tiles-value-font">
                                        {"$"}{`${Utils.nFormatter(sumOfReturn, 2)}`}
                                    </p>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid container style={{ backgroundColor: "#eeeeee", minHeight: !Utils.isMobile?"116px":"0px", display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: Utils.isMobile?10:0 }}>
                        {IdentityManager.showShippingCharges && <Grid container style={{ marginTop: "10px", display: "flex", justifyContent: "space-around" }}>
                            <Grid item sm={6}>
                                {/* <Box> */}
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    <Typography style={{ color: "black", fontSize: "12px" }}> Shipping charges </Typography>
                                </div>
                                {/* </Box> */}
                            </Grid>
                            <Grid item sm={6} style={{ textAlign: "right" }}>
                                {/* <Box> */}
                                {isShippingChargesLoaded && <Typography style={{ color: "black", fontWeight: "bold", marginRight: "25px", fontSize: "12px" }}>{"$"}{`${Utils.nFormatter(shippingCharges, 2)}`} </Typography>}
                                {/* </Box> */}
                            </Grid>
                        </Grid>}
                        <Grid container style={{ display: "flex", justifyContent: "space-around", marginTop:Utils.isMobile ? 5 : 0 }}>
                            <Grid item sm={6}>
                                {/* <Box> */}
                                <div style={{ float: "left", marginLeft: "25px" }}>
                                    <Typography style={{ color: "black", fontSize: "12px" }}> Store to Store  -<b> {shipToStoreCount}</b></Typography>
                                </div>
                                {/* </Box> */}
                            </Grid>
                            <Grid item sm={6} style={{ textAlign: "right" }}>
                                {/* <Box> */}
                                <Typography style={{ color: "black", fontWeight: "bold", marginRight: "25px", fontSize: "12px" }}>{"$"}{`${Utils.nFormatter(shipToStore, 2)}`}{`(${shipToStorePerc ? shipToStorePerc : 0}%)`} </Typography>
                                {/* </Box> */}
                            </Grid>
                        </Grid>
                        <Grid container style={{ display: "flex", justifyContent: "space-around", marginTop:Utils.isMobile ? 5 : 0  }}>
                            <Grid item sm={6}>
                                {/* <Box> */}
                                <div style={{ float: "left", marginLeft: "25px" }}>

                                    <Typography style={{ color: "black", fontSize: "12px" }}>Store to Customer  -<b> {shipToCustomerCount}</b></Typography>
                                </div>
                                {/* </Box> */}
                            </Grid>
                            <Grid item sm={6} style={{ textAlign: "right" }}>
                                <Box>
                                    <Typography style={{ color: "black", fontWeight: "bold", wordBreak: "break-all", marginRight: "25px", fontSize: "12px" }}>{"$"}{`${Utils.nFormatter(shipToCustomer, 2)}`}{`(${shipToCustomerPerc ? shipToCustomerPerc : 0}%)`}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {isShippingDataLoaded && distList && <ShippingDataGrid open={gridPopUp} close={closeGridPopup} data={distList} isLoaded={isShippingDataLoaded} />}
        </React.Fragment>);
}