import Constants from "../Common/Constants";
import IdentityManager from "./Identity.manager";
//import moment from "moment";
import Utils from "../Common/Utils";
import ApiService from "../Common/ApiService";
import { ITecAudits } from "../Models/ITecAudit"
var moment = require('moment');
export default class AuditLogsManager {

    public static AuditLogs: any = [];
    public static Auditcount: number = 0;


    public static async getTechAuditsTags() {

        let data = [{ key: "All", value: "All" }];
        let result = await ApiService.get("pf/getTechAuditsTags");
        if (result.success) {
            if (result.data?.aggregations?.langs?.buckets && result.data?.aggregations?.langs?.buckets.length > 0) {
                let buckets = result.data?.aggregations?.langs?.buckets;
                for (let i = 0; i < buckets.length; i++) {
                    let key = buckets[i].key;
                    let value = key.replace("GC", "");
                    value = value.replace(/([A-Z])/g, ' $1').trim();
                    data.push(
                        {
                            key: key,
                            value: value
                        }
                    );
                }
            }
        }

        return data;

    }
    public static getTecAudits = async (tecAuditCall: ITecAudits) => {
        try {
            return await ApiService.post(`/pf/getLogElastic`, tecAuditCall);
        }
        catch (error) {
            console.log(error);
        }
    }
}