import { CHANGE_SEARCHRESULT } from "../ActionTypes";
const initialState = {
    searchResult: []
}
const SearchReducer = (state: any = initialState,
    action: { type: any; payLoad: any }) => {
    switch (action.type) {
        case CHANGE_SEARCHRESULT:
            return {
                ...state,
                searchResult: action.payLoad
            }
    }
    return state;
}
export default SearchReducer;