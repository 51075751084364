import { Box, Card, CardContent, Dialog, DialogContent, DialogTitle, Divider, IconButton, Link, Typography } from "@mui/material";
import { Slide } from '@material-ui/core';
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import DataTable from "react-data-table-component";
import { CloseIcon } from "../Shared/SharedIcons";
import Utils from "../../Common/Utils";
import DataNotFound from "../Shared/DataNotFound";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const OrderFulFilmentDetailsGrid = (props: any) => {
    // debugger
    const close = () => {
        props.close()
    }

    const getColumns = () => {
        const columns = [
            {
                id: "orderno",
                name: 'Order No',
                sortable: true,
                selector: (row: any) => row.orderNo,
                width: "250px"
            },
            {
                id: "shipmentkey",
                name: 'Shipment Key',
                sortable: true,
                selector: (row: any) => (row.shipmentKey),
                width: "300px"
            },
            {
                id: "linetotal",
                name: 'Total',
                sortable: true,
                selector: (row: any) => "$" + Utils.nFormatter((row.lineTotal), 2),
                width: "250px"
            },
            // {
            //     id: "shiptocustomer",
            //     name: 'Ship To Customer',
            //     sortable: true,
            //     selector: (row: any) => row.shipToCustomer,
            //     width: "120px"
            // },
            // {
            //     id: "instorepickup",
            //     name: 'In Store Pickup',
            //     sortable: true,
            //     selector: (row: any) => row.inStorePickup,
            //     width: "120px"
            // },
            // {
            //     id: "instorepickup",
            //     name: 'Ship To Store',
            //     sortable: true,
            //     selector: (row: any) => row.isShipToStore,
            //     width: "120px"
            // },
            // {
            //     id: "usedItem",
            //     name: 'Used Item',
            //     sortable: true,
            //     selector: (row: any) => row.usedItem,
            //     width: "120px"
            // },
            {
                id: "descriptions",
                name: 'Descriptions',
                selector: (row: any) => row.descriptions,
            },

        ];
        return columns;
    }

    return (
        <Dialog disableEnforceFocus open={props.open} fullWidth maxWidth={"xl"} TransitionComponent={Transition}>
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { close() }}
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography className="report-card-heading" style={{ display: "flex", justifyContent: "center"}} variant="h5">Store {props.selectedStore ? props.selectedStore : ""} - {props.orderType}</Typography>
                {<div className='dataTableDashboardExpanded'>
                    <DataTable
                        className='dataTableDashboardExpanded'
                        title=""
                        data={props.data?.data}
                        columns={getColumns()}
                        noDataComponent={<DataNotFound></DataNotFound>}
                        dense
                    />
                </div>

                }
            </DialogContent>
        </Dialog>
    );
}