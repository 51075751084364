import React, { useState } from 'react';
import { ErrorIcon } from "../Subcomponent/Shared/SharedIcons"
const NotAuthorized = () => {

    return (
        <div className='UserError'>
            <div className='title'><ErrorIcon style={{ fontSize: '60px' }} />Error</div>
            <div className='subtitle'>Your profile is not configured to access this resource</div>
            <div className='subtitle'>Please <a>click here</a> to Logout and Login again OR if issue persist contact the IT Help Desk (818) 735-8800 ext. 2660 or email <a href={"mailto:gwitickets@guitarcenter.com"}>gwitickets@guitarcenter.com</a></div>
        </div>
    );

}

export default NotAuthorized;
