import { styled } from '@mui/material/styles';
import React from "react";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Constants from "../../Common/Constants";
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from "@mui/material";
import { Box, Divider, Grid, Paper, Stack, Tooltip, Typography, Card, CardContent, Button } from "@mui/material";
import Chip from "@mui/material/Chip";
import { PrintIcon, CloseIcon, ArrowForwardIosSharpIcon, DoDisturbOnIcon, TimelineIcon, ExpandMoreIcon } from "../Shared/SharedIcons"
import { Container, ContainerDetail, OmsPackOrderDetail } from "../../Models/OmsPackOrderDetails";
import OMSManager from "../../Managers/Oms.manager";
import { NotificationPopup } from "../../Subcomponent/Shared/NotificationPopup";
import { INotificationPopupSettings, IButton } from "../../Models/INotificationPopupSettings";
import allActions from '../../Store/Actions/AllActions';
import StoreManager from '../../Managers/Store.manager';
import ApiService from "../../Common/ApiService";
import PrintManager from '../../Managers/Print.Manager';
import Utils from "../../Common/Utils";
import logger from '../../Common/logger';
import { IImageViewerDetail, IImageViewerPopupSettings } from '../../Models/IImageViewerPopupSettings';
import { ImageViewerPopup } from '../Shared/ImageViewerPopup';
import ExtendedDescription from '../PickOrder/ExtendedDescription';
const Accordion = styled((props: any) => (
    <MuiAccordion style={{ minHeight: "35px !important" }} disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: Constants.Colors.white,
    color: '#2ca131b5',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        order: 1,
        marginLeft: theme.spacing(1),
    },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const PackagedItems: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    const [expandedPacked, setExpandedPacked] = React.useState(true);
    const [openWarning, setOpenWarning] = React.useState(false);
    const [printClicked, setPrintClicked] = React.useState(false);
    const [ShipmentKey, setShipmentKey] = React.useState("");
    const [ShipmentContainerKey, setShipmentContainerKey] = React.useState("");
    const [isvoidAll, setIsVoidAll] = React.useState(false);
    const [warningMsg, setWarningMsg] = React.useState("");
    const [errorWhileVoidOms, setErrorWhileVoidOms] = React.useState(false);
    const [errorVoidingLabel, setErrorVoidingLabel] = React.useState(false);
    const [imagePopUp, setImagePopUp] = React.useState(false);
    const [imageData, setImageData] = React.useState({} as IImageViewerDetail);
    let packagedItems: Container[] = useSelector((state: any) => state.PackOrderReducersState.packagedItems);
    let elasticLabelData = useSelector((state: any) => state.PackOrderReducersState.shipmentDataFromShippingCenter);
    let unpackagedItemsList: OmsPackOrderDetail = useSelector((state: any) => state.PackOrderReducersState.unpackagedItems);
    const printAll = (e: any) => {
        e.stopPropagation()
        let shipmentContainerKeys = [] as string[];
        packagedItems.forEach((container: Container) => {
            shipmentContainerKeys.push(container.ShipmentContainerKey)
        });
        let trackingNumbers = [] as string[]
        shipmentContainerKeys.forEach((shipmentContainerKey) => {
            let containerTrackingNumbers = packagedItems.filter((container: Container) => { // getting container details
                return container.ShipmentContainerKey == shipmentContainerKey
            })
            // add tracking number to list to void
            containerTrackingNumbers.forEach((container: Container) => {
                trackingNumbers.push(container.TrackingNo)
            });
        });
        printByTrackingNumber(trackingNumbers)
    };
    const voidAll = (e: any) => {
        e.stopPropagation()
        setIsVoidAll(true);
        setWarningMsg("Are you sure you want to void All Labels ?");
        setOpenWarning(true);
    };

    const handleChangePacked = () => {
        setExpandedPacked(!expandedPacked);
    };
    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }

    const printByTrackingNumber = async (trackingNumbers: string[]) => {
        //
        if (StoreManager.currentStore.id != undefined) {
            let selectedStore = StoreManager.currentStore.id
            let hitsData = elasticLabelData?.hits?.hits;
            if (!hitsData) {
                return;
            }
            let SUIDToPrintPayload = [] as any
            let elasticLabelDatafilterByStore = hitsData?.filter((hit: any) => {
                return (hit._source.Store == StoreManager.getStorePreFixByStoreId(selectedStore) + selectedStore)
            })
            let trackingNumbersFound = [] as any
            elasticLabelDatafilterByStore.forEach((hit: any) => {
                if (trackingNumbers.indexOf(hit._source.ServiceResponse.CARRIER_PACKAGE_REFERENCE) > -1) {
                    let payload = {
                        "ACTION": "RE_PRINT",
                        "PAYLOAD": {
                            "APP_ID": "Shipping Center",
                            "EMPLOYEE_ID": hit._source.EmployeeID,
                            "SHIPPER": hit._source.Store,
                            "SUID": hit._source.ServiceResponse.SUID
                        }
                    }
                    SUIDToPrintPayload.push(payload)
                    trackingNumbersFound.push(trackingNumbers[trackingNumbers.indexOf(hit._source.ServiceResponse.CARRIER_PACKAGE_REFERENCE)])
                }
            })
            setPrintClicked(true);
            showActionLoader();
            let zplData: any[] = [];
            if (SUIDToPrintPayload.length > 0) {
                let response: any = null;
                // get response of all boxes created
                if (Utils.shippingAPI()) {
                    response = await ApiService.post(Constants.PFServicesRoutes.RePrintLabel, SUIDToPrintPayload)
                }
                else {
                    response = await ApiService.post("/pf/sendRequest", SUIDToPrintPayload);
                }
                if (response?.success == true) {
                    for (let i = 0; i < response.data.length; i++) {
                        let result = response.data[i].response;
                        let payLoad = response.data[i].payLoad;
                        if (result.success == true) {
                            if (result.data?.hits?.hits[0]?._source?.ServiceResponse?.BASE_64_PDF != undefined || result.data?.hits?.hits[0]?._source?.ServiceResponse?.BASE_64_PDF != "") {
                                result.printData.forEach((r: any) => {
                                    PrintManager.sendPrinterRequest(r)
                                    zplData.push(r)
                                });
                            }
                        }
                    }
                }

                Utils.writeSendRequestLogs(response, Constants.LOG_TYPES.REPRINT);
            }


            let adhoqTrackingNumbers = [] as any
            if (trackingNumbersFound.length != trackingNumbers.length) {
                trackingNumbers.forEach(element => {
                    if (trackingNumbersFound.indexOf(element) == -1) {
                        adhoqTrackingNumbers.push(element)
                    }
                });
                let response = await ApiService.post("/pf/printAdhocTrackingData", adhoqTrackingNumbers)
                if (response.success == true) {
                    if (response.data && response.data.length > 0) {
                        for (let i = 0; i < response.data.length; i++) {
                            let result = response.data[i].response;
                            let payLoad = response.data[i].payLoad;
                            if (result.success == true) {
                                if (result.data?.hits?.hits[0]?._source?.ServiceResponse?.BASE_64_PDF != undefined || result.data?.hits?.hits[0]?._source?.ServiceResponse?.BASE_64_PDF != "") {
                                    result.printData.forEach((r: any) => {
                                        PrintManager.sendPrinterRequest(r)
                                        zplData.push(r)
                                    });
                                }
                            }
                        }
                    }
                    else {
                        props.showErrorMessageFromChild("Error While Printing Ad-hoc Label");
                    }

                }

                Utils.writeSendRequestLogs(response, Constants.LOG_TYPES.REPRINT);


            }
            await PrintManager.handlePrintDataDisplay(zplData);
            setPrintClicked(false)
            hideActionLoader()
        }
        else {
            hideActionLoader()
            props.showErrorMessageFromChild("Select Store");
        }
    }
    const getItemDetail = (shipmentKey: any) => {

        let shipment = unpackagedItemsList?.ShipmentLines?.ShipmentLine?.find(x => x.ShipmentLineKey == shipmentKey);
        return shipment;
    };

    const renderExtendedDescription = (shipmentKey: any) => {
        let extnDesc = "";
        let shipment = unpackagedItemsList.ShipmentLines.ShipmentLine.find(x => x.ShipmentLineKey == shipmentKey);
        if (shipment?.OrderLine?.ItemDetails?.Extn?.ExtnIsUsedOrVintageItem == "Y") {
            let description = shipment?.OrderLine?.ItemDetails?.PrimaryInformation?.ExtendedDescription;
            if (description?.toLowerCase()?.includes(Constants.MESSAGES.PRODUCT_CASE.toLowerCase())) {
                extnDesc = Constants.MESSAGES.PRODUCT_CASE;
            }
            else if (description?.toLowerCase()?.includes(Constants.MESSAGES.PRODUCT_BAG.toLowerCase())) {
                extnDesc = Constants.MESSAGES.PRODUCT_BAG;
            }
        }
        return (extnDesc != ""
            ? <Typography style={{ fontSize: Utils.isMobile ? "13px" : "15px", color: "#1976D2" }}><b>{extnDesc}</b></Typography>
            : <></>
        );
    }

    const voidShippingCenter = async (shipmentContainerKey: any) => {
        let selectedStore = StoreManager.currentStore.id
        let hitsData = elasticLabelData.hits.hits
        let elasticLabelDatafilterByStore = hitsData.filter((hit: any) => {
            return (hit._source.Store == "G" + selectedStore)
        })
        let SUIDToVoidPayload = [] as any
        let trackingNumbers = [] as string[]
        let containerDetails = packagedItems.filter((container: Container) => { // getting container details
            return container.ShipmentContainerKey == shipmentContainerKey
        })[0]
        if (containerDetails) {
            if (containerDetails.Extn.ExtnMultiBoxGroupID != "") {
                packagedItems.forEach((container: Container) => {
                    if (container.Extn.ExtnMultiBoxGroupID == containerDetails.Extn.ExtnMultiBoxGroupID) {
                        trackingNumbers.push(container.TrackingNo)
                    }
                })
            }
            else {
                trackingNumbers.push(containerDetails.TrackingNo)
            }
        }
        else {
            setErrorVoidingLabel(true)
        }


        // add SUID if tracking numbers match
        elasticLabelDatafilterByStore.forEach((hit: any) => {
            if (trackingNumbers.indexOf(hit._source.ServiceResponse.CARRIER_PACKAGE_REFERENCE) > -1) {
                let payload = {
                    "ACTION": "VOID_SHIPMENT",
                    "PAYLOAD": {
                        "APP_ID": "Shipping Center",
                        "EMPLOYEE_ID": hit._source.EmployeeID,
                        "SHIPPER": hit._source.Store,
                        "SUID": hit._source.ServiceResponse.SUID
                    }
                }
                SUIDToVoidPayload.push(payload)
            }
        });
        let isErrorSendRequest = false
        let isErrorVoidAdhocTrackingData = false
        if (SUIDToVoidPayload.length == trackingNumbers.length) {
            let response: any = null;
            if (Utils.shippingAPI()) {
                response = await ApiService.post(Constants.PFServicesRoutes.VoidShipment, SUIDToVoidPayload)
            }
            else {
                response = await ApiService.post("/pf/sendRequest", SUIDToVoidPayload)
            }
            if (response?.success == false || response?.data == undefined || response?.data?.length == 0) {
                console.log("setErrorVoidingLabel sendRequest")
                isErrorSendRequest = true
            }

            // logging for voiding
            Utils.writeSendRequestLogs(response, Constants.LOG_TYPES.VOID_LABEL);
        }
        else {
            let response = await ApiService.post("/pf/voidAdhocTrackingData", trackingNumbers)
            if (response.success == false || response.data == undefined || response.data.length == 0) {
                console.log("setErrorVoidingLabel voidAdhocTrackingData")
                isErrorVoidAdhocTrackingData = true
            }
            // logging for voiding Ad-hoc
            Utils.writeSendRequestLogs(response, Constants.LOG_TYPES.VOID_LABEL);
        }
        if (isErrorSendRequest && isErrorVoidAdhocTrackingData) {
            setErrorVoidingLabel(true)
        }
    }



    const getCleanContainerKeys = (shipmentContainerKeys: string[]) => {
        //
        let addedMultiBox = [] as any
        let filteredShipmentContainerKeys = [] as any
        shipmentContainerKeys.forEach((containerKey: string) => {

            let containerDetail = packagedItems.filter((container) => {
                return container.ShipmentContainerKey == containerKey;
            })[0]
            if (containerDetail) {
                if (containerDetail.Extn.ExtnMultiBoxGroupID == "") {
                    filteredShipmentContainerKeys.push(containerKey)
                }
                else {
                    if (addedMultiBox.indexOf(containerDetail.Extn.ExtnMultiBoxGroupID) < 0) {
                        addedMultiBox.push(containerDetail.Extn.ExtnMultiBoxGroupID)
                        filteredShipmentContainerKeys.push(containerKey)
                    }
                }
            }
        })
        return filteredShipmentContainerKeys
    }
    const voidContainerAndLabel = async (shipmentKeys: string[], shipmentContainerKeys: string[]) => {
        // filter for multi box 
        let filterdShipmentContainerKeys = getCleanContainerKeys(shipmentContainerKeys)
        if (StoreManager.currentStore.id != undefined) {
            let calls = filterdShipmentContainerKeys.map((shipmentContainerKey: any, index: any) => {
                return new Promise((resolve, reject) => {
                    console.log("inside shipmentContainerKeys loop")
                    OMSManager.GCPackDeleteTrackingNo(shipmentKeys[index], shipmentContainerKey, packagedItems).then(async (response) => {
                        if (response.success == true) {
                            console.log("inside GCPackDeleteTrackingNo then ")
                            let result = response.data
                            if (result.errors != undefined) {
                                console.log("error true")
                                resolve(false)
                            }
                            else {
                                console.log("calling shipping center api")
                                await voidShippingCenter(shipmentContainerKey)
                                resolve(true)
                            }
                        }
                        else {
                            resolve(false)
                        }
                    }).catch(() => {
                        resolve(false)
                    })
                })
            })

            await Promise.all(calls).then((data) => {
                console.log("inside promise all")
                hideActionLoader()
                let ErrorMessage = ""
                let result = data.every(result => result == true)
                if (result == false) {
                    ErrorMessage = "There was an error while deleting container"
                }
                if (errorVoidingLabel) {
                    if (ErrorMessage == "") {
                        ErrorMessage = "There was an error while voiding Label"
                    }
                    else {
                        ErrorMessage = ErrorMessage + "and there was an error while voiding Label"
                    }
                }
                if (result == false || errorVoidingLabel) {
                    props.showErrorMessageFromChild(ErrorMessage);
                }
                props.openLoadingOrderLines()
                props.getPackDetailFromOms()
            })
        }
        else {
            hideActionLoader()
            props.showErrorMessageFromChild("Select Store");
        }
    }
    const voidContainer = () => {
        closeWarningPopup()
        showActionLoader()
        let shipmentKeys = [] as string[];
        let shipmentContainerKey = [] as string[];
        packagedItems.forEach((container: Container) => {
            shipmentKeys.push(container.ShipmentKey)
            shipmentContainerKey.push(container.ShipmentContainerKey)
        });
        if (isvoidAll) {
            voidContainerAndLabel(shipmentKeys, shipmentContainerKey)
        }
        else {
            voidContainerAndLabel([ShipmentKey], [ShipmentContainerKey]);
        }

    }
    const closeWarningPopup = () => {
        setOpenWarning(false)
        setIsVoidAll(false);
    }

    const openWarningPopup = (shipmentKey: any, shipmentContainerKey: any) => {
        setShipmentKey(shipmentKey)
        setShipmentContainerKey(shipmentContainerKey)
        setWarningMsg("Are you sure you want to void Label ?");
        setOpenWarning(true);
    }
    let warningPopupButtons: IButton[] = [
        {
            text: "Void",
            icon: <DoDisturbOnIcon />,
            action: voidContainer,
            color: Constants.Colors.red,
        },
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeWarningPopup,
            color: Constants.Colors.grey,
        },

    ];
    let VoidWarning: INotificationPopupSettings = {
        open: openWarning,
        type: Constants.NotificationPopupType.Warning,
        title: "Warning",
        msg: warningMsg,
        draggable: false,
        handleClose: closeWarningPopup,
        actions: warningPopupButtons
    }

    const closeImageViewer = () => {
        setImagePopUp(false);
        setImageData({} as IImageViewerDetail);
    }

    let imageViewerSettings: IImageViewerPopupSettings = {
        open: imagePopUp,
        imageDetail: imageData,
        close: closeImageViewer
    }


    const imageViewer = (item: any) => {
        let imageData: IImageViewerDetail = {
            imageURL: item.ImageUrl,
            itemDesc: item.ItemDesc,
            itemID: "-",
            skuID: item.ItemID,
            price: item.UnitPrice,
            qty: item.Quantity
        }

        setImagePopUp(true);
        setImageData(imageData);
    }

    const webView = () => {
        return (
            <div>{
                packagedItems.length > 0 && props.loadingOrderLines == false ?
                    <div style={{ marginBottom: 5 }}>
                        <Accordion expanded={expandedPacked} onChange={() => handleChangePacked()} style={{ margin: 16, padding: 10, backGroundColor: "white", borderRadius: 16 }}>
                            <AccordionSummary aria-controls="panel1d-content">
                                <Grid container>
                                    <Grid item xs={8} sm={8}>
                                        <Typography className='gridHeading' style={{ fontSize: 20, fontWeight: 700 }}>
                                            Packaged Items
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        sm={4}
                                        style={{ display: "flex", justifyContent: "center" }}
                                    >
                                        <Stack direction={"row"}>
                                            <Tooltip title="Print All Label">
                                                <IconButton
                                                    onClick={(e) => printAll(e)}
                                                    style={{
                                                        color: printClicked ? "grey" : "#2ca131b5",
                                                        paddingTop: 0,
                                                        paddingBottom: 0,
                                                    }}
                                                >
                                                    <PrintIcon></PrintIcon>
                                                </IconButton>
                                            </Tooltip>
                                            {!props.shipped &&
                                                <Tooltip title="Void All Label">
                                                    <IconButton
                                                        onClick={(e) => voidAll(e)}
                                                        style={{
                                                            color: "#2ca131b5",
                                                            paddingTop: 0,
                                                            paddingBottom: 0,
                                                        }}
                                                    >
                                                        <DoDisturbOnIcon></DoDisturbOnIcon>
                                                    </IconButton>
                                                </Tooltip>}
                                        </Stack>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        sm={4}
                                        style={{ display: "flex", justifyContent: "left" }}
                                    ></Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails style={{ display: "block" }}>
                                {
                                    packagedItems.map((container: Container, index: any) => {
                                        return (
                                            <div className="orderBoxDiv" key={index}>
                                                <Paper className="packOrderPackageHeaderPackChild">
                                                    <Grid container>
                                                        <Grid item xs={6} sm={6}>
                                                            <Typography variant="h6">
                                                                Package {index + 1}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sm={6} style={{}}>
                                                            <Box display="flex" justifyContent="flex-end" style={{ float: "right" }}>
                                                                <Tooltip title="Print Label">
                                                                    <IconButton
                                                                        onClick={() => printByTrackingNumber([container.TrackingNo])}
                                                                        style={{
                                                                            color: printClicked ? "lightgrey" : Constants.Colors.darkGrey,
                                                                            paddingTop: 0,
                                                                            paddingBottom: 0,
                                                                        }}
                                                                    >
                                                                        <PrintIcon></PrintIcon>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                {!props.shipped &&
                                                                    <Tooltip title="Void Label">
                                                                        <IconButton
                                                                            onClick={() => openWarningPopup(container.ShipmentKey, container.ShipmentContainerKey)}//voidContainer(container.ShipmentKey,container.ShipmentContainerKey )
                                                                            style={{
                                                                                color: Constants.Colors.darkGrey,
                                                                                paddingTop: 0,
                                                                                paddingBottom: 0,
                                                                            }}
                                                                        >
                                                                            <DoDisturbOnIcon></DoDisturbOnIcon>
                                                                        </IconButton>
                                                                    </Tooltip>}
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                                <div>
                                                    <Grid
                                                        container
                                                        className="headerRow"
                                                        style={{ paddingLeft: 5 }}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={3}
                                                            sm={3}
                                                            style={{ paddingTop: "10px" }}
                                                        >
                                                            <Typography
                                                                sx={{ fontSize: 15 }}
                                                                color="text.secondary"
                                                                gutterBottom
                                                            >
                                                                Item Description
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={5}
                                                            sm={5}
                                                            style={{ paddingTop: "10px" }}
                                                        >
                                                            <Grid container>
                                                                <Grid item xs={6} sm={6}>
                                                                    <Typography
                                                                        style={{ whiteSpace: "nowrap" }}
                                                                        sx={{ fontSize: 14 }}
                                                                        color="text.secondary"
                                                                        gutterBottom
                                                                    >
                                                                        Items in Package:{" "}
                                                                        <b style={{ color: "black" }}>{container.NoOfItems}</b>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={4} sm={4}>
                                                                    <Typography
                                                                        sx={{ fontSize: 14 }}
                                                                        color="text.secondary"
                                                                        gutterBottom
                                                                    ></Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>


                                                        <Grid
                                                            item
                                                            xs={4}
                                                            sm={4}
                                                            style={{ paddingTop: "6px", paddingBottom: "6px" }}
                                                        >
                                                            <Grid item xs={10} sm={10} style={{}}>
                                                                <Typography
                                                                    style={{ textAlign: "right", fontSize: "14px" }}
                                                                >
                                                                    {
                                                                        <Chip

                                                                            icon={<TimelineIcon style={{ color: "#006100" }} />}
                                                                            label={container.TrackingNo}
                                                                            style={{
                                                                                background: "#A1D899",
                                                                                color: "#006100",
                                                                                fontSize: "12px",
                                                                                fontWeight: 700,
                                                                                height: "20px",
                                                                                borderRadius: "4px"
                                                                            }}
                                                                        />

                                                                    }

                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider></Divider>
                                                    {
                                                        container.ContainerDetails?.ContainerDetail?.map((containerDetail: ContainerDetail,index:any) => {
                                                            return (
                                                                <React.Fragment>
                                                                    <Grid container spacing={1} display={"flex"} >
                                                                        <Grid item xs={12} sm={12} className='textLeft'>
                                                                            <Grid container spacing={1} style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                                <Grid item xs={3} sm={3} style={{ padding: "8px", display: "flex", justifyContent: "center" }}>
                                                                                    <div className="image-zoom" onClick={() => imageViewer(containerDetail)}>
                                                                                        <img style={{ height: "auto", width: "100%" }} alt="" src={Utils.convertToHttps(containerDetail.ImageUrl)} />
                                                                                    </div>
                                                                                </Grid>

                                                                                <Grid item xs={9} sm={9} style={{ padding: "8px", textAlign: "left" }}>

                                                                                    {/* Item Description */}
                                                                                    <Typography style={{ fontSize: "16px", fontWeight: 400 }}>{containerDetail.ItemDesc}</Typography>
                                                                                    {
                                                                                        renderExtendedDescription(containerDetail.ShipmentLineKey)
                                                                                    }  
                                                                                    <ExtendedDescription key={index} itemDetails={getItemDetail(containerDetail.ShipmentLineKey)}></ExtendedDescription>

                                                                                    <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                                                                            <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                                                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                    Item #
                                                                                                </Typography>
                                                                                                <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                    <b>{containerDetail.ItemID}</b>
                                                                                                </Typography>
                                                                                            </div>
                                                                                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                    Qty Packed
                                                                                                </Typography>
                                                                                                <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                    <b>{containerDetail.Quantity}</b>
                                                                                                </Typography>

                                                                                            </div>
                                                                                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                    Brand #
                                                                                                </Typography>
                                                                                                <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                    <b>{containerDetail.ItemBrand}</b>
                                                                                                </Typography>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                                                            <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                <b>{container.Extn.ExtnMultiBoxDesc}</b>
                                                                                            </Typography>
                                                                                        </div>
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Divider></Divider>
                                                                </React.Fragment>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </AccordionDetails>

                        </Accordion >

                    </div >

                    :
                    <div></div>
            }</div >
        );

    }
    const mobileView = () => {
        return (
            <React.Fragment>
                {
                    packagedItems.length > 0 && props.loadingOrderLines == false ?

                        <div >
                            <Accordion expanded={expandedPacked} onChange={() => handleChangePacked()} style={{ margin: 5, padding: 10, borderRadius: 16, boxShadow: "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 2px rgb(0 0 0 / 12%)" }}>


                                <AccordionSummary aria-controls="panel1d-content">
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={8}
                                            sm={8}
                                            style={{
                                                display: "flex",
                                                justifyContent: "left",
                                                verticalAlign: "center",
                                            }}
                                        >
                                            <Typography style={{ paddingTop: 5, fontSize: 20, fontWeight: 700 }}>
                                                Packaged Items
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            sm={4}
                                            style={{ display: "flex", justifyContent: "right" }}
                                        >
                                            <Stack direction={"row"}>
                                                {/* <Tooltip title="Print All Label">
                                                        <IconButton
                                                            onClick={(e) => printAll(e)}
                                                            style={{
                                                                color: "white",
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                            }}
                                                        >
                                                            <PrintIcon></PrintIcon>
                                                        </IconButton>
                                                    </Tooltip> */}
                                                {!props.shipped &&
                                                    <Tooltip title="Void All Label">
                                                        <IconButton
                                                            onClick={(e) => voidAll(e)}
                                                            style={{
                                                                color: "#2ca131b5",
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                            }}
                                                        >
                                                            <DoDisturbOnIcon></DoDisturbOnIcon>
                                                        </IconButton>
                                                    </Tooltip>}
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>

                                <AccordionDetails style={{ display: "block" }}>
                                    {
                                        packagedItems.map((container: Container, index: any) => {
                                            return (
                                                <div className="mainContainer">
                                                    <Card style={{ margin: "6px" }}>
                                                        {
                                                            <React.Fragment>
                                                                <div
                                                                    style={{
                                                                        color: Constants.Colors.black,
                                                                        backgroundColor: "#F5F5F5",
                                                                        display: "flex",
                                                                        justifyContent: "space-around",
                                                                    }}
                                                                >
                                                                    <Typography style={{ paddingTop: 3, paddingBottom: 3, color: "black" }}>
                                                                        Package {index + 1}
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            flexWrap: "wrap",
                                                                            paddingTop: 3,
                                                                            paddingBottom: 3,
                                                                        }}
                                                                    >
                                                                        <TimelineIcon style={{ color: "black" }} />
                                                                        <span style={{ fontSize: 12 }}>{container.TrackingNo}</span>
                                                                    </Typography>

                                                                </div>
                                                                <CardContent style={{ padding: "10px" }}>
                                                                    {
                                                                        container.ContainerDetails?.ContainerDetail?.map((containerDetail: ContainerDetail,index:any) => {
                                                                            return (

                                                                                <>
                                                                                    <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                                                                        <Grid item xs={4} sm={4} style={{ padding: "8px" }}>
                                                                                            <img style={{ height: "100px", cursor: "zoom-in" }} alt="" src={Utils.convertToHttps(containerDetail.ImageUrl)} />
                                                                                        </Grid>
                                                                                        <Grid item xs={6} sm={6} style={{ padding: "8px", textAlign: "left" }}>
                                                                                            <Typography style={{ fontSize: "16px", fontWeight: 400 }}>{containerDetail.ItemDesc}</Typography>
                                                                                            {
                                                                                                renderExtendedDescription(containerDetail.ShipmentLineKey)
                                                                                            }
                                                                                            <ExtendedDescription key={index} itemDetails={getItemDetail(containerDetail.ShipmentLineKey)}></ExtendedDescription>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <div style={{ marginLeft: '1rem', marginBottom: '1rem', marginTop: '1rem' }}>
                                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                                                                            <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                                                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                    Item #
                                                                                                </Typography>
                                                                                                <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                    <b>{containerDetail.ItemID}</b>
                                                                                                </Typography>
                                                                                            </div>
                                                                                            <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                                                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                    Qty Packed
                                                                                                </Typography>
                                                                                                <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                    <b>{containerDetail.Quantity}</b>
                                                                                                </Typography>
                                                                                            </div>
                                                                                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                                                                    Brand #
                                                                                                </Typography>
                                                                                                <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                    <b>{containerDetail.ItemBrand}</b>
                                                                                                </Typography>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                                            <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                                                                <b>{container.Extn.ExtnMultiBoxDesc}</b>
                                                                                            </Typography>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            );
                                                                        })
                                                                    }

                                                                    <Grid container className="rightAlign" style={{ marginTop: 3 }}>
                                                                        {!props.shipped &&
                                                                            <Button
                                                                                onClick={() => openWarningPopup(container.ShipmentKey, container.ShipmentContainerKey)}//voidContainer(container.ShipmentKey,container.ShipmentContainerKey )
                                                                                startIcon={<DoDisturbOnIcon fontSize="small" />}
                                                                                className="btn btnMblCancel"
                                                                                style={{
                                                                                    color: "#ffffff",
                                                                                    border: "1px solid #D93A2F",
                                                                                    width: "100%",
                                                                                }}
                                                                                variant="contained"
                                                                            >
                                                                                Void
                                                                            </Button>
                                                                        }
                                                                        {/* <Button
                                                                            onClick={() => printByTrackingNumber([container.TrackingNo])}
                                                                            startIcon={<PrintIcon fontSize="small" />}
                                                                            className="btn btnMblResource"
                                                                            style={{
                                                                                color: "#ffffff",
                                                                                border: "1px solid #D93A2F",
                                                                                width: "100%",
                                                                            }}
                                                                            variant="contained"
                                                                        >
                                                                            Print
                                                                        </Button> */}
                                                                    </Grid>
                                                                </CardContent>
                                                            </React.Fragment>
                                                        }
                                                    </Card>
                                                </div>
                                            )
                                        })
                                    }
                                </AccordionDetails>
                            </Accordion>
                        </div>

                        :
                        ""
                }
            </React.Fragment >
        )
    }

    return (
        <React.Fragment>
            {Utils.isMobile ? mobileView() : webView()}
            <NotificationPopup {...VoidWarning}></NotificationPopup>
            <ImageViewerPopup {...imageViewerSettings}>
            </ImageViewerPopup>
        </React.Fragment>
    );
}