import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import "./ContactCustomer.scss";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Grid,
    Input,
    TextField,
    Typography,
} from "@mui/material";
import Utils from "../../Common/Utils";
import { AddIcon, CloseIcon, RemoveIcon, SaveIcon } from "./SharedIcons";
import { color } from "@mui/system";
import OrderManager from "../../Managers/Orders.Manager";
import Constants from "../../Common/Constants";
import OrderDetailShimmer from "./OrderDetailShimmer";
import ApiService from "../../Common/ApiService";
import { log } from "console";
var moment = require('moment');
export const PickBoxPopup: React.FC<any> = (props: any) => {
    const { ShipmentLine, orderNumber, shipmentKey, GCPickConfirm } = props;
    //Use State
    const [shipmentLineState, setShipmentLineState] = useState<any>([]);

    React.useEffect(() => {
        ShipmentLine?.forEach((element: any) => {
            element["boxNumber"] = 1;
        });
        setShipmentLineState(ShipmentLine)
    }, []);


    const handleBoxNmbrIncrement = (item: any) => {
        const updatedShipmentLine = shipmentLineState.map((el: any) => {
            if (el === item) {
                return { ...el, boxNumber: el.boxNumber + 1 };
            }
            return el;
        });
        setShipmentLineState(updatedShipmentLine);
    }


    const handleBoxNmbrDecrement = (item: any) => {
        const updatedShipmentLine = shipmentLineState.map((el: any) => {
            if (el === item && el.boxNumber > 1) {
                return { ...el, boxNumber: el.boxNumber - 1 };
            }
            return el;
        });
        setShipmentLineState(updatedShipmentLine);
    }

    const boxNumberWidget = (item: any) => {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                    <div className="circle-buttons">
                        <AddIcon onClick={() => { handleBoxNmbrIncrement(item) }} style={{ fontSize: "20px" }} />
                    </div>
                </div>
                <div>
                    <TextField disabled value={item.boxNumber} inputProps={{ style: { textAlign: 'center', height: "10px" } }} InputProps={{ style: { marginLeft: "8px", marginRight: "8px", width: Utils.isMobile ? "auto" : "100px", textAlign: "center" } }} id="filled-basic" variant="outlined" autoComplete="off" />
                </div>
                <div>
                    <div className="circle-buttons">
                        <RemoveIcon onClick={() => { handleBoxNmbrDecrement(item) }} style={{ fontSize: "20px" }} />
                    </div>
                </div>
            </div>
        );
    }

    const handleIdentifierChange = (item: any, event: any) => {
        const updatedShipmentLine = shipmentLineState.map((el: any) => {
            if (el === item) {
                return { ...el, identifier: event.target.value };
            }
            return el;
        });
        setShipmentLineState(updatedShipmentLine);

    }

    // Save History for handleSaveBoxNumber
    const handleSaveBoxNumber = async () => {

        GCPickConfirm()
        await OrderManager.saveOrderHistory({
            orderNo: orderNumber,
            shipmentKey: shipmentKey,
            logType: Constants.boxNumberHistory,
            boxNumberList: JSON.stringify(shipmentLineState)
        });


    }


    const renderWebDetails = () => {
        return <React.Fragment>
            {
                <>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <p><b>Please provide the number of boxes the customer is supposed to receive</b></p>
                    </div>
                    {shipmentLineState.map((item: any, index: any) => (
                        <Card className="dCard" style={{ margin: 3 }}>
                            <Grid item xs={12} sm={12} className='textLeft'>
                                <Grid container spacing={1} style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                    <Grid item xs={2} sm={2} style={{ padding: "4px", display: "flex", justifyContent: "center" }}>
                                        <img style={{ height: "auto", width: "75%" }} alt="" src={Utils.convertToHttps(item.ImageUrl)} />
                                    </Grid>
                                    <Grid item xs={10} sm={10} style={{ padding: "0px", textAlign: "left" }}>
                                        <div style={{ marginBottom: '0px', marginTop: '0px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0px' }}>
                                                <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                        Item #
                                                    </Typography>
                                                    <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                        <b>{item?.ExtnPOSItemID}</b>
                                                    </Typography>
                                                </div>
                                                <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                        EID
                                                    </Typography>
                                                    <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                        <b>{item.ItemID}</b>
                                                    </Typography>
                                                </div>
                                            </div>
                                            <Divider className="card-divider" />
                                        </div>
                                        <div style={{ marginBottom: '0px', marginTop: '2px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                                <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                    <Typography style={{ fontSize: "12px", fontWeight: 600, color: Constants.Colors.dimGrey }}>Number of Boxes</Typography>
                                                    <Typography style={{ fontSize: "12px", fontWeight: 500, marginTop: "4px", display: "flex", justifyContent: "start" }}>{boxNumberWidget(item)}</Typography>
                                                </div>
                                                <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                                    {<React.Fragment>
                                                        <Typography style={{ fontSize: "12px", fontWeight: 600, color: Constants.Colors.dimGrey }}>Identifier (optional)</Typography>
                                                        <TextField InputProps={{
                                                            style: {
                                                                height: "30px",
                                                                fontSize: "12px"
                                                            }
                                                        }} onChange={(event: any) => { handleIdentifierChange(item, event) }} value={item?.identifier || ''} fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Tracking/Sku' variant="outlined" />
                                                    </React.Fragment>}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    ))}

                    {
                        <div style={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1, padding: 5 }}>
                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                <Button style={{ whiteSpace: 'nowrap', backgroundColor: Constants.Colors.red, color: Constants.Colors.white, marginLeft: 5 }} onClick={handleSaveBoxNumber} variant="contained" startIcon={<SaveIcon />}>
                                    Pick Confirm
                                </Button>
                            </div>
                        </div>

                    }
                </>
            }
        </React.Fragment >

    }

    const renderMobileDetails = () => {

        return <React.Fragment>
            {

                <div className="mainContainer" style={{ padding: "0px" }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <p><b>Please provide the number of boxes the customer is supposed to receive</b></p>
                    </div>
                    <>

                        {shipmentLineState.map((item: any, index: any) => (
                            <Card className="dCard" style={{ marginBottom: "2px" }}><CardContent className="dCardContent" style={{ padding: "0px" }}>
                                <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                                    <Grid item xs={3} sm={3} style={{ padding: "1px" }}>
                                        <img style={{ height: "70px" }} alt="" src={Utils.convertToHttps(item.ImageUrl)} />
                                    </Grid>
                                    <Grid item xs={8} sm={8} style={{ padding: "8px" }}>
                                        <div style={{}}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '' }}>
                                                <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                        Item #
                                                    </Typography>
                                                    <Typography sx={{ fontSize: 13 }} gutterBottom>
                                                        <b>{item.ExtnPOSItemID}</b>
                                                    </Typography>
                                                </div>
                                            </div>
                                            <Divider className="card-divider" />
                                        </div>
                                        <div style={{ marginTop: "2px" }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                                <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                    <Typography style={{ fontSize: "12px", fontWeight: 600, color: Constants.Colors.dimGrey }}>Number of Boxes</Typography>
                                                    <Typography style={{ fontSize: "12px", fontWeight: 500, marginTop: "4px", display: "flex", justifyContent: "center" }}>{boxNumberWidget(item)}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{}}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                                <div style={{ flex: 1, marginRight: '0.5rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                    {<React.Fragment>
                                                        <Typography style={{ fontSize: "12px", fontWeight: 600, color: Constants.Colors.dimGrey }}>Identifier(optional)</Typography>
                                                        <TextField InputProps={{
                                                            style: {
                                                                height: "30px",
                                                                fontSize: "12px"
                                                            }
                                                        }} onChange={(event: any) => { }} fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Tracking/Sku' variant="outlined" />
                                                    </React.Fragment>}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            </Card>
                        ))}



                        {
                            <div style={{ padding: 5, display: "flex", justifyContent: "center" }}>
                                <div style={{ padding: 5, display: "flex", justifyContent: "space-between" }}>
                                    <Button style={{ whiteSpace: 'nowrap', backgroundColor: Constants.Colors.red, color: Constants.Colors.white, marginLeft: 5 }} onClick={handleSaveBoxNumber} variant="contained" startIcon={<SaveIcon></SaveIcon>} >
                                        {"Pick Confirm"}
                                    </Button>

                                </div>
                            </div>


                        }
                    </>

                </div >
            }
        </React.Fragment>

    }

    return (
        <div>

            {Utils.isMobile ? renderMobileDetails() : renderWebDetails()}
        </div>
    );
};


