import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, Divider, InputBase, TextField, Checkbox, Tooltip, Button } from '@mui/material';
import Chip from "@mui/material/Chip";
import Utils from '../../Common/Utils';
import { useNavigate } from 'react-router-dom';
import { CheckBoxOutlinedIcon, MarkEmailReadIcon, MarkEmailUnreadIcon, ShoppingCart } from '../Shared/SharedIcons';
import ExpandedRow from './ExpandedRow';
import allActions from '../../Store/Actions/AllActions';
import Constants from '../../Common/Constants';
import "../../Routes/FulfillOrders.scss";

export default function PickDataTable(props: any) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let orderRows = useSelector((state: any) => state.FulfillOrdersReducersState.orderRows);
    const renderCheckBox = (shipmentKey: any) => {

        return (<Checkbox checkedIcon={<CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>} onChange={(e) => props.handleCheckboxChange(e, shipmentKey)} checked={props.ordersToPrint.indexOf(shipmentKey) > -1 ? true : false} style={{ color: "#D93A2F" }} />);
    }
    const getCount = (row: any) => {
        let count = 0
        row._source.Shipment?.ShipmentLines?.ShipmentLine?.forEach((element: any) => {
            if (element.ExtnIsWarrantyItem == "N" && element.MinLineStatusDesc != "Cancelled" && element.BundleParentOrderLineKey == undefined) {
                count = count + Number(element.OrderedQty)
            }
        });
        return count
    }
    const getDescription = (row: any) => {
        let description = ""
        // let count = row?._source?.Shipment?.ShipmentLines?.ShipmentLine?.length
        // if (count) {
        //     for (let i = 0; i < count; i++) {
        //         let element = row._source.Shipment.ShipmentLines.ShipmentLine[i]
        //         if (element.ExtnIsWarrantyItem == "N" && element.MinLineStatusDesc != "Cancelled") {
        //             description = element.ItemDesc
        //             break
        //         }
        //     }
        // }
        description = row?._source?.Shipment?.ShipmentDescription
        return <Tooltip title={description}><p style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{description}</p></Tooltip>
    }
    const getLevelOfService = (row: any) => {
        let los = ""
        if (row._source.Shipment.IsSameStorePickup != undefined && row._source.Shipment.IsSameStorePickup == "Y") {
            los = "Store Pickup"
        }
        else {
            los = row._source.Shipment.LevelOfService
        }
        return los
    }

    const getAgeTextFormat = (ms: any) => {
        const obj = Utils.convertHoursToDays(ms)

        if (obj.Days && obj.Hours) {
            return `${obj.Days} d(s) - ${obj.Hours} hr(s)`
        }
        if (obj.Days) {
            return `${obj.Days} d(s) `
        }
        if (obj.Hours) {
            return `${obj.Hours} hr(s) `
        }
        if (obj.Minutes) {
            return `${obj.Minutes} min(s) `
        }
        if (obj.Sec) {
            return `${obj.Sec} sec(s) `
        } else { return `` }
    }

    const getType = (row: any) => {
        let isWarranty = false;
        let isBundle = false;
        let isResourced = false;
        row._source.Shipment.ShipmentLines.ShipmentLine.forEach((element: any) => {
            if (element.ExtnIsWarrantyItem == "Y") {
                isWarranty = true
            }
            if (element.KitCode && element.KitCode != "") {
                isBundle = true
            }
            if (element.ExtnRescheduleReqCount > 0) {
                isResourced = true;
            }
        })
        let title = ""
        let cellValue = ""
        if (isBundle) {
            title = title + "SET"
            cellValue = cellValue + "S"
        }
        if (isWarranty) {
            if (isBundle) {
                title = title + "/PRO COV"
                cellValue = cellValue + "/PC"
            }
            else {
                title = title + "PRO COV"
                cellValue = cellValue + "PC"
            }
        }
        if (isResourced) {
            title = (isBundle || isWarranty) ? title + "/RESOURCED" : title + "RESOURCED";
            cellValue = (isBundle || isWarranty) ? cellValue + "/R" : cellValue + "R";
        }
        return (
            <div>
                <Tooltip title={title}>
                    {
                        isBundle || isWarranty || isResourced ?
                            <Chip
                                className="chipTracking"
                                label={cellValue}
                                style={{
                                    background: "white",
                                    color: "#dc2b2b",
                                    fontSize: "11px",
                                    height: "15px",
                                    border: "1px solid",
                                    position: "absolute",
                                    top: "2%",
                                    right: "0"
                                }}
                            />
                            :
                            <></>
                    }
                </Tooltip>
                {
                    getCustomerContacted(row, isBundle, isWarranty, isResourced)
                }
            </div>);
    }

    const getCustomerContacted = (row: any, isBundle: any, isWarranty: any, isResourced: any) => {
        if (row?._source?.Shipment?.CustomerResponded) {
            return <Tooltip title={Constants.FulfillOrdersGrid.CustomerResponded}>
                {
                    isBundle || isWarranty || isResourced ?
                        <MarkEmailReadIcon style={{
                            color: Constants.Colors.green, height: "20px", position: "absolute",
                            bottom: "0", right: "0"
                        }} />
                        :
                        <MarkEmailReadIcon style={{
                            color: Constants.Colors.green, height: "20px", position: "absolute",
                            top: "0", right: "0"
                        }} />
                }
            </Tooltip>
        }
        else if (row?._source?.Shipment?.CustomerContacted) {
            return <Tooltip title={Constants.FulfillOrdersGrid.CustomerContacted}>
                {
                    isBundle || isWarranty || isResourced ?
                        <MarkEmailUnreadIcon style={{
                            color: Constants.Colors.red, height: "20px", position: "absolute",
                            bottom: "0", right: "0"
                        }} />
                        :
                        <MarkEmailUnreadIcon style={{
                            color: Constants.Colors.red, height: "20px", position: "absolute",
                            top: "0", right: "0"
                        }} />
                }
            </Tooltip>
        }
        else {
            <></>
        }
    }

    const getOrderNumberWithType = (row: any) => {

        return [getType(row), <span>{row._source.Shipment.OrderNo}</span>];

    }
    const bulkPickAction = (row: any) => {

        return <Button className='btn' startIcon={<ShoppingCart className='fTabIcons' />} variant="contained" color="primary" onClick={() => { navigate("/BulkPickOrder?orderno=" + row.OrderNumber + ""); }}>Pick</Button>


    }

    const ExpandedComponent = (data: any) => {
        return <ExpandedRow data={data.data} pickTab={true}></ExpandedRow>
    };
    const rowClicked = (row: any) => {
        console.log(row, "rowClickedPick");
        let data = [...orderRows];
        if (data && data.length > 0) {
            data.forEach((item) => {
                if (item._id == row._id) {
                    item._source.Shipment.defaultExpanded = !item._source.Shipment.defaultExpanded
                }
            })
        }
        dispatch(allActions.fulfillOrdersActions.changeOrderRows(data));
    }
    const conditionalRowStyles = [

        // You can also pass a callback to style for additional customization
        {
            when: (row: any) => row._id,
            style: (row: any) => ({
                cursor: 'pointer',
            }),
        }
    ];
    const getColumns = () => {
        if (props.lineType == 0) {
            return [
                {
                    name: <Checkbox checkedIcon={<CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>} onChange={(e) => props.selectAll(e)} checked={props.selectAllCheckBox} indeterminate={props.intermediate} style={{ background: "white", padding: 0, color: "#D93A2F", borderRadius: "0px" }} />,
                    cell: (row: any) => renderCheckBox(row._id),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    minWidth: "30px",
                    width: "30px"
                },
                {
                    name: 'Action',
                    cell: (row: any) => props.WebGridButton(row),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    minWidth: "120px",
                    width: "120px"
                },

                {
                    id: "OrderNo",
                    name: 'Order Number',
                    cell: (row: any) => props.getOrderNumberWithType(row),
                    selector: (row: any) => row._source.Shipment.OrderNo,
                    sortable: true,
                    sortfield: "OrderNo",
                    minWidth: "150px",
                    width: "150px"
                },
                {
                    id: "OrderDate",
                    name: 'Ordered On',
                    selector: (row: any) => props.getDate(row),
                    sortable: true,
                    sortfield: "OrderDate",
                    minWidth: "185px",
                    width: "185px"
                },
                {
                    id: "LevelOfService",
                    name: 'Ship Method',
                    selector: (row: any) => <span style={{ fontFamily: "Roboto Mono", fontSize: "13px", textWrap: "nowrap" }}>{getLevelOfService(row)}</span>,
                    sortable: true,
                    sortfield: "LevelOfService",
                    minWidth: "170px",
                    width: "170px"
                },
                {
                    id: "Qty",
                    name: 'Qty',
                    //selector: (row: any) => Utils.getCount(row),
                    selector: (row: any) => <span style={{ fontFamily: "Roboto Mono", fontSize: "13px", textWrap: "nowrap" }}>{row._source.Shipment.qtyCountCalculated}</span>,
                    sortable: true,
                    sortfield: "Qty",
                    minWidth: "50px",
                    width: "50px"
                },
                // {
                //     name: 'Line Type',
                //     cell: (row: any) => getType(row),
                //     sortable: true,
                //     minWidth: "120px",
                //     width: "120px"
                // },
                {
                    id: "Age",
                    name: 'Age',
                    cell: (row: any) => getAgeTextFormat(row._source.Shipment.Age),
                    selector: (row: any) => row._source.Shipment.Age,
                    sortable: true,
                    sortfield: "Age",
                    minWidth: "80px",
                    width: "80px"
                },
                {
                    id: "Store",
                    name: 'Store',
                    selector: (row: any) => row._source.Shipment.ShipNode,
                    sortable: true,
                    sortfield: "Store",
                    minWidth: "85px",
                    width: "85px",
                    omit: props.selectedStores?.length <= 1
                },
                {
                    id: "Description",
                    name: 'Description',
                    cell: (row: any) => getDescription(row),
                    sortable: true,
                    sortfield: "Description",
                    minWidth: '370px',
                    maxWidth: '700px'
                }
            ];
        }
        else {

            return [
                {
                    name: '',
                    cell: (row: any) => renderCheckBox(row.OrderNumber),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                },
                {
                    name: 'Action',
                    cell: (row: any) => bulkPickAction(row),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    minWidth: "200px",
                    width: "200px"
                },


                {
                    name: 'SKU #',
                    selector: (row: any) => row.OrderNumber,
                    sortable: true,
                    minWidth: "150px",
                    width: "150px"
                },
                {
                    name: 'Qty',
                    selector: (row: any) => row.randomQty,
                    sortable: true,
                    minWidth: "150px",
                    width: "150px"
                },
                {
                    name: 'Brand',
                    selector: (row: any) => row.Brand,
                    sortable: true,
                },
                {
                    name: 'Description',
                    selector: (row: any) => row.Description,
                    sortable: true,
                    width: Utils.getDescriptionWidth("pick") + "px",
                }

            ];

        }
    }
    return (
        <DataTable
            className='fulfilledDataTable'
            title=""
            data={props.lineType == 0 ? orderRows : Utils.getDataForBulk()}
            columns={getColumns() as any}
            expandableRows
            onRowDoubleClicked={rowClicked}
            conditionalRowStyles={conditionalRowStyles}
            highlightOnHover
            // expandableRowExpanded={row => row._source.Shipment.defaultExpanded}
            expandableRowsComponent={ExpandedComponent}
            sortServer
            onSort={props.handleSort}
            defaultSortFieldId={props.defaultSortFieldId}
            defaultSortAsc={props.defaultSortAsc}
        />
    );
}
