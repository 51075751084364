import {
    LOAD_RECEIVEORDERS,
    LOAD_ALLRECEIVEORDERS,
    CHANGE_RECEIVEORDERDETAIL
} from "../ActionTypes";
const receiveOrders = (obj: any) => {
    return {
        type: LOAD_RECEIVEORDERS,
        payLoad: obj
    };
}
const allReceiveOrders = (obj: any) => {
    return {
        type: LOAD_ALLRECEIVEORDERS,
        payLoad: obj
    };
}
const changeReceiveOrderDetail = (obj: any) => {
    return {
        type: CHANGE_RECEIVEORDERDETAIL,
        payLoad: obj
    };
}
const receiveOrdersActions = {
    receiveOrders,
    allReceiveOrders,
    changeReceiveOrderDetail
}

export default receiveOrdersActions;
