import IdentityManager from "../Managers/Identity.manager";
import Constants from "./Constants";
import logger, { ILogger } from "./logger";
import Utils from "./Utils";

class ApiService {
    static getSimple = async (endpoint: string): Promise<any> => {
        let token = IdentityManager.AccessToken;
        const request = await fetch(endpoint, { headers: { 'Authorization': `Bearer ${token}` } });
        if (request.status !== 500 && request.status != 400 && request.status != 404) {
            const response = await request.json();
            return response;
        }
        else {
            console.log("Error=" + endpoint)
        }
    };
    static get = async (endpoint: string): Promise<any> => {
        let token = IdentityManager.AccessToken;
        const request = await fetch(endpoint, { headers: { 'Authorization': `Bearer ${token}` } });
        if (request.status >= 400) {
            return { success: false, msg: request.status, data: "" };
        }
        else {
            const response = await request.json();
            if (response.success) {
                return { success: true, msg: "", data: response.data };
            }
            else {
                return { success: false, msg: response.msg, data: "" };
            }
        }
    };

    static nomGet = async (endpoint: string): Promise<any> => {
        const request = await fetch(endpoint);

        if (request.status >= 400) {
            return { success: false, msg: request.status, data: "" };
        }
        else {
            const response = await request.json();
            if (response.success) {
                return { success: true, msg: "", data: response.data };
            }
            else {
                return { success: false, msg: response.msg, data: "" };
            }
        }
    };


    static nomPost = async (endpoint: string, body: any): Promise<any> => {

        try {
            const controller = new AbortController();
            const id = setTimeout(() => {
                controller.abort()
            }, 100000);
            let token = IdentityManager.AccessToken;
            const request = await fetch(endpoint, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                signal: controller.signal
            });
            clearTimeout(id);



            if (request.status >= 400) {
                return { success: false, msg: request.status, data: "" };
            }
            else {
                const response = await request.json();
                if (response.success) {
                    return { success: true, msg: "", data: response.data };
                }
                else {
                    return { success: false, msg: response.msg, data: "" };
                }
            }
        }
        catch (e) {
            throw e;
        }


    };

    static post = async (
        endpoint: string,
        body: any
    ): Promise<any> => {

        try {

            const controller = new AbortController();
            const id = setTimeout(() => {
                controller.abort()
            }, 100000);
            let token = IdentityManager.AccessToken;
            const request = await fetch(endpoint, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                signal: controller.signal
            });
            clearTimeout(id);
            if (request.status >= 400) {
                // logging, type - error
                const response = await request.json();
                if (ApiService.allowAPILoggin(endpoint)) {
                    let saveResponseConfig = Utils.getConfigurationWithKey(Constants.Configurations.SaveLogResponse);
                    let loggingPayload: ILogger = {
                        message: response?.msg,
                        tag: endpoint.toString().split("/")[2],
                        payLoad: body,
                    }

                    if (saveResponseConfig && saveResponseConfig == "1" && ApiService.checkLogsEndpoint(loggingPayload.tag)) {
                        loggingPayload.responseOMS = JSON.stringify(response);
                        logger.error(loggingPayload);
                    }
                    else {
                        logger.error(loggingPayload);
                    }
                }

                return { success: false, msg: response?.msg, data: "" };

            }
            else {
                const response = await request.json();
                if (response.success) {
                    // logging == success
                    if (ApiService.allowAPILoggin(endpoint)) {

                        let saveResponseConfig = Utils.getConfigurationWithKey(Constants.Configurations.SaveLogResponse);
                        let loggingPayload: ILogger = {
                            message: response?.msg,
                            tag: endpoint.toString().split("/")[2],
                            payLoad: body
                        }
                        if (saveResponseConfig && saveResponseConfig == "1" && ApiService.checkLogsEndpoint(loggingPayload.tag)) {
                            loggingPayload.responseOMS = JSON.stringify(response);
                            logger.info(loggingPayload);
                        }
                        else {
                            logger.info(loggingPayload);
                        }
                    }
                    return { success: true, msg: "", data: response.data };
                }
                else {
                    // logging
                    if (ApiService.allowAPILoggin(endpoint)) {
                        let saveResponseConfig = Utils.getConfigurationWithKey(Constants.Configurations.SaveLogResponse);
                        let loggingPayload: ILogger = {
                            message: response?.msg,
                            tag: endpoint.toString().split("/")[2],
                            payLoad: body
                        }
                        if (saveResponseConfig && saveResponseConfig == "1" && ApiService.checkLogsEndpoint(loggingPayload.tag)) {
                            loggingPayload.responseOMS = JSON.stringify(response);
                            logger.error(loggingPayload);
                        }
                        else {
                            logger.error(loggingPayload);
                        }
                    }
                    return { success: false, msg: response.msg, data: "" };
                }

            }
        }
        catch (error: any) {

            if (ApiService.allowAPILoggin(endpoint) && error) {
                logger.error({
                    message: error.name === 'AbortError' ? error.name : error.message,
                    tag: endpoint.toString().split("/")[2],
                    payLoad: body
                });
            }
            throw error;

        }



    };

    private static allowAPILoggin = (endpoint: any) => {

        if (endpoint && endpoint.length > 0 && endpoint.indexOf("/oms/") > -1) {
            return true;
        }

        return false;
    }

    private static checkLogsEndpoint = (endpoint: any)=>{
        let endpointConfig = Utils.getConfigurationWithKey(Constants.Configurations.LogsEndpoint);
        if(!endpointConfig)
        {
            return true;
        }
        else{
            let endpointsArray = endpointConfig.split(",");
            return endpointsArray?.length>0? endpointsArray.some((x:any)=>x.trim()==endpoint): false;
        }
    }

    static patch = async (endpoint: string, body: Partial<any>): Promise<any> => {
        let token = IdentityManager.AccessToken;
        const request = await fetch(endpoint, {
            method: "PATCH",
            body: JSON.stringify(body),
            headers: { 'Authorization': `Bearer ${token}` }
        });
        if (request.status >= 400) {
            return { success: false, msg: request.status, data: "" };
        }
        else {
            const response = await request.json();
            return { success: true, msg: "", data: response };
        }
    };


}

export default ApiService;
