import { CHANGE_ShipmentDataFromShippingCenter, REMOVE_ALL_BOXCHARACTERISTIC_FROMLIST, CHANGE_PACKAGED_ITEM, CHANGE_ITEM_QUANTITY, CHANGE_SELECTED_ITEMS, CHANGE_UNPACKAGED_ITEMS, ADD_UNPACKAGED_ITEMS, CHANGE_BOXCHARACTERISTIC, ADD_BOXCHARACTERISTIC_TOLIST, INITILIZE_BOXCHARACTERISTIC, REMOVE_BOXCHARACTERISTIC_FROMLIST, CHANGE_SHIPDATE, CHANGE_RESIDENTIAL, CHANGE_CUSTOMER_DETAIL_PACK } from "../ActionTypes";
const initialState = {
    selectedItems: [],
    shipDate: new Date(),
    isResidential: true,
    boxCharacteristicList: [],
    unpackagedItems: [],
    packagedItems: [],
    itemQty: [], // quantity to pack 
    customerDetail: {},
    shipmentDataFromShippingCenter: []
}
const PackOrderReducer = (state: any = initialState,
    action: { type: any; payLoad: any }) => {
    let clone = JSON.parse(JSON.stringify(state));
    let boxCharacteristicList = clone.boxCharacteristicList;
    let unpackagedItems = clone.unpackagedItems
    switch (action.type) {
        case CHANGE_SHIPDATE:
            return {
                ...state,
                shipDate: action.payLoad
            }
        case CHANGE_RESIDENTIAL:
            return {
                ...state,
                isResidential: action.payLoad
            }
        case CHANGE_BOXCHARACTERISTIC:
            return {
                ...state,
                boxCharacteristicList: changeBoxCharacteristicsByIndex(boxCharacteristicList, action.payLoad)
            }
        case ADD_BOXCHARACTERISTIC_TOLIST:
            return {
                ...state,
                boxCharacteristicList: action.payLoad
            }
        case INITILIZE_BOXCHARACTERISTIC:
            console.log("INITILIZE_BOXCHARACTERISTIC")
            return {
                ...state,
                boxCharacteristicList: [action.payLoad]
            }
        case REMOVE_ALL_BOXCHARACTERISTIC_FROMLIST:
            return {
                ...state,
                boxCharacteristicList: []
            }
        case REMOVE_BOXCHARACTERISTIC_FROMLIST:
            return {
                ...state,
                boxCharacteristicList: removeBoxCharacteristicByIndex(boxCharacteristicList, action.payLoad)
            }
        case ADD_UNPACKAGED_ITEMS:
            return {
                ...state,
                unpackagedItems: action.payLoad
            }
        case CHANGE_UNPACKAGED_ITEMS:
            return {
                ...state,
                unpackagedItems: changeUnpackagedByIndex()
            }
        case CHANGE_SELECTED_ITEMS:
            return {
                ...state,
                selectedItems: action.payLoad
            }
        case CHANGE_ITEM_QUANTITY:
            return {
                ...state,
                itemQty: action.payLoad
            }
        case CHANGE_PACKAGED_ITEM:
            return {
                ...state,
                packagedItems: action.payLoad
            }
        case CHANGE_CUSTOMER_DETAIL_PACK:
            return {
                ...state,
                customerDetail: action.payLoad
            }
        case CHANGE_ShipmentDataFromShippingCenter:
            return {
                ...state,
                shipmentDataFromShippingCenter: action.payLoad
            }
    }
    return state;
}
const changeUnpackagedByIndex = () => {

}
const removeBoxCharacteristicByIndex = (boxCharacteristicList: any, index: number) => {
    let newBoxCharacteristicList = boxCharacteristicList.filter((box: any) => {
        return box.index != index
    })
    return newBoxCharacteristicList
}
const changeBoxCharacteristicsByIndex = (boxCharacteristicList: any, payload: any) => {
    let key = payload.key
    let listIndex = boxCharacteristicList.findIndex((item: any) => {
        return item.index == payload.index
    })
    boxCharacteristicList[listIndex][key] = payload.value
    return boxCharacteristicList
}


export default PackOrderReducer;