import React from "react";
import { Grid, Typography, Card, CardContent, Divider, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { PersonIcon, AddLocationAltIcon, EmailIcon, LocalPhoneIcon, ExpandMoreIcon } from "../Shared/SharedIcons"
import CustomerDetailShimmer from "../PickOrder/CustomerDetailShimmer"
import { ICustomerDetail } from '../../Models/IPickOrder'
import { useSelector } from 'react-redux';
import CustomerDetailShimmerMobile from "../PickOrder/CustomerDetailShimmerMobile"
import Utils from "../../Common/Utils";
import { CustomerDetails } from "../Shared/CustomerDetails";
import Constants from "../../Common/Constants";
export const PackOrderCustomerDetail: React.FC<any> = (props) => {
    let customerDetail: ICustomerDetail = useSelector((state: any) => state.PackOrderReducersState.customerDetail);
    // let customerDetailOMS: ICustomerDetail = useSelector((state: any) => state.PickOrdersReducersState.customerDetailOMS);

    const webView = () => {
        return (
            <Card className="dCard" style={{ margin: 16 }}>
                <CardContent className="dCardContent">
                    <Typography className="card-heading"> Customer Details</Typography>
                    <Divider/>
                     {
                        props.loadingOrderLines == true ?
                            // <div className="mainContainer" style={{ padding: "20px" }}>
                            <CustomerDetailShimmer></CustomerDetailShimmer>
                            // </div>
                            :
                            <div>
                                <CustomerDetails customerDetail={customerDetail}></CustomerDetails>
                            </div>
                    }
                </CardContent>
            </Card>
        );
    }
    const mobileView = () => {
        return (
            <>
                {/* <React.Fragment>
                    {
                        props.loadingOrderLines == true ?
                            <div className="mainContainer" style={{ padding: "20px" }}>
                                <CustomerDetailShimmerMobile></CustomerDetailShimmerMobile>
                            </div>
                            :
                            <div>
                                <CustomerDetails customerDetail={customerDetail}></CustomerDetails>
                            </div>
                    }
                </React.Fragment> */}
                <Accordion style={{ margin: 5, padding: 10, borderRadius: 16, boxShadow: "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 2px rgb(0 0 0 / 12%)" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ color: Constants.Colors.darkGrey, height: 80 }}
                    >
                        <div className="topHeading">
                            <div className="gridHeading" style={{ display: "flex" }}>
                                <Typography>Customer Details</Typography>
                            </div>
                        </div>
                    </AccordionSummary>
                    <Divider className="card-divider" />
                    <AccordionDetails style={{ display: "block", padding: 0, overflowY: "scroll" }}>
                        {
                            props.loadingOrderLines == true ?
                            
                                <CustomerDetailShimmerMobile></CustomerDetailShimmerMobile>
                                :
                                // <div className="mainContainer" style={{ padding: "20px" }}>
                                <CustomerDetails customerDetail={customerDetail}></CustomerDetails>
                            // </div >

                        }
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }
    return (
        <React.Fragment>
            {Utils.isMobile ? mobileView() : webView()}
        </React.Fragment>
    )
}