import { VIEW, CONTEXT, BAR_CODE_CAPTURE, BAR_CODE_TRACKING, CAMERA, BAR_CODE_TRACKING_OVERLAY, BAR_CODE_CAPTURE_OVERLAY } from "../ActionTypes";

const scandItView = (obj: any) => {
    return {
        type: VIEW,
        payLoad: obj,
    }
}

const scandItContext = (obj: any) => {
    return {
        type: CONTEXT,
        payLoad: obj
    }
}
const scandItBarCodeCapture = (obj: any) => {
    return {
        type: BAR_CODE_CAPTURE,
        payLoad: obj
    }
}

const scandItBarCodeTracking = (obj: any) => {
    return {
        type: BAR_CODE_TRACKING,
        payLoad: obj
    }
}
const scandItCamera = (obj: any) => {
    return {
        type: CAMERA,
        payLoad: obj
    }
}

const scandItBarCodeTrackingOverlay = (obj: any) => {
    return {
        type: BAR_CODE_TRACKING_OVERLAY,
        payLoad: obj
    }
}

const scandItBarCodeCaptureOverlay = (obj: any) => {
    return {
        type: BAR_CODE_CAPTURE_OVERLAY,
        payLoad: obj
    }
}


const scandItActions = {
    scandItView,
    scandItContext,
    scandItBarCodeCapture,
    scandItBarCodeTracking,
    scandItCamera,
    scandItBarCodeTrackingOverlay,
    scandItBarCodeCaptureOverlay
}

export default scandItActions;