import React, { useEffect, useState } from "react";
import { Bars } from "react-loader-spinner";
import Utils from "../../Common/Utils";
import { Divider, Grid, Typography } from "@mui/material";
import Constants from "../../Common/Constants";
import { CloseIcon, ExpandMoreIcon, OpenInFullOutlinedIcon } from "../Shared/SharedIcons";
import { NotificationPopup } from "../Shared/NotificationPopup";
import { IButton, INotificationPopupSettings } from "../../Models/INotificationPopupSettings";
import AllItemsLocations from "./AllItemsLocations";
import { OmsPickOrderDetail } from "../../Models/OmsPickOrderDetail";
import { useSelector } from "react-redux";
import OMSManager from "../../Managers/Oms.manager";
import LabelText from "../../Common/LabelText";
import ItemLocationsShimmer from "./ItemLocationsShimmer";

const ItemLocations = (props: any) => {
    const [viewAllLocation, setViewAllLocation] = useState(false);
    const [mappedLocations,setMappedLocations] = useState([] as any);
    const [posQty, setPosQty] = useState("N/A");
    const [piDate, setPiDate] = useState("N/A");
    const [receiveDate, setReceiveDate] = useState("N/A");
    const LocationPageSize = Utils.isMobile ? 2 : 4;
    let orderDetail: OmsPickOrderDetail = useSelector((state: any) => state.PickOrdersReducersState.orderDetail);
    const [loadingItemsLocations, setLoadingItemsLocations] = useState(true);
    const [itemsLocationsErrorMsg, setItemsLocationsErrorMsg] = useState("");
    const [itemsLocationsError, setItemsLocationsError] = useState(false);

    const resetState = () => {
        setMappedLocations([]);
        setPosQty("N/A");
        setPiDate("N/A");
        setReceiveDate("N/A");
        setLoadingItemsLocations(true);
        setItemsLocationsError(false);
        setItemsLocationsErrorMsg("");

    };

    useEffect(() => {
        async function dataMapper() {
            const locationsPaylod = [{
                "location": orderDetail?.ShipNode?.ShipNode ?? "", 
                "posItemId": props?.itemDetails?.OrderLine?.ItemDetails?.Extn?.ExtnPOSItemID ?? "", 
                "orderNo": props?.itemDetails?.OrderNo ?? "" 
            }]
            try{
                let itemsLocationResponse = await OMSManager.getItemLocationDetails(locationsPaylod);
                if (itemsLocationResponse.success && itemsLocationResponse?.data?.length>0) {
                    const data = itemsLocationResponse?.data[0];
                    let itemLocationsResult: any = [];
                    data?.items?.forEach((skuID: any, index: number) => {
                        if (data.error == "") {
                            itemLocationsResult.push({id:index,  skuID: skuID["SKU.Number"], area: (skuID.LastPIArea == "" ? "N/A" : skuID.LastPIArea) })
                        }

                    });
                    setMappedLocations(itemLocationsResult);
                    setPosQty(data?.qty ? data.qty : "N/A");
                    setPiDate(data?.piDate ? data?.piDate : "N/A" );
                    setReceiveDate(data?.rxDate ? data?.rxDate : "N/A");
                    setLoadingItemsLocations(false);
                }
                else {
                    setItemsLocationsErrorMsg(LabelText.errorMessage);
                    setItemsLocationsError(true);
                    setLoadingItemsLocations(false);
                }
            }
            catch(error){
                console.log("OMSGetPosCycleCount Error",error);
                setItemsLocationsErrorMsg(LabelText.errorMessage);
                setItemsLocationsError(true);
                setLoadingItemsLocations(false);
            }
        }
        dataMapper()
        return () => {
            resetState();
        };
    }, [])

    const webView = () => {
        return (
            <>
                <Grid container>
                    <Grid item sm={12} xs={12}>
                        <Divider style={{marginBottom:7,border:"2px"}}/>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item sm={4} xs={5}>
                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                            POS Inventory on-hand
                        </Typography>
                        <Typography sx={{ fontSize: 13 }} gutterBottom>
                            <b>{posQty}</b>
                        </Typography>
                    </Grid>
                    
                    <Grid item sm={4} xs={4}>
                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                            Last Receive
                        </Typography>
                        <Typography sx={{ fontSize: 13 }} gutterBottom>
                            <b>{receiveDate}</b>
                        </Typography>
                    </Grid>
                    <Grid item sm={4} xs={3} style={{paddingLeft: Utils.isMobile ? 20 : 0}}>
                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                            Last PI
                        </Typography>
                        <Typography sx={{ fontSize: 13 }} gutterBottom>
                            <b>{piDate}</b>
                        </Typography>
                    </Grid>
                </Grid>
                <Typography style={{ fontSize: "13px"}}><b>Item Location</b></Typography>
                {!itemsLocationsError ?<div style={{ display: 'flex', marginTop:'5px' }}>
                    <div style={{ marginRight: '0.5rem', display: 'flex', flexDirection: 'column', borderRight: "1px solid black", paddingRight: "6px" }}>
                        <Typography sx={{ fontSize: 12,fontWeight: 500 }} color="text.secondary" gutterBottom>
                            SKU #
                        </Typography>
                        <Typography sx={{ fontSize: 12 ,fontWeight: 500}} gutterBottom>
                            Location
                        </Typography>
                    </div>
                    {
                        mappedLocations.length> 0 ? mappedLocations.slice(0, LocationPageSize).map((location: any) => {
                            return (
                                <>
                                    <div style={{ marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                            {location.skuID}
                                        </Typography>
                                        <Typography sx={{ fontSize: 12 }} gutterBottom>
                                            {location.area}
                                        </Typography>
                                    </div>
                                </>
                            )
                        }):
                        <>
                            <div style={{ marginRight: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                    {"N/A"}
                                </Typography>
                                <Typography sx={{ fontSize: 12 }} gutterBottom>
                                    {"N/A"}
                                </Typography>
                            </div>
                        </>
                    }    
                    {mappedLocations.length > LocationPageSize ?
                        <div onClick={()=> setViewAllLocation(true)} style={{ marginRight: '0.5rem', display: 'flex', flexDirection: 'column', cursor: 'pointer' }}>
                            <OpenInFullOutlinedIcon ></OpenInFullOutlinedIcon>       
                            <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                View All
                            </Typography>                                     
                        </div>
                        :
                        <></>
                    }                                
                </div>:
                <Typography sx={{ fontSize: 13 }}>
                    <b style={{ color: Constants.Colors.red}}>{itemsLocationsErrorMsg}</b>
                </Typography>
                }
            </>
        )
    }

    const closeAllLocations = () => {
        setViewAllLocation(false)
    }

    let viewAllLocationsAction: IButton[] = [
        {
            text: "Close",
            icon: <CloseIcon />,
            action: closeAllLocations,
            color: Constants.Colors.red,
        }
    ];

    let boxFeaturePopup: INotificationPopupSettings = {
        open: viewAllLocation,
        type: Constants.NotificationPopupType.Info,
        title: "All Locations",
        msg: "",
        handleClose: closeAllLocations,
        isCustomJSX: true,
        customJSX: <AllItemsLocations rows={mappedLocations}></AllItemsLocations>,
        actions: viewAllLocationsAction,
        fullWidth: Utils.isMobile ? true : false,
        maxWidth: "lg"
    }
    return (
        <>
            <NotificationPopup {...boxFeaturePopup}></NotificationPopup>
            {

                loadingItemsLocations ?
                    // <Bars color="#D93A2F" height={Utils.isMobile ? 30 : 50} width={Utils.isMobile ? 30 : 50} />
                    <ItemLocationsShimmer></ItemLocationsShimmer>
                    :
                    <>{webView()}</>
            }
        </>
    )
}

export default ItemLocations



