import React,{useEffect, useState} from 'react';
import { Button, Dialog, DialogContent, DialogContentText, DialogActions, Slide, DialogTitle, Typography,Grid, Paper } from '@material-ui/core';
import { IconButton } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import {CloseIcon} from "../../Subcomponent/Shared/SharedIcons" 
import Constants from "../../Common/Constants"
import Utils from "../../Common/Utils"
const html2pdf = require('html2pdf.js');
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const PdfIframe = (props: any) => {
    return (
        <Dialog disableEnforceFocus fullWidth={true} maxWidth="lg" open={props.open} TransitionComponent={Transition}  scroll={"body"} >
       <DialogTitle>               
                <IconButton
                    aria-label="close"
                    onClick={()=>props.close()}
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        color: Constants.Colors.grey,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent id="previewPDF">
            {props.open?
                <iframe title='test' width={"99%"} height={Utils.displayHeight} src={props.blobUrl}></iframe>:
                <></>
            }            
            </DialogContent>       
    </Dialog>
    );
}
export default PdfIframe;

