import Utils from '../../Common/Utils';
import { SET_DATE, SET_ORDER_TYPE, SET_RANGE_TYPE } from '../ActionTypes';
import Constants from '../../Common/Constants';

const initialState = {
    rangeType: "T",
    dateFilter: Utils.getDates(1) as any,
    orderType: Constants.FulfilledOrdersHistory.OrderTypes.All
}

const FulfilledOrdersHistoryReducer = (state: any = initialState,
    action: { type: any; payLoad: any }) => {
    switch (action.type) {
        case SET_RANGE_TYPE:
            return {
                ...state,
                rangeType: action.payLoad
            }
        case SET_DATE:
            return {
                ...state,
                dateFilter: action.payLoad
            }
        case SET_ORDER_TYPE:
            return {
                ...state,
                orderType: action.payLoad
            }
    }

    return state;
}

export default FulfilledOrdersHistoryReducer;

