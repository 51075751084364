import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Divider, Grid, Typography, Card, CardContent } from '@mui/material';
const OrderLineShimmer = (props: any) => {
    return (
        <React.Fragment>
            <Card style={{ margin: "6px" }} >
                <CardContent style={{ padding: "10px" }}>
                    <Grid spacing={2} container style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                        <Grid item xs={3} sm={4} style={{ padding: "8px", borderRight: "1px solid #2f2d2d" }}>
                            <Skeleton variant="circular" height={40} width={40} animation="wave" />
                        </Grid>
                        <Grid item xs={7} sm={7} style={{ padding: "8px", textAlign: "left" }}>
                            <Typography style={{ fontSize: "13px", fontWeight: 500, color: "#D93A2F" }}><Skeleton animation="wave" /></Typography>
                            <Typography style={{ fontSize: "11px" }}><Skeleton animation="wave" /></Typography>
                            <Typography style={{ fontSize: "11px" }}><Skeleton animation="wave" /></Typography>

                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </React.Fragment>
    );

}
export default OrderLineShimmer;