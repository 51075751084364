import React, { useEffect, useState } from 'react';
import './Routes.scss';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import Utils from '../Common/Utils';
import Box from '@material-ui/core/Box';
import { Card, CardContent, Divider, InputBase, TextField } from '@mui/material';
import Stack from '@mui/material/Stack';
import DataTable from 'react-data-table-component';
import { IconButton, Paper, Typography } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SiteBreadCrumbs from '../Subcomponent/Shared/SiteBreadCrumbs'
import { ReceiptLongIcon, FilterAltIcon, DocumentScannerIcon, SearchIcon, AssignmentIcon, CloseIcon, ArrowForwardIcon, ReportProblemIcon, UnfoldMoreSharpIcon, LocalShippingIcon } from "../Subcomponent/Shared/SharedIcons"
import MobileBarCodeScannerTrackingNumberScandit from '../Subcomponent/ReceiveOrders/MobileBarCodeScannerTrackingNumberScandit';
import allActions from '../Store/Actions/AllActions';
import Constants from '../Common/Constants';
import Drawer from '@mui/material/Drawer';
import OMSManager from '../Managers/Oms.manager';
import OrderManager from '../Managers/Orders.Manager';
import IdentityManager from '../Managers/Identity.manager';
import { IButton, INotificationPopupSettings } from '../Models/INotificationPopupSettings';
import { NotificationPopup } from '../Subcomponent/Shared/NotificationPopup';
import { ReportErrorPopup } from '../Subcomponent/Shared/ReportErrorPopup';
import "./ReceiveOrders.scss"
import { ServiceNowCnfrmText } from '../Subcomponent/Shared/ServiceNowCnfrmText';
import KCDCExpandedRows from './KCDCExpandedRows';
const queryString = window.location.search;
const pathname = window.location.pathname;
const urlParams = new URLSearchParams(queryString);
const isAfterShip = urlParams.get('ship');

const steps = [
    'Pick',
    'Pack'
];
const steps2 = [
    'Pick',
    'Customer Pickup'
]
const CreateButton = withStyles(({
    root: {
        color: 'white',
        fontWeight: 600,
        backgroundColor: "#D93A2F",
        '&:hover': { backgroundColor: "#bd3627" },
    },
}))(Button);
const KCDCReturns = (props: any) => {
    // generate random Data fro faker js
    const dispatch = useDispatch();
    const showActionLoader = () => {
        dispatch(allActions.actionLoaderActions.showLoader());
    }
    const hideActionLoader = () => {
        dispatch(allActions.actionLoaderActions.hideLoader());
    }

    var startProductBarPos = -1;
    window.onscroll = function () {
        var bar = document.getElementById('paginationWebId') as any;
        var dataTable = document.getElementById('containerDataTable') as any;
        if (bar && dataTable) {
            var dataTableWidth = dataTable.offsetWidth;
            if (startProductBarPos < 0) startProductBarPos = findPosY(bar);
            if (window.pageYOffset > startProductBarPos && (window.pageYOffset - startProductBarPos > 50)) {
                bar.style.width = (dataTableWidth - (0.8 * dataTableWidth / 100)) + "px";
                bar.classList.add("fixedPagination");
                bar.style.marginTop = Utils.getWebbarheight + "px";
            } else {
                bar.classList.remove("fixedPagination");
                bar.style.width = "";
                bar.style.marginTop = "";
            }
        }
        // Mobile View Fix the Tabs and FILTER
        if (Utils.isMobile) {
            console.log("scrolled");
            bar = document.getElementById('tabFilterContaoner') as any;
            var mblBar = document.getElementById('mblBar') as any;
            if (bar) {
                if (startProductBarPos < 0) startProductBarPos = findPosY(mblBar);
                if (window.pageYOffset > startProductBarPos) {
                    bar.classList.add("scrolledTopFix");
                } else {
                    bar.classList.remove("scrolledTopFix");
                }
            }
        }
    };
    function findPosY(obj: any) {
        var curtop = 0;
        if (obj && typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
            while (obj.offsetParent) {
                curtop += obj.offsetTop;
                obj = obj.offsetParent;
            }
            curtop += obj.offsetTop;
        }
        else if (obj && obj.y)
            curtop += obj.y;
        return curtop;
    }
    const TestState = useSelector((state: any) => state.TestReducerState);
    let userSettings = useSelector((state: any) => state.ActionLoaderReducersState.userSettings);
    console.log(TestState, "TestState");
    const navigate = useNavigate();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(userSettings?.pagination ?? Constants.UserSettingsDefault.Pagination);
    const [searchTracking, setSearchTracking] = useState("")
    let orderRows = useSelector((state: any) => state.KcdcReducerState.allkcdcOrders);
    if (searchTracking && searchTracking.length > 0) {
        orderRows = orderRows?.filter((a: any) => a._source.Shipment.ShipmentNo.indexOf(searchTracking) > -1)
    }

    let from = (page) * rowsPerPage;
    let to = from + rowsPerPage;

    let paginated: any = []
    if (orderRows) {
        try {
            paginated = orderRows?.slice(from, to);
        }
        catch {
            console.log("KCDC on Mobile list view")
            console.log(orderRows)
            console.log(from)
            console.log(to)
        }
    }

    const theme = useTheme();
    // Drawer Code
    const anchor = "right";
    const [openDrawer, setDrawer] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [openError, setOpenError] = useState(false);

    const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
    const [rowDetail, setRowDetail] = useState({} as any);
    const [value, setValue] = useState("");

    //Report Error Action Use State
    const [reportErrorAction, setReportErrorAction] = useState("");

    // Report Error Pop Up Use States
    const [openReportError, setOpenReportError] = useState(false);
    const [openAlreadyReportedError, setOpenAlreadyReportedError] = useState(false);
    const [openConfirmReportError, setOpenConfirmReportError] = useState(false);
    const [errorReportMsg, setErrorReportMsg] = useState("");

    const toggleDrawer = (op: any) => {
        setDrawer(op);
    };
    // Pagination 

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        updatePaginationSettings(parseInt(event.target.value));
    };

    const updatePaginationSettings = (pageSize: number) => {
        const updatedSettings = {
            ...userSettings,
            pagination: pageSize
        }
        dispatch(allActions.actionLoaderActions.setUserSettings(updatedSettings));
        //Update User Settings in Dynamo
        const payload = {
            attributeName: Constants.UserSettingsFields.Pagination,
            value: pageSize,
            userName: userSettings.userName
        }
        Utils.updateUserSettings(payload);
    }


    const loadOrder = (row: any) => {
        if (row._source.Shipment.ShipNode && row._source.Shipment.ShipNode > 0) {
            let shipNode = row._source.Shipment.ShipNode;
            navigate("/KCDCReturnDetail?shipmentkey=" + row._source.Shipment.ShipmentKey + "&store=" + shipNode);
        }
        else {
            navigate("/KCDCReturnDetail?shipmentkey=" + row._source.Shipment.ShipmentKey);
        }
    }

    const handleChangeOrder = (row: any) => {
        loadOrder(row);
    }

    // End Drawer Code
    const handleChange = (event: any) => {
        setValue(event.target.value);
    };

    const getData = () => {
        let storeId = IdentityManager.getMultipleStoresId(props.store);
        if (!storeId) {
            return
        }

        showActionLoader()
        setTimeout(() => {
            OrderManager.getkcdcOrders(storeId).then((resultResponse: any) => {
                if (resultResponse.success) {
                    let result = resultResponse.data
                    let dataRows = result.hits.hits;

                    if (result.hits?.totalShipmentLines > 0) {
                        dispatch(allActions.actionLoaderActions.setKcdcTotalQuantity(result.hits.totalShipmentLines));
                    }

                    if (dataRows.length > 0) {

                        dispatch(allActions.kcdcOrdersActions.loadKcdcOrders(dataRows));


                    }
                    else {

                        dispatch(allActions.kcdcOrdersActions.loadKcdcOrders([]));

                    }

                    hideActionLoader();
                }
                else {
                    hideActionLoader()
                    let message = "Something went wrong please contact the IT Help Desk for assistance"
                    console.log(resultResponse.msg)
                    setErrorMsg(message);
                    setReportErrorAction(Constants.REPORT_ERROR_ACTIONS.KCDC_RETURNS_ORDER_DETAILS);
                    openErrorPopup()
                }


            });
        }, isAfterShip ? 6000 : 2500);


    }
    const getColumns = () => {
        const columns = [

            {
                name: 'Actions',
                cell: (row: any) => WebGridButton(row),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                minWidth: "200px",
                width: "200px"
            },
            {
                name: 'Shipment #',
                selector: (row: any) => row._source.Shipment.ShipmentNo,
                sortable: true,
                minWidth: "135px"

            },
            {
                name: 'Status',
                selector: (row: any) => "Ship Back to KCDC",
                sortable: true,
                minWidth: "145px"

            },
            {
                name: 'Qty',
                selector: (row: any) => row._source.Shipment.qtyCountCalculated,
                sortable: true,
                minWidth: "160px",
                width: "150px"

            },
            {
                id: "Store",
                name: 'Store',
                selector: (row: any) => row._source.Shipment.ShipNode,
                sortable: true,
                minWidth: "85px",
                width: "85px",
                omit: props.store?.length <= 1
            },
            {
                name: 'Tracking #',
                selector: (row: any) => row._source.Shipment.trackingNo,
                sortable: true,
                minWidth: "160px"

            }

        ];
        return columns;
    }

    const WebGridButton = (row: any) => {
        return <React.Fragment>
            <Button className='red-outline-btn' startIcon={<LocalShippingIcon className='fTabIcons' />} variant="contained" color="primary" onClick={() => { handleChangeOrder(row) }}>Ship</Button>
        </React.Fragment >
    }

    const closeErrorPopup = () => {
        setOpenError(false)
    }
    const openErrorPopup = () => {
        setOpenError(true)
    }

    // Popups



    // Error Popup

    // Buttons When there is no Action
    let errorPopupCloseButtons: IButton[] = [{
        text: "Close",
        icon: <CloseIcon />,
        action: closeErrorPopup,
        color: Constants.Colors.red,
    }];


    let Error: INotificationPopupSettings = {
        open: openError,
        type: Constants.NotificationPopupType.Error,
        title: "Error",
        msg: errorMsg,
        draggable: false,
        handleClose: closeErrorPopup,
        actions: errorPopupCloseButtons
    }

    React.useEffect(() => {
        getData();
        return () => {
        }
    }, [props.store]);
    const renderWebDataTable = () => {
        return <div className='pendingReceiptsDataTable'>
            <DataTable
                className='pendingReceiptsDataTable'
                title=""
                data={paginated}
                columns={getColumns()}
            />
        </div>
    }

    const renderMobileFilters = () => {
        return <Box sx={{ p: 3 }} style={{ textAlign: "left", padding: "5px", paddingLeft: "5px", paddingTop: "5px" }}>
            {searchBar()}
        </Box>
    }

    const renderMobileData = () => {
        let storeId = IdentityManager.getStoreId(props.store);
        if (paginated && paginated.length == 0) {
            return <div style={{ marginTop: 10 }}>
                <Typography variant='body2'>No Data to display</Typography>
                {!storeId ? <Typography variant='h5'>Please select a Store #</Typography> : ""}
            </div>
        }
        else {
            return <>
                    {paginated.map((d: any, index: number) => {

                        return (
                            <>
                            {/* <div style={{ background: index % 2 !== 0 ? 'white' : '#F9F9F9' }} className='mobileRow'>
                                <div className='mobileCellLeft'>
                                    <Typography className='cellMeta'>
                                        Shipment #
                                    </Typography>
                                    <Typography className='cellMeta'>
                                        Status
                                    </Typography>
                                    <Typography className='cellMeta'>
                                        Qty
                                    </Typography>
                                    {
                                        props.store?.length > 1 ?
                                            <Typography className='cellMeta'>
                                                Store
                                            </Typography>
                                            :
                                            <></>
                                    }

                                    <Typography className='cellMeta'>
                                        Tracking #
                                    </Typography>
                                </div>
                                <div className='mobileCellRight'>
                                    <Typography style={{ fontFamily: "Roboto Mono" }} className='orderIdLabel' >
                                        <Typography className='cellInfoMain' style={{paddingTop:"3px"}}>
                                            {d._source.Shipment.ShipmentNo}
                                        </Typography>

                                        <div style={{ display: "flex", justifyContent: "flex-end", gap: "2px" }}>
                                            <div onClick={() => { handleChangeOrder(d) }} className='red-outline-btn'>
                                                {<LocalShippingIcon style={{ fontSize: 17 }} />}
                                                <Typography style={{ fontSize: "12px" }}>
                                                    Ship
                                                </Typography>
                                            </div>
                                            {<UnfoldMoreSharpIcon onClick={() => { openDetailDrawerClick(d._id) }} style={{ transform: "rotate(30deg)" }}></UnfoldMoreSharpIcon>}
                                        </div>
                                    </Typography>

                                    <Typography className='cellInfo' style={{paddingTop:"1px"}}>
                                        Ship Back to KCDC
                                    </Typography>

                                    <Typography className='cellInfo'>
                                        {Utils.getCount(d)}
                                    </Typography>
                                    {
                                        props.store?.length > 1 ?
                                            <Typography className='cellMeta' style={{marginTop:"-4px"}}>
                                                {d._source.Shipment.ShipNode}
                                            </Typography>
                                            :
                                            <></>
                                    }
                                    <Typography className='cellInfo' style={{marginTop:props.store?.length > 1 ? "6px": "-4px"}}>
                                        {Utils.getContainerTrackingNumber(d)}
                                    </Typography>
                                </div>
                            </div> */}
                            <Card style={{ margin:4}}>
                                <CardContent style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}>
                                    <Grid container style={{ margin: "0px" }} >
                                        <Grid onClick={() => { openDetailDrawerClick(d?._id) }} xs={3} sm={3} style={{ paddingTop: 4, paddingLeft: 0, color: Constants.Colors.black, background: "#ddd", display: "flex", textAlign:"center",alignItems: "center", position:"relative" }}>
                                            <Typography style={{ fontSize: "11px", fontWeight: "425", marginLeft: 1, wordBreak:"break-all"}}>
                                                {d?._source?.Shipment?.ShipmentNo}
                                            </Typography>
                                            {<UnfoldMoreSharpIcon onClick={() => { openDetailDrawerClick(d?._id) }} style={{ transform: "rotate(30deg)",position:"absolute",top:0,right:0, fontSize:"20px",color:"#e7131a" }}></UnfoldMoreSharpIcon>}
                                        </Grid>
                                        <Grid onClick={() => { openDetailDrawerClick(d?._id) }} item xs={7} sm={7} style={{ paddingTop: 3, paddingLeft: 3, textAlign: "left", verticalAlign: "center",paddingRight:"5px" }}>
                                            <Typography noWrap style={{ color: Constants.Colors.black, fontSize: "12px", fontWeight: "425" }}>{Utils.getContainerTrackingNumber(d)}</Typography>
                                            <Typography style={{ fontSize: "12px" }}>
                                                    <span className="returnsMobileLabel">Ship Back to KCDC</span>
                                            </Typography>
                                            <Typography style={{ fontSize: "11px",display:"flex",alignItems:"center"}}>
                                                <div style={{display:"flex",alignItems:"center"}}>
                                                    {props?.store?.length > 1 &&
                                                        <>
                                                            <span style={{color:"black",fontWeight:500}}>Store:</span>
                                                            <span className="returnsMobileLabel" style={{marginLeft:"2px",marginRight:4}}>
                                                            {d?._source?.Shipment?.ShipNode}
                                                            </span>
                                                        </>
                                                    }
                                                    <span style={{color:"black",fontWeight:500}}>QTY:</span>
                                                    <span className="returnsMobileLabel" style={{marginLeft:"2px"}}>{Utils.getCount(d)}</span>
                                                </div>
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={2} sm={2} style={{display:"flex",alignItems:"center",justifyContent:"center",paddingRight:"10px"}}>
                                            <Button onClick={() => { handleChangeOrder(d) }} className='mobile-list-btn'>
                                                {<LocalShippingIcon style={{ fontSize: 18,marginRight:2 }} />}
                                                <Typography style={{ fontSize: "12px"}}>
                                                    Ship
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            </>
                        );
                    })
                    }
                </>
        }

    }

    const openDetailDrawerClick = (id: any) => {
        let selectedOrder = orderRows.filter((order: any) => {
            return order._id == id
        })
        if (selectedOrder && selectedOrder.length > 0) {
            setRowDetail(selectedOrder[0]);
            setOpenDetailDrawer(true);
        }
        setOpenDetailDrawer(true)
    }
    const renderDetailDrawer = () => {
        return (
            <React.Fragment>

                <Card className="dCard" style={{ backgroundColor: "#F5F5F5" }}>
                    <CardContent className={Utils.isMobile ? "" : "dCardContent"} >
                        <div className="topHeading">
                            <div className="gridHeading">
                                <Typography style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography className="card-heading"> Order Details</Typography>
                                    <div
                                        onClick={() => {
                                            setOpenDetailDrawer(false);
                                        }}

                                    >
                                        <CloseIcon className="card-heading" />
                                    </div>
                                </Typography>
                            </div>
                        </div>
                        {/* <Stack direction={"row"} style={{ display: "flex", gap: "20", flexWrap: "wrap" }}>
                            <Stack spacing={2} direction={"column"} style={{ flex: 1 }}>
                                <Typography className="itemHeading" >{rowDetail._source?.Shipment?.ShipmentLines?.ShipmentLine[0]?.ItemDesc}</Typography>

                            </Stack>
                        </Stack> */}

                        <Stack direction={"row"} style={{ marginTop: "20px", display: "flex", gap: "20", flexWrap: "wrap" }}>
                            <Stack spacing={2} direction={"column"} style={{ flex: "60" }}>
                                <Typography className="itemHeading"  >Tracking #</Typography>
                                <Typography className="itemElement" >{rowDetail._source?.Shipment?.trackingNo}</Typography>
                            </Stack>
                        </Stack>
                        <Stack direction={"row"} style={{ marginTop: "20px", display: "flex", gap: "20", flexWrap: "wrap" }}>
                            <Stack spacing={2} direction={"column"} style={{ flex: "45" }}>
                                <Typography className="itemHeading">Status</Typography>
                                <Typography className="itemElement">{rowDetail._source?.Shipment?.StatusName == "SHIPTOKCDC" ? "Ship_Back_to_KCDC" : rowDetail._source?.Shipment?.StatusName}</Typography>
                            </Stack>
                            {
                                props.store?.length > 1 ?
                                    <Stack spacing={2} direction={"column"} style={{ flex: "45" }}>
                                        <Typography className="itemHeading">Store</Typography>
                                        <Typography className="itemElement">{rowDetail?._source?.Shipment?.ShipNode}</Typography>
                                    </Stack>
                                    : <></>
                            }

                        </Stack>
                        <KCDCExpandedRows data={rowDetail}></KCDCExpandedRows>


                    </CardContent>
                </Card>
            </React.Fragment >

        );
    }
    const changeSearchTracking = (e: any) => {
        setSearchTracking(e.target.value)
    }

    const renderPaginationAndSearch = () => {
        if (!Utils.isMobile) {
            return <React.Fragment >
                {pagination()}
            </React.Fragment>
        }
        else {
            return <React.Fragment >
            </React.Fragment>
        }
    }
    const pagination = () => {
        return < div id='paginationWebId' className="pendingReceiptsPaginationWeb">
            <div style={{ margin: "auto" }}>
                <Stack spacing={1} style={{ paddingTop: 7,marginLeft:281 }}>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={orderRows.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}

                    />
                </Stack>
            </div>
            <div>
                {searchBar()}
            </div>

        </div >
    }
    const searchBar = () => {
        return <Paper elevation={0} style={{ width: "auto", marginRight: Utils.isMobile ? 0 : 10, marginBottom: Utils.isMobile ? 0 :9 }} className={!Utils.isMobile ? "searchWeb" : ""}
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', background: "white", border: "1px solid #bbbbbb", borderRadius: "6px" }}
        >
            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
            </IconButton>
            <InputBase
                value={searchTracking}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Shipment #"
                inputProps={{ 'aria-label': 'search google maps' }}
                style={{ marginLeft: 10 }}
                onChange={(e) => changeSearchTracking(e)}
            />
            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                    <CloseIcon onClick={() => { setSearchTracking("") }}></CloseIcon>
            </IconButton>
        </Paper>
    }

    return (<div>

        <NotificationPopup {...Error}></NotificationPopup>

        <Box sx={{ p: 4, display: "flex", justifyContent: "space-between" }} style={{padding:Utils.isMobile? "0px": "10px"}}>
            {/* {Utils.isMobile && <Typography style={{ fontWeight: 700, fontSize: "32px" }} variant="h5">
                Returns
            </Typography>} */}
        </Box>
        <div style={{ marginTop: 0 }} className={Utils.isMobile ? "innerContianerMobile" : "innerContianer"}>

            {!Utils.isMobile ? <div></div> : renderMobileFilters()}
            {renderPaginationAndSearch()}
            {!Utils.isMobile ? renderWebDataTable() : renderMobileData()}

        </div>
        <Drawer
            anchor={"bottom"}
            open={openDetailDrawer}
            onClose={() => { }}
            onClick={() => { setOpenDetailDrawer(false) }}
            style={{ zIndex: 9999999 }}
        >
            {(renderDetailDrawer())}
        </Drawer>
    </div >
    );

}
export default KCDCReturns;