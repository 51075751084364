import { AnyARecord } from "dns";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import PrintConfigurationManager from "../../Managers/PrintConfiguration.Manager";
import { TEST_ACTION } from "../ActionTypes";
 const Test = (obj:any)=> {
    return {
        type: TEST_ACTION,
        payLoad: obj
    };
  }


 const testActions = {
    Test
}

export default testActions;
