import { CHANGE_SEARCHRESULT } from "../ActionTypes";
const changeSearchResult = (obj: any) => {
    return {
        type: CHANGE_SEARCHRESULT,
        payLoad: obj
    };
}

const searchActions = {
    changeSearchResult
}

export default searchActions;
