import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Divider, Grid, Typography, } from '@mui/material';
import Utils from '../../Common/Utils';
const ItemLocationsShimmer = (props: any) => {
    return (
        <React.Fragment>
                <Grid container>
                    <Grid item sm={12} xs={12}>
                        <Divider style={{marginBottom:7,border:"2px"}}/>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item sm={4} xs={5}>
                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                            POS Inventory on-hand
                        </Typography>
                        <Typography sx={{ fontSize: 11}} gutterBottom style={{paddingRight: 30}}>
                           <Skeleton animation="wave"/>
                        </Typography>
                    </Grid>
                    
                    <Grid item sm={4} xs={4}>
                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                            Last Receive
                        </Typography>
                        <Typography sx={{ fontSize: 11}} style={{paddingRight: 30}} gutterBottom>
                          <Skeleton animation="wave"/>
                        </Typography>
                    </Grid>
                    <Grid item sm={4} xs={3} style={{paddingLeft: Utils.isMobile ? 20 : 0}}>
                        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                            Last PI
                        </Typography>
                        <Typography sx={{ fontSize: 11}} gutterBottom style={{paddingRight: Utils.isMobile? 10:30}}>
                            <Skeleton animation="wave"  />
                        </Typography>
                    </Grid>
                </Grid>
                <Typography style={{ fontSize: "13px"}}><b>Item Location</b></Typography>
                <Grid container>
                    <Grid item sm={8} xs={9}>
                        <Typography sx={{ fontSize: 11}} gutterBottom style={{paddingRight: 30}}>
                            <Skeleton animation="wave"  />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item sm={8} xs={9}>
                        <Typography sx={{ fontSize: 11}} gutterBottom style={{paddingRight: 30}}>
                            <Skeleton animation="wave"  />
                        </Typography>
                    </Grid>
                </Grid>
        </React.Fragment>
    );

}
export default ItemLocationsShimmer;