export default class ApiURLS {
    public static NOM = {
        getGroupsRights: {
            URL: "nom/getGroupsRights",
            ACTION: "",
        },
        getNavigation: {
            URL: "nom/getNavigation",
            ACTION: "",
        },
        getTemplates: {
            URL: "nom/getTemplates",
            ACTION: "",
        },
        getConfiguration: {
            URL: "nom/getConfiguration",
            ACTION: "",
        },
        getPickOrdersDetailNom: {
            URL: "nom/GetPickOrdersDetailNom",
            ACTION: "",
        },
        getPickCancelLineNom: {

            URL: "nom/GCPickCancelLineNom",
            ACTION: "",
        },

        getOntactedCustomerDataByguid: {
            URL: "nom/getOntactedCustomerDataByguid",
            ACTION: "",
        },

        updateCustomerContacted: {
            URL: "nom/updateCustomerContacted",
            ACTION: "",
        },
        getFollowupDataByGuid: {
            URL: "nom/getFollowupDataByGuid",
            ACTION: "",
        },
        sendCustomerResponseEmail: {
            URL: "nom/sendCustomerResponseEmail",
            ACTION: "",

        },
        GCGetUserLoginID: {
            URL: "nom/GCGetUserLoginID",
            ACTION: "",

        },
        GCAddNoteOnEmailTrigger: {
            URL: "nom/GCAddNoteOnEmailTrigger",
            ACTION: "",

        },
        saveOrderHistory: {
            URL: "nom/saveOrderHistory",
            ACTION: "",

        },
        getDataDogConfig: {
            URL: "nom/dataDogConfig",
            ACTION: "",
        },
    }
    public static PF = {
        createShipment: {
            URL: "/pf/sendRequest",
            ACTION: "",
        }
    }
    public static Store = {
        getAllSCStores: {
            URL: "/store/getAllSCStores",
            ACTION: "",
        }
    }
    public static Index = {
        config: {
            URL: "/config",
            ACTION: "",
        }
    }
}
