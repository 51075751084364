import * as React from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardContent, Divider, InputBase, TextField, Checkbox, IconButton, Typography, Stack, Box, Grid, Tooltip, Chip, FormControl, Paper, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';
import { DoneIcon, MarkEmailReadIcon, MarkEmailUnreadIcon, PreviewIcon, ShoppingCart, UnfoldMoreSharpIcon } from "../../Subcomponent/Shared/SharedIcons"
import Constants from "../../Common/Constants"
import ShipmentStatusConstant from "../../Common/ShipmentStatusConstants"
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../Store/Actions/AllActions';
import { useNavigate } from 'react-router-dom';
import OMSManager from '../../Managers/Oms.manager';
import Utils from '../../Common/Utils';
import "../../Routes/FulfillOrders.scss";
var moment = require('moment');
// let mobilePickSingleMultipleValGlobal: any = null;
// let mobilePickSingleMultipleMarkedValuesGlobal: any = [];

export default function MobileDataTable(props: any) {


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const orderRows = useSelector((state: any) => state.FulfillOrdersReducersState.orderRows);
    const mobilePickSingleMultipleVal = useSelector((state: any) => state.FulfillOrdersReducersState.mobilePickSingleMultipleVal);
    const mobilePickSingleMultipleMarkedValues = useSelector((state: any) => state.FulfillOrdersReducersState.mobilePickSingleMultipleMarkedValues);
    // mobilePickSingleMultipleValGlobal = mobilePickSingleMultipleVal;
    // mobilePickSingleMultipleMarkedValuesGlobal = mobilePickSingleMultipleMarkedValues;
    console.log(mobilePickSingleMultipleMarkedValues, "mobilePickSingleMultipleMarkedValues");
    let currentTab = useSelector((state: any) => state.FulfillOrdersReducersState.currentTab);
    console.log(currentTab, "currentTab");



    const getCount = (row: any) => {
        let count = 0
        row._source.Shipment?.ShipmentLines?.ShipmentLine?.forEach((element: any) => {
            if (element.ExtnIsWarrantyItem == "N" && element.MinLineStatusDesc != "Cancelled" && element.BundleParentOrderLineKey == undefined) {
                count = count + Number(element.OrderedQty)
            }
        });
        return count
    }
    const getDescription = (row: any) => {
        let description = ""
        let count = row?._source?.Shipment?.ShipmentLines?.ShipmentLine?.length
        if (count) {
            for (let i = 0; i < count; i++) {
                let element = row._source.Shipment.ShipmentLines.ShipmentLine[i]
                if (element.ExtnIsWarrantyItem == "N" && element.MinLineStatusDesc != "Cancelled") {
                    description = element.ItemDesc
                    break
                }
            }
        }
        return description
    }
    const getType = (row: any) => {
        let isWarranty = false;
        let isBundle = false;
        let isResourced = false;
        row._source.Shipment.ShipmentLines.ShipmentLine.forEach((element: any) => {
            if (element.ExtnIsWarrantyItem == "Y") {
                isWarranty = true
            }
            if (element.KitCode && element.KitCode != "") {
                isBundle = true
            }
            if (element.ExtnRescheduleReqCount > 0) {
                isResourced = true;
            }
        })
        let title = ""
        let cellValue = ""
        if (isBundle) {
            title = title + "SET"
            cellValue = cellValue + "S"
        }
        if (isWarranty) {
            if (isBundle) {
                title = title + "/PRO COV"
                cellValue = cellValue + "/PC"
            }
            else {
                title = title + "PRO COV"
                cellValue = cellValue + "PC"
            }
        }
        if (isResourced) {
            title = (isBundle || isWarranty) ? title + "/RESOURCED" : title + "RESOURCED";
            cellValue = (isBundle || isWarranty) ? cellValue + "/R" : cellValue + "R";
        }
        return <React.Fragment>
            {
                isBundle || isWarranty || isResourced ?
                    <Chip
                        className="chipTracking"
                        label={cellValue}
                        style={{
                            background: "#A1D899",
                            color: "#006100",
                            fontSize: "10px",
                            height: "15px",
                            borderRadius: '4px',
                            marginLeft:4
                        }}
                    />
                    :
                    <></>
            }
            {getCustomerContacted(row)}
        </React.Fragment>
    }

    const getCustomerContacted = (row: any) => {
        if (row?._source?.Shipment?.CustomerResponded) {
            return <React.Fragment>
                {
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <MarkEmailReadIcon style={{ color: Constants.Colors.green, height: "18px" }} titleAccess="Customer Responded" />
                    </div>
                }
            </React.Fragment>
        }
        else if (row?._source?.Shipment?.CustomerContacted) {
            return <React.Fragment>
                {<div style={{ display: "flex", alignItems: "center" }}>
                    <MarkEmailUnreadIcon style={{ color: Constants.Colors.red, height: "18px" }} titleAccess="Customer Contacted" />
                </div>
                }
            </React.Fragment>

        }
        return <></>
    }

    const getCustomerName = (row: any) => {
        let name: string = "";
        if (row?._source?.Shipment?.CustomerInfo?.FirstName) {
            name = row._source.Shipment.CustomerInfo.FirstName + ' ' + row._source.Shipment.CustomerInfo.LastName
        }
        return name;
    }

    const getSingleMultipleOptions = () => {

        if (currentTab == "1") {
            return <Paper style={{ padding: "5px" }}>
                <Typography variant='body1' style={{ textAlign: "left", fontWeight: 800 }}>
                    Single/Batch Order Picking
                </Typography>
                <Grid container>
                    <Grid item xs={12} sm={12} className='inputField'>
                        {
                            Constants.SingleMultiplePickingOptions && Constants.SingleMultiplePickingOptions.length > 0 ?
                                <FormControl className='externalcustomRadio2'>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={mobilePickSingleMultipleVal}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {

                                            getMarkedValuesfromLocalStorage((event.target as HTMLInputElement).value);
                                            dispatch(allActions.fulfillOrdersActions.changeSingleMultipleVal(
                                                (event.target as HTMLInputElement).value
                                            ))
                                        }}
                                    >
                                        {Constants.SingleMultiplePickingOptions.map((val: any) => {
                                            return <FormControlLabel value={val.id} control={<Radio style={{ color: "#f2554a" }} />} label={val.val} />
                                        })}


                                    </RadioGroup>
                                </FormControl> : ""
                        }
                    </Grid>
                </Grid>
            </Paper>
        }
        else {
            return "";
        }


    }

    const getMobileDate = (date:any) =>{
        if(date)
        {
        return moment
            .utc(date)
            .tz("America/Los_Angeles")
            .format(Constants.DateFormat.Format);
        }
        return date;
    }
    const renderNormalLines = () => {


        // return <div className='mobileContainer'>
        //     <div className='mobileFulfilmentDataTable'>
        //         {orderRows.map((row: any, index: any) => {
        //             let stepstoShow = [];
        //             stepstoShow = props.steps;
        //             let activeStepCount = 0;
        //             if (props.value != 0) {
        //                 activeStepCount = props.value
        //             }
        //             return (
        //                 <div style={{ background: index % 2 === 0 ? 'white' : '#F9F9F9' }} className='mobileRow'>
        //                     <div className='mobileCellLeft' style={{height:props.store?.length > 1 ? "222px":"100%"}}>
        //                         <Typography className='cellMeta'>
        //                             Order #
        //                         </Typography>
        //                         <Typography className='cellMeta'>
        //                             Qty
        //                         </Typography>
        //                         {
        //                             props.value == 3 &&
        //                             <Typography className='cellMeta'>
        //                                 Customer
        //                             </Typography>
        //                         }
        //                         {
        //                             props.value == 3 ?
        //                             <Typography className='cellMeta'>
        //                                 Queue Date
        //                             </Typography>:
        //                             <Typography className='cellMeta'>
        //                                 Order Date
        //                             </Typography>


        //                         }
        //                         {props.store?.length > 1 ?
        //                             <Typography className='cellMeta'>
        //                                 Store
        //                             </Typography>
        //                             :
        //                             <></>
        //                         }
        //                         <Typography className='cellMeta'>
        //                             Desc.
        //                         </Typography>
        //                     </div>
        //                     <div className='mobileCellRight'>
        //                         <Typography style={{ fontFamily: "Roboto Mono" }} className='orderIdLabel' >
        //                             <Typography className='cellInfoMain' style={{paddingTop:"2px"}}>
        //                                 {row._source.Shipment.OrderNo}
        //                             </Typography>
        //                             <div style={{ display: "flex", justifyContent: "flex-end", gap: "2px" }}>
        //                                 {props.monileGridButton(row._source.Shipment.Status, row._source.Shipment.ShipmentKey, row._source.Shipment.OrderNo, getStoreId(row))}
        //                                 {<UnfoldMoreSharpIcon onClick={() => { props.detailDrawerOpen(row._id) }} style={{ transform: "rotate(30deg)" }}></UnfoldMoreSharpIcon>}
        //                             </div>

        //                         </Typography>
        //                         <Typography className='cellInfoMain'>
        //                             {row._source.Shipment.qtyCountCalculated}
        //                             {getType(row)}
        //                         </Typography>
        //                         {
        //                             props.value == 3 &&
        //                             <Typography className='cellInfo' style={{paddingTop:"3px"}}>
        //                                 {getCustomerName(row)}
        //                             </Typography>
        //                         }
        //                         {
        //                             props.value == 3 ?
        //                             <Typography className='cellInfo' style={{paddingTop:"3px"}}>
        //                                 {getMobileDate(row?._source?.Shipment?.StatusDate)}
        //                             </Typography>:
        //                             <Typography className='cellInfo' style={{paddingTop:"3px"}}>
        //                                 {getMobileDate(row?._source?.Shipment?.OrderDate)}
        //                             </Typography>
        //                         }
        //                         {props.store?.length > 1 ?
        //                             <Typography className='cellInfo' style={{paddingTop:"1px"}}>
        //                                 {row._source.Shipment.Status ==
        //                                     ShipmentStatusConstant.ReadyForCustomerPickup
        //                                     ? row._source.Shipment?.ExtnPickupStoreID
        //                                     : row._source.Shipment.ShipNode}
        //                             </Typography>
        //                             :
        //                             <></>
        //                         }

        //                         <Typography className='cellInfo' style={{paddingTop:"0px"}}>
        //                             {getDescription(row)}
        //                         </Typography>
        //                     </div>
        //                 </div>
        //             );
        //         })}

        //         {props.noMobileRecords && <Card style={{ marginTop: 4, marginBottom: 4 }}>
        //             <CardContent style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}>
        //                 <Grid container style={{ display: "flex", justifyContent: "center", margin: "0px" }} >
        //                     No More Records
        //                 </Grid>
        //             </CardContent>
        //         </Card>}
        //     </div>
        // </div>
        

        return <React.Fragment>
            {orderRows.map((row: any) => {
                return (
                    <Card style={{ marginTop: 4, marginBottom: 4 }}>
                        <CardContent style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}>
                            <Grid container style={{ margin: "0px" }} >
                                <Grid onClick={() => { props.detailDrawerOpen(row._id) }} xs={3} sm={3} style={{ paddingTop: 4, paddingLeft: 0, color: Constants.Colors.black, background: "#ddd", display: "flex", textAlign:"center",alignItems: "center", position:"relative" }}>
                                    <Typography style={{ fontSize: "11px", fontWeight: "425", marginLeft: 1, wordBreak:"break-all"}}>
                                        {row._source.Shipment.OrderNo}
                                    </Typography>
                                    {<UnfoldMoreSharpIcon onClick={() => { props.detailDrawerOpen(row._id) }} style={{ transform: "rotate(30deg)",position:"absolute",top:0,right:0, fontSize:"20px",color:"#e7131a" }}></UnfoldMoreSharpIcon>}
                                </Grid>
                                <Grid onClick={() => { props.detailDrawerOpen(row._id) }} item xs={7} sm={7} style={{ paddingTop: 3, paddingLeft: 3, textAlign: "left", verticalAlign: "center",paddingRight:"5px" }}>
                                    <Typography noWrap style={{ color: Constants.Colors.black, fontSize: "12px", fontWeight: "425" }}>{getDescription(row)}</Typography>
                                    <Typography style={{ fontSize: "12px" }}>
                                            {
                                                props.value == 3 ?
                                                    <span className="fulfillmentMobileLable" >{getMobileDate(row?._source?.Shipment?.StatusDate)}</span>
                                                :
                                                    <span className="fulfillmentMobileLable" >{getMobileDate(row?._source?.Shipment?.OrderDate)}</span>
                                            }
                                    </Typography>
                                    {props.value == 3 &&

                                        <Typography style={{ fontSize: "12px" }}>
                                            <span className="fulfillmentMobileNameLabel" style={{ marginRight: "13px" }}>{getCustomerName(row)}</span>
                                        </Typography>
                                    }
                                    <Typography style={{ fontSize: "11px",display:"flex",alignItems:"center"}}>
                                        <div style={{display:"flex",alignItems:"center"}}>
                                            {props.store?.length > 1 &&
                                                <>
                                                    <span style={{color:"black",fontWeight:500}}>Store:</span>
                                                    <span className="fulfillmentMobileLable" style={{marginLeft:"2px",marginRight:4}}>
                                                        {row?._source?.Shipment?.Status == ShipmentStatusConstant.ReadyForCustomerPickup ?
                                                            row?._source?.Shipment?.ExtnPickupStoreID :
                                                            row?._source.Shipment?.ShipNode
                                                        }</span>
                                                </>
                                            }
                                            <span style={{color:"black",fontWeight:500}}>QTY:</span>
                                            <span className="fulfillmentMobileLable" style={{marginLeft:"2px"}}>{row._source.Shipment.qtyCountCalculated}</span>
                                            {getType(row)}
                                        </div>
                                    </Typography>

                                </Grid>
                                <Grid item xs={2} sm={2} style={{display:"flex",alignItems:"center",justifyContent:"center",paddingRight:"4px"}}>
                                    {props.monileGridButton(row._source.Shipment.Status, row._source.Shipment.ShipmentKey, row._source.Shipment.OrderNo, getStoreId(row))}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                );
            })}
            
            {props.noMobileRecords && <Card style={{ marginTop: 4, marginBottom: 4 }}>
                <CardContent style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}>
                    <Grid container style={{ display: "flex", justifyContent: "center", margin: "0px" }} >
                        No More Records
                    </Grid>
                </CardContent>
            </Card>}
        </React.Fragment>


    }

    const getStoreId = (row: any) => {
        if (row._source.Shipment.Status == ShipmentStatusConstant.ReadyForCustomerPickup) {
            return row._source.Shipment.ExtnPickupStoreID;
        }
        else {
            return row._source.Shipment.ShipNode
        }
    }
    const formatDataForMissingKeys = (currentKeyData: any[]) => {


        let returnShipLines = [];

        const unique = currentKeyData.map((item: any) => item.ItemID)
            .filter((value: any, index: any, self: any) => self.indexOf(value) === index);


        if (unique && unique.length > 0) {
            // get each unique count and 

            for (const u of unique) {

                let sumItems = currentKeyData.filter((a: any) => a.ItemID == u);
                let itemIdOrders = currentKeyData.filter((a: any) => a.ItemID == u);
                let uniqueOrders = itemIdOrders.map((item: any) => item.OrderNo)
                    .filter((value: any, index: any, self: any) => self.indexOf(value) === index);
                let sum = 0;
                for (const item of sumItems) {
                    sum = sum + (+item.OrderedQty)
                }
                let fItem = currentKeyData.filter((a: any) => a.ItemID == u);
                if (fItem && fItem.length > 0) {

                    let firsOne = fItem[0];
                    firsOne.Identifier = firsOne.ItemID + firsOne.ItemDesc
                    firsOne.OrderedQty = sum;
                    firsOne.markedBatch = checkLineMarked(firsOne);
                    firsOne.OrdersCount = uniqueOrders.length;
                    returnShipLines.push(firsOne);
                }
            }
        }

        return returnShipLines;


    }


    const formatData = () => {
        let returnData: any = {};
        let finalData: any = {};
        if (orderRows && orderRows.length > 0) {
            let shipLines: any[] = [];
            let orders: any[] = JSON.parse(JSON.stringify(orderRows));
            orders.forEach((element: any) => {
                element._source.Shipment.ShipmentLines.ShipmentLine.forEach((item: any) => {

                    item.OrderNo = element._source.Shipment.OrderNo;
                    item.LevelOfService = element._source.Shipment.LevelOfService;
                    item.Age = element._source.Shipment.Age;
                    item.ParentCategoryID = "";
                    item.ParentCategoryIdDescription = "";
                    item.PosId = "";
                    item.OrderStatus = element._source.Shipment.Status;
                    if (item?.ItemDetails?.CategoryList?.Category) {
                        item.ParentCategoryID = item?.ItemDetails?.CategoryList?.Category.ParentCategoryID;
                        if (item.ParentCategoryID && item.ParentCategoryID.length > 0) {
                            if (OMSManager.catalogCategories && OMSManager.catalogCategories.Category && OMSManager.catalogCategories.Category.length > 0) {
                                let fCategory: any = OMSManager.catalogCategories.Category.filter(a => a.CategoryID == item.ParentCategoryID);
                                if (fCategory && fCategory.length > 0) {
                                    item.ParentCategoryIdDescription = fCategory[0].ShortDescription;
                                }
                            }
                            else {
                                item.ParentCategoryIdDescription = item.ParentCategoryID;
                            }

                        }
                    }

                    if (item.ItemDetails) {
                        let val = item.ItemDetails?.Extn?.ExtnPOSItemID;

                        item.PosId = val && val.length > 0 ? val : "-";
                    }

                    shipLines.push(item);

                });
            });


            // get Valid Ship Lines
            shipLines = Utils.getValidShipLines(shipLines);

            switch (mobilePickSingleMultipleVal) {

                case "2":
                    returnData = Utils.groupBy(shipLines, "PosId");
                    break;
                case "1":
                    returnData = Utils.groupBy(shipLines, "ParentCategoryIdDescription");
                    break;
                default:
                    break;
            }

            console.log(returnData, "formatData");
            let keys = Object.keys(returnData);
            if (keys && keys.length > 0) {

                for (const key of keys) {
                    let newShipLines: any[] = [];
                    let currentKeyData = returnData[key];
                    if (currentKeyData && currentKeyData.length > 0) {

                        // get distinct Item Id
                        const unique = currentKeyData.map((item: any) => item.PosId)
                            .filter((value: any, index: any, self: any) => self.indexOf(value) === index);


                        if (unique && unique.length > 0) {
                            // get each unique count and 

                            for (const u of unique) {

                                if (u == "-") {

                                    let unGroupedLines: any[] = formatDataForMissingKeys(currentKeyData);
                                    newShipLines = newShipLines.concat(unGroupedLines);
                                }
                                else {
                                    let sumItems = currentKeyData.filter((a: any) => a.PosId == u);
                                    let itemIdOrders = shipLines.filter((a: any) => a.PosId == u);
                                    let uniqueOrders = itemIdOrders.map((item: any) => item.OrderNo)
                                        .filter((value: any, index: any, self: any) => self.indexOf(value) === index);
                                    let sum = 0;
                                    for (const item of sumItems) {
                                        sum = sum + (+item.OrderedQty)
                                    }
                                    let fItem = currentKeyData.filter((a: any) => a.PosId == u);
                                    if (fItem && fItem.length > 0) {

                                        let firsOne = fItem[0];
                                        firsOne.Identifier = firsOne.PosId + firsOne.ItemDesc
                                        firsOne.OrderedQty = sum;
                                        firsOne.markedBatch = checkLineMarked(firsOne);
                                        firsOne.OrdersCount = uniqueOrders.length;
                                        newShipLines.push(firsOne);
                                    }
                                }


                            }
                        }
                        finalData[key] = newShipLines;
                    }
                }
            }

        }

        console.log(finalData, "finalData");
        return finalData;
    }

    const checkLineMarked = (inLine: any) => {

        let isMarked = false;
        if (mobilePickSingleMultipleMarkedValues && mobilePickSingleMultipleMarkedValues.length > 0) {
            let filter = mobilePickSingleMultipleMarkedValues.filter((a: any) => a.Identifier == inLine.Identifier && a.markedBatch);
            if (filter && filter.length > 0) {
                isMarked = true;
            }
        }

        //check if there are any old values for marked vaues in local storage
        let b: any = localStorage.getItem(mobilePickSingleMultipleVal + "mobilePickSingleMultipleMarkedValues");
        let mobilePickSingleMultipleMarkedValuesLocalStorage = JSON.parse(b);
        if (mobilePickSingleMultipleMarkedValuesLocalStorage && mobilePickSingleMultipleMarkedValuesLocalStorage.length > 0) {

            let filter = mobilePickSingleMultipleMarkedValuesLocalStorage.filter((a: any) => a.Identifier == inLine.Identifier && a.markedBatch);
            if (filter && filter.length > 0) {
                isMarked = true;
            }

        }

        return isMarked;

    }

    const syncLocalStorageMarkedValues = (line: any) => {

        let b: any = localStorage.getItem(mobilePickSingleMultipleVal + "mobilePickSingleMultipleMarkedValues");
        let mobilePickSingleMultipleMarkedValuesLocalStorage = JSON.parse(b);
        mobilePickSingleMultipleMarkedValuesLocalStorage = !mobilePickSingleMultipleMarkedValuesLocalStorage ? [] : mobilePickSingleMultipleMarkedValuesLocalStorage;
        if (mobilePickSingleMultipleMarkedValuesLocalStorage) {
            let filter = mobilePickSingleMultipleMarkedValuesLocalStorage.filter((a: any) => a.Identifier == line.Identifier);
            if (filter && filter.length > 0) {
                for (let element of mobilePickSingleMultipleMarkedValuesLocalStorage) {
                    if (element.Identifier == line.Identifier) {
                        element.markedBatch = line.markedBatch;
                    }
                }
            }
            else {
                mobilePickSingleMultipleMarkedValuesLocalStorage.push(line);
            }


            localStorage.setItem(mobilePickSingleMultipleVal + "mobilePickSingleMultipleMarkedValues", JSON.stringify(mobilePickSingleMultipleMarkedValuesLocalStorage));

        }

    }

    const markBarchPicked = (line: any) => {


        line.markedBatch = !line.markedBatch;
        let newvalues = [...mobilePickSingleMultipleMarkedValues];

        if (newvalues.length == 0 || newvalues.filter((a: any) => a.Identifier == line.Identifier).length == 0) {
            newvalues.push(line);
        }
        else {
            for (let element of newvalues) {
                if (element.Identifier == line.Identifier) {
                    element.markedBatch = line.markedBatch;
                }
            }
        }

        syncLocalStorageMarkedValues(line);
        dispatch(allActions.fulfillOrdersActions.changeSingleMultipleMarkedValues(newvalues));
    }
    const renderGroupedLines = () => {
        let data = formatData();
        console.log(data, "renderGroupedLines");
        let objKeys = Object.keys(data);
        let renderItemId = mobilePickSingleMultipleVal != "2"

        if (objKeys && objKeys.length > 0) {

            return <React.Fragment>
                {
                    objKeys.map((item: any) => {
                        return <React.Fragment>



                            <Paper style={{ paddingTop: "10px", paddingBottom: "10px", paddingLeft: "8px", background: "#BBBBBB", marginTop: "5px" }}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} >
                                        <Typography variant='body2' style={{ fontSize: "14px", textAlign: "left", color: "black", fontWeight: 800 }}>{!item || item.length == 0 ? "-" : item}</Typography>
                                    </Grid>
                                </Grid>
                                <Typography variant='body2'>

                                </Typography>
                            </Paper>
                            {
                                data[item].map((line: any, index: any) => {
                                    return <> 
                                    {/* <div className='mobileContainer'>
                                        <div className='mobileFulfilmentDataTable'>
                                            <div style={{ background: index % 2 === 0 ? 'white' : '#F9F9F9' }} className='mobileRow'>
                                                <div className='mobileCellLeft'>
                                                    {renderItemId && <Typography className='cellMeta'>
                                                        Item ID
                                                    </Typography>}

                                                    <Typography className='cellMeta'>
                                                        Qty
                                                    </Typography>
                                                    <Typography className='cellMeta'>
                                                        Desc
                                                    </Typography>
                                                </div>
                                                <div onClick={() => { }} className='mobileCellRight'>
                                                    <Typography style={{ fontFamily: "Roboto Mono" }} className='orderIdLabel' >
                                                        <Typography className='cellInfoMain'>
                                                            {line.ItemID}

                                                        </Typography>
                                                        <div

                                                            className={line.markedBatch ? "green-outline-btn" : "red-outline-btn"}
                                                            onClick={() => {
                                                                markBarchPicked(line);
                                                            }}
                                                        >
                                                            {<ShoppingCart style={{ fontSize: 20 }} />}
                                                            <Typography style={{ fontSize: "12px" }} >
                                                                Batch Pick
                                                            </Typography>
                                                        </div>

                                                    </Typography>

                                                    <Typography className='cellInfo'>
                                                        {line.OrderedQty}
                                                    </Typography>
                                                    <Typography className='cellInfo'>
                                                        {line.ItemDesc}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                     <Card style={{ marginTop: 4, marginBottom: 4 }}>
                                        <CardContent style={{ padding:4 }}>
                                            <Grid container style={{ margin: "0px" }} >
                                                <Grid item xs={9} sm={9} style={{ paddingTop: 3, paddingLeft: 3, textAlign: "left", verticalAlign: "center",paddingRight:"5px" }}>
                                                    <Typography noWrap style={{ color: Constants.Colors.black, fontSize: "12px", fontWeight: "425" }}>{line.ItemDesc}</Typography>
                                                    {renderItemId && <Typography style={{ fontSize: "12px" }}>
                                                        <span className="fulfillmentMobileLable">{line.ItemID}</span>
                                                    </Typography>}
                                                    <Typography style={{ fontSize: "11px",display:"flex",alignItems:"center"}}>
                                                        <div style={{display:"flex",alignItems:"center"}}>
                                                                <span style={{color:"black",fontWeight:500}}>QTY:</span>
                                                                <span className="fulfillmentMobileLable" style={{marginLeft:"2px",marginRight:4}}>
                                                                    {line.OrderedQty}
                                                                </span>
                                                                <span style={{color:"black",fontWeight:500}}>Orders:</span>
                                                                <span className="fulfillmentMobileLable" style={{marginLeft:"2px"}}>{line.OrdersCount}</span>
                                                        </div>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} sm={3} style={{display:"flex",alignItems:"center",justifyContent:"center",paddingRight:"4px"}}>
                                                    <Button onClick={() => {
                                                                markBarchPicked(line);
                                                            }} className={line.markedBatch ? 'mobile-list-btn marked-batch': 'mobile-list-btn'}>
                                                        {<ShoppingCart style={{ fontSize: 18 }} />}
                                                        <Typography style={{ fontSize: "12px"}}>
                                                            Batch Pick
                                                        </Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>

                                    </>



                                })
                            }

                        </React.Fragment>

                    })
                }
            </React.Fragment>

        }
        else {
            return "";
        }

    }



    const getMarkedValuesfromLocalStorage = (mobilePickSingleMultipleValSelectedOption: any) => {



        let markedValues: any[] = []

        if (localStorage && mobilePickSingleMultipleValSelectedOption != "0") {
            let locStoargeVals: any = localStorage.getItem(mobilePickSingleMultipleValSelectedOption + "mobilePickSingleMultipleMarkedValues");
            if (locStoargeVals && locStoargeVals.length > 0) {
                let localStoargeParsedMarkedvalues = JSON.parse(locStoargeVals);
                if (localStoargeParsedMarkedvalues && localStoargeParsedMarkedvalues.length > 0) {
                    markedValues = localStoargeParsedMarkedvalues;
                }
            }

        }

        dispatch(allActions.fulfillOrdersActions.changeSingleMultipleMarkedValues(markedValues));


    }



    React.useEffect(() => {



        return () => {

            // save the value for the option and marked values in loca storage
            //console.log("Calling Destruct");

            //dispatch(allActions.fulfillOrdersActions.changeSingleMultipleMarkedValues([]));
            //dispatch(allActions.fulfillOrdersActions.changeSingleMultipleVal("0"));
        }
    }, []);

    return (
        <React.Fragment>
            {orderRows.length > 0 ?
                <div>

                    {getSingleMultipleOptions()}
                    {
                        (currentTab != "1" || (currentTab == "1" && mobilePickSingleMultipleVal == "0")) ? renderNormalLines() : renderGroupedLines()
                    }
                </div > :
                <Card style={{ marginTop: 4, marginBottom: 4 }}>
                    <CardContent style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}>
                        <Grid container style={{ display: "flex", justifyContent: "center", margin: "0px" }} >
                            There are no records to display
                        </Grid>
                    </CardContent>
                </Card>
            }
        </React.Fragment>

    );
}