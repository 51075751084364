import IdentityManager from "./Identity.manager";
import StoreManager from "./Store.manager";
import AuditLogsManager from "./Audit.Logs.Manager";
import Constants from "../Common/Constants";
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'

export default class PrintManager {

    public static printData: any = {};

    public static handlePrintDataDisplay = async (data: any) => {
        if (data !== undefined) {
            if (data.length > 0) {
                const printData_InputArray: any[] = data.map((r: any) => r.BASE_64_PDF);
                const data_pdf = await PrintManager.mergePdf(printData_InputArray);
                PrintManager.openPdf(data_pdf)
            }
        }
    }

    public static mergePdf = async (printData_InputArray: any[]) => {
        const pdfDoc = await PDFDocument.create();

        for (var i = 0; i < printData_InputArray.length; i++) {
            const donorPdfBytes = PrintManager.base64ToArrayBuffer(printData_InputArray[i]);
            const donorPdfDoc = await PDFDocument.load(donorPdfBytes);
            const docLength = donorPdfDoc.getPageCount();
            for (var k = 0; k < docLength; k++) {
                const [donorPage] = await pdfDoc.copyPages(donorPdfDoc, [k]);
                pdfDoc.addPage(donorPage);
            }
        }
        const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
        const data_pdf = pdfDataUri.substring(pdfDataUri.indexOf(',') + 1);
        return data_pdf
    }

    public static openPdf = (basePdf: string) => {
        try {
            let byteCharacters = window.atob(basePdf);
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            let file = new Blob([byteArray], { type: 'application/pdf;base64' });
            let fileURL = URL.createObjectURL(file);
            window.open(fileURL, '_blank');
        } catch (error) {
            console.log(error)
        }
    }

    public static base64ToArrayBuffer = (base64: string) => {
        let binary_string = window.atob(base64);
        let len = binary_string.length;
        let bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    public static getZPL = async (encodedData: any[]) => {
        if (encodedData !== null && encodedData !== undefined) {
            let token = IdentityManager.AccessToken;
            let zpl = '';
            encodedData.forEach((r: any) => zpl = zpl + atob(r))
            try {
                let _res = fetch(
                    `/pf/getZPL`,
                    {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ file: zpl })
                    }
                )
                    .then(async (response) => {
                        let data = await response.arrayBuffer();
                        var file = new Blob([data], { type: 'application/pdf' });
                        window.open(URL.createObjectURL(file));
                    })
                    .catch(error => console.log('error', error));
            } catch (error) {
                console.log(error);
            }
        }
    }

    public static sendPrinterRequest = (data: any) => {
        
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("PrinterName", data.payLoad.PrinterName);//StoreManager.config.printerName);
            urlencoded.append("RawPrintData", data.payLoad.RawPrintData);//encodedData);

            var requestOptions: any = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            let _res = fetch(data.url, requestOptions)
                .then(response => response.json())
                .then(async result => {
                    console.log(result)
                })
                .catch(async error => {
                    console.log('error', error)
                    let data = { name: error.name, message: error.message, stack: error.stack }
                });
        } catch (error) {
            console.log(error);
        }
    }
}