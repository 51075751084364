import React from "react";
import "./Reasons.scss";
import {
    Grid,
    TextField,
    Typography,
    FormControl,
    Select,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";
import { IReasons } from "../../Models/IReasons";
export const Reasons: React.FC<IReasons> = (props: IReasons) => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let isExternal = urlParams.get('external');
    console.log(isExternal, "isExternal");
    const handleChangeCode = (event: SelectChangeEvent<Number>, child: any) => {
        if (props.handleValueChange) {
            props.handleValueChange("code", event.target.value);
        }
    }

    const handleChangeText = (event: any) => {

        if (props.handleValueChange) {
            props.handleValueChange("text", event.target.value);
        }
    }

    return (
        <div style={{ marginTop: 20 }}>
            {isExternal && isExternal == "true" ? ""

                : <React.Fragment>
                    {props.error && props.error.length > 0 ? <Typography style={{ color: "red" }}>
                        {props.error}
                    </Typography> : ""}
                    <Grid item xs={12} sm={12} className='inputField'>
                        <Typography>
                            Reason Code*
                        </Typography>
                        <FormControl>
                            <Select
                                onChange={handleChangeCode}
                                displayEmpty
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={props.code}
                                variant='outlined'
                                inputProps={{ 'aria-label': 'Without label' }}          >
                                <MenuItem disabled value={0}>
                                    <em>Please Select ID Type</em>
                                </MenuItem>
                                {props.reasonsCodes.map((item: any, i: number) => <MenuItem value={item}>{item}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} className='inputField'>
                        <Typography>
                            Reason Text*
                        </Typography>
                        <TextField value={props.text} onChange={handleChangeText} fullWidth InputLabelProps={{ shrink: false }} id="filled-basic" placeholder='Reason Text' variant="outlined" multiline />
                    </Grid>
                </React.Fragment>

            }

        </div>
    );
};
