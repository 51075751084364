import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Constants from "../Common/Constants";
import './Dashboard.scss'
import { ShoppingCartOutlinedIcon, NearMeOutlinedIcon, DownloadingOutlinedIcon, ClassOutlinedIcon } from "../Subcomponent/Shared/SharedIcons";
import Utils from '../Common/Utils';
import React, { useState } from 'react';
import OrderCountShimmer from '../Subcomponent/Dashboard/OrderCountShimmer';

interface IDashboardPendingGrid {
    isOrderCountLoaded: boolean;
    orderCount: any;
    navigateToDrawer: (route: string, type: number) => void;
}

const DashboardPendingGrid: React.FC<IDashboardPendingGrid> = ({ isOrderCountLoaded, orderCount, navigateToDrawer, ...props }) => {

    const smGridSize: any = Utils.isMobile ? {
        item1: 6, item2: 6, item3: 6, item4: 6
    } : {
        item1: 6, item2: 6, item3: 6, item4: 6
    }
    const xsGridSize: any = Utils.isMobile ? {
        item1: 6, item2: 6, item3: 6, item4: 6
    } : {
        item1: 6, item2: 6, item3: 6, item4: 6
    }
    const mdGridSize: any = Utils.isMobile ? {
        item1: 0, item2: 0, item3: 0, item4: 0
    } : {
        item1: 6, item2: 6, item3: 6, item4: 6
    }
    const lgGridSize: any = Utils.isMobile ? {
        item1: 0, item2: 0, item3: 0, item4: 0
    } : {
        item1: 3, item2: 3, item3: 3, item4: 3
    }
    const gridStyle = Utils.isMobile ? { paddingLeft: 3, paddingRight: 3, marginBottom: 8 } : { marginBottom: 5, marginTop: 5 };
    const flexDirectionDynamic = Utils.isMobile ? "column": "row";

    return (
        <Grid container spacing={2} className={Utils.isMobile ? "1" : "2"} style={gridStyle}>
            <Grid item lg={lgGridSize.item1} md={mdGridSize.item1} xs={xsGridSize.item1} sm={smGridSize.item1}>
                <Card className='dCardCustom' onClick={() => navigateToDrawer("FulfillOrders", 1)} style={{background:"#1491F6"}}>
                    <CardContent className={Utils.isMobile ? "dCardContent" : "dCardContentCustom"}>
                        <div className={Utils.isMobile ? "": "dCardDetails"}>
                            <div className='dCardHeader' style={{ flexDirection: flexDirectionDynamic}}>
                                <div className='icon'>
                                    <ShoppingCartOutlinedIcon />
                                </div>
                                <div className='header'>
                                    <Typography>
                                        Pick
                                    </Typography>
                                </div>

                                {isOrderCountLoaded &&
                                    <React.Fragment>
                                        <Typography component="div" style={{ backgroundColor: "#E7131A", color: Constants.Colors.white, fontWeight: '700', fontSize: '12px', marginBottom: 2, marginLeft: 10, borderRadius: '4px', padding: '4px 6px 4px 6px', lineHeight: '10px' }}>
                                            {orderCount.onHold} On Hold
                                        </Typography>
                                        {/* <Button onClick={() => props.navigateToDrawer("FulfillOrders", 1)} variant="contained" color="primary" className='btn' style={{ marginTop: 0 }}>Pick</Button> */}
                                    </React.Fragment>}
                            </div>
                            {isOrderCountLoaded ?
                                <React.Fragment>
                                    <Typography variant="h4" component="div" className='number'>
                                        {orderCount.readyForPicking}
                                    </Typography>
                                    {/* <Button onClick={() => props.navigateToDrawer("FulfillOrders", 1)} variant="contained" color="primary" className='btn' style={{ marginTop: 0 }}>Pick</Button> */}
                                </React.Fragment> : <OrderCountShimmer></OrderCountShimmer>}
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item lg={lgGridSize.item2} md={mdGridSize.item2} xs={xsGridSize.item2} sm={smGridSize.item2} >
                <Card className='dCardCustom' onClick={() => navigateToDrawer("FulfillOrders", 2)} style={{background:"#FFA514"}}>
                    <CardContent className={Utils.isMobile ? "dCardContent" : "dCardContentCustom"}>
                        <div className={Utils.isMobile ? "": "dCardDetails"} >
                            <div className='dCardHeader' style={{ flexDirection: flexDirectionDynamic }}>
                                <div className='icon'>
                                    <svg className="fTabIcons" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.41667 5.66667V17.5833H17.5833V5.66667H14.3333V14.3333L10 12.1667L5.66667 14.3333V5.66667H2.41667ZM2.41667 19.75C1.82083 19.75 1.31076 19.5378 0.886458 19.1135C0.462153 18.6892 0.25 18.1792 0.25 17.5833V4.06875C0.25 3.81597 0.290625 3.57222 0.371875 3.3375C0.453125 3.10278 0.575 2.88611 0.7375 2.6875L2.09167 1.03542C2.29028 0.782639 2.53854 0.588542 2.83646 0.453125C3.13438 0.317708 3.44583 0.25 3.77083 0.25H16.2292C16.5542 0.25 16.8656 0.317708 17.1635 0.453125C17.4615 0.588542 17.7097 0.782639 17.9083 1.03542L19.2625 2.6875C19.425 2.88611 19.5469 3.10278 19.6281 3.3375C19.7094 3.57222 19.75 3.81597 19.75 4.06875V17.5833C19.75 18.1792 19.5378 18.6892 19.1135 19.1135C18.6892 19.5378 18.1792 19.75 17.5833 19.75H2.41667ZM2.85 3.5H17.15L16.2292 2.41667H3.77083L2.85 3.5ZM7.83333 5.66667V10.8125L10 9.72917L12.1667 10.8125V5.66667H7.83333Z" fill="#FFF"/>
                                    </svg>
                                </div>
                                <div className='header'>
                                    <Typography>
                                        Pack
                                    </Typography>
                                </div>
                            </div>

                            
                                {isOrderCountLoaded ? <React.Fragment>
                                    <Typography variant="h4" component="div" className='number'>
                                        {orderCount.readyForPacking}
                                    </Typography>
                                    {/* <Button onClick={() => props.navigateToDrawer("FulfillOrders", 2)} variant="contained" color="primary" className='btn' style={{ marginTop: 23 }}>Pack</Button> */}
                                </React.Fragment> : <OrderCountShimmer></OrderCountShimmer>}
                           
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item lg={lgGridSize.item4} md={mdGridSize.item4} xs={xsGridSize.item4} sm={smGridSize.item4} >
                <Card className='dCardCustom' style={{cursor: "pointer",background:"#338A5D"}} onClick={() => navigateToDrawer("ReceiveOrders", 0)}>
                     <CardContent className={Utils.isMobile ? "dCardContent" : "dCardContentCustom"}>
                        <div className={Utils.isMobile ? "": "dCardDetails"}>
                            <div className='dCardHeader' style={{ flexDirection: flexDirectionDynamic}}>
                                <div className='icon'>
                                    <DownloadingOutlinedIcon />
                                </div>
                                <div className='header'>
                                    <Typography>
                                        Receive
                                    </Typography>
                                </div>
                            </div>
                            {isOrderCountLoaded ?
                                <React.Fragment>
                                    <Typography variant="h4" component="div" className='number'>
                                        {orderCount.pendingReceipts}
                                    </Typography>
                                </React.Fragment> : <OrderCountShimmer></OrderCountShimmer>}
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item lg={lgGridSize.item3} md={mdGridSize.item3} xs={xsGridSize.item3} sm={smGridSize.item3} >
                <Card className='dCardCustom' style={{cursor: "pointer",background:"#3A3C59"}} onClick={() => navigateToDrawer("FulfillOrders", 3)}>
                    <CardContent className={Utils.isMobile ? "dCardContent" : "dCardContentCustom"}>
                        <div className={Utils.isMobile ? "": "dCardDetails"}>
                            <div className='dCardHeader' style={{ flexDirection: flexDirectionDynamic}}>
                                <div className='icon'>
                                    <NearMeOutlinedIcon/>
                                </div>
                                <div className='header'>
                                    <Typography>
                                        Pickup
                                    </Typography>
                                </div>
                            </div>
                            {isOrderCountLoaded ?
                                <React.Fragment>
                                    <Typography variant="h4" component="div" className='number'>
                                        {orderCount.readyForCustomerPickup}
                                    </Typography>
                                </React.Fragment> : <OrderCountShimmer></OrderCountShimmer>}
                        </div>
                    </CardContent>
                </Card>
            </Grid>

            
        </Grid>
    )
};

export default DashboardPendingGrid;