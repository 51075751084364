import { CHANGE_DESCRIPTION, CHANGE_SHORT_DESCRIPTION, CHANGE_PRIORITY, CHANGE_IMPACT, URGENCY } from "../ActionTypes";
const initialState = {

    description: "",
    shortDescription: "",
    priority: "",
    impact: "",
    urgency: ""
}
const ReportErrorReducers = (state: any = initialState,
    action: { type: any; payLoad: any }) => {
    switch (action.type) {
        case CHANGE_DESCRIPTION:
            return {
                ...state,
                description: action.payLoad
            }
        case CHANGE_SHORT_DESCRIPTION:
            return {
                ...state,
                shortDescription: action.payLoad
            }
        case CHANGE_PRIORITY:
            return {
                ...state,
                priority: action.payLoad
            }
        case CHANGE_IMPACT:
            return {
                ...state,
                impact: action.payLoad
            }
        case URGENCY:
            return {
                ...state,
                urgency: action.payLoad
            }

    }
    return state;
}

export default ReportErrorReducers;