import { Typography } from '@mui/material';

import React from 'react';
import { SearchOffIcon } from './SharedIcons';

const DataNotFound = () => {
    return (
        <div style={{ padding: "70px", textAlign: "center", display: "flex", justifyContent: "center" }}>
            <span > <Typography variant="h6" className='number' style={{ color: 'grey' }}> No Records to Display <SearchOffIcon  ></SearchOffIcon></Typography></span>
        </div>

    )
}

export default DataNotFound;