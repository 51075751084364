import React, { useState } from "react";
import "./ContactCustomer.scss";
import {
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { PersonIcon, AddLocationAltIcon, EmailIcon, LocalPhoneIcon } from "./SharedIcons";
import { CardContent } from "@material-ui/core";
import { Divider } from '@mui/material';
import Utils from "../../Common/Utils";
import Constants from "../../Common/Constants";
export const NumberOfBoxes: React.FC<any> = (props: any) => {

    const { numberOfBoxArr } = props

    const renderWebDetails = () => {

        return <React.Fragment>
            {

                numberOfBoxArr.length && numberOfBoxArr.map((item: any) => {

                    return < div >
                        <Grid container spacing={2} style={{ display: "flex", flexDirection: "column", padding: "10px" }}>
                            <Grid container item xs={12} sm={12} className='textLeft'>
                                <Grid item xs={3} sm={3} className='textLeft'>
                                    <Typography style={{ fontSize: "13px", fontWeight: 500, marginLeft: "10px", wordBreak: "break-all", color: Constants.Colors.red }}>Item ID </Typography>
                                    <Typography style={{ fontSize: "13px", fontWeight: 400, marginLeft: "10px", wordBreak: "break-all" }}>{item?.ItemID} </Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} className='textLeft'>
                                    <Typography style={{ fontSize: "13px", fontWeight: 500, marginLeft: "10px", wordBreak: "break-all", color: Constants.Colors.red }}>SKU #</Typography>
                                    <Typography style={{ fontSize: "13px", fontWeight: 400, marginLeft: "10px", wordBreak: "break-all" }}>{item?.ExtnPOSItemID} </Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} className='textLeft'>
                                    <Typography style={{ fontSize: "13px", fontWeight: 500, marginLeft: "10px", wordBreak: "break-all", color: Constants.Colors.red }}>Box Number </Typography>
                                    <Typography style={{ fontSize: "13px", fontWeight: 400, marginLeft: "10px", wordBreak: "break-all" }}>{item?.boxNumber} </Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} className='textLeft'>
                                    <Typography style={{ fontSize: "13px", fontWeight: 500, marginLeft: "10px", wordBreak: "break-all", color: Constants.Colors.red }}>Identifier </Typography>
                                    <Typography style={{ fontSize: "13px", fontWeight: 400, marginLeft: "10px", wordBreak: "break-all" }}>{item?.identifier} </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider />
                    </div >
                })

            }
        </React.Fragment >

    }



    return (
        <div>
            {renderWebDetails()}
        </div>
    );
};
