import React, { useState } from 'react';
import IdentityManager from '../../Managers/Identity.manager';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TableContainer, Table, Paper, TableRow, TableCell, TableBody, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Constants from '../../Common/Constants';
import "./Feedback.scss";
import SAD from '../../Assets/SAD.png';
import HAPPY from '../../Assets/HAPPY.png';
import NEUTRAL from '../../Assets/NEUTRAL.png';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import Utils from '../../Common/Utils';
import OrderManager from '../../Managers/Orders.Manager';
import PFManager from '../../Managers/Process.Fulfillment.Manager';
import { NotificationPopup } from '../Shared/NotificationPopup';
import { DoneIcon } from "../Shared/SharedIcons";
import { IButton, INotificationPopupSettings } from '../../Models/INotificationPopupSettings';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface FeedBackResponse {
  questionId: string;
  questionText: string;
  selectedResponse: string;
  textFeedback: string;
}
export default function FeedbackDialog(props: any): any {
  const closeDialogBox = () => props.setFeedBackDialog(false)
  const openDialogBox = () => {
    setTextFeedback("")
    setFeedbackResponse([]);
    setErrorMessage("");
    props.setFeedBackDialog(true)
  }
  const [isActive, setActive] = useState(false);
  const [feedbackQuestions, setFeedbackQuestions] = useState([] as any);
  const [feedbackConfiguration, setFeedbackConfiguration] = useState({} as any);
  const [feedbackResponse, setFeedbackResponse] = useState([] as FeedBackResponse[]);
  const [textFeedback, setTextFeedback] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openSuccess, setSuccess] = useState(false);
  const init = () => {
    const feedbackConfiguration = Utils.getFeedbackConfiguration();
    const feedbackQuestions = Utils.getFeedbackQuestions()?.filter((x: any) => x.IsActive == "1");
    setFeedbackQuestions(feedbackQuestions);
    setFeedbackConfiguration(feedbackConfiguration)
    if (feedbackConfiguration.IsActive == "1") {
      if (feedbackQuestions.some((x: any) => x.IsActive == "1")) {
        setActive(true);
      }
    }
  }

  const closeSuccess = () => {
    setSuccess(false)
  }

  let successPopupButtons: IButton[] = [
    {
      text: "Close",
      icon: <DoneIcon />,
      action: closeSuccess,
      color: Constants.Colors.red,
    }
  ];

  const submitFeedbackResponse = async () => {
  
    var showRequiredError = false
    feedbackQuestions.forEach((question: any) => {
      var findIndex = feedbackResponse.findIndex((x: FeedBackResponse) => x.questionId == question.questionId)
      if (findIndex == -1) {
        showRequiredError = true
      }
    });
    if (!showRequiredError) {
      try {
        let payload = [] as any
        feedbackResponse.forEach((response: any) => {
          let doc = {
            store: OrderManager.getStore(),
            userId: IdentityManager.getUserProperty(Constants.EmployeeID),
            upn: IdentityManager.getUserProperty(Constants.Preferred_UserName),
            textFeedback: textFeedback,
            questionId: response.questionId,
            questionText: response.questionText,
            selectedResponse: response.selectedResponse,
          }
          payload.push(doc)
        });
        await PFManager.addFeedbackBulk({
          payload
        });
        closeDialogBox();
        setSuccess(true);
      }
      catch (e) {
        setErrorMessage("There was an error submitting response")
      }
    }
    else {
      setErrorMessage("Please select response against all questions.")
    }
    // loop over all questions and text and save to index
    // create payload for bulk update
  }

  let success: INotificationPopupSettings = {
    open: openSuccess,
    type: Constants.NotificationPopupType.Success,
    title: "Success",
    msg: "Thank you for submitting your feedback",
    draggable: false,
    handleClose: closeSuccess,
    actions: successPopupButtons
  }

  const handleFeedbackClick = (type: string, questionId: string, questionText: string) => {
    var selected = "";
    switch (type) {
      case "SAD":
        selected = "-1"
        break;
      case "NEUTRAL":
        selected = "0"
        break;
      case "HAPPY":
        selected = "1"
        break;
    }
    var findIndex = feedbackResponse.findIndex((x: FeedBackResponse) => x.questionId == questionId);
    if (findIndex != -1) {
      const updatedItems = [...feedbackResponse];
      updatedItems[findIndex].selectedResponse = selected;
      setFeedbackResponse(updatedItems);
    }
    else {
      setFeedbackResponse([
        ...feedbackResponse,
        { questionId: questionId, questionText: questionText, selectedResponse: selected, textFeedback: "" }
      ]);
    }
  }
  const textFeedbackOnChange = (event: any) => {
    setTextFeedback(event.target.value)
  }
  React.useEffect(() => {
    init();
  }, []);
  const renderTable = () => {
    return (
      <div>
        <div id="panel" className="panel-container">
          {
            feedbackQuestions.map((row: any, index: any) => {
              var currentFeedbackResponse = feedbackResponse.filter(x => x.questionId == row.questionId)
              return (<div>
                <div>
                  <strong>{row.questionText}</strong>
                </div>
                <div className="ratings-container">
                  <div onClick={(e) => handleFeedbackClick("SAD", row.questionId, row.questionText)} className={`rating ${currentFeedbackResponse.length > 0 && currentFeedbackResponse[0].selectedResponse == '-1' && 'active'}`}>
                    <img src={SAD} alt=""></img>
                    <small>Unhappy</small>
                  </div>
                  <div onClick={(e) => handleFeedbackClick("NEUTRAL", row.questionId, row.questionText)} className={`rating ${currentFeedbackResponse.length > 0 && currentFeedbackResponse[0].selectedResponse == "0" && 'active'}`}>
                    <img src={NEUTRAL} alt=""></img>
                    <small>Neutral</small>
                  </div>
                  <div onClick={(e) => handleFeedbackClick("HAPPY", row.questionId, row.questionText)} className={`rating ${currentFeedbackResponse.length > 0 && currentFeedbackResponse[0].selectedResponse == "1" && 'active'}`}>
                    <img src={HAPPY} alt=""></img>
                    <small>Satisfied</small>
                  </div>
                </div>
              </div>)
            })
          }
          <div>
            <strong>{feedbackConfiguration.TextBoxLabel}</strong>
          </div>
          <TextField
            onChange={(event: any) => { textFeedbackOnChange(event) }}
            value={textFeedback}
            style={{ width: Utils.isMobile ? "auto" : 350 }}
            id="outlined-multiline-static"
            hiddenLabel
            multiline
            rows={2}
            defaultValue=""
          />
          {
            errorMessage.length > 0 ?
              <div style={{ color: "red" }}>
                {
                  errorMessage
                }
              </div>
              :
              <></>
          }
        </div>

      </div>
    );
  }
  return (
    <>
      {
        isActive ? <>
          <NotificationPopup {...success}></NotificationPopup>
          {
            Utils.isDesktop ?
              <Fab onClick={() => { openDialogBox() }} style={{ position: "fixed", zIndex: "999", left: "-40px", bottom: "60px", transform: "rotate(90deg)", borderRadius: "0px" }} color="primary" variant="extended">
                Feedback !
              </Fab>
              :
              <></>
          }
          <Dialog open={props.open} onClose={() => { closeDialogBox() }} TransitionComponent={Transition} PaperProps={{ style: { borderRadius: '16px'} }}>
            <DialogContent style={{ padding: 0, paddingTop: 0 }}>
              {renderTable()}
            </DialogContent>
            <DialogActions>
              <Button size="small" onClick={() => { closeDialogBox() }} variant="contained" color="secondary">
                <span className="dialogButton">Close</span>
              </Button>
              <Button size="small" onClick={() => { submitFeedbackResponse() }} variant="contained" color="primary">
                <span className="dialogButton">Send Review</span>
              </Button>
            </DialogActions>
          </Dialog>
        </>
          :
          <></>
      }
    </>
  );
}