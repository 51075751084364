import { SET_DATE, SET_ORDER_TYPE, SET_RANGE_TYPE } from "../ActionTypes";


const setRangeType = (obj:any)=>{

    return {
        type: SET_RANGE_TYPE,
        payLoad: obj
    };

}

const setDate = (obj: any) => {
    return {
        type: SET_DATE,
        payLoad: obj
    };
}

const setOrderType = (obj: any)=>{
    return {
        type: SET_ORDER_TYPE,
        payLoad: obj
    };
}

const fulfilledOrdersHistoryActions = {
    setRangeType,
    setDate,
    setOrderType
   
}

export default fulfilledOrdersHistoryActions;