import Utils from "../../Common/Utils";
import { Grid, Typography } from "@mui/material";
import IdentityManager from "../../Managers/Identity.manager";
import Constants from "../../Common/Constants";

const momenttz = require('moment-timezone');
const CustomerPickupSlip = (props:any) => {
    
    const getCustomerName = (row: any) => {
        let name: string = "";
        if (row?._source?.Shipment?.CustomerInfo?.FirstName) {
            name = row._source.Shipment.CustomerInfo.FirstName + ' ' + row._source.Shipment.CustomerInfo.LastName
        }
        return name;
    }

    const renderTableHeading = (text: any) => {
        return <Typography variant='body1'><span style={{ fontSize: "12px" }}>{text}</span></Typography>;
    }

    const renderPrice = (text: any) => {
        const number = Number(text)?.toFixed(2) ?? "0.00";
        return <Typography variant='body1'><span style={{ fontSize: "12px" }}>{'$'}{number}</span></Typography>;
    }

    const renderBody = (lines: any) => {
        return (lines.map((item: any, index: any) => {
            return (
                <tr>
                    <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                        {renderTableHeading(index + 1)}
                    </td>
                    <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                        {renderTableHeading(item.itemID)}
                    </td>
                    <td style={{ textAlign: "left" }} className="picklistTableCollaped">

                        {renderTableHeading(item.itemDesc)}
                    </td>
                    <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                        {renderPrice(item.unitPrice)}
                    </td>
                    <td style={{ textAlign: "left" }} className="picklistTableCollaped">
                        {renderTableHeading(item.qty)}
                    </td>
                </tr>
            );
        }))
    }

    const getCustomerField = (data:any) => {
        return data.find((x:any)=>x.customerName)?.customerName ?? "";
    }

    const getOrderAge = (data:any) => {
        return data.find((x:any)=>x.age)?.age ?? "";
    }

    const getCustomerNumber = (data:any) => {
        return data.find((x:any)=>x.customerPhone)?.customerPhone ?? "";
    }
    
    const getCustomerEmail = (data:any) => {
        return data.find((x:any)=>x.customerEmail)?.customerEmail ?? "";
    }

    const formatData = () => {
        let data:any = [];
        props.data?.forEach((order:any) => {
            order?._source?.Shipment?.ShipmentLines?.ShipmentLine?.forEach((shipment:any) => {
                let dataRow: any = {};
                dataRow.orderNo = order?._source?.Shipment?.OrderNo ?? "";
                dataRow.orderDate = order?._source?.Shipment?.OrderDate ?? "";
                dataRow.customerName = getCustomerName(order);
                dataRow.customerEmail = order?._source?.Shipment?.CustomerInfo?.CustomerEMailID ?? "";
                dataRow.customerPhone = order?._source?.Shipment?.CustomerInfo?.DayPhone ?? "-";
                dataRow.age = Utils.getAgeTextFormat(order?._source?.Shipment?.Age) ?? ""; 
                dataRow.itemID = shipment?.ItemDetails?.Extn?.ExtnPOSItemID ?? "";
                dataRow.itemDesc = shipment?.ItemDesc ?? "";
                dataRow.unitPrice = shipment?.UnitPrice ?? "0";
                dataRow.qty = shipment?.OrderedQty ?? "";
                data.push(dataRow);
            });
        });

        let groupedData = Utils.groupBy(data, "orderNo");
        return groupedData;
    }

    const renderCustomerPickupSlip = () => {
        let data: any = formatData();
        let objKeys = Object.keys(data);

        const today = momenttz.tz("America/Los_Angeles").format(Constants.DateFormat.DateOnly);
        if (objKeys && objKeys.length > 0) {

            return <div style={{ width: '100%' }}>

                <div style={{ display: "flex", width: "100%", marginTop: "5px", borderBottom: "1px solid black" }}>
                    <div style={{ width: "33%", textAlign: "left" }}>
                        <Typography variant='body1' style={{ textAlign: "left", color: "black", fontWeight: 500 }}>Customer Pickup List</Typography>
                        <Typography variant='body2' style={{ textAlign: "left", color: "black" }}>Store #: {IdentityManager.getStoreId(props.store)}</Typography>

                    </div>
                    <div style={{ width: "66%", textAlign: "right" }}>
                        <Typography variant='body2' style={{ textAlign: "right", color: "black" }}>Current Date: {today}</Typography>
                    </div>

                </div>

                {
                    objKeys.map((item: any) => {
                        return <div>
                            <div style={{ marginTop: "20px", fontWeight: 500, color: "black", padding: "10px", fontSize: "12px" }}>
                                <Grid container>
                                        <Grid item sm={4} xs={4} md ={4}>
                                            <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>{"Order Number: "}{item}</Typography>
                                        </Grid>
                                        <Grid item sm={4} xs={4} md ={4}>
                                            <Typography variant='body2' style={{ fontSize: "12px",marginLeft:"40px", textAlign: "left", color: "black", fontWeight: 800 }}>{"Order Age: "}{getOrderAge(data[item])}</Typography>
                                        </Grid>
                                        <Grid item sm={4} xs={4} md ={4} textAlign={"right"}>
                                           <Typography variant='body2' style={{ fontSize: "12px", textAlign: "right", color: "black", fontWeight: 800 }}>{"Customer PH #: "}{getCustomerNumber(data[item])}</Typography>
                                        </Grid>
                                </Grid>
                                <Grid container marginTop={"5px"}>
                                        <Grid item sm={4} xs={4} md ={4}>
                                            <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>{"Customer Name: "}{getCustomerField(data[item])}</Typography>
                                        </Grid>
                                        <Grid item sm={6} xs={6} md ={6}>
                                            <Typography variant='body2' style={{ fontSize: "12px", marginLeft:"40px",textAlign: "left", color: "black", fontWeight: 800 }}>{"Customer Email: "}{getCustomerEmail(data[item])}</Typography>
                                        </Grid>
                                </Grid>
                            </div>
                            <div style={{ display: "flex", width: "100%" }}>
                                <table style={{ width: "100%", borderRight: "1px solid black" }} cellPadding={0} cellSpacing={0} className="packingListTable">
                                    <thead style={{ color: "black", borderTop: "1px solid black" }}>
                                        <th style={{ width: "5%", textAlign: "left" }} className="picklistTableCollaped">
                                            <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Sr no.</Typography>
                                        </th>
                                        <th style={{ width: "10%", textAlign: "left" }} className="picklistTableCollaped">
                                            <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Item ID</Typography>
                                        </th>
                                        <th style={{ width: "70%", textAlign: "left" }} className="picklistTableCollaped">

                                            <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Item Description</Typography>
                                        </th>
                                        <th style={{ width: "10%", textAlign: "left" }} className="picklistTableCollaped">
                                            <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Price</Typography>

                                        </th>
                                        <th style={{ width: "5%", textAlign: "left" }} className="picklistTableCollaped">
                                            <Typography variant='body2' style={{ fontSize: "12px", textAlign: "left", color: "black", fontWeight: 800 }}>Qty</Typography>

                                        </th>
                                    </thead>
                                    <tbody>
                                        {
                                            renderBody(data[item])
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>


                    })
                }
            </div>

        }
        else {
            return <></>;
        } 
    }

    return (
        <div style={{display:"none"}}>
            <div id='PickSlipContainer' style={{ background: 'white' }}>
                {renderCustomerPickupSlip()}
            </div>
        </div>
    );
}

export default CustomerPickupSlip;