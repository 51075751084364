import { Button, Dialog, DialogContent, DialogContentText, DialogActions, Slide, DialogTitle, Typography } from '@material-ui/core';
import { TransitionProps } from '@mui/material/transitions';
import { Grid } from '@mui/material';
import { InfoIcon, CloseIcon } from "../../Subcomponent/Shared/SharedIcons";
import IconButton from '@mui/material/IconButton';
import Utils from '../../Common/Utils';
import React from 'react';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const OrderDetailNotificationPopup = (props: any) => {
    const fontSize = Utils.isMobile ? 30 : 35;

    const close = () => {
        props.handleClose()
    }

    return (
        <Dialog disableEnforceFocus open={props.open} maxWidth={"sm"} TransitionComponent={Transition} style={{ zIndex: 1000000 }}>
            <DialogTitle id="draggable-dialog-title" style={{ padding: '5px 15px', background: "#2196F3" }}>
                <Grid container direction="row" alignItems="center" style={{ height: 35 }}>
                    <Grid item>
                        <InfoIcon style={{ color: "white", fontSize: fontSize }} />
                    </Grid>
                    <Grid item style={{ paddingBottom: 7, paddingLeft: 7 }}>
                        <p style={{ color: "white", fontSize: Utils.isMobile ? 16 : 22, margin: 0 }}>Info</p>
                    </Grid>
                </Grid>
                <IconButton
                    aria-label="close"
                    onClick={close}
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        color: "white",
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent className="itemDialogBorder dialogMinHeight dialogMinWidth" style={{ display: "flex", justifyContent: "center" }}>
                <Typography style={{ fontSize: 16, alignSelf: "center" }}>{props.msg}</Typography>
            </DialogContent>
            <DialogActions>
                <Button style={{ whiteSpace: 'nowrap', backgroundColor: "#D93A2F", color: "white" }}
                    onClick={close} variant="contained" startIcon={<CloseIcon />} >
                    Close
                </Button>
            </DialogActions>

        </Dialog>
    );
}