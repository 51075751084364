import React from "react";
import "./Drawer.scss";
import { useNavigate } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { Avatar } from "@material-ui/core";
import Constants from "../../Common/Constants";
import { useSelector } from "react-redux";
import {
  HomeIcon,
  AssignmentIcon,
  BarChartOutlinedIcon,
  CalendarMonthOutlinedIcon,
  DownloadingOutlinedIcon,
  FeedbackOutlinedIcon,
  InventoryOutlinedIcon,
  RedoOutlinedIcon,
  SyncOutlinedIcon,
} from "./SharedIcons";
import { Tooltip, Typography } from "@mui/material";
const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, .03)",
    },
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "10px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiAccordionDetails);

export default function DrawerRow(props: any): any {
  const navigate = useNavigate();
  const panel = "currentPanel";
  const [expanded, setExpanded] = React.useState<string | false>();
  const kcdcQuantity = useSelector(
    (state: any) => state.ActionLoaderReducersState.kcdcTotalQuantity
  );

  const handleClick = () => {
    props.setSelectedOption(props.data.Header);
    if (props.data.Data != "") {
      setExpanded(expanded ? false : panel);
    } else {
      changeRoute(props.data.Route, null);
    }
  };
  const changeRoute = (route: string, id: string | null) => {
    if (id !== null) {
      navigate(route + ":" + id);
    } else {
      navigate(route);
    }
  };

  const chooseIcon = (value: any) => {
    switch (value) {
      case "Dashboard":
        return <HomeIcon fontSize="small" />;
      case "FulfillOrders":
        return <InventoryOutlinedIcon fontSize="small" />;
      case "ReceiveOrders":
        return <DownloadingOutlinedIcon fontSize="small" />;
      case "KCDCReturns":
        return <RedoOutlinedIcon fontSize="small" />;
      case "Reports":
        return <BarChartOutlinedIcon fontSize="small" />;
      case "TechAudits":
        return <AssignmentIcon fontSize="small" />;
      case "OrdersSyncFlow":
        return <SyncOutlinedIcon fontSize="small" />;
      case "Feedback":
        return <FeedbackOutlinedIcon fontSize="small" />;
      case "OrderHistory":
        return <CalendarMonthOutlinedIcon fontSize="small" />;
      default:
        break;
    }
  };

  const isSelected = props.selected === props.data.Header;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height:"50px",
        alignItems: "center",
        width: "100%",
        backgroundColor: isSelected ? "#F9F9F9" : "",
      }}
    >
      <div
        onClick={() => {
          handleClick();
        }}
        className={isSelected ? "DrawerLine SelectedDrawerLine" : "DrawerLine"}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: "10px" }}>
          {chooseIcon(props.data.Route)}
        </div>
        {props.data.Header}
        {props.data.Route == Constants.RouteLabels.KCDCReturns &&
          kcdcQuantity > 0 && (
            <Avatar
              style={{
                backgroundColor: Constants.Colors.red,
                width: 25,
                height: 25,
                fontSize: "0.95rem",
                marginLeft:"3px"
              }}
            >
              {kcdcQuantity}
            </Avatar>
          )}
      </div>
    </div>
  );
}
