import { combineReducers } from 'redux'
import TestReducer from "./TestReducer";
import ActionLoaderReducer from './ActionLoaderReducers';
import PackOrderReducer from './PackOrderReducters';
import FulfillOrdersReducers from './FulfillOrdersReducers';
import PickOrdersReducers from './PickOrderReducers'
import DashboardReducer from './DashboardReducer';
import CustomerPickupReducers from './CustomerPickupReducers';
import OrderHistoryReducer from './OrderHistoryReducer';
import ReceiveOrdersReducers from './ReceiveOrdersReducers';
import TecAuditsReducers from './TecAuditsReducers';
import ContactCustomerReducers from './ContactCustomerReducers';
import GenericNotificationReducers from './GenericNotificationReducers';
import SearchReducer from './SearchReducers';
import KcdcReducer from './KcdcReducer';
import FulfilledOrdersHistoryReducer from './FulfilledOrdersHistoryReducer';
import ReportErrorReducers from './ReportErrorReducers';
import ScandItReducers from './ScandItReducers';
const CombineReducer = combineReducers({
    TestReducerState: TestReducer,  //  TestReducerState  // TestReducer
    ActionLoaderReducersState: ActionLoaderReducer,
    PackOrderReducersState: PackOrderReducer,
    FulfillOrdersReducersState: FulfillOrdersReducers,
    PickOrdersReducersState: PickOrdersReducers,
    DashboardReducerState: DashboardReducer,
    CustomerPickupState: CustomerPickupReducers,
    OrderHistoryReducerState: OrderHistoryReducer,
    ReceiveOrdersReducersState: ReceiveOrdersReducers,
    TecAuditsReducersState: TecAuditsReducers,
    ContactCustomerReducersState: ContactCustomerReducers,
    GenericNotificationReducersState: GenericNotificationReducers,
    SearchReducerState: SearchReducer,
    KcdcReducerState: KcdcReducer,
    FulfilledOrdersHistoryReducerState: FulfilledOrdersHistoryReducer,
    ReportErrorReducersState: ReportErrorReducers,
    ScandItReducersState: ScandItReducers
})
export default CombineReducer