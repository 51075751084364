import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Divider, Grid, Typography, } from '@mui/material';
const OrderLineShimmer = (props: any) => {
    return (
        <React.Fragment>
            <Grid container spacing={2} display={"flex"} >
                <Grid item xs={12} sm={12} className='textLeft'>
                    <Grid container spacing={2} style={{ margin: "0px" }} sx={{ flexGrow: 1 }}>
                        <Grid item xs={2} sm={2} style={{ padding: "8px", borderRight: "1px solid #E0E0E0", display: "flex", justifyContent: "center" }}>
                            <Skeleton variant="circular" width={50} height={50} />
                        </Grid>
                        <Grid item xs={10} sm={10} style={{ padding: "8px", textAlign: "left" }}>
                            <Typography style={{ fontSize: "15px", fontWeight: 500, color: "#D93A2F" }}>
                                <Skeleton animation="wave" /></Typography>
                            <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                <Skeleton animation="wave" />
                            </Typography>
                            <Typography variant="body2" sx={{ fontSize: 15 }} gutterBottom>
                                <Skeleton animation="wave" />
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            <Divider></Divider>
        </React.Fragment>
    );

}
export default OrderLineShimmer;