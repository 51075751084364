import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
// import  makeStyles from '@mui/styles';
import './VerticalLinearStepper.scss';
import { IVerticalStepper } from '../../Models/IVerticalStepper';
import { NotificationPopup } from '../../Subcomponent/Shared/NotificationPopup'
import { INotificationPopupSettings, IButton } from '../../Models/INotificationPopupSettings';
import { CustomerResponse } from '../Shared/CustomerResponse'
import { ResourceData } from '../Shared/ResourceData'
import Constants from '../../Common/Constants'
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import {
  NearMeOutlinedIcon,
  MarkEmailUnreadIcon, MessageIcon, MarkEmailReadIcon, ApartmentIcon,
  DoneIcon, CloseIcon, PlaylistAddCheck, PlaylistAddIcon, ForwardToInboxIcon, StopIcon, DescriptionIcon, ShoppingCartOutlinedIcon, LocalShippingOutlinedIcon, DirectionsCarFilledOutlinedIcon, MarkEmailUnreadOutlinedIcon, MessageOutlinedIcon, EscalatorOutlinedIcon, CancelOutlinedIcon, ReceiptLongOutlinedIcon
} from "../Shared/SharedIcons"
import Utils from '../../Common/Utils';
import LabelText from '../../Common/LabelText';
import { Note } from '../Shared/Note';
import OrderHistory from '../Shared/OrderHistory';
import ContactCustomerManager from '../../Managers/ContactCustomer.Manager';
import { useDispatch } from 'react-redux';
import allActions from '../../Store/Actions/AllActions';
var moment = require('moment');

export const VerticalLinearStepper: React.FC<IVerticalStepper> = (setting) => {

  const [detail, setDetail] = useState(false);
  const [customerResponse, setCustomerResponse] = useState(false);
  const [title, setTitle] = useState("");
  const [msg, setMsg] = useState("");
  const [contactCustomerData, setContactCustomerData] = useState({} as any);
  const [resourceData, setResourceData] = useState({} as any);
  const [showResourcePopup, setShowResourcePopup] = useState(false);
  const dispatch = useDispatch();

  const openDetail = () => {
    setDetail(true)
  }
  const closeDetail = () => {
    setDetail(false)
  }
  const closeCustomerResponse = () => {
    setCustomerResponse(false)
  }
  const openCustomerResponse = () => {
    setCustomerResponse(true)
  }
  const closeResourcePopup = () => {
    setShowResourcePopup(false)
  }
  const openResourcePopup = () => {
    setShowResourcePopup(true)
  }

  const clickCustomerResponseIcon = async (e: any, data: any) => {
    console.log(data)
    e.stopPropagation()
    setContactCustomerData(data)
    openCustomerResponse()
  }

  const showCursor = (step: any) => {
    if ((step.description || step.label === Constants.OrderHistoryTypes.Customer_Contacted) ||
      (step.label === Constants.OrderHistoryTypes.Resource_Item &&
        step.data?.hasOwnProperty("resourceShipmentLineData"))) {
      return "pointer";
    }
    return "default";
  }

  const clickLable = async (e: any, step: any) => {
    e.stopPropagation()
    if (step.description && step.description != "") {
      setTitle(step.label);
      setMsg(step.description)
      openDetail()
    }
    if (step.label == Constants.OrderHistoryTypes.Customer_Contacted) {

      setContactCustomerData(step)
      openCustomerResponse()
    }
    else if (step.label == Constants.OrderHistoryTypes.Resource_Item && step.data?.hasOwnProperty("resourceShipmentLineData")) {
      setResourceData(step.data);
      openResourcePopup();
    }
  }

  let detailPopupButtons: IButton[] = [
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeDetail,
      // color: Constants.Colors.blue,
      color: Constants.Colors.white,
      border: Constants.Colors.blue,
      textColor: Constants.Colors.blue
    }]
  let detailPopup: INotificationPopupSettings = {
    open: detail,
    type: Constants.NotificationPopupType.Info,
    title: title,
    msg: msg,
    handleClose: closeDetail,
    isCustomJSX: false,
    actions: detailPopupButtons
  }

  let customerResponseButtons: IButton[] = [
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeCustomerResponse,
      // color: Constants.Colors.blue,
      color: Constants.Colors.white,
      border: Constants.Colors.blue,
      textColor: Constants.Colors.blue
    }]
  let showCustomerResponse: INotificationPopupSettings = {
    open: customerResponse,
    type: Constants.NotificationPopupType.Info,
    title: "Customer Response",
    msg: "",
    handleClose: closeCustomerResponse,
    isCustomJSX: true,
    customJSX: <CustomerResponse {...contactCustomerData}></CustomerResponse>,
    actions: customerResponseButtons,
    fullWidth: true,
    maxWidth: "lg"
  }


  let resourcePopupButtons: IButton[] = [
    {
      text: "Close",
      icon: <CloseIcon />,
      action: closeResourcePopup,
      // color: Constants.Colors.blue,
      color: Constants.Colors.white,
      border: Constants.Colors.blue,
      textColor: Constants.Colors.blue
    }]

  let resourceDataPopup: INotificationPopupSettings = {
    open: showResourcePopup,
    type: Constants.NotificationPopupType.Info,
    title: "Resourced",
    msg: "",
    handleClose: closeResourcePopup,
    isCustomJSX: true,
    customJSX: <ResourceData {...resourceData}></ResourceData>,
    actions: resourcePopupButtons,
    fullWidth: true,
    maxWidth: "lg"
  }

  const activeIndex = Utils.getStepperIndex(setting, setting.activeStep);

  const iconDecision = (type: string, index: number) => {
    const completedIndex = Utils.getStepperIndex(setting, setting.activeStep);

    switch (type) {
      case Constants.OrderHistoryTypes.Order_Release:
        return (
          <PlaylistAddCheck style={{ color: index < completedIndex ? Constants.Colors.black : index === completedIndex ? Constants.Colors.red : Constants.Colors.dimGrey }} />
        );
      case Constants.OrderHistoryTypes.Order_Queue:
        return (
          <PlaylistAddIcon style={{ color: index < completedIndex ? Constants.Colors.black : index === completedIndex ? Constants.Colors.red : Constants.Colors.dimGrey }} />
        );
      case Constants.OrderHistoryTypes.Picking:
        return (
          <ShoppingCartOutlinedIcon style={{ color: index < completedIndex ? Constants.Colors.black : index === completedIndex ? Constants.Colors.red : Constants.Colors.dimGrey }} />
        );
      case Constants.OrderHistoryTypes.OnHold:
        return (
          <StopIcon style={{ color: index < completedIndex ? Constants.Colors.black : index === completedIndex ? Constants.Colors.red : Constants.Colors.dimGrey }} />
        );
      case Constants.OrderHistoryTypes.Packing:
        return (
          // <ApartmentIcon style={{ color: index < completedIndex ? Constants.Colors.black : index === completedIndex ? Constants.Colors.red : Constants.Colors.dimGrey }} />
          <svg width="20" height="20" viewBox="0 0 20 20" fill="red" xmlns="http://www.w3.org/2000/svg" >
            <path d="M2.41667 5.66667V17.5833H17.5833V5.66667H14.3333V14.3333L10 12.1667L5.66667 14.3333V5.66667H2.41667ZM2.41667 19.75C1.82083 19.75 1.31076 19.5378 0.886458 19.1135C0.462153 18.6892 0.25 18.1792 0.25 17.5833V4.06875C0.25 3.81597 0.290625 3.57222 0.371875 3.3375C0.453125 3.10278 0.575 2.88611 0.7375 2.6875L2.09167 1.03542C2.29028 0.782639 2.53854 0.588542 2.83646 0.453125C3.13438 0.317708 3.44583 0.25 3.77083 0.25H16.2292C16.5542 0.25 16.8656 0.317708 17.1635 0.453125C17.4615 0.588542 17.7097 0.782639 17.9083 1.03542L19.2625 2.6875C19.425 2.88611 19.5469 3.10278 19.6281 3.3375C19.7094 3.57222 19.75 3.81597 19.75 4.06875V17.5833C19.75 18.1792 19.5378 18.6892 19.1135 19.1135C18.6892 19.5378 18.1792 19.75 17.5833 19.75H2.41667ZM2.85 3.5H17.15L16.2292 2.41667H3.77083L2.85 3.5ZM7.83333 5.66667V10.8125L10 9.72917L12.1667 10.8125V5.66667H7.83333Z" fill={index < completedIndex ? Constants.Colors.black : index === completedIndex ? Constants.Colors.red : Constants.Colors.dimGrey} />
          </svg >
        );
      case Constants.OrderHistoryTypes.Shipped:
        return (
          <LocalShippingOutlinedIcon style={{ color: index < completedIndex ? Constants.Colors.black : index === completedIndex ? Constants.Colors.red : Constants.Colors.dimGrey }} />
        );
      case Constants.OrderHistoryTypes.Customer_Pickup:
        return (
          // <DirectionsCarFilledOutlinedIcon style={{ color: index < completedIndex ? Constants.Colors.black : index === completedIndex ? Constants.Colors.red : Constants.Colors.dimGrey }} />
          <NearMeOutlinedIcon style={{ color: index < completedIndex ? Constants.Colors.black : index === completedIndex ? Constants.Colors.red : Constants.Colors.dimGrey }} />
        );
      case Constants.OrderHistoryTypes.Customer_Contacted:
        return (
          <MarkEmailUnreadOutlinedIcon style={{ color: index < completedIndex ? Constants.Colors.black : index === completedIndex ? Constants.Colors.red : Constants.Colors.dimGrey }} />
        );
      case Constants.OrderHistoryTypes.Note:
        return (
          <MessageOutlinedIcon style={{ color: index < completedIndex ? Constants.Colors.black : index === completedIndex ? Constants.Colors.red : Constants.Colors.dimGrey }} />
        );
      case Constants.OrderHistoryTypes.Pending_Receive:
        return (
          // <ReceiptLongOutlinedIcon style={{ color: index < completedIndex ? Constants.Colors.black : index === completedIndex ? Constants.Colors.red : Constants.Colors.dimGrey }} />
          <svg width="20" height="20" viewBox="0 0 20 20" fill="red" xmlns="http://www.w3.org/2000/svg" >
            <path d="M2.41667 5.66667V17.5833H17.5833V5.66667H14.3333V14.3333L10 12.1667L5.66667 14.3333V5.66667H2.41667ZM2.41667 19.75C1.82083 19.75 1.31076 19.5378 0.886458 19.1135C0.462153 18.6892 0.25 18.1792 0.25 17.5833V4.06875C0.25 3.81597 0.290625 3.57222 0.371875 3.3375C0.453125 3.10278 0.575 2.88611 0.7375 2.6875L2.09167 1.03542C2.29028 0.782639 2.53854 0.588542 2.83646 0.453125C3.13438 0.317708 3.44583 0.25 3.77083 0.25H16.2292C16.5542 0.25 16.8656 0.317708 17.1635 0.453125C17.4615 0.588542 17.7097 0.782639 17.9083 1.03542L19.2625 2.6875C19.425 2.88611 19.5469 3.10278 19.6281 3.3375C19.7094 3.57222 19.75 3.81597 19.75 4.06875V17.5833C19.75 18.1792 19.5378 18.6892 19.1135 19.1135C18.6892 19.5378 18.1792 19.75 17.5833 19.75H2.41667ZM2.85 3.5H17.15L16.2292 2.41667H3.77083L2.85 3.5ZM7.83333 5.66667V10.8125L10 9.72917L12.1667 10.8125V5.66667H7.83333Z" fill={index < completedIndex ? Constants.Colors.black : index === completedIndex ? Constants.Colors.red : Constants.Colors.dimGrey} />
          </svg >
        );
      case Constants.OrderHistoryTypes.Resource_Item:
        return (
          <EscalatorOutlinedIcon style={{ color: index < completedIndex ? Constants.Colors.black : index === completedIndex ? Constants.Colors.red : Constants.Colors.dimGrey }} />
        );
      case Constants.OrderHistoryTypes.Cancel_Order:
        return (
          <CancelOutlinedIcon style={{ color: index < completedIndex ? Constants.Colors.black : index === completedIndex ? Constants.Colors.red : Constants.Colors.dimGrey }} />
        );
      default:
        return null;
    }
  }




  const getDate = (dateTime: any) => {
    if (dateTime && dateTime != "") {
      //
      return moment(dateTime).format(Constants.DateFormat.Format);
    }
    else {
      return ""
    }

  }

  const renderTransactionType = (text: any, store: any, resourceStore?: any, resourceShipmentNode?: any) => {
    // Order Release
    if (text == Constants.OrderHistoryTypes.Order_Release) {
      text = "Order Created"
    }
    if (text == Constants.OrderHistoryTypes.Order_Queue) {
      if (store != "") {
        text = "Order Released to Store " + store
      }
      else {
        text = "Order Release"
      }
    }
    if (text == Constants.OrderHistoryTypes.Resource_Item && resourceStore && resourceShipmentNode) {
      text = `Resourced from Store ${resourceStore} to ${resourceShipmentNode}`
    }
    if (text == Constants.OrderHistoryTypes.OnHold) {
      text = Constants.FulfillOrdersDisplayNames.OnHold;
    }
    if (text == Constants.OrderHistoryTypes.Pending_Receive) {
      text = Constants.FulfillOrdersDisplayNames.Pending_Receive;
    }
    return text;
  }

  const getContactResponse = (step: any) => {
    return <>
      {
        step.map((item: any) => {
          return <StepContent style={{ color: Constants.Colors.red }}>
            <React.Fragment>
              <div style={{ display: "flex" }}>
                {
                  item.isResponse ?
                    <MarkEmailReadIcon style={{ cursor: "pointer" }} onClick={(e) => clickCustomerResponseIcon(e, item.data)} />
                    :
                    <ForwardToInboxIcon style={{ cursor: "pointer" }} onClick={(e) => clickCustomerResponseIcon(e, item.data)} />
                }

                <Typography variant="caption" style={{ marginTop: 5, marginLeft: 3 }}>
                  {
                    item.label
                  }
                </Typography>
              </div>
              <Typography variant="caption" style={{ color: Constants.Colors.grey }}>
                {getDate(item.date)}
              </Typography>
            </React.Fragment>
          </StepContent>
        })
      }
    </>
  }

  const getSortedData = (step: any) => {
    let sortedData = []
    if (step?.data?.responded == true || step?.data?.responded == "true") {
      let contactCustomerResponseObj = {
        data: step.data,
        dateJson: moment(step?.data?.customerresponsedate).valueOf(),
        date: step?.data?.customerresponsedate,
        isResponse: true,
        label: "Customer Responded"
      }
      sortedData.push(contactCustomerResponseObj)
    }
    if (step?.historyFollowupsSteps && step?.historyFollowupsSteps.length > 0) {
      step?.historyFollowupsSteps.forEach((element: any) => {
        let followupObj = {
          data: element.data,
          dateJson: moment(element?.data?.transactionDate).valueOf(),
          date: element?.data?.transactionDate,
          isResponse: false,
          label: "Follow up"
        }
        sortedData.push(followupObj)
        if (element?.data?.responded == true || element?.data?.responded == "true") {
          let contactCustomerResponseObj = {
            data: element.data,
            dateJson: moment(element?.data?.customerresponsedate).valueOf(),
            date: element?.data?.customerresponsedate,
            isResponse: true,
            label: "Customer Responded (Follow up)"
          }
          sortedData.push(contactCustomerResponseObj)
        }
      });
    }
    sortedData.sort((a: any, b: any) => { return a.dateJson - b.dateJson })
    return sortedData
  }
  const showNestedData = (step: any, active: boolean) => {
    // This is for Normal Cases for Customer Responded etc.
    if (step.label == Constants.OrderHistoryTypes.Customer_Contacted) {
      let sortedData = getSortedData(step)
      return <>
        {getContactResponse(sortedData)}
      </>
    }
    else if (step.data?.resourceShipmentLineData && step.label == Constants.OrderHistoryTypes.Resource_Item) {
      let resourceData = step.data?.resourceShipmentLineData;
      return <StepContent style={{ color: active ? Constants.Colors.grey : "", display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        {
          <React.Fragment>
            <div>
              <Typography variant="caption">Item ID: {resourceData.itemID}</Typography>
            </div>
            <div>
              <Typography variant="caption">SKU #: {resourceData.skuID}</Typography>
            </div>
          </React.Fragment>
        }

      </StepContent>
    }
    else {
      // This is the case of merged data
      if (step?.mergedData && step?.mergedData.length > 0) {

        return <StepContent style={{ color: active ? Constants.Colors.red : "" }}>
          {
            step?.mergedData.map((item: any) => {
              return (
                <div>
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "flex" }}>
                      <Typography variant="caption" style={{ marginLeft: 3, marginRight: 10 }}>Partial {renderTransactionType(item._source ? item._source.transactionType : item.transactionType, item.shipNode ? item.shipNode : "")}</Typography>
                    </div>
                    <Typography variant="caption">
                      {getDate(item._source ? item._source.transactionDate : item.transactionDate)}
                    </Typography>
                  </div>
                  <div>
                    <div style={{ display: "flex" }}>
                      <Typography variant="caption" style={{ marginLeft: 3, marginRight: 10 }}>Fulfillment Location: {item.orderData ? item.orderData?._source?.Shipment?.ShipNode : ""}</Typography>
                      {item.orderData?._source?.Shipment?.ExtnPickupStoreID && <Typography variant="caption">Pickup Store: {item.orderData._source.Shipment.ExtnPickupStoreID}</Typography>}
                    </div>
                  </div>
                </div>
              )

            })



          }

        </StepContent >

      }

    }



    return "";

  }

  const checkResourcedNotes = (step: any) => {
    return step?.label == Constants.OrderHistoryTypes.Resource_Item && (step?.data?.reasonCodeName || step?.data?.reasonText)
  }

  const showLabel = (stepIndex: number) => {
    const completedIndex = Utils.getStepperIndex(setting, setting.activeStep);

    if (stepIndex < completedIndex) {
      return Constants.Colors.black; // Previous steps are black
    } else if (stepIndex === completedIndex) {
      return Constants.Colors.red; // Active step is red
    } else {
      return Constants.Colors.dimGrey; // Upcoming steps are gray
    }
  };



  return (

    <div>
      <NotificationPopup {...detailPopup}>
      </NotificationPopup>
      <NotificationPopup {...showCustomerResponse}>
      </NotificationPopup>
      <NotificationPopup {...resourceDataPopup}>
      </NotificationPopup>
      <Stepper activeStep={activeIndex} orientation='vertical'>
        {setting.stepsOptions.map((step, index) => {
          console.log(step)
          let active = index <= activeIndex ? true : false
          console.log(active)
          const labelProps: any = {};
          labelProps.optional = (
            <Typography variant="caption" >
              {getDate(step.time)}
            </Typography>
          );
          // labelProps.icon = (iconDecision(step.label, active))
          labelProps.icon = (iconDecision(step.label, index))

          return (
            <Step key={step.label} active={true}>
              <StepLabel style={{ cursor: showCursor(step) }}
                onClick={(e: any) => clickLable(e, step)}  {...labelProps}>
                <div style={{ display: "flex", color: showLabel(index) }}>
                  {renderTransactionType(step.label, step.data?.shipNode ? step.data.shipNode : "", step.data?.store, step.data?.resourceShipmentNode)}
                  {checkResourcedNotes(step) && <DescriptionIcon style={{ color: active ? Constants.Colors.yellow : '', marginLeft: "4px", marginTop: "-3px" }} />}

                </div>
              </StepLabel>
              {showNestedData(step, active)}
            </Step>
          );
        })}
      </Stepper>
    </div >


  );
}



