import { Navigate, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { textAlign } from '@mui/system';
import { Typography } from '@material-ui/core';
import StoreManager from '../../Managers/Store.manager';
import allActions from '../../Store/Actions/AllActions';
import ApiService from '../../Common/ApiService'
import PdfIframe from "../../Subcomponent/PickOrder/PdfIframe";
import Constants from '../../Common/Constants';
import { Card, CardContent, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup } from '@mui/material';
import Utils from '../../Common/Utils';
import { IButton, INotificationPopupSettings } from '../../Models/INotificationPopupSettings';
import { CloseIcon, DoneIcon } from '../Shared/SharedIcons';
import LabelText from '../../Common/LabelText';

const PrintPickSlipsGroupByOptions = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let pickSlipGroupByModal: any = useSelector((state: any) => state.FulfillOrdersReducersState.pickSlipGroupByModal);




    return (
        <Paper style={{ padding: "5px" }}>
            <Grid container>
                <Grid item xs={12} sm={12} className='inputField'>
                    {
                        Constants.choicesValues && Constants.choicesValues.length > 0 ?
                            <FormControl className='externalcustomRadio'>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={pickSlipGroupByModal.value}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {

                                        dispatch(allActions.fulfillOrdersActions.changePrintPickSlips(
                                            {
                                                ...pickSlipGroupByModal,
                                                value: (event.target as HTMLInputElement).value
                                            }
                                        ))
                                    }}
                                >
                                    {Constants.choicesValues.map((val: any) => {
                                        return <FormControlLabel value={val.id} control={<Radio style={{ color: "#f2554a" }} />} label={val.val} />
                                    })}


                                </RadioGroup>
                            </FormControl> : ""
                    }
                </Grid>
            </Grid>
        </Paper>
    )

}

export default PrintPickSlipsGroupByOptions;