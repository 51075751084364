import * as React from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { EqualizerIcon, HomeIcon, PlaylistAddCheck, ApartmentIcon, ShoppingCart, DirectionsCar, CallReceived, ReceiptLongIcon, ManageSearchIcon, PreviewIcon, AssignmentIcon, CallMadeIcon, ForumIcon, HistoryIcon } from "../../Subcomponent/Shared/SharedIcons";
import Constants from "../../Common/Constants";
export default function IconBreadcrumbs(props: any) {
  const navigate = useNavigate();
  const iconDecision = (type: string) => {
    switch (type) {
      case "home":
        return (
          <HomeIcon
            sx={{ mr: 0.5 }}
            fontSize="inherit"
            style={{ color: Constants.SiteColors.Red }}
          />
        );
      case "fulfillorders":
        return (
          <PlaylistAddCheck
            sx={{ mr: 0.5 }}
            fontSize="inherit"
            style={{ color: Constants.SiteColors.Red }}
          />
        );
      case "reports":
        return (
          <EqualizerIcon
            sx={{ mr: 0.5 }}
            fontSize="inherit"
            style={{ color: Constants.SiteColors.Red }}
          />
        );
      case "pick":
        return (
          <ShoppingCart
            sx={{ mr: 0.5 }}
            fontSize="inherit"
            style={{ color: Constants.SiteColors.Red }}
          />
        );
      case "pack":
        return (
          <ApartmentIcon
            sx={{ mr: 0.5 }}
            fontSize="inherit"
            style={{ color: Constants.SiteColors.Red }}
          />
        );
      case "customerpickup":
        return (
          <DirectionsCar
            sx={{ mr: 0.5 }}
            fontSize="inherit"
            style={{ color: Constants.SiteColors.Red }}
          />
        );
      case "receiveorders":
        return (
          <CallReceived
            sx={{ mr: 0.5 }}
            fontSize="inherit"
            style={{ color: Constants.SiteColors.Red }}
          />
        );
      case "pendingreceipt":
        return (
          <ReceiptLongIcon
            sx={{ mr: 0.5 }}
            fontSize="inherit"
            style={{ color: Constants.SiteColors.Red }}
          />
        );
      case "search":
        return (
          <ManageSearchIcon
            sx={{ mr: 0.5 }}
            fontSize="inherit"
            style={{ color: Constants.SiteColors.Red }}
          />
        );
      case "orderdetail":
        return (
          <PreviewIcon
            sx={{ mr: 0.5 }}
            fontSize="inherit"
            style={{ color: Constants.SiteColors.Red }}
          />
        );
      case "kcdcreturn":
        return (
          <CallMadeIcon
            sx={{ mr: 0.5 }}
            fontSize="inherit"
            style={{ color: Constants.SiteColors.Red }}
          />
        );
      case "kcdcreturndetail":
        return (
          <AssignmentIcon
            sx={{ mr: 0.5 }}
            fontSize="inherit"
            style={{ color: Constants.SiteColors.Red }}
          />
        );
      case "techaudits":
        return (
          <AssignmentIcon
            sx={{ mr: 0.5 }}
            fontSize="inherit"
            style={{ color: Constants.SiteColors.Red }}
          />
        );
      case "orderssyncflow":
        return (
          <AssignmentIcon
            sx={{ mr: 0.5 }}
            fontSize="inherit"
            style={{ color: Constants.SiteColors.Red }}
          />
        );
      case "feedback":
        return (
          <ForumIcon
            sx={{ mr: 0.5 }}
            fontSize="inherit"
            style={{ color: Constants.SiteColors.Red }}
          />
        );
      case "feedback":
        return (
          <ForumIcon
            sx={{ mr: 0.5 }}
            fontSize="inherit"
            style={{ color: Constants.SiteColors.Red }}
          />
        );
      case "orderHistory":
        return (
          <HistoryIcon
            sx={{ mr: 0.5 }}
            fontSize="inherit"
            style={{ color: Constants.SiteColors.Red }}
          />
        );
    }
  }
  const navigateTo = (route: string) => {
    navigate(route)
  }
  const tail = (item: any, key: number) => {
    return (
      <Link
        key={key}
        underline="hover"
        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        color="inherit"
        onClick={() => navigateTo(item.route)}
      >
        {iconDecision(item.icon)}
        {item.label}
      </Link>
    );
  };
  const active = (item: any, key: number) => {
    return (
      <Typography
        key={key}
        sx={{ display: "flex", alignItems: "center", fontSize: 22 }}
        color="text.primary"
      >
        {iconDecision(item.icon)}
        {item.label}
      </Typography>
    );
  };
  return (
    <div style={{ marginTop: 5, marginLeft: 5 }}>
      <Breadcrumbs aria-label="breadcrumb">
        {props.siteBreadCrumbs.map((item: any, i: number) => {
          return item.active ?
            active(item, i)
            :
            tail(item, i)
        })}
      </Breadcrumbs>
    </div>
  );
}
